import React from 'react'
import { Button, Col, Row } from "react-bootstrap-v5"
import { t } from 'i18next'
import { useMemo, useState } from "react"
import { LoyaltyModel } from '../../models/Loyalty'
import './style.scss'
import { growthAndMarketingAction, selectIsOpenEditLoyaltyEquationModal, selectLoyaltyEquation } from '../../redux/GrowthAndMarketingSlice'
import { useDispatch } from 'react-redux'
import EditLoyaltyEquationModal from '../../modals/loyalty/EditLoyaltyEquationModal'
import { useAppSelector } from 'setup/redux/Hooks'
import { KTSVG } from 'app/utils/helpers/components/KTSVG'
import { selectLanguage } from 'app/modules/auth'
import { Languages } from 'app/utils/common/constants'
import DataTable, { IDataTableColumn, IDataTableStyles } from 'react-data-table-component'
import { B8akPagination } from 'theme/layout/components/B8akPagination'

type Props = {
  isLoading?: boolean
  listLoyalty: LoyaltyModel[]
}

const Loyalty: React.FC<Props> = ({ isLoading, listLoyalty }) => {
  const dispatch = useDispatch()
  const isOpenEditLoyaltyEquationModal = useAppSelector(selectIsOpenEditLoyaltyEquationModal)
  const loyaltyEquation = useAppSelector(selectLoyaltyEquation)
  const language = useAppSelector(selectLanguage)
  const rtl = language === Languages.ar
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(6)

  const LoadingElement = useMemo(() => () => {
    return (<>
      <Col xs={12} className='skeleton-box h-80px rounded mb-1 mt-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
      <Col xs={12} className='skeleton-box h-80px rounded' />
    </>)
  }, [])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setRowsPerPage(newPerPage)
  }

  const customStyles: IDataTableStyles = {
    headCells: {
      style: {
        textTransform: "uppercase",
        fontSize: "14px",
        fontWeight: 500,
        backgroundColor: "var(--bs-primary)",
        height: "50px !important",
        padding: "0 !important",
      }
    },
    cells: {
      style: {
        textAlign: "center",
      }
    }
  }

  const columns: IDataTableColumn<LoyaltyModel>[] = useMemo(
    () => [
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_LOYALTY.CUSTOMER_NAME'),
        cell: (cell: LoyaltyModel) => <p className='text-center'>{cell.customerName}</p>,
        width: 'calc(100%*1/3)',
        sortable: false,
      },
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_LOYALTY.PHONE_NUMBER'),
        cell: (cell: LoyaltyModel) => <p className='text-center'>{cell.phoneNumer}</p>,
        width: 'calc(100%*1/3)',
        sortable: false,
      },
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_LOYALTY.POINTS'),
        cell: (cell: LoyaltyModel) => <p className='text-center'>{cell.points}</p>,
        width: 'calc(100%*1/3)',
        sortable: false,
      }
    ],
    []
  )

  return (
    <div>
      <Row className={`px-5 loyalty-page ${rtl ? 'loyalty-page-rtl' : ''}`}>
        <Col xs={12} className="d-flex justify-content-end p-0 mt-5">
          <Col xs={3} className='ms-2'>
            <Button
              variant='dark'
              className='fs-14 p-0 edit-loyalty-equation-btn'
              disabled={isLoading}
              onClick={() => {dispatch(growthAndMarketingAction.openEditLoyaltyEquationModal())}}
            >
              {isLoading && (
                <div
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></div>
              )}
              {t('GROWTH_AND_MARKETING_PAGE.BTN_EDIT_LOYALTY')}
            </Button>
          </Col>
        </Col>
        <Col xs={12} className="mt-10">
          <Row className='loyalty-table-title'>
            <Col xs={4} >
              <span className='loyalty-information-title'>{t('GROWTH_AND_MARKETING_PAGE.LOYALTY_EQUATION')}
                <h4 className='loyalty-information-content'>1 {t('SAR')} = {loyaltyEquation?.numberOfPoints} {t('GROWTH_AND_MARKETING_PAGE.POINTS')}</h4>
              </span>
              <span className='line'></span>
            </Col>
            <Col xs={4} >
              <span className='loyalty-information-title'>{t('GROWTH_AND_MARKETING_PAGE.EXPRIES_IN')}
                <h4 className='loyalty-information-content'>{loyaltyEquation?.expriesDay ? loyaltyEquation?.expriesDay : 
                  <KTSVG defaultColor={true} path='/assets/images/icons/infinity.svg' /> } {t('GROWTH_AND_MARKETING_PAGE.TABLE_LOYALTY.DAYS')}</h4>
              </span>
              <span className='line'></span>
            </Col>
            <Col xs={4} ><span className='loyalty-information-title'>{t('GROWTH_AND_MARKETING_PAGE.PARTICIPANTS')}
              <h4 className='loyalty-information-content'>3 {t('GROWTH_AND_MARKETING_PAGE.PARTICIPANTS')}</h4></span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="mt-15px">
          <Row className='loyalty-table-header'>
              <Col xs={12} className='loyalty-points-table--header--bg-sources-list loyalty-points-table--header-title'>{t('GROWTH_AND_MARKETING_PAGE.TABLE_TITLE')}</Col>
          </Row>
        </Col>
        <div className='loyalty-table'>
          <DataTable
            columns={columns}
            data={listLoyalty}
            customStyles={customStyles}
            responsive
            fixedHeader
            noHeader
            selectableRows={false}
            pagination
            paginationDefaultPage={page}
            paginationTotalRows={listLoyalty.length}
            paginationPerPage={rowsPerPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationComponent={(e: any) => (
              <>
                <B8akPagination event={e} enabledPageSize={false} enabledStat={false} />
              </>
            )}
            noDataComponent={
              <div className='text-center my-10'>{t('GROWTH_AND_MARKETING_PAGE.NO_DATA')}</div>
            }
          />
        </div>
      </Row>

      <EditLoyaltyEquationModal isShow={isOpenEditLoyaltyEquationModal} rtl={rtl}
        onHide={() => dispatch(growthAndMarketingAction.hideEditLoyaltyEquationModal())}/>
    </div>
  )
}

export default Loyalty