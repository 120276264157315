import axios from 'axios';
import { Response } from '../../../../setup/axios/HttpResponse';
import { API_URL } from '../../../utils/common/constants';
import { AccountStatusModel, MonthlyReportModel, OrderStatisticModel } from '../models';

const accountStatus = {
    getAccountStatus(): Promise<Response<AccountStatusModel>> {
        const url = `${API_URL}/api/v1/home/account-status`;
        return axios.get(url);
    },
};

const monthlyStatistic = {
    getMonthlyStatistic(): Promise<Response<MonthlyReportModel>> {
        const url = `${API_URL}/api/v1/home/account-status`;
        return axios.get(url);
    },
};

const orderStatistic = {
    getOrderStatistic(): Promise<Response<OrderStatisticModel>> {
        const url = `${API_URL}/api/v1/home/account-status`;
        return axios.get(url);
    },
};

const orderCustomer = {
    getOrderCustomer(): Promise<Response<OrderStatisticModel>> {
        const url = `${API_URL}/api/v1/home/account-status`;
        return axios.get(url);
    },
};

export { accountStatus, monthlyStatistic, orderStatistic, orderCustomer };