import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap-v5'
import { t } from 'i18next'
import { useMemo } from "react"
import './style.scss'
import { useDispatch } from 'react-redux'
import { growthAndMarketingAction, selectIsOpenCreatePromoCodeModal, selectIsOpenEditPromoCodeModal, selectListPromoCodeData } from '../../redux/GrowthAndMarketingSlice'
import { selectLanguage } from 'app/modules/auth'
import { useAppSelector } from 'setup/redux/Hooks'
import { Languages } from 'app/utils/common/constants'
import DataTable, { IDataTableColumn, IDataTableStyles } from 'react-data-table-component'
import { PROMO_CODE_TYPE, PromoCodeModel } from '../../models'
import { KTSVG } from 'app/utils/helpers'
import { B8akPagination } from 'theme/layout/components/B8akPagination'
import EditPromoCodeModal from '../../modals/promo-code/EditPromoCodeModal'

type Props = {
  isLoading?: boolean
}

const PromoCode: React.FC<Props> = ({ isLoading }) => {
  const dispatch = useDispatch()
  const language = useAppSelector(selectLanguage)
  const listPromoCode = useAppSelector(selectListPromoCodeData)
  const isShowEditPromoCodeModal = useAppSelector(selectIsOpenEditPromoCodeModal)
  const isOpenCreatePromoCodeModal = useAppSelector(selectIsOpenCreatePromoCodeModal)

  const rtl = language === Languages.ar
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(6)
    
  const LoadingElement = useMemo(
    () => () => {
      return (
        <>
          <Col xs={12} className='skeleton-box h-80px rounded mb-1 mt-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
          <Col xs={12} className='skeleton-box h-80px rounded' />
        </>
      )
    },
    []
  )

  const openEditPromoCodeModal = (payload: PromoCodeModel) => {
    dispatch(growthAndMarketingAction.openEditPromoCodeModal(payload))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setRowsPerPage(newPerPage)
  }

  const customStyles: IDataTableStyles = {
    headCells: {
      style: {
        textTransform: "uppercase",
        fontSize: "14px",
        fontWeight: 500,
        backgroundColor: "var(--bs-primary)",
        height: "50px !important",
        padding: "0 !important",
      }
    },
    cells: {
      style: {
        textAlign: "center",
      }
    }
  }

  const columns: IDataTableColumn<PromoCodeModel>[] = useMemo(
    () => [
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_PROMO_CODE.PROMO_CODE'),
        cell: (cell: PromoCodeModel) => <p className='text-center'>{cell.promoCode}</p>,
        width: 'calc(100%*1/5)',
        sortable: false,
      },
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_PROMO_CODE.DISCOUNT_AMOUNT'),
        cell: (cell: PromoCodeModel) => (
          <p>
            {cell.promoCodeType === PROMO_CODE_TYPE.PERCENT
              ? `${cell.discountAmount}%`
              : `${cell.discountAmount} ${rtl ? 'ريال' : 'SR'}`}
          </p>
        ),
        width: 'calc(100%*1/5)',
        sortable: false,
      },
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_PROMO_CODE.MAXIMUM_AMOUNT'),
        cell: (cell: PromoCodeModel) => (
          <p>
            {cell.maxDiscountAmount ? (
              `${cell.maxDiscountAmount} ${rtl ? 'ريال ' : ' SR'}`
            ) : (
              <KTSVG defaultColor={true} path='/assets/images/icons/infinity.svg' />
            )}
          </p>
        ),
        width: 'calc(100%*1/5)',
        sortable: false,
      },
      {
        name: t('GROWTH_AND_MARKETING_PAGE.TABLE_PROMO_CODE.ACTIVE'),
        cell: (cell: PromoCodeModel) => (
          <div className='flex-grow-1'>
            <Form.Check
              checked={cell.isActive}
              className='growth-marketing-switcher d-flex justify-content-center'
              type='switch'
              onChange={() => {
                dispatch(
                  growthAndMarketingAction.changeStatusPromoCode({
                    id: cell.id,
                    status: !cell.isActive,
                  })
                )
              }}
            />
          </div>
        ),
        width: 'calc(100%*1/5)',
        sortable: false,
      },
      {
        name: '',
        cell: (cell: PromoCodeModel) => (
          <div className='flex-grow-1'>
            <Button
              className='btn-edit-row-promo-code'
              variant='light'
              onClick={() => openEditPromoCodeModal(cell)}
            >
              <KTSVG defaultColor={true} path='/assets/images/icons/edit-new.svg' />
            </Button>
          </div>
        ),
        width: 'calc(100%*1/5)',
        sortable: false,
      },
    ],
    [rtl, dispatch, openEditPromoCodeModal]
  )

  return (
    <div>
      <Row className='px-5'>
        <Col xs={12} className='d-flex justify-content-end p-0 mt-5 mb-5'>
          <Col xs={3} className='ms-2' style={{display: 'contents'}}>
            <Button
              variant='dark'
              className='fs-14 px-5 w-100 btn-create-promo-code'
              disabled={isLoading}
              onClick={() => {
                dispatch(growthAndMarketingAction.openCreatePromoCodeModal())
              }}
            >
              {isLoading && (
                <div
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></div>
              )}
              {t('GROWTH_AND_MARKETING_PAGE.BTN_CREATE_PROMO_CODE')}
            </Button>
          </Col>
        </Col>
        <div className='promo-code-table'>
          <DataTable
            columns={columns}
            data={listPromoCode}
            customStyles={customStyles}
            responsive
            fixedHeader
            noHeader
            selectableRows={false}
            pagination
            paginationDefaultPage={page}
            paginationTotalRows={listPromoCode.length}
            paginationPerPage={rowsPerPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationComponent={(e: any) => (
              <>
                <B8akPagination event={e} enabledPageSize={false} enabledStat={false} />
              </>
            )}
            noDataComponent={
              <div className='text-center my-10'>{t('GROWTH_AND_MARKETING_PAGE.NO_DATA')}</div>
            }
          />
        </div>
      </Row>
      {isShowEditPromoCodeModal && <EditPromoCodeModal isShow={isShowEditPromoCodeModal} isEditMode={true}
        onHide={() => dispatch(growthAndMarketingAction.hideEditPromoCodeModal())}/>}

      {isOpenCreatePromoCodeModal && <EditPromoCodeModal isShow={isOpenCreatePromoCodeModal} isEditMode={false}
        onHide={() => dispatch(growthAndMarketingAction.hideCreatePromoCodeModal())}/>}
    </div>
  )
}

export default PromoCode
