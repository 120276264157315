import React, { useCallback, useMemo, useState } from 'react'
import { Button, Col, Form, OverlayTrigger, Pagination, Row, Tooltip } from 'react-bootstrap-v5'
import { t } from 'i18next';
import "./style.scss"
import { CreatePackageForMajorServiceCentroRequestBody, PackageWorkflowServiceModel, ServiceManagementPackageInfoModel, ServiceManagementPackageModel, UpdatePackageForMajorServiceCentroRequestBody } from '../../models';
import { KTSVG } from 'app/utils/helpers/components/KTSVG';
import AddPackageOffcanvas from './components/package-offcanvas/AddPackageOffCanvas';
import DataTable, { IDataTableColumn, IDataTableStyles } from 'react-data-table-component';
import { B8akPagination } from 'theme/layout/components/B8akPagination';
import { _sleep } from 'app/utils/helpers/extendMethod';
import _ from 'lodash';
import EditPackageOffcanvas from './components/package-offcanvas/EditPackageOffCanvas';
import { selectLanguage } from 'app/modules/auth';
import { Languages } from 'app/utils/common/constants';
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks';
import { serviceManagementAction } from '../../redux/ServiceManagementSlice';
import { authActions } from 'app/modules/auth/redux/AuthSlice'

interface Props {
  selectedService: PackageWorkflowServiceModel | null
  listPackage: ServiceManagementPackageInfoModel[],
  setListPackage: (data: ServiceManagementPackageInfoModel[]) => void,
  selectedPackage: ServiceManagementPackageInfoModel | null,
  setSelectedPackage: (data: ServiceManagementPackageInfoModel) => void,
  isLoading: boolean,
  setIsLoading: (state: boolean) => void,
  reloadListPackage: () => void
}
const PackageComponent = (props: Props) => {
  const { selectedService, selectedPackage, setSelectedPackage, listPackage, setListPackage, isLoading, setIsLoading, reloadListPackage } = props

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width };
  };

  const dispatch = useAppDispatch()
  const viewPort = useViewport();
  const language = useAppSelector(selectLanguage)
  const rtlMode = language === Languages.ar

  const [openPackageOffcanvas, setOpenPackageOffcanvas] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [isSwitching, setIsSwitching] = useState<boolean>(false)
  const [editPackageData, setEditPackageData] = useState<ServiceManagementPackageInfoModel | null>(null)
  const handleHideCreatePackageOffcanvas = () => setOpenPackageOffcanvas(false)
  const handleOpenCreatePackageOffcanvas = () => setOpenPackageOffcanvas(true)
  const handleOpenEditPackageOffcanvas = (data: ServiceManagementPackageInfoModel) => setEditPackageData(data)
  const handleHideEditPackageOffcanvas = () => setEditPackageData(null)

  const callbackChangeActiveState = useCallback((packageId: number, list: ServiceManagementPackageInfoModel[], res: boolean | null, err: any) => {
    setIsLoading(false)
    if (err || !res) return
    const newListPackage = _.cloneDeep(list).map(item => {
      item.isActive = item.packageId === packageId ? !item.isActive : item.isActive;
      return item
    })
    setListPackage(newListPackage)
    setIsSwitching(false)
  }, [setIsLoading, setListPackage])

  const handleSwitchChange = useCallback((packageId: number, list: ServiceManagementPackageInfoModel[]) => {
    const targetItem = _.cloneDeep(list).find(item => item.packageId === packageId)
    if (!targetItem) return
    setIsSwitching(true)
    dispatch(serviceManagementAction.updatePackageStatusForMajorServiceCentro({
      callback: (res: boolean | null, err: any) => callbackChangeActiveState(packageId, list, res, err), body: {
        packageId,
        isPublished: !targetItem.isActive
      }
    }))
  }, [callbackChangeActiveState])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setRowsPerPage(newPerPage)
  }

  const onAddPackageCallback = (res: boolean | null, err: any) => {
    setIsLoading(false)
    if (err || !res) return
    handleHideCreatePackageOffcanvas()
    reloadListPackage()
    dispatch(authActions.getServiceScope());
  }

  const onAddPackage = (data: ServiceManagementPackageModel) => {
    if (!selectedService?.id || !selectedService?.packageWorkFlowId) {
      return
    }
    setIsLoading(true)
    const listBusFiltered = _.flatten(_.cloneDeep(data.buses).map(item => item.buses)).filter(bus => Boolean(bus.isSelected) || Boolean(bus.isOldData))
    const cityLinkBusDetailes = listBusFiltered.map(bus => ({
      busId: bus.id,
      packageServicePriceSetDetail: Boolean(bus.packageServicePriceSetDetail?.packagePrice) ? bus.packageServicePriceSetDetail : null
    }))
    const body: CreatePackageForMajorServiceCentroRequestBody = {
      uniqueNameAr: data.packageInfo.subServiceNameAr,
      uniqueNameEn: data.packageInfo.subServiceNameEn,
      packageWorkFlowId: selectedService.packageWorkFlowId,
      packageNameAr: data.packageInfo.packageNameAr,
      packageNameEn: data.packageInfo.packageNameEn,
      numberOfPackageWeeks: data.packageInfo.numberOfPackageWeeks,
      numberOfWeeklyVisits: data.packageInfo.numberOfWeeklyVisits,
      visitDuration: data.packageInfo.visitDuration,
      shift: data.packageInfo.shiftType || 0,
      numberOfWorkers: data.packageInfo.numberOfWorker,
      isShowNumberOfWorker: data.packageInfo.isShowNumberOfWorker,
      rescheduleLimit: data.packageInfo.rescheduleLimit,
      majorServiceId: selectedService.id,
      cityLinkBusDetailes,
      packageServicePriceSetDefault: data.packageServicePriceSetDefault,
      status: data.packageInfo.isActive ? 1 : 0,
    }
    dispatch(serviceManagementAction.createPackageForMajorServiceCentro({ callback: onAddPackageCallback, body }))
  }

  const onUpdatePackageCallback = (res: boolean | null, err: any) => {
    setIsLoading(false)
    if (err || !res) return
    handleHideEditPackageOffcanvas()
    reloadListPackage()
  }

  const onUpdatePackage = (data: ServiceManagementPackageModel) => {
    if (!selectedService?.id || !selectedService?.packageWorkFlowId) {
      return
    }
    setIsLoading(true)
    const listBusFiltered = _.flatten(_.cloneDeep(data.buses).map(item => item.buses)).filter(bus => Boolean(bus.isSelected) || Boolean(bus.isOldData) || bus.isLinkedPackage)
    const cityLinkBusDetailes = listBusFiltered.map(bus => {
      if (!Boolean(bus.isSelected)) {
        return {
          busId: bus.id,
          isDeleteSp: true,
          packageServicePriceSetDetail: null
        }
      }
      if (!Boolean(bus.isOldData)) {
        return {
          busId: bus.id,
          packageServicePriceSetDetail: (Boolean(bus.isSelected) && Boolean(bus.packageServicePriceSetDetail?.packagePrice)) ? bus.packageServicePriceSetDetail : null
        }
      }
      return {
        busId: bus.id,
        isDeletePrice: !Boolean(bus.packageServicePriceSetDetail?.packagePrice),
        packageServicePriceSetDetail: Boolean(bus.packageServicePriceSetDetail?.packagePrice) ? bus.packageServicePriceSetDetail : null
      }
    })
    const body: UpdatePackageForMajorServiceCentroRequestBody = {
      packageId: data.packageInfo.packageId,
      uniqueNameAr: data.packageInfo.subServiceNameAr,
      uniqueNameEn: data.packageInfo.subServiceNameEn,
      packageWorkFlowId: selectedService.packageWorkFlowId,
      packageNameAr: data.packageInfo.packageNameAr,
      packageNameEn: data.packageInfo.packageNameEn,
      numberOfPackageWeeks: data.packageInfo.numberOfPackageWeeks,
      numberOfWeeklyVisits: data.packageInfo.numberOfWeeklyVisits,
      visitDuration: data.packageInfo.visitDuration,
      shift: data.packageInfo.shiftType || 0,
      numberOfWorkers: data.packageInfo.numberOfWorker,
      isShowNumberOfWorker: data.packageInfo.isShowNumberOfWorker,
      rescheduleLimit: data.packageInfo.rescheduleLimit,
      majorServiceId: selectedService.id,
      cityLinkBusDetailes,
      packageServicePriceSetDefault: data.packageServicePriceSetDefault,
      status: data.packageInfo.isActive ? 1 : 0,
    }
    dispatch(serviceManagementAction.updatePackageForMajorServiceCentro({ callback: onUpdatePackageCallback, body }))
  }

  const customStyles: IDataTableStyles = {
    headCells: {
      style: {
        textTransform: "uppercase",
        fontSize: "14px",
        fontWeight: 500,
        backgroundColor: "var(--bs-primary)",
        height: "50px !important",
        padding: "0 !important",
      }
    }
  }
  const LoadingElement = useMemo(() => () => {
    return (<Row className='m-0 service-management-package-table--loading'>
      <Col xs={12}>
        <Col xs={12} className='skeleton-box h-80px rounded mb-1 mt-1' />
        <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
        <Col xs={12} className='skeleton-box h-80px rounded mb-1' />
        <Col xs={12} className='skeleton-box h-80px rounded' />
      </Col>
    </Row>)
  }, [])

  const columns: IDataTableColumn<ServiceManagementPackageInfoModel>[] = useMemo(() => ([
    {
      name: "",
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <></>
      ),
      width: "calc(100%*1/12)",
      sortable: false,
    },
    {
      name: t('SERVICE_MANAGEMENT_PAGE.PACKAGE.PACKAGE_NAME_IN_ARABIC'),
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip id={cell.workFolowId.toString()} dir='rtl'>
                {cell.packageNameAr}
              </Tooltip>
            }
          >
            <p dir={rtlMode ? "auto" : "rtl"} style={{ textAlign: rtlMode ? undefined : 'end', top: "calc(50% - 15px)" }}>{cell.packageNameAr}</p>
          </OverlayTrigger>
          <p dir={rtlMode ? "auto" : "rtl"} style={{ textAlign: rtlMode ? undefined : 'end', fontSize: "12px", color: "#71787B", top: "calc(50% + 5px)" }}>{cell.subServiceNameAr}</p>
        </>
      ),
      width: "calc(100%*2/12)",
      sortable: false,
    },
    {
      name: t('SERVICE_MANAGEMENT_PAGE.PACKAGE.PACKAGE_NAME_IN_ENGLISH'),
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <>
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip id={cell.workFolowId.toString()}>
                {cell.packageNameEn}
              </Tooltip>
            }
          >
            <p style={{ top: "calc(50% - 15px)" }}>{cell.packageNameEn}</p>
          </OverlayTrigger>
          <p style={{ fontSize: "12px", color: "#71787B", top: "calc(50% + 5px)" }}>{cell.subServiceNameEn}</p>
        </>

      ),
      width: "calc(100%*2/12)",
      sortable: false,
    },
    {
      name: t('SERVICE_MANAGEMENT_PAGE.PACKAGE.NUMBER_OF_VISITS'),
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <p className='text-center'>{cell.numberOfWeeklyVisits * cell.numberOfPackageWeeks}</p>
      ),
      width: "calc(100%*2/12)",
      sortable: false,
    },
    {
      name: t('SERVICE_MANAGEMENT_PAGE.PACKAGE.SHIFTS'),
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <p>{(!Boolean(cell.shiftType) ? t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ALL") : (cell.shiftType === 2 ? t("SERVICE_MANAGEMENT_PAGE.PACKAGE.EVENING") : t("SERVICE_MANAGEMENT_PAGE.PACKAGE.MORNING")))}</p>
      ),
      width: "calc(100%*1/12)",
      sortable: false,
    },
    {
      name: t('SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE'),
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <p>{`${(cell.packagePriceWithVat).toFixed(2)} ${t("SAR")}`} </p>
      ),
      width: "calc(100%*1/12)",
      sortable: false,
    },
    {
      name: t('SERVICE_MANAGEMENT_PAGE.PACKAGE.ACTIVE'),
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <Form.Check
          // disabled={isLoading || isSwitching}
          checked={cell.isActive}
          onChange={() => handleSwitchChange(cell.packageId, listPackage)}
          className='service-management-package-switcher d-flex justify-content-center'
          type='switch'
        />
      ),
      width: "calc(100%*1/12)",
      sortable: false,
    },
    {
      name: "",
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <Button
          variant='light'
          // disabled={isLoading || isSwitching}
          onClick={() => handleOpenEditPackageOffcanvas(_.cloneDeep(cell))}
        >
          <KTSVG defaultColor={true} path='/assets/images/icons/pencil.svg' />
        </Button>
      ),
      width: "calc(100%*1/12)",
      sortable: false,
    },
    {
      name: "",
      cell: (cell: ServiceManagementPackageInfoModel) => (
        <></>
      ),
      width: "calc(100%*1/12)",
      sortable: false,
    },
  ]), [rtlMode, handleSwitchChange, listPackage])

  const TableHeader = (<>
    <Row className='service-management-package-table--header m-0'>
      <Col xs={12} className='service-management-package-table--header--bg-package-list'>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.TITLE')}</Col>
    </Row>
    {/* <Row className='service-management-package-table--header m-0'>
      <Col xs={1}></Col>
      <Col xs={2}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.PACKAGE_NAME_IN_ARABIC')}</Col>
      <Col xs={2}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.PACKAGE_NAME_IN_ENGLISH')}</Col>
      <Col xs={2}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.NUMBER_OF_VISITS')}</Col>
      <Col xs={1}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.SHIFTS')}</Col>
      <Col xs={1}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE')}</Col>
      <Col xs={1}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.ACTIVE')}</Col>
      <Col xs={1}></Col>
      <Col xs={1}></Col>
    </Row> */}
  </>)

  const TableBody = (isLoading ? (
    <LoadingElement />
  ) : (<Row className='service-management-package-table--body m-0 mb-3'>
    <DataTable
      columns={columns}
      data={listPackage}
      customStyles={customStyles}
      responsive
      fixedHeader
      noHeader
      selectableRows={false}
      pagination
      paginationDefaultPage={page}
      paginationTotalRows={listPackage.length}
      paginationPerPage={rowsPerPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      /* @ts-ignore */
      paginationComponent={(e: any) => <><B8akPagination event={e} enabledPageSize={false} enabledStat={false} /></>}
      /* @ts-ignore */
      noDataComponent={<div className='text-center my-10'>{t('SERVICE_MANAGEMENT_PAGE.NO_DATA')}</div>}
    />
  </Row>))

  return (<>
    {openPackageOffcanvas && <AddPackageOffcanvas isShow={openPackageOffcanvas} onHide={handleHideCreatePackageOffcanvas} onAddPackage={onAddPackage} isLoading={isLoading} />}
    {Boolean(editPackageData) && <EditPackageOffcanvas packageData={editPackageData} isShow={Boolean(editPackageData)} onHide={handleHideEditPackageOffcanvas} onUpdatePackage={onUpdatePackage} isLoading={isLoading} />}
    <Col xs={12} className='d-flex justify-content-end px-3'>
      <Col xs={12} md={2}>
        <Button disabled={!Boolean(selectedService)} variant='dark' onClick={handleOpenCreatePackageOffcanvas} className='w-100 h-50px'>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.ADD_PACKAGE')}</Button>
      </Col>
    </Col>
    <Col xs={12}>
      <Col xs={12} className='mt-6 service-management-package-table rounded'>
        {TableHeader}
        {TableBody}
      </Col >
    </Col>
  </>)
}

export default PackageComponent