import React from 'react'
type Props = {
    className?: string,
}

const Skeleton: React.FC<Props> = ({ className }) => {
    return (
        <div className='d-flex'>
            <div className='skeleton-box w-50px h-50px me-3 rounded'></div>
            <div className='skeleton-box w-50 h-50px rounded'></div>
        </div>
    )
}

export default Skeleton
