import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Completed: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='m-20 mb-5 text-center w-100'>
      <img
        src='/assets/images/pages/order/success.png' alt='success'
      />
      <h3 className='mt-10'>{t('WARRANTY_MODAL.WARRANTY_COMPLETE')}</h3>
    </div>

  )
}

export { Completed }
