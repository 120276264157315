export enum TicketType {
    NEW = 0,
    WARRANTY = 1,
    OFFER = 2,
    WARRANTY_OFFER = 3,
    BIDDING_SERVICE = 4,
    WARRANTY_BIDDING = 5,
    COMMERCE = 6,
    SUBSCRIPTION = 7
  
  }