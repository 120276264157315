import { useStateCallback } from "app/utils/hooks/useStateCallback";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Modal as ModalBT, } from 'react-bootstrap-v5';
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { ModalProps as IModalPropsBT } from 'react-overlays/Modal'

declare let window: any;

export type IPropsModal<TData = any> = {
    element?: HTMLElement,
    refer?: React.MutableRefObject<IRefModal<TData> | undefined>,
    initShow?: boolean,
    title?: React.ReactNode,
    footer?: React.ReactNode,
    message?: React.ReactNode,
    children?: React.ReactNode,
    className?: string | string[],
    closeButton?: boolean;
    render?: (data?: TData) => React.ReactNode,
} & IModalPropsBT

export interface IRefModal<TData = any> {
    toggle: (flag?: boolean) => void;
    toggleRender: (flag?: boolean, data?: Partial<TData>) => void;
}

export const useModal = <T,>(): [React.MutableRefObject<IRefModal<T> | undefined>, IRefModal<T>] => {
    const ref = useRef<IRefModal<T>>()
    return [ref, {
        toggle: (flag?: boolean, callback?: () => void) => ref.current?.toggle(flag),
        toggleRender: (flag?: boolean, data?: Partial<T>) => ref.current?.toggleRender(flag, data),
        //   toggleLoading: (flag?: boolean) => ref.current?.toggleLoading(flag),
        //   setTitle: (title: string) => ref.current?.setTitle(title),
    }]
}

const _BaseReactModal = <T,>(props: IPropsModal<T>, ref: React.ForwardedRef<IRefModal<T>>) => {
    const {
        element,
        refer,
        initShow = false,
        title,
        message,
        children,
        className = [],
        closeButton = true,
        render,
        onHide: _onHide,
        footer,
        ...rest
    } = props;

    const [isOpen, setIsOpen] = useStateCallback(initShow);
    const [data, setData] = useState<T>();

    useEffect(() => {
        // if (document.body.className?.includes('modal-open'))
        //     document.body.style.setProperty('overflow', 'hidden', 'important')
        // return () => {
        //     setTimeout(() => {
        //         if (!document.body.className?.includes('modal-open'))
        //             document.body.style.setProperty('overflow', 'auto', 'important')
        //     }, 200);
        // }
    }, [])

    const refProperty = () => ({
        toggle,
        toggleRender
    })
    useImperativeHandle(ref, refProperty);

    const toggle = (flag?: boolean) => {
        flag = flag == null ? !isOpen : flag;
        setIsOpen(flag);

        (flag === false && onHide != null) && onHide();
        if (!flag && element) {
            unmountComponentAtNode(element);
            element?.parentNode?.removeChild(element);
        }
        
        setTimeout(() => {
            if (flag) {
                if (window.getComputedStyle(document.body, null).overflow !== 'hidden')
                    document.body.style.setProperty('overflow', 'hidden');
            } else if (!document.body.className?.includes('modal-open')) {
                document.body.style.overflow = '';
            }
        }, 200);
    };
    const toggleRender = (flag?: boolean, _data?: Partial<T>) => {
        flag = flag == null ? !isOpen : flag;
        setIsOpen(flag, () => setData({ ...data, ..._data } as any));
    };

    const onHide = () => {
        _onHide && _onHide()
    }

    let _children = render != null ? (data === undefined ? render(undefined) : render(data)) : message == null ? children : message;

    return (
        <ModalBT
            ref={(e: any) => {
                refer != null && (refer.current = refProperty())
            }}
            className={typeof className == 'string' ? className : className.join(' ')}
            show={isOpen}
            onHide={() => toggle(false)}
            animation={true}
            {...rest}
        >
            {typeof title == 'string' ?
                <ModalBT.Header closeButton={closeButton}>
                    {<b>{title}</b>}
                </ModalBT.Header>
                : title
            }
            <ModalBT.Body>
                {_children == null ? null : _children.constructor == String ? <p>{_children}</p> : _children}
            </ModalBT.Body>
            {footer != null &&
                <ModalBT.Footer>
                    {typeof footer == 'string' ? <p>{footer}</p> : footer}
                </ModalBT.Footer>
            }
        </ModalBT >
    )
};
export const Modal = React.forwardRef(_BaseReactModal) as <T>(
    props: IPropsModal<T> & { ref?: React.ForwardedRef<IRefModal<T>> }
) => ReturnType<typeof _BaseReactModal>

const ConfirmDialog = ({
    title = "Warning",
    message = "Are you sure you want to delete?",
    ok = () => { },
    cancel = () => { },
    btnOkText = "OK",
    btnCancelText = "Cancel"
} = {}) => {
    return new Promise((resolve, _) => {
        let element = document.createElement("div");
        document.body.appendChild(element);

        const refModal = React.createRef<IRefModal>();

        ReactDOM.render(
            <Modal
                ref={refModal}
                {...{
                    element,
                    initShow: true,
                    title,
                    message: (<p>{message}</p>),
                    footer: (
                        <div className="d-flex justify-content-end">
                            <button className="btn button text-dark" onClick={() => refModal.current != null && (refModal.current.toggle(), cancel(), resolve(false))}>{btnCancelText}</button>
                            <button className="btn button btn--gradient" onClick={() => refModal.current != null && (refModal.current.toggle(), ok(), resolve(true))}>{btnOkText}</button>
                        </div>
                    )
                }}
            />,
            element
        );
    });
};

const AlertDialog = ({ title = "Alert", message = "message", ok = () => { }, btnOkText = "OK", ...rest } = {}) => {
    let element = document.createElement("div");
    document.body.appendChild(element);

    const refModal = React.createRef<IRefModal>();
    return new Promise((resolve, _) => {
        ReactDOM.render(
            <Modal
                ref={refModal}
                {...{
                    ...rest,
                    element,
                    initShow: true,
                    title,
                    message: (<p>{message}</p>),
                    footer: (
                        <div className="d-flex justify-content-end">
                            <button className="btn button btn--gradient" onClick={() => refModal.current != null && (refModal.current.toggle(), ok(), resolve(true))}>{btnOkText}</button>
                        </div>
                    )
                }}
            />,
            element
        );
    });
};

const ModalDialog = (props: IPropsModal) => {
    let element = document.createElement("div");
    document.body?.appendChild(element);

    const ref = React.createRef<IRefModal>();
    ReactDOM.render(
        <Modal
            ref={ref}
            {...{
                initShow: true,
                ...props,
                element
            }}
        />,
        element
    );
    return ref;
};

window.ConfirmDialog = ConfirmDialog;
window.AlertDialog = AlertDialog;
window.ModalDialog = ModalDialog;
export { ConfirmDialog, AlertDialog, ModalDialog };

