import React, { useState } from 'react'
import { IPropsRadio, Radio } from './Radio';
import { FieldProps, Field, FieldConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap-v5';
import { classNameFunc, IClassName } from './utils';

export interface IPropsRadioGroup<T = any> {
    options: T[]
    optionLabel?: (e: T) => string;
    optionKey?: (e: T) => string | number;
    optionName?: (e: T) => string;
    propsRadio?: IPropsRadio;
    onChange?: (value: string | number) => void;
    name?: string,
    renderRadio?: (props: {
        value?: number | string,
        onChange: (value: number | string) => void,
        error?: string,
        Radio: { [name: string]: { value: number | string, item: T, component: React.ReactNode } }
    }) => React.ReactNode;
    classContainer?: IClassName
}
export const RadioGroup = <T,>(props: IPropsRadioGroup) => {
    const {
        options: _options,
        optionLabel = e => '',
        optionKey = e => '',
        optionName = e => undefined,
        propsRadio,
        onChange: _onChange = (selected) => { },
        name,
        renderRadio,
        classContainer,

        //formik
        field,
        form,
        ...rest
    } = props as IPropsRadioGroup<T> & FieldProps;

    const [selected, setSelected] = useState<number | string | undefined>(form != undefined ? field.value : undefined)


    const { t } = useTranslation();

    const touched = form?.touched[field.name];
    let _error = form?.errors[field.name];
    let [error, params = ''] = ![null, undefined, ''].includes(_error as any) ? String(_error).split('-') : [_error, '']
    error = ![null, undefined, ''].includes(error as any) ? t(error as string).format(params.split(';')) : error
    const isInvalid = touched === true && error != undefined

    const onChange = (value?: number | string) => {
        value != undefined && _onChange(value);
        setSelected(value);
        form != undefined && form.setFieldValue(field.name, value)
    }

    const options = Object.fromEntries(_options.map((e, index) => {
        const label = optionLabel(e);
        const value = optionKey(e);
        const nameC = optionName(e) || 'Radio' + (index + 1);
        return [
            nameC,
            {
                value,
                item: e,
                component:
                    React.createElement(
                        Radio,
                        {
                            ...propsRadio,
                            key: index,
                            label: label,
                            checked: value == selected,
                            name: name,
                            value: value,
                            // selected: value == selected,
                            // onPress: () => onChange(value)
                            onChange: () => onChange(value),
                            ...form != undefined && { name: field.name, }
                        }
                    )
            }
        ]
    }))

    const render = () => {
        if (renderRadio == null)
            return Object.keys(options).map((k, index) => {
                return options[k].component;
            });
        return renderRadio({
            value: selected,
            onChange: onChange,
            error: '',
            Radio: options
        });
    }
    return (
        <div className={classNameFunc([isInvalid && 'has-error', classContainer])}>
            {render()}
            {isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
        </div>
    )
}

export type IPropsFieldRadioGroup<T> = IPropsRadioGroup<T> & FieldConfig
export const FieldRadioGroup = <T,>(props: IPropsFieldRadioGroup<T>) => <Field component={RadioGroup} {...props} />;