/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Response } from '../../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { SelectDateTime } from '../../../../../theme/partials/content/steps/select-date-time/SelectDateTime';
import { SelectTeam } from '../../../../../theme/partials/content/steps/team-select/SelectTeam';
import { clientTimeZoneOffset } from '../../../../utils/common/constants';
import { TicketType } from '../../../../utils/common/enum';
import {
    AvailableTimeRequest,
    AvailableTimeResponse,
    CheckSpJoinOfferResponse,
    SpJoinOfferRequest,
    SpServiceAvailable,
    SpServiceAvailableRequest,
    SubSubServiceScopes,
    TimeSlot,
} from '../../../../utils/common/models';
import { selectLanguage, selectUser } from '../../../auth';
import { ReassignRequestModel, TicketModel } from '../../models';
import { ticketApi } from '../../redux/TicketApi';
import { selectTicketSpTeam } from '../../redux/TicketSlice';
import { Completed } from './steps/Completed';

type Props = {
    className?: string,
    data?: TicketModel | null,
    isShow: boolean,
    onHide?: () => void,
    onReassigned?: (request: ReassignRequestModel) => void,
}

interface ReassignForm {
    timeSlotIndex: string,
    spTeam: string,
}
const initReassignForm = {
    timeSlotIndex: '',
    spTeam: '',
} as ReassignForm



const ReassignTicketModal: React.FC<Props> = ({ className, data, isShow, onHide, onReassigned }) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const { t } = useTranslation();

    const reassignSchemas = [
        Yup.object({
            timeSlotIndex: Yup.string().required(t('REASSIGN_MODAL.TIME_REQUIRED')).min(1),
        }),
        Yup.object({
            spTeam: Yup.string().required(t('REASSIGN_MODAL.TEAM_REQUIRED')).min(1),
        })
    ]

    const language = useAppSelector(selectLanguage);
    const rtlMode = language === 'ar';

    const [currentSchema, setCurrentSchema] = useState(reassignSchemas[0]);
    const [isSubmitButton, setSubmitButton] = useState(false);
    const [isPreselect, setIsPreselect] = useState(true);
    const [isLoadingDateTime, setLoadingDateTime] = useState(true);
    const [isLoadingSpTeam, setLoadingSpTeam] = useState(false);
    const [isLoadingReassign, setLoadingReassign] = useState(false);
    const [dataDateTime, setDataDateTime] = useState<AvailableTimeResponse | null>(null);
    const [spTeamAvailable, setSpTeamAvailable] = useState<SpServiceAvailable[]>([]);
    const [timeSlotSelected, setTimeSlotSelected] = useState<TimeSlot | null>(null);
    const [messageErrorChangeDate, setMessageErrorChangeDate] = useState<string>('')

    const [dateSelected, setDateSelected] = useState(new Date());
    const user = useAppSelector(selectUser);
    const spTeams = useAppSelector(selectTicketSpTeam);

    const handleChangeDate = (date: Date) => {
        setDataDateTime(null);
        setLoadingDateTime(true);
        setDateSelected(date);

        let request = {
            dateCheck: date,
            id: data?.id,
            clientTimeZone: clientTimeZoneOffset,
            isPreselect: isPreselect,
        } as AvailableTimeRequest

        ticketApi.getReassignAvailableTime(request).then((response: Response<AvailableTimeResponse>) => {
            if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                if (response.data) {
                    setDataDateTime(response.data);
                    setDateSelected(new Date(response.data.selectedDate));
                    setIsPreselect(false);
                    setMessageErrorChangeDate(response.message)
                }
            } else {
                toast.error(response.message, ToastDefaultConfig());
            }
        }).finally(() => { setLoadingDateTime(false); });
    };

    const handleGotoSelectTeam = (timeSlotIndex: number) => {
        let shoppingCart = JSON.parse(data?.shoppingCartJson || '{}');
        setSpTeamAvailable([]);
        let timeSlot = dataDateTime?.timeSlots[timeSlotIndex] as TimeSlot;
        setTimeSlotSelected(timeSlot);


        if (data?.packageId || data?.ticketType === TicketType.OFFER || data?.ticketType === TicketType.WARRANTY_OFFER) {
            let spTeamAvailable = [] as SpServiceAvailable[];
            if (data?.ticketType === TicketType.OFFER || data?.ticketType === TicketType.WARRANTY_OFFER) {
                const requestCheckSPJoinOffer = {
                    offerId: data.offerId,
                    spIds: [data?.spId]
                } as SpJoinOfferRequest
                    ticketApi.checkSpJoinOffer(requestCheckSPJoinOffer).then((response: Response<CheckSpJoinOfferResponse[]>) => {
                        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                            timeSlot?.spIds.forEach(spId => {
                                let sp = spTeams.find(x => x.id === spId);
                                let checkSpJoinOffer = response.data?.find(item => item.spId === spId);
                                let spInfo = {
                                    spId: spId,
                                    fullNameAr: sp?.fullNameAr,
                                    fullNameEn: sp?.fullNameEn,
                                    isOffer: true,
                                    isSpJoinOffer: checkSpJoinOffer?.isAvailable,
                                } as SpServiceAvailable
                                spTeamAvailable.push(spInfo);
                            });
                            setSpTeamAvailable(spTeamAvailable);
                        }
                    })
                    return;
                }
            timeSlot?.spIds.forEach(spId => {
                let sp = spTeams.find(x => x.id === spId);
                let spInfo = {
                    spId: spId,
                    fullNameAr: sp?.fullNameAr,
                    fullNameEn: sp?.fullNameEn,
                    isOffer: true,
                    isSpJoinOffer: true,
                } as SpServiceAvailable
                spTeamAvailable.push(spInfo);
            });
            setSpTeamAvailable(spTeamAvailable);

        } else {
            let litSubSub = [] as number[];
            shoppingCart.subServices?.forEach((sub: any) => {
                if (sub.subSubServices) {
                    sub.subSubServices.forEach((subSub: any) => {
                        litSubSub.push(subSub.id);
                    });
                }
            });

            litSubSub = Array.from(new Set(litSubSub));

            if (shoppingCart) {
                let request = {
                    spIds: timeSlot?.spIds,
                    subSubIdsSelected: litSubSub,
                } as SpServiceAvailableRequest
                setLoadingSpTeam(true);
                ticketApi.getSpAvailable(request).then((response: Response<SpServiceAvailable[]>) => {
                    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                        if (response.data) {
                            for (let index = 0; index < response.data.length; index++) {
                                let servicesAvailable = response.data[index].subSubServiceScopes.filter((x: SubSubServiceScopes) => x.isAvailable);
                                let servicesUnavailable = response.data[index].subSubServiceScopes.filter((x: SubSubServiceScopes) => !x.isAvailable);
                                response.data[index].isAvailableAll = servicesAvailable.length === litSubSub.length;
    
                                if (rtlMode) {
                                    response.data[index].servicesAvailable = servicesAvailable.map((x: SubSubServiceScopes) => x.subSubNameAr).join(', ');
                                    response.data[index].servicesUnavailable = servicesUnavailable.map((x: SubSubServiceScopes) => x.subSubNameAr).join(', ');
                                } else {
                                    response.data[index].servicesAvailable = servicesAvailable.map((x: SubSubServiceScopes) => x.subSubNameEn).join(', ');
                                    response.data[index].servicesUnavailable = servicesUnavailable.map((x: SubSubServiceScopes) => x.subSubNameEn).join(', ');
                                }
                            }

                            setSpTeamAvailable(response.data);
                        }
                    } else {
                        toast.error(response.message, ToastDefaultConfig());
                    }
                }).finally(() => { setLoadingSpTeam(false); });

            }
        }

    }

    const handleSubmitData = (spTeamIndex: number) => {
        let spTeam = spTeamAvailable[spTeamIndex] as SpServiceAvailable;
        let request = {
            id: data?.id || '',
            clientTimeZone: clientTimeZoneOffset,
            durationTime: timeSlotSelected ? timeSlotSelected?.end - timeSlotSelected?.start : 0,
            spId: spTeam?.spId || '',
            visitTimeOffset: moment(dateSelected).startOf('day').add(timeSlotSelected?.start || 0, 'minutes').toDate()
        } as ReassignRequestModel;

        setLoadingReassign(true);

        ticketApi.reassignTicket(request).then((response: Response<boolean>) => {
            if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                if (response.data) {
                    stepper?.current?.goNext();
                    setSubmitButton(true);
                    onReassigned?.(request);
                }
            } else {
                toast.error(response.message, ToastDefaultConfig());
            }
        }).finally(() => { setLoadingReassign(false); });
    }

    const loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        setSubmitButton((stepper.current.currentStepIndex - 1) === stepper.current.totalStepsNumber)
        stepper.current.goPrev();
        setCurrentSchema(reassignSchemas[stepper.current.currentStepIndex - 1]);
    }

    const nextStep = (values: ReassignForm) => {
        console.log(values);
        if (!stepper.current) {
            return
        }


        setCurrentSchema(reassignSchemas[stepper.current.currentStepIndex])
        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {

            switch (stepper.current.currentStepIndex) {
                case 1:
                    setSubmitButton(false);
                    stepper.current.goNext();
                    handleGotoSelectTeam(parseInt(values.timeSlotIndex));
                    break;
                case 2:
                    handleSubmitData(parseInt(values.spTeam));
                    break;
            }
        } else {
            handleClose();
        }
    }

    useEffect(() => {
        if (!isShow) return;

        if (!stepperRef.current) {
            return;
        }
        resetData();
        loadStepper();
        handleChangeDate(new Date());
    }, [stepperRef, isShow])

    const handleClose = () => {
        resetData();
        onHide?.();
    };

    const resetData = () => {
        stepper?.current?.goto(1);
        setCurrentSchema(reassignSchemas[0]);
        setSubmitButton(false);
        setIsPreselect(true);
        setLoadingDateTime(false);
        setLoadingSpTeam(false);
        setDataDateTime(null);
        setSpTeamAvailable([]);
        setTimeSlotSelected(null);
        setDateSelected(new Date());
    }

    return (
        <>
            <Modal show={isShow} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton className='header-primary'>
                    <Modal.Title className='fw-normal'>{t('REASSIGN_MODAL.TITLE')}</Modal.Title>
                </Modal.Header>

                <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                >
                    <div className='d-none'>
                        <div data-kt-stepper-element='nav'>
                        </div>
                        <div data-kt-stepper-element='nav'>
                        </div>
                        <div data-kt-stepper-element='nav'>
                        </div>
                    </div>

                    <Formik validationSchema={currentSchema} initialValues={initReassignForm} onSubmit={(values) => { nextStep(values) }} >
                        {({ handleSubmit, errors, touched, resetForm, isValid, values }) => (
                            <form id='kt_create_account_form'>
                                <div className='current' data-kt-stepper-element='content'>
                                    <SelectDateTime
                                        dateSelected={dateSelected}
                                        timeSlots={dataDateTime?.timeSlots}
                                        messageErrorChangeDate={messageErrorChangeDate}
                                        customerVisitDuration={dataDateTime?.customerVisitDuration || 0}
                                        isLoading={isLoadingDateTime}
                                        onSelectedDate={(data: Date) => { handleChangeDate(data); resetForm(); }}
                                        rtl={rtlMode}
                                        indexSelected={values.timeSlotIndex}
                                    />

                                </div>

                                <div data-kt-stepper-element='content'>
                                    <SelectTeam data={spTeamAvailable} isLoading={isLoadingSpTeam} companyImage={user?.companyLogo} rtl={rtlMode} valueName='spTeam'/>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <Completed />
                                </div>

                                {!isSubmitButton ? <div className='d-flex flex-stack p-5 assign-footer'>

                                    <div className='mr-2'>
                                        {stepper?.current?.currentStepIndex === 1 &&
                                            <button
                                                onClick={handleClose}
                                                type='button'
                                                className='btn btn-secondary w-150px me-3 btn-back'>
                                                {t('BACK')}
                                            </button>
                                        }
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-secondary w-150px me-3 btn-back'
                                            data-kt-stepper-action='previous'
                                        >
                                            {t('BACK')}
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {errors.timeSlotIndex && touched.timeSlotIndex ? (
                                            <div className='w-100 text-danger'>{errors.timeSlotIndex}</div>
                                        ) : null}
                                        {errors.spTeam && touched.spTeam ? (
                                            <div className='w-100 text-danger'>{errors.spTeam}</div>
                                        ) : null}
                                    </div>

                                    <div>

                                        <button type='button' onClick={() => handleSubmit()} className='btn btn-dark w-150px me-3' disabled={isLoadingReassign || !isValid}>
                                            {isLoadingReassign && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                            <span className='indicator-label'> {stepper?.current?.currentStepIndex && stepper.current.currentStepIndex === 2 ? t('REASSIGN_MODAL.REASSIGN') : t('NEXT')}</span>

                                        </button>
                                    </div>
                                </div>
                                    : <div className="text-center mb-20">
                                        <button type='button' onClick={() => handleSubmit()} className='btn btn-dark w-150px'>
                                            <span className='indicator-label'> {t('FINISH')} </span>
                                        </button>
                                    </div>
                                }
                            </form>
                        )}
                    </Formik>
                </div>

            </Modal>
        </>
    )
}

export { ReassignTicketModal }
