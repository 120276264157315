import './style.scss';
import 'moment-duration-format';
import 'react-datepicker/dist/react-datepicker.css';

import { Field } from 'formik';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap-v5';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { Languages } from '../../../../../app/utils/common/constants';
import { TimeSlot } from '../../../../../app/utils/common/models';
import { minuteToTime } from '../../../../../app/utils/services';


type Props = {
    onSelectedDate?: (value: Date) => void,
    customerVisitDuration: number,
    dateSelected: Date,
    messageErrorChangeDate?: string;
    timeSlots?: TimeSlot[],
    isLoading?: boolean,
    rtl?: boolean,
    isPackage?: boolean,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void,
    indexSelected?: string,
}
const SelectDateTime: React.FC<Props> = ({ dateSelected, timeSlots: times, messageErrorChangeDate, isLoading, customerVisitDuration, rtl, isPackage, indexSelected, onSelectedDate, setFieldValue }) => {
    const { t } = useTranslation();
    const [timeSlot, setTimeSlot] = useState<TimeSlot| null>(null)
    const handleChangeDate = (date: Date) => {
        onSelectedDate?.(date);
        setFieldValue?.('timeSlotIndex', '');
        setTimeSlot(null);
    }

    const checkWorkerBusySelectTime = () => {
        if(!timeSlot) return false;
        return timeSlot?.isWorkerBusy && timeSlot?.isAvailable;
    }

    return (
        <Row className='date-time-select m-auto'>
            <Col sm={12}>
                <h4 className='ms-9 my-8 mb-5'>{t('SELECT_DATE_COMPONENT.TITLE')}</h4>
            </Col>
            <Col sm={12} md={8} className="p-0">
                <DatePicker selected={dateSelected} onChange={(date: Date) => handleChangeDate(date)} inline minDate={new Date()}
                />
            </Col>
            <Col sm={12} md={4} className="p-0 time-selection">
                {isLoading ?
                    <div className='text-center'><div className='spinner-border spinner-border' role="status"></div></div>
                    :
                    <>
                        {messageErrorChangeDate && <div className='text-danger text-center'>{messageErrorChangeDate}</div>}
                        {!messageErrorChangeDate && times && times.length > 0 && !times.every(x => !x.isAvailable) ?
                            <>
                                {times.map((time: TimeSlot, index: number) => {
                                    return <div className={`time-selection__item${time.isAvailable ? '' : '--disabled'} ${index.toString() == indexSelected ? ' active' : ''}`} key={index} onClick={() => setTimeSlot(time)}>
                                        <label className='d-flex flex-stack mb-0 cursor-pointer px-4 h-50px border'>
                                            <span>{minuteToTime(time.start, true, true, (rtl ? Languages.ar : Languages.en))} - {minuteToTime(time.start + customerVisitDuration, true, true, (rtl ? Languages.ar : Languages.en))}</span>
                                            {time?.spCapacity && time?.isAvailable &&
                                                <span className={`mx-2 capacity-used ${time?.spCapacityUsed >= time?.spCapacity ? 'color-red' : 'color-green'}`}>({time?.spCapacityUsed}/{time?.spCapacity})</span>
                                            }
                                            <span className='form-check form-check-custom form-check-solid'>
                                                <Field className='form-check-input' type='radio' name='timeSlotIndex' value={index.toString()} disabled={!time.isAvailable} />
                                            </span>
                                        </label>
                                    </div>
                                })}
                               {checkWorkerBusySelectTime() && <div className='text-center mt-3 text-danger'>{t('RESCHEDULE_PACKAGE_MODAL.BUSY_OF_TIMESLOT')}</div>}
                            </>
                            :
                            <>
                                {!messageErrorChangeDate && <div className='text-center'>{t('RESCHEDULE_PACKAGE_MODAL.NO_TIME_SELECT')}</div>}
                            </>
                            
                        }
                    </>
                }
            </Col>
        </Row>
    )
}

export { SelectDateTime }
