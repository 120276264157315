import {FC, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { selectErrorDomain } from '../../../auth';
import './style.scss'

const ErrorDomain: FC = () => {
    const history = useHistory();
    const isErrorDomain = useAppSelector(selectErrorDomain);
  
    useEffect(() => {
        if (!isErrorDomain) {
        history.push('/')
        }
    }, [])
    return <div id="wrap" className="container">
    <div className="wrapper-error-domain">
        <div className="wrapper-shadow">
            <div className="no-helpdesk">
                {/* <img src="/assets/images/common/b8ak logo.png" alt="B8ak Support" width="204px" height="50px" /> */}
                <h2 className='h2'>  We couldn't find  <span id="domainname">{window.location.origin}</span></h2>
                <p className='p'> May be this is still fresh!  </p> 
                <p className='p'>You can claim it now at <a href="https://www.b8ak.com/" target="_blank" rel="nofollow" className='a'>https://www.b8ak.com</a></p>
            </div>
        </div>
    </div>
</div>
}
export {ErrorDomain};