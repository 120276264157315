/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import DateTimeSection from './component/DateTimeSection'
import {
  RescheduleRequestModel,
  TicketModel,
  TicketPackageDetail,
  WorkersAssigned,
} from '../../models'
import { CompletedReschedulePackage } from './step/CompletedReschedulePackage'
import ServiceProvider from './component/ServiceProvider'
import { selectLanguage } from 'app/modules/auth'
import { useAppSelector } from 'setup/redux/Hooks'
import './style.scss'
import { ReschedulePackageForm } from './type'
import { TimeSlotPackage } from 'app/utils/common/models'
import { ticketApi } from '../../redux/TicketApi'
import {
  ticketActions,
  selectParamSearchAttendanceDelivery
} from '../../redux/TicketSlice'
import { useDispatch } from 'react-redux'
import {
  clientTimeZoneOffset,
  Languages,
  loadingType,
  DEFAULT_WORKER,
} from 'app/utils/common/constants'
import moment from 'moment'
import { minuteToTime } from 'app/utils/services'
import { toast } from 'react-toastify'
import { ToastDefaultConfig } from 'setup/toast/ToastConfig'
import { HTTP_RESPONSE_STATUS } from 'setup/constants/Http'
type Props = {
  className?: string
  data: TicketModel | null
  ticketPackage?: TicketPackageDetail
  isShow?: boolean
  onBack?: () => void
  updateRescheduleTimeline?: (request: RescheduleRequestModel) => void
  onHide?: () => void
  finishSuccess?: () => void
  onSchedulePackageUpdated: (
    request: RescheduleRequestModel,
    ticketWorkers: WorkersAssigned
  ) => void
  rtl?: boolean
}

const initRescheduleForm = {
  date_time: undefined,
  service_provider: DEFAULT_WORKER,
  service_provider_filter: {
    sortBy: 1,
    filterBy: 0,
  },
  loadingType: loadingType.SERVICE_PROVIDER,
  favWorkerIds: [] as string[],
  number_of_workers: 0,
  isShowDeliveryTime: false,
  visitReminder: '',
  packageShift: 0,
  newSpId: '',
} as ReschedulePackageForm

const ReschedulePackage: React.FC<Props> = ({
  isShow,
  data,
  ticketPackage,
  onBack,
  onHide,
  finishSuccess,
  updateRescheduleTimeline,
  onSchedulePackageUpdated,
}) => {
  const { t } = useTranslation()
  const language = useAppSelector(selectLanguage)
  const dispatch = useDispatch()
  const paramAttendanceDelivery = useAppSelector(selectParamSearchAttendanceDelivery)

  const rescheduleSchema = Yup.object({
    date_time: Yup.array(
      Yup.object().shape({
        date: Yup.date().required('CREATE_ORDER_DRAWER.SECTION_3.VALID.DATE'),
        time: Yup.object().when('date', {
          is: (
            values: NonNullable<{
              date?: Date
              time?: TimeSlotPackage
              timeSlot?: TimeSlotPackage[]
            }>['date']
          ) => values !== undefined,
          then: (schema) => schema.required(t('CREATE_ORDER_DRAWER.SECTION_3.VALID.TIME')),
        }),
      })
    )
      .required('CREATE_ORDER_DRAWER.SECTION_3.VALID.DATE')
      .min(1, t('CREATE_ORDER_DRAWER.SECTION_3.VALID.DATE')),
  })

  const [isLoadingReschedule, setLoadingReschedule] = useState(false)
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false)
  const [isRescheduleLimitStatus, setIsRescheduleLimitStatus] = useState(false)

  useEffect(() => {
    if (!isShow) return
    resetData()
    if (ticketPackage) {
      if (
        ticketPackage.packageDetails.rescheduleCount >= ticketPackage.packageDetails.rescheduleLimit
      ) {
        setIsRescheduleLimitStatus(true)
      } else {
        setIsRescheduleLimitStatus(false)
      }
    }
  }, [isShow])

  const resetData = () => {
    setLoadingReschedule(false)
    setIsShowModalSuccess(false)
  }

  const submittedReschedule = async (values: ReschedulePackageForm) => {
    if (values.date_time && values.date_time.length > 0) {
      setLoadingReschedule(true)
      const selectedDate = values.date_time[0]
      let favWorkerAdd = [] as string[]
      let favWorkerRemove = [] as string[]
      values.service_provider_available.forEach((el) => {
        if (el.isFavoriteWorker) {
          favWorkerAdd.push(el.workerId)
        } else {
          favWorkerRemove.push(el.workerId)
        }
      })
      const requestBody = {
        id: data?.id,
        clientTimeZone: -clientTimeZoneOffset,
        scheduledVisitDateTime: `${moment(selectedDate.date)
          .locale('en-US')
          .format('MM/DD/YYYY')} ${minuteToTime(
            selectedDate?.time?.start || 0,
            true,
            true,
            Languages.en
          )} ${moment(selectedDate.date).locale('en-US').format('Z')}`,
        scheduledVisitEndDateTime:
          selectedDate?.time?.end === 1440
            ? `${moment(selectedDate.date).add(1, 'days').locale('en-US').format('MM/DD/YYYY')} ${minuteToTime(
                0,
                true,
                true,
                Languages.en
              )} ${moment(selectedDate.date).locale('en-US').format('Z')}`
            : `${moment(selectedDate.date).locale('en-US').format('MM/DD/YYYY')} ${minuteToTime(
                selectedDate?.time?.end || 0,
                true,
                true,
                Languages.en
              )} ${moment(selectedDate.date).locale('en-US').format('Z')}`,
        newSpId: values.newSpId,
        newWorkerId:
          values.service_provider && values.service_provider !== DEFAULT_WORKER
            ? values.service_provider
            : '',
        customerFavWorkerIdAdd: favWorkerAdd,
        customerFavWorkerIdRemove: favWorkerRemove,
      } as RescheduleRequestModel
      try {
        const resp = await ticketApi.rescheduleTicket(requestBody)
        if (resp.status !== HTTP_RESPONSE_STATUS.SUCCESS) {
          toast.error(resp.message, ToastDefaultConfig())
          return
        }
        dispatch(ticketActions.getAttendanceDelivery(paramAttendanceDelivery))
        setIsShowModalSuccess(true)
        updateRescheduleTimeline?.(requestBody)
        onSchedulePackageUpdated(requestBody, resp.data)
        setLoadingReschedule(false)
      } catch (error) {
        toast.error(`${t('RESCHEDULE_TICKET.UNEXPECTED_ERROR')}`, ToastDefaultConfig())
        setLoadingReschedule(false)
        console.log(error)
      }
    }
  }

  if (!isShow || !data) return <></>
  return (
    <>
      <Modal
        show={isShow && !isShowModalSuccess}
        onHide={onHide}
        backdrop='static'
        keyboard={false}
        fullscreen='sm-down'
        dialogClassName='modal-reschedule'
      >
        <Modal.Header closeButton className='header-primary'>
          <Modal.Title className='fw-normal'>{t('RESCHEDULE_PACKAGE_MODAL.TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='modal-title-description'>
            <Modal.Title className='fw-normal'>{t('RESCHEDULE_TICKET.TITLE')}</Modal.Title>
          </p>
          {isRescheduleLimitStatus && (
            <p className='reschedule-limit--status text-danger'>
              {t('RESCHEDULE_TICKET.RESCHEDULE_STATUS')}
            </p>
          )}
          <div className='reschedule-content'>
            <Formik
              validationSchema={rescheduleSchema}
              initialValues={initRescheduleForm}
              validateOnChange
              onSubmit={(values) => {
                submittedReschedule(values)
              }}
            >
              {(props) => {
                const { handleSubmit, setValues, resetForm, values, errors } = props
                return (
                  <Form onSubmit={handleSubmit} onReset={() => resetForm()}>
                    <Accordion
                      alwaysOpen
                      defaultActiveKey={['date_time--RS', 'service-provider--RS']}
                    >
                      <ServiceProvider
                        index={1}
                        id='service-provider--RS'
                        name='service_provider'
                        values={values}
                        errors={errors}
                        setValues={setValues}
                        language={language}
                        ticketData={data}
                      />
                      <DateTimeSection
                        ticketId={data.id}
                        number_visit={1}
                        number_week={1}
                        scheduledVisitDateTime={data.scheduledVisitDateTime}
                        scheduledVisitEndDateTime={data.scheduledVisitEndDateTime}
                        form={props}
                        language={language}
                      />
                    </Accordion>

                    <div className='d-flex flex-stack p-5 assign-footer'>
                      <div className='mr-2'>
                        <button
                          onClick={onBack}
                          type='button'
                          className='btn btn-secondary w-150px me-3 btn-back-rs'
                          data-kt-stepper-action='previous'
                        >
                          {t('BACK')}
                        </button>
                      </div>
                      <div>
                        <button
                          type='button'
                          onClick={() => handleSubmit()}
                          className='btn btn-dark w-150px me-3 btn-submitted'
                          disabled={isLoadingReschedule || !values.defaultServiceProvider}
                        >
                          {isLoadingReschedule && (
                            <div
                              className='spinner-border spinner-border-sm me-2'
                              role='status'
                              aria-hidden='true'
                            ></div>
                          )}
                          <span className='indicator-label'>{t('SAVE')}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal Step success */}
      <Modal show={isShowModalSuccess} backdrop='static' keyboard={false} className='modal-success'>
        <Modal.Body>
          <CompletedReschedulePackage finishSuccess={finishSuccess} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { ReschedulePackage }
