import { DateHighLighted } from "app/modules/tickets/drawer/components/type";
import { TSelectedPackageItem } from "app/modules/tickets/drawer/package-flow/DateTimeComponent/type";
import { DatePackageAvailable, PackageInfo, Worker } from "app/modules/tickets/models/OrderDrawerModel";
import { TimeSlot, TimeSlotPackage } from "../common/models";
import { TSelectedItem } from "./components/Form";
import moment from 'moment'
import {Languages} from '../common/constants'

export const transformPackageInfo = (packageInfo: PackageInfo[]) => {
    return packageInfo.map(s => ({
        id: s.id,
        titleAr: s.titleAr,
        titleEn: s.titleEn,
        packagePrice: s.packagePrice,
        packagePriceWithVat: s.packagePriceWithVat,
        percentVAT: s.percentVAT,
        multiWorker: s.multiWorker,
        numberOfWeeks: s.numberOfWeeks,
        numberOfVisitPerWeek: s.numberOfVisitPerWeek,
        preSelectSP: s.preSelectSP,
        hasUnratedWorker: s.hasUnratedWorker,
        workers: s.workers,
        packageServicePriceSetId: s.packageServicePriceSetId || null,
        packageServicePriceSetDetailId: s.packageServicePriceSetDetailId || null,
        isRefund: s.isRefund,
        reScheduleLimit: s.reScheduleLimit || null,
        isShowDeliveryTime: s.isShowDeliveryTime,
        subServiceId: s.subServiceId,
        numberOfVisitRecurrence: s.numberOfVisitRecurrence
    }))
}

export const highlightDateProcess = (dayAvailable: DatePackageAvailable[], intervalDay: number, intervalWeek: number) => {
    const availableDate: DateHighLighted[] = []
    if (intervalDay === 1 && intervalWeek > 1) {
        const startDay = dayAvailable[0]
        if (startDay) {
            for (let i = 0; i < intervalWeek; i++) {
                const numberDate = new Date(startDay.dateCheck).getDate()
                availableDate.push({ date: new Date(new Date(startDay.dateCheck).setDate(numberDate + i * 7)), timeSlots: startDay.listTimes || [] })
            }
        }
    } else {
        dayAvailable.forEach(
            (day: DatePackageAvailable) =>
                day.isAvailable &&
                availableDate.push({ date: new Date(day.dateCheck), timeSlots: day.listTimes || [] })
        )
    }
    return availableDate
}

export const highlightDateSeveralVist = (selectedDates: TSelectedItem[] | undefined, choosenDate: Date) => {
    let highlightDates = new Array()
    if (selectedDates && selectedDates.length !== 0) {
        let firstDate = selectedDates[0].date
        let currentDate = choosenDate
        if (firstDate.getTime() > choosenDate.getTime()) {
            while (currentDate.getTime() < firstDate.getTime()) {
                highlightDates.push({ date: currentDate, timeSlots: [] })
                currentDate = currentDate.addDays(1)
            }
        } else {
            while (firstDate.getTime() < currentDate.getTime()) {
                highlightDates.push({ date: firstDate, timeSlots: [] })
                firstDate = firstDate.addDays(1)
            }
        }

    }
    highlightDates.push({ date: choosenDate, timeSlots: [] })
    return highlightDates
}

export const activeDateProcess = (dayAvailable: DateHighLighted[], minDate: Date) => { // Add dates after selected date in several visit case
    let arrayDates = new Array()
    let currentDate = minDate
    while (currentDate.getTime() < dayAvailable[0].date.getTime()) {
        arrayDates.push({ date: currentDate, timeSlots: [] })
        currentDate = currentDate.addDays(1)
    }

    return arrayDates.concat(dayAvailable)
}
export const transformWorkerData = (data: Worker[]) => {
    return data.map(s => {

        const startDate = s.listAvailableDay?.find(item => item.isAvailable)?.dateCheck

        return {
            workerId: s.workerId,
            spId:s.spId,
            workerNameAr: s.workerNameAr,
            workerNameEn: s.workerNameEn,
            startDate,
            isAuto: s.isAuto || false,
            personalImageUrl: s.personalImageUrl,
            listAvailableDay: s.listAvailableDay,
            isFavoriteWorker: s.isFavoriteWorker,
            isLastCompleted: s.isLastCompleted,
            isBaseOnAvailable: s.isBaseOnAvailable,
            visitReminder: s.visitReminder
        }
    })
}

export const generateDateSelectedSingleVisitPerWeek = (choosenDate: Date, selectedDate: TSelectedItem[], intervalWeek: number, timeSlots: TimeSlot[] | TimeSlotPackage[] | any) => {
    let result: TSelectedItem[] = []

    let newSelectedDate: TSelectedItem[] = []
    for (let i = 0; i < intervalWeek; i++) {
        newSelectedDate.push({ date: choosenDate.addDays(7 * i), time: undefined, customerVisitDuration: 0, timeSlot: timeSlots, related: i > 0 })
    }

    if (newSelectedDate && newSelectedDate.length === 0) {
        result = newSelectedDate
    } else {
        const id = selectedDate.findIndex(e => e.date.isSame(choosenDate))
        if (id < 0) {
            result = newSelectedDate
        } else {
            result = selectedDate
        }
    }
    return result
}

export const getDateSelectedSingleVisitPerWeek = (choosenDate: Date, selectedDate: TSelectedPackageItem[], intervalWeek: number, timeSlots: TimeSlot[] | TimeSlotPackage[] | any, initTime: TimeSlotPackage | undefined) => {
    let result: TSelectedPackageItem[] = []

    let newSelectedDate: TSelectedPackageItem[] = []
    for (let i = 0; i < intervalWeek; i++) {
        newSelectedDate.push({ date: choosenDate.addDays(7 * i), time: initTime, timeSlot: timeSlots, related: i > 0 ? choosenDate : undefined })
    }

    if (newSelectedDate && newSelectedDate.length === 0) {
        result = newSelectedDate
    } else {
        const id = selectedDate.findIndex(e => e.date.isSame(choosenDate))
        if (id < 0) {
            result = newSelectedDate
        } else {
            result = selectedDate
        }
    }
    return result
}

export const highlightDateSeveralVistPackage = (firstDate: Date, choosenDate: Date) => {
    let highlightDates = new Array()
    let currentDate = choosenDate
    if (firstDate.getTime() > choosenDate.getTime()) {
        while (currentDate.getTime() < firstDate.getTime()) {
            highlightDates.push({ date: currentDate, timeSlots: [] })
            currentDate = currentDate.addDays(1)
        }
    } else {
        while (firstDate.getTime() < currentDate.getTime()) {
            highlightDates.push({ date: firstDate, timeSlots: [] })
            firstDate = firstDate.addDays(1)
        }
    }
    highlightDates.push({ date: choosenDate, timeSlots: [] })
    return highlightDates
}

export const formatDateByLocale = (date: Date | undefined, lang: string, format: string) => moment(date || new Date()).locale(lang === Languages.ar ? 'ar-SA' : 'en-US').format(format)
