import { selectLanguage } from 'app/modules/auth'
import { Languages } from 'app/utils/common/constants'
import { KTSVG, handleWheelInputNumber } from 'app/utils/helpers'
import React from 'react'
import { Search } from 'react-bootstrap-icons'
import { Col, Form, InputGroup, Row } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'setup/redux/Hooks'
interface Props {
  formik: any,
  handleChangeNumberField: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, needChangeValue?: boolean) => void,
  handleBlurNumberField: React.FocusEventHandler<HTMLInputElement>
}
const PackageInfo = (props: Props) => {
  const { formik, handleBlurNumberField, handleChangeNumberField } = props
  const { t } = useTranslation()
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;
  const { handleChange, handleBlur, setFieldValue, touched, values } = formik

  return (
    <Row className='create-package-offcanvas--package-info'>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.UNIQUE_NAME_IN_ARABIC")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/subtitle.svg' /></InputGroup.Text>
          <Form.Control required type="text" name='packageInfo.subServiceNameAr' value={values.packageInfo.subServiceNameAr} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_UNIQUE_NAME_IN_ARABIC") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.UNIQUE_NAME_IN_ENGLISH")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/subtitle.svg' /></InputGroup.Text>
          <Form.Control required type="text" name='packageInfo.subServiceNameEn' value={values.packageInfo.subServiceNameEn} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_UNIQUE_NAME_IN_ENGLISH") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PACKAGE_NAME_IN_ARABIC")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/subtitle.svg' /></InputGroup.Text>
          <Form.Control required type="text" name='packageInfo.packageNameAr' value={values.packageInfo.packageNameAr} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_PACKAGE_NAME_IN_ARABIC") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PACKAGE_NAME_IN_ENGLISH")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/subtitle.svg' /></InputGroup.Text>
          <Form.Control required type="text" name='packageInfo.packageNameEn' value={values.packageInfo.packageNameEn} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_PACKAGE_NAME_IN_ENGLISH") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.NUMBER_OF_PACKAGE_WEEKS")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/clock.svg' /></InputGroup.Text>
          <Form.Control required type={"text"}
            name='packageInfo.numberOfPackageWeeks' value={(!touched?.packageInfo?.numberOfPackageWeeks && !Boolean(values.packageInfo.numberOfPackageWeeks)) ? undefined : values.packageInfo.numberOfPackageWeeks}
            step={1} min={0} onChange={handleChangeNumberField} onBlur={handleBlurNumberField}
            onWheel={handleWheelInputNumber}
            placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_NUMBER_OF_PACKAGE_WEEKS") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.NUMBER_OF_WEEKLY_VISITS")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/place.svg' /></InputGroup.Text>
          <Form.Control required type={"text"}
            name='packageInfo.numberOfWeeklyVisits' value={(!touched?.packageInfo?.numberOfWeeklyVisits && !Boolean(values.packageInfo.numberOfWeeklyVisits)) ? undefined : values.packageInfo.numberOfWeeklyVisits}
            step={1} min={0} onChange={handleChangeNumberField} onBlur={handleBlurNumberField}
            onWheel={handleWheelInputNumber}
            placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_NUMBER_OF_WEEKLY_VISITS") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.VISIT_DURATION")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/clock.svg' /></InputGroup.Text>
          <Form.Control required type={"text"}
            name='packageInfo.visitDuration' value={(!touched?.packageInfo?.visitDuration && !Boolean(values.packageInfo.visitDuration)) ? undefined : values.packageInfo.visitDuration}
            step={1} min={0} onChange={handleChangeNumberField} onBlur={handleBlurNumberField}
            onWheel={handleWheelInputNumber}
            placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_VISIT_DURATION") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.SHIFT")}</Form.Label>
          <InputGroup.Text><Search width={16} height={12} color="#000000" /></InputGroup.Text>
          <Form.Select name='packageInfo.shiftType' value={values.packageInfo.shiftType || undefined} onChange={(e) => setFieldValue("packageInfo.shiftType", e.currentTarget.value.toLocaleLowerCase() === "all" ? null : parseInt(e.currentTarget.value))} onBlur={handleBlur} className='h-35px' >
            <option value={undefined}>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ALL")}</option>
            <option value="1">{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.MORNING")}</option>
            <option value="2">{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.EVENING")}</option>
          </Form.Select>
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.NUMBER_OF_WORKER")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/user.svg' /></InputGroup.Text>
          <Form.Control type={"text"}
            name='packageInfo.numberOfWorker' value={(!touched?.packageInfo?.numberOfWorker && !Boolean(values.packageInfo.numberOfWorker)) ? undefined : values.packageInfo.numberOfWorker}
            step={1} min={0} onChange={handleChangeNumberField} onBlur={handleBlurNumberField}
            onWheel={handleWheelInputNumber}
            placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_NUMBER_OF_WORKER") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-4">
        <Form.Check
          className={`create-package-offcanvas-checkbox${rtlMode ? "--rtl" : ""}`}
          label={t('SERVICE_MANAGEMENT_PAGE.PACKAGE.MAKE_IT_AS_FIXED')}
          checked={values.packageInfo.isShowNumberOfWorker}
          type="checkbox"
          name='packageInfo.isShowNumberOfWorker'
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <div className='fs-12 m-0' style={{ fontWeight: 500 }}><span style={{ color: "#E31C2A", fontWeight: 500 }}>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.NOTE')}</span>&nbsp;{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.MAKE_IT_AS_FIXED_NOTE')}</div>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.RESCHEDULE_LIMIT")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/reschedule.svg' /></InputGroup.Text>
          <Form.Control type={"text"}
            name='packageInfo.rescheduleLimit' value={(!touched?.packageInfo?.rescheduleLimit && !Boolean(values.packageInfo.rescheduleLimit !== -1)) ? undefined : values.packageInfo.rescheduleLimit}
            step={1} min={0} onChange={handleChangeNumberField} onBlur={handleBlurNumberField}
            onWheel={handleWheelInputNumber}
            placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_RESCHEDULE_LIMIT") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <Form.Check
          checked={values.packageInfo.isActive}
          name='packageInfo.isActive'
          onBlur={handleBlur}
          onChange={handleChange}
          className={`create-package-offcanvas-switcher${rtlMode ? "--rtl" : ""} create-package-offcanvas-switcher-label-left${rtlMode ? "--rtl" : ""} ps-4`}
          label={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ACTIVE")}
          type="switch"
        />
      </Col>
    </Row>
  )
}

export default PackageInfo