import moment from "moment";

declare global {
    interface String {
        format(...args: any[]): string;
        toNumber(...args: any[]): number;
        toDate(format?: string): Date;
        reverse(): string;
    }
}

if (!String.prototype.format)
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined' ?
                args[number] :
                match;
        });
    };
if (!String.prototype.toNumber)
    String.prototype.toNumber = function () {
        return Number(this);
    };

if (!String.prototype.toDate)
    String.prototype.toDate = function (format?: string) {
        format = format == null ? 'MM-DD-YYYY' : format;
        return moment(this.toString(), format).toDate()
    };

if (!String.prototype.reverse)
    String.prototype.reverse = function () {
        return this.split("").reverse().join("");
    }
export { }