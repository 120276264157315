import './style.scss';

import { Field, FieldArray, Formik, FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, FormGroup, FormLabel, InputGroup, Modal, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { selectCurrency, selectUser, selectUserMetaData } from '../../../auth';
import { CompleteRequestModel, SparePart, TicketModel } from '../../models';
import { toast } from 'react-toastify';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';

type Props = {
    className?: string,
    data?: TicketModel | null,
    isShow: boolean,
    isLoading?: boolean,
    onHide?: () => void,
    onSubmit?: (data: CompleteRequestModel) => void,
}

const CompleteTicketModal: React.FC<Props> = ({ className, data, isShow, isLoading, onHide, onSubmit }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isShow || !data) return;
        setTotalPrice(0);
        setCurrentCustomerWallet(0);
        setVatAmount(0);
        setDiscountAmount(0);
        if (data.promoCode) {
            setDiscountCode(data.promoCode + ' | ' + data.discountValue + " " + (data.discountType != 1 ? shoppingCart.currency : "%") + " off");
        }
    }, [isShow]);

    const userMetaData = useAppSelector(selectUserMetaData);
    const user = useAppSelector(selectUser);
    const currency = useAppSelector(selectCurrency);

    const [totalPrice, setTotalPrice] = useState(0);
    const [currentCustomerWallet, setCurrentCustomerWallet] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountCode, setDiscountCode] = useState('');


    const DisputeSchema = Yup.object().shape({
        serviceFee: Yup.number().required(t('COMPLETE_MODAL.SERVICE_FEE_REQUIRED'))
    });

    const shoppingCart = useMemo(() => {
        try {
            return JSON.parse(data?.shoppingCartJson || '{}')
        } catch (err) {

            return {};
        }
    }, [data?.shoppingCartJson])

    const vatValue = user?.isShowVat ? userMetaData?.vatValue : 0;
    const deliveryFee = shoppingCart?.delivery?.fee || 0;

    const initialValues = {
        serviceFee: undefined,
        parts: [] as SparePart[],
        comments: '',
    };

    const form = useRef<FormikProps<{ serviceFee: number | undefined, parts: SparePart[], comments: string }>>(null);

    const handleTicketPrice = () => {

        if (data) {

            let parts = [] as SparePart[];
            if (form?.current?.values.parts) {
                parts = form.current.values.parts;
            }

            let fee = 0;
            if (form?.current?.values.serviceFee) {
                fee = form.current.values.serviceFee;
            }
            let servicePart = 0;
            let discountAmount = 0;
            let deliveryFee = shoppingCart && shoppingCart.delivery ? (isNaN(shoppingCart.delivery.fee) ? 0 : parseFloat(shoppingCart.delivery.fee)) : 0;
            let vatValue = userMetaData?.vatValue || 0;

            setDiscountAmount(0);
            if (data.promoCode) {
                discountAmount = data.discountType == 1 ? (fee * data.discountValue) / 100 : data.discountValue;
                discountAmount = discountAmount <= shoppingCart.maximumDiscountAmount || shoppingCart.maximumDiscountAmount == 0 ? discountAmount : shoppingCart.maximumDiscountAmount;
                discountAmount = isNaN(discountAmount) ? 0 : discountAmount;
                setDiscountAmount(discountAmount);
            };

            for (var i = 0; i < parts.length; i++) {
                if (parts[i].price) {
                    servicePart += parts[i].price || 0;
                }
            }

            let vatAmount = user?.isShowVat ? (fee + deliveryFee - discountAmount) * (vatValue / 100) : 0;
            setVatAmount(Math.round((vatAmount) * 100) / 100);

            let ticketPrice = (servicePart + fee + deliveryFee - discountAmount) > 0 ? (servicePart + fee + deliveryFee - discountAmount) : 0;
            ticketPrice += vatAmount;

            //Check wallet
            if (shoppingCart && shoppingCart.isUsecustomerWallet) {
                let customerWalletAmount = currentCustomerWallet - ticketPrice <= 0 ? currentCustomerWallet : ticketPrice;
                ticketPrice -= customerWalletAmount;
            }

            ticketPrice = Math.round((ticketPrice) * 100) / 100;
            setTotalPrice(ticketPrice);
        }

    };

    const handleOnHide = () => {
        onHide?.();
        setTotalPrice(0);
        setCurrentCustomerWallet(0);
        setVatAmount(0);
        setDiscountAmount(0);

    }

    return (
        <>
            <Modal show={isShow} onHide={handleOnHide} backdrop="static" keyboard={false} size='lg'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={DisputeSchema}
                    onSubmit={values => {
                        if (data) {
                            // Check part 
                            if (values.parts.some(x => x.price <= 0 || x.quantity <= 0)) {
                                toast.error("Spare part require part, price and quantity", ToastDefaultConfig());
                                return;
                            }
                            let request = {
                                id: data.id,
                                serviceFee: values.serviceFee || 0,
                                comments: values.comments,
                                parts: values.parts,
                                price: totalPrice,
                            } as CompleteRequestModel
                            onSubmit?.(request);
                        }
                    }}
                    innerRef={form}
                    render={({ values, errors, touched, handleReset, handleSubmit, isValid }) => {
                        return (
                            <form>
                                <Modal.Header closeButton className='header-primary'>
                                    <Modal.Title className='fw-normal'>{t('COMPLETE_MODAL.TITLE')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormGroup className="mb-3" >
                                        <FormLabel>{t('COMPLETE_MODAL.SERVICE_FEE')}</FormLabel>
                                        <InputGroup className='h-50px'>
                                            <InputGroup.Text id='provider-name'>
                                                <img src='/assets/images/pages/order/service-fee.svg'></img>
                                            </InputGroup.Text>
                                            <Field
                                                className='form-control text-center'
                                                name='serviceFee'
                                                type="number"
                                                onKeyUp={handleTicketPrice}
                                                placeholder="00"
                                            />

                                            <InputGroup.Text>{currency}</InputGroup.Text>

                                        </InputGroup>
                                        {errors.serviceFee && touched.serviceFee ? (
                                            <div className='small text-danger'>{errors.serviceFee}</div>
                                        ) : null}
                                    </FormGroup>
                                    {deliveryFee != null && deliveryFee > 0 &&
                                        <FormGroup className="mb-3" >
                                            <FormLabel>{t('COMPLETE_MODAL.DELIVERY_FEE')}</FormLabel>
                                            <Field
                                                className='form-control text-center'
                                                type="number"
                                                value={deliveryFee}
                                                disabled
                                            />
                                        </FormGroup>
                                    }

                                    {vatValue != null && vatValue > 0 &&
                                        <FormGroup className="mb-3" >
                                            <FormLabel>{t('COMPLETE_MODAL.VAT')} ({vatValue}%)</FormLabel>
                                            <Field
                                                className='form-control text-center'
                                                type="number"
                                                value={vatAmount}
                                                disabled
                                            />

                                        </FormGroup>
                                    }

                                    {data?.promoCode &&
                                        <FormGroup className="mb-3" >
                                            <FormLabel>
                                                {t('COMPLETE_MODAL.PROMO')} <span className='text-success'>({discountCode})</span>
                                            </FormLabel>
                                            <Field
                                                className='form-control text-center text-success'
                                                type="number"
                                                value={discountAmount > 0 ? `-${discountAmount}` : 0}
                                                disabled
                                            />

                                        </FormGroup>
                                    }
                                    <FieldArray
                                        name="parts"
                                        render={({ insert, remove, push, handleRemove }) => (
                                            <>
                                                {values.parts.length > 0 &&
                                                    values.parts.map((part, index) => (
                                                        <Row className="mb-3 m-auto" key={index}>
                                                            <Col sm={12} md={4} xl={5} className="ps-0">
                                                                <FormGroup className="mb-3" >
                                                                    <FormLabel>{t('COMPLETE_MODAL.PART')}</FormLabel>
                                                                    <Field
                                                                        className='form-control'
                                                                        name={`parts.${index}.part`}
                                                                        type="text"
                                                                        placeholder={t('COMPLETE_MODAL.PART')}
                                                                    />

                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={12} md={3} xl={3}>
                                                                <FormGroup className="mb-3" >
                                                                    <FormLabel>{t('COMPLETE_MODAL.QUANTITY')}</FormLabel>
                                                                    <Field
                                                                        className='form-control text-center'
                                                                        name={`parts.${index}.quantity`}
                                                                        type="number"
                                                                        placeholder='00'
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={12} md={3} xl={3}>
                                                                <FormGroup className="mb-3" >
                                                                    <FormLabel>{t('COMPLETE_MODAL.PRICE')}</FormLabel>
                                                                    <Field
                                                                        className='form-control text-center'
                                                                        name={`parts.${index}.price`}
                                                                        type="number"
                                                                        onKeyUp={handleTicketPrice}
                                                                        placeholder='00'

                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm={12} md={2} xl={1} className="d-flex mb-3 align-items-end">
                                                                <Button variant="danger" className='btn-block' onClick={() => { remove(index); setTimeout(() => { handleTicketPrice() }, 100) }}>
                                                                    <i className="fas fa-times p-0"></i>
                                                                </Button>
                                                            </Col>

                                                        </Row>
                                                    ))}
                                                <Button variant='success' onClick={() => push({ part: '', price: 0, quantity: 0 })} className='d-flex align-items-center'>
                                                    <img src='/assets/images/pages/order/add.svg' className='me-5'></img>
                                                    {t('COMPLETE_MODAL.ADD_SPARE_PART')}
                                                </Button>
                                            </>
                                        )}
                                    />
                                    {errors.parts && touched.parts ? (
                                        <div className='small text-danger'>{errors.parts}</div>
                                    ) : null}



                                </Modal.Body>
                                <Row className='px-9 py-5 border-top border-bottom m-auto fs-4'>
                                    <Col sm={12} md={6} className='ps-0'>
                                        <strong className='text-uppercase'>{t('COMPLETE_MODAL.TOTAL_PRICE')}</strong>
                                    </Col>
                                    <Col sm={12} md={6} className="text-end">
                                        <strong>{totalPrice} {currency}</strong>
                                    </Col>
                                </Row>
                                <Field as="textarea" name="comments" className="form-control border-0 px-9 py-5" rows="3" placeholder={t('COMPLETE_MODAL.COMMENT')} />
                                <Modal.Footer className='justify-content-between modal-footer-gray'>
                                    <div>
                                        <Button variant="light" className="px-10 fw-normal" onClick={onHide} disabled={isLoading}>
                                            {t('BACK')}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button variant="success" className="px-10 fw-normal" onClick={() => handleSubmit()} disabled={totalPrice <= 0 || isLoading || !isValid}>
                                            {isLoading && <span className="spinner-border w-20px h-20px me-5" role="status" aria-hidden="true"></span>}
                                            <span>{t('COMPLETE_MODAL.COMPLETE')}</span>
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </form>
                        )
                    }}
                />
            </Modal>
        </>

    )
}

export { CompleteTicketModal }
