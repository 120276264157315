import { TimeInWeek } from "../../../modules/users/models";

export const UserStatus = {
  Active: 1,
  Deactive: 0,
};

export const UserStatusColor = {
  Active: "user-active",
  Deactive: "user-deactive"
};

export const UserStatusLabel = {
  User_Active: "USERS_PAGE.USER_STATUS.ACTIVE",
  User_Deactive: "USERS_PAGE.USER_STATUS.INACTIVE",
};

export const USER_TYPE = {
  ADMIN: "400",
  DRIVER: "600",
  WORKER: "500",
  CUSTOMER: "300",
  TECHNICIAN: "200",
}

export const UserRoleLabel = {
  ADMIN: "USERS_PAGE.USER_ROLE.ADMIN",
  DRIVER: "USERS_PAGE.USER_ROLE.DRIVER",
  WORKER: "USERS_PAGE.USER_ROLE.WORKER",
  TECHNICIAN: "USERS_PAGE.USER_ROLE.TECHNICIAN",
  BUS: "USERS_PAGE.USER_ROLE.BUSES",
  SUPERVISOR: "USERS_PAGE.USER_ROLE.SUPERVISOR"
};

export const SELECT_USER_TYPE = {
  ACTIVE: "Active",
  DEACTIVE: "Deactive",
}

export const UserRoleColor = {
  Admin: "user-admin",
  Driver: "user-driver",
  Technician: "user-tech",
  Worker: "user-worker",
  Bus: "user-bus"
};

export const USER_TYPE_SELECT = [
  {
    title: "BUSES",
    value: "200"
  },
  {
    title: "SUPERVISOR",
    value: "400"
  },
  {
    title: "WORKER",
    value: "500"
  }
]

export const STEP_CREATE_USER = {
  ACCOUNT_TYPE: "ACCOUNT_TYPE",
  ACCOUNT_INFO: "ACCOUNT_INFO",
  WORK_INFO: "WORK_INFO",
  OPERATION_HOURS: "OPERATION_HOURS",
  WORK_LOCATION: "WORK_LOCATION",
}

export const STEP_CREATE_USER_ARRAY = [
  "ACCOUNT_TYPE", "FIELD_USER_INFO", "SERVICE", "VISIT_HOURS", "GEOGRAPHIC_COVERAGE"
]


export const STEP_UPDATE_TECH_USER_ARRAY = [
  "FIELD_USER_INFO", "SERVICE", "VISIT_HOURS", "GEOGRAPHIC_COVERAGE"
]

export const STEP_UPDATE_WORKER_USER_ARRAY = [
  "FIELD_USER_INFO", "SERVICE", "GEOGRAPHIC_COVERAGE"
]
export const STEP_USERS = {
  ACCOUNT_TYPE: 1,
  ACCOUNT_INFO: 2,
  WORK_INFO: 3,
  OPERATION_HOURS: 4,
  WORK_LOCATION: 5
}

export const TAB_OPERATION_HOURS = {
  REGULAR_HOURS: "Regular Hours",
  RAMADAN_HOURS: "Ramadan Hours",
  TRANSPORTATION_TIME: "Transportation time",
}

export const TRANSPORT_TIME_SELECT = [
  { label: "30 mins", value: "30" },
  { label: "60 mins", value: "60" },
  { label: "90 mins", value: "90" },
  { label: "120 mins", value: "120" },
]

export const TYPE_OPERATION_HOURS = {
  REGULAR: 1,
  RAMADAN: 2
}
export const SELECT_TIME_IN_WEEK = [
  {
    key: "Mon",
    label: "Monday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
  {
    key: "Tue",
    label: "Tuesday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
  {
    key: "Wed",
    label: "Wednesday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
  {
    key: "Thu",
    label: "Thursday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
  {
    key: "Fri",
    label: "Friday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
  {
    key: "Sat",
    label: "Saturday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
  {
    key: "Sun",
    label: "Sunday",
    value: [{ start: 360, end: 1080 }],
    isSelected: false
  },
] as TimeInWeek[]

export const WORKING_TIME_LIST = [
  { title: '00:00 AM', value: 0 },
  { title: '00:30 AM', value: 30 },
  { title: '1:00 AM', value: 60 },
  { title: '1:30 AM', value: 90 },
  { title: '2:00 AM', value: 120 },
  { title: '2:30 AM', value: 150 },
  { title: '3:00 AM', value: 180 },
  { title: '3:30 AM', value: 210 },
  { title: '4:00 AM', value: 240 },
  { title: '4:30 AM', value: 270 },
  { title: '5:00 AM', value: 300 },
  { title: '5:30 AM', value: 330 },
  { title: '6:00 AM', value: 360 },
  { title: '6:30 AM', value: 390 },
  { title: '7:00 AM', value: 420 },
  { title: '7:30 AM', value: 450 },
  { title: '8:00 AM', value: 480 },
  { title: '8:30 AM', value: 510 },
  { title: '9:00 AM', value: 540 },
  { title: '9:30 AM', value: 570 },
  { title: '10:00 AM', value: 600 },
  { title: '10:30 AM', value: 630 },
  { title: '11:00 AM', value: 660 },
  { title: '11:30 AM', value: 690 },
  { title: '12:00 PM', value: 720 },
  { title: '12:30 PM', value: 750 },
  { title: '1:00 PM', value: 780 },
  { title: '1:30 PM', value: 810 },
  { title: '2:00 PM', value: 840 },
  { title: '2:30 PM', value: 870 },
  { title: '3:00 PM', value: 900 },
  { title: '3:30 PM', value: 930 },
  { title: '4:00 PM', value: 960 },
  { title: '4:30 PM', value: 990 },
  { title: '5:00 PM', value: 1020 },
  { title: '5:30 PM', value: 1050 },
  { title: '6:00 PM', value: 1080 },
  { title: '6:30 PM', value: 1110 },
  { title: '7:00 PM', value: 1140 },
  { title: '7:30 PM', value: 1170 },
  { title: '8:00 PM', value: 1200 },
  { title: '8:30 PM', value: 1230 },
  { title: '9:00 PM', value: 1260 },
  { title: '9:30 PM', value: 1290 },
  { title: '10:00 PM', value: 1320 },
  { title: '10:30 PM', value: 1350 },
  { title: '11:00 PM', value: 1380 },
  { title: '11:30 PM', value: 1410 },
  { title: '00:00 AM', value: 1440 }
];
