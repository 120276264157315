import './DateTimeVisitTable.scss'

import { TimeSlotPackage, DateTimeVisitTableType } from './type'
import { KTSVG } from 'app/utils/helpers'
import { minuteToTime } from 'app/utils/services'
import { Col, Row, Container } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'setup/redux/Hooks'
import { selectTicketModalData } from 'app/modules/tickets/redux/TicketSlice'
import { SPAvaialble } from 'app/modules/tickets/models/OrderDrawerModel'

const DateTimeVisitTable = (props: DateTimeVisitTableType) => {
  const {
    isLoading,
    isMulti,
    isEmpty,
    lang,
    dateTimeOptions,
    onSelectTimeSlot,
    onClearSelected,
    onSelectedSp,
    listSpAvailable,
    newSpId
  } = props
  const { t } = useTranslation()
  const ticketDetail = useAppSelector(selectTicketModalData);
  const selected = isMulti ? dateTimeOptions?.filter((s) => !s.related) : dateTimeOptions

  const spIdOrg = ticketDetail?.spId;

  let listSorted = listSpAvailable.filter(s => s.spId != spIdOrg && s.listTimes && s.listTimes.find(x => x.isAvailable)).sort((a, b) => a.spName.toLowerCase() > b.spName.toLowerCase() ? 1 : -1)
  let spOrg = listSpAvailable.find(s => s.spId == spIdOrg && s.listTimes && s.listTimes.find(x => x.isAvailable));
  if (spOrg) listSorted.unshift(spOrg);

  const renderTimeSlots = (date: Date, spAvaialble: SPAvaialble) => {
    const timeSlots = spAvaialble.listTimes;

    if (timeSlots && timeSlots.length > 0) {
      let morningSlots: TimeSlotPackage[] = []
      let eveningSlots: TimeSlotPackage[] = []

      timeSlots.forEach((el, index) => {
        if (el.start >= 300 && el.start < 960) {
          //Morning Shift 5am --> 4pm
          morningSlots.push({ ...el, rawIndex: index })
        } else if (el.start < 300 || el.start >= 960) {
          // Night Shift 4pm --> 5am (of the next day)
          eveningSlots.push({ ...el, rawIndex: index })
        }
      })

      return (
        <div className='time-slot-list'>
          <div className='morning-shift--time-slot'>
            {morningSlots.length > 0 && morningSlots.map((e, index) => (
              <div className='slot-item--wrapper pt-4' key={index}>
                <button
                  key={e.rawIndex}
                  type='button'
                  className={`time-slot-item ${!e.isAvailable ? 'disabled' :
                    e.start == selected[0].time?.start && spAvaialble.spId == newSpId ? 'active' : ''} `}
                  onClick={() =>
                    e.rawIndex !== undefined && e.isAvailable &&
                    onSelectTimeSlot(date, spAvaialble.spId, timeSlots[e.rawIndex])
                  }
                >
                  {!e.isAvailable && (
                    <div className='disabled-line'>
                      <KTSVG defaultColor={true} path='/assets/images/icons/line.svg' />
                    </div>
                  )}
                  <span className='shift-icon'>
                    <KTSVG defaultColor={true} path='/assets/images/icons/morning.svg' />
                  </span>
                  <span className='time-slot-content'>
                    {`${minuteToTime(
                      e.visitReminderStart || e.start,
                      true,
                      true,
                      lang
                    )} - ${minuteToTime(e.visitReminderEnd || e.end, true, true, lang)}`}
                  </span>
                </button>
                <div className={`capacity-slot ${!e.isAvailable ? 'disable' : ''}`}>{`(${e.capacityUsed ?? 0}/${e.capacity ?? 0})`}</div>
              </div>
            ))}
          </div>
          <div className={`${lang !== 'ar' ? 'evening-shift--time-slot' : 'evening-shift--time-slot isRtl'}`}>
            {eveningSlots.length > 0 && eveningSlots.map((e, index) => (
              <div className='slot-item--wrapper pt-4' key={index}>
                <button
                  key={e.rawIndex}
                  type='button'
                  className={`time-slot-item ${!e.isAvailable ? 'disabled' : e.start == selected[0].time?.start && spAvaialble.spId == newSpId ? 'active' : ''} `}
                  onClick={() =>
                    e.rawIndex !== undefined && e.isAvailable &&
                    onSelectTimeSlot(date, spAvaialble.spId, timeSlots[e.rawIndex])
                  }
                >
                  {!e.isAvailable && (
                    <div className='disabled-line'>
                      <KTSVG defaultColor={true} path='/assets/images/icons/line.svg' />
                    </div>
                  )}
                  <span className='shift-icon'>
                    <KTSVG defaultColor={true} path='/assets/images/icons/evening.svg' />
                  </span>
                  <span className='time-slot-content'>
                    {`${minuteToTime(
                      e.visitReminderStart || e.start,
                      true,
                      true,
                      lang
                    )} - ${minuteToTime(e.visitReminderEnd || e.end, true, true, lang)}`}
                  </span>
                </button>
                <div className={`capacity-slot ${!e.isAvailable ? 'disable' : ''}`}>{`(${e.capacityUsed ?? 0}/${e.capacity ?? 0})`}</div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }

  return (
    <Container className='package-time-table reschedule-package-time'>
      <Row className='package-table-header'>
        <Col xs={4} className={`date-title ${lang === 'ar' ? 'isRTL' : ''}`}>
          {t('CREATE_ORDER_DRAWER.SECTION_3.SELECTED_BUS')}
        </Col>
        <Col xs={8} className='time-title'>
          {t('CREATE_ORDER_DRAWER.SECTION_3.SELECTED_TIME')}
        </Col>
      </Row>
      <div className='package-table-body'>
        {isLoading ? (
          <div className='d-flex h-100 justify-content-center align-items-center'>
            <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
          </div>
        ) : (
          <>
            {selected && selected[0] && listSorted && listSorted.map((sp, index) => (
              <Row className='package-table-row' key={index}>
                <Col xs={4} className={`selected-item--wrapper ${lang === 'ar' ? 'isRTL' : ''}`}>
                  <div className='time-slot-list'>
                    <div className='morning-shift--time-slot'>
                      <div className='slot-item--wrapper pt-4'>
                        <button type='button' className={`time-slot-item ${sp.spId == newSpId ? 'active' : ''}`} onClick={() => onSelectedSp(sp.spId)}>
                          <span className='time-slot-content unset-uppercase'>{sp.spName}</span>
                          {
                            sp.spId == spIdOrg && <span className='icon-org'>
                              <KTSVG defaultColor={true} path='/assets/images/icons/original.svg' />
                            </span>
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={8} className={`timeslot-items--wrapper ${lang === 'ar' ? 'isRTL' : ''}`}>
                  {renderTimeSlots(new Date(selected[0].date), sp)}
                </Col>
              </Row>
            ))
            }
            {selected && selected.length > 0 && (!listSorted || listSorted.length == 0 || isEmpty) &&
              <Row className='package-table-row'>
                <Col xs={4} className={`selected-item--wrapper ${lang === 'ar' ? 'isRTL' : ''}`}>
                  <div className='d-flex h-100 justify-content-center align-items-center pt-3 pb-3'>
                    {!isEmpty ?
                      <span>{t('CREATE_ORDER_DRAWER.SECTION_3.VALID.NO_WORKER_AVAILABLE')}</span>
                      : <span>{t('CREATE_ORDER_DRAWER.SECTION_3.VALID.NO_SP_AVAILABLE')}</span>
                    }
                  </div>
                </Col>
                <Col xs={8} className={`timeslot-items--wrapper ${lang === 'ar' ? 'isRTL' : ''}`}>
                  <div className='d-flex h-100 justify-content-center align-items-center pt-3 pb-3'>
                    <span>{t('CREATE_ORDER_DRAWER.SECTION_3.VALID.NO_TIMESLOT_AVAILABLE')}</span>
                  </div>
                </Col>
              </Row>
            }
          </>
        )}
      </div>
    </Container>
  )
}

export default DateTimeVisitTable
