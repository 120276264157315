import axios from "axios";
import { ListResponse, Response } from "../../../../setup/axios/HttpResponse";
import { API_URL } from "../../../utils/common/constants";
import { _convertObjectToQuery } from "../../../utils/helpers";
import { UserModel } from "../../users/models";
import { ActiveOnlineBookingLinkRequest, CreateOnlineBookingLinkRequest, OnlineBookingModel, SearchOnlineBooking, UpdateOnlineBookingLinkRequest } from "../models";

const OnlineBookingApi = {
    getOnlineBooking(payload: SearchOnlineBooking): Promise<ListResponse<OnlineBookingModel>> {
        const url = `${API_URL}/api/v1/online-booking?${_convertObjectToQuery(payload)}`;
        return axios.get(url);
    },
    createOnlineBookingLink(payload: CreateOnlineBookingLinkRequest): Promise<ListResponse<OnlineBookingModel>> {
        const url = `${API_URL}/api/v1/online-booking`;
        return axios.post(url, payload);
    },
    updateOnlineBookingLink(payload: UpdateOnlineBookingLinkRequest): Promise<Response<OnlineBookingModel>> {
        const url = `${API_URL}/api/v1/online-booking/${payload.id}`;
        return axios.put(url, payload);
    },
    activeOnlineBookingLink(payload: ActiveOnlineBookingLinkRequest): Promise<Response<any>> {
        const url = `${API_URL}/api/v1/online-booking/${payload.id}/${payload.isActive}`;
        return axios.put(url);
    }
};

export {OnlineBookingApi}