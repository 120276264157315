import { UserMetaData, ServiceScopeModel, UserInfoModel, ServiceScopeSPModel, SPInforModel } from './../models/AuthModel';
import axios from 'axios';
import { LoginParam, UserModel, AuthModel } from '../models/AuthModel';
import { Response } from '../../../../setup/axios/HttpResponse';
import { API_URL } from '../../../utils/common/constants';

const authApi = {
  checkDomainExist(): Promise<Response<any>> {
    const url = API_URL;
    return axios.get(url);
  },
  login(param: LoginParam): Promise<Response<{ auth: AuthModel, user: UserModel, userMetaData: UserMetaData}>> {
    const url = `${API_URL}/api/v1/users/login`;
    return axios.post(url, {
      ...param
    });
  },
  getUserInfo(): Promise<Response<UserInfoModel>> {
    const url = `${API_URL}/api/v1/users/info`;
    return axios.get(url);
  },
  getServiceScope(): Promise<Response<ServiceScopeModel>> {
    const url = `${API_URL}/api/v1/services`;
    return axios.get(url);
  },
  getServiceScopes(): Promise<Response<ServiceScopeSPModel>> {
      const url = `${API_URL}/api/v1/services/service-scopes`;
      return axios.get(url);
  },
  getAllSPLinkedWorker(): Promise<Response<SPInforModel[]>> {
    const url = `${API_URL}/api/v1/users/get-all-sp-linked-worker`;
    return axios.get(url);
  }
}

export default authApi;