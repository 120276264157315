
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap-v5'
import { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks'
import { t } from 'i18next'
import { KTSVG } from 'app/utils/helpers'
import { _sleep } from 'app/utils/helpers/extendMethod'
import { PackageWorkflowServiceModel } from 'app/modules/service-management/models'
import { selectLanguage } from 'app/modules/auth'
import { Languages } from 'app/utils/common/constants'
interface Props {
  isShow: boolean,
  onHide: () => void,
  serviceData: PackageWorkflowServiceModel | null,
  onUpdateService: (data: PackageWorkflowServiceModel) => void,
  isLoading: boolean,
}

const EditServiceModal = (props: Props) => {
  const { isShow, onHide, serviceData, onUpdateService, isLoading } = props
  const dispatch = useAppDispatch()
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;

  const initialValues: PackageWorkflowServiceModel = useMemo(() => ({
    id: 0,
    nameAr: "",
    nameEn: "",
    subTitleTextEn: "",
    subTitleTextAr: "",
    svgIcon: "",
    status: 0,
    packageWorkFlowId: 0,
  }), [])

  const editSchema = Yup.object({
    nameAr: Yup.string().required("required"),
    nameEn: Yup.string().required("required"),
    subTitleTextEn: Yup.string(),
    subTitleTextAr: Yup.string(),
    status: Yup.number().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: editSchema,
    onSubmit: (_values, formikHelpers) => {
      onUpdateService(_values)
      onHide()
    },
  })
  const { handleChange, handleBlur, submitForm, setFieldValue, setValues, values, isValid, errors } = formik

  useEffect(() => {
    if (!serviceData) {
      return
    }
    setValues({..._.cloneDeep(serviceData), status: Boolean(serviceData.status) ? 1 : 0 })
  }, [serviceData])
  
  return (
    <Modal
      show={isShow}
      onHide={onHide}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
      className={`modal-edit-service${rtlMode ? "--rtl" : ""}`}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fw-normal'>
          {t("SERVICE_MANAGEMENT_PAGE.SERVICE.EDIT_SERVICE")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-2">
            <InputGroup className="mb-3">
              <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.SERVICE.SERVICE_TITLE_IN_ARABIC")}</Form.Label>
              <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/right-head-arrow.svg' /></InputGroup.Text>
              <Form.Control required type="text" name='nameAr' onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.SERVICE.ENTER_SERVICE_TITLE_IN_ARABIC") + "*"} className='h-50px' value={values.nameAr} />
            </InputGroup>
          </Col>
          <Col xs={12} className="mb-2">
            <InputGroup className="mb-3">
              <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.SERVICE.SERVICE_TITLE_IN_ENGLISH")}</Form.Label>
              <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/right-head-arrow.svg' /></InputGroup.Text>
              <Form.Control required type="text" name='nameEn' onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.SERVICE.ENTER_SERVICE_TITLE_IN_ENGLISH") + "*"} className='h-50px' value={values.nameEn} />
            </InputGroup>
          </Col>
          <Col xs={12} className="mb-2">
            <InputGroup className="mb-3">
              <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.SERVICE.SERVICE_DESCRIPTION_IN_ARABIC")}</Form.Label>
              <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/justify.svg' /></InputGroup.Text>
              <Form.Control required type="text" name='subTitleTextAr' onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.SERVICE.ENTER_SERVICE_DESCRIPTION_IN_ARABIC")} className='h-50px' value={values.subTitleTextAr} />
            </InputGroup>
          </Col>
          <Col xs={12} className="mb-2">
            <InputGroup className="mb-3">
              <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.SERVICE.SERVICE_DESCRIPTION_IN_ENGLISH")}</Form.Label>
              <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/justify.svg' /></InputGroup.Text>
              <Form.Control required type="text" name='subTitleTextEn' onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.SERVICE.ENTER_SERVICE_DESCRIPTION_IN_ENGLISH")} className='h-50px' value={values.subTitleTextEn} />
            </InputGroup>
          </Col>
          <Col xs={12} className="mb-2">
            <Form.Check
              checked={Boolean(values.status)}
              name='status'
              onBlur={handleBlur}
              onChange={() => setFieldValue("status", Boolean(values.status) ? 0 : 1)}
              className={`service-management-switcher service-management-switcher-label-left ps-4`}
              label={t("SERVICE_MANAGEMENT_PAGE.SERVICE.ACTIVE")}
              type="switch"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col xs={12} className="d-flex justify-content-between">
          <Col xs={2}>
            <Button
              variant='light'
              className='px-10 fw-normal h-50px w-100 text-uppercase'
              onClick={onHide}
              disabled={isLoading}
            >
              {t("CLOSE")}
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              variant='dark'
              className='px-10 fw-normal h-50px w-100 text-uppercase'
              type="submit"
              disabled={isLoading || !isValid}
              onClick={submitForm}
            >
              {isLoading && (
                <div
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></div>
              )}
              {t("SAVE")}
            </Button>
          </Col>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}

export default EditServiceModal