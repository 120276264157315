import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { ListResponse, Response } from "../../../../setup/axios/HttpResponse";
import { HTTP_RESPONSE_STATUS } from "../../../../setup/constants/Http";
import { ToastDefaultConfig } from "../../../../setup/toast/ToastConfig";
import { ActiveOnlineBookingLinkRequest, CreateOnlineBookingLinkRequest, OnlineBookingModel, SearchOnlineBooking } from "../models";
import { OnlineBookingApi } from "./OnlineBookingApi";
import { onlineBookingAction } from "./OnlineBookingSlice";

function* fetchOnlineBooking(action: PayloadAction<SearchOnlineBooking>) {
  try {
    const response: ListResponse<OnlineBookingModel> = yield call(OnlineBookingApi.getOnlineBooking, action.payload);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(onlineBookingAction.getOnlineBookingCompleted(response));
    } else {
      toast.error(response.message, ToastDefaultConfig());
      yield put(onlineBookingAction.getOnlineBookingFailed);
    }
  } catch (error: any) {
    yield put(onlineBookingAction.getOnlineBookingFailed);
  }
}

function* createOnlineBookingLink(action: PayloadAction<CreateOnlineBookingLinkRequest>) {
  try {
    const response: Response<OnlineBookingModel> = yield call(OnlineBookingApi.createOnlineBookingLink, action.payload);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(onlineBookingAction.createOnlineBookingCompleted(response));
    } else {
      toast.error(response.message, ToastDefaultConfig());
      yield put(onlineBookingAction.createOnlineBookingFailed);
    }
  } catch (error: any) {
    yield put(onlineBookingAction.createOnlineBookingFailed);
  }
}

function* activeOnlineBookingLink(action: PayloadAction<ActiveOnlineBookingLinkRequest>) {
  try {
    const response: Response<any> = yield call(OnlineBookingApi.activeOnlineBookingLink, action.payload);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(onlineBookingAction.activeOnlineBookingCompleted(action.payload));
    } else {
      toast.error(response.message, ToastDefaultConfig());
      yield put(onlineBookingAction.activeOnlineBookingFailed);
    }
  } catch (error: any) {
    yield put(onlineBookingAction.activeOnlineBookingFailed);
  }
}
export default function* OnlineBookingSaga() {
  yield takeLatest(onlineBookingAction.getOnlineBooking, fetchOnlineBooking);
  yield takeLatest(onlineBookingAction.createOnlineBooking, createOnlineBookingLink);
  yield takeLatest(onlineBookingAction.activeOnlineBooking, activeOnlineBookingLink);
}