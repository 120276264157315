import { Field, Formik, FormikHelpers } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonToolbar, Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { number } from "yup/lib/locale";
import { useAppSelector } from "../../../../setup/redux/Hooks";
import { StepperComponent } from "../../../../theme/assets/ts/components";
import { Languages } from "../../../utils/common/constants";
import { MajorServiceModel, ServiceTreeNode, SubServiceModel, SubSubServiceModel } from "../../../utils/common/models";
import { KTSVG } from "../../../utils/helpers";
import { authActions, selectLanguage, selectServiceScope, selectServiceScopeSp } from "../../auth";
import { ServiceScopeModel, ServiceScopeSPModel } from "../../auth/models/AuthModel";
import { TreeServiceUser } from "../../users/modals/create-user/component";
import { CreateOnlineBookingLinkRequest, OnlineBookingFormModel, UpdateOnlineBookingLinkRequest } from "../models";
import { OnlineBookingApi } from "../redux/OnlineBookingApi";
import { onlineBookingAction, selectIsCreateOnlineBookingSuccess, selectIsUpdateOnlineBookingSuccess, selectLoadingCreateOnlineBooking, selectOnlineBookingLinkModalData, selectOpeningActionLinkModal } from "../redux/OnlineBookingSlice";
import { CompleteOnlineBooking } from "./complete-onlinebooking/CompleteOnlineBooking";
import OnlineBookingLink from "./online-booking-link/OnlineBookingLink";

const initialValue: OnlineBookingFormModel = {
    title: '',
    listService: [],
    listServiceId: [],
    isActive: false,
}

const OnlineBookingModal: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const validateOnlineBokingLink = Yup.object().shape({
        title: Yup.string().required(t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.VALIDATE.TITLE_REQUIRED')),
        listService: Yup.array().required(t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.VALIDATE.SERVICE_REQUIRED'))
    })
    const isShowViewModal = useAppSelector(selectOpeningActionLinkModal);
    const [initValueForm, setInitValueForm] = useState(initialValue);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const servicesSP = useAppSelector(selectServiceScopeSp);
    const services = useAppSelector(selectServiceScope);

    const language = useAppSelector(selectLanguage);
    const isLoading = useAppSelector(selectLoadingCreateOnlineBooking);
    const isCreateOnlineBookingSuccess = useAppSelector(selectIsCreateOnlineBookingSuccess);
    const onlineBookingDataEdit = useAppSelector(selectOnlineBookingLinkModalData);
    const isUpdateOnlineBookingSuccess = useAppSelector(selectIsUpdateOnlineBookingSuccess) || false;
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

    const [serviceScopeCurrent, setServiceScopeCurrent] = useState<ServiceScopeModel>();

    const rtl = language === Languages.ar;

    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)

    useEffect(() => {
        if(!servicesSP) dispatch(authActions.fetchServiceScope());
    }, [])

    useEffect(() => {
        if (onlineBookingDataEdit && onlineBookingDataEdit?.title) {
            let tmpValueForm = {...initialValue};
            tmpValueForm.listServiceId = JSON.parse(onlineBookingDataEdit.servicesScope);
            tmpValueForm.title = onlineBookingDataEdit.title;
            tmpValueForm.isActive = onlineBookingDataEdit.status
            setInitValueForm(tmpValueForm);
            return;
        }
        setInitValueForm(initialValue);
    }, [onlineBookingDataEdit])

    useEffect(() => {
        if(services != null && servicesSP != null) {
            const subSubIds = servicesSP?.map(ss => ss.id);
            const subIds = servicesSP?.map(s => s.subId);
            const majorIds = servicesSP?.map(m => m.majorId);
            const serviceScopeCurrent: ServiceScopeModel = {
                majorServices: services?.majorServices?.filter(c => majorIds?.some(m => m === c.id)),
                subServices: services?.subServices?.filter(c => subIds?.some(s => s === c.id)),
                subSubServices: services?.subSubServices?.filter(c => subSubIds?.some(ss => ss === c.id)),
            }
            setServiceScopeCurrent(serviceScopeCurrent);
        }
    }, [servicesSP, services])

    const handleCloseModal = () => {
        dispatch(onlineBookingAction.actionHideModalOnlineBooking());
    }
    const nextStep = (values: OnlineBookingFormModel) => {
        if (!stepper.current) return;
        setIsSubmitted(false);
        let listServiceId: number[] = [];
        if (values.listService && values.listService?.length > 0) {
            values.listService?.forEach((data: ServiceTreeNode) => {
                listServiceId.push(data.subSubId || 0);
            })
        }
        if (listServiceId.length === 0) {
            return;
        }
        const paramCreateOnlineBooking: CreateOnlineBookingLinkRequest = {
            servicesScope: listServiceId,
            title: values.title,
            status: true,
        }
        if (!onlineBookingDataEdit) {
            dispatch(onlineBookingAction.createOnlineBooking(paramCreateOnlineBooking));
            return;
        }
        const paramActive: UpdateOnlineBookingLinkRequest = {
            title: values.title,
            servicesScope: listServiceId,
            status: values.isActive,
            id: onlineBookingDataEdit.id,
        };
        setIsLoadingUpdate(true);
        OnlineBookingApi.updateOnlineBookingLink(paramActive).then((response) => {
            setIsLoadingUpdate(false);
            stepper.current?.goNext();
            setIsSubmitted(true);
            dispatch(onlineBookingAction.updateOnlineBookingLinkSuccess(response));
        })
    }
    useEffect(() => {
        setIsSubmitted(false);
        if (!isShowViewModal) return;

        if (!stepperRef.current) {
            return;
        }
        loadStepper();
    }, [stepperRef, isShowViewModal])

    useEffect(() => {
        if (isCreateOnlineBookingSuccess) {
            stepper.current?.goNext();
            setIsSubmitted(true);
        }
    }, [isCreateOnlineBookingSuccess, isUpdateOnlineBookingSuccess])

    const loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }
    return <>
        <Formik
            validationSchema={validateOnlineBokingLink}
            initialValues={initValueForm}
            enableReinitialize
            onSubmit={(values: OnlineBookingFormModel) => nextStep(values)}
        >
            {({
                handleSubmit,
                errors,
                touched,
                resetForm,
                isValid,
                values,
                setFieldValue,
                setFieldError,
                setTouched,
            }) => (<>
                <Modal
                    show={isShowViewModal}
                    onHide={() => {
                        handleCloseModal()
                        resetForm()
                        setInitValueForm({ ...initialValue })
                        setIsSubmitted(false)
                    }}
                    backdrop='static'
                    keyboard={false}
                    size='xl'
                >
                    {!isSubmitted && !isLoading && !isLoadingUpdate && <Modal.Header closeButton className='header-primary'>
                        <Modal.Title className='fw-normal'>
                            {onlineBookingDataEdit?.title ? t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.TITLE_UPDATE') : t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.TITLE_CREATE')}
                        </Modal.Title>
                    </Modal.Header>}
                    <Modal.Body className="pt-0">
                        <div
                            ref={stepperRef}
                            className='stepper stepper-links d-flex flex-column'
                            id='kt_create_account_stepper'
                        >
                            <div data-kt-stepper-element='nav'></div>
                            <div data-kt-stepper-element='nav'></div>
                            <Form id='kt_create_account_form'>
                                <OnlineBookingLink setFieldValue={setFieldValue} services={serviceScopeCurrent} rtl={rtl} errors={errors} values={values} onlineBookingDataEdit={onlineBookingDataEdit}/>

                                <div data-kt-stepper-element='content'>
                                    <CompleteOnlineBooking />
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-space-between modal-footer-gray group-button-footer'>
                        <div className={(isSubmitted && !isLoading && !isLoadingUpdate && onlineBookingDataEdit) ? 'm-auto' : ''}>
                            <Button
                                className='px-10 fw-normal btn-next btn-back w-200px'
                                data-kt-stepper-action='previous'
                                onClick={() => handleCloseModal()}
                            >
                                {t('CLOSE')}
                            </Button>
                        </div>
                        <div>
                            {(isLoading || isLoadingUpdate) && (
                                <Button className='px-10 fw-normal btn-next w-200px'>
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                    <span className='mx-2'> {t('USERS_PAGE.CONTENT_STEP.LOADING')}</span>
                                </Button>
                            )}
                            {!isSubmitted && !isLoading && !isLoadingUpdate && (
                                <Button
                                    className='px-10 btn btn-warning fw-normal w-200px'
                                    onClick={() => handleSubmit()}
                                >
                                    {onlineBookingDataEdit ? t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.UPDATE_LINK') : t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.GENERATE_LINK')}
                                </Button>
                            )}
                            {isSubmitted && !isLoading && !isLoadingUpdate && !onlineBookingDataEdit &&
                                <Button
                                    className='px-10 btn btn-warning fw-normal w-200px text-nowrap'
                                    onClick={() =>{
                                        resetForm()
                                        setInitValueForm({ ...initialValue })
                                        setFieldValue("listServiceId", [])
                                        setIsSubmitted(false)
                                        stepper?.current?.goPrev();
                                    }}
                                >
                                    {t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.GENERATE_NEW_LINK')}
                                </Button>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
            )}
        </Formik>
    </>
}
export default OnlineBookingModal;