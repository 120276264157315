import { ServiceManagementBusesManagementModel } from 'app/modules/service-management/models'
import { KTSVG, handleWheelInputNumber } from 'app/utils/helpers'
import _ from 'lodash'
import React, { ChangeEventHandler, useEffect, useMemo } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'setup/redux/Hooks'
import { selectLanguage, selectUserMetaData } from 'app/modules/auth'
import { Languages } from 'app/utils/common/constants'
interface Props {
  formik: any,
  handleChangeNumberField: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, needChangeValue?: boolean) => void,
  handleBlurNumberField: React.FocusEventHandler<HTMLInputElement>
}

const PriceManagement = (props: Props) => {
  const { formik, handleBlurNumberField, handleChangeNumberField } = props
  const { t } = useTranslation()
  const userMetaData = useAppSelector(selectUserMetaData);
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar
  const vatPercent = (userMetaData?.vatValue || 0) / 100;

  const { handleChange, handleBlur, setFieldValue, touched, values } = formik

  const isEnabledCustomPricing: boolean = useMemo(() => {
    if (!Boolean(values.buses?.length)) {
      return false
    }
    const cloneBuses: ServiceManagementBusesManagementModel[] = _.cloneDeep(values.buses)
    const result = cloneBuses.map(item => item.buses.some(bus => bus?.isSelected === true))
    return result.some(item => item === true)
  }, [values.buses])

  const handleBlurCustomPrice = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, indexCity: number, indexBus: number) => {
    setFieldValue(`buses[${indexCity}].buses[${indexBus}].packageServicePriceSetDetail`, {
      packagePrice: parseFloat(e.target?.value || "0"),
      packagePriceWithVat: parseFloat(e.target?.value || "0") * (1 + vatPercent)
    })
    handleBlur(e)
  }

  const handleBlurCustomPriceWithVat = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, indexCity: number, indexBus: number) => {
    setFieldValue(`buses[${indexCity}].buses[${indexBus}].packageServicePriceSetDetail`, {
      packagePrice: parseFloat(e.target?.value || "0") / (1 + vatPercent),
      packagePriceWithVat: parseFloat(e.target?.value || "0")
    })
    handleBlur(e)
  }

  const handleBlurDefaultPrice: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFieldValue("packageServicePriceSetDefault", {
      packagePrice: parseFloat(e.target?.value || "0"),
      packagePriceWithVat: parseFloat(e.target?.value || "0") * (1 + vatPercent)
    })
    handleBlur(e)
  }

  const handleBlurDefaultPriceWithVat: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFieldValue("packageServicePriceSetDefault", {
      packagePrice: parseFloat(e.target?.value || "0") / (1 + vatPercent),
      packagePriceWithVat: parseFloat(e.target?.value || "0")
    })
    handleBlur(e)
  }

  return (
    <Row className='create-package-offcanvas--price-management'>
      <Row className='m-0 mb-8'>
        <Col xs={12} className='create-package-offcanvas--price-management--general-pricing-title mb-3'>
          {t("SERVICE_MANAGEMENT_PAGE.PACKAGE.GENERAL_PRICE_NOTE")}
        </Col>
        <Col xs={3} className="p-0">
          <InputGroup className="mb-3">
            <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE_BEFORE_VAT")}</Form.Label>
            <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/price.svg' /></InputGroup.Text>
            <Form.Control
              key={`packageServicePriceSetDefault.packagePrice-${values.packageServicePriceSetDefault.packagePrice}`}
              type={"text"}
              min={0}
              name='packageServicePriceSetDefault.packagePrice'
              defaultValue={(!touched?.packageServicePriceSetDefault?.packagePrice && !Boolean(values.packageServicePriceSetDefault.packagePrice)) ? undefined : parseFloat(values.packageServicePriceSetDefault.packagePrice || "0").toFixed(2)}
              onBlur={handleBlurDefaultPrice}
              onChange={(e) => handleChangeNumberField(e, false)}
              onWheel={handleWheelInputNumber} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.SUB_TOTAL") + "*"}
              className='h-35px rounded-0' />
          </InputGroup>
        </Col>
        <Col xs={3} className="p-0">
          <InputGroup className="mb-3">
            <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.VAT")}</Form.Label>
            <Form.Control readOnly disabled type={"text"}
              value={(parseFloat(values.packageServicePriceSetDefault.packagePrice || "0") * vatPercent).toFixed(2)}
              placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.VAT") + "*"}
              className='h-35px rounded-0' />
          </InputGroup>
        </Col>
        <Col xs={3} className="p-0">
          <InputGroup className="mb-3">
            <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE_WITH_VAT")}</Form.Label>
            <Form.Control
              key={`packageServicePriceSetDefault.packagePriceWithVat-${values.packageServicePriceSetDefault.packagePriceWithVat}`}
              type={"text"}
              min={0}
              name='packageServicePriceSetDefault.packagePriceWithVat'
              defaultValue={(!touched?.packageServicePriceSetDefault?.packagePriceWithVat && !Boolean(values.packageServicePriceSetDefault.packagePriceWithVat))
                ? undefined : parseFloat(values.packageServicePriceSetDefault.packagePriceWithVat || "0").toFixed(2)}
              onBlur={handleBlurDefaultPriceWithVat}
              onChange={(e) => handleChangeNumberField(e, false)}
              onWheel={handleWheelInputNumber}
              placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.TOTAL") + "*"}
              className='h-35px rounded-0' />
          </InputGroup>
        </Col>
        <Col xs={3} className="p-0">
          <InputGroup className="mb-3">
            <Form.Label className='w-100'>&nbsp;</Form.Label>
            <Form.Control readOnly disabled type="text" value={`${t("SAR")}`} className='h-35px text-center' />
          </InputGroup>
        </Col>
        <Col xs={12} className='create-package-offcanvas--price-management--note'>
          {"* " + t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE_NOTE")}
        </Col>
      </Row>
      {isEnabledCustomPricing && <Row className='m-0'>
        <Col xs={12} className='p-0 create-package-offcanvas--price-management--custom-pricing-title mb-3'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.CUSTOM_BUS_PRICING")}</Col>
        {values.buses.map((item: ServiceManagementBusesManagementModel, indexCity: number) => {
          return item.buses.map((bus, indexBus) => {
            const control: {
              packagePrice: { type: "text"; value: string | undefined; }
              packagePriceWithVat: { type: "text"; value: string | undefined; }
            } = {
              packagePrice: {
                type: "text",
                value: parseFloat(values.buses[indexCity].buses[indexBus]?.packageServicePriceSetDetail?.packagePrice || "0").toFixed(2)
              },
              packagePriceWithVat: {
                type: "text",
                value: parseFloat(values.buses[indexCity].buses[indexBus]?.packageServicePriceSetDetail?.packagePriceWithVat || "0").toFixed(2)
              },
            }
            if (!Boolean(bus?.isSelected)) {
              return null
            }
            return (<Row key={`bus-${indexCity}-${indexBus}-${control.packagePrice.value}-${control.packagePriceWithVat.value}`} className='m-0 p-0'>
              <Col xs={12} className="p-0">
                <InputGroup className="mb-3">
                  <Form.Label className='w-100 create-package-offcanvas--price-management--bus-name'><span>{bus[rtlMode ? "fullName" : "fullNameEn"]}</span></Form.Label>
                </InputGroup>
              </Col>
              <Col xs={3} className="p-0">
                <InputGroup className="mb-3">
                  <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE_BEFORE_VAT")}</Form.Label>
                  <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/price.svg' /></InputGroup.Text>
                  <Form.Control required
                    type={control.packagePrice.type}
                    min={0}
                    name={`buses[${indexCity}].buses[${indexBus}].packageServicePriceSetDetail.priceBeforeVAT`}
                    defaultValue={control.packagePrice.value}
                    onChange={(e) => handleChangeNumberField(e, false)}
                    onBlur={(e: any) => handleBlurCustomPrice(e, indexCity, indexBus)}
                    onWheel={handleWheelInputNumber}
                    placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.SUB_TOTAL")}
                    className='h-35px rounded-0' />
                </InputGroup>
              </Col>
              <Col xs={3} className="p-0">
                <InputGroup className="mb-3">
                  <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.VAT")}</Form.Label>
                  <Form.Control readOnly disabled type="text" value={(parseFloat(control.packagePrice.value || "0") * vatPercent).toFixed(2)} className='h-35px rounded-0' />
                </InputGroup>
              </Col>
              <Col xs={3} className="p-0">
                <InputGroup className="mb-3">
                  <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.PRICE_WITH_VAT")}</Form.Label>
                  <Form.Control
                    type={control.packagePriceWithVat.type}
                    defaultValue={control.packagePriceWithVat.value}
                    onChange={(e) => handleChangeNumberField(e, false)}
                    onBlur={(e: any) => handleBlurCustomPriceWithVat(e, indexCity, indexBus)}
                    onWheel={handleWheelInputNumber}
                    placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.TOTAL")}
                    className='h-35px rounded-0' />
                </InputGroup>
              </Col>
              <Col xs={3} className="p-0">
                <InputGroup className="mb-3">
                  <Form.Label className='w-100'>&nbsp;</Form.Label>
                  <Form.Control readOnly disabled type="text" value={`${t("SAR")}`} onChange={handleChange} onBlur={handleBlur} className='h-35px text-center' />
                </InputGroup>
              </Col>
            </Row>)
          })
        })}
      </Row>}
    </Row>
  )
}

export default PriceManagement