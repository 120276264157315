import { Field, FieldConfig, FieldProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { _sleep } from '../../extendMethod';
import { InfiniteScroll } from './InfiniteScroll';
import './SelectorInline1.scss';
import { TextInput } from './TextInput';

type IFetch<T> = (params: { page: number, pageSize: number, textSearch: string }) => { total: number, results: T[] } | Promise<{ total: number, results: T[] }>

export function useDebouncedCallback<A extends unknown[]>(
    callback: (...args: A) => void,
    wait: number
) {
    const argsRef = useRef<A>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }
    useEffect(() => cleanup, []);

    return function debouncedCallback(
        ...args: A
    ) {
        argsRef.current = args;
        cleanup();
        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current);
            }
        }, wait);
    };
}

type TFuncDebounce = (...args: any) => void
const debounce = (fn: TFuncDebounce, ms: number): TFuncDebounce => {
    var timer: any;
    //@ts-ignore
    return function (...args: any) {
        //@ts-ignore
        var context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, ms)
    }
}

export interface IPropsSelectorInline<T = unknown> {
    label?: string,
    options?: T[],
    optionKey?: (e: T, index?: number) => number | string
    optionLabel?: (e: T, index?: number) => number | string
    onFetch?: IFetch<T>
    placeholder?: string
    placeholderInput?: string
    className?: string | string[],
    value?: number | string | T
    onChange?: (e: T) => void
    inputGroup?: React.ReactNode,
    pageInit?: number
}
export const SelectorInline = <T,>(props: IPropsSelectorInline<T>) => {
    const {
        options = [],
        optionKey = (e, index) => index || 0,
        optionLabel = (e, index) => 'Item-' + ((index || 0) + 1),
        onFetch: _onFetch,
        placeholder = 'Select',
        className,
        value,
        onChange,
        inputGroup,
        placeholderInput = 'Search ...',
        pageInit = 0,

        //formik
        field,
        form,
    } = props as IPropsSelectorInline<T> & FieldProps;

    const _state = useRef<{
        page: number,
        pageSize: number,
        textSearch: string,

        total: number,
        results: T[],
        selected?: T,
        isLoading?: boolean,
        isShowSelect?: boolean
    }>({
        page: 0,
        pageSize: 20,
        textSearch: '',
        total: 0,
        results: []
    })

    const [state, setState] = useState<typeof _state.current>(_state.current)

    useEffect(() => {
        const v = field !== undefined ? field.value : value;
        _state.current = { ..._state.current, selected: v === undefined ? undefined : typeof v === 'object' ? v : (_onFetch === undefined ? options : _state.current.results).find(e => optionKey(e) === v) }
        setState(_state.current)
    }, [value, field])

    const touched = form?.touched[field.name];
    let error = form?.errors[field.name];
    // let [error, params = ''] = ![null, undefined, ''].includes(_error as any) ? String(_error).split('-') : [_error, '']
    // error = ![null, undefined, ''].includes(error as any) ? t(error as string).format(params.split(';')) : error
    const isInvalid = touched === true && error !== undefined

    const onFocusTextSearch = async () => {
        _state.current = { ..._state.current, isShowSelect: true }
        setState(_state.current)
        if (_state.current.textSearch === '' && _state.current.results.length === 0)
            handleLoadMore(pageInit);
    }

    const onBlurTextSearch = async () => {
        await _sleep(200)
        _state.current = { ..._state.current, isShowSelect: false }
        setState(_state.current);
    }

    const onSelected = (e: T, index: number) => {
        _state.current = { ..._state.current, selected: e }
        if (field !== undefined)
            form.setFieldValue(field.name, optionKey(e))
        else
            onChange && onChange(e)
        setState({ ..._state.current, isShowSelect: false })
    }

    const onChangeTextSearch = (text: string) => {
        _state.current = { ..._state.current, total: 0, page: pageInit, textSearch: text }
        setState(_state.current)
        handleLoadMore(pageInit)
    }

    const onFetch = useCallback(debounce(async () => {
        if (_state.current.isLoading || (_state.current.total !== 0 && _state.current.results.length >= _state.current.total)) return;

        setState({ ..._state.current, isLoading: true });

        const { total, results } = _onFetch === undefined ?
            { total: 0, results: options.filter(e => optionLabel(e).toString().toLocaleLowerCase().indexOf(_state.current.textSearch?.toLocaleLowerCase() || '') >= 0) }
            : await _onFetch({ page: _state.current.page, pageSize: _state.current.pageSize, textSearch: _state.current.textSearch });

        _state.current = { ..._state.current, isLoading: false, total, results: _state.current.page === pageInit ? results : [..._state.current.results, ...results] }
        setState(_state.current);
    }, 200), [state])

    const handleLoadMore = useCallback(debounce((page?: number) => {
        if (_state.current.isLoading) return
        const _page = page !== undefined ? page : _state.current.page + 1;
        _state.current = { ..._state.current, page: _page };
        onFetch();
    }, 200), [])

    return (
        <div className={`selector-inline ${className === undefined ? [] : (Array.isArray(className) ? className : [className]).join(' ')}`}>
            <TextInput
                inputGroup={inputGroup}
                inputGroupEnd={<i className={`fa fa-solid ${state.isShowSelect ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>}
                onFocus={() => onFocusTextSearch()}
                onBlur={() => onBlurTextSearch()}
                onChange={(e) => onChangeTextSearch(e.target.value)}
                placeholder={(state.isShowSelect === true) ? placeholderInput : placeholder}
                value={(state.isShowSelect === true || _state.current.selected === undefined) ? state.textSearch : optionLabel(_state.current.selected)}
                isInvalid={isInvalid}
                error={error as string}
            />
            {state.isLoading && <i className="fa fa-solid fa-spinner"></i>}
            {state.isShowSelect &&
                <InfiniteScroll className='selector-list'
                    onFetch={() => {
                        handleLoadMore()
                    }}
                >
                    {state.results.map((e, index) => (
                        <button
                            key={optionKey(e, index)}
                            type='button'
                            className={`selector-item ${state.selected !== undefined && optionKey(state.selected) === optionKey(e, index) ? 'selected' : ''}`}
                            onClick={() => onSelected(e, index)}
                        >
                            <p>{optionLabel(e, index)}</p>
                        </button>
                    ))}
                </InfiniteScroll>
            }
        </div>
    )
}

export type IPropsFieldSelectorInline<T> = IPropsSelectorInline<T> & FieldConfig
export const FieldSelectorInline = <T,>(props: IPropsFieldSelectorInline<T>) => <Field component={SelectorInline} {...props} />;