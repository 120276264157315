/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { authActions } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../../app/utils/helpers';

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'ar',
    name: 'العربية',
    flag: toAbsoluteUrl('/media/flags/saudi-arabia.svg'),
  },
]

const Languages: FC = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const currentLanguage = languages.find((x) => x.lang === lang);
  const dispatch = useDispatch();
  const changeLanguage = (language: string) => {
    dispatch(authActions.changeLanguage(language));
    i18n.changeLanguage(language);
  }
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-start'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          { lang === "ar" ? "اللغة" : "Language" }
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            data-lang-menu-trigger='click'
            key={l.lang}
            onClick={() => {
              changeLanguage(l.lang)
            }}
            >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Languages }
