export const sortOptions = [
    {
        value: 1,
        label: 'TICKET_FILTER_OPTIONS.Favorite_Worker',
        icon: '/assets/images/icons/FavWorker.svg'
    },
    {
        value: 2,
        label: 'TICKET_FILTER_OPTIONS.Latest_Worker',
        icon: '/assets/images/icons/UserList.svg'
    }
]

export const shiftOptions = [
    {
        value: 0,
        label: 'TICKET_FILTER_OPTIONS.All_shifts',
        icon: '/assets/images/icons/Cloud.svg'
    },
    {
        value: 1,
        label: 'TICKET_FILTER_OPTIONS.Morning_shift',
        icon: '/assets/images/icons/Sun.svg'
    },
    {
        value: 2,
        label: 'TICKET_FILTER_OPTIONS.Evening_shift',
        icon: '/assets/images/icons/Moon.svg'
    }
]