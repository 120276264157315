import React, { useCallback, useEffect, useRef } from 'react';
import './SelectorInline1.scss';

export interface IPropsInfiniteScroll {
    className?: string | string[],
    children?: React.ReactNode,
    loadingOffSet?: number,
    onFetch?: () => void
}
export const InfiniteScroll = (props: IPropsInfiniteScroll) => {
    const { className = [], children, loadingOffSet = 100, onFetch } = props;

    const refList = useRef<HTMLDivElement>()

    const onScroll = useCallback((e: HTMLElementEventMap['scroll']) => {
        const target = e.target as Element

        if (target.scrollTop + target.clientHeight + loadingOffSet >= target.scrollHeight)
            onFetch && onFetch();
    }, [loadingOffSet, onFetch])

    useEffect(() => {
        refList.current?.addEventListener('scroll', onScroll);
    }, [onScroll])

    return (
        <div className={`${className === undefined ? [] : (Array.isArray(className) ? className : [className]).join(' ')}`} style={{}} ref={(e: any) => refList.current = e}>
            {children}
        </div>
    )
}