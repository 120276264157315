export const Currency = {
    currency: {
        'SAR': {
            'en': 'SAR',
            'ar': 'ريال'
        },
        'BHD': {
            'en': 'BHD',
            'ar': 'دينار'
        },
    } as any,
    country: {
        1: {
            'en': 'SAR',
            'ar': 'ريال'
        },
        2: {
            'en': 'BHD',
            'ar': 'دينار'
        }
    } as any
}