import { ticketActions } from 'app/modules/tickets/redux/TicketSlice';
import { toggleDrawer as toggleDrawerCreateOrder } from 'app/modules/tickets/drawer/CreateOrderDrawer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'setup/redux/Hooks';

import { MenuItem } from './MenuItem';

export function MenuInner() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      <div className='menu-item me-lg-1'>
        <button onClick={() => toggleDrawerCreateOrder?.(true)}
          style={{
            fontWeight: 'var(--bs-btn-font-weight)',
            border: 0,
            minWidth: 115,
            padding: 'calc(0.65rem + 1px) calc(1.25rem + 1px)',
            color: 'white',
            background: ' #00171f',
            borderRadius: 6,
            fontSize: 12,
          }}
        >{t('TICKET_LIST_PAGE.CREATE_ORDER')}</button>
      </div>
      {/* <MenuItem title={t('HEADER.SUBSCRIBES')} to='/home' /> */}
    </>
  )
}
