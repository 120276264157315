import {Languages} from 'app/utils/common/constants'
import {SpServiceAvailable} from 'app/utils/common/models'
import {Worker} from '../../../app/modules/tickets/models/OrderDrawerModel'
import Item from './Item'

const List = (props: {
  radioList: {
    [name: string]: {
      value: string | number
      item: SpServiceAvailable | Worker
      component: React.ReactNode
    }
  }
  textSearch: string
  onChange: Function
  isOffer?: boolean
  handleFavoriteWorker?: Function
  language?: string
}) => {
  const {radioList, textSearch, onChange, isOffer, handleFavoriteWorker, language} = props

  return (
    <div className='worker-list mt-5'>
      {Object.entries(radioList)
        .map(([, radio]) => ({
          ...radio,
          label: !('workerId' in radio.item)
            ? language === Languages.ar
              ? radio.item.fullNameAr
              : radio.item.fullNameEn
            : language === Languages.ar
            ? radio.item.workerNameAr
            : radio.item.workerNameEn,
        }))
        .filter(
          (e) =>
            textSearch.trim() === '' ||
            e.label?.toLowerCase().indexOf(textSearch.toLowerCase()) >= 0
        )
        .map((e, index) => (
          <Item
            index={index}
            onChange={onChange}
            selection={e}
            isOffer={isOffer}
            handleFavoriteWorker={handleFavoriteWorker}
            language={language}
          />
        ))}
    </div>
  )
}

export default List
