import { UserStatus, UserStatusColor, UserStatusLabel, USER_TYPE, UserRoleLabel, UserRoleColor } from "../common/constants";

export const statusUserToLabel = (status: number) => {
  switch (status) {
      case UserStatus.Active:
          return UserStatusLabel.User_Active;
      case UserStatus.Deactive:
          return UserStatusLabel.User_Deactive;
      default:
          break;
  }
}

export const statusUserToColor = (status: number) => {
  switch (status) {
    case UserStatus.Active:
      return UserStatusColor.Active;
    case UserStatus.Deactive:
      return UserStatusColor.Deactive;
    default:
      break;
  }
}

export const roleUserToLabel = (role: string) => {
  switch (role) {
    case USER_TYPE.ADMIN:
        return UserRoleLabel.SUPERVISOR;
    case USER_TYPE.DRIVER:
        return UserRoleLabel.DRIVER;
    case USER_TYPE.TECHNICIAN:
        return UserRoleLabel.BUS;
    case USER_TYPE.WORKER:
      return UserRoleLabel.WORKER;
    default:
        break;
  }
}

export const roleUserToColor = (role: string) => {
  switch (role) {
    case USER_TYPE.ADMIN:
        return UserRoleColor.Admin;
    case USER_TYPE.DRIVER:
        return UserRoleColor.Driver;
    case USER_TYPE.TECHNICIAN:
        return UserRoleColor.Bus;
    case USER_TYPE.WORKER:
      return UserRoleColor.Worker;
    default:
        break;
  }
}