import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../theme/layout/components/HeadTitle';

import { B8akToolbar } from '../../../theme/layout/components/toolbar/B8akToolbar';
import { PageUrl } from '../../utils/common/constants';
import OnlineBookingList from './pages/OnlineBookingList';
const OnlineBookingPage: React.FC = () => {
	const { t } = useTranslation();

	PageTitle(t('ONLINE_BOOKING_PAGE.PAGE_TITLE'));
	return (
		<>
			<Switch>
				<Route path={PageUrl.ONLINE_BOOKING.ROOT}>
					<B8akToolbar title={t('ONLINE_BOOKING_PAGE.PAGE_TITLE')} />
					<OnlineBookingList />
				</Route>

				<Redirect to={PageUrl.ONLINE_BOOKING.ROOT} />
			</Switch>
		</>
	)

}

export default OnlineBookingPage