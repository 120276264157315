import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { Col, InputGroup, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';

import { STEP_USERS } from '../../../../../../utils/common/constants';
import { SelectFilter, ServiceTreeNode } from '../../../../../../utils/common/models';
import { KTSVG } from '../../../../../../utils/helpers';
import { UserFormModel } from '../../../../models';
import { TreeServiceUser } from '../tree-service-user/TreeServiceUser';

type Props = {
  className?: string,
  values?: UserFormModel,
  rtl?: boolean,
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  currentCheckCustomStep: number,
  serviceFormatTreeData: ServiceTreeNode[],
  selectService: ServiceTreeNode[],
  optionSkills: SelectFilter[],
  selectSkill: SelectFilter[],
  overrideStrings: any,
  onSelectedSubSubService: (selectedNodes: ServiceTreeNode[]) => void,
  setServiceFormatTreeData: (s: ServiceTreeNode[]) => void,
  removeItemService: (s: ServiceTreeNode) => void,
  removeItemSkill: (id: string) => void,
  handleChangeSkill: (event: SelectFilter[]) => void
  isUpdateForm?: boolean
}

const WorkInFoWorker: React.FC<Props> = ({
  rtl, values, errors, touched, currentCheckCustomStep,
  serviceFormatTreeData, selectService, optionSkills, selectSkill, overrideStrings,
  onSelectedSubSubService, setServiceFormatTreeData, removeItemService, removeItemSkill, handleChangeSkill,
  isUpdateForm = false
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Select Services */}
      <Row className='mt-8'>
        <Col sm={12}>
          <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.TITLE_SERVICE')}</h6>
          <TreeServiceUser
            data={serviceFormatTreeData}
            onChange={setServiceFormatTreeData}
            onSelectedSubSubService={onSelectedSubSubService}
            isShowSelectAll={false}
            placeholder='USERS_PAGE.CONTENT_STEP.STEP3.ROLE_WORKER.PLACEHOLDER.SERVICE'
            inlineSearchPlaceholder='USERS_PAGE.CONTENT_STEP.STEP3.ROLE_WORKER.PLACEHOLDER.SEARCH_SELECT'
          />
          {/* {false && selectService.length == 0 && (currentCheckCustomStep >= STEP_USERS.WORK_INFO || (errors.pricePerMonth && touched.pricePerMonth)) ? (
            <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SERVICE')}</div>
          ) : null} */}
        </Col>
        <Col sm={12}>
          <div className='list-item--select'>
            {selectService && selectService.map((service: ServiceTreeNode) => (
              <div className="item" key={service.subSubId}>
                <span className='name'>{service.label}</span> <span onClick={() => removeItemService?.(service)} className='close'> <i className="fas fa-times text-white"></i></span>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      {/* Select skills */}
      <Row className='mt-8'>
        <Col sm={12}>
          <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.TITLE_SKILL')}</h6>
          <InputGroup className='filter-calender'>
            <InputGroup.Text id='basic-addon1'>
              {/* <KTSVG defaultColor={true} path="/assets/images/icons/service-and-skill.svg" /> */}
              <img src="/assets/images/common/worker-skill.png" alt="worker skill" width="22px" height="22px" />
            </InputGroup.Text>
            <MultiSelect
              options={optionSkills}
              value={selectSkill}
              onChange={handleChangeSkill}
              labelledBy="USERS_PAGE.CONTENT_STEP.STEP3.ROLE_WORKER.PLACEHOLDER.SKILLS"
              className='form-multi-select form-multi-select-mobile'
              overrideStrings={{ ...overrideStrings, selectSomeItems: t('USERS_PAGE.CONTENT_STEP.STEP3.ROLE_WORKER.PLACEHOLDER.SKILLS') }}
            />
          </InputGroup>
          {false && selectSkill.length == 0 && ((currentCheckCustomStep >= STEP_USERS.WORK_INFO) || (errors.pricePerMonth && touched.pricePerMonth)) ? (
            <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SKILL')}</div>
          ) : null}
        </Col>
        <Col sm={12}>
          <div className='list-item--select'>
            {selectSkill && selectSkill.map((skill: SelectFilter) => (
              <div className="item" key={skill.value}>
                <span className='name'>{skill.label}</span> <span onClick={() => removeItemSkill?.(skill.value)} className='close'> <i className="fas fa-times text-white"></i></span>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      {/* Cost per month */}
      <Row className='mt-8'>
        <Col sm={12}>
          <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.TITLE_PRICE')}</h6>
          <InputGroup className='h-50px'>
            <InputGroup.Text id='basic-addon1'>
              <img src='/assets/images/icons/cost.svg'></img>
            </InputGroup.Text>
            <Field
              className='form-control h-50px'
              name='pricePerMonth'
              type="number"
              placeholder={t('USERS_PAGE.CONTENT_STEP.STEP3.ROLE_WORKER.PLACEHOLDER.PRICE')}
              value={values?.pricePerMonth}
            />
          </InputGroup>
          {errors.pricePerMonth && touched.pricePerMonth ? (
            <div className='small text-danger'>{errors.pricePerMonth}</div>
          ) : null}
        </Col>
      </Row>
    </>
  )
}

export { WorkInFoWorker }