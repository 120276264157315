import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../theme/layout/components/HeadTitle';
import './style.scss'
import { B8akToolbar } from '../../../theme/layout/components/toolbar/B8akToolbar';
import { PageUrl } from '../../utils/common/constants';
import GrowthMarketing from './components/GrowthMarketing';

const GrowthAndMarketingPage: React.FC = () => {
	const { t } = useTranslation();

	PageTitle(t('GROWTH_AND_MARKETING_PAGE.PAGE_TITLE'));
	return (
		<>
			<Switch>
				<Route path={PageUrl.GROWTH_AND_MARKETING.ROOT}>
					<B8akToolbar title={t('GROWTH_AND_MARKETING_PAGE.PAGE_TITLE')} />
					<GrowthMarketing />
				</Route>

				<Redirect to={PageUrl.GROWTH_AND_MARKETING.ROOT} />
			</Switch>
		</>
	)

}

export default GrowthAndMarketingPage