import moment from "moment";
declare global {
    interface Date {
        format(format?: string): string;
        isSame(date: Date | string | number, type?: 'year' | 'month' | 'day'): boolean;
        addDays(number: number): Date
        set(opts: { h?: number, m?: number, s?: number, D?: number, M?: number, Y?: number }): Date
    }
}
if (!Date.prototype.format)
    Date.prototype.format = function (format?: string) {
        format = format || 'DD/MM/YYYY';
        return moment(this).format(format)
    };
if (!Date.prototype.isSame)
    Date.prototype.isSame = function (date: Date | string | number, type?: 'year' | 'month' | 'day') {
        type = type || 'day';
        const d = date === undefined ? undefined : date instanceof Date ? date : new Date(date);
        if (d === undefined) return false
        return moment(this).isSame(d, type)
    };

if (!Date.prototype.addDays)
    Date.prototype.addDays = function (number: number) {
        var date = new Date(this);
        date.setDate(date.getDate() + number);
        return date;
    };

if (!Date.prototype.set)
    Date.prototype.set = function (opts) {
        const date = new Date(this);
        const { h, m, s, D, M, Y } = opts;
        h != undefined && date.setHours(h);
        m != undefined && date.setMinutes(m);
        s != undefined && date.setSeconds(s);
        D != undefined && date.setDate(D);
        M != undefined && date.setMonth(M);
        Y != undefined && date.setFullYear(Y);
        return date;
    };
export { }