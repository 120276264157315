import { useCallback, useEffect, useRef, useState } from 'react';
export const useStateCallback = <S>(initialState: S): [S, (data: S, cb?: (state?: S) => void) => void] => {
    const [state, setState] = useState({
        data: initialState
    });
    const cbRef = useRef<(value?: S) => void | undefined>();

    const setStateCallback = useCallback((data: S, cb?: (state?: S) => void) => {
        cbRef.current = cb;
        setState({
            data: data
        });
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current != undefined && cbRef.current(state.data);
            cbRef.current = undefined;
        }
    }, [state]);

    return [state.data, setStateCallback];
}