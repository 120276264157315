import DatePicker from 'react-datepicker'
import {FieldSelector} from 'app/utils/helpers/components/Form'
import {Placement} from '@popperjs/core'
import {
  sortOptions,
  shiftOptions,
  Languages,
  popperPlacement,
  DateFormat,
} from 'app/utils/common/constants/index'
import {useField, useFormikContext} from 'formik'
import {toAbsoluteUrl} from 'app/utils/helpers'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import ar from 'date-fns/locale/ar'
import en from 'date-fns/locale/en-US'
import OptionsFilter from './OptionsFilter'

const Filter = (props: {
  language: string
  startDate?: Date
  disabled?: boolean
  handleChangeDate?: Function
}) => {
  const {language, startDate = new Date(), disabled = false, handleChangeDate} = props
  const {t} = useTranslation()
  const {setFieldValue} = useFormikContext()
  const [field] = useField('service_provider_filter.startDate')

  return (
    <div className={`filter-container row ${language === Languages.ar && 'rtl'}`}>
      <div className={`col-sm-12 col-md-6 ${language === Languages.ar ? 'custom-col-5' : 'col-lg-4'} d-flex align-items-center mb-4`}>
        <p className='filter-title fw-bold'>{t('RESCHEDULE_PACKAGE.SORT_BY')}:</p>
        <FieldSelector
          name='service_provider_filter.sortBy'
          as='select'
          options={sortOptions}
          optionKey={(e) => e.value}
          optionLabel={(e) => String(t(e.label))}
          filterComponent={OptionsFilter}
          classContainer='w-100 filter-input-disabled'
          inputDisabled={disabled}
          isSearchable={false}
        />
      </div>
      <div className={`col-sm-12 col-md-6 ${language === Languages.ar ? 'custom-col-4' : 'col-lg-4'} d-flex align-items-center mb-4`}>
        <p className='filter-title fw-bold'>{t('RESCHEDULE_PACKAGE.FILTER_BY')}:</p>
        <FieldSelector
          name='service_provider_filter.filterBy'
          as='select'
          options={shiftOptions}
          optionKey={(e) => e.value}
          optionLabel={(e) => String(t(e.label))}
          filterComponent={OptionsFilter}
          classContainer='w-100 filter-input-disabled'
          inputDisabled={disabled}
          isSearchable={false}
        />
      </div>
      <div className={`col-sm-12 col-md-6 ${language === Languages.ar ? 'custom-col-3' : 'col-lg-4'} d-flex align-items-center mb-4`}>
        <p className='filter-title fw-bold'>{t('RESCHEDULE_PACKAGE.START_DATE')}:</p>
        <DatePicker
          locale={language === Languages.ar ? ar : en}
          {...field}
          dateFormat={DateFormat.FORMAT_2}
          popperPlacement={
            (language === Languages.ar
              ? popperPlacement.TOP_START
              : popperPlacement.TOP_END) as Placement
          }
          useWeekdaysShort={false}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            handleChangeDate && handleChangeDate(val)
            setFieldValue(field.name, val)
          }}
          minDate={moment(startDate).toDate()}
          wrapperClassName='start-date-picker'
          placeholderText={t('RESCHEDULE_PACKAGE.SELECT_DATE')}
          disabled={disabled}
          onKeyDown={(e) => {
            e.preventDefault()
          }}
        />
      </div>
    </div>
  )
}

export default Filter
