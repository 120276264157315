import './style.scss';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { STEP_UPDATE_TECH_USER_ARRAY, STEP_UPDATE_WORKER_USER_ARRAY, STEP_USERS, USER_TYPE } from '../../../../../../utils/common/constants';
import { UserFormModel } from 'app/modules/users/models';

type Props = {
  currentStep: number;
  values?: UserFormModel,
  handleStepJump: (step: number) => void

}

export const StepByStepTechUserHeader: React.FC<Props> = ({ currentStep, values, handleStepJump }) => {
  const { t } = useTranslation();
  const returnSuccessStep = (step: number) => {
    if (step == (currentStep - 1)) return 'active';
    // if (step < (currentStep - 1)) return 'success';
    return ''
  }
  const stepUpdate = values?.userType === USER_TYPE.WORKER ? STEP_UPDATE_WORKER_USER_ARRAY : STEP_UPDATE_TECH_USER_ARRAY;
  return (
    <div className='box-step-header'>
      <div className='header-step'>
        {stepUpdate.map((step, index) => (
          <div
            onClick={() => { handleStepJump(index + 1) }}
            data-kt-stepper-element='nav'
            key={step}
            className={`mx-5 w-200px border step-item ${returnSuccessStep(index)}`}
            style={{ cursor: 'pointer' }}
          >
            <span className='name-step'>{t(`USERS_PAGE.STEP_UPDATE_USER.${step}`)}</span>
            {/* {returnSuccessStep(index) == 'success' && (
              <div className='tick-icon'>
                <img src='assets/images/icons/done-step.svg' />
              </div>
            )} */}
          </div>
        ))}
        <div data-kt-stepper-element='nav' className='display-none'></div>
      </div>
    </div>
  )
}
