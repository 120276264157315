/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { USER_TYPE } from '../../../../utils/common/constants';
import { UserSchemasHelper } from '../../../../utils/helpers/UserValidate';
import { selectLanguage } from '../../../auth';
import { Nationality, UserFormModel, UserModel } from '../../models';
import { userApi } from '../../redux/UserApi';
import AccountInfo from '../create-user/step/account-info/AccountInfo';
import { Completed } from './steps/Completed';
import { toast } from 'react-toastify';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { checkUserInfo } from '../../../../utils/helpers/UserHelper';
import { Response } from 'setup/axios/HttpResponse';

type Props = {
    className?: string,
    adminId?: string | null,
    nationalities: Nationality[],
    onHide?: () => void,
    onUpdated?: (data: UserModel) => void,
}

const initialValues = {
    userType: USER_TYPE.ADMIN,
    avatar: '',
    fullNameAr: '',
    fullNameEn: '',
    email: '',
    phone: '',
    workerId: '',
    firstPhoneNumber: '+966',
    nationality: '',
    password: '',
    confirmPassword: '',
    isActive: false
}
export const UpdateAdminModal: React.FC<Props> = ({ nationalities, adminId, onHide, onUpdated }) => {
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const { t } = useTranslation();
    const UserSchemas = UserSchemasHelper(t)
    const [userImageUrl, setUserImageUrl] = useState("");
    const [currentSchema, setCurrentSchema] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const [initForm, setInitForm] = useState<UserFormModel>(initialValues);

    const language = useAppSelector(selectLanguage);
    const rtlMode = language === 'ar';

    const [isSubmitButton, setSubmitButton] = useState(false);
    const [isLoadingUpdate, setLoadingUpdate] = useState(false);

    useEffect(() => {
        if (adminId) {
            setCurrentSchema(UserSchemas.ACCOUNT_INFO_ADMIN_UPDATE);
        }
    }, [adminId])

    const handleSubmitData = (values: UserFormModel) => {
        if (adminId) {
            let formData = new FormData();
            formData.append("Id", adminId);
            //formData.append('WorkerId', `${values?.workerId}`);
            formData.append('FullNameAr', values?.fullNameAr || '');
            formData.append('FullNameEn', values?.fullNameEn || '');
            //formData.append('NationalityId', `${values?.nationality}`);
            formData.append('Avatar', values.avatar);
            formData.append('Email', values?.email || '');
            formData.append('Password', values?.password || '');
            formData.append('PhoneNumber', `${values?.phone}`);
            formData.append('IsActive', `${values?.isActive}`);
            formData.append('IsRemoveAvatar', `${values?.isRemoveAvatar ? "true" : "false"}`);

            setLoadingUpdate(true);

            userApi.updateAdmin(formData).then(response => {
                if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                    stepper?.current?.goNext();
                    setSubmitButton(true);
                    onUpdated?.(response.data);
                } else {
                    toast.error(response.message, ToastDefaultConfig());
                }
            }).finally(() => setLoadingUpdate(false));
        }

    }

    const loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = (setErrors: any) => {
        if (!stepper.current) {
            return
        }
        
        setErrors({});
        setSubmitButton((stepper.current.currentStepIndex - 1) === stepper.current.totalStepsNumber)
        stepper.current.goPrev();
        // setCurrentSchema(reassignSchemas[stepper.current.currentStepIndex - 1]);
    }
    const checkExistEmail = async (id: any, email: any) => {
        const params = {
            email: email,
            userId: id
        }

        const response: Response<boolean> = await userApi.checkExistEmail(params);

        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            return response.data;
        } else {
            toast.error(response.message, ToastDefaultConfig());
            return false;
        }
    }
    const checkExistPhone = async (id: any, phone: any) => {
        const params = {
            phoneNumber: phone,
            userId: id
        }

        const response: Response<boolean> = await userApi.checkExistPhone(params);

        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            return response.data;
        } else {
            toast.error(response.message, ToastDefaultConfig());
            return false;
        }
    }
    const nextStep = async (values: UserFormModel) => {
        console.log(values);
        if (!stepper.current) {
            return
        }

        // setCurrentSchema(reassignSchemas[stepper.current.currentStepIndex])
        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            setLoadingUpdate(true);
            switch (stepper.current.currentStepIndex) {
                case 1:
                    let requestCheck = {
                        email: values.email,
                        phoneNumber: `${values?.phone}`,
                        workerId: values?.workerId,
                        userId: adminId || ""
                    };

                    if (await checkExistEmail(adminId, values.email)) {
                        toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
                        setLoadingUpdate(false);
                        return;
                    }

                    if (await checkExistPhone(adminId, values.phone)) {
                        toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
                        setLoadingUpdate(false);
                        return;
                    }

                    if (await checkUserInfo(requestCheck, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.USER_HAS_EXIST'))) {
                        setLoadingUpdate(false);
                        return;
                    }
                    handleSubmitData(values);
                    break;
            }
        } else {
            handleClose();
        }
    }


    useEffect(() => {
        if (!adminId) return;

        if (!stepperRef.current) {
            return;
        }
        resetData();
        loadStepper();
        setIsLoading(true);
        userApi.getUserById(adminId).then(x => {
            if (x.data) {
                setUserImageUrl(x.data.personalImageUrl);

                setInitForm({
                    userType: USER_TYPE.ADMIN,
                    avatar: '',
                    fullNameAr: x.data.fullNameAr,
                    fullNameEn: x.data.fullNameEn,
                    email: x.data.email,
                    phone: x.data.phoneNumber,
                    //workerId: x.data.workerId,
                    firstPhoneNumber: x.data.phoneNumber.substring(0, 5) == "00966" ? '+966' : '+973',
                    //nationality: x.data.nationality,
                    password: x.data.password,
                    //confirmPassword: x.data.password,
                    isActive: x.data.isActive
                });
            }
        }).finally(() => setIsLoading(false));
    }, [stepperRef, adminId])

    const handleClose = () => {
        setInitForm({ ...initialValues });
        resetData();
        onHide?.();
    };

    const resetData = () => {
        stepper?.current?.goto(1);
        // setCurrentSchema(reassignSchemas[0]);
        setSubmitButton(false);
    }

    return (
        <>
            <Modal show={!!adminId} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
                {!isSubmitButton && <Modal.Header closeButton className='header-primary'>

                    <Modal.Title className='fw-normal'>{t('USERS_PAGE.STEP_UPDATE_USER.TITLE_SUPERVISOR')}</Modal.Title>
                </Modal.Header>
                }
                <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                >
                    <div className='d-none'>
                        <div data-kt-stepper-element='nav'>
                        </div>
                        <div data-kt-stepper-element='nav'>
                        </div>
                    </div>
                    {isLoading && <div className='update-loading mt-10 mb-10'>
                        <div className='spinner-border' role='status'></div>
                    </div>}
                    {
                        !isLoading && <Formik validationSchema={currentSchema} initialValues={initForm} onSubmit={(values) => { nextStep(values) }} >
                            {({ handleSubmit, errors, touched, setTouched, setErrors, isValid, values, setFieldError, setFieldValue }) => (
                                <form id='kt_create_account_form'>
                                    <div className='current' data-kt-stepper-element='content'>
                                        {nationalities && (
                                            <AccountInfo
                                                errors={errors}
                                                values={values}
                                                touched={touched}
                                                rtl={rtlMode}
                                                nationalities={nationalities}
                                                setFieldValue={setFieldValue}
                                                setFieldError={setFieldError}
                                                setTouched={setTouched}
                                                avatar={userImageUrl}
                                                clearAvatar={() => setUserImageUrl("")}
                                            />
                                        )}
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <Completed />
                                    </div>

                                    {!isSubmitButton ?
                                        <div className='d-flex flex-stack p-5 assign-footer'>

                                            <div className='mr-2'>
                                                {
                                                    stepper?.current?.currentStepIndex === 1 ?
                                                        <button
                                                            onClick={handleClose}
                                                            type='button'
                                                            className='btn btn-secondary w-150px me-3 btn-back'>
                                                            {t('BACK')}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={() => prevStep(setErrors)}
                                                            type='button'
                                                            className='btn btn-secondary w-150px me-3 btn-back'
                                                            data-kt-stepper-action='previous'
                                                        >
                                                            {t('BACK')}
                                                        </button>
                                                }
                                            </div>

                                            <div>

                                                <button type='button' onClick={() => handleSubmit()} className='btn btn-dark w-150px me-3' disabled={isLoadingUpdate || !isValid}>
                                                    {isLoadingUpdate && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                                    <span className='indicator-label'> {stepper?.current?.currentStepIndex && stepper.current.currentStepIndex === 1 ? t('USERS_PAGE.SAVE') : t('NEXT')}</span>

                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="text-center mb-20">
                                            <button type='button' onClick={() => handleSubmit()} className='btn btn-secondary w-150px'>
                                                <span className='indicator-label'> {t('CLOSE')} </span>
                                            </button>
                                        </div>
                                    }
                                </form>
                            )}
                        </Formik>
                    }

                </div>

            </Modal>
        </>
    )
}

