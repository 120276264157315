import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../setup/redux/RootReducer';
import { AccountStatusModel, MonthlyReportModel, OrderStatisticModel } from '../models';

export interface HomeState {
    loadingAccountSetup: boolean;
    loadingReportStatistic: boolean;
    loadingReportOrder: boolean;
    loadingOrderCustomer: boolean;
    accountStatus: AccountStatusModel;
    monthlyReport: MonthlyReportModel;
    orderStatistic: OrderStatisticModel;
    orderCustomer: OrderStatisticModel;
}

const initialState: HomeState = {
    loadingAccountSetup: false,
    loadingReportStatistic: false,
    loadingReportOrder: false,
    loadingOrderCustomer: false,
    accountStatus: {
        accountStatus: false,
        bankStatus: false,
        managerStatus: false,
        teamStatus: false
    },
    monthlyReport: {
        canceledOrders: {
            total: 0,
            week: 0
        },
        client: {
            total: 0,
            week: 0
        },
        completedOrders: {
            total: 0,
            week: 0
        },
        employer: {
            total: 0,
            week: 0
        },
        netProfit: {
            total: 0,
            week: 0
        },
        newOrders: {
            total: 0,
            week: 0
        },
        orders: {
            total: 0,
            week: 0
        },
        totalProfit: {
            total: 0,
            week: 0
        }
    },
    orderStatistic: {
        cityStatistics: [],
        orderStatistics: []
    },
    orderCustomer: {
        cityStatistics: [],
        orderStatistics: []
    }
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        fetchData(state) {
            state.loadingAccountSetup = true;
            state.loadingReportStatistic = true;
            state.loadingReportOrder = true;
            state.loadingOrderCustomer = true;
        },
        fetchDataFailed(state) {
            state.loadingAccountSetup = false;
            state.loadingReportStatistic = false;
            state.loadingReportOrder = false;
            state.loadingOrderCustomer = false;
        },

        setAccountStatus(state, action: PayloadAction<AccountStatusModel>) {
            state.accountStatus = action.payload;
            state.loadingAccountSetup = false;
        },
        setMonthlyReport(state, action: PayloadAction<MonthlyReportModel>) {
            state.monthlyReport = action.payload;
            state.loadingAccountSetup = false;
        },
        setOrderStatistic(state, action: PayloadAction<OrderStatisticModel>) {
            state.orderStatistic = action.payload;
            state.loadingAccountSetup = false;
        },
        setOrderCustomer(state, action: PayloadAction<OrderStatisticModel>) {
            state.orderStatistic = action.payload;
            state.loadingAccountSetup = false;
        }
    },
});

// Actions
export const homeActions = homeSlice.actions;

// Selectors
export const selectHomeLoadingAccountSetup = (state: RootState) => state.home.loadingAccountSetup;
export const selectHomeLoadingOrderCustomer = (state: RootState) => state.home.loadingOrderCustomer;
export const selectHomeLoadingReportOrder = (state: RootState) => state.home.loadingReportOrder;
export const selectHomeLoadingReportStatistic = (state: RootState) => state.home.loadingReportStatistic;

export const selectHomeAccountStatus = (state: RootState) => state.home.accountStatus;
export const selectHomeMonthlyReport = (state: RootState) => state.home.monthlyReport;
export const selectHomeOrderStatistic = (state: RootState) => state.home.orderStatistic;
export const selectHomeOrderCustomer = (state: RootState) => state.home.orderCustomer;

// Reducer
const homeReducer = homeSlice.reducer;
export default homeReducer;