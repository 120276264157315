import { FC, useRef, useEffect, useState } from 'react';
import { connect, useDispatch, ConnectedProps } from 'react-redux';
import { disableSplashScreen, enableSplashScreen, LayoutSplashScreen } from '../../../../theme/layout/core';
import { RootState } from '../../../../setup';
import { authActions, selectAuth, selectErrorDomain, selectLanguage } from '..';
import { useAppSelector } from '../../../../setup/redux/Hooks';
import { HTTP_RESPONSE_STATUS } from '../../../../setup/constants/Http';
import authApi from "./AuthApi";
import { Response } from '../../../../setup/axios/HttpResponse';
import { UserInfoModel, UserModel } from "../models/AuthModel";
import { updateStylePage } from '../../../utils/helpers';

import * as Yup from 'yup';
import { useTranslation, } from 'react-i18next';

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, authActions)
type PropsFromRedux = ConnectedProps<typeof connector>


const AuthInit: FC<PropsFromRedux> = (props) => {
  const { t, } = useTranslation();
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const authInfo = useAppSelector(selectAuth);
  const language = useAppSelector(selectLanguage);

  // We should request user by authToken before rendering the application
  useEffect(() => {
    updateStylePage(language == 'ar');
    checkDomainExist();
    Yup.setLocale({
      mixed: { required: 'VALIDATOR.REQUIRED', },
      string: {
        length: ({ length }) => `VALIDATOR.LENGTH-${length}`,
        min: ({ min }) => `VALIDATOR.MIN_LENGTH-${min}`,
        max: ({ max }) => `VALIDATOR.MAX_LENGTH-${max}`,
        email: () => `VALIDATOR.EMAIL`,
        url: () => `VALIDATOR.URL`
      },
      number: {
        min: ({ min }) => `VALIDATOR.MIN-${min}`,
        max: ({ max }) => `VALIDATOR.MAX-${max}`,
      },
      array: {
        min: ({ min }) => min == -1 ? `VALIDATOR.ARRAY_REQUIRED-${min}` : `VALIDATOR.MIN-${min}`,
      }
    })
  }, [])

  const checkDomainExist = async () => {
    try {
      await authApi.checkDomainExist();
      dispatch(authActions.successDomain());
      if (authInfo && authInfo.token) {
        requestUser()
      } else {
        disableSplashScreen()
        dispatch(authActions.logout())
        setShowSplashScreen(false)
      }
    } catch (error: any) {
      dispatch(authActions.errorDomain());
      disableSplashScreen();
      setShowSplashScreen(false);
      return;
    }
  }

  const requestUser = async () => {
    enableSplashScreen();
    try {
      if (!didRequest.current) {
        const responseUserInfo: Response<UserInfoModel> = await authApi.getUserInfo();
        if (responseUserInfo.status === HTTP_RESPONSE_STATUS.SUCCESS) {
          dispatch(authActions.setUserInfo(responseUserInfo.data));
          dispatch(authActions.getServiceScope());
          dispatch(authActions.fetchServiceScope());
        }
      }
    } catch (error: any) {
      console.error(error);
      if (!didRequest.current) {
        dispatch(authActions.logout())
      }
    } finally {
      disableSplashScreen();
      setShowSplashScreen(false);
    }
    return () => (didRequest.current = true)
  }
  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
