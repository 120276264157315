import "./style.scss"
import React, { useEffect, useRef, useState } from 'react';
import { Button, InputGroup, Row, Col } from 'react-bootstrap-v5';
import DropdownTreeSelect, { TextProps } from 'react-dropdown-tree-select';
import { useTranslation } from 'react-i18next';
import { ServiceTreeNode } from '../../../../../../utils/common/models';
import { KTSVG } from "../../../../../../utils/helpers";


type Props = {
    data: ServiceTreeNode[],
    onChange?: (data: ServiceTreeNode[]) => void,
    onSelectedSubSubService?: (selectedNodes: ServiceTreeNode[]) => void,
    isShowSelectAll?: boolean,
    placeholder?: string
    inlineSearchPlaceholder?: string
}


const TreeServiceUser: React.FC<Props> = ({
    data,
    onChange,
    onSelectedSubSubService,
    isShowSelectAll = true,
    placeholder,
    inlineSearchPlaceholder,
}) => {
    const { t } = useTranslation();

    const texts = {
        placeholder: t(placeholder || 'USERS_PAGE.CONTENT_STEP.SELECT_SERVICE'),
        inlineSearchPlaceholder: t(inlineSearchPlaceholder || 'USERS_PAGE.CONTENT_STEP.SEARCH_SERVICE'),
    } as TextProps
    if (!data) return <></>

    const onChangeService = (currentNode: ServiceTreeNode, selectedNodes: ServiceTreeNode[]) => {
        let newData = [...data];
        let subSubSelected = [] as ServiceTreeNode[];

        // handle click on major tree data
        newData.forEach(majorNode => {
            if (majorNode.majorId === currentNode.majorId) {
                //majorNode.checked = true;
                // Checked sub service
                majorNode.expanded = true;
                majorNode.children?.forEach(subNode => {

                    subNode.disabled = false;
                    subNode.expanded = true;
                    subNode.children?.forEach(subSubNode => {
                        subSubNode.expanded = true;
                        // checked major and sub and sub sub
                        switch (true) {
                            case currentNode.isMajor:
                                subSubNode.checked = currentNode.checked;
                                break;

                            case currentNode.isSub && subSubNode.subId == currentNode.subId:
                                subSubNode.checked = currentNode.checked;
                                break;

                            case currentNode.isSubSub && currentNode.subSubId === subSubNode.subSubId:
                                subSubNode.checked = currentNode.checked;
                                break;
                            default:
                                break;
                        }

                        if (subSubNode.checked) subSubSelected.push(subSubNode);
                    });
                });
            } else {
                majorNode.expanded = false;
                majorNode.children?.forEach(subNode => {

                    subNode.expanded = false;
                    subNode.children?.forEach(subSubNode => {

                        subSubNode.expanded = false;
                        if (subSubNode.checked) subSubSelected.push(subSubNode);
                    });
                });

            }
        });
        onChange?.(newData);
        onSelectedSubSubService?.(subSubSelected);
    }


    const handleSelectedAll = () => {
        let newData = [...data];
        let subSubSelected = [] as ServiceTreeNode[];

        // handle click on major tree data
        newData.forEach(majorNode => {

            majorNode.expanded = false;
            majorNode.children?.forEach(subNode => {

                subNode.expanded = false;
                subNode.children?.forEach(subSubNode => {
                    subSubNode.checked = true;
                    subSubNode.expanded = false;
                    subSubSelected.push(subSubNode);
                });
            });

        });
        onChange?.(newData);
        onSelectedSubSubService?.(subSubSelected);
    }

    const handleUnSelectedAll = () => {
        let newData = [...data];

        // handle click on major tree data
        newData.forEach(majorNode => {

            majorNode.expanded = false;
            majorNode.checked = false;
            majorNode.children?.forEach(subNode => {

                subNode.checked = false;
                subNode.expanded = false;
                subNode.children?.forEach(subSubNode => {
                    subSubNode.checked = false;
                    subSubNode.expanded = false;
                });
            });

        });
        onChange?.(newData);
        let subSubSelected = [] as ServiceTreeNode[];
        onSelectedSubSubService?.(subSubSelected);
    }
    return <Row>
        <Col xl={isShowSelectAll ? 8 : 12} className="mb-3">
            <InputGroup className='filter-calender'>
                <InputGroup.Text id='basic-addon1'>
                    <KTSVG defaultColor={true} path="/assets/images/icons/service-and-skill.svg" />
                </InputGroup.Text>
                <div className='select-service1 flex-root'>
                    <DropdownTreeSelect
                        data={data}
                        className="service-tree1"
                        onChange={onChangeService}
                        inlineSearchInput
                        keepTreeOnSearch
                        showPartiallySelected
                        texts={texts}
                    />
                </div>
            </InputGroup>
        </Col>
        {isShowSelectAll && <Col xl={4} className="mb-3">
            <div className='button-action-select-service'>
                <Button variant='secondary' className='rounded w-150px h-50px' onClick={handleSelectedAll}>{t('USERS_PAGE.CONTENT_STEP.SELECT_ALL')}</Button>
                <Button variant='secondary' className='mx-2 rounded w-150px h-50px' onClick={handleUnSelectedAll}>{t('USERS_PAGE.CONTENT_STEP.UNSELECT_ALL')}</Button>
            </div>
        </Col>}

    </Row>
}

export { TreeServiceUser }