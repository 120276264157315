import './style.scss'
import { Button, Col, Row } from "react-bootstrap-v5"
import { t } from 'i18next'
import { useEffect, useMemo, useState } from "react"
import AddServiceModal from './components/AddServiceModal'
import EditServiceModal from './components/EditServiceModal'
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks'
import _ from 'lodash'
import ServiceItem from './components/ServiceItem'
import { CreatePackageWorkflowServiceRequestBody, PackageWorkflowServiceModel, UpdatePackageWorkflowServiceRequestBody } from '../../models'
import { serviceManagementAction } from '../../redux/ServiceManagementSlice'
import { selectUser } from 'app/modules/auth'
interface Props {
  isLoading?: boolean,
  listService: PackageWorkflowServiceModel[],
  setListService: (list: PackageWorkflowServiceModel[]) => void,
  selectedService: PackageWorkflowServiceModel | null,
  setSelectedService: (data: PackageWorkflowServiceModel | null) => void,
  reloadListService: () => void
}

const ServiceComponent = (props: Props) => {
  const { isLoading, listService, setListService, selectedService, setSelectedService, reloadListService } = props
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser);

  const [isOpenAddService, setIsOpenAddServiceModal] = useState<boolean>(false)
  const [selectedEditService, setSelectedEditService] = useState<PackageWorkflowServiceModel | null>(null)
  const [isLoadingAddService, setIsLoadingAddService] = useState<boolean>(false)
  const [isLoadingEditService, setIsLoadingEditService] = useState<boolean>(false)

  const handleOpenAddService = () => setIsOpenAddServiceModal(true)
  const handleCloseAddService = () => setIsOpenAddServiceModal(false)
  const handleOpenEditService = (serviceData: any) => setSelectedEditService(serviceData)
  const handleCloseEditService = () => setSelectedEditService(null)

  const addServiceCallback = (data: PackageWorkflowServiceModel, res: any | null, err: any) => {
    setIsLoadingAddService(false)
    if (err || !res) {
      return
    }
    handleCloseAddService()
    reloadListService()
  }

  const onAddService = (data: PackageWorkflowServiceModel) => {
    setIsLoadingAddService(true)
    const body: CreatePackageWorkflowServiceRequestBody = {
      nameAr: data.nameAr,
      nameEn: data.nameEn,
      descriptionAr: data.subTitleTextAr || "",
      descriptionEn: data.subTitleTextEn || "",
      status: Boolean(data.status),
      comapanyId: user?.companyId || ""
    }
    dispatch(serviceManagementAction.createPackageWorkflowService({ callback: (res: any | null, err: any) => addServiceCallback(data, res, err), body }))
  }

  const updateServiceCallback = (data: PackageWorkflowServiceModel, res: any | null, err: any) => {
    setIsLoadingEditService(false)
    if (err || !res) {
      return
    }
    handleCloseEditService()

    const newListService = _.cloneDeep(listService).map(item => item.id === data.id ? _.cloneDeep(data) : item)
    if (data.id === selectedService?.id) {
      setSelectedService(_.cloneDeep(data))
    }
    setListService(newListService)
  }

  const onUpdateService = (data: PackageWorkflowServiceModel) => {
    setIsLoadingEditService(true)
    const body: UpdatePackageWorkflowServiceRequestBody = {
      nameAr: data.nameAr,
      nameEn: data.nameEn,
      descriptionAr: data.subTitleTextAr || "",
      descriptionEn: data.subTitleTextEn || "",
      status: Boolean(data.status),
      packageFlowId: data.packageWorkFlowId
    }
    dispatch(serviceManagementAction.updatePackageWorkflowService({ callback: (res: any | null, err: any) => updateServiceCallback(data, res, err), body }))
  }

  const LoadingElement = useMemo(() => () => {
    return (<>
      <Col xs={12} className='skeleton-box w-100 h-100px rounded mb-1 mt-1' />
      <Col xs={12} className='skeleton-box w-100 h-100px rounded mb-1' />
      <Col xs={12} className='skeleton-box w-100 h-100px rounded mb-1' />
      <Col xs={12} className='skeleton-box w-100 h-100px rounded mb-1' />
      <Col xs={12} className='skeleton-box w-100 h-100px rounded' />
    </>)
  }, [])

  return <>
    <AddServiceModal isShow={isOpenAddService} onHide={handleCloseAddService} onAddService={onAddService} isLoading={isLoadingAddService} />
    <EditServiceModal serviceData={selectedEditService} isShow={Boolean(selectedEditService)} onHide={handleCloseEditService} onUpdateService={onUpdateService} isLoading={isLoadingEditService} />
    <Row className='px-6 py-2'>
      <Col xs={12} className='my-4 p-0'>
        <div className="h5 m-0">{t('SERVICE_MANAGEMENT_PAGE.SERVICE.TITLE')}</div>
      </Col>
      <Col xs={12} className='px-0 py-4'>
        <Button onClick={handleOpenAddService} variant='dark' className='w-100 h-50px'>{t('SERVICE_MANAGEMENT_PAGE.SERVICE.ADD_SERVICE')}</Button>
      </Col>
      <Col xs={12} className='service-list-container px-4 py-2'>
        {isLoading ? <LoadingElement /> : listService.map(item => {
          return <Row key={item.id} xs={12} className='m-0 mt-1 p-1'>
            <ServiceItem serviceData={item} onUpdateService={onUpdateService} isSelected={_.isEqual(item, selectedService)} handleSelectService={() => setSelectedService(_.cloneDeep(item))} handleOpenEditService={() => handleOpenEditService(_.cloneDeep(item))} />
          </Row>
        })}
      </Col>
    </Row>
  </>
}

export default ServiceComponent