import moment from 'moment';
import { ServiceScopeModel } from '../../modules/auth/models/AuthModel';
import { SHIFT_CONFIG, SHIFT_TICKET } from '../common/constants';
import { ServiceTreeNode } from '../common/models';
import { ServiceAvailabilityResponse } from './../../modules/tickets/models/CreateTicketModel';

export const convertServiceToTree = (serviceScope?: ServiceScopeModel, serviceAvailability?: ServiceAvailabilityResponse, rtl?: boolean, emptyMessage?: string) => {
    const dataTree = [] as ServiceTreeNode[];

    if (serviceScope) {
        serviceScope?.majorServices?.forEach(majorService => {

            // Major
            let serviceAvailable = serviceAvailability?.majorServicesAvailable[majorService.id];

            if (serviceAvailable) {

                let majorNode = {
                    label: rtl ? majorService.nameAr : majorService.nameEn,
                    value: majorService.id.toString(),
                    children: [] as ServiceTreeNode[],
                    disabled: serviceAvailable ? false : true,
                    majorId: majorService.id,
                    isMajor: true,
                    isUnavailable: serviceAvailable ? false : true
                } as ServiceTreeNode

                // Sub
                let subService = serviceScope?.subServices?.filter(s => s.majorId === majorService.id);

                if (subService) {

                    subService.forEach(subService => {
                        if (subService.isPublished) {
                            let subServiceAvailable = serviceAvailable?.subServices[subService.id];

                            let subNode = {
                                label: rtl ? subService.nameAr : subService.nameEn,
                                value: subService.id.toString(),
                                children: [] as ServiceTreeNode[],
                                subId: subService.id,
                                majorId: majorService.id,
                                isSub: true,
                            } as ServiceTreeNode

                            if (subServiceAvailable) {

                                // Sub Sub
                                let subSubService = serviceScope?.subSubServices?.filter(s => s.subId === subService.id);
                                if (subSubService) {
                                    subSubService.forEach(subSub => {
                                        if(subSub.isPublished) {
                                            let subSubServiceAvailable = subServiceAvailable?.subSubServices[subSub.id];


                                            if (subSubServiceAvailable) {
    
                                                let isHadPriceList = subSubServiceAvailable.priceDetails?.length > 0 && subSubServiceAvailable.priceDetails[0].isSpecifiedPricingModel == true;
    
                                                let subSubNode = {
                                                    label: rtl ? subSub.nameAr : subSub.nameEn,
                                                    value: subSub.id.toString(),
                                                    subId: subService.id,
                                                    majorId: majorService.id,
                                                    subSubId: subSub.id,
                                                    isSubSub: true,
                                                    data: isHadPriceList ? subSubServiceAvailable.priceDetails : null,
                                                    spIds: subSubServiceAvailable.spIds || [],
                                                    quantity: 1,
                                                    price: isHadPriceList ? subSubServiceAvailable.priceDetails[0].price : 0,
                                                } as ServiceTreeNode
    
                                                if (subSubServiceAvailable) {
                                                    subNode?.children?.push(subSubNode);
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                            majorNode?.children?.push(subNode);
                        }
                    });
                }

                // Offer
                // if (serviceAvailability?.offersAvalilable) {
                //     let offerIndex = serviceAvailability.offersAvalilable.findIndex(x => x.majorId == majorService.id);
                //     if (offerIndex > -1) {
                //         let offer = serviceAvailability?.offersAvalilable[offerIndex];
                //         let offerNode = {
                //             label: offer.title,
                //             value: offer.offerId.toString(),
                //             majorId: majorService.id,
                //             isOffer: true,
                //             data: offer,
                //             spIds: offer.spIds || [],
                //         } as ServiceTreeNode
                //         majorNode?.children?.push(offerNode);
                //     }
                // }


                dataTree.push(majorNode);
            }
        });

        // Offer
        if (serviceAvailability?.offersAvalilable && serviceAvailability?.offersAvalilable.length > 0) {
            let majorNode = {
                label: 'Offer',
                isOffer: true,
                children: [] as ServiceTreeNode[],
                // disabled: true,
                // expanded: true
            } as ServiceTreeNode

            serviceAvailability.offersAvalilable.forEach((offer) => {
                let offerNode = {
                    label: offer.titleAr,
                    value: offer.offerId.toString(),
                    majorId: offer.majorId,
                    isOffer: true,
                    data: offer,
                    spIds: offer.spIds || [],
                } as ServiceTreeNode
                majorNode?.children?.push(offerNode);
            });

            dataTree.push(majorNode);
        }
    }

    if (dataTree.length == 0 && emptyMessage) {
        dataTree.push(
            {
                label: emptyMessage,
                value: '',
                disabled: true,
            }
        );
    }

    return dataTree;
}

export const getShiftFromVisitTime = (visitTime: Date | null) => {
    if (!visitTime) return null;
    let visitTimeParsed = moment(visitTime);
    let timeToMinutes: number = visitTimeParsed.local().hours() * 60 + visitTimeParsed.local().minutes();

    if (timeToMinutes >= SHIFT_CONFIG.MORNING.FROM && timeToMinutes <= SHIFT_CONFIG.MORNING.TO) {
        return SHIFT_TICKET.MORNING;

    } else if (timeToMinutes >= SHIFT_CONFIG.AFTERNOON.FROM && timeToMinutes <= SHIFT_CONFIG.AFTERNOON.TO) {
        return SHIFT_TICKET.AFTERNOON;

    } else if (timeToMinutes >= SHIFT_CONFIG.NIGHT.FROM && timeToMinutes <= SHIFT_CONFIG.NIGHT.TO) {
        return SHIFT_TICKET.NIGHT;

    } else {
        return null;
    }
}