export function _sleep(ms: number) {
    return new Promise((resolve: any) => setTimeout(resolve, ms));
}

export const _debounce = function <A extends unknown[]>(callback: (...args: A) => void, wait: number) {
    let timeout: Parameters<typeof clearTimeout>[0];
    return function (...args: A) {
        if (timeout !== undefined) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            callback(...args)
        }, wait);
    }
}

export const isObject = (param: any) => {
    return param !== undefined && param !== null && typeof param == 'object' && !Array.isArray(param)
}
export const isEmptyOrNull = (param?: number | string | object | any[]) => {
    return (
        (param === null || param === undefined) ? true
            : param instanceof Date ? param.toString() == 'Invalid Date'
                : Array.isArray(param) ? param.length === 0
                    : typeof param === 'object' ? Object.keys(param).length === 0
                        : typeof param === 'string' ? param === ''
                            : false
    )
}