/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { SelectTeamMarket } from '../../../../../theme/partials/content/steps/team-select/SelectTeamMarket';
import { clientTimeZoneOffset } from '../../../../utils/common/constants';
import { selectLanguage, selectUser } from '../../../auth';
import { MarketModel, SpTeamMarket, TicketModel } from '../../models';
import { ticketApi } from '../../redux/TicketApi';
import { Completed } from './Complete';


type Props = {
    isShow?: boolean,
    data: MarketModel | null,
    onHide?: () => void,
    onAccepted?: (data: TicketModel) => void,
    onCleared?: (id: string) => void,
}

const MarketAcceptModal: React.FC<Props> = ({ data, isShow, onHide, onAccepted, onCleared }) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const { t } = useTranslation();

    const reassignSchemas = [
        Yup.object({
            spTeam: Yup.string().required(t('REASSIGN_MODAL.TEAM_REQUIRED')).min(1),
        })
    ]
    const language = useAppSelector(selectLanguage);
    const rtlMode = language === 'ar';

    const [currentSchema, setCurrentSchema] = useState(reassignSchemas[0]);
    const [isCompleted, setCompleted] = useState(false);
    const [isLoadingSp, setLoadingSp] = useState(false);
    const [isLoadingAccept, setLoadingAccept] = useState(false);
    const [listSpTeam, setListSpTeam] = useState<SpTeamMarket[]>([]);
    const user = useAppSelector(selectUser);


    const loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }


    const prevStep = () => {
        if (!stepper.current) {
            return
        }

        setCompleted((stepper.current.currentStepIndex - 1) === stepper.current.totalStepsNumber)
        stepper.current.goPrev();
        setCurrentSchema(reassignSchemas[stepper.current.currentStepIndex - 1]);
    }

    const nextStep = (values: any) => {
        console.log(values);
        if (!stepper.current) {
            return
        }


        setCurrentSchema(reassignSchemas[stepper.current.currentStepIndex])
        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {

            switch (stepper.current.currentStepIndex) {
                case 1:
                    handleAcceptTicket(parseInt(values.spTeam));
                    break;
            }
        } else {
            handleClose();
        }
    }

    useEffect(() => {
        if (!isShow || !data) return;

        if (!stepperRef.current) {
            return;
        }
        resetData();
        loadStepper();
        handleSpTeam();
    }, [stepperRef, isShow]);

    const handleSpTeam = () => {
        setListSpTeam([]);
        if (data) {
            setLoadingSp(true);
            ticketApi.getSpTeamMarket({ ticketId: data.id, clientTimeOffset: clientTimeZoneOffset }).then(response => {
                if (response.status === HTTP_RESPONSE_STATUS.SUCCESS && response.data.length > 0) {
                    for (let index = 0; index < response.data.length; index++) {
                        if (!response.data[index].isOfferTicket) {
                            let servicesAvailable = response.data[index].subSubServiceScopes.filter((x) => x.isAvailable);
                            let servicesUnavailable = response.data[index].subSubServiceScopes.filter((x) => !x.isAvailable);

                            response.data[index].isAvailableAll = response.data[index].subSubServiceScopes.length > 0 && servicesAvailable.length === response.data[index].subSubServiceScopes.length;
                            if (!response.data[index].isAvailableAll) {
                                response.data[index].servicesAvailable = servicesAvailable.map((x) => rtlMode ? x.subSubNameAr : x.subSubNameEn).join(', ');
                                response.data[index].servicesUnavailable = servicesUnavailable.map((x) => rtlMode ? x.subSubNameAr : x.subSubNameEn).join(', ');
                            }
                            response.data[index].visitTimeInvalid = response.data[index].isOutOfOperationHours;
                            response.data[index].isServiceNotCover = response.data[index].subSubServiceScopes.length > 0 && servicesUnavailable.length === response.data[index].subSubServiceScopes.length;
                        } else {
                            response.data[index].isServiceNotCover = !response.data[index].majorServiceScope.isAvailable;
                            response.data[index].visitTimeInvalid = response.data[index].isOutOfOperationHours;
                            response.data[index].isAvailableAll = response.data[index].majorServiceScope.isAvailable;
                        }

                    }
                    setListSpTeam(response.data);
                }
            }, reason => {
                onCleared?.(data.id);
                handleClose();
            }).finally(() => setLoadingSp(false));
        }
    }

    const handleClose = () => {
        resetData();
        onHide?.();
    };

    const resetData = () => {
        stepper?.current?.goFirst();
        setCurrentSchema(reassignSchemas[0]);
        setCompleted(false);
    }


    const handleAcceptTicket = (spIndex: number) => {
        if (data) {
            let spTeam = listSpTeam[spIndex];
            setLoadingAccept(true);
            ticketApi.acceptMarketOrder({ ticketId: data?.id, spId: spTeam.spId }).then(response => {
                if (response.status == HTTP_RESPONSE_STATUS.SUCCESS && response.data) {
                    onAccepted?.(response.data);
                    setCompleted(true);
                    stepper?.current?.goNext();
                } else {
                    toast.error(response.message, ToastDefaultConfig());
                }
            }, reason => {
                onCleared?.(data.id);
                handleClose();
            }).finally(() => setLoadingAccept(false));
        }
    }

    return (
        <Modal show={isShow} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
            <Modal.Header closeButton className='header-primary'>
            </Modal.Header>

            <div
                ref={stepperRef}
                className='stepper stepper-links d-flex flex-column'
                id='kt_create_account_stepper'
            >
                <div className='d-none'>
                    <div data-kt-stepper-element='nav'>
                    </div>
                    <div data-kt-stepper-element='nav'>
                    </div>
                </div>

                <Formik validationSchema={currentSchema} initialValues={{ spTeam: '' }} onSubmit={(values) => { nextStep(values) }} >
                    {({ handleSubmit, errors, touched, resetForm, isValid }) => (
                        <form id='kt_create_account_form'>
                            <div data-kt-stepper-element='content' className={stepper?.current?.currentStepIndex == 1 ? 'current' : ''}>
                                <div className='flex-root'>
                                    <SelectTeamMarket data={listSpTeam} isLoading={isLoadingSp} companyImage={user?.companyLogo} rtl={rtlMode} valueName='spTeam' />
                                    {moment().isAfter(moment(data?.scheduledVisitDateTime)) &&
                                        <div className='text-danger mx-9 my-8 bg-light-danger p-2 small rounded'>
                                            <i className="fas fa-exclamation-triangle text-danger"></i> {t('MARKET_MODAL.ORDER_VISIT_IN_PAST')}
                                        </div>
                                    }
                                </div>

                            </div>

                            <div data-kt-stepper-element='content' className={stepper?.current?.currentStepIndex == 2 ? 'current' : ''} >
                                <Completed />
                            </div>
                            {!isCompleted ?
                                <div className='d-flex flex-stack p-5 assign-footer'>

                                    <div className='mr-2'>
                                        {stepper?.current?.currentStepIndex === 1 &&
                                            <button
                                                onClick={handleClose}
                                                type='button'
                                                className='btn btn-secondary w-150px me-3 btn-back'>
                                                {t('BACK')}
                                            </button>
                                        }
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-secondary w-150px me-3 btn-back'
                                            data-kt-stepper-action='previous'
                                        >
                                            {t('BACK')}
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {errors.spTeam && touched.spTeam ? (
                                            <div className='w-100 text-danger'>{errors.spTeam}</div>
                                        ) : null}
                                    </div>

                                    <div>

                                        <button type='button' onClick={() => handleSubmit()} className='btn btn-dark w-150px me-3' disabled={isLoadingAccept || !isValid}>
                                            {isLoadingAccept && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                            <span className='indicator-label'> {stepper?.current?.currentStepIndex && stepper.current.currentStepIndex === 1 ? t('MARKET_MODAL.ACCEPT') : t('NEXT')}</span>

                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="text-center mb-20">
                                    <button type='button' onClick={() => handleSubmit()} className='btn btn-dark w-150px'>
                                        <span className='indicator-label'> {t('FINISH')} </span>
                                    </button>
                                </div>
                            }
                        </form>
                    )}
                </Formik>
            </div>

        </Modal>
    )
}

export { MarketAcceptModal }
