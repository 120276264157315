import axios from "axios";
import { Response } from "../../../../setup/axios/HttpResponse";
import { API_URL } from "../../../utils/common/constants";
import { CreatePackageForMajorServiceCentroRequestBody, CreatePackageWorkflowServiceRequestBody, GetBusInfoDetailByCompanyRequestBody, GetBusInfoDetailByCompanyResponseData, GetListPackageByMajorServiceIdRequestBody, PackageWorkflowServiceModel, ServiceManagementPackageInfoModel, UpdatePackageForMajorServiceCentroRequestBody, UpdatePackageStatusForMajorServiceCentroRequestBody, UpdatePackageWorkflowServiceRequestBody } from "../models";

const ServiceManagementApi = {
  getListPackageWorkflowService(): Promise<Response<PackageWorkflowServiceModel>> {
    const url = `${API_URL}/api/v1/services/get-package-work-flow-for-centro`
    return axios.get(url)
  },
  createPackageWorkflowService(payload: CreatePackageWorkflowServiceRequestBody): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/services/create-package-work-flow-for-centro`;
    return axios.post(url, payload);
  },
  updatePackageWorkflowService(payload: UpdatePackageWorkflowServiceRequestBody): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/services/update-package-work-flow-for-centro`;
    return axios.post(url, payload);
  },
  getBusInfoDetailByCompany(payload: GetBusInfoDetailByCompanyRequestBody): Promise<Response<GetBusInfoDetailByCompanyResponseData>> {
    const url = `${API_URL}/api/v1/services/get-bus-info-detail-by-company${payload?.packageId ? `?packageId=${payload.packageId}` : ""}`;
    return axios.get(url);
  },
  getListPackageByMajorServiceId(payload: GetListPackageByMajorServiceIdRequestBody): Promise<Response<ServiceManagementPackageInfoModel[]>> {
    const url = `${API_URL}/api/v1/services/get-package-info-by-major?majorId=${payload.majorId}`;
    return axios.get(url);
  },
  createPackageForMajorServiceCentro(payload: CreatePackageForMajorServiceCentroRequestBody): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/services/create-new-package-service-for-centro`
    return axios.post(url, payload)
  },
  updatePackageForMajorServiceCentro(payload: UpdatePackageForMajorServiceCentroRequestBody): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/services/update-package-service-for-centro`
    return axios.put(url, payload)
  },
  updatePackageStatusForMajorServiceCentro(payload: UpdatePackageStatusForMajorServiceCentroRequestBody): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/services/update-package-service-info-status`
    return axios.put(url, payload)
  },
};

export { ServiceManagementApi }