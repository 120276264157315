/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Response } from '../../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { SelectDateTime } from '../../../../../theme/partials/content/steps/select-date-time/SelectDateTime';
import { clientTimeZoneOffset } from '../../../../utils/common/constants';
import {
    AvailableTimeRequest,
    AvailableTimeResponse,
    TimeSlot,
    WarrantyRequest,
} from '../../../../utils/common/models';
import { selectLanguage } from '../../../auth';
import { TicketModel } from '../../models';
import { ticketApi } from '../../redux/TicketApi';
import { Completed } from './steps/Completed';

type Props = {
    className?: string,
    data?: TicketModel | null,
    isShow: boolean,
    onHide?: () => void,
    onWarrantied?: (response: TicketModel) => void,
}

interface WarrantyForm {
    timeSlotIndex: string,
}
const initWarrantyForm = {
    timeSlotIndex: '',
} as WarrantyForm



const WarrantyTicketModal: React.FC<Props> = ({ data, isShow, onHide, onWarrantied }) => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const { t } = useTranslation();

    const warrantySchemas = [
        Yup.object({
            timeSlotIndex: Yup.string().required(t('WARRANTY_MODAL.TIME_REQUIRED')).min(1),
        })
    ]

    const language = useAppSelector(selectLanguage);
    const rtlMode = language === 'ar';

    const [currentSchema, setCurrentSchema] = useState(warrantySchemas[0]);
    const [isSubmitButton, setSubmitButton] = useState(false);
    const [isPreselect, setIsPreselect] = useState(true);
    const [isLoadingDateTime, setLoadingDateTime] = useState(true);
    const [isLoadingWarranty, setLoadingWarranty] = useState(false);
    const [dataDateTime, setDataDateTime] = useState<AvailableTimeResponse | null>(null);
    const [messageErrorChangeDate, setMessageErrorChangeDate] = useState<string>('')

    const [dateSelected, setDateSelected] = useState(new Date());

    const handleChangeDate = (date: Date) => {
        setDataDateTime(null);
        setLoadingDateTime(true);
        setDateSelected(date);
        let request = {
            dateCheck: date,
             id: data?.id,
            clientTimeZone: clientTimeZoneOffset,
            isPreselect: isPreselect,
        } as AvailableTimeRequest
        ticketApi.getWarrantyAvailableTime(request).then((response: Response<AvailableTimeResponse>) => {
            if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                if (response.data) {
                    setDataDateTime(response.data);
                    setDateSelected(new Date(response.data.selectedDate));
                    setIsPreselect(false);
                    setMessageErrorChangeDate(response.message)
                }
            } else {
                toast.error(response.message, ToastDefaultConfig());
            }
        }).finally(() => { setLoadingDateTime(false); });
    };

    const handleSubmitData = (values: WarrantyForm) => {
        let timeSlot = dataDateTime?.timeSlots[parseInt(values.timeSlotIndex)] as TimeSlot;
        let request = {
            id: data?.id || '',
            clientTimeZone: clientTimeZoneOffset,
            durationTime: timeSlot ? timeSlot?.end - timeSlot?.start : 0,
            visitTimeOffset: moment(dateSelected).startOf('day').add(timeSlot?.start || 0, 'minutes').toDate()
        } as WarrantyRequest;

        setLoadingWarranty(true);

        ticketApi.createWarranty(request).then((response: Response<any>) => {
            if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                if (response.data) {
                    stepper?.current?.goNext();
                    setSubmitButton(true);
                    onWarrantied?.(response.data);
                }
            } else {
                toast.error(response.message, ToastDefaultConfig());
            }
        }).finally(() => { setLoadingWarranty(false); });
    }

    const loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }

    const nextStep = (values: WarrantyForm) => {
        if (!stepper.current) {
            return
        }
        setCurrentSchema(warrantySchemas[stepper.current.currentStepIndex])
        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            setSubmitButton(false);
            handleSubmitData(values);
        } else {
            handleClose();
        }
    }

    useEffect(() => {
        if (!isShow) return;

        if (!stepperRef.current) {
            return;
        }
        resetData();
        loadStepper();
        handleChangeDate(new Date());
    }, [stepperRef, isShow])

    const handleClose = () => {
        resetData();
        onHide?.();
    };

    const resetData = () => {
        stepper?.current?.goto(1);
        setCurrentSchema(warrantySchemas[0]);
        setSubmitButton(false);
        setIsPreselect(true);
        setLoadingDateTime(false);
        setDataDateTime(null);
        setDateSelected(new Date());
    }

    return (
        <>
            <Modal show={isShow} onHide={handleClose} backdrop="static" keyboard={false} size='lg'>
                <Modal.Header closeButton className='header-primary'>
                    <Modal.Title className='fw-normal'>{t('WARRANTY_MODAL.TITLE')}</Modal.Title>
                </Modal.Header>

                <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                >
                    <div className='d-none'>
                        <div data-kt-stepper-element='nav'>
                        </div>
                        <div data-kt-stepper-element='nav'>
                        </div>
                    </div>

                    <Formik validationSchema={currentSchema} initialValues={initWarrantyForm} onSubmit={(values) => { nextStep(values) }} >
                        {({ handleSubmit, errors, touched, resetForm, isValid, values }) => (
                            <form id='kt_create_account_form'>
                                <div className='current' data-kt-stepper-element='content'>
                                    <SelectDateTime
                                        dateSelected={dateSelected}
                                        timeSlots={dataDateTime?.timeSlots}
                                        messageErrorChangeDate={messageErrorChangeDate}
                                        customerVisitDuration={dataDateTime?.customerVisitDuration || 0}
                                        isLoading={isLoadingDateTime}
                                        onSelectedDate={(data: Date) => { handleChangeDate(data); resetForm(); }}
                                        rtl={rtlMode}
                                        indexSelected={values.timeSlotIndex}
                                    />

                                </div>
                                <div data-kt-stepper-element='content'>
                                    <Completed />
                                </div>

                                {!isSubmitButton ? <div className='d-flex flex-stack p-5 assign-footer'>

                                    <div className='mr-2'>
                                        {stepper?.current?.currentStepIndex === 1 &&
                                            <button
                                                onClick={handleClose}
                                                type='button'
                                                className='btn btn-secondary w-150px me-3 btn-back'>
                                                {t('BACK')}
                                            </button>
                                        }
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {errors.timeSlotIndex && touched.timeSlotIndex ? (
                                            <div className='w-100 text-danger'>{errors.timeSlotIndex}</div>
                                        ) : null}
                                    </div>

                                    <div>
                                        <button type='button' onClick={() => handleSubmit()} className='btn btn-warning me-3' disabled={isLoadingWarranty || !isValid}>
                                            {isLoadingWarranty && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                            <span className='indicator-label'> {t('WARRANTY_MODAL.CREATE_TICKET')}</span>
                                        </button>
                                    </div>
                                </div>
                                    : <div className="text-center mb-20">
                                        <button type='button' onClick={() => handleSubmit()} className='btn btn-dark w-150px'>
                                            <span className='indicator-label'> {t('FINISH')} </span>
                                        </button>
                                    </div>
                                }
                            </form>
                        )}
                    </Formik>
                </div>

            </Modal>
        </>
    )
}

export { WarrantyTicketModal }
