import { Field, FieldConfig, FieldProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap-v5';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import './CustomerNameInput.scss';
export interface IPropsCustomerNameInput {
    minWidth?: number;
    offsetFontWidth?: number;
    customerName?: string;
    customerStatistics?: {
        averageOrder: number,
        totalCompletedOrder: number,
        receivedOrder: number
    }
    placeholder?: string;
    label?: string;
    isB8akCustomer?: boolean
    inputGroup?: React.ReactNode
    disabledInput?: boolean
    isTouchedBlur?: boolean;
    title?: {
        isB8Customer?: string,
        popoverTitle?: string,
        avgOrder?: string,
        totalOrder?: string,
        receivedOrder?: string,
    },
    rtl?: boolean
}
const popover = (customerStatics: IPropsCustomerNameInput['customerStatistics'], title: IPropsCustomerNameInput['title']) => (
    <Popover className='customer-input-popover' style={{ zIndex: 9999 }}>
        <Popover.Header as="h3">{title?.popoverTitle}</Popover.Header>
        <Popover.Body>
            <ol>
                <li>{`${title?.avgOrder}: `}<span>{`${customerStatics?.averageOrder}/5`}</span></li>
                <li>{`${title?.totalOrder}: `}<span>{customerStatics?.totalCompletedOrder}</span></li>
                <li>{`${title?.receivedOrder}: `}<span>{customerStatics?.receivedOrder}</span></li>
            </ol>
        </Popover.Body>
    </Popover>
);

const getTextWidth = (t: string) => {
    const text = document.createElement("span");
    document.body.appendChild(text);

    // text.style.font = "times new roman";
    text.style.fontSize = 14 + "px";
    text.style.height = 'auto';
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.innerHTML = t;

    const width = Math.ceil(text.clientWidth);
    document.body.removeChild(text);
    return width;
}

export const CustomerNameInput = (props: IPropsCustomerNameInput) => {
    const {
        minWidth = 40,
        offsetFontWidth = 5,
        customerName,
        isB8akCustomer,
        inputGroup,
        placeholder,
        label,
        customerStatistics = {
            averageOrder: 0,
            receivedOrder: 0,
            totalCompletedOrder: 0
        },
        disabledInput = false,
        isTouchedBlur = true,
        title = {
            isB8Customer: 'B8ak Customer',
            popoverTitle: 'Customer Statistics',
            avgOrder: 'Average Order Rating',
            totalOrder: 'Total Completed Orders',
            receivedOrder: 'Received Orders',
            ...props.title,
        },
        rtl = false,

        field,
        form
    } = props as IPropsCustomerNameInput & FieldProps;

    const ref = useRef<{
        container?: HTMLDivElement,
        input?: HTMLInputElement,
        widthContainer?: number
    }>({}).current

    const [state, setState] = useState({
        value: '',
        width: minWidth,
        isEditInput: false,
        isTextChange: false
    })

    useEffect(() => {
        ref.widthContainer = (ref.container?.clientWidth || 0) - 50;
    }, [])

    useEffect(() => {
        setState(s => ({ ...s, isTextChange: false, isEditInput: false }))
    }, [customerName])

    const { t } = useTranslation();

    const touched = form?.touched[field.name];
    let _error = form?.errors[field.name];
    let [error, params = ''] = ![null, undefined, ''].includes(_error as any) ? String(_error).split('-') : [_error, '']
    error = ![null, undefined, ''].includes(error as any) ? t(error as string).format(params.split(';')) : error
    const isInvalid = touched === true && error != undefined

    const onChangeText = (t: string) => {
        setState(s => ({ ...s, isTextChange: true }))
        if (field !== undefined)
            form.setFieldValue(field.name, t)
    }

    const onBlurInput = async () => {
        if ((customerName || '').trim() !== '' && state.isEditInput === true /* && !state.isTextChange */)
            setState(s => ({ ...s, isEditInput: false }))
        isTouchedBlur && form?.setFieldTouched(field.name, true);
    }

    const onFocusText = () => {
        if ((customerName || '').trim() !== '') {
            setState(s => ({ ...s, isEditInput: true }));
            setTimeout(() => {
                ref.input?.focus()
            }, 0);
        }
    }

    return (
        <div className={`customer-name-input ${isInvalid ? 'has-error' : ''}`}>
            {label !== undefined && <label className="form-label">{label}</label>}
            <div className='customer-input input-group' onClick={(e) => {
                if ((e.target as any)?.type === 'button') {
                    e.stopPropagation()
                    return;
                }
                ref.input?.focus()
                onFocusText();
            }}>
                {inputGroup !== undefined &&
                    <span className="input-group-text">
                        {inputGroup}
                    </span>
                }
                {(customerName || '') !== '' && !state.isEditInput ?
                    <div className='overlay-customer-statistic form-control'>
                        <span className='pe-5'>{field.value}</span>
                        {isB8akCustomer &&
                            <OverlayTrigger trigger={["hover", "hover"]} placement={rtl ? 'right' : "left"} overlay={popover(customerStatistics, title)}>
                                <button type="button" className='btn-popover'>{title.isB8Customer}</button>
                            </OverlayTrigger>
                        }
                    </div>
                    : <input
                        ref={(e: any) => ref.input = e}
                        className='form-control'
                        // style={{ width: '100%' }}
                        // onFocus={() => onFocusText()}
                        onChange={e => onChangeText(e.target.value)}
                        disabled={disabledInput}
                        placeholder={placeholder}
                        {
                        ...field !== undefined && { value: field.value }
                        }
                        value={field?.value}
                        onBlur={() => onBlurInput()}
                    />
                }
            </div>
            {isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
        </div>
    )
}

export type IPropsFieldCustomerNameInput = IPropsCustomerNameInput & FieldConfig
export const FieldCustomerNameInput = (props: IPropsFieldCustomerNameInput) => <Field component={CustomerNameInput} {...props} />;