/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import { isEmptyOrNull } from 'app/utils/helpers/extendMethod';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Response } from '../../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { USER_TYPE } from '../../../../utils/common/constants';
import { SelectFilter, ServiceTreeNode } from '../../../../utils/common/models';
import { convertOperationObjectToList } from '../../../../utils/helpers';
import { UserSchemasHelper } from '../../../../utils/helpers/UserValidate';
import { ServiceScopeModel } from '../../../auth/models/AuthModel';
import {
    AdminResponse,
    BusNotLinkedDriver,
    CheckStepAccountInfo,
    City,
    DispatchCenter,
    Driver,
    LinkedDispatcher,
    Nationality,
    Skill,
    SPInfos,
    Team,
    UserFormModel,
    UserModel
} from '../../models';
import { OperationHoursAndMap } from '../../models/OperationHoursAndMap';
import { userApi } from '../../redux/UserApi';
import AccountInfo from '../create-user/step/account-info/AccountInfo';
import { StepByStepTechUserHeader } from '../create-user/step/step-by-step-header/StepByStepTechUserHeader';
import WorkInfo from '../create-user/step/work-info/WorkInfo';
import WorkLocation from '../create-user/step/work-location/WorkLocation';
import { Completed } from './steps/Completed';

type Props = {
    className?: string,
    workerId?: string | null,
    nationalities: Nationality[],
    skills: Skill[];
    services?: ServiceScopeModel;
    listAdmin: AdminResponse[];
    busesNotLinkedDriver: BusNotLinkedDriver[];
    dispatchCenters: DispatchCenter[];
    drivers: Driver[];
    linkedDispatchers: LinkedDispatcher[];
    operationHoursAndMap: OperationHoursAndMap[];
    teams: Team[];
    cities: City[];
    onHide?: () => void,
    onUpdated?: (data: UserModel) => void,
    rtlMode: boolean,
}

type IForm = {
    pricePerMonth?: number | string
} & UserFormModel;
const checkExistEmail = async (id: any, email: any) => {
    const params = {
        email: email,
        userId: id
    }

    const response: Response<boolean> = await userApi.checkExistEmail(params);

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        return response.data;
    } else {
        toast.error(response.message, ToastDefaultConfig());
        return false;
    }
}
const checkExistPhone = async (id: any, phone: any) => {
    const params = {
        phoneNumber: phone,
        userId: id
    }

    const response: Response<boolean> = await userApi.checkExistPhone(params);

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        return response.data;
    } else {
        toast.error(response.message, ToastDefaultConfig());
        return false;
    }
}
const checkExistUserInfo = async (id: any, phone: any, email: any) => {
    const params: CheckStepAccountInfo = {
        workerId: '',
        phoneNumber: phone,
        email: email,
        userId: id
    }

    const response: Response<boolean> = await userApi.checkExistUserInfo(params);

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        return response.data;
    } else {
        toast.error(response.message, ToastDefaultConfig());
        return false;
    }
}

const defaultValue = {
    userType: USER_TYPE.WORKER,
    avatar: '',
    fullNameAr: '',
    fullNameEn: '',
    email: '',
    phone: '-1',
    workerId: '',
    firstPhoneNumber: '+966',
    nationality: '',
    password: '',
    //confirmPassword: '',
    isActive: false,
    pricePerMonth: undefined,
    firstNameAr: '',
    firstNameEn: '',
    lastNameAr: '',
    lastNameEn: '',
    regularHours: undefined,
    ramadanHours: undefined,
    cityId: undefined,
    adminId: undefined,
    serviceAreaJson: undefined,
    listServiceId: undefined,
    workerTravel: undefined,
    listWorkerSkill: [],
    listSpLinked: [],
    listCity: [],
    workerDispatchCenter: []
}

export const UpdateWorkerModal: React.FC<Props> = (props) => {
    const {
        cities, teams, operationHoursAndMap, linkedDispatchers, drivers, dispatchCenters,
        busesNotLinkedDriver, skills, services, listAdmin, nationalities, workerId: Id, onHide, onUpdated, rtlMode
    } = props;
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const { t } = useTranslation();
    const UserSchemas = UserSchemasHelper(t)
    const [userImageUrl, setUserImageUrl] = useState("");
    const [currentSchema, setCurrentSchema] = useState<any>(UserSchemas.ACCOUNT_INFO_WORKER);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [initForm, setInitForm] = useState<IForm>(defaultValue);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isStepJump, setIsStepJump] = useState(false)
    const [nextStep, setNextStep] = useState(0)
    const [isSaveForm, setIsSaveForm] = useState(false)
    useEffect(() => {
        if (!Id || teams.length == 0) return;

        resetData();
        setIsLoading(true);

        userApi.getUserById(Id).then(x => {
            if (x.data) {
                setUserImageUrl(x.data.personalImageUrl);
                setInitForm({
                    userType: USER_TYPE.WORKER,
                    avatar: '',
                    firstNameAr: x.data.firstNameAr,
                    firstNameEn: x.data.firstNameEn,
                    lastNameAr: x.data.lastNameAr,
                    lastNameEn: x.data.lastNameEn,
                    email: x.data.email,
                    phone: x.data.phoneNumber,
                    workerId: isEmptyOrNull(x.data.workerId) || x.data.workerId === "null" ? '' : x.data.workerId,
                    firstPhoneNumber: x.data.phoneNumber.substring(0, 5) == "00966" ? '+966' : '+973',
                    nationality: isEmptyOrNull(x.data.nationality) ? '' : x.data.nationality,
                    password: x.data.password,
                    //confirmPassword: x.data.password,
                    isActive: x.data.isActive,
                    regularHours: convertOperationObjectToList(JSON.parse(x.data.operationHours || "{}")),
                    ramadanHours: convertOperationObjectToList(JSON.parse(x.data.ramadanOperationHours || "{}")),
                    cityId: parseInt(("" + x.data.cityId).split('.')[1]),
                    adminId: x.data.spAdminId,
                    serviceAreaJson: !x.data.serviceArea || x.data.serviceArea == 'undefined' ? "" : x.data.serviceArea,
                    listServiceId: x.data.subSubServiceLinkeds,
                    pricePerMonth: x.data.pricePerMonth === undefined ? '' : x.data.pricePerMonth,
                    workerTravel: x.data.isTravelToDifferentCity,
                    listWorkerSkill: _fillDataUpdateForSkill(x.data.skillsLinkedToWorker),
                    listSpLinked: _fillDataUpdateSp(x.data.sPsLinkedToWoker),
                    listCity: _fillDataUpdateCity(x.data.citysLinkedToWorker),
                    workerDispatchCenter: _fillDataUpdateDispatchCenter(x.data.sPsLinkedToWoker)
                });
            }
        }).finally(() => setIsLoading(false));
    }, [Id, teams.length])

    useEffect(() => {
        _loadStepper();
    }, [stepperRef.current != null])

    const onSubmit = async (values: IForm) => {
        // return;
        if (Id) {
            let serviceArea = [
                {
                    type: "POLYGON",
                    id: null,
                    geometry: [values?.serviceArea?.map(x => [x.lat, x.lng])]
                }
            ];
            let formData = new FormData();
            formData.append('Id', Id);
            formData.append('Avatar', values.avatar);
            formData.append('Email', values?.email || '');
            formData.append('Password', values?.password || '');
            formData.append(
                'PhoneNumber',
                `${values?.phone}`
            );
            formData.append('IsActive', `${values?.isActive}`);
            formData.append('UserType', `${values?.userType}`);
            formData.append('FirstNameEn', values.firstNameEn || '')
            formData.append('FirstNameAr', values.firstNameAr || '')
            formData.append('LastNameAr', values.lastNameAr || '')
            formData.append('LastNameEn', values.lastNameEn || '')
            formData.append('WorkerId', `${values?.workerId}`);
            formData.append('PricePerMonth', `${isEmptyOrNull(values?.pricePerMonth) ? '' : values?.pricePerMonth}`);
            formData.append('IsEditPermission', `${values?.isEditPermission || false}`);

            if (values.listService && values.listService?.length > 0) {
                values.listService?.forEach((data: ServiceTreeNode) =>
                    formData.append('SubSubServiceLinkeds', `${data.subSubId}`)
                )
            }

            if (values.listWorkerSkill && values.listWorkerSkill?.length > 0) {
                values.listWorkerSkill?.forEach((data: Skill) =>
                    formData.append('WorkerSkills', `${data.id}`)
                )
            }

            if (values.listCity && values.listCity?.length > 0) {
                values.listCity?.forEach((data: SelectFilter) => formData.append('CityLinkeds', `${data.value}`))
            }

            if (values.listSpLinked && values.listSpLinked?.length > 0) {
                values.listSpLinked?.forEach((data: SelectFilter) => formData.append('SpLinkeds', `${data.value}`))
            }
            formData.append('IsAllowTravel', `${values?.workerTravel || false}`);
            formData.append('NationalityId', `${isEmptyOrNull(values?.nationality) ? '' : values?.nationality}`);
            formData.append('IsRemoveAvatar', `${values?.isRemoveAvatar ? "true" : "false"}`);


            const resp = await userApi.updateWorker(formData).finally(() => setIsLoadingSave(false));
            if (resp.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                stepper?.current?.goto(4);
                setCurrentStep(4)
                setIsSuccess(true);
                onUpdated?.(resp.data);
            } else {
                toast.error(resp.message, ToastDefaultConfig());
            }
        }

    }

    const _loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }

    const onPrevStep = async (setErrors: any) => {
        if (!stepper.current) {
            return
        }
        setErrors({});
        setIsSuccess(false);
        stepper.current.goPrev();

        switch (stepper?.current?.currentStepIndex) {
            case 1:
                setCurrentSchema(UserSchemas.ACCOUNT_INFO_WORKER);
                break
            case 2:
                setCurrentSchema(UserSchemas.WORK_INFO.WORKER)
                break
            case 3:
                setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)
                break
        }
        setCurrentStep(currentStep - 1);
    }
    const handleSubmit = async (values: UserFormModel) => {
        setIsLoading(false)
        setIsLoadingSave(true)
        return await onSubmit(values)
    }
    const onNextStep = async (values: IForm, formikHelpers: FormikHelpers<IForm>) => {
        if (!stepper.current || isLoading) {
            return
        }
        setIsLoading(true)

        switch (currentStep) {
            case 1:
                if (values?.email && await checkExistEmail(Id, values?.email)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                if (await checkExistPhone(Id, values.phone)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                if (values?.email && await checkExistUserInfo(Id, `${values?.phone}`, values?.email)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.USER_HAS_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                formikHelpers.setTouched({})
                setCurrentSchema(UserSchemas.WORK_INFO.WORKER)
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goNext()
                break;
            case 2:
                formikHelpers.setTouched({})
                setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goNext()
                break;
            case 3:

                if (values.workerTravel && (!values.listCity || !values.listCity.length)) {
                    formikHelpers.setFieldError("listCity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITIES'));
                    return;
                }

                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goNext()
                break;
            case 4:
                onHideModal();
                break;
        }
        if (!isSaveForm) {
            setCurrentStep(currentStep + 1);
        }

        setIsLoading(false)
    }
    const onStepJump = async (values: IForm, formikHelpers: FormikHelpers<IForm>) => {
        console.log(nextStep)
        if (!stepper.current || isLoading) {
            return
        }
        setIsLoading(true)
        switch (currentStep) {
            case 1:

                if (values?.email && await checkExistEmail(Id, values?.email)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                if (await checkExistPhone(Id, values.phone)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                if (values?.email && await checkExistUserInfo(Id, `${values?.phone}`, values?.email)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.USER_HAS_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                formikHelpers.setTouched({})
                setCurrentSchema(UserSchemas.WORK_INFO.WORKER)
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);
                break;
            case 2:
                formikHelpers.setTouched({})
                setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);
                break;
            case 3:
                if (values.workerTravel && (!values.listCity || !values.listCity.length)) {
                    formikHelpers.setFieldError("listCity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITIES'));
                }
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);
                break;
            case 4:
                onHideModal();
                break;
        }
        if (!isSaveForm) {
            setCurrentStep(nextStep);
        }
        setIsLoading(false)
    }
    const _fillDataUpdateForSkill = (listIdSelect: number[]) => {

        let listWorkerSkill: Skill[] = [];
        skills.map((item: Skill) => {
            const itemExist = listIdSelect.includes(Number(item.id) || 0);
            return itemExist ? listWorkerSkill.push(item) : ''
        })
        console.log(listWorkerSkill)
        return listWorkerSkill;
    }

    const _fillDataUpdateSp = (listId: string[]) => {
        // let listSp: SelectFilter[] = [];
        // let groupSpInfo: SPInfos[] = [];
        // dispatchCenters.map((item: DispatchCenter) => groupSpInfo = _.concat(groupSpInfo, item.spInfos));
        // groupSpInfo.map((g: SPInfos) => {
        //     const itemExist = listId.includes(g.spId);
        //     return itemExist ? listSp.push({ label: rtlMode ? g.fullNameAr : g.fullNameEn, value: g.spId }) : '';
        // })
        // return listSp;
        return listId.map(e => teams.find(e1 => e1.id == e)).filter((e): e is typeof teams[0] => e != undefined).map(e => ({ label: rtlMode ? e.fullNameAr : e.fullNameEn, value: e.id }));
    }

    const _fillDataUpdateCity = (listId: number[]) => {
        let listCity: SelectFilter[] = [];
        cities.map((c: City) => {
            const itemExist = listId.includes(Number(c.id));
            return itemExist ? listCity.push({ label: rtlMode ? c.nameAr : c.nameEn, value: c.id }) : '';
        })
        return listCity;
    }

    const _fillDataUpdateDispatchCenter = (listId: string[]) => {
        let listSelect: SelectFilter[] = [];
        dispatchCenters.forEach((item: DispatchCenter) => {
            item.spInfos.map((g: SPInfos) => {
                const itemExist = listId.includes(g.spId);
                return itemExist ? listSelect.push({ label: item.name, value: item.id }) : '';
            })
        })
        return listSelect;
    }

    const onHideModal = () => {
        resetData();
        onHide?.();
    };

    const resetData = () => {
        setCurrentStep(1);
        stepper?.current?.goto(1);
        setCurrentSchema(UserSchemas.ACCOUNT_INFO_WORKER);
        setIsSuccess(false);
        setInitForm(defaultValue)
    }

    return (
        <>
            <Modal show={!!Id} onHide={onHideModal} backdrop="static" keyboard={false} size='xl'>
                {!isSuccess &&
                    <Modal.Header closeButton className='header-primary'>
                        <Modal.Title className='fw-normal'>{t('USERS_PAGE.STEP_UPDATE_USER.TITLE_WORKER')}</Modal.Title>
                    </Modal.Header>
                }
                <Formik
                    validationSchema={currentSchema}
                    initialValues={initForm}
                    onSubmit={(values, helpers) => { isStepJump ? onStepJump(values, helpers) : onNextStep(values, helpers) }}
                    initialTouched={{}}
                    enableReinitialize
                >
                    {(props) => {
                        const { handleSubmit, errors, touched, setTouched, setErrors, isValid, values, setFieldError, setFieldValue } = props;
                        // console.log(errors, touched, values)
                        return (
                            <>
                                {(isLoading && initForm.phone == '-1') && <div className='update-loading mt-10 mb-10'>
                                    <div className='spinner-border' role='status'></div>
                                </div>}
                                {initForm.phone != '-1' &&
                                    <div
                                        ref={stepperRef}
                                        className='stepper stepper-links d-flex flex-column'
                                        id='kt_create_account_stepper'
                                    >
                                        <form id='kt_create_account_form'>
                                            <Modal.Body>
                                                {!isSuccess &&
                                                    <StepByStepTechUserHeader values={values} handleStepJump={(step) => { handleSubmit(); setIsStepJump(true); setNextStep(step); setIsSaveForm(false) }} currentStep={currentStep} />
                                                }
                                                <Row className='justify-content-center'>
                                                    <Col sm="12" md="11">
                                                        <div className='current' data-kt-stepper-element='content'>
                                                            {nationalities && (
                                                                <AccountInfo
                                                                    errors={errors}
                                                                    values={values}
                                                                    touched={touched}
                                                                    rtl={rtlMode}
                                                                    nationalities={nationalities}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldError={setFieldError}
                                                                    setTouched={setTouched}
                                                                    avatar={userImageUrl}
                                                                    clearAvatar={() => setUserImageUrl("")}
                                                                />
                                                            )}
                                                        </div>

                                                        <div data-kt-stepper-element='content'>
                                                            <WorkInfo
                                                                errors={errors}
                                                                values={values}
                                                                touched={touched}
                                                                rtl={rtlMode}
                                                                skills={skills}
                                                                services={services}
                                                                busesNotLinkedDriver={busesNotLinkedDriver}
                                                                dispatchCenters={dispatchCenters}
                                                                drivers={drivers}
                                                                linkedDispatchers={linkedDispatchers}
                                                                currentCheckCustomStep={stepper.current?.currentStepIndex || 1}
                                                                setFieldValue={setFieldValue}
                                                                setTouched={setTouched}
                                                                step={stepper.current?.currentStepIndex}
                                                                listAdmin={listAdmin}
                                                                techId={Id}
                                                                isUpdateForm={true}
                                                            />
                                                        </div>
                                                        <div data-kt-stepper-element='content'>
                                                            <WorkLocation
                                                                errors={errors}
                                                                values={values}
                                                                touched={touched}
                                                                rtl={rtlMode}
                                                                teams={teams}
                                                                cities={cities}
                                                                currentCheckCustomStep={stepper.current?.currentStepIndex || 1}
                                                                setFieldValue={setFieldValue}
                                                                setTouched={setTouched}
                                                                step={stepper.current?.currentStepIndex}
                                                                operationHoursAndMap={operationHoursAndMap}
                                                                dispatchCenters={dispatchCenters}
                                                            />
                                                        </div>
                                                        <div data-kt-stepper-element='content'>
                                                            <Completed />
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                            {!isSuccess ?
                                                <Modal.Footer className='d-flex flex-stack p-5 assign-footer'>

                                                    <div className='mr-2'>
                                                        {
                                                            stepper?.current?.currentStepIndex === 1 ?
                                                                <button
                                                                    onClick={onHideModal}
                                                                    type='button'
                                                                    className='btn btn-secondary w-150px me-3 btn-back'>
                                                                    {t('BACK')}
                                                                </button>
                                                                :
                                                                <button
                                                                    onClick={() => { onPrevStep(setErrors); setIsStepJump(false) }}
                                                                    type='button'
                                                                    className='btn btn-secondary w-150px me-3 btn-back'
                                                                    data-kt-stepper-action='previous'
                                                                >
                                                                    {t('BACK')}
                                                                </button>
                                                        }
                                                    </div>
                                                    <div>
                                                        {!(stepper?.current?.currentStepIndex && stepper.current.currentStepIndex === 3) && <button type='button' onClick={() => { handleSubmit(); setIsStepJump(false); setIsSaveForm(false) }} className='btn btn-secondary w-150px me-3 btn-back' disabled={isLoading || isLoadingSave || !isValid}>
                                                            {isLoading && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                                            <span className='indicator-label'>  {t('NEXT')}</span>
                                                        </button>}
                                                        <button type='button' onClick={() => { handleSubmit(); setIsSaveForm(true) }} className='btn btn-dark w-150px me-3' disabled={isLoadingSave || isLoading || !isValid}>
                                                            {isLoadingSave && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                                            <span className='indicator-label'>  {t('USERS_PAGE.SAVE')}</span>
                                                        </button>
                                                    </div>
                                                </Modal.Footer>
                                                :
                                                <div className="text-center mb-20">
                                                    <button type='button' onClick={() => { handleSubmit(); setIsSaveForm(false); setIsStepJump(false) }} className='btn btn-secondary w-150px'>
                                                        <span className='indicator-label'> {t('CLOSE')} </span>
                                                    </button>
                                                </div>
                                            }
                                        </form>
                                    </div>
                                }
                            </>
                        )
                    }}
                </Formik>
            </Modal>
        </>
    )
}

