export const TicketStatus = {
    ToBeAssigned: "TicketStatus_ToBeAssigned",
    Inprogress: "TicketStatus_InProgress",

    Completed_No_Customer_Rating: "TicketStatus_Completed_No_Customer_Rating",
    Completed_And_Customer_Rated: "TicketStatus_Completed_And_Customer_Rated",

    SPDisputed_CustomerNotReachable: "TicketStatus_SPDisputed_CustomerNotReachable",
    SPDisputed_CustomerCanceled: "TicketStatus_SPDisputed_CustomerCanceled",

    SPDisputed_OutOfScope: "TicketStatus_SPDisputed_OutOfScope",
    SPDisputed_PriceDisagreement: "TicketStatus_SPDisputed_PriceDisagreement",
    SPDisputed_RejectTime: "TicketStatus_SPDisputed_RejectTime",
    SPDisputed_VisitTimeNotSuitable: "TicketStatus_SPDisputed_VisitTimeNotSuitable",

    CanceledByB8akAgent: "TicketStatus_CanceledByB8akAgent",
    CanceledByCustomer: "TicketStatus_CanceledByCustomer",

    PreAssign: "PreAssign",
    Completed: "TicketStatus_Completed_",
    Disputed: "TicketStatus_SPDisputed_",
    Canceled: "TicketStatus_Canceled",

    Product_Inprogress: "TicketProductStatus_InProgress",
    Product_Shipped: "TicketProductStatus_Shipped",
    Product_Canceled: "TicketProductStatus_Canceled",
    Product_Delivered: "TicketProductStatus_Delivered",

    InprogressConfirmed: "TicketStatus_InProgress_Confirmed",
    Ticket_Declined: "Ticket_Declined",
    Delivered: "TicketStatus_Delivered",
    Undelivered: "TicketStatus_SPUndelivered_",

    SPUndelivered_NoFemalePresent: "TicketStatus_SPUndelivered_NoFemalePresent",
    SPUndelivered_CustomerNotReachable: "TicketStatus_SPUndelivered_CustomerNotReachable",
    SPUndelivered_Other: "TicketStatus_SPUndelivered_Other"
};

export const PackageTicketCompleteLabel = 'TicketStatus_Completed';

export const TicketStatusColor = {
    ToBeAssigned: "to-be-assign",
    Inprogress: "in-progress",
    CompletedRated: "completed-rated",
    Completed: "completed",
    Canceled: "disputed-customer",
    DisputedCustomer: "disputed-customer",
    DisputedSP: "disputed-sp",

};

export const TicketStatusLabel = {
    "TicketStatus_InProgress": "TICKET_STATUS.IN_PROGRESS",
    "TicketStatus_Canceled": "TICKET_STATUS.CANCELED",
    "TicketStatus_Completed": "TICKET_STATUS.COMPLETED",
    "TicketStatus_SPDisputed_OutOfScope": "TICKET_STATUS.DISPUTED",
    "TicketStatus_SPDisputed_CustomerNotReachable": "TICKET_STATUS.DISPUTED",
    "TicketStatus_SPDisputed_PriceDisagreement": "TICKET_STATUS.DISPUTED",
    "TicketStatus_SPDisputed_RejectTime": "TICKET_STATUS.DISPUTED",
    "TicketStatus_SPDisputed_CustomerCanceled": "TICKET_STATUS.DISPUTED",
    "TicketStatus_SPDisputed_VisitTimeNotSuitable": "TICKET_STATUS.DISPUTED",
    "TicketStatus_CanceledByCustomer": "TICKET_STATUS.CANCELED",
    "TicketStatus_CanceledByB8akAgent": "TICKET_STATUS.CANCELED",
    "TicketStatus_Completed_No_Customer_Rating": "TICKET_STATUS.COMPLETED",
    "TicketStatus_Completed_And_Customer_Rated": "TICKET_STATUS.COMPLETED",
    "TicketStatus_InProgress_Confirmed": "TICKET_STATUS.CONFIRM",
    "TicketStatus_Delivered": "TICKET_STATUS.DELIVERED",
    "TicketStatus_SPUndelivered": "TICKET_STATUS.UNDELIVERED",
    "TicketStatus_SPUndelivered_CustomerNotReachable": "TICKET_STATUS.UNDELIVERED",
    "TicketStatus_SPUndelivered_Other": "TICKET_STATUS.UNDELIVERED",
    "TicketStatus_SPUndelivered_NoFemalePresent": "TICKET_STATUS.UNDELIVERED",
    "TicketStatus_SPUndelivered_CustomerDidnotConfirm": "TICKET_STATUS.UNDELIVERED"
}  as any;

export const TicketStatusClass = {
    "TicketStatus_InProgress": "in-progress",
    "TicketStatus_Canceled": "disputed-customer",
    "TicketStatus_Completed": "completed",
    "TicketStatus_SPDisputed_OutOfScope": "disputed-sp",
    "TicketStatus_SPDisputed_CustomerNotReachable": "disputed-sp",
    "TicketStatus_SPDisputed_PriceDisagreement": "disputed-sp",
    "TicketStatus_SPDisputed_RejectTime": "disputed-sp",
    "TicketStatus_SPDisputed_CustomerCanceled": "disputed-sp",
    "TicketStatus_SPDisputed_VisitTimeNotSuitable": "disputed-sp",
    "TicketStatus_CanceledByCustomer": "disputed-customer",
    "TicketStatus_CanceledByB8akAgent": "disputed-customer",
    "TicketStatus_Completed_No_Customer_Rating": "completed",
    "TicketStatus_Completed_And_Customer_Rated": "completed",
}  as any;

export const TicketUndeliveredLabel = {
    "TicketStatus_SPUndelivered_NoFemalePresent": "TICKET_STATUS.UNDELIVERED_NO_FEMALE",
    "TicketStatus_SPUndelivered_CustomerNotReachable": "TICKET_STATUS.UNDELIVERED_CUSTOMER_NOT_REACHABLE",
    "TicketStatus_SPUndelivered_Other": "TICKET_STATUS.UNDELIVERED_OTHER",
    "TicketStatus_SPUndelivered_CustomerDidnotConfirm": "TICKET_STATUS.UNDELIVERED_OTHER",
} as any;

export const CommerceStatusColor = {
    Product_Inprogress: "commerce-inprogress",
    Product_Shipped: "commerce-shipped",
    Product_Canceled: "commerce-canceled",
    Product_Delivered: "commerce-delivered",

};

export const CommerceStatusLabel = {
    Product_Inprogress: "COMMERCE_STATUS.IN_PROGRESS",
    Product_Shipped: "COMMERCE_STATUS.SHIPPED",
    Product_Canceled: "COMMERCE_STATUS.CANCELLED",
    Product_Delivered: "COMMERCE_STATUS.DELIVERED",
};

export const TicketStatusFilter: {[key: string]: any} = {
    "New": {
        value: "Ticket_UnRead",
        label: "TICKET_FILTER_STATUS.NEW"
    },
    "Inprogress": {
        value: "TicketStatus_InProgress",
        label: "TICKET_FILTER_STATUS.IN_PROGRESS"
    },
    "Completed": {
        value: "TicketStatus_Completed_",
        label: "TICKET_FILTER_STATUS.COMPLETED"
    },
    "Disputed": {
        value: "TicketStatus_SPDisputed_",
        label: "TICKET_FILTER_STATUS.DISPUTED"
    },
    "Attendant": {
        value: "TicketStatus_InProgress_Confirmed",
        label: "TICKET_FILTER_STATUS.ATTENDANT"
    },
    "Unattendant": {
        value: "Ticket_Declined",
        label: "TICKET_FILTER_STATUS.UN_ATTENDANT"
    },
    "Delivered": {
        value: "TicketStatus_Delivered",
        label: "TICKET_FILTER_STATUS.DELIVERED"
    },
    "UnDelivered": {
        value: "TicketStatus_SPUndelivered_",
        label: "TICKET_FILTER_STATUS.UN_DELIVERED"
    },
    "CustomerCanceled": {
        value: "TicketStatus_CanceledByCustomer",
        label: "TICKET_FILTER_STATUS.CUSTOMER_CANCELED"
    },
    "AgentCanceled": {
        value: "TicketStatus_CanceledByB8akAgent",
        label: "TICKET_FILTER_STATUS.AGENT_CANCELED"
    },
    "CenteroCanceled": {
        value: "TicketStatus_Canceled",
        label: "TICKET_FILTER_STATUS.CENTERO_CANCELED"
    }
};

export const ATTENDANCE_STATUS = {
    'Attendant': 'Attendant',
    'Unattendant': 'Unattendant'
}
export const DELIVERY_STATUS = {
    'Delivered': 'Delivered',
    'UndeliveredCustomerNotReachable': 'UndeliveredCustomerNotReachable',
    'UndeliveredNoFemale': 'UndeliveredNoFemale',
    'UndeliveredOther': 'UndeliveredOther',
}
export const ACTION_STATUS = {
    'UndoDelivery': 'UndoDelivery',
}