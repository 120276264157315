import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { userApi } from './UserApi';
import { userActions } from './UserSlice';
import { ResponseArray, Response, ListResponse, ListAdminResponse, WorkersResponse } from '../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../../setup/toast/ToastConfig';

import { SearchUserRequest, Nationality, UserModel, Skill, City, Team, LinkedDispatcher, DispatchCenter, Driver, BusNotLinkedDriver, UsersTypeParams,  ActiveUserParam } from '../models'
import { USER_TYPE } from '../../../utils/common/constants';
import { OperationHoursAndMap } from '../models/OperationHoursAndMap';


function* fetchUsers(action: PayloadAction<SearchUserRequest>) {
  try {
      const response: ListResponse<UserModel> = yield call(userApi.getUsers, action.payload);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchUserDataSuccess(response));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchUserDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchUserDataFailed());
  }
}

function* createUsers(action: PayloadAction<FormData>) {
  try {
      let response: Response<UserModel>;
      switch(action.payload.get('UserType')){
        case USER_TYPE.WORKER:
          response = yield call(userApi.createWorker, action.payload);
          if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(userActions.createUserComplete(response.data));
          } else {
            toast.error(response.message, ToastDefaultConfig());
            yield put(userActions.createUserError());
          }
          break;
        case USER_TYPE.DRIVER:
          response = yield call(userApi.createDriver, action.payload);
          if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(userActions.createUserComplete(response.data));
          } else {
            toast.error(response.message, ToastDefaultConfig());
            yield put(userActions.createUserError());
          }
          break;
        case USER_TYPE.TECHNICIAN:
          response = yield call(userApi.createBus, action.payload);
          if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(userActions.createUserComplete(response.data));
          } else {
            toast.error(response.message, ToastDefaultConfig());
            yield put(userActions.createUserError());
          }
          break;
        default:
          response = yield call(userApi.createAdmin, action.payload);
          if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(userActions.createUserComplete(response.data));
          } else {
            toast.error(response.message, ToastDefaultConfig());
            yield put(userActions.createUserError);
          }
          break;
      }
  } catch (error: any) {
      yield put(userActions.createUserError());
  }
}
// Active User 
function* activeUser(action: PayloadAction<ActiveUserParam>) {
  try {
    const response: ListResponse<any> = yield call(userApi.activeUser, action.payload);
    const userActive = action.payload;
    const responseActive = {...response, userActive};
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(userActions.fetchActiveUserSuccess(responseActive));
    } else {
      toast.error(response.message, ToastDefaultConfig());
      yield put(userActions.fetchActiveUserFailed());
    }
  } catch(error: any) {
    yield put(userActions.fetchActiveUserFailed());
  }
}
// Admin
function* fetchAdmin(action: PayloadAction<UsersTypeParams>) {
  try {
    const response: ListAdminResponse = yield call(userApi.getAllAdmin, action.payload);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(userActions.fetchAdminDataSuccess(response));
    }
  } catch (error: any) {
    yield put(userActions.fetchAdminDataFailed());
  }
}
// Worker
function* fetchWorker(action: PayloadAction<UsersTypeParams>) {
  try {
    const response: WorkersResponse = yield call(userApi.getAllWorkerWithSPLinked, action.payload);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(userActions.fetchWorkerDataSuccess(response));
    }
  } catch (error: any) {
    yield put(userActions.fetchWorkerDataFailed());
  }
}
// nationality
function* fetchNationality() {
  try {
      const response: ResponseArray<Nationality> = yield call(userApi.getAllNationality);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchNationalityDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchNationalityDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchNationalityDataFailed());
  }
}

// skill
function* fetchSkills() {
  try {
      const response: ResponseArray<Skill> = yield call(userApi.getAllSkill);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchSkillDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchSkillDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchSkillDataFailed());
  }
}

// city
function* fetchCities() {
  try {
      const response: ResponseArray<City> = yield call(userApi.getAllCity);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchCityDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchCityDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchCityDataFailed());
  }
}

// Linked Dispatcher
function* fetchDispatchers() {
  try {
      const response: ResponseArray<LinkedDispatcher> = yield call(userApi.getAllLinkedDispatcher);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchDispatcherDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchDispatcherDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchDispatcherDataFailed());
  }
}

// Team
function* fetchTeams() {
  try {
      const response: ResponseArray<Team> = yield call(userApi.getAllTeams);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchTeamDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchTeamDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchTeamDataFailed());
  }
}

// Dispatch Center
function* fetchDispatchCenter() {
  try {
      const response: ResponseArray<DispatchCenter> = yield call(userApi.getAllDispatchCenter);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchDispatchCenterDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchDispatchCenterDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchDispatchCenterDataFailed());
  }
}

// Driver
function* fetchDriver() {
  try {
      const response: ResponseArray<Driver> = yield call(userApi.getAllDriver);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchDriversDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchDriversDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchDriversDataFailed());
  }
}

// Buses
function* fetchBusNotLinkedDriver() {
  try {
      const response: ResponseArray<BusNotLinkedDriver> = yield call(userApi.getAlBusNotLinkedDriver);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchBusNotLinkedDriverDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchBusNotLinkedDriverDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchBusNotLinkedDriverDataFailed());
  }
}

// Operation hours and map
function* fetchOperationHoursAndMap() {
  try {
      const response: ResponseArray<OperationHoursAndMap> = yield call(userApi.getOperationHoursAndMaps);
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        yield put(userActions.fetchOperationHoursAndMapDataSuccess(response.data));
      } else {
          toast.error(response.message, ToastDefaultConfig());
          yield put(userActions.fetchOperationHoursAndMapDataFailed());
      }
  } catch (error: any) {
      yield put(userActions.fetchOperationHoursAndMapDataFailed());
  }
}

export default function* UserSaga() {
  yield takeLatest(userActions.fetchUserData, fetchUsers);
  yield takeLatest(userActions.createUser, createUsers);
  yield takeLatest(userActions.fetchNationalityData, fetchNationality);
  yield takeLatest(userActions.fetchSkillData, fetchSkills);
  yield takeLatest(userActions.fetchCityData, fetchCities);
  yield takeLatest(userActions.fetchTeamData, fetchTeams);
  yield takeLatest(userActions.fetchDispatchCenterData, fetchDispatchCenter);
  yield takeLatest(userActions.fetchDriversData, fetchDriver);
  yield takeLatest(userActions.fetchDispatcherData, fetchDispatchers);
  yield takeLatest(userActions.fetchBusNotLinkedDriverData, fetchBusNotLinkedDriver);
  yield takeLatest(userActions.fetchOperationHoursAndMapData, fetchOperationHoursAndMap);
  yield takeLatest(userActions.fetchActiveUser, activeUser);
  yield takeLatest(userActions.fetchAdminData, fetchAdmin);
  yield takeLatest(userActions.fetchWorkerData, fetchWorker);
}