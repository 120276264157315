import { ServiceScopeModel } from '../../modules/auth/models/AuthModel';
import { SpTeamModel } from '../../modules/tickets';
import { City } from '../../modules/users/models';
import { ServiceTreeNode, FilterTicketNode } from '../common/models';

export const convertServiceToTreeUserModal = (serviceScope?: ServiceScopeModel, rtl?: boolean, emptyMessage?: string) => {
  const dataTree = [] as ServiceTreeNode[];
  if (serviceScope) {
    serviceScope?.majorServices?.forEach(majorService => {
      // Major
      let majorNode = {
        label: rtl ? majorService.nameAr : majorService.nameEn,
        value: majorService.id.toString(),
        children: [] as ServiceTreeNode[],
        disabled: false,
        majorId: majorService.id,
        isMajor: true,
        isUnavailable: false,
        className: `node${Boolean(majorService?.status) ? "--active" : "--inactive"}`,
        tagClassName: `tag-item${Boolean(majorService?.status) ? "--active" : "--inactive"}`
      } as ServiceTreeNode
      // Sub
      let subService = serviceScope?.subServices?.filter(s => s.majorId === majorService.id);

      if (subService) {
        subService.forEach(subService => {
          let subNode = {
            label: rtl ? subService.nameAr : subService.nameEn,
            value: subService.id.toString(),
            children: [] as ServiceTreeNode[],
            subId: subService.id,
            majorId: majorService.id,
            isSub: true,
            className: `node${Boolean(subService?.isPublished) ? "--active" : "--inactive"}`,
            tagClassName: `tag-item${Boolean(subService?.isPublished) ? "--active" : "--inactive"}`
          } as ServiceTreeNode

          let subSubService = serviceScope?.subSubServices?.filter(s => s.subId === subService.id);
          if (subSubService) {
            subSubService.forEach(subSub => {
              let subSubNode = {
                label: rtl ? subSub.nameAr : subSub.nameEn,
                value: subSub.id.toString(),
                subId: subService.id,
                majorId: majorService.id,
                subSubId: subSub.id,
                isSubSub: true,
                data: null,
                spIds: [],
                quantity: 1,
                price: 0,
                className: `node${Boolean(subSub?.isPublished) ? "--active" : "--inactive"}`,
                tagClassName: `tag-item${Boolean(subSub?.isPublished) ? "--active" : "--inactive"}`
              } as ServiceTreeNode
              subNode?.children?.push(subSubNode);
            });
            majorNode?.children?.push(subNode);
          }
        });
      }
      if (!majorNode.children || majorNode.children.length == 0) return;
      let check = true;
      majorNode.children.forEach(sub => {
        if (!sub.children || sub.children.length == 0) check = false;
      })
      if (check) dataTree.push(majorNode);
    });
  }

  if (dataTree.length == 0 && emptyMessage) {
    dataTree.push(
      {
        label: emptyMessage,
        value: '',
        disabled: true,
      }
    );
  }

  return dataTree;
}

export const checkValidPhoneNumber = (firstPhoneNumber: string, phone: string) => {
  if (!phone) return false;
  let regex = /^([0-9]{8})$/;
  if (firstPhoneNumber === '+966') {
    regex = /^([0-9]{9})$/
  }
  if (!regex.test(phone)) return false;
  return true;
}

export const checkValidFullPhoneNumber = (phone: string) => {
  if (!phone) return false;
  let regex = /00966([0-9]{9})$/;
  if (!regex.test(phone)) return false;
  return true;
}

export const checkFullNameUser = (name: string = "") => {
  if (!name || name == "") return true; // error name is require
  const texts = splitName(name);
  if (texts.length <= 1) return false;
  let checkLengthWord = true;
  texts.forEach((word: string) => {
    if (word.length <= 1) checkLengthWord = false;
  })
  return checkLengthWord;
}

const splitName = (name: string = "") => {
  const results = name.split(/(\s+)/).filter((item: string) => item.trim().length > 0);
  return results;
}