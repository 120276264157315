import './style.scss';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string
  finishSuccess?: () => void
  rtl?: boolean
}


const CompletedCancelPackage: FC<Props> = ({finishSuccess}) => {
  const { t } = useTranslation();

  return (
    <div className='box-success center-item-success'>
        <div className="content-success center-item-success">
          <div className="image">
            <img src='/assets/images/pages/order/success.png' alt='success'/>
          </div>
          <div className="text">
            <div className="text__title">
              {t("CANCEL_PACKAGE_MODAL.CANCEL_COMPLETED")}
            </div>
          </div>
        </div>
        <div className="btn-finish">
          <button className='btn-create-emp btn btn-dark fs-14' onClick={finishSuccess}>{t('FINISH')}</button>
        </div>
    </div>
  )
}

export { CompletedCancelPackage }