import {CustomerSearch} from 'app/modules/customers/models'
import {customerApi} from 'app/modules/customers/redux'
import {clientTimeZoneOffset} from 'app/utils/common/constants'
import {
  FieldCustomerNameInput,
  FieldCustomerPhone,
  FieldTagsInputInline,
  FieldTextInput,
  IPropsFieldCustomerNameInput,
} from 'app/utils/helpers/components/Form'
import {isEmptyOrNull} from 'app/utils/helpers/extendMethod'
import {FormikProps} from 'formik'
import {t} from 'i18next'
import {useState} from 'react'
import {HTTP_RESPONSE_STATUS} from 'setup/constants/Http'
import {TagsModel} from '../../models'
import {ticketApi} from '../../redux/TicketApi'
import AccordionItem from './AccordionItem'
import {IForm, ServiceAvailable} from './type'
import {
  defaultAreaInfo,
  defaultCustomerInfo,
  initalTouchedArea,
  initalTouchedCustomerInfo,
} from 'app/utils/common/constants/CreateOrderFormConfig'

export const BasicInfoComponent = (props: {
  index?: number
  rtl?: boolean
  formProps: FormikProps<IForm>
}) => {
  const [state, setState] = useState<{
    isLoading: boolean
    isLoadingMap: boolean
    customerStatistics?: IPropsFieldCustomerNameInput['customerStatistics']
  }>({
    isLoading: false,
    isLoadingMap: false,
  })
  const {
    formProps: {values, setValues, setTouched, setFieldTouched, touched},
    index = 0,
    rtl = false,
  } = props

  const getCustomer = async (customer?: CustomerSearch) => {
    setState({...state, isLoading: customer !== undefined})

    const lat = customer?.customerLat || 0
    const lng = customer?.customerLng || 0
    const cityId = customer?.cityId || 0
    const countryId = customer?.countryId || 0

    const location =
      customer !== undefined && [lat, lng].indexOf(0) < 0
        ? (await customerApi.fetchAddressByLatLng({lat, lng}, false)).data
        : undefined

    const serviceAvailable =
      customer != undefined && [lat, lng, cityId, countryId].indexOf(0) < 0
        ? (
            await ticketApi
              .getMainServiceAvailable({
                lat,
                lng,
                cityId,
                countryId,
                clientTimeZone: clientTimeZoneOffset,
              })
              .catch((e) => ({data: undefined}))
          ).data
        : undefined

    const isB8akCustomer =
      customer != undefined ? (customer.customerType === 1 ? true : false) : undefined

    const customerStatistics =
      customer != undefined
        ? (await customerApi.getCustomerTicketStatistic(customer.id || '', false)).data
        : undefined

    const tags =
      customer != undefined
        ? (await ticketApi.getCustomerTags(customer.id as string)).data
        : undefined

    setState({
      ...state,
      isLoading: false,
      customerStatistics: {
        averageOrder: customerStatistics?.averageRating || 0,
        totalCompletedOrder: customerStatistics?.totalCompleted || 0,
        receivedOrder: customerStatistics?.totalReceived || 0,
      },
    })
    return {
      customer,
      location:
        [lat, lng, cityId, countryId].indexOf(0) >= 0
          ? undefined
          : {
              lat,
              lng,
              cityId,
              countryId,
              location,
            },
      serviceAvailable:
        serviceAvailable === undefined
          ? undefined
          : {
              major: serviceAvailable.majorServicesAvailable,
              offer: serviceAvailable.offersAvalilable,
            },
      isB8akCustomer,
      tags,
    }
  }

  const onSelectedCustomer = async (e?: CustomerSearch) => {
    setValues((s) => ({
      ...s,
      ...defaultCustomerInfo,
    }))
    setTouched({...props.formProps.touched, ...initalTouchedCustomerInfo})

    if (e?.id) {
      const resp = await getCustomer(e)

      setValues((s) => ({
        ...s,
        ...(resp.customer !== undefined && {
          phone: resp.customer.phoneNumber || '',
          backupNumber: resp.customer.backupNumber || '',
          customer_id: resp.customer.id,
          customer_name_handler: resp.customer.fullNameAr || resp.customer.fullNameEn || '',
          customer_name: resp.customer.fullNameAr || resp.customer.fullNameEn || '',
          is_b8ak_customer: resp.isB8akCustomer,
        }),
        ...(resp.serviceAvailable !== undefined && {
          service_available: resp.serviceAvailable,
          spIds: resp.serviceAvailable ? getSpIdsInMajorByLocation(resp.serviceAvailable) : [],
        }),
        ...(resp.location !== undefined &&
          [
            resp.location.lat || 0,
            resp.location.lng || 0,
            resp.location.cityId || 0,
            resp.location.countryId || 0,
          ].indexOf(0) < 0 && {
            location: resp.location,
            map: `https://www.google.com/maps/search/?api=1&query=${resp.location.lat}%2C${resp.location.lng}`,
          }),
        ...(resp.tags !== undefined && {
          tags: resp.tags,
        }),
      }))
    }
  }

  const onChangeGoogleMap = async () => {
    const url = values.map
    if (url === undefined || url.trim() == '') {
    } else {
      setState({...state, isLoadingMap: true})

      let mapError: string | undefined = undefined

      setValues((s) => ({
        ...s,
        ...defaultAreaInfo,
      }))
      setTouched({...props.formProps.touched, ...initalTouchedArea})

      const {data: resp, status} = await customerApi
        .convertUrlLocation(url.replace('&', '%26'), false)
        .catch((e) => ({status: -1, data: undefined}))

      if (status !== HTTP_RESPONSE_STATUS.SUCCESS) {
        mapError = 'CREATE_ORDER_DRAWER.SECTION_1.VALID.MAP_URL_VALID'
        setState({...state, isLoadingMap: false})
      }

      const respService =
        resp == undefined || (resp.lat || 0) === 0 || (resp.lng || 0) === 0
          ? undefined
          : await ticketApi
              .getMainServiceAvailable(
                {
                  lat: resp.lat || 0,
                  lng: resp.lng || 0,
                  cityId: resp.cityId || 0,
                  countryId: resp.countryId || 0,
                  clientTimeZone: clientTimeZoneOffset,
                },
                false
              )
              .catch((e) => ({status: -1, data: undefined}))

      if (respService?.status !== HTTP_RESPONSE_STATUS.SUCCESS) {
        setState({...state, isLoadingMap: false})
      }

      if (
        resp !== undefined &&
        (isEmptyOrNull(respService?.data) ||
          (isEmptyOrNull(respService?.data?.offersAvalilable) &&
            isEmptyOrNull(respService?.data?.majorServicesAvailable)))
      ) {
        mapError = 'CREATE_ORDER_DRAWER.SECTION_1.VALID.SERVICE_EMPTY_WITH_LOCATION'
      }

      const service_available =
        respService?.data === undefined
          ? undefined
          : {
              major: respService.data?.majorServicesAvailable,
              offer: respService.data?.offersAvalilable,
            }

      setValues((s) => ({
        ...s,
        mapError: mapError,
        location: {...resp},
        service_available,
        spIds: service_available ? getSpIdsInMajorByLocation(service_available) : [],
      }))
      setFieldTouched('map', true)

      setState({...state, isLoadingMap: false})
    }
  }

  const onAddTags = async (v: string) => {
    const tag = await (await ticketApi.postCustomerTags({tag: v})).data
    return tag
  }

  const getSpIdsInMajorByLocation = (serviceAvailable: ServiceAvailable) => {
    return (
      !!serviceAvailable &&
      Object.values(serviceAvailable.major)
        .map((item) => {
          return (
            !!item.subServices &&
            Object.values(item.subServices).map((sub) => {
              return !!sub && sub.spIds
            })
          )
        })
        .flat()
        .flat()
        .filter((v, i, a) => a.indexOf(v) === i)
    )
  }

  const clearMapError = () => {
    values.map && setValues((s) => ({...s, mapError: undefined}))
  }

  const onBlurBackupNumber = () => {
    setFieldTouched('backupNumber', true)
  }

  return (
    <AccordionItem
      id={'basic_info'}
      title={`${index}. ${t('CREATE_ORDER_DRAWER.SECTION_1.TITLE')}`}
    >
      <p className='section-title'>{t('CREATE_ORDER_DRAWER.SECTION_1.SUB_TITLE')}</p>
      <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_1.CUSTOMER_INFO')}</p>
      <FieldCustomerPhone<CustomerSearch>
        name='phone'
        placeholder={t('CREATE_ORDER_DRAWER.SECTION_1.MOBILE_NUMBER')}
        disabledInput={state.isLoading}
        optionKey={(e) => e.id as string}
        optionLabel={(e) => (e.fullNameEn || e.fullNameAr || e.phoneNumber) as string}
        optionPick={(e) => String(e.phoneNumber || '')}
        onSelected={(e) => onSelectedCustomer(e)}
        clearField={[{name: 'customer_id', value: ''}]}
        pageInit={1}
        onFetch={async (params) => {
          if (params.textSearch == '') return {customerSearch: [], total: 0, keyWord: ''}
          const resp = (
            await customerApi
              .searchCustomerCreateOrder({
                keyword: params.textSearch,
                page: params.page,
                pageSize: params.pageSize,
              })
              .catch((e) => ({data: []}))
          ).data
          if ('customerSearch' in resp) {
            return {
              customerSearch: resp.customerSearch,
              total: resp?.customerSearch.length,
              keyWord: resp.keyWord,
            }
          }
          return {customerSearch: [], total: 0, keyWord: ''}
        }}
        inputGroup={
          state.isLoading ? (
            <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
          ) : (
            <svg
              width='11'
              height='16'
              viewBox='0 0 11 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.96609 11.3034L5.85359 11.2134C5.81179 11.1851 5.76639 11.1624 5.71859 11.1459L5.58359 11.0859C5.46196 11.0605 5.33592 11.0657 5.21679 11.101C5.09766 11.1364 4.98918 11.2008 4.90109 11.2884C4.83485 11.3613 4.78158 11.445 4.74359 11.5359C4.68684 11.6726 4.67177 11.823 4.70029 11.9682C4.72881 12.1134 4.79964 12.2469 4.90387 12.3519C5.0081 12.4569 5.14107 12.5288 5.28604 12.5584C5.43101 12.5881 5.58151 12.5741 5.71859 12.5184C5.80822 12.475 5.89148 12.4194 5.96609 12.3534C6.07016 12.248 6.14066 12.114 6.16869 11.9685C6.19672 11.8231 6.18103 11.6725 6.12359 11.5359C6.0862 11.4492 6.03276 11.3703 5.96609 11.3034ZM8.43359 0.585938H2.43359C1.83686 0.585938 1.26456 0.82299 0.842604 1.24495C0.420647 1.6669 0.183594 2.2392 0.183594 2.83594V13.3359C0.183594 13.9327 0.420647 14.505 0.842604 14.9269C1.26456 15.3489 1.83686 15.5859 2.43359 15.5859H8.43359C9.03033 15.5859 9.60263 15.3489 10.0246 14.9269C10.4465 14.505 10.6836 13.9327 10.6836 13.3359V2.83594C10.6836 2.2392 10.4465 1.6669 10.0246 1.24495C9.60263 0.82299 9.03033 0.585938 8.43359 0.585938ZM9.18359 13.3359C9.18359 13.5349 9.10458 13.7256 8.96392 13.8663C8.82327 14.0069 8.63251 14.0859 8.43359 14.0859H2.43359C2.23468 14.0859 2.04392 14.0069 1.90326 13.8663C1.76261 13.7256 1.68359 13.5349 1.68359 13.3359V2.83594C1.68359 2.63703 1.76261 2.44626 1.90326 2.30561C2.04392 2.16496 2.23468 2.08594 2.43359 2.08594H8.43359C8.63251 2.08594 8.82327 2.16496 8.96392 2.30561C9.10458 2.44626 9.18359 2.63703 9.18359 2.83594V13.3359Z'
                fill='black'
              />
            </svg>
          )
        }
      />
      <FieldCustomerNameInput
        rtl={rtl}
        name='customer_name'
        label=''
        customerName={values.customer_name_handler}
        isB8akCustomer={values.is_b8ak_customer}
        customerStatistics={state.customerStatistics}
        placeholder={t('CREATE_ORDER_DRAWER.SECTION_1.CUSTOMER_NAME')}
        disabledInput={state.isLoading}
        title={{
          isB8Customer: t('CREATE_ORDER_DRAWER.SECTION_1.IS_B8_CUSTOMER'),
          popoverTitle: t('CREATE_ORDER_DRAWER.SECTION_1.POPOVER_TITLE'),
          avgOrder: t('CREATE_ORDER_DRAWER.SECTION_1.AVG_ORDER'),
          totalOrder: t('CREATE_ORDER_DRAWER.SECTION_1.TOTAL_ORDER'),
          receivedOrder: t('CREATE_ORDER_DRAWER.SECTION_1.RECEIVED_ORDER'),
        }}
        inputGroup={
          state.isLoading ? (
            <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
          ) : (
            <svg
              width='13'
              height='16'
              viewBox='0 0 13 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.769455 15.1495C0.868432 15.1513 0.966756 15.1331 1.05855 15.096C1.15035 15.059 1.23374 15.0038 1.30374 14.9338C1.37374 14.8638 1.42891 14.7804 1.46596 14.6886C1.50301 14.5968 1.52118 14.4985 1.51938 14.3995V12.2496C1.51938 10.5738 2.24417 9.87555 3.89344 9.87555H8.268C9.9438 9.87555 10.6421 10.6003 10.6421 12.2496V14.3995C10.6421 14.5984 10.7211 14.7892 10.8617 14.9298C11.0023 15.0704 11.1931 15.1495 11.392 15.1495C11.5909 15.1495 11.7816 15.0704 11.9223 14.9298C12.0629 14.7892 12.1419 14.5984 12.1419 14.3995V12.2496C12.1419 9.74986 10.767 8.375 8.2673 8.375H3.89414C1.39439 8.3743 0.0195312 9.74916 0.0195312 12.2496V14.3995C0.020084 14.5983 0.0992712 14.7887 0.23979 14.9292C0.380308 15.0697 0.570733 15.1489 0.769455 15.1495Z'
                fill='#00171F'
              />
              <path
                d='M6.09482 7.74823C6.84633 7.74823 7.58098 7.52539 8.20585 7.10788C8.83073 6.69037 9.31777 6.09695 9.60539 5.40265C9.89302 4.70835 9.96831 3.94436 9.82175 3.20727C9.67519 2.47018 9.31335 1.7931 8.78199 1.26165C8.25064 0.730197 7.57363 0.368237 6.83657 0.221538C6.09951 0.0748397 5.3355 0.149991 4.64114 0.437489C3.94679 0.724986 3.35328 1.21192 2.93566 1.83672C2.51804 2.46151 2.29506 3.19612 2.29492 3.94764C2.29465 4.44675 2.39274 4.94104 2.5836 5.40222C2.77445 5.86341 3.05433 6.28246 3.40723 6.63543C3.76013 6.98839 4.17913 7.26834 4.64028 7.45928C5.10143 7.65023 5.5957 7.74841 6.09482 7.74823ZM6.09482 1.64829C6.54975 1.64829 6.99447 1.7832 7.37273 2.03596C7.75098 2.28873 8.04578 2.64799 8.21985 3.06831C8.39391 3.48862 8.43942 3.95112 8.35062 4.39731C8.26181 4.84349 8.04269 5.25332 7.72095 5.57496C7.39921 5.8966 6.98932 6.1156 6.54311 6.20427C6.09689 6.29294 5.63441 6.24729 5.21414 6.0731C4.79388 5.89891 4.43471 5.604 4.18206 5.22566C3.92941 4.84733 3.79463 4.40257 3.79477 3.94764C3.79403 3.64541 3.85303 3.34603 3.96836 3.06668C4.0837 2.78733 4.2531 2.53353 4.46684 2.31986C4.68057 2.10619 4.93443 1.93686 5.21381 1.82161C5.49319 1.70636 5.7926 1.64746 6.09482 1.64829Z'
                fill='#00171F'
              />
            </svg>
          )
        }
      />
      <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_1.TAGS')}</p>
      <FieldTagsInputInline<{customer_id?: string}, TagsModel>
        name='tags'
        tagKey={(e) => e.id}
        tagLabel={(e) => e.tag}
        placeholder={t('CREATE_ORDER_DRAWER.SECTION_1.TAGS_INPUT')}
        inputGroup={
          <svg
            width='15'
            height='15'
            viewBox='0 0 15 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.875 8.75957H12.5C12.875 8.75957 13.125 9.00957 13.125 9.38457C13.125 9.75957 12.875 10.0096 12.5 10.0096H9.75L9.375 13.1971C9.3125 13.5096 9.0625 13.7596 8.75 13.7596H8.6875C8.3125 13.6971 8.0625 13.3846 8.125 13.0721L8.5 10.0096H6L5.625 13.1971C5.5625 13.5096 5.3125 13.7596 5 13.7596H4.9375C4.5625 13.6971 4.3125 13.3846 4.375 13.0721L4.6875 10.0096H2.5C2.125 10.0096 1.875 9.75957 1.875 9.38457C1.875 9.00957 2.125 8.75957 2.5 8.75957H4.875L5.125 6.25957H2.5C2.125 6.25957 1.875 6.00957 1.875 5.63457C1.875 5.25957 2.125 5.00957 2.5 5.00957H5.25L5.625 1.82207C5.6875 1.44707 6 1.19707 6.3125 1.25957C6.6875 1.32207 6.9375 1.63457 6.875 1.94707L6.5 5.00957H9L9.375 1.82207C9.4375 1.44707 9.75 1.19707 10.0625 1.25957C10.4375 1.32207 10.6875 1.63457 10.625 1.94707L10.3125 5.00957H12.5C12.875 5.00957 13.125 5.25957 13.125 5.63457C13.125 6.00957 12.875 6.25957 12.5 6.25957H10.125L9.875 8.75957ZM6.375 6.25957L6.125 8.75957H8.625L8.875 6.25957H6.375Z'
              fill='black'
            />
            <mask
              id='mask0_518_36284'
              style={{maskType: 'alpha'}}
              maskUnits='userSpaceOnUse'
              x='1'
              y='1'
              width='13'
              height='13'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.875 8.75957H12.5C12.875 8.75957 13.125 9.00957 13.125 9.38457C13.125 9.75957 12.875 10.0096 12.5 10.0096H9.75L9.375 13.1971C9.3125 13.5096 9.0625 13.7596 8.75 13.7596H8.6875C8.3125 13.6971 8.0625 13.3846 8.125 13.0721L8.5 10.0096H6L5.625 13.1971C5.5625 13.5096 5.3125 13.7596 5 13.7596H4.9375C4.5625 13.6971 4.3125 13.3846 4.375 13.0721L4.6875 10.0096H2.5C2.125 10.0096 1.875 9.75957 1.875 9.38457C1.875 9.00957 2.125 8.75957 2.5 8.75957H4.875L5.125 6.25957H2.5C2.125 6.25957 1.875 6.00957 1.875 5.63457C1.875 5.25957 2.125 5.00957 2.5 5.00957H5.25L5.625 1.82207C5.6875 1.44707 6 1.19707 6.3125 1.25957C6.6875 1.32207 6.9375 1.63457 6.875 1.94707L6.5 5.00957H9L9.375 1.82207C9.4375 1.44707 9.75 1.19707 10.0625 1.25957C10.4375 1.32207 10.6875 1.63457 10.625 1.94707L10.3125 5.00957H12.5C12.875 5.00957 13.125 5.25957 13.125 5.63457C13.125 6.00957 12.875 6.25957 12.5 6.25957H10.125L9.875 8.75957ZM6.375 6.25957L6.125 8.75957H8.625L8.875 6.25957H6.375Z'
                fill='white'
              />
            </mask>
            <g mask='url(#mask0_518_36284)'>
              <rect width='15' height='15' fill='#00171F' />
            </g>
          </svg>
        }
        onAddTags={(e) => onAddTags(e)}
        params={{customer_id: values.customer_id}}
        onFetch={async (params) => {
          const resp =
            params.textSearch == ''
              ? (values.customer_id || '') == ''
                ? []
                : (
                    await ticketApi
                      .getCustomerTags(values.customer_id as string)
                      .catch((e) => ({data: []}))
                  ).data
              : (await ticketApi.getTagsByKeyword(params.textSearch)).data
          return {
            results: resp,
            total: resp.length,
          }
        }}
      />
      <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_1.ADDRESS')}</p>
      <FieldTextInput
        name='map'
        placeholder={t('CREATE_ORDER_DRAWER.SECTION_1.GOOGLE_MAP_URL')}
        onBlur={() => onChangeGoogleMap()} // đây nhé
        onKeyUp={(e) => (e.keyCode == 13 ? onChangeGoogleMap() : touched.map && clearMapError())}
        disabledInput={state.isLoadingMap}
        inputGroup={
          state.isLoadingMap ? (
            <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
          ) : (
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.41448 4.20003C1.24295 4.20098 1.07653 4.14032 0.944215 4.02861C0.811895 3.9169 0.72206 3.76122 0.690359 3.5887C0.658658 3.41617 0.687099 3.23772 0.770729 3.08445C0.854359 2.93117 0.987877 2.81279 1.14802 2.74991L5.78096 0.772778C5.87826 0.734036 5.98232 0.716279 6.08663 0.72062C6.19094 0.724961 6.29325 0.751306 6.38714 0.798003L9.9243 2.6231L14.2648 0.77346C14.443 0.699874 14.6423 0.700543 14.82 0.775322C14.9978 0.850102 15.1399 0.993063 15.216 1.17366C15.2877 1.3562 15.287 1.56014 15.2141 1.74217C15.1412 1.9242 15.0019 2.06999 14.8257 2.14859L10.1928 4.12571C10.095 4.16433 9.99048 4.18178 9.8858 4.17697C9.78112 4.17216 9.67855 4.14519 9.58457 4.09776L6.02277 2.29789L1.70691 4.14753C1.61188 4.17712 1.51367 4.19475 1.41448 4.20003Z'
                fill='#00171F'
              />
              <path
                d='M1.39087 15.723C1.22137 15.7221 1.05732 15.6616 0.926186 15.5517C0.795053 15.4418 0.704799 15.2891 0.670537 15.1192C0.636276 14.9493 0.660087 14.7725 0.737985 14.6184C0.815882 14.4644 0.943135 14.3424 1.09843 14.2729L5.75602 12.2957C5.85378 12.2565 5.95844 12.2385 6.06337 12.2428C6.16829 12.2472 6.27119 12.2738 6.36553 12.321L9.94998 14.146L14.2905 12.2964C14.4679 12.2241 14.666 12.2261 14.8421 12.3019C15.0181 12.3778 15.158 12.5213 15.2316 12.7017C15.3051 12.8821 15.3065 13.0848 15.2354 13.2663C15.1643 13.4477 15.0264 13.5932 14.8514 13.6715L10.1938 15.6487C10.096 15.6879 9.99137 15.7059 9.88645 15.7016C9.78152 15.6972 9.67863 15.6706 9.58428 15.6234L6.02447 13.8236L1.68197 15.6732C1.58726 15.7018 1.48951 15.7185 1.39087 15.723Z'
                fill='#00171F'
              />
              <path
                d='M6.04734 13.718C5.95059 13.72 5.85445 13.7019 5.76469 13.6649C5.67493 13.6279 5.5934 13.5727 5.52497 13.5027C5.45655 13.4327 5.40264 13.3492 5.36649 13.2574C5.33034 13.1655 5.31269 13.0671 5.31459 12.9681V1.46869C5.31459 1.2698 5.39179 1.07905 5.52921 0.938404C5.66662 0.797762 5.853 0.71875 6.04734 0.71875C6.24167 0.71875 6.42805 0.797762 6.56546 0.938404C6.70288 1.07905 6.78008 1.2698 6.78008 1.46869V12.9681C6.78008 13.167 6.70288 13.3577 6.56546 13.4984C6.42805 13.639 6.24167 13.718 6.04734 13.718Z'
                fill='#00171F'
              />
              <path
                d='M14.5571 13.718C14.4604 13.7199 14.3643 13.7017 14.2746 13.6647C14.1848 13.6277 14.1033 13.5725 14.0349 13.5025C13.9665 13.4325 13.9126 13.3491 13.8764 13.2572C13.8403 13.1654 13.8225 13.0671 13.8243 12.9681V1.46869C13.8243 1.37021 13.8433 1.27269 13.8801 1.1817C13.9169 1.09072 13.9709 1.00804 14.039 0.938404C14.107 0.868765 14.1878 0.813524 14.2767 0.775836C14.3656 0.738148 14.4609 0.71875 14.5571 0.71875C14.6533 0.71875 14.7486 0.738148 14.8375 0.775836C14.9264 0.813524 15.0072 0.868765 15.0752 0.938404C15.1433 1.00804 15.1972 1.09072 15.2341 1.1817C15.2709 1.27269 15.2898 1.37021 15.2898 1.46869V12.9681C15.2853 13.1655 15.2066 13.3535 15.0702 13.4932C14.9337 13.6328 14.75 13.7133 14.5571 13.718Z'
                fill='#00171F'
              />
              <path
                d='M1.41452 15.6998C1.31778 15.7018 1.22164 15.6837 1.13188 15.6467C1.04212 15.6097 0.960583 15.5545 0.892159 15.4845C0.823735 15.4145 0.769832 15.331 0.733681 15.2391C0.697531 15.1473 0.679877 15.0489 0.681779 14.9499V3.45321C0.679877 3.35419 0.697531 3.2558 0.733681 3.16393C0.769832 3.07207 0.823735 2.98862 0.892159 2.91859C0.960583 2.84855 1.04212 2.79339 1.13188 2.75639C1.22164 2.71939 1.31778 2.70132 1.41452 2.70327C1.51116 2.70151 1.60715 2.71971 1.69676 2.75679C1.78636 2.79388 1.86774 2.84907 1.93602 2.91908C2.0043 2.9891 2.05808 3.07248 2.09415 3.16426C2.13022 3.25603 2.14783 3.35431 2.14594 3.45321V14.9499C2.14138 15.1471 2.0629 15.3349 1.92674 15.4745C1.79059 15.6141 1.60719 15.6948 1.41452 15.6998Z'
                fill='#00171F'
              />
              <path
                d='M9.8989 15.6997C9.80215 15.7016 9.70602 15.6835 9.61625 15.6465C9.52649 15.6095 9.44496 15.5544 9.37653 15.4843C9.30811 15.4143 9.25421 15.3309 9.21806 15.239C9.18191 15.1471 9.16425 15.0487 9.16615 14.9497V3.45307C9.16615 3.25417 9.24335 3.06342 9.38077 2.92278C9.51819 2.78214 9.70456 2.70313 9.8989 2.70312C10.0932 2.70312 10.2796 2.78214 10.417 2.92278C10.5544 3.06342 10.6316 3.25417 10.6316 3.45307V14.9497C10.6316 15.1486 10.5544 15.3394 10.417 15.48C10.2796 15.6207 10.0932 15.6997 9.8989 15.6997Z'
                fill='#00171F'
              />
            </svg>
          )
        }
      />
      {(values.location?.lng || 0) != 0 && (values.location?.lat || 0) != 0 && (
        <div className='d-flex justify-content-between text-muted mt-1'>
          <p>{`${values.location?.location}`}</p>
          <p>{`lat: ${values.location?.lat}, lng: ${values.location?.lng}`}</p>
        </div>
      )}
      <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_1.OTHER_INFO')}</p>
      <FieldTextInput
        name='backupNumber'
        placeholder={t('CREATE_ORDER_DRAWER.SECTION_1.BACKUP_NUMBER_PLACEHOLDER')}
        onBlur={() => onBlurBackupNumber()} // đây nhé
        disabledInput={state.isLoadingMap}
        inputGroup={
          <svg
            width='11'
            height='16'
            viewBox='0 0 11 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.96609 11.3034L5.85359 11.2134C5.81179 11.1851 5.76639 11.1624 5.71859 11.1459L5.58359 11.0859C5.46196 11.0605 5.33592 11.0657 5.21679 11.101C5.09766 11.1364 4.98918 11.2008 4.90109 11.2884C4.83485 11.3613 4.78158 11.445 4.74359 11.5359C4.68684 11.6726 4.67177 11.823 4.70029 11.9682C4.72881 12.1134 4.79964 12.2469 4.90387 12.3519C5.0081 12.4569 5.14107 12.5288 5.28604 12.5584C5.43101 12.5881 5.58151 12.5741 5.71859 12.5184C5.80822 12.475 5.89148 12.4194 5.96609 12.3534C6.07016 12.248 6.14066 12.114 6.16869 11.9685C6.19672 11.8231 6.18103 11.6725 6.12359 11.5359C6.0862 11.4492 6.03276 11.3703 5.96609 11.3034ZM8.43359 0.585938H2.43359C1.83686 0.585938 1.26456 0.82299 0.842604 1.24495C0.420647 1.6669 0.183594 2.2392 0.183594 2.83594V13.3359C0.183594 13.9327 0.420647 14.505 0.842604 14.9269C1.26456 15.3489 1.83686 15.5859 2.43359 15.5859H8.43359C9.03033 15.5859 9.60263 15.3489 10.0246 14.9269C10.4465 14.505 10.6836 13.9327 10.6836 13.3359V2.83594C10.6836 2.2392 10.4465 1.6669 10.0246 1.24495C9.60263 0.82299 9.03033 0.585938 8.43359 0.585938ZM9.18359 13.3359C9.18359 13.5349 9.10458 13.7256 8.96392 13.8663C8.82327 14.0069 8.63251 14.0859 8.43359 14.0859H2.43359C2.23468 14.0859 2.04392 14.0069 1.90326 13.8663C1.76261 13.7256 1.68359 13.5349 1.68359 13.3359V2.83594C1.68359 2.63703 1.76261 2.44626 1.90326 2.30561C2.04392 2.16496 2.23468 2.08594 2.43359 2.08594H8.43359C8.63251 2.08594 8.82327 2.16496 8.96392 2.30561C9.10458 2.44626 9.18359 2.63703 9.18359 2.83594V13.3359Z'
              fill='black'
            />
          </svg>
        }
      />
      <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_1.NOTES')}</p>
      <FieldTextInput
        name='note'
        placeholder={t('CREATE_ORDER_DRAWER.SECTION_1.NOTES_PLACEHOLDER')}
        multi
        rows={3}
      />
    </AccordionItem>
  )
}
