import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { selectLanguage, selectUser } from '../../../../app/modules/auth';
import {
	selectTicketFlagData,
	selectTicketLoadingFlag,
	selectTicketLoadingMarket,
	selectTicketMarketData,
	ticketActions,
} from '../../../../app/modules/tickets/redux/TicketSlice';
import { Languages } from '../../../../app/utils/common/constants';
import { toAbsoluteUrl } from '../../../../app/utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../../setup/redux/Hooks';
import { HeaderNotificationsMenu, HeaderUserMenu } from '../../../partials';

const
	toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
	const user = useAppSelector(selectUser);
	const language = useAppSelector(selectLanguage);
	const loadingFlag = useAppSelector(selectTicketLoadingFlag);
	const loadingMarket = useAppSelector(selectTicketLoadingMarket);

	const flagData = useAppSelector(selectTicketFlagData);
	const marketData = useAppSelector(selectTicketMarketData);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		handleGetFlag();
	}, []);

	const handleGetFlag = () => {
		dispatch(ticketActions.getFlag({ pageIndex: 1, pageSize: 100 }));
	}

	return (
		<div className='d-flex align-items-stretch flex-shrink-0'>

			{/* BANK */}
			<div className='d-flex align-items-center border-start px-2'>
				{/* begin::Menu wrapper */}
				<div
					className={clsx(
						'btn btn-active-light-primary position-relative justify-content-center d-flex align-items-center',
						toolbarButtonHeightClass
					)}
					id='kt_drawer_chat_toggle'
				>
					<img src='/assets/images/icons/bank.svg' alt='back'></img>
					{loadingMarket ?
						<div className='position-absolute translate-middle start-15'>
							<div className="spinner-border spinner-border-sm text-warning" role="status"></div>
						</div> :
						marketData.length > 0 &&
						<div className='position-absolute translate-middle start-15'>
							<span className='p-1 rounded-pill bg-danger text-white fs-12'>{marketData.length}</span>
						</div>
					}
				</div>
				{/* end::Menu wrapper */}
			</div>

			{/* NOTIFICATIONS */}
			<div className='d-flex align-items-center border-start px-2'>
				{/* begin::Menu- wrapper */}
				<div
					className={clsx(
						'btn btn-active-light-primary position-relative justify-content-center d-flex align-items-center',
						toolbarButtonHeightClass
					)}
					data-kt-menu-trigger='click'
					data-kt-menu-attach='parent'
					data-kt-menu-placement={language == Languages.en ? 'bottom-end' : 'bottom-start'}
					data-kt-menu-flip='bottom'
				>
					<img src='/assets/images/icons/notification.svg' alt='notification'></img>

					{loadingFlag ?
						<div className='position-absolute translate-middle start-15'>
							<div className="spinner-border spinner-border-sm text-danger" role="status"></div>
						</div>
						:
						flagData.total > 0 &&
						<div className='position-absolute translate-middle start-15'>
							<span className='p-1 rounded-pill bg-danger text-white fs-12'>{flagData?.total}</span>
						</div>
					}

				</div>
				<HeaderNotificationsMenu data={flagData} loading={loadingFlag} onRefresh={handleGetFlag} />
				{/* end::Menu wrapper */}
			</div>

			{/* begin::User */}
			<div
				className='d-flex align-items-center border-start ps-4'
				id='kt_header_user_menu_toggle'
			>

				{/* begin::Toggle */}
				<div
					className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
					data-kt-menu-trigger='click'
					data-kt-menu-attach='parent'
					data-kt-menu-placement='bottom-end'
					data-kt-menu-flip='bottom'
				>
					<div className='d-flex align-items-center'>

						{/* <img width={32} height={32} className='rounded-circle me-3' src={toAbsoluteUrl('/media/avatars/150-26.jpg')} alt='metronic' /> */}

						<div className="d-flex flex-column me-3">
							<strong>{language === Languages.ar ? user?.fullNameAr : user?.fullNameEn}</strong>
							<span className='small'>{user?.userType === '200' ? t('HEADER.SERVICE_PROVIDER') : t('HEADER.SERVICE_PROVIDER_ADMIN')}</span>
						</div>
						<i className="fas fa-chevron-down small px-1"></i>

					</div>


				</div>
				<HeaderUserMenu />
				{/* end::Toggle */}
			</div>
			{/* end::User */}
		</div>
	)
}

export { Topbar }
