import {Field, FieldConfig, FieldProps} from 'formik'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Form} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {_debounce, _sleep} from 'app/utils/helpers/extendMethod'
import './CustomerPhone.scss'
import {InfiniteScroll} from './InfiniteScroll'
import {CustomerSearch} from 'app/modules/customers/models'

export interface IPropsCustomerPhone<T> {
  disabledInput?: boolean
  inputGroup?: React.ReactNode
  label?: string
  onFetch?: (params: {
    page: number
    pageSize: number
    textSearch: string
  }) =>
    | {total: number; customerSearch: CustomerSearch[]; keyWord: string}
    | Promise<{total: number; customerSearch: CustomerSearch[]; keyWord: string}>
  onSelected?: (e?: CustomerSearch) => void
  optionKey?: (e: CustomerSearch, index?: number) => number | string
  optionLabel?: (e: CustomerSearch, index?: number) => number | string
  optionPick?: (e: CustomerSearch, index?: number) => number | string
  pageInit?: number
  pageSize?: number
  placeholder?: string
  selected?: T
  valueInput?: string
  isTouchedBlur?: boolean
  clearField? : {name: string, value: any}[]
}

export const CustomerPhone = <T,>(props: IPropsCustomerPhone<T>) => {
  const {
    disabledInput = false,
    clearField=[],
    inputGroup,
    label,
    onFetch: _onFetch,
    onSelected: _onSelected,
    optionKey = (e, index) => index || 0,
    optionLabel = (e, index) => 'Item-' + ((index || 0) + 1),
    optionPick,
    pageInit = 0,
    pageSize = 10,
    placeholder,
    valueInput = '',
    isTouchedBlur = true,

    field,
    form,
  } = props as IPropsCustomerPhone<T> & FieldProps

  const params = useRef<{
    isTextChange?: boolean
    page: number
    pageSize: number
    textSearch: string
    total: number
  }>({
    isTextChange: false,
    page: pageInit,
    pageSize: pageSize,
    textSearch: '',
    total: 0,
  })

  const [state, setState] = useState<{
    isLoading: boolean
    isShowSelect: boolean
    page: number
    customerSearch: CustomerSearch[]
    textSearch: string
    total: number
    keyWord: string
  }>({
    isLoading: false,
    isShowSelect: false,
    page: pageInit,
    customerSearch: [],
    textSearch: '',
    total: 0,
    keyWord: '',
  })

  useEffect(() => {
    if (field?.value === '') {
      onChangeInput('')
    }
  }, [field?.value])

  const {t} = useTranslation()

  const onFocusInput = () => {
    setState((s) => ({...s, isShowSelect: true}))
    if (params.current.textSearch === '' && state.customerSearch.length === 0)
      handleLoadMore(pageInit)
  }

  const onBlurInput = async () => {
    await _sleep(200)
    setState((s) => ({...s, isShowSelect: false}))

    params.current.isTextChange && _onSelected && _onSelected(undefined)
    params.current = {...params.current, isTextChange: false}

    isTouchedBlur && form?.setFieldTouched(field.name, true)
  }

  const onChangeInput = (text: string) => {
    form?.setFieldValue(field.name, text)
    clearField.forEach(el=> 
      form?.setFieldValue(el.name,el.value)
    )    
    params.current = {
      ...params.current,
      page: pageInit,
      total: 0,
      textSearch: text,
      isTextChange: true,
    }
    setState((s) => ({...s, textSearch: text}))
    onFetch()
  }

  const onFetch = useCallback(
    _debounce(async () => {
      !state.isLoading && setState((s) => ({...s, isLoading: true}))

      const {total, customerSearch, keyWord} =
        _onFetch === undefined
          ? {total: 0, customerSearch: [], keyWord: ''}
          : await _onFetch({
              page: params.current.page,
              pageSize: params.current.pageSize,
              textSearch: params.current.textSearch,
            })

      params.current = {...params.current, total}

      if (keyWord === params.current.textSearch) {
        setState((s) => ({
          ...s,
          isLoading: false,
          total,
          customerSearch:
            params.current.page === pageInit
              ? customerSearch
              : [...s.customerSearch, ...customerSearch],
        }))
        return
      }
    }, 600),
    [state.isLoading]
  )

  const handleLoadMore = useCallback(
    _debounce((page?: number) => {
      if (state.isLoading) return
      const _page = page !== undefined ? page : params.current.page + 1
      params.current = {...params.current, page: _page, ...(page !== undefined && {total: 0})}
      onFetch()
    }, 200),
    [state.isLoading]
  )

  const onSelected = (e: CustomerSearch, index: number) => {
    optionPick && form?.setFieldValue(field.name, optionPick(e), true)
    _onSelected && _onSelected(e)
    params.current = {...params.current, isTextChange: false}
  }

  const touched = form?.touched[field.name]
  let _error = form?.errors[field.name]
  let [error, _params = ''] = ![null, undefined, ''].includes(_error as any)
    ? String(_error).split('-')
    : [_error, '']
  error = ![null, undefined, ''].includes(error as any)
    ? t(error as string).format(_params.split(';'))
    : error
  const isInvalid = touched === true && error != undefined

  return (
    <div className={`customer-phone-container ${isInvalid ? 'has-error' : ''}`}>
      {label !== undefined && <label className='form-label'>{label}</label>}
      <div className='customer-phone input-group'>
        {inputGroup !== undefined && <span className='input-group-text'>{inputGroup}</span>}
        <input
          className='form-control'
          disabled={disabledInput}
          value={field !== undefined ? field.value : valueInput}
          placeholder={placeholder}
          onFocus={() => onFocusInput()}
          onBlur={() => onBlurInput()}
          onChange={(e) => onChangeInput(e.target.value)}
        />
      </div>
      {state.isShowSelect && state.customerSearch.length > 0 && (
        <div className='suggest-list-container'>
          <InfiniteScroll
            className={'suggest-list text-center'}
            onFetch={() => {
              handleLoadMore()
            }}
          >
            {/* {(state.isLoading) && <i className="fa fa-solid fa-spinner my-5"></i>} */}
            {state.customerSearch.map((e, index) => (
              <button
                key={optionKey(e, index)}
                onClick={() => onSelected(e, index)}
                className='suggest-item text-start'
                type='button'
              >
                <p className='phone-label--options'>{optionLabel(e, index)}</p>
              </button>
            ))}
          </InfiniteScroll>
        </div>
      )}
      {isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
    </div>
  )
}

export type IPropsFieldCustomerPhone<T> = IPropsCustomerPhone<T> & FieldConfig
export const FieldCustomerPhone = <T,>(props: IPropsFieldCustomerPhone<T>) => (
  <Field component={CustomerPhone} {...props} />
)
