export const EventLogName = {
  Ticket_Read: 'Ticket_Read',
  Ticket_Reassigned: 'Ticket_Reassigned',
  Ticket_Go_To_Market: 'Ticket_Go_To_Market',
  Market_Ticket_Clear: 'Market_Ticket_Clear',
  Market_Ticket_Accepted: 'Market_Ticket_Accepted',
  Acknowledge_Timeout: 'Acknowledge_Timeout',
  Acknowledged_Ticket: 'Acknowledged_Ticket',
  Ticket_Disputed: 'Ticket_Disputed',
  Reassigned_Under_Warranty: 'Reassigned_Under_Warranty',
  SP_Reassigned: 'SP_Reassigned',
  SP_Join_Offer: 'SP_Join_Offer',
  SP_Left_Offer: 'SP_Left_Offer',
  SP_Reject_Offer: 'SP_Reject_Offer',
  SP_Decide_Later: 'SP_Decide_Later',
  Awareness_Activated: 'Awareness_Activated',
  Awareness_Stoppped: 'Awareness_Stoppped',
  Awareness_Acknowledged: 'Awareness_Acknowledged',
  WalletEnforcement_Disabled: 'WalletEnforcement_Disabled',
  WalletEnforcement_Enabled: 'WalletEnforcement_Enabled',
  WalletEnforcement_StatusUpdate: 'WalletEnforcement_StatusUpdate',
  WalletEnforcement_PopupButtonClicked: 'WalletEnforcement_PopupButtonClicked',
  SP_Performance_Read: 'SP_Performance_Read',
  Market_Ticket_SPApplied: 'Market_Ticket_SPApplied',
  Market_Ticket_SPUnapplied: 'Market_Ticket_SPUnapplied',
  Auto_AssignTicket_To_ApplySP: 'Auto_AssignTicket_To_ApplySP',
  Ticket_Canceled: 'Ticket_Canceled',
  Ticket_Canceled_With_Refund: 'Ticket_Canceled with refund',
  Delete_Project_Bidding: 'Delete_Project_Bidding',
  Send_Mail_Receipt: 'Send_Mail_Receipt',
  ReSend_Mail_Recipt: 'ReSend_Mail_Receipt',
  Awarded_Project_Bidding: 'Awarded_Project_Bidding',
  Project_Submitted: 'Project_Submitted',
  Project_Cancel: 'Project_Cancel',
  Bid_Placed_Project: 'Bid_Placed_Project',
  B8ak_Action_Rate_Project: 'B8ak_Action_Rate_Project',
  B8ak_Action_Rebid_Project: 'B8ak_Action_Rebid_Project',
  B8ak_Action_Reopen_Project: 'B8ak_Action_Reopen_Project',
  B8ak_Action_Award_Project: 'B8ak_Action_Award_Project',
  Status_Change_Project: 'Status_Change_Project',
  Warranty_Project: 'Warranty_Project',
  Call_Customer: 'Call_Customer',
  Disputed_Ticket_Reassigned: 'Disputed_Ticket_Reassigned',
  Canceled_Ticket_Reassigned: 'Canceled_Ticket_Reassigned',
  SP_Dispute_Project: 'SP_Dispute_Project',
  SP_Join_Campaign: 'SP_Join_Campaign',
  SP_Decide_Later_Campaign: 'SP_Decide_Later_Campaign',
  SP_Left_Campaign: 'SP_Left_Campaign',
  SP_Reject_Campaign: 'SP_Reject_Campaign',
  Change_Paid_In_Cash: 'Change_Paid_In_Cash',

  Completed_Ticket_OpenForRating: 'Completed_Ticket_OpenForRating',
  Ticket_Submitted: 'Ticket_Submitted',
  Product_Submitted: 'Order_Submitted',
  Order_Status_Changed: 'Order_Status_Changed',

    Package_Ticket_Confirmed: "Ticket_Confirmed",
    Package_Ticket_Declined: "Ticket_Declined",
    Package_Ticket_Delivered: "Ticket_Delivered",
    Package_Ticket_Undelivered: "Ticket_Undelivered",
    Package_Ticket_Undo_Delivery: "Ticket_DeliveryCanceled",
    Package_Ticket_Favourite_Worker_Assigned: "Favourite_Worker_Assigned",
    Package_Ticket_Favourite_Worker_Un_Assigned: "Favourite_Worker_Un_Assigned",
    Navigate_Send_Push: "Push_Notification_Sent",
    Emergency_Replacement: "Emergency_Replacement",
}

export const EventLogSource = {
    SPAdminDashboard: "SPDashboard",
    B8akDashboard: "B8akDashboard",
    System: "System",
    CApp: "CApp",
    SPAdminApp: "SPAdminApp",
    SPApp: "SPApp",
    CentroDashboard: "CentroDashboard",
    OnlineBooking: "OnlineBooking",
}

export const EventLogLabel = {
  Ticket_Submitted: 'EVENT_LOG.TICKET_SUBMITTED',
  Ticket_Delivered: 'EVENT_LOG.TICKET_DELIVERED',
  Ticket_Undelivered: 'EVENT_LOG.TICKET_UNDELIVERED',
  Ticket_Reassigned: 'EVENT_LOG.TICKET_REASSIGNED',
  Ticket_Disputed: 'EVENT_LOG.TICKET_DISPUTED',
  Completed_Ticket_OpenForRating: 'EVENT_LOG.TICKET_COMPLETED',
  Ticket_Completed: 'EVENT_LOG.TICKET_COMPLETED',
  Ticket_Confirmed: 'EVENT_LOG.TICKET_CONFIRMED',
  Ticket_Declined: 'EVENT_LOG.TICKET_DECLINED',
  Ticket_Canceled: 'EVENT_LOG.TICKET_CANCELED',
  'Ticket_Canceled with refund': 'EVENT_LOG.TICKET_CANCELED_REFUND',
} as any

export const EventLogClass = {
    Ticket_Submitted: "event-ticket-submitted",
    Ticket_Delivered: "event-ticket-delivered",
    Ticket_Undelivered: "event-ticket-undelivered",
    Ticket_DeliveryCanceled: "event-ticket-undo-delivery",
    Ticket_Reassigned: "event-ticket-reassigned",
    Ticket_Disputed: "event-ticket-disputed",
    Completed_Ticket_OpenForRating: "event-ticket-completed",
    Ticket_Completed: "event-ticket-completed",
    Ticket_Confirmed: "event-ticket-confirmed",
    Ticket_Declined: "event-ticket-declined",
    Ticket_Canceled: "event-ticket-canceled",
    Ticket_Canceled_With_Refund: "event-ticket-canceled-refund",
    Ticket_Read: "event-ticket-submitted",
    Ticket_Go_To_Market: "event-ticket-submitted",
    Reassigned_Under_Warranty: "event-ticket-submitted",
    Acknowledged_Ticket: "event-ticket-submitted",
    Market_Ticket_Clear: "event-ticket-submitted",
    Market_Ticket_Accepted: "event-ticket-submitted",
    Acknowledge_Timeout: "event-ticket-submitted",
    WalletEnforcement_Disabled: "event-ticket-submitted",
    WalletEnforcement_Enabled: "event-ticket-submitted",
    WalletEnforcement_StatusUpdate: "event-ticket-submitted",
    WalletEnforcement_PopupButtonClicked: "event-ticket-submitted",
    Market_Ticket_SPApplied: "event-ticket-submitted",
    Market_Ticket_SPUnapplied: "event-ticket-submitted",
    Auto_AssignTicket_To_ApplySP: "event-ticket-submitted",
    Delete_Project_Bidding: "event-ticket-canceled",
    Send_Mail_Receipt: "event-ticket-submitted",
    ReSend_Mail_Recipt: "event-ticket-submitted",
    Awarded_Project_Bidding: "event-ticket-submitted",
    Project_Submitted: "event-ticket-submitted",
    Project_Cancel: "event-ticket-canceled",
    Bid_Placed_Project: "event-ticket-submitted",
    B8ak_Action_Rate_Project: "event-ticket-submitted",
    B8ak_Action_Rebid_Project: "event-ticket-submitted",
    B8ak_Action_Reopen_Project: "event-ticket-submitted",
    B8ak_Action_Award_Project: "event-ticket-submitted",
    Status_Change_Project: "event-ticket-submitted",
    Warranty_Project: "event-ticket-submitted",
    Call_Customer: "event-ticket-submitted",
    Disputed_Ticket_Reassigned: "event-ticket-disputed",
    Canceled_Ticket_Reassigned: "event-ticket-canceled",
    Change_Paid_In_Cash: "event-ticket-submitted",
    Product_Submitted: "event-ticket-submitted",
    Order_Status_Changed: "event-ticket-submitted",
    Package_Ticket_Confirmed: "event-ticket-submitted",
    Package_Ticket_Declined: "event-ticket-submitted",
    Package_Ticket_Delivered: "event-ticket-submitted",
    Package_Ticket_Undelivered: "event-ticket-submitted",
    Package_Ticket_Favourite_Worker_Assigned: "event-ticket-reassigned",
    Package_Ticket_Favourite_Worker_Un_Assigned: "event-ticket-reassigned",
    Navigate_Send_Push: "event-ticket-submitted",
    Emergency_Replacement: "event-ticket-submitted",
    Un_Assigned_Worker: "event-ticket-reassigned",
    SP_Reassigned: "event-ticket-reassigned",
    SP_Join_Offer: "event-ticket-submitted",
    SP_Left_Offer: "event-ticket-submitted",
    SP_Reject_Offer: "event-ticket-canceled",
    SP_Decide_Later: "event-ticket-submitted",
    Awareness_Activated: "event-ticket-submitted",
    Awareness_Stoppped: "event-ticket-canceled",
    Awareness_Acknowledged: "event-ticket-submitted",
    SP_Performance_Read: "event-ticket-submitted",
    SP_Dispute_Project: "event-ticket-disputed",
    SP_Join_Campaign: "event-ticket-submitted",
    SP_Decide_Later_Campaign: "event-ticket-submitted",
    SP_Left_Campaign: "event-ticket-submitted",
    SP_Reject_Campaign: "event-ticket-canceled",
    Awareness_Expired: "event-ticket-submitted",
    Awareness_From_Actived_To_Stoppped: "event-ticket-canceled",
    Awareness_From_Pending_To_Stoppped: "event-ticket-canceled",
    Awareness_Pending: "event-ticket-delivered",
    Call_SP_Project: "event-ticket-submitted",
    Completed_Ticket_Reassigned: "event-ticket-completed",
    Favourite_Worker_Assigned: "event-ticket-reassigned",
    Favourite_Worker_Un_Assigned: "event-ticket-reassigned",
    Order_Submitted: "event-ticket-submitted",
    Push_Notification_Sent: "event-ticket-submitted",
    Share_Ticket: "event-ticket-submitted",
    SP_Left_Offer_Campaign: "event-ticket-submitted",
    SPAdminApp: "event-ticket-submitted",
} as any;

export const EventLogNameDis = {
    Ticket_Reassigned: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Reassigned",
    Ticket_Read: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Read",
    Ticket_Go_To_Market: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Go_To_Market",
    Market_Ticket_Clear: "VIEW_TICKET_MODAL.EVENT_NAME.Market_Ticket_Clear",
    Market_Ticket_Accepted: "VIEW_TICKET_MODAL.EVENT_NAME.Market_Ticket_Accepted",
    Acknowledge_Timeout: "VIEW_TICKET_MODAL.EVENT_NAME.Acknowledge_Timeout",
    Acknowledged_Ticket: "VIEW_TICKET_MODAL.EVENT_NAME.Acknowledged_Ticket",
    Ticket_Disputed: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Disputed",
    Reassigned_Under_Warranty: "VIEW_TICKET_MODAL.EVENT_NAME.Reassigned_Under_Warranty",
    SP_Reassigned: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Reassigned",
    SP_Join_Offer: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Join_Offer",
    SP_Left_Offer: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Left_Offer",
    SP_Reject_Offer: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Reject_Offer",
    SP_Decide_Later: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Decide_Later",
    Awareness_Activated: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_Activated",
    Awareness_Stoppped: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_Stoppped",
    Awareness_Acknowledged: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_Acknowledged",
    WalletEnforcement_Disabled: "VIEW_TICKET_MODAL.EVENT_NAME.WalletEnforcement_Disabled",
    WalletEnforcement_Enabled: "VIEW_TICKET_MODAL.EVENT_NAME.WalletEnforcement_Enabled",
    WalletEnforcement_StatusUpdate: "VIEW_TICKET_MODAL.EVENT_NAME.WalletEnforcement_StatusUpdate",
    WalletEnforcement_PopupButtonClicked: "VIEW_TICKET_MODAL.EVENT_NAME.WalletEnforcement_PopupButtonClicked",
    SP_Performance_Read: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Performance_Read",
    Market_Ticket_SPApplied: "VIEW_TICKET_MODAL.EVENT_NAME.Market_Ticket_SPApplied",
    Market_Ticket_SPUnapplied: "VIEW_TICKET_MODAL.EVENT_NAME.Market_Ticket_SPUnapplied",
    Auto_AssignTicket_To_ApplySP: "VIEW_TICKET_MODAL.EVENT_NAME.Auto_AssignTicket_To_ApplySP",
    Ticket_Canceled: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Canceled",
    Ticket_Canceled_With_Refund: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Canceled_With_Refund",
    Delete_Project_Bidding: "VIEW_TICKET_MODAL.EVENT_NAME.Delete_Project_Bidding",
    Awarded_Project_Bidding: "VIEW_TICKET_MODAL.EVENT_NAME.Awarded_Project_Bidding",
    Project_Submitted: "VIEW_TICKET_MODAL.EVENT_NAME.Project_Submitted",
    Project_Cancel: "VIEW_TICKET_MODAL.EVENT_NAME.Project_Cancel",
    Bid_Placed_Project: "VIEW_TICKET_MODAL.EVENT_NAME.Bid_Placed_Project",
    B8ak_Action_Rate_Project: "VIEW_TICKET_MODAL.EVENT_NAME.B8ak_Action_Rate_Project",
    B8ak_Action_Rebid_Project: "VIEW_TICKET_MODAL.EVENT_NAME.B8ak_Action_Rebid_Project",
    B8ak_Action_Reopen_Project: "VIEW_TICKET_MODAL.EVENT_NAME.B8ak_Action_Reopen_Project",
    B8ak_Action_Award_Project: "VIEW_TICKET_MODAL.EVENT_NAME.B8ak_Action_Award_Project",
    Status_Change_Project: "VIEW_TICKET_MODAL.EVENT_NAME.Status_Change_Project",
    Call_Customer: "VIEW_TICKET_MODAL.EVENT_NAME.Call_Customer",
    Disputed_Ticket_Reassigned: "VIEW_TICKET_MODAL.EVENT_NAME.Disputed_Ticket_Reassigned",
    Canceled_Ticket_Reassigned: "VIEW_TICKET_MODAL.EVENT_NAME.Canceled_Ticket_Reassigned",
    SP_Dispute_Project: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Dispute_Project",
    SP_Join_Campaign: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Join_Campaign",
    SP_Decide_Later_Campaign: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Decide_Later_Campaign",
    SP_Left_Campaign: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Left_Campaign",
    SP_Reject_Campaign: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Reject_Campaign",
    Change_Paid_In_Cash: "VIEW_TICKET_MODAL.EVENT_NAME.Change_Paid_In_Cash",
    Completed_Ticket_OpenForRating: "VIEW_TICKET_MODAL.EVENT_NAME.Completed_Ticket_OpenForRating",
    Ticket_Submitted: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Submitted",
    Product_Submitted: "VIEW_TICKET_MODAL.EVENT_NAME.Product_Submitted",
    Order_Status_Changed: "VIEW_TICKET_MODAL.EVENT_NAME.Order_Status_Changed",
    Ticket_Completed: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Completed",
    Un_Assigned_Worker: "VIEW_TICKET_MODAL.EVENT_NAME.Un_Assigned_Worker",
    Awareness_Expired: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_Expired",
    Awareness_From_Actived_To_Stoppped: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_From_Actived_To_Stoppped",
    Awareness_From_Pending_To_Stoppped: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_From_Pending_To_Stoppped",
    Awareness_Pending: "VIEW_TICKET_MODAL.EVENT_NAME.Awareness_Pending",
    Call_SP_Project: "VIEW_TICKET_MODAL.EVENT_NAME.Call_SP_Project",
    Completed_Ticket_Reassigned: "VIEW_TICKET_MODAL.EVENT_NAME.Completed_Ticket_Reassigned",
    Favourite_Worker_Assigned: "VIEW_TICKET_MODAL.EVENT_NAME.Favourite_Worker_Assigned",
    Favourite_Worker_Un_Assigned: "VIEW_TICKET_MODAL.EVENT_NAME.Favourite_Worker_Un_Assigned",
    Order_Submitted: "VIEW_TICKET_MODAL.EVENT_NAME.Order_Submitted",
    Push_Notification_Sent: "VIEW_TICKET_MODAL.EVENT_NAME.Push_Notification_Sent",
    Share_Ticket: "VIEW_TICKET_MODAL.EVENT_NAME.Share_Ticket",
    SP_Left_Offer_Campaign: "VIEW_TICKET_MODAL.EVENT_NAME.SP_Left_Offer_Campaign",
    SPAdminApp: "VIEW_TICKET_MODAL.EVENT_NAME.SPAdminApp",
    Ticket_Confirmed: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Confirmed",
    Ticket_Declined: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Declined",
    Ticket_Deliver_Confirm: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Deliver_Confirm",
    Ticket_Delivered: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Delivered",
    Ticket_Rated: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Rated",
    Ticket_Undelivered: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered",
    Ticket_DeliveryCanceled: "VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undo_Delivery",
    Start_date_edited: 'VIEW_TICKET_MODAL.EVENT_NAME.Start_date_edited',
    End_date_edited: 'VIEW_TICKET_MODAL.EVENT_NAME.End_date_edited',
} as any;
