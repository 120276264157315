import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Completed: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='m-20 mb-5 text-center w-100'>
      <img
        src='/assets/images/pages/order/success-primary.png' alt='success'
      />
      <h3 className='mt-10'>{t('MARKET_MODAL.THANH_YOU')}</h3>
      <h3>{t('MARKET_MODAL.YOUR_ORDER')}</h3>
    </div>

  )
}

export { Completed }
