import {t} from 'i18next'
import {Languages, ticketStatus} from 'app/utils/common/constants'
import {SubcriptionContact} from 'app/modules/tickets/models'

export const renderContractLength = (language: string, data: SubcriptionContact) => {
  if (language === Languages.ar) {
    if (data.contractLength === 1) return t('SUBSCRIPTION_CONTRACTS.MONTH')
    else if (data.contractLength === 2) return t('SUBSCRIPTION_CONTRACTS.MONTH_2')
    else if (data.contractLength > 2 && data.contractLength < 11)
      return `${data.contractLength} ${t('SUBSCRIPTION_CONTRACTS.MONTH_3-10')}`
    else return `${data.contractLength} ${t('SUBSCRIPTION_CONTRACTS.MONTHS')}`
  } else {
    return `${data.contractLength} ${t(
      data.contractLength < 2 ? 'SUBSCRIPTION_CONTRACTS.MONTH' : 'SUBSCRIPTION_CONTRACTS.MONTHS'
    )}`
  }
}

export const renderRemainingDays = (language: string, data: SubcriptionContact) => {
  if (data.ticketStatus.toLowerCase().includes(ticketStatus.COMPLETED))
    return t('TICKET_STATUS.COMPLETED')
  if (data.ticketStatus.toLowerCase().includes(ticketStatus.CANCELED))
    return t('TICKET_STATUS.CANCELED')
  if (language === Languages.ar) {
    if (data.remainingDays === 1) return t('SUBSCRIPTION_CONTRACTS.DAY')
    else if (data.remainingDays === 2) return t('SUBSCRIPTION_CONTRACTS.DAY_2')
    else if (data.remainingDays > 2 && data.remainingDays < 11)
      return `${data.remainingDays} ${t('SUBSCRIPTION_CONTRACTS.DAY_3-10')}`
    else return `${data.remainingDays} ${t('SUBSCRIPTION_CONTRACTS.DAYS')}`
  } else {
    return `${data.remainingDays} ${t(
      data.remainingDays < 2 ? 'SUBSCRIPTION_CONTRACTS.DAY' : 'SUBSCRIPTION_CONTRACTS.DAYS'
    )}`
  }
}
