import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import ServiceComponent from './service/Service';
import { useAppDispatch } from 'setup/redux/Hooks';
import demoServicesData from "../demo-services-data.json"
import demoPackagesData from "../demo-packages-data.json"
import PackageComponent from './package/Package';
import { PackageWorkflowServiceModel, ServiceManagementPackageInfoModel, ServiceManagementPackageModel } from '../models';
import { _sleep } from 'app/utils/helpers/extendMethod';
import _ from 'lodash';
import { serviceManagementAction } from '../redux/ServiceManagementSlice';
import { authActions } from 'app/modules/auth/redux/AuthSlice'

interface Props {
  rtl?: boolean,
}

function ServiceManagement(props: Props) {
  const { rtl } = props
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width };
  };

  const viewPort = useViewport();

  const [selectedService, setSelectedService] = useState<PackageWorkflowServiceModel | null>(null)
  const [listService, setListService] = useState<PackageWorkflowServiceModel[]>([])
  const [selectedPackage, setSelectedPackage] = useState<ServiceManagementPackageInfoModel | null>(null)
  const [listPackage, setListPackage] = useState<ServiceManagementPackageInfoModel[]>([])
  const [isLoadingService, setIsLoadingService] = useState<boolean>(false)
  const [isLoadingPackage, setIsLoadingPackage] = useState<boolean>(false)

  const getMajorServicesCallback = (res: PackageWorkflowServiceModel[] | null, err: any) => {
    setIsLoadingService(false)
    if (err || !res) {
      return
    }
    if (Boolean(res.length)) {
      setSelectedService(_.cloneDeep(res[0]))
    }
    setListService(res)
  }

  const getListService = () => {
    setIsLoadingService(true)
    dispatch(serviceManagementAction.getListPackageWorkflowService({ callback: getMajorServicesCallback }))
  }

  const getListPackageByMajorServiceCallback = (res: ServiceManagementPackageInfoModel[] | null, err: any) => {
    setIsLoadingPackage(false)
    if (err || !res) {
      return
    }
    setListPackage(res)
  }

  const getListPackage = async (serviceData: PackageWorkflowServiceModel | null) => {
    setIsLoadingPackage(true)
    if (!serviceData) {
      setListPackage([])
      setIsLoadingPackage(false)
      return
    }
    dispatch(serviceManagementAction.getListPackageByMajorServiceId({ callback: getListPackageByMajorServiceCallback, body: { majorId: serviceData.id } }))
  }

  useEffect(() => {
    getListPackage(selectedService)
  }, [selectedService])

  useEffect(() => {
    getListService()
  }, [])

  return (
    <div className='service-management-container'>
      <Row className={`d-flex ${viewPort.width <= 768 ? "" : "flex-nowrap"}`}>
        <Col xs={12} md={3} className={`service-management-container-bg rounded p-3 ${viewPort.width <= 768 ? "mb-9" : "me-9"}`}>
          <ServiceComponent selectedService={selectedService} setSelectedService={setSelectedService} listService={listService} setListService={setListService} isLoading={isLoadingService} reloadListService={() => {getListService(); dispatch(authActions.getServiceScope());}}/>
        </Col>
        <Col xs={12} md={9} className='service-management-container-bg rounded p-6'>
          <Row>
            <PackageComponent selectedService={selectedService} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} listPackage={listPackage} setListPackage={setListPackage} isLoading={isLoadingPackage} setIsLoading={setIsLoadingPackage} reloadListPackage={() => getListPackage(selectedService)}/>
            {/* <Col xs={12}>
              <PriceComponent selectedPackage={selectedPackage} />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ServiceManagement