export const PAGESIZE = {
  EMPLOYERS: 10,
}

export const REACT_APP_MAP_KEY_API = process.env.REACT_APP_MAP_KEY_API

export const MAP_LIBRARY: any = ['geometry', 'drawing', 'places']

export const optionsPolygonDefault = {
  fillColor: `#FFC20F`,
  strokeColor: '#a1a5b7',
  fillOpacity: 0.5,
  strokeWeight: 5,
  clickable: true,
  editable: true,
  zIndex: 1,
}

export const polygonOptionsDefault = {
  fillColor: `#FFC20F`,
  fillOpacity: 0.5,
  strokeWeight: 5,
  clickable: true,
  editable: true,
  zIndex: 1,
}

export const POSITION_LABEL_POLYGON_SELECTED = {x: 0, y: 0}

const subDomain = window.location.host.split(".")[0].includes('www') ? window.location.host.split(".")[1] : window.location.host.split(".")[0];

export const API_URL = `${process.env.REACT_APP_API_URL}/${subDomain}`
// export const API_URL = `${process.env.REACT_APP_API_URL}/care`
