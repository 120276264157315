import { TimeInWeek } from "../../modules/users/models"
import { SELECT_TIME_IN_WEEK } from "../common/constants";

export const convertOperationObjectToList = (data: any) => {
  var result = JSON.parse(JSON.stringify(SELECT_TIME_IN_WEEK)) as TimeInWeek[];
  result.forEach(time => {
    let value = data[time.key] as Array<any>;
    if (value && value.length > 0) {
      time.isSelected = true;
      time.value = value;
    }
  });
  return result;
}

export const convertOperationListToObject = (data: TimeInWeek[]) => {
  var result = {} as any;
  data.forEach(time => {
    result[time.key] = time.isSelected ? time.value : []
  });
  return result;
}