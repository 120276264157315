import {Field, FieldConfig, FieldProps} from 'formik'
import React, {CSSProperties} from 'react'
import {Form, InputGroup} from 'react-bootstrap-v5'
import {BsPrefixProps, ReplaceProps} from 'react-bootstrap-v5/lib/helpers'
import {useTranslation} from 'react-i18next'
import {classNameFunc} from './utils'

export interface IPropsTextInput
  extends React.PropsWithChildren<ReplaceProps<'input', BsPrefixProps<'input'>>> {
  label?: string
  type?: 'text' | 'number'
  styleContainer?: CSSProperties
  classContainer?: string
  placeholder?: string
  inputGroup?: React.ReactNode
  inputGroupEnd?: React.ReactNode
  multi?: boolean
  rows?: number
  isInvalid?: boolean
  error?: string
  onChangeMore?: (v: string) => {}
  disabledInput?: boolean
  isTouchedBlur?: boolean
  allowDecimal?: boolean
  min?: string
  keyDownEnterFunction?: Function
}
export const TextInput = (props: IPropsTextInput) => {
  const {
    label,
    placeholder,
    type = 'text',
    styleContainer,
    classContainer,
    inputGroup,
    inputGroupEnd,
    multi = false,
    rows = 3,
    isInvalid: _isInvalid,
    error: __error,
    disabledInput = false,
    onChangeMore,
    onBlur,
    isTouchedBlur = true,
    allowDecimal = true,
    keyDownEnterFunction,

    //formik
    field,
    form,
    meta,
    ...rest
  } = props as IPropsTextInput & FieldProps

  const onBlurInput = (e: any) => {
    // đây nữa
    isTouchedBlur && form?.setFieldTouched(field.name, true)
    onBlur && onBlur(e)
  }

  const {t} = useTranslation()

  const touched = form?.touched[field.name]
  let _error = __error !== undefined ? __error : form?.errors[field.name]
  let [error, params = ''] = ![null, undefined, ''].includes(_error as any)
    ? String(_error).split('-')
    : [_error, '']
  error = ![null, undefined, ''].includes(error as any)
    ? t(error as string).format(params.split(';'))
    : error
  const isInvalid = _isInvalid !== undefined ? _isInvalid : touched === true && error !== undefined

  const renderInput = () => (
    <Form.Control
      {...rest}
      disabled={disabledInput}
      type={type}
      onKeyDown={(e: any) => {
        if (['.', ',', '-'].includes(e.key) && !allowDecimal) e.preventDefault()
        if (e.key === 'Enter' && !!keyDownEnterFunction) keyDownEnterFunction()
      }}
      placeholder={placeholder}
      as={multi ? 'textarea' : 'input'}
      //@ts-ignore
      rows={rows}
      {...(form != null && {
        name: field.name,
        value: field.value,
        onChange: (val) => {
          onChangeMore && onChangeMore(val.target.value)
          form.handleChange(val)
        },
      })}
      onBlur={(e: any) => onBlurInput(e)}
    />
  )

  return (
    <Form.Group
      className={classNameFunc([isInvalid && 'has-error', classContainer])}
      style={styleContainer}
    >
      {label != null && <Form.Label>{label}</Form.Label>}
      {inputGroup === undefined && inputGroupEnd === undefined ? (
        <>
          {renderInput()}
          {/*{isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}*/}
        </>
      ) : (
        <InputGroup>
          {inputGroup !== undefined && <InputGroup.Text>{inputGroup}</InputGroup.Text>}
          {renderInput()}
          {inputGroupEnd !== undefined && <InputGroup.Text>{inputGroupEnd}</InputGroup.Text>}
        </InputGroup>
      )}
      {isInvalid && <Form.Control.Feedback type='invalid'><>{error}</></Form.Control.Feedback>}
    </Form.Group>
  )
}

export type IPropsField = IPropsTextInput & FieldConfig
export const FieldTextInput = (props: IPropsField) => <Field component={TextInput} {...props} />
