import {authActions, selectLanguage, selectSpLinkedWorkerData} from 'app/modules/auth'
import {Languages} from 'app/utils/common/constants'
import {isEmptyOrNull} from 'app/utils/helpers/extendMethod'
import {t} from 'i18next'

import moment from 'moment'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from 'setup/redux/Hooks'
import {
  IStatusAttendanceDelivery,
  TicketAttendanceSheetModel,
} from '../models/TicketAttendanceDelivery'
import {
  selectAttendanceDeliveryData,
  selectParamSearchAttendanceDelivery,
  ticketActions,
} from '../redux/TicketSlice'
import {ContentComponents} from './components/ContentComponents'
import {FilterComponent} from './components/FilterComponent'
import './style.scss'
import { clientTimeZoneOffset } from 'app/utils/common/constants';
import { PageTitle } from 'theme/layout/components/HeadTitle'

export interface IProps {}

export type IMapStatusFilter =
  | 'Attendant'
  | 'Unattendant'
  | 'Delivered'
  | 'Undelivered'
  | 'No_Action'
export const mapStatusFilter: {
  status: IMapStatusFilter
  labelEn: string
  labelAr: string
  type?: 'attendance' | 'delivery'
  group?: IStatusAttendanceDelivery | IStatusAttendanceDelivery[]
  statusMap?: string[]
}[] = [
  {
    status: 'Delivered',
    labelEn: 'Delivered',
    labelAr: 'ATTENDANCE_DELIVERY_PAGE.DELIVERED',
    type: 'delivery',
    group: ['Delivered'],
  },
  {
    status: 'Undelivered',
    labelEn: 'Undelivered',
    labelAr: 'ATTENDANCE_DELIVERY_PAGE.UNDELIVERED',
    type: 'delivery',
    group: ['UndeliveredCustomerNotReachable', 'UndeliveredNoFemale', 'UndeliveredOther'],
  },
  {
    status: 'Attendant',
    labelEn: 'Attendant',
    labelAr: 'ATTENDANCE_DELIVERY_PAGE.ATTENDANT',
    type: 'attendance',
    group: 'Attendant',
  },
  {
    status: 'Unattendant',
    labelEn: 'Unattendant',
    labelAr: 'ATTENDANCE_DELIVERY_PAGE.UN_ATTENDANT',
    type: 'attendance',
    group: 'Unattendant',
  },
  {
    status: 'No_Action',
    labelEn: 'No Action',
    labelAr: 'ATTENDANCE_DELIVERY_PAGE.NO_ACTION',
  },
]

export const mapStatus: {
  labelEventDis?: string
  type: 'attendance' | 'delivery' | 'undoDelivery'
  status: IStatusAttendanceDelivery
  update: string
  statusMap: (
    | {
        status: string
        event?: string
        rule?: 'startsWith' | 'multi_worker'
      }
    | string
  )[]
}[] = [
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Declined',
    type: 'attendance',
    status: 'Unattendant',
    update: 'Ticket_Declined',
    statusMap: [{status: 'TicketStatus_InProgress', event: 'Ticket_Declined'}],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Confirmed',
    type: 'attendance',
    status: 'Attendant',
    update: 'TicketStatus_InProgress_Confirmed',
    statusMap: [
      {status: 'TicketStatus_InProgress_Confirmed', event: 'Ticket_Confirmed'},
      {status: 'TicketStatus_InProgress', event: 'Ticket_Confirmed', rule: 'multi_worker'},

      'TicketStatus_Delivered',
      'TicketStatus_SPUndelivered_CustomerNotReachable',
      'TicketStatus_SPUndelivered_NoFemalePresent',
      'TicketStatus_SPUndelivered_Other',
      'TicketStatus_InProgress_Confirmed',
      'TicketStatus_Completed_And_Customer_Rated',
      'TicketStatus_Completed_No_Customer_Rating'
    ],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Delivered',
    type: 'delivery',
    status: 'Delivered',
    update: 'TicketStatus_Delivered',
    statusMap: [
      {status: 'TicketStatus_InProgress_Confirmed', event: 'Ticket_Delivered'},

      'TicketStatus_Delivered',
      'TicketStatus_Completed_And_Customer_Rated',
      'TicketStatus_Completed_No_Customer_Rating'
    ],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered',
    type: 'delivery',
    status: 'UndeliveredNoFemale',
    update: 'TicketStatus_SPUndelivered_NoFemalePresent',
    statusMap: ['TicketStatus_SPUndelivered_NoFemalePresent'],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered',
    type: 'delivery',
    status: 'UndeliveredCustomerNotReachable',
    update: 'TicketStatus_SPUndelivered_CustomerNotReachable',
    statusMap: ['TicketStatus_SPUndelivered_CustomerNotReachable'],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered',
    type: 'delivery',
    status: 'UndeliveredOther',
    update: 'TicketStatus_SPUndelivered_Other',
    statusMap: ['TicketStatus_SPUndelivered_Other', 'TicketStatus_SPUndelivered_CustomerDidnotConfirm'],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undo_Delivery',
    type: 'undoDelivery',
    status: 'UndoDelivery',
    update: 'Ticket_DeliveryCanceled',
    statusMap: [],
  },
]
export const mapStatusPackageDetail: {
  labelEventDis?: string
  type: 'attendance' | 'delivery' | 'undoDelivery'
  status: IStatusAttendanceDelivery
  update: string
  statusMap: (
    | {
        status: string
        event?: string
        rule?: 'startsWith' | 'multi_worker'
      }
    | string
  )[]
}[] = [
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Declined',
    type: 'attendance',
    status: 'Unattendant',
    update: 'Ticket_Declined',
    statusMap: [{status: 'TicketStatus_InProgress', event: 'Ticket_Declined'}],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Confirmed',
    type: 'attendance',
    status: 'Attendant',
    update: 'TicketStatus_InProgress_Confirmed',
    statusMap: [
      {status: 'TicketStatus_InProgress_Confirmed', event: 'Ticket_Confirmed'},
      {status: 'TicketStatus_InProgress', event: 'Ticket_Confirmed', rule: 'multi_worker'},

      'TicketStatus_Delivered',
      'TicketStatus_SPUndelivered_CustomerNotReachable',
      'TicketStatus_SPUndelivered_NoFemalePresent',
      'TicketStatus_SPUndelivered_Other',
      'TicketStatus_InProgress_Confirmed'
    ],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Delivered',
    type: 'delivery',
    status: 'Delivered',
    update: 'TicketStatus_Delivered',
    statusMap: [
      {status: 'TicketStatus_InProgress_Confirmed', event: 'Ticket_Delivered'},

      'TicketStatus_Delivered'
    ],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered',
    type: 'delivery',
    status: 'UndeliveredNoFemale',
    update: 'TicketStatus_SPUndelivered_NoFemalePresent',
    statusMap: ['TicketStatus_SPUndelivered_NoFemalePresent'],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered',
    type: 'delivery',
    status: 'UndeliveredCustomerNotReachable',
    update: 'TicketStatus_SPUndelivered_CustomerNotReachable',
    statusMap: ['TicketStatus_SPUndelivered_CustomerNotReachable'],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undelivered',
    type: 'delivery',
    status: 'UndeliveredOther',
    update: 'TicketStatus_SPUndelivered_Other',
    statusMap: ['TicketStatus_SPUndelivered_Other'],
  },
  {
    labelEventDis: 'VIEW_TICKET_MODAL.EVENT_NAME.Ticket_Undo_Delivery',
    type: 'undoDelivery',
    status: 'UndoDelivery',
    update: 'Ticket_DeliveryCanceled',
    statusMap: [],
  },
]
export const getTypeStatus = (opts: {
  group: typeof mapStatus[number]['type']
  eventStatus: string
  status: string
  workerId?: string
  isMultiWorker?: boolean
  workerAttendantAction?: string
}) => {
  const {
    group,
    eventStatus,
    status,
    isMultiWorker = false,
    workerAttendantAction = '',
    workerId = '',
  } = opts
  return mapStatus
    .filter((e) => e.type == group)
    .find(
      (e) =>
        e.statusMap.findIndex((e) =>
          typeof e == 'string'
            ? e == status
            : e.rule == 'startsWith'
            ? status.startsWith(e.status)
            : e.rule == 'multi_worker'
            ? e.event == eventStatus && e.status == status && workerId == workerAttendantAction
            : e.event == eventStatus && e.status == status
        ) >= 0
    )
}

export const getTypeStatusPackageDetail = (opts: {
  group: typeof mapStatus[number]['type']
  eventStatus: string
  status: string
  workerId?: string
  isMultiWorker?: boolean
  workerAttendantAction?: string
}) => {
  const {
    group,
    eventStatus,
    status,
    isMultiWorker = false,
    workerAttendantAction = '',
    workerId = '',
  } = opts
  return mapStatusPackageDetail
    .filter((e) => e.type == group)
    .find(
      (e) =>
        e.statusMap.findIndex((e) =>
          typeof e == 'string'
            ? e == status
            : e.rule == 'startsWith'
            ? status.startsWith(e.status)
            : e.rule == 'multi_worker'
            ? e.event == eventStatus && e.status == status && workerId == workerAttendantAction
            : e.event == eventStatus && e.status == status
        ) >= 0
    )
}

export const mapItemAttendanceDelivery = (
  e: TicketAttendanceSheetModel,
  list?: TicketAttendanceSheetModel[]
): TicketAttendanceSheetModel => {
  return {
    ...e,
    statusAttendance: getTypeStatus({
      group: 'attendance',
      workerId: e.workerId,
      eventStatus: e.event,
      status: e.status,
      isMultiWorker: e.isMultiWorker,
      workerAttendantAction: e.workerAttendantAction,
    })?.status,
    statusDelivery: getTypeStatus({
      group: 'delivery',
      workerId: e.workerId,
      eventStatus: e.event,
      status: e.status,
      isMultiWorker: e.isMultiWorker,
      workerAttendantAction: e.workerAttendantAction,
    })?.status,
    ...(list != undefined && {
      isMultiWorker: list.filter((e1) => e1.id == e.id).length > 0,
    }),
  }
}

export const AttendanceDelivery = (props: IProps) => {
  const {} = props
  const dispatch = useAppDispatch()

  const language = useAppSelector(selectLanguage)
  const rtl = language === Languages.ar
  const {t} = useTranslation()
  PageTitle(t('ASIDE_MENU.ATTENDANCE_DELIVERY'))

  const attendanceDeliveryData = useAppSelector(selectAttendanceDeliveryData)
  const paramAttendanceDelivery = useAppSelector(selectParamSearchAttendanceDelivery)

  const initParams = {

    spIds: [],
  
    cityIds: [],
  
    clientTimeZone: clientTimeZoneOffset,
  
    dateSearch: new Date(),
  
  }

  useEffect(() => {
    return () => {
      dispatch(ticketActions.changeParamSearchAttendanceDelivery(initParams));
    }
  }, [])

  useEffect(() => {
    dispatch(ticketActions.getAttendanceDelivery(paramAttendanceDelivery))
  }, [paramAttendanceDelivery])

  const onRefresh = () => {
    dispatch(ticketActions.getAttendanceDelivery(paramAttendanceDelivery))
  }
  
  return (
    <div className='attendance-delivery-container'>
      <div className='row'>
        <div className='nav-header d-flex flex-row align-items-center justify-content-between'>
          <p className='mb-0 nav-title'></p>
          <button className='' type='button' onClick={onRefresh}>
            {t('ATTENDANCE_DELIVERY_PAGE.REFRESH')}
          </button>
        </div>
        <div className='col-md-4 col-xl-3'>
          <FilterComponent
            rtl={rtl}
            onFetch={(data) => {
              dispatch(ticketActions.changeParamSearchAttendanceDelivery(data))
            }}
          />
        </div>
        <div className='col-md-8 col-xl-9'>
          <ContentComponents
            rtl={rtl}
            paramAttendanceDelivery={paramAttendanceDelivery}
            dataAttendanceDelivery={attendanceDeliveryData}
            onChangeDate={(adjust) => {
              const params = {
                ...paramAttendanceDelivery,
                dateSearch: moment(paramAttendanceDelivery.dateSearch).add(adjust, 'day').toDate(),
              }
              dispatch(ticketActions.changeParamSearchAttendanceDelivery(params))
            }}
          />
        </div>
      </div>
    </div>
  )
}
