import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../theme/layout/components/HeadTitle';
import { Languages, PageUrl } from '../../utils/common/constants';
import { B8akToolbar } from 'theme/layout/components';
import ServiceManagement from './components/ServiceManagement';
import "./style.scss"
import { useAppSelector } from 'setup/redux/Hooks';
import { selectLanguage } from '../auth';

const ServiceManagementPage: React.FC = () => {
    const { t } = useTranslation();
    const language = useAppSelector(selectLanguage);
    const rtl = language === Languages.ar;
    PageTitle(t('SERVICE_MANAGEMENT_PAGE.PAGE_TITLE'));
    return (
        <>
            <Switch>
                <Route path={PageUrl.SERVICE_MANAGEMENT.ROOT}>
                    <B8akToolbar title={t('ASIDE_MENU.SERVICES_MANAGEMENT')} />
                    <ServiceManagement />
                </Route>

                <Redirect to={PageUrl.SERVICE_MANAGEMENT.ROOT} />
            </Switch>
        </>
    )

}

export default ServiceManagementPage