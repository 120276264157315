/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import MapMaker from '../../../../utils/helpers/components/MapMaker';
import { MarketModel, TicketModel } from '../../models';
import { MarketAcceptModal } from './MarketAcceptModal';
import { useAppDispatch, useAppSelector } from '../../../../../setup/redux/Hooks';
import { selectTicketLoadingMarket, selectTicketMarketData, ticketActions } from '../../redux/TicketSlice';
import { selectLanguage } from '../../../auth';
import { Languages } from '../../../../utils/common/constants';
import { ticketApi } from '../../redux/TicketApi';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { toast } from 'react-toastify';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';

type Props = {

}

const MarketModal: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState<MarketModel | null>(null);
  const { t } = useTranslation();
  const marketData = useAppSelector(selectTicketMarketData);
  const loading = useAppSelector(selectTicketLoadingMarket);
  const [loadingClear, setLoadingClear] = useState(false);
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;
  const handleOpenModal = (data: MarketModel) => {
    setDataModal(data);
    setShowModal(true);
  }

  const handleClear = (id: string) => {
    setLoadingClear(true);
    ticketApi.clearMarketOrder(id).then(res => {
      if (res.status === HTTP_RESPONSE_STATUS.SUCCESS && res.data) {
        dispatch(ticketActions.clearMarketTicket(id));
      } else {
        toast.error(res.message, ToastDefaultConfig());
      }
    }).finally(() => setLoadingClear(false));
  }

  const handleAccepted = (data: TicketModel) => {
    dispatch(ticketActions.acceptedMarketTicket(data));
  }

  const handleRefreshMarket = () => {
    dispatch(ticketActions.getMarketOrders());
  }

  const handleClearMarket = (id: string) => {
    dispatch(ticketActions.clearMarketTicket(id));
    }

  useMemo(() => {
    const scriptSrc = `${process.env.REACT_APP_GOOGLE_MAP_URL}&language=${language}`;
    let scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', scriptSrc);
    scriptTag.setAttribute('async', '');
    document.head.appendChild(scriptTag);
  }, [language])
  return (
    <>
      <div
        id='kt_drawer_chat'
        className='bg-white market-modal'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '500px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
      >
        <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
          <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <span className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                  {t('MARKET_MODAL.MODAL_TITLE')}
                </span>
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='btn btn-sm btn-icon btn-active-light-primary' onClick={() => handleRefreshMarket()}>
                <i className="fas fa-sync"></i>
              </div>
              <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
                <i className="fas fa-times fs-4"></i>
              </div>
            </div>
          </div>
          {loading ?
            <div className='py-10 text-center border-top'>
              <div className="spinner-border text-dark" role="status"></div>
            </div>
            :
            <div className='ticket-section'>
              {marketData.length > 0 ?
                marketData.map((item, index) => (
                  <div className='ticket-item mb-10' key={index}>
                    {item.isOutOfCoverage && <span className='ticket-item-header fw-bold text-danger small shadow px-3 py-1'>{t("MARKET_MODAL.OUT_OF_COVERAGE")}</span>}

                    <MapMaker lat={item.customerLat}
                      lng={item.customerLng}
                      googleMapURL={`${process.env.REACT_APP_GOOGLE_MAP_URL}&language=${language}` as string}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `200px` }} />}
                      mapElement={<div style={{ height: `100%`, borderRadius: '5px' }} />}
                      key={`${process.env.REACT_APP_GOOGLE_MAP_URL}&language=${language}` as string}
                    />

                    <Row className='mt-5'>
                      <Col sm={6}>
                        <strong>{t('MARKET_MODAL.SERVICE_NAME')}</strong>
                        {item.offerId ?
                          <div>{rtlMode ? item.majorServiceNameAr : item.majorServiceNameEn} - {rtlMode ? item.offerTitleAr : item.offerTitleEn}</div>
                          :
                          <div>{rtlMode ? item.majorServiceNameAr : item.majorServiceNameEn} - {rtlMode ? item.subServiceNameAr : item.subServiceNameEn}</div>
                        }

                      </Col>
                      <Col sm={6}>
                        <strong>{t('MARKET_MODAL.VISIT_TIME')}</strong>

                        <div className='text-info'>
                          <i className="far fa-clock text-info me-1"></i> {moment(item.scheduledVisitDateTime).locale(rtlMode ? 'ar-SA' : 'en-US').format('DD.MM.YYYY hh:mm A')}
                        </div>

                      </Col>
                      <Col sm={6} className='text-center mt-5'>
                        <Button variant='danger' size='sm' className='w-150px' onClick={() => handleClear(item.id)} disabled={loadingClear}> {loadingClear && <div className="spinner-border spinner-border-sm mx-1" role="status"></div>} {t('MARKET_MODAL.CLEAR')}</Button>
                      </Col>
                      <Col sm={6} className='text-center mt-5'>
                        <Button variant='success' size='sm' className='w-150px' onClick={() => handleOpenModal(item)} disabled={loadingClear}>{t('MARKET_MODAL.ACCEPT')}</Button>
                      </Col>
                    </Row>
                  </div>
                ))
                :
                <div className='text-center mt-5 text-muted'>{t('MARKET_MODAL.NOT_FOUND')}</div>
              }
            </div>
          }

        </div>
      </div>
      <MarketAcceptModal isShow={showModal} onHide={() => setShowModal(false)} data={dataModal} onAccepted={handleAccepted} onCleared={handleClearMarket} />
    </>

  )
}

export { MarketModal }
