import './style.scss';

import { Field, FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import { STEP_USERS, USER_TYPE } from '../../../../../../utils/common/constants';
import { checkFullNameUser, checkValidFullPhoneNumber, checkValidPhoneNumber } from '../../../../../../utils/services/userService';
import { City, Nationality, UserFormModel } from '../../../../models';
import { useAppSelector } from '../../../../../../../setup/redux/Hooks';
import { selectActionUserModal } from '../../../../redux/UserSlice';

type Props = {
  className?: string,
  values: UserFormModel,
  rtl?: boolean,
  step?: number,
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  nationalities?: Nationality[],
  cities?: City[],
  avatar?: string,
  clearAvatar?: () => void,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setFieldError: (field: string, value: any) => void,
  setTouched: (value: FormikTouched<UserFormModel>, shouldValidate?: boolean) => void,
}

const AccountInfo: React.FC<Props> = ({ setFieldValue, setFieldError, setTouched, step, avatar = "", rtl, values, errors, cities, touched, nationalities = [], clearAvatar }) => {
  const { t } = useTranslation();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [newAvatarEmpl, setNewAvatarEmpl] = useState("");
  const [textErrorPhone, setTextErrorPhone] = useState("");
  const [isActive, setIsActive] = useState(values.isActive);

  const isShowViewModalCreateUser = useAppSelector(selectActionUserModal);

  const changeAvatar = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file: Blob = event.target.files[0];
    if (!file) return;
    setNewAvatarEmpl(file ? URL.createObjectURL(file) : '');
    setFieldValue("avatar", file);
    setFieldValue("isRemoveAvatar", false)
    clearAvatar?.();
  }

  const removeAvatar = () => {
    setFieldValue("avatar", "")
    setFieldValue("isRemoveAvatar", true)
    clearAvatar?.();
    setNewAvatarEmpl("");
  }

  const handlePhoneChange = (
    values: UserFormModel,
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    // if (!values || !values.phone || values.phone == "") {
    //   setTextErrorPhone("");
    //   return;
    // }
    // if (checkValidFullPhoneNumber(values?.phone ? values.phone : "")) {
    //   setTextErrorPhone("");
    // } else setTextErrorPhone(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE'));
  }

  const handleChangeStatus = () => {
    var newValue = !isActive;
    setIsActive(newValue);
    setFieldValue("isActive", newValue);
  }
  return (
    <>
      <div className='box-step-2'>
        <Row className='flex-root step-1 m-auto'>
          {/* Avatar user */}
          <Col sm={12} className='mt-8'>
            <div className='group-avatar'>
              <div className='text'>
                <div className='text-bold'>{t('USERS_PAGE.CONTENT_STEP.STEP2.FIELD_USER_IMAGE')}</div>
                <div className='text-grey'>{t('USERS_PAGE.CONTENT_STEP.STEP2.IMAGE_DESC')}</div>
              </div>
              <div className='upload'>
                <div className='circle' >
                  {!newAvatarEmpl && !avatar && <img src={'/assets/images/icons/user-avatar-employer.svg'}></img>}
                  {avatar ? <img className='image-user' src={avatar}></img> : newAvatarEmpl ? <img className='image-user' src={newAvatarEmpl}></img> : <></>}

                  <div className="icon-action icon-delete" onClick={removeAvatar}>
                    <i className="fas fa-trash"></i>
                  </div>
                  <div className="icon-action icon-edit" onClick={() => inputFile?.current && inputFile.current.click()}>
                    <i className="fas fa-edit"></i>
                  </div>
                </div>
                <input id="fileInput" type="file" ref={inputFile} accept="image/png, image/jpeg" className='none'
                  onClick={(event: any) => { event.target.value = null }} onChange={changeAvatar} />
              </div>
            </div>
          </Col>
          {values.userType == USER_TYPE.WORKER ? <>
            <Col sm={12} md={6} className='mt-8'>
              <Row>
                <Col sm={12} md={6} >
                  <InputGroup className='h-50px'>
                    <InputGroup.Text id='basic-addon1'>
                      <img src='/assets/images/icons/user.svg'></img>
                    </InputGroup.Text>
                    <Field
                      className='form-control h-50px'
                      name='firstNameEn'
                      type="text"
                      placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PLACEHOLDER_FIRSTNAME_EN')}
                      value={values?.firstNameEn}
                    />
                  </InputGroup>
                  {errors.firstNameEn && touched.firstNameEn ? (
                    <div className='small text-danger'>{errors.firstNameEn}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={6} className="mt-md-0 mt-8">
                  <InputGroup className='h-50px'>
                    <InputGroup.Text id='basic-addon1'>
                      <img src='/assets/images/icons/user.svg'></img>
                    </InputGroup.Text>
                    <Field
                      className='form-control h-50px'
                      name='lastNameEn'
                      type="text"
                      placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PLACEHOLDER_LASTNAME_EN')}
                      value={values?.lastNameEn}
                    />
                  </InputGroup>
                  {errors.lastNameEn && touched.lastNameEn ? (
                    <div className='small text-danger'>{errors.lastNameEn}</div>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={6} className='mt-0 mt-md-8'>
              <Row>
                <Col sm={12} md={6} className="mt-md-0 mt-8">
                  <InputGroup className='h-50px'>
                    <InputGroup.Text id='basic-addon1'>
                      <img src='/assets/images/icons/user.svg'></img>
                    </InputGroup.Text>
                    <Field
                      className='form-control h-50px'
                      name='firstNameAr'
                      type="text"
                      placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PLACEHOLDER_FIRSTNAME_AR')}
                      value={values?.firstNameAr}
                    />
                  </InputGroup>
                  {errors.firstNameAr && touched.firstNameAr ? (
                    <div className='small text-danger'>{errors.firstNameAr}</div>
                  ) : null}
                </Col>
                <Col sm={12} md={6} className="mt-md-0 mt-8">
                  <InputGroup className='h-50px'>
                    <InputGroup.Text id='basic-addon1'>
                      <img src='/assets/images/icons/user.svg'></img>
                    </InputGroup.Text>
                    <Field
                      className='form-control h-50px'
                      name='lastNameAr'
                      type="text"
                      placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PLACEHOLDER_LASTNAME_AR')}
                      value={values?.lastNameAr}
                    />
                  </InputGroup>
                  {errors.lastNameAr && touched.lastNameAr ? (
                    <div className='small text-danger'>{errors.lastNameAr}</div>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </> : <>
            {/* Full Name En */}
            <Col sm={12} md={6} className='mt-8'>
              <InputGroup className='h-50px'>
                <InputGroup.Text id='basic-addon1'>
                  <img src='/assets/images/icons/user.svg'></img>
                </InputGroup.Text>
                <Field
                  className='form-control h-50px'
                  name='fullNameEn'
                  type="text"
                  placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PLACEHOLDER_FULLNAME_EN')}
                  value={values?.fullNameEn}
                />
              </InputGroup>
              {errors.fullNameEn && touched.fullNameEn ? (
                <div className='small text-danger'>{errors.fullNameEn}</div>
              ) : null}
              {!checkFullNameUser(values?.fullNameEn) && !errors.fullNameEn && isShowViewModalCreateUser &&
                <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_FULLNAME')}</div>
              }
            </Col>
            {/* Full Name Ar */}
            <Col sm={12} md={6} className='mt-8'>
              <InputGroup className='h-50px'>
                <InputGroup.Text id='basic-addon1'>
                  <img src='/assets/images/icons/user.svg'></img>
                </InputGroup.Text>
                <Field
                  className='form-control h-50px'
                  name='fullNameAr'
                  type="text"
                  placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PLACEHOLDER_FULLNAME_AR')}
                  value={values?.fullNameAr}
                />
              </InputGroup>
              {errors.fullNameAr && touched.fullNameAr ? (
                <div className='small text-danger'>{errors.fullNameAr}</div>
              ) : null}
              {!checkFullNameUser(values?.fullNameAr) && !errors.fullNameAr && isShowViewModalCreateUser &&
                <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_FULLNAME')}</div>
              }
            </Col>
          </>}
          {/* Email */}
          <Col sm={12} md={6} className='mt-8'>
            <InputGroup className='h-50px'>
              <InputGroup.Text id='basic-addon1'>
                <img src='/assets/images/icons/mail.svg'></img>
              </InputGroup.Text>
              <Field
                className='form-control h-50px'
                name='email'
                type="text"
                autoComplete="off"
                placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.EMAIL')}
                value={values?.email}
              />
            </InputGroup>
            {errors.email && touched.email ? (
              <div className='small text-danger'>{errors.email}</div>
            ) : null}
          </Col>
          {/* Phone */}
          <Col sm={12} md={6} className='mt-8'>
            <InputGroup className='h-50px'>
              <InputGroup.Text id='provider-name'>
                <img src='/assets/images/icons/phone.svg' alt='phone'></img>
              </InputGroup.Text>
              {/* <Field as='select' className='form-phone select-phone-type' name='firstPhoneNumber' onBlur={() => { handlePhoneChange(values, setFieldError) }}>
                <option value="+966">+966</option>
                <option value="+973">+973</option>
              </Field> */}
              <Field
                className='form-control'
                name='phone'
                type="text"
                placeholder={t('CREATE_CUSTOMER_MODAL.PHONE')}
              // onKeyUp={() => { handlePhoneChange(values, setFieldError) }}
              //onBlur={() => { handlePhoneChange(values, setFieldError) }}
              />
            </InputGroup>
            {errors.phone && touched.phone ? (
              <div className='small text-danger'>{errors.phone}</div>
            ) : null}
            {/* {textErrorPhone &&
              < div className='small text-danger'>{textErrorPhone}</div>
            } */}
          </Col>

          {/* ID */}
          {/* {values.userType !== USER_TYPE.TECHNICIAN &&
            <Col sm={12} md={6} className='mt-8'>
              <InputGroup className='h-50px'>
                <InputGroup.Text id='basic-addon1'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256"><rect width="12" height="12" fill="none"></rect><circle cx="80" cy="104" r="40" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle><line x1="152" y1="80" x2="248" y2="80" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="152" y1="128" x2="248" y2="128" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="176" y1="176" x2="248" y2="176" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M18,192a64,64,0,0,1,124,0" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>                  
                </InputGroup.Text>
                <Field
                  className='form-control h-50px'
                  name='workerId'
                  type="text"
                  placeholder={values.userType === USER_TYPE.WORKER ? t('USERS_PAGE.CONTENT_STEP.STEP2.ID_NOT_REQUIRED') : t('USERS_PAGE.CONTENT_STEP.STEP2.ID')}
                  value={values?.workerId}
                />
              </InputGroup>
              {errors.workerId && touched.workerId ? (
                <div className='small text-danger'>{errors.workerId}</div>
              ) : null}
            </Col>
          } */}

          {/* Nationality */}
          {/* {values.userType !== USER_TYPE.TECHNICIAN && <Col sm={12} md={6} className='mt-8'>
            <InputGroup className='h-50px'>
              <InputGroup.Text id='basic-addon1'>
                <img src='/assets/images/icons/globe.svg'></img>
              </InputGroup.Text>
              <Field as="select" name="nationality" className='form-select'>
                <option value="" disabled>{values.userType === USER_TYPE.WORKER ? t('USERS_PAGE.CONTENT_STEP.STEP2.NATIONALITY_NOT_REQUIRED') : t('USERS_PAGE.CONTENT_STEP.STEP2.NATIONALITY')}</option>
                {nationalities && nationalities.map((national: Nationality) => (
                  <option key={national.id} value={national.id}>{rtl ? national?.nameAr : national?.nameEn}</option>
                ))}

              </Field>
            </InputGroup>
            {errors.nationality && touched.nationality ? (
              <div className='small text-danger'>{errors.nationality}</div>
            ) : null}
          </Col>} */}



          {/* Password */}
          <Col sm={12} md={6} className='mt-8'>
            <InputGroup className='h-50px'>
              <InputGroup.Text id='basic-addon1'>
                <img src='/assets/images/icons/password.svg'></img>
              </InputGroup.Text>
              <Field
                className='form-control h-50px'
                name='password'
                type="password"
                autoComplete="off"
                placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.PASSWORD')}
                value={values?.password}
              />
            </InputGroup>
            {errors.password && touched.password ? (
              <div className='small text-danger'>{errors.password}</div>
            ) : null}
          </Col>

          {/* City */}
          {(values.userType !== USER_TYPE.ADMIN && values.userType !== USER_TYPE.WORKER) && <Col sm={12} md={6} className='mt-8'>
            <InputGroup className='h-50px'>
              <InputGroup.Text id='basic-addon1'>
                <img src='/assets/images/icons/globe-hemisphere-east.svg'></img>
              </InputGroup.Text>
              <Field as="select" name="cityId" className={`form-select custom-select ${Boolean(values.cityId) ? "selected":"" }`}>
                <option value="" disabled>{t('USERS_PAGE.CONTENT_STEP.STEP2.CITY')}</option>
                {cities && cities.map((cities: City) => (
                  <option key={cities.id} value={cities.id}>{rtl ? cities?.nameAr : cities?.nameEn}</option>
                ))}
              </Field>
            </InputGroup>
            {errors.cityId && touched.cityId ? (
              <div className='small text-danger'>{errors.cityId}</div>
            ) : null}
          </Col>}
          {/* Confirm Password */}
          {/* <Col sm={12} md={6} className='mt-8'>
            <InputGroup className='h-50px'>
              <InputGroup.Text id='basic-addon1'>
                <img src='/assets/images/icons/password.svg'></img>
              </InputGroup.Text>
              <Field
                className='form-control h-50px'
                name='confirmPassword'
                type="password"
                autoComplete="off"
                placeholder={t('USERS_PAGE.CONTENT_STEP.STEP2.CONFIRM_PASSWORD')}
                value={values?.confirmPassword}
              />
            </InputGroup>
            {errors.confirmPassword && touched.confirmPassword ? (
              <div className='small text-danger'>{errors.confirmPassword}</div>
            ) : null}
          </Col> */}
          {(values.userType !== USER_TYPE.WORKER) &&
            <Col sm={12} className='mt-8'>
              <div className='pb-4'>
                <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle' style={{ width: 'max-content' }}>
                  <input className='form-check-input  mx-2' type='checkbox' checked={isActive} onChange={handleChangeStatus} />
                  <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{t('USERS_PAGE.CONTENT_STEP.STEP4.ACTIVE')}</span>
                </label>
              </div>
            </Col>
          }
        </Row>
      </div>
    </>
  )
}


export default AccountInfo;