import * as Yup from 'yup';
import { USER_TYPE } from '../common/constants';

export const UserSchemasHelper = (t: any) => {
  const userSchemas = {
    ACCOUNT_TYPE: Yup.object({
      userType: Yup.string()
    }),
    ACCOUNT_INFO_ADMIN: Yup.object().shape({
      avatar: Yup.string(),
      firstPhoneNumber: Yup.string(),
      fullNameAr: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_AR'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      fullNameEn: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_EN'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      email: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL'))
        .when('email', {
          is: (value: string) => value?.length,
          then: (rule) => rule.email(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_EMAIL')),
        }
        ),
      // .required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL')),
      phone: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE')).phoneAr1(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PHONE')),
      //nationality: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_NATIONALITY')),
      // cityId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITY')),
      // workerId: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_ID'))
      //   .matches(/^[0-9]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID'))
      //   .length(10, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID')),
      password: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PASSWORD')),
      // confirmPassword: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CONFIRM_PASSWORD'))
      //   .oneOf([Yup.ref('password'), null], t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MATCH_CONFIRM_PASSWORD'))
    }, [
      // Add Cyclic deps here because when require itself
      ['email', 'email'],
    ]),
    ACCOUNT_INFO_TECHNICIAN: Yup.object().shape({
      avatar: Yup.string(),
      firstPhoneNumber: Yup.string(),
      fullNameAr: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_AR'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      fullNameEn: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_EN'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      email: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL'))
        .when('email', {
          is: (value: string) => value?.length,
          then: (rule) => rule.email(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_EMAIL')),
        }
        ),
      // .required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL')),
      phone: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE')).phoneAr1(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PHONE')),
      // nationality: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_NATIONALITY')),
      cityId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITY')),
      // id: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_ID'))
      //   .matches(/^[0-9]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID'))
      //   .length(10, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID')),
      password: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PASSWORD')),
      // confirmPassword: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CONFIRM_PASSWORD'))
      //   .oneOf([Yup.ref('password'), null], t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MATCH_CONFIRM_PASSWORD'))
    }, [
      // Add Cyclic deps here because when require itself
      ['email', 'email'],
    ]),
    ACCOUNT_INFO_WORKER: Yup.object().shape({
      avatar: Yup.string(),
      firstPhoneNumber: Yup.string(),
      // fullNameAr: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_AR'))
      //   .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      // fullNameEn: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_EN'))
      //   .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      firstNameEn: Yup.string().required(t('VALIDATOR.FIRST_NAME'))
        // .matches(/^[a-zA-Z0-9\s]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_FIRSTNAME'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      lastNameEn: Yup.string().required(t('VALIDATOR.LAST_NAME'))
        // .matches(/^[a-zA-Z0-9\s]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_LASTNAME'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      firstNameAr: Yup.string().required(t('VALIDATOR.FIRST_NAME'))
        // .matches(/^[a-zA-Z0-9\s]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_FIRSTNAME'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      lastNameAr: Yup.string().required(t('VALIDATOR.LAST_NAME'))
        // .matches(/^[a-zA-Z0-9\s]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_LASTNAME'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      // email: Yup.string().when('email', {
      //     is: (value: string) => value?.length,
      //     then: (rule) => rule.email(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_EMAIL')),
      //   }
      //   ),
      // .required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL')),
      phone: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE')).phoneAr1(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PHONE')),
      // nationality: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_NATIONALITY')),
      // workerId: Yup.string().nullable()
      //   .matches(/^[0-9]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID'))
      //   .length(10, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID')),
      // cityId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITY')),
      // id: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_ID'))
      //   .matches(/^[0-9]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID'))
      //   .length(10, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID')),
      password: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PASSWORD')),
      // confirmPassword: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CONFIRM_PASSWORD'))
      //   .oneOf([Yup.ref('password'), null], t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MATCH_CONFIRM_PASSWORD'))
    }, [
      // Add Cyclic deps here because when require itself
      ['email', 'email'],
    ]),
    WORK_INFO: {
      WORKER: Yup.object({
        // listWorkerSkill: Yup.array().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SKILL')),
        // listService: Yup.array().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SERVICE')),
        pricePerMonth: Yup.number()
          // .typeError(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PRICE_NUMBER'))
          // .default(0)
          .nullable()
          .min(1, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PRICE')),
      }),
      TECH: Yup.object({
        // dispatchCenterId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_DISPATCH_CENTER')),
        // listService: Yup.array().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SERVICE')),
        adminId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_ADMIN')),
        dispatchCenterLocation: Yup.object().requiredObj('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_DISPATCH_LOCATION'),
        // listService: Yup.array().when(['userType', 'step', 'formType'], {
        //   is: (userType: any, step: number, formType: string) => {
        //     return userType == USER_TYPE.TECHNICIAN && step == 3;
        //   }
        //   ,then: schema => schema.requireArray(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SERVICE'))
        // }
        // ),
        capacity: Yup.number().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CAPACITY')),
      }),
      DRIVER: Yup.object({
        linkedBuses: Yup.array().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_BUS')),
      }),
    },
    OPERATION_HOURS: Yup.object({
    }),
    WORK_LOCATION: {
      WORKER: Yup.object({
        //listSpLinked: Yup.array().requireArray(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_BUS')).required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_BUS')),
        listCity: Yup.array().when('workerTravel', {
          is: (workerTravel: boolean) => workerTravel === true,
          then: schema => schema.requireArray(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITIES'))
        })
      }),
      TECH: Yup.object({
        // cityId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITY')),
        // technician: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_TECHNICIAN')),
      }),
    },
    EDIT_ACCOUNT_INFO: Yup.object().shape({
      fullNameAr: Yup.string().trim().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_AR'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      fullNameEn: Yup.string().trim().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_EN'))
        .min(2, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_NAME')),
      phone: Yup.string().trim().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE')).phoneAr1(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PHONE')),
      id: Yup.string().trim().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_ID'))
        .matches(/^[0-9]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID'))
        .length(10, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID')),
    }, [
      // Add Cyclic deps here because when require itself
      ['email', 'email'],
    ]),

    ACCOUNT_INFO_ADMIN_UPDATE: Yup.object().shape({
      avatar: Yup.string(),
      firstPhoneNumber: Yup.string(),
      fullNameAr: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_AR')),
      fullNameEn: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_EN')),
      email: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL'))
        .when('email', {
          is: (value: string) => value?.length,
          then: (rule) => rule.email(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_EMAIL')),
        }
        ),
      phone: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE')).phoneAr1(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PHONE')),
      // nationality: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_NATIONALITY')),
      // workerId: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_ID'))
      //   .matches(/^[0-9]*$/, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID'))
      //   .length(10, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_ID')),
      password: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PASSWORD')),
      // cityId: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITY')),
      // confirmPassword: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CONFIRM_PASSWORD'))
      //   .oneOf([Yup.ref('password'), null], t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MATCH_CONFIRM_PASSWORD'))
    }, [
      // Add Cyclic deps here because when require itself
      ['email', 'email'],
    ]),
    ACCOUNT_INFO_TECHNICIAN_UPDATE: Yup.object().shape({
      avatar: Yup.string(),
      firstPhoneNumber: Yup.string(),
      fullNameAr: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_AR')),
      fullNameEn: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_FULLNAME_EN')),
      email: Yup.string().nullable().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_EMAIL'))
        .when('email', {
          is: (value: string) => value?.length,
          then: (rule) => rule.email(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_EMAIL')),
        }
        ),
      phone: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PHONE')).phoneAr1(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.VALIDATE_PHONE')),
      password: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_PASSWORD')),
      // confirmPassword: Yup.string().required(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CONFIRM_PASSWORD'))
      //   .oneOf([Yup.ref('password'), null], t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MATCH_CONFIRM_PASSWORD'))
    }, [
      // Add Cyclic deps here because when require itself
      ['email', 'email'],
    ]),
  }
  return userSchemas;
}