import axios from 'axios';
import { Response } from '../../../../setup/axios/HttpResponse';
import { API_URL } from '../../../utils/common/constants';
import { AddressByLatLngRequest, CreateCustomerRequest, CustomerSearchRequest, CustomerSearchResponse, MapLocation, CustomerTicketStatisticModel, CustomerSearchCreateOrderRequest, CustomerSearchCreateOrderResponse, CustomerSearchCreateOrderModel } from '../models';

const customerApi = {
    convertUrlLocation(payload: string, isToastMessage: boolean = true): Promise<Response<MapLocation>> {
        const url = `${API_URL}/api/v1/users/get-location-by-google-map-url?googleMapUrl=${payload}`
        return axios.get(url, { 'headers': { 'toast-message': isToastMessage } });
    },
    createCustomer(payload: CreateCustomerRequest): Promise<Response<string>> {
        const url = `${API_URL}/api/v1/users/create-customer`
        return axios.post(url, {
            fullName: payload.fullName,
            phoneNumber: payload.firstPhoneNumber + payload.phoneNumber,
            googleMapUrl: payload.map,
        });
    },
    searchCustomer(payload: CustomerSearchRequest): Promise<Response<CustomerSearchResponse>> {
        const url = `${API_URL}/api/v1/users/search-customer?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}`
        return axios.get(url);
    },
    checkB8akCustomer(payload: string): Promise<Response<boolean>> {
        const url = `${API_URL}/api/v1/users/check-b8ak-customer?customerId=${payload}`
        return axios.get(url);
    },
    fetchAddressByLatLng(payload: AddressByLatLngRequest, isToastMessage: boolean = true): Promise<Response<string>> {
        const url = `${API_URL}/api/v1/location/get-address-by-lat-lng?lat=${payload.lat}&lng=${payload.lng}`;
        return axios.get(url, { 'headers': { 'toast-message': isToastMessage } });
    },
    getCustomerTicketStatistic(payload: string, isToastMessage: boolean = true): Promise<Response<CustomerTicketStatisticModel>> {
        const url = `${API_URL}/api/v1/users/customer-ticket-statistic?customerId=${payload}`;
        return axios.get(url, { 'headers': { 'toast-message': isToastMessage } });
    },
    searchCustomerCreateOrder(payload: CustomerSearchCreateOrderRequest): Promise<Response<CustomerSearchCreateOrderModel>> {
        const url = `${API_URL}/api/v1/users/search-customer-create-order?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}`;
        return axios.get(url);
    }
};


export { customerApi };