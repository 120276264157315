import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {selectLanguage} from '../../../../app/modules/auth'

import {Languages, PageUrl} from '../../../../app/utils/common/constants'
import {useAppSelector} from '../../../../setup/redux/Hooks'

type Props = {
  title?: string
}

const B8akToolbar: React.FC<Props> = ({title}) => {
  const language = useAppSelector(selectLanguage)
  const {t} = useTranslation()

  return (
    <div className='row toolbar-b8ak my-5'>
      <div className='col-lg-12 col-sm-12'>
        <h2 className='title'>{title}</h2>
      </div>
      {/* <div className='col-lg-12 col-sm-12 head-breadcrumb pt-18'>
      <Link to={PageUrl.HOME}>{t('HOME')}</Link>
      {language === Languages.ar ?
        <i className="fas fa-chevron-left mx-5"></i>
        : <i className="fas fa-chevron-right mx-5"></i>
      }
      <span>{title}</span>

    </div> */}
    </div>
  )
}

export {B8akToolbar}
