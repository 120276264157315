import './style.scss';

import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import { STEP_USERS, USER_TYPE } from '../../../../../../utils/common/constants';
import { SelectFilter, ServiceTreeNode } from '../../../../../../utils/common/models';
import { convertServiceToTreeUserModal } from '../../../../../../utils/services/userService';
import { ServiceScopeModel } from '../../../../../auth/models/AuthModel';
import { AdminResponse, BusNotLinkedDriver, DispatchCenter, Driver, LinkedDispatcher, Skill, UserFormModel } from '../../../../models';
import { WorkInfoAdmin, WorkInfoTech, WorkInfoDriver, WorkInFoWorker } from '../../component';
import _ from 'lodash';

type Props = {
  className?: string,
  values?: UserFormModel,
  rtl?: boolean,
  step?: number,
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  skills: Skill[],
  linkedDispatchers: LinkedDispatcher[],
  services?: ServiceScopeModel,
  dispatchCenters?: DispatchCenter[],
  drivers?: Driver[],
  busesNotLinkedDriver: BusNotLinkedDriver[],
  currentCheckCustomStep: number,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setTouched: (value: FormikTouched<UserFormModel>, shouldValidate?: boolean) => void,
  listAdmin: AdminResponse[],
  techId?: string | null,
  isUpdateForm?: boolean
}

const WorkInfo: React.FC<Props> = ({
  currentCheckCustomStep, rtl, step, values, errors, touched,
  skills, linkedDispatchers, services, dispatchCenters, drivers, busesNotLinkedDriver,
  setFieldValue, setTouched, listAdmin, techId, isUpdateForm = false
}) => {
  const { t } = useTranslation();
  const [checkFirstReset, setCheckFirstReset] = useState(false);

  const [selectService, setSelectedService] = useState<ServiceTreeNode[]>([]);
  const [selectSkill, setSelectedSkill] = useState<SelectFilter[]>([]);
  const [selectBuses, setSelectedBuses] = useState<SelectFilter[]>([]);
  const [selectLinkedDispatcher, setSelectLinkedDispatcher] = useState<SelectFilter[]>([]);

  const [skillSetup, setSkillSetup] = useState<SelectFilter[]>([]);
  const [busesSetup, setBusesSetup] = useState<SelectFilter[]>([]);
  const [linkedDispatcherSetup, setLinkedDispatcherSetup] = useState<SelectFilter[]>([]);
  const [serviceFormatTreeData, setServiceFormatTreeData] = useState<ServiceTreeNode[]>([]);

  const [toggleActive, setToggleActive] = useState(values?.isActive);

  const overrideStringsSkills = {
    "allItemsAreSelected": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_SKILL.ALL_SELECTED'),
    "search": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_SKILL.SEARCH'),
    "selectAll": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_SKILL.ALL_SELECTED'),
    "selectSomeItems": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_SKILL.SELECT_ITEM'),
  }

  const overrideStringsBus = {
    "allItemsAreSelected": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_BUS.ALL_SELECTED'),
    "search": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_BUS.SEARCH'),
    "selectAll": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_BUS.ALL_SELECTED'),
    "selectSomeItems": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_BUS.SELECT_ITEM'),
  }

  const overrideStringsLinkedDispatcher = {
    "allItemsAreSelected": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_DISPATCHER.ALL_SELECTED'),
    "search": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_DISPATCHER.SEARCH'),
    "selectAll": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_DISPATCHER.ALL_SELECTED'),
    "selectSomeItems": t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_DISPATCHER.SELECT_ITEM'),
  }

  const optionSkills = skillSetup.map((data: any) => {
    return { label: data.label, value: data.value };
  });

  const optionBuses = busesSetup.map((data: any) => {
    return { label: data.label, value: data.value };
  });

  const optionLinkedDispatcher = linkedDispatcherSetup.map((data: any) => {
    return { label: data.label, value: data.value };
  });

  // effect reset touched form
  useEffect(() => {
    if (step != STEP_USERS.WORK_INFO || checkFirstReset || Object.entries(touched).length === 0) return;
    setCheckFirstReset(true);
    setTouched({}, false);
  }, [touched, step]);

  useEffect(() => {
    const newServices = (_.cloneDeep(services) || {})
    newServices.majorServices = (_.cloneDeep(newServices?.majorServices) || []).filter(item => Boolean(item?.isCentroService))
    const serviceTree = convertServiceToTreeUserModal(newServices, rtl, t('USERS_PAGE.VIEW_USER_MODAL.SEARCH_SERVICE_NULL'));
    if (values?.listServiceId) {
      let subsubSelected = [] as ServiceTreeNode[];

      serviceTree.forEach(major => {
        if (!major.children || major.children.length == 0) return;
        major.children?.forEach(sub => {
          if (!sub.children || sub.children.length == 0) return;
          sub.children.forEach(subSub => {
            subSub.checked = subSub.subSubId ? values.listServiceId?.includes(subSub.subSubId) : false;
            if (subSub.checked) {
              subsubSelected.push(subSub);
            }

          })
        })
      });
      setSelectedService(subsubSelected);
      setFieldValue('listService', subsubSelected);
    }
    setServiceFormatTreeData(serviceTree);
    if (!values?.listServiceId) {
      setSelectedService([]);
    }
  }, [services, values?.listServiceId]);

  useEffect(() => {
    const tmpSelectSkill: SelectFilter[] = values?.listWorkerSkill?.map((item: Skill) => {
      const itemSelectSkillTmp: SelectFilter = { label: rtl ? item.nameAr : item.nameEn, value: item.id }
      return itemSelectSkillTmp;
    }) || [];
    setSelectedSkill(tmpSelectSkill);
  }, [values?.listWorkerSkill])

  // effect Format Service Tree Data
  useEffect(() => {
    if (!serviceFormatTreeData || serviceFormatTreeData.length == 0) return;
    let dataTmp = clearCheckTreeData();
    dataTmp.forEach(major => {
      if (!major.children || major.children.length == 0) return;
      major.children?.forEach(sub => {
        if (!sub.children || sub.children.length == 0) return;
        sub.children.forEach(subSub => {
          const index = selectService.findIndex(service => major.majorId == service.majorId && sub.subId == service.subId && service.subSubId == subSub.subSubId);
          if (index >= 0) subSub.checked = true;
          else subSub.checked = false;
        })
      })
    });
    setServiceFormatTreeData(dataTmp);
  }, [selectService]);

  // format data skills select
  useEffect(() => {
    const data: any = skills.length == 0 ? [] : skills.map((data: Skill) => {
      return { label: rtl ? data?.nameAr : data?.nameEn, value: data?.id }
    })
    setSkillSetup(data);
  }, [skills]);

  // format data buses select
  useEffect(() => {
    const data: any = busesNotLinkedDriver.length == 0 ? [] : busesNotLinkedDriver.map((data: BusNotLinkedDriver) => {
      return { label: rtl ? data?.fullNameAr : data?.fullNameEn, value: data?.id }
    })
    setBusesSetup(data);
  }, [busesNotLinkedDriver]);

  // format data linked dispatcher select
  useEffect(() => {
    const data: any = linkedDispatchers.length == 0 ? [] : linkedDispatchers.map((data: LinkedDispatcher) => {
      return { label: rtl ? data?.nameAr : data?.nameEn, value: data?.id }
    })
    setLinkedDispatcherSetup(data);
  }, [linkedDispatchers])

  const changeActive = () => {
    const isActive = !values?.isActive;
    setFieldValue("isActive", isActive);
    setToggleActive(isActive)
  }

  // Work info Worker
  const clearCheckTreeData = () => {
    let dataTmp = [...serviceFormatTreeData];
    dataTmp.forEach(major => {
      if (!major.children || major.children.length == 0) return;
      major.children?.forEach(sub => {
        if (!sub.children || sub.children.length == 0) return;
        sub.children.forEach(subSub => {
          subSub.checked = true;
        })
      })
    });
    return dataTmp;
  }

  const handleChangeSkill = (event: SelectFilter[]) => {
    if (!values) return;
    const selectSkillTmp = [...event];
    setSelectedSkill(selectSkillTmp);
    checkAndUpdateSkillFormData(selectSkillTmp);
  }

  const checkAndUpdateSkillFormData = (selectSkillTmp: SelectFilter[]) => {
    if (!values) return;
    if (selectSkillTmp.length == 0) setFieldValue("listWorkerSkill", []);
    else {
      let newData: Skill[] = [];
      selectSkillTmp.forEach((stmp: SelectFilter) => {
        skills.forEach((s: Skill) => {
          if (s.id == stmp.value) newData.push(s);
        })
      })
      setFieldValue("listWorkerSkill", [...newData]);
    }
  }

  const onSetServiceFormatTreeData = (s: ServiceTreeNode[]) => {
    setServiceFormatTreeData(s);
  }
  const removeItemService = (s: ServiceTreeNode) => {
    let selectServiceTmp = [...selectService];
    const index = selectServiceTmp.findIndex((service: ServiceTreeNode) => service.subSubId == s.subSubId);
    if (index < 0) return;
    selectServiceTmp.splice(index, 1);
    setSelectedService(selectServiceTmp);
    setFieldValue("listService", [...selectServiceTmp]);
  }

  const removeItemSkill = (id: string) => {
    if (!values) return;
    let selectSkillTmp = [...selectSkill];
    const index = selectSkillTmp.findIndex((skill: SelectFilter) => skill.value == id);
    if (index < 0) return;
    selectSkillTmp.splice(index, 1);
    setSelectedSkill(selectSkillTmp)
    checkAndUpdateSkillFormData(selectSkillTmp);
  }

  const onSelectedSubSubService = (selectedNodes: ServiceTreeNode[]) => {
    setSelectedService(selectedNodes);
    setFieldValue('listService', selectedNodes);
  }
  //End  Work info Worker

  // Work info Admin
  const handleChangeLinkedDispatcher = (event: SelectFilter[]) => {
    if (!values) return;
    const selectDispatcherTmp = [...event];
    setSelectLinkedDispatcher(selectDispatcherTmp);
    checkAndUpdateLinkedDispatcherFormData(selectDispatcherTmp);
  }

  const removeItemLinkedDispatcher = (id: string) => {
    if (!values) return;
    let selectDispatcherTmp = [...selectLinkedDispatcher];
    const index = selectDispatcherTmp.findIndex((dispatcher: SelectFilter) => dispatcher.value == id);
    if (index < 0) return;
    selectDispatcherTmp.splice(index, 1);
    setSelectLinkedDispatcher(selectDispatcherTmp)
    checkAndUpdateLinkedDispatcherFormData(selectDispatcherTmp);
  }

  const checkAndUpdateLinkedDispatcherFormData = (selectDispatcherTmp: SelectFilter[]) => {
    if (!values) return;
    if (selectDispatcherTmp.length == 0) setFieldValue("linkedDispatchers", []);
    else {
      let newData: LinkedDispatcher[] = [];
      selectDispatcherTmp.forEach((stmp: SelectFilter) => {
        linkedDispatchers.forEach((ld: LinkedDispatcher) => {
          if (ld.id == stmp.value) newData.push(ld);
        })
      })
      setFieldValue("linkedDispatchers", [...newData]);
    }
  }
  //End  Work info Admin

  // Work info Driver
  const handleChangeBuses = (event: SelectFilter[]) => {
    if (!values) return;
    const selectBusesTmp = [...event];
    setSelectedBuses(selectBusesTmp);
    checkAndUpdateBusesFormData(selectBusesTmp);
  }

  const removeItemBuses = (id: string) => {
    if (!values) return;
    let selectBusesTmp = [...selectBuses];
    const index = selectBusesTmp.findIndex((bus: SelectFilter) => bus.value == id);
    if (index < 0) return;
    selectBusesTmp.splice(index, 1);
    setSelectedBuses(selectBusesTmp)
    checkAndUpdateBusesFormData(selectBusesTmp);
  }

  const checkAndUpdateBusesFormData = (selectBusesTmp: SelectFilter[]) => {
    if (!values) return;
    if (selectBusesTmp.length == 0) setFieldValue("linkedBuses", []);
    else {
      let newData: BusNotLinkedDriver[] = [];
      selectBusesTmp.forEach((stmp: SelectFilter) => {
        busesNotLinkedDriver.forEach((b: BusNotLinkedDriver) => {
          if (b.id == stmp.value) newData.push(b);
        })
      })
      setFieldValue("linkedBuses", [...newData]);
    }
  }
  // End work Info Driver
  return (
    <>
      <div className='box-step-2'>
        <Row className='flex-root step-1 m-auto mb-10'>
          {/* step type Admin */}
          {values?.userType == USER_TYPE.ADMIN && (
            <WorkInfoAdmin
              toggleActive={toggleActive}
              changeActive={changeActive}
              optionLinkedDispatcher={optionLinkedDispatcher}
              selectLinkedDispatcher={selectLinkedDispatcher}
              overrideStrings={overrideStringsLinkedDispatcher}
              removeItemLinkedDispatcher={removeItemLinkedDispatcher}
              handleChangeLinkedDispatcher={handleChangeLinkedDispatcher}
              errors={errors}
              touched={touched}
              currentCheckCustomStep={currentCheckCustomStep}
            />
          )}
          {/* end step type Admin */}

          {/* step type Bus */}
          {values?.userType == USER_TYPE.TECHNICIAN && (
            <WorkInfoTech
              rtl={rtl}
              errors={errors}
              currentCheckCustomStep={currentCheckCustomStep}
              serviceFormatTreeData={serviceFormatTreeData}
              selectService={selectService}
              onSelectedSubSubService={onSelectedSubSubService}
              setServiceFormatTreeData={onSetServiceFormatTreeData}
              listAdmin={listAdmin}
              techId={techId}
              values={values}
              touched={touched}
            />
          )}
          {/* end step type Driver */}

          {/* step type Driver */}
          {values?.userType == USER_TYPE.DRIVER && (
            <WorkInfoDriver
              toggleActive={toggleActive}
              changeActive={changeActive}
              optionBuses={optionBuses}
              selectBuses={selectBuses}
              overrideStrings={overrideStringsBus}
              removeItemBuses={removeItemBuses}
              handleChangeBuses={handleChangeBuses}
              currentCheckCustomStep={currentCheckCustomStep}
              rtl={rtl}
              errors={errors}
              touched={touched}
            />
          )}
          {/* end step type Driver */}

          {/* step type Worker */}
          {values?.userType == USER_TYPE.WORKER &&
            (
              <WorkInFoWorker
                values={values}
                rtl={rtl}
                errors={errors}
                touched={touched}
                currentCheckCustomStep={currentCheckCustomStep}
                serviceFormatTreeData={serviceFormatTreeData}
                selectService={selectService}
                optionSkills={optionSkills}
                selectSkill={selectSkill}
                overrideStrings={overrideStringsSkills}
                onSelectedSubSubService={onSelectedSubSubService}
                setServiceFormatTreeData={onSetServiceFormatTreeData}
                removeItemService={removeItemService}
                removeItemSkill={removeItemSkill}
                handleChangeSkill={handleChangeSkill}
                isUpdateForm={isUpdateForm}
              />
            )}
          {/* end step type Worker */}
        </Row>
      </div>
    </>
  )
}

export default WorkInfo;