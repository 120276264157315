import './style.scss'

import React from 'react';
import { useTranslation } from 'react-i18next';
import { USER_TYPE } from '../../../../../../utils/common/constants';
import { classNameFunc } from 'app/utils/helpers/components/Form';

type Props = {
  className?: string,
  onHide?: () => void;
  continueCreateNewUser: (type: string) => void;
  rtl?: boolean;
  formDataUpdate: any
}

const WorkerSuccessModal: React.FC<Props> = ({ className, rtl, onHide, continueCreateNewUser, formDataUpdate }) => {
  const { t } = useTranslation();

  const userType = formDataUpdate?.get('UserType');

  const closeModal = () => {
    if (onHide) onHide();
  }

  const addNewUser = () => {
    if (onHide) onHide();
    continueCreateNewUser(formDataUpdate.get('UserType'))
  }

  const titleSuccess = () => {
    if (!userType) return '';
    switch (userType) {
      case USER_TYPE.WORKER:
        return t('USERS_PAGE.VIEW_USER_MODAL.WORKER_TITLE_SUCCESS')
      case USER_TYPE.TECHNICIAN:
        return t('USERS_PAGE.VIEW_USER_MODAL.TECH_TITLE_SUCCESS')
      case USER_TYPE.DRIVER:
        return t('USERS_PAGE.VIEW_USER_MODAL.DRIVER_TITLE_SUCCESS')
      case USER_TYPE.ADMIN:
        return t('USERS_PAGE.VIEW_USER_MODAL.SUPERVISOR_TITLE_SUCCESS')
    }
  }

  const buttonSuccess = () => {
    if (!userType) return '';
    switch (userType) {
      case USER_TYPE.WORKER:
        return t('USERS_PAGE.VIEW_USER_MODAL.ADD_NEW_WORKER')
      case USER_TYPE.TECHNICIAN:
        return t('USERS_PAGE.VIEW_USER_MODAL.ADD_NEW_TECH')
      case USER_TYPE.DRIVER:
        return t('USERS_PAGE.VIEW_USER_MODAL.ADD_NEW_DRIVER')
      case USER_TYPE.ADMIN:
        return t('USERS_PAGE.VIEW_USER_MODAL.ADD_NEW_SUPERVISOR')
    }
  }

  return (
    <>
      <div className='box-success center-item-success'>
        <div className="content-success center-item-success">
          <div className="image">
            <img src="/assets/images/icons/success-employer.svg" />
          </div>
          <div className="text">
            <div style={{ fontSize: '25px' }} className={classNameFunc([`text__title`, userType == USER_TYPE.WORKER && ['text-worker', 'my-2']])}>
              {titleSuccess()}
            </div>
          </div>
        </div>
        <div className="btn-finish">
          <button className='btn-create-emp btn btn-secondary fs-14' style={{ 'width': '211px', 'height': '50px' }} onClick={closeModal}>{t('CLOSE')}</button>
          <div style={{ 'width': '20px' }}></div>
          <button className='btn-create-emp btn btn-dark fs-14' style={{ 'width': '220px', 'height': '50px' }} onClick={addNewUser}>{buttonSuccess()}</button>
        </div>
      </div>
    </>
  )
}

export default WorkerSuccessModal;