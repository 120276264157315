import {selectLanguage} from 'app/modules/auth'
import {Languages} from 'app/utils/common/constants'
import {Field, FieldConfig, FieldProps} from 'formik'
import React from 'react'
import {Form, InputGroup} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import Select, {GroupBase, FormatOptionLabelContext, SelectComponentsConfig} from 'react-select'
import AsyncSelect from 'react-select/async'
import {useAppSelector} from 'setup/redux/Hooks'
import {classNameFunc, IClassName} from './utils'

type IOption = {
  value?: string | number
  label?: string
}

export interface IPropsSelector<
  T,
  IsMulti extends boolean = false,
  IGroup extends boolean = false
> {
  classContainer?: IClassName
  formatGroupLabel?: (group: IGroup extends true ? GroupBase<T> : false) => React.ReactNode
  formatOptionLabel?: (
    data: IGroup extends true ? {label: string; options: T[]} : T,
    context: FormatOptionLabelContext
  ) => React.ReactNode
  inputGroup?: React.ReactNode
  isGroup?: IGroup
  isMulti?: IsMulti
  checkboxComponent?: React.ReactNode
  filterComponent?: React.ReactNode
  isNative?: boolean
  label?: string
  inputPlaceholder?: string
  noOptionsMessage?: string
  onFocus?: () => void
  onBlur?: () => void
  onMenuClose?: () => void
  onSelected?: (e: IsMulti extends true ? T[] : T) => void
  optionDisabled?: (e: T, index?: number) => boolean | undefined
  optionKey?: (e: T, index?: number) => number | string
  optionLabel?: (e: T, index?: number) => number | string
  options?: IGroup extends true ? {label: string; options: T[]}[] : T[]
  values?: IsMulti extends true ? T[] : T
  isInvalid?: boolean
  error?: string
  loading?: boolean
  isTouchedBlur?: boolean
  inputDisabled?: boolean
  isSearchable?: boolean
}

export const Selector = <T, IsMulti extends boolean = false, IGroup extends boolean = false>(
  props: IPropsSelector<T, IsMulti, IGroup>
) => {
  const {
    classContainer,
    formatGroupLabel,
    formatOptionLabel,
    inputGroup,
    isGroup = false,
    isMulti,
    checkboxComponent,
    isNative = false,
    label,
    inputPlaceholder,
    noOptionsMessage = 'No options',
    onFocus,
    onBlur: _onBlur,
    onMenuClose,
    onSelected,
    optionDisabled,
    optionKey = (e, index) => index || 0,
    optionLabel = (e, index) => 'Item-' + ((index || 0) + 1),
    options = [],
    values,
    isInvalid: _isInvalid,
    error: __error,
    loading = false,
    isTouchedBlur = true,
    filterComponent,
    inputDisabled = false,
    isSearchable,

    //formik
    field,
    form,

    ...rest
  } = props as IPropsSelector<T, IsMulti, IGroup> & FieldProps

  const {t} = useTranslation()
  const language = useAppSelector(selectLanguage)
  const touched = form?.touched[field.name]
  let _error = __error !== undefined ? __error : form?.errors[field.name]
  let [error, params = ''] = ![null, undefined, ''].includes(_error as any)
    ? String(_error).split('-')
    : [_error, '']
  error = ![null, undefined, ''].includes(error as any)
    ? t(error as string).format(params.split(';'))
    : error
  const isInvalid = _isInvalid !== undefined ? _isInvalid : touched === true && error != undefined

  const _options = options.map((e: any, index: number) =>
    'options' in e
      ? {
          ...e,
          options: e.options.map((e: any, index: number) => ({
            ...e,
            label: optionLabel(e, index),
            value: optionKey(e, index),
          })),
        }
      : {
          ...e,
          label: optionLabel(e, index),
          value: optionKey(e, index),
        }
  )

  const _optionsFlat = _options.map((e) => ('options' in e ? e.options : [e])).flat()
  const renderInput = () =>
    false ? (
      <AsyncSelect
        pageSize={20}
        styles={{
          container: () => ({width: 200}),
          control: (provided, state) => {
            return {
              ...provided,
              height: 50,
              borderColor: '#f6f6f6',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              ...(state.isFocused ? {boxShadow: 'unset', borderColor: '#b5b5c3'} : {}),
              '&:hover': {borderColor: state.isFocused ? '#b5b5c3' : '#f6f6f6'},
              '&:focus': {borderColor: '#b5b5c3'},
            }
          },
        }}
        loadOptions={(initValue, options) => {
          setTimeout(() => {
            options(
              new Array(1000).fill(1).map((e, index) => ({id: index, label: 'package ' + index}))
            )
          }, 1000)
        }}
        isDisabled={loading}
      />
    ) : !isNative ? (
      //@ts-ignore
      <Select<typeof _options[0], boolean, GroupBase<typeof _options[0]>>
        {...rest}
        //@ts-ignore
        value={values || (isMulti ? [] : null)}
        placeholder={inputPlaceholder}
        isSearchable={isSearchable ?? true}
        noOptionsMessage={(inputValue) => noOptionsMessage}
        styles={{
          container: () => ({flex: 1}),
          input: (provided, state) => ({
            ...provided,
            border: '0 !important',
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            width: 0,
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: filterComponent ? '#7E8299 !important' : '#0C1B32 !important',
            padding: 5,
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 10,
            width: '100%',
            left: 0,
            right: 0,
            ...(filterComponent && {padding: '8px', boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)'}),
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            minHeight: '32px',
            padding: '0 8px',
            ...(filterComponent && {color: '#A1A5B7 !important'}),
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            minHeight: '32px',
          }),
          control: (provided, state) => {
            return {
              ...provided,
              height: isMulti ? undefined : '34.3px',
              minHeight: '34.3px',
              borderColor: isInvalid ? '#e96161' : '#eeeeee',
              ...(!filterComponent &&
                (language === Languages.en
                  ? {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }
                  : {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    })),
              ...(state.isFocused ? {boxShadow: 'unset', borderColor: '#b5b5c3'} : {}),
              '&:hover': {borderColor: state.isFocused ? '#b5b5c3' : '#eeeeee'},
              '&:focus': {borderColor: '#b5b5c3'},
              ...(filterComponent && {fontWeight: 600}),
            }
          },
          option: (provided, state) => {
            return {
              ...provided,
              transition: 'all 0.25s ease',
              ...(filterComponent && {
                borderRadius: filterComponent && '4px',
                margin: '4px 0px',
              }),
              ...(state.isSelected && {
                color: 'initial',
                background: '#ffc20f',
                ...(filterComponent && {
                  background: '#d9d9d9',
                  color: '#A1A5B7',
                }),
                height: '35px',
              }),
              height: '35px',
              paddingTop: '7px',
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(state.isFocused ? {background: filterComponent ? '#F6F6F6' : '#F7D87D'} : {}),
              '&:hover': {background: filterComponent ? '#F6F6F6' : '#F7D87D'},
              '&:focus': {background: filterComponent ? '#F6F6F6' : '#F7D87D'},
            }
          },
          singleValue: (provided) => {
            return {...provided, ...(filterComponent && {color: '#A1A5B7'})}
          },
        }}
        isMulti={isMulti}
        options={_options}
        onFocus={onFocus}
        onBlur={onBlur}
        onMenuClose={onMenuClose}
        backspaceRemovesValue={true}
        {...(isMulti &&
          checkboxComponent !== undefined && {
            closeMenuOnSelect: false,
            hideSelectedOptions: false,
            components: {
              Option: checkboxComponent,
            },
          })}
        {...(filterComponent !== undefined && {
          components: {
            Option: filterComponent,
          },
        })}
        {...(formatGroupLabel !== undefined && {formatGroupLabel})}
        {...(formatOptionLabel !== undefined && {formatOptionLabel})}
        {...(form === undefined
          ? {
              onChange: (e) => {
                const selected =
                  e == undefined
                    ? undefined
                    : Array.isArray(e)
                    ? (e as (T & {value: string | number})[])
                    : typeof e == 'object'
                    ? (e as {value: string | number})
                    : undefined
                onSelected && selected != undefined && onSelected(selected as any)
              },
            }
          : {
              value:
                field.value == null
                  ? isMulti
                    ? []
                    : null
                  : isMulti
                  ? _optionsFlat.filter((e) => field.value.find((e1: any) => e.value == e1))
                  : _optionsFlat.find((e) => e.value == field.value),
              onChange: (e) => {
                const selected =
                  e == undefined
                    ? undefined
                    : Array.isArray(e)
                    ? (e as (T & {value: string | number})[])
                    : typeof e == 'object'
                    ? (e as {value: string | number})
                    : undefined
                form.setFieldValue(
                  field.name,
                  Array.isArray(selected) ? selected.map((e) => e.value) : selected?.value
                )
              },
            })}
        isDisabled={loading || inputDisabled}
      />
    ) : (
      <Form.Select
        {...(form != null && {
          name: field.name,
          value: field.value,
          onChange: form.handleChange,
          onBlur: () => form.setFieldTouched(field.name, true),
        })}
        disabled={loading || inputDisabled}
      >
        {options.map((e: any, index) =>
          'options' in e ? (
            <optgroup label={e.label}>
              {e.options.map((e: any) => (
                <option
                  key={optionKey(e, index) || index}
                  value={optionKey(e, index)}
                  disabled={(optionDisabled && optionDisabled(e, index)) || false}
                >
                  {optionLabel(e, index)}
                </option>
              ))}
            </optgroup>
          ) : (
            <option
              key={optionKey(e, index) || index}
              value={optionKey(e, index)}
              disabled={(optionDisabled && optionDisabled(e, index)) || false}
            >
              {optionLabel(e, index)}
            </option>
          )
        )}
      </Form.Select>
    )

  const onBlur = () => {
    isTouchedBlur &&
      form &&
      setTimeout(() => {
        form.setFieldTouched(field.name, true)
      }, 100)
    _onBlur && _onBlur()
  }
  return (
    <Form.Group
      className={classNameFunc([isInvalid && 'has-error', classContainer, 'position-relative'])}
    >
      {label != null && <Form.Label>{label}</Form.Label>}
      {inputGroup == undefined ? (
        <>
          {renderInput()}
          {isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
        </>
      ) : (
        <InputGroup>
          <InputGroup.Text>{inputGroup}</InputGroup.Text>
          {renderInput()}
          {isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
        </InputGroup>
      )}
    </Form.Group>
  )
}

export type IPropsFieldSelector<T, IsMulti extends boolean> = IPropsSelector<T, IsMulti> &
  FieldConfig
export const FieldSelector = <T, IsMulti extends boolean>(
  props: IPropsFieldSelector<T, IsMulti>
) => <Field component={Selector} {...props} />
