import { FieldInputMapPlaces, InputMapPlaces } from 'app/utils/helpers/components/Form';
import { Field, FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, InputGroup } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import { STEP_USERS } from '../../../../../../utils/common/constants';
import { ServiceTreeNode } from '../../../../../../utils/common/models';
import { KTSVG } from '../../../../../../utils/helpers';
import { AdminResponse, UserFormModel } from '../../../../models';
import { TreeServiceUser } from '../tree-service-user/TreeServiceUser';

type Props = {
  rtl?: boolean,
  errors: FormikErrors<UserFormModel>,
  currentCheckCustomStep: number,
  serviceFormatTreeData: ServiceTreeNode[],
  selectService: ServiceTreeNode[],
  onSelectedSubSubService: (selectedNodes: ServiceTreeNode[]) => void,
  setServiceFormatTreeData: (s: ServiceTreeNode[]) => void,
  listAdmin: AdminResponse[],
  values?: UserFormModel,
  techId?: string | null
  touched: FormikTouched<UserFormModel>,
}

const WorkInfoTech: React.FC<Props> = ({
  currentCheckCustomStep, rtl, errors,
  serviceFormatTreeData, selectService,
  onSelectedSubSubService, setServiceFormatTreeData,
  listAdmin, values, techId, touched
}) => {
  
  const { t } = useTranslation();

  return (
    <div>
      {/* Select Services */}
      <Col sm={12} className='mt-8'>
        <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.TITLE_SEARCH_SERVICE')}</h6>

        <TreeServiceUser data={serviceFormatTreeData} onChange={setServiceFormatTreeData} onSelectedSubSubService={onSelectedSubSubService} />
        {touched['listService'] && errors['listService'] ? (
          <div className='small text-danger'>{errors['listService']}</div>
        ) : null}
      </Col>

      {/* Select Admin */}
      <Col sm={8} className='mt-8'>
        <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_ADMIN.TITLE')}</h6>
        <InputGroup className=''>
          <InputGroup.Text id='basic-addon1'>
            <KTSVG defaultColor={true} path="/assets/images/icons/service-and-skill.svg" />
          </InputGroup.Text>
          <Field as="select" name="adminId" className='form-select'>
            <option value="" disabled>{t('USERS_PAGE.CONTENT_STEP.STEP3.FILTER_ADMIN.SELECT_ITEM')}</option>
            {listAdmin && listAdmin.map((admin: AdminResponse) => (
              <option key={admin.id} value={admin.id}>{rtl ? admin?.fullNameAr : admin?.fullNameEn}</option>
            ))}

          </Field>
        </InputGroup>

        {(touched['adminId'] && errors?.adminId) && <div className='small text-danger'>{errors?.adminId}</div>}
      </Col>
      <Col sm={8} className="mt-8">
        <FieldInputMapPlaces
          name='dispatchCenterLocation'
          styleLabel={{
            fontSize: '1.075rem',
            fontWeight: 600,
            color: '#00171f',
            lineHeight: 1.2
          }}
          classContainer='dispatch-center-location'
          label={t('USERS_PAGE.CONTENT_STEP.STEP3.DISPATCH_LOCATION_TITLE')}
          placeholder={t('USERS_PAGE.CONTENT_STEP.STEP3.DISPATCH_LOCATION_TITLE_PLACEHOLDER')}
          inputGroup={
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' >
              <path d='M1.41448 4.20003C1.24295 4.20098 1.07653 4.14032 0.944215 4.02861C0.811895 3.9169 0.72206 3.76122 0.690359 3.5887C0.658658 3.41617 0.687099 3.23772 0.770729 3.08445C0.854359 2.93117 0.987877 2.81279 1.14802 2.74991L5.78096 0.772778C5.87826 0.734036 5.98232 0.716279 6.08663 0.72062C6.19094 0.724961 6.29325 0.751306 6.38714 0.798003L9.9243 2.6231L14.2648 0.77346C14.443 0.699874 14.6423 0.700543 14.82 0.775322C14.9978 0.850102 15.1399 0.993063 15.216 1.17366C15.2877 1.3562 15.287 1.56014 15.2141 1.74217C15.1412 1.9242 15.0019 2.06999 14.8257 2.14859L10.1928 4.12571C10.095 4.16433 9.99048 4.18178 9.8858 4.17697C9.78112 4.17216 9.67855 4.14519 9.58457 4.09776L6.02277 2.29789L1.70691 4.14753C1.61188 4.17712 1.51367 4.19475 1.41448 4.20003Z' fill='#00171F' />
              <path d='M1.39087 15.723C1.22137 15.7221 1.05732 15.6616 0.926186 15.5517C0.795053 15.4418 0.704799 15.2891 0.670537 15.1192C0.636276 14.9493 0.660087 14.7725 0.737985 14.6184C0.815882 14.4644 0.943135 14.3424 1.09843 14.2729L5.75602 12.2957C5.85378 12.2565 5.95844 12.2385 6.06337 12.2428C6.16829 12.2472 6.27119 12.2738 6.36553 12.321L9.94998 14.146L14.2905 12.2964C14.4679 12.2241 14.666 12.2261 14.8421 12.3019C15.0181 12.3778 15.158 12.5213 15.2316 12.7017C15.3051 12.8821 15.3065 13.0848 15.2354 13.2663C15.1643 13.4477 15.0264 13.5932 14.8514 13.6715L10.1938 15.6487C10.096 15.6879 9.99137 15.7059 9.88645 15.7016C9.78152 15.6972 9.67863 15.6706 9.58428 15.6234L6.02447 13.8236L1.68197 15.6732C1.58726 15.7018 1.48951 15.7185 1.39087 15.723Z' fill='#00171F' />
              <path d='M6.04734 13.718C5.95059 13.72 5.85445 13.7019 5.76469 13.6649C5.67493 13.6279 5.5934 13.5727 5.52497 13.5027C5.45655 13.4327 5.40264 13.3492 5.36649 13.2574C5.33034 13.1655 5.31269 13.0671 5.31459 12.9681V1.46869C5.31459 1.2698 5.39179 1.07905 5.52921 0.938404C5.66662 0.797762 5.853 0.71875 6.04734 0.71875C6.24167 0.71875 6.42805 0.797762 6.56546 0.938404C6.70288 1.07905 6.78008 1.2698 6.78008 1.46869V12.9681C6.78008 13.167 6.70288 13.3577 6.56546 13.4984C6.42805 13.639 6.24167 13.718 6.04734 13.718Z' fill='#00171F' />
              <path d='M14.5571 13.718C14.4604 13.7199 14.3643 13.7017 14.2746 13.6647C14.1848 13.6277 14.1033 13.5725 14.0349 13.5025C13.9665 13.4325 13.9126 13.3491 13.8764 13.2572C13.8403 13.1654 13.8225 13.0671 13.8243 12.9681V1.46869C13.8243 1.37021 13.8433 1.27269 13.8801 1.1817C13.9169 1.09072 13.9709 1.00804 14.039 0.938404C14.107 0.868765 14.1878 0.813524 14.2767 0.775836C14.3656 0.738148 14.4609 0.71875 14.5571 0.71875C14.6533 0.71875 14.7486 0.738148 14.8375 0.775836C14.9264 0.813524 15.0072 0.868765 15.0752 0.938404C15.1433 1.00804 15.1972 1.09072 15.2341 1.1817C15.2709 1.27269 15.2898 1.37021 15.2898 1.46869V12.9681C15.2853 13.1655 15.2066 13.3535 15.0702 13.4932C14.9337 13.6328 14.75 13.7133 14.5571 13.718Z' fill='#00171F' />
              <path d='M1.41452 15.6998C1.31778 15.7018 1.22164 15.6837 1.13188 15.6467C1.04212 15.6097 0.960583 15.5545 0.892159 15.4845C0.823735 15.4145 0.769832 15.331 0.733681 15.2391C0.697531 15.1473 0.679877 15.0489 0.681779 14.9499V3.45321C0.679877 3.35419 0.697531 3.2558 0.733681 3.16393C0.769832 3.07207 0.823735 2.98862 0.892159 2.91859C0.960583 2.84855 1.04212 2.79339 1.13188 2.75639C1.22164 2.71939 1.31778 2.70132 1.41452 2.70327C1.51116 2.70151 1.60715 2.71971 1.69676 2.75679C1.78636 2.79388 1.86774 2.84907 1.93602 2.91908C2.0043 2.9891 2.05808 3.07248 2.09415 3.16426C2.13022 3.25603 2.14783 3.35431 2.14594 3.45321V14.9499C2.14138 15.1471 2.0629 15.3349 1.92674 15.4745C1.79059 15.6141 1.60719 15.6948 1.41452 15.6998Z' fill='#00171F' />
              <path d='M9.8989 15.6997C9.80215 15.7016 9.70602 15.6835 9.61625 15.6465C9.52649 15.6095 9.44496 15.5544 9.37653 15.4843C9.30811 15.4143 9.25421 15.3309 9.21806 15.239C9.18191 15.1471 9.16425 15.0487 9.16615 14.9497V3.45307C9.16615 3.25417 9.24335 3.06342 9.38077 2.92278C9.51819 2.78214 9.70456 2.70313 9.8989 2.70312C10.0932 2.70312 10.2796 2.78214 10.417 2.92278C10.5544 3.06342 10.6316 3.25417 10.6316 3.45307V14.9497C10.6316 15.1486 10.5544 15.3394 10.417 15.48C10.2796 15.6207 10.0932 15.6997 9.8989 15.6997Z' fill='#00171F' />
            </svg>
          }
        />
      </Col>
      <Col sm={8} className="mt-8">
        <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.BUS_MAXIMUM_TITLE')}</h6>
        <InputGroup className='h-50px'>
          <InputGroup.Text id='basic-addon1'>
            <img src='/assets/images/icons/capacity.svg'></img>
          </InputGroup.Text>
          <Field
            className='form-control h-50px'
            name='capacity'
            type="number"
            placeholder={t('USERS_PAGE.CONTENT_STEP.STEP3.BUS_MAXIMUM_TITLE_PLACEHOLDER')}
            value={values?.capacity}
          />
        </InputGroup>
        {errors.capacity && touched.capacity ? (
          <div className='small text-danger'>{errors.capacity}</div>
        ) : null}
      </Col>
    </div>
  )
}

export { WorkInfoTech }