import { selectLanguage } from 'app/modules/auth'
import {
  Languages,
} from 'app/utils/common/constants'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'setup/redux/Hooks'
import './style.scss'
import { useFormik } from 'formik'
import { Accordion, Offcanvas } from 'react-bootstrap'
import _ from 'lodash'
import AccordionItem from './components/AccordionItem'
import { Col, Row, Button } from 'react-bootstrap-v5'
import PackageInfo from './components/PackageInfo'
import BusesManagement from './components/BusesManagement'
import PriceManagement from './components/PriceManagement'
import AutoDiscountConfig from './components/AutoDiscountConfig'
import { ServiceManagementPackageModel } from 'app/modules/service-management/models'
import { ServiceManagementPackageSchema } from './schema'

interface IPropsCreatePackageDrawer {
  isShow: boolean,
  onHide: () => void,
  onAddPackage: (data: ServiceManagementPackageModel) => void,
  isLoading: boolean
}

const ACTIVE_KEY = {
  PACKAGE_INFO: "PACKAGE_INFO",
  BUSES_MANAGEMENT: "BUSES_MANAGEMENT",
  PRICE_MANAGEMENT: "PRICE_MANAGEMENT",
  AUTO_DISCOUNT_CONFIG: "AUTO_DISCOUNT_CONFIG"
}

const AddPackageOffcanvas = (props: IPropsCreatePackageDrawer) => {
  const { isShow, onHide, onAddPackage, isLoading } = props
  const { t } = useTranslation()
  const language = useAppSelector(selectLanguage)
  const rtlMode = language === Languages.ar

  const initialValues: ServiceManagementPackageModel = useMemo(() => ({
    "packageInfo": {
      "packageId": 0,
      "subServiceId": 0,
      "subServiceNameAr": "",
      "subServiceNameEn": "",
      "workFolowId": 0,
      "packageNameAr": "",
      "packageNameEn": "",
      "numberOfPackageWeeks": 0,
      "numberOfWeeklyVisits": 0,
      "visitDuration": 0,
      "shiftType": null,
      "numberOfWorker": 0,
      "numberOfVisits": 0,
      "rescheduleLimit": -1,
      "isActive": true,
      "isShowNumberOfWorker": false,
      "packagePrice": 0,
      "packagePriceWithVat": 0,
      "packageOriginalPrice": 0,
      "packageOriginalPriceWithVat": 0,
      "packageServicePriceSetId": 0
    },
    "buses": [],
    "packageServicePriceSetDefault": {
      "packagePrice": 0,
      "packagePriceWithVat": 0
    },
    "autoDiscount": {
      "activeAutoDiscount": false,
      "discountDays": 0,
      "discountPercent": 0,
      "arabicPushNotificationTitle": "",
      "englishPushNotificationTitle": "",
      "arabicPushNotificationDescription": "",
      "englishPushNotificationDescription": ""
    }
  }), [])
  const [currentInitialValues, setCurrentInitialValues] = useState<ServiceManagementPackageModel>(initialValues)

  const formik = useFormik({
    initialValues,
    validationSchema: ServiceManagementPackageSchema,
    onSubmit: (_values) => {
      onAddPackage(_values)
    },
  })

  const { setValues, values, submitForm, isValid, resetForm, validateForm, setFieldValue, handleBlur } = formik

  const handleChangeNumberField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, needChangeValue = true) => {
    if (!e.target.value) {
      if (needChangeValue) {
        setFieldValue(e.target.name, 0)
        return
      }
      e.target.value = "0"
      return
    }
    const regex = /^-?\d*\.?\d+$/
    if (!regex.test(e.target.value)) {
      e.target.value = isNaN(parseFloat(e.target.value)) ? "0" : e.target.value
      return
    }
    needChangeValue && setFieldValue(e.target.name, parseFloat(e.target.value))
  }

  const handleBlurNumberField: React.FocusEventHandler<HTMLInputElement> = (e) => {
    setFieldValue(e.target.name, isNaN(parseFloat(e.target.value)) ? "0" : parseFloat(e.target.value))
    handleBlur(e)
  }

  useEffect(() => {
    validateForm()
  }, [values])

  useEffect(() => {
    resetForm({ values: _.cloneDeep(currentInitialValues) })
    validateForm()
  }, [currentInitialValues])

  useEffect(() => {
    if (!isShow) {
      return
    }
    //resetValues
    const newValues = _.cloneDeep(initialValues)
    setCurrentInitialValues(_.cloneDeep(newValues))
  }, [isShow])

  return <Offcanvas backdropClassName='modal-backdrop' className={`create-package-offcanvas ${rtlMode ? "create-package-offcanvas--rtl" : ""}`} placement='top' show={isShow} onHide={onHide}>
    <Offcanvas.Header className='create-package-offcanvas--header modal-header' closeButton>
      <div className='create-package-offcanvas--header--title h3'>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.ADD_PACKAGE')}</div>
    </Offcanvas.Header>
    <Offcanvas.Body className='create-package-offcanvas--body modal-body'>
      <Accordion
        alwaysOpen
        defaultActiveKey={_.cloneDeep(Object.keys(ACTIVE_KEY))}
      >
        <AccordionItem
          id={ACTIVE_KEY.PACKAGE_INFO}
          title={"1. " + t(`SERVICE_MANAGEMENT_PAGE.PACKAGE.${ACTIVE_KEY.PACKAGE_INFO}`)}
        >
          <PackageInfo formik={formik} handleBlurNumberField={handleBlurNumberField} handleChangeNumberField={handleChangeNumberField} />
        </AccordionItem>
        <AccordionItem
          id={ACTIVE_KEY.BUSES_MANAGEMENT}
          title={"2. " + t(`SERVICE_MANAGEMENT_PAGE.PACKAGE.${ACTIVE_KEY.BUSES_MANAGEMENT}`)}
        >
          <BusesManagement formik={formik} />
        </AccordionItem>
        <AccordionItem
          id={ACTIVE_KEY.PRICE_MANAGEMENT}
          title={"3. " + t(`SERVICE_MANAGEMENT_PAGE.PACKAGE.${ACTIVE_KEY.PRICE_MANAGEMENT}`)}
        >
          <PriceManagement formik={formik} handleBlurNumberField={handleBlurNumberField} handleChangeNumberField={handleChangeNumberField} />

        </AccordionItem>
        <AccordionItem
          id={ACTIVE_KEY.AUTO_DISCOUNT_CONFIG}
          title={"4. " + t(`SERVICE_MANAGEMENT_PAGE.PACKAGE.${ACTIVE_KEY.AUTO_DISCOUNT_CONFIG}`)}
        >
          <AutoDiscountConfig formik={formik} handleBlurNumberField={handleBlurNumberField} handleChangeNumberField={handleChangeNumberField} />
        </AccordionItem>
      </Accordion>
      <Row className='m-0'>
        <Col xs={12} className='mb-4 p-0'><Button disabled={!isValid || isLoading} onClick={submitForm} variant='dark' className='w-100 create-package-offcanvas--btn-add-package fs-14'>{isLoading && (
          <div
            className='spinner-border spinner-border-sm me-2'
            role='status'
            aria-hidden='true'
          ></div>
        )}{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.ADD_PACKAGE')}</Button></Col>
        <Col xs={12} className='p-0'><Button disabled={isLoading || _.isEqual(currentInitialValues, values)} onClick={() => setValues(_.cloneDeep(currentInitialValues))} variant='outline-dark' className='w-100 create-package-offcanvas--btn-clear-all-data fs-14'>{t('SERVICE_MANAGEMENT_PAGE.PACKAGE.CLEAR_ALL_DATA')}</Button></Col>
      </Row>
    </Offcanvas.Body>
  </Offcanvas>
}
export default AddPackageOffcanvas