import './style.scss';

import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { DisputeRequestModel, TicketModel } from '../../models';

type Props = {
    className?: string,
    isShow?: boolean,
    isLoading?: boolean,
    ticketId?: string,
    onHide?: () => void,
    onSubmit?: (data: DisputeRequestModel) => void,
    data?: TicketModel | null,
}

const DisputeTicketModal: React.FC<Props> = ({ className, ticketId, isShow, isLoading, onHide, onSubmit, data }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isShow || !ticketId) {
            formik.resetForm();
            return;
        };
    }, [isShow, ticketId]);

    const DisputeSchema = Yup.object().shape({
        reason: Yup.string().required(t('DISPUTE_MODAL.DISPUTE_REASON_REQUIRED')),
    });

    const formik = useFormik({
        initialValues: {
            reason: '',
        },
        validationSchema: DisputeSchema,
        onSubmit: (values) => {
            var request: DisputeRequestModel = { id: ticketId || '', status: values.reason };
            onSubmit?.(request);
        },
    });

    const handleHide = () => {
        onHide?.();
        formik.resetForm();
    };

    return (
        <>
            <Modal show={isShow} onHide={handleHide} backdrop="static" keyboard={false}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton className='header-primary'>
                        <Modal.Title className='fw-normal'>{t('DISPUTE_MODAL.TITLE')}</Modal.Title>
                    </Modal.Header>
                    <h4 className='mt-5 ms-9'>{t('DISPUTE_MODAL.SELECT_REASON')}</h4>
                    <hr />
                    <Modal.Body className="pt-5">
                        {data?.sourceType === 1 && <>
                            <div className="form-group">
                            <span className="form-check form-check-custom form-check-solid">
                                <input name="reason" className="form-check-input" type="radio" value="TicketStatus_SPDisputed_CustomerNotReachable" id="c1" onChange={formik.handleChange} />
                                <label className="form-check-label" htmlFor="c1">{t('DISPUTE_MODAL.CUSTOMER_NOT_REACHABLE')}</label>
                            </span>
                            </div>
                            <div className="form-group mt-5">
                                <span className="form-check form-check-custom form-check-solid">
                                    <input name="reason" className="form-check-input" type="radio" value="TicketStatus_SPDisputed_OutOfScope" id="c2" onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor="c2">{t('DISPUTE_MODAL.OUT_OF_SCOPE')}</label>
                                </span>
                            </div>
                            <div className="form-group mt-5">
                                <span className="form-check form-check-custom form-check-solid">
                                    <input name="reason" className="form-check-input" type="radio" value="TicketStatus_SPDisputed_PriceDisagreement" id="c3" onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor="c3">{t('DISPUTE_MODAL.PRICE_DISAGREEMENT')}</label>
                                </span>
                            </div>
                            <div className="form-group mt-5">
                                <span className="form-check form-check-custom form-check-solid">
                                    <input name="reason" className="form-check-input" type="radio" value="TicketStatus_SPDisputed_RejectTime" id="c5" onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor="c5">{t('DISPUTE_MODAL.REJECT_TIME')}</label>
                                </span>
                            </div>
                        </>}
                        <div className={`form-group ${data?.sourceType === 1 ? "mt-5" : ''}`}>
                            <span className="form-check form-check-custom form-check-solid">
                                <input name="reason" className="form-check-input" type="radio" value="TicketStatus_SPDisputed_CustomerCanceled" id="c4" onChange={formik.handleChange} />
                                <label className="form-check-label" htmlFor="c4">{t('DISPUTE_MODAL.CUSTOMER_CANCELED')}</label>
                            </span>
                        </div>
                        {formik.errors.reason &&
                            <div className='small text-danger mt-3'>{formik.errors.reason}</div>
                        }
                    </Modal.Body>
                    <Modal.Footer className='justify-content-between modal-footer-gray'>
                        <div>
                            <Button variant="light" className="px-10 fw-normal" onClick={onHide} disabled={isLoading}>
                                {t('BACK')}
                            </Button>
                        </div>
                        <div>
                            <Button variant="danger" className="px-10 fw-normal" type='submit' disabled={isLoading || !formik.isValid}>
                                {isLoading && <span className="spinner-border w-20px h-20px me-5" role="status" aria-hidden="true"></span>}
                                <span>{t('DISPUTE_MODAL.DISPUTE')}</span>
                            </Button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    )
}

export { DisputeTicketModal }
