import {selectCurrency} from 'app/modules/auth'
import {ServiceScopeModel} from 'app/modules/auth/models/AuthModel'
import {MajorServicesAvailable, OffersAvailable, PriceDetails} from 'app/modules/tickets'
import {priceSubSubService} from 'app/modules/tickets/drawer/components/NeededService'
import {Nullable, objectWithType} from 'app/utils/extensions/ts.script'
import {Field, FieldConfig, FieldProps} from 'formik'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {components, OptionProps} from 'react-select'
import {useAppSelector} from 'setup/redux/Hooks'
import {KTSVG} from '../KTSVG'
import {Selector} from './Selector'
import './ServiceInput.scss'
import {classNameFunc} from './utils'
import {Languages} from 'app/utils/common/constants'
import { Dash, Plus } from 'react-bootstrap-icons'

const mapMajor = (
  major: MajorServicesAvailable,
  servicesScope?: ServiceScopeModel,
  language?: string
) => {
  return Object.entries(major).map((major) => {
    const _major = servicesScope?.majorServices?.find((e1) => e1.id == major[1].id)
    const type = major[1].packageWorkFlowId ? 'package_service' : 'service'
    return objectWithType(type, {
      id: major[1].id,
      parentId: -1,
      data: Nullable({...major[1], ..._major}),
      label: (language === Languages.ar ? _major?.nameAr : _major?.nameEn) || '',
      children: Object.entries(major[1].subServices).map((sub) => {
        const _sub = servicesScope?.subServices?.find((e1) => e1.id.toString() == sub[1].id)
        return objectWithType('sub', {
          id: parseInt(sub[1].id),
          parentId: major[1].id,
          data: Nullable({...sub[1], ..._sub}),
          data_main: Nullable({...major[1], ..._major}),
          label: (language === Languages.ar ? _sub?.nameAr : _sub?.nameEn) || '',
          children: Object.entries(sub[1].subSubServices).map((subsub) => {
            const _subsub = servicesScope?.subSubServices?.find(
              (e1) => e1.id.toString() == subsub[1].id
            )
            return objectWithType('sub_sub', {
              id: parseInt(subsub[1].id),
              parentId: parseInt(sub[1].id),
              major_id: major[1].id,
              data: Nullable({...subsub[1], ..._subsub}),
              data_main: Nullable({...major[1], ..._major}),
              data_sub: Nullable({...sub[1], ..._sub}) as any,
              label: (language === Languages.ar ? _subsub?.nameAr : _subsub?.nameEn) || '',
              children: [],
            })
          }),
        })
      }),
    })
  })
}

const mapOffer = (
  offer: OffersAvailable[],
  servicesScope?: ServiceScopeModel,
  language?: string
) => {
  return offer.length > 0
    ? [
        objectWithType('service_offer', {
          id: -1,
          parentId: 0,
          data: undefined,
          label: 'Offer',
          children: offer.map((e) => {
            const _offer = servicesScope?.majorServices?.find((e1) => e1.id == e.majorId)
            return objectWithType('offer', {
              id: e.offerId,
              parentId: e.majorId,
              data: Nullable({...e, ..._offer}),
              data_main: undefined as any,
              data_sub: undefined as any,
              label: (language === Languages.ar ? e?.titleAr : e?.titleEn) || '',
              children: [],
            })
          }),
        }),
      ]
    : []
}

const mapService = (
  language: string,
  s?: {major: MajorServicesAvailable; offer: OffersAvailable[]},
  servicesScope?: ServiceScopeModel
) => {
  const data =
    s === undefined
      ? []
      : [
          ...mapMajor(s.major, servicesScope, language),
          ...mapOffer(s.offer, servicesScope, language),
        ]
  return data
}

type IServiceAvailable = ReturnType<typeof mapService>
export type IMainServiceSelected = ReturnType<typeof mapMajor>[0] | ReturnType<typeof mapOffer>[0]
export type ISubServiceSelected = ReturnType<typeof mapMajor>[0]['children'][0][]
export type ISubSubServiceSelected = (ReturnType<
  typeof mapMajor
>[0]['children'][0]['children'][0] & {quantity?: number})[]
export type IOfferServiceSelected = (ReturnType<typeof mapOffer>[0]['children'][0] & {
  quantity?: number
})[]

export type IServiceSelected = IOfferServiceSelected | ISubSubServiceSelected

export interface IPropsServiceInput {
  serviceAvailable?: {
    major: MajorServicesAvailable
    offer: OffersAvailable[]
  }
  subSubPrices?: PriceDetails[]
  onChangeMainService?: (e?: IMainServiceSelected | ISubServiceSelected) => void
  placeholder?: {
    mainService?: string
    subService?: string
    subSubService?: string
    offerService?: string
    noOptionsMessage?: string
  }
  label?: {
    mainService?: string
    subService?: string
    subSubService?: string
    offerService?: string
  }
  rtl?: boolean
  language: string
  loading?: boolean
}

const OptionCheckboxComponent = (props: OptionProps<ISubSubServiceSelected[0], true>) => {
  const {selectOption, getValue, isSelected} = props
  return (
    <components.Option {...props}>
      <div className='d-flex align-items-center'>
        <input type='checkbox' checked={isSelected} />
        <span className='px-2'>{props.data.label}</span>
      </div>
    </components.Option>
  )
}

export const ServiceInput = (props: IPropsServiceInput) => {
  const {
    serviceAvailable,
    subSubPrices,
    onChangeMainService = () => {},
    placeholder = {
      mainService: 'Search your preferred main service',
      subService: 'Search your preferred sub services',
      subSubService: 'Search your preferred',
      offerService: 'Search your preferred offer',
      noOptionsMessage: 'No options',
      ...props.placeholder,
    },
    label = {
      mainService: 'Main Service',
      subService: 'Sub Service',
      subSubService: 'Subsub Service',
      offerService: 'Offer',
      ...props.label,
    },
    rtl = false,
    language,
    loading = false,
    field,
    form,
  } = props as IPropsServiceInput & FieldProps
  const servicesScope = useAppSelector((s) => s.auth.servicesScope)

  const [state, setState] = useState<{
    service?: IServiceAvailable
    mainSelected?: IMainServiceSelected
    subSelected?: ISubServiceSelected
    subSubSelected?: ISubSubServiceSelected
    offerSelected?: IOfferServiceSelected
    touched?: string
  }>({})

  useEffect(() => {
    if (form?.dirty === false) {
      setState((s) => ({...s, touched: undefined}))
    }
  }, [form?.dirty])

  useEffect(() => {
    const service = mapService(language, serviceAvailable, servicesScope)
    setState((state) => ({
      ...state,
      mainSelected: undefined,
      offerSelected: undefined,
      subSelected: undefined,
      subSubSelected: undefined,
      service: service,
    }))
  }, [serviceAvailable])

  const adjustSubsubQuantity = (
    e: IServiceAvailable[0]['children'][0]['children'][0],
    index: number,
    adjust: number
  ) => {
    const value = [...(state.subSubSelected || [])]
    value[index].quantity = (value[index].quantity || 0) + adjust
    if ((value[index].quantity || 0) <= 0) value[index].quantity = 1
    setState((state) => ({...state, subSubSelected: value}))
    form?.setFieldValue(field?.name, value)
  }

  const onBlurInput = async (type: 'service' | 'service_offer' | 'sub' | 'sub_sub') => {
    setState((s) => ({...s, touched: type}))
    form?.setFieldTouched(field.name, true)
  }

  const {t} = useTranslation()
  const currency = useAppSelector(selectCurrency)

  const touched = form?.touched[field.name]
  let _error = form?.errors[field.name]
  let [error, _params = ''] = ![null, undefined, ''].includes(_error as any)
    ? String(_error).split('-')
    : [_error, '']
  error = ![null, undefined, ''].includes(error as any)
    ? t(error as string).format(_params.split(';'))
    : error
  let isInvalid =
    (typeof touched == 'boolean'
      ? touched === true
      : Array.isArray(touched)
      ? touched.length == 0
      : touched) && error != undefined
  isInvalid = (isInvalid === true && form?.submitCount > 0) || state.touched === _params

  const displayPrice = (value: ISubSubServiceSelected[0]['data'], index: number) => {
    const selected = [...(state.subSubSelected || [])]
    const quantity = selected[index].quantity || 1
    const price =
      subSubPrices === undefined ? (
        <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
      ) : (
        priceSubSubService(subSubPrices, {id: value.id as number, quantity})
      )
    return price == undefined
      ? t('CREATE_TICKET_MODAL.UPON_CHECKUP')
      : typeof price == 'number'
      ? `${price} ${currency}`
      : price
  }

  return (
    <div className={`${isInvalid ? 'has-error' : ''}`}>
      <Selector
        values={state.mainSelected || (null as any)}
        label={label.mainService}
        inputPlaceholder={placeholder.mainService}
        noOptionsMessage={placeholder.noOptionsMessage}
        options={state.service || []}
        optionKey={(e) => e.id}
        optionLabel={(e) => e.label}
        inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/search-black.svg' />}
        onSelected={(e) => {
          onChangeMainService(e)
          form?.setFieldValue(field?.name, undefined)
          // setTimeout(() => onBlurInput('service'), 0);
          setState((state) => ({
            ...state,
            mainSelected: e,
            offerSelected: undefined,
            subSelected: undefined,
            subSubSelected: undefined,
          }))
        }}
        onBlur={() => onBlurInput('service')}
        error={error as string}
        isInvalid={isInvalid && _params === 'service'}
        loading={loading}
      />
      {state.mainSelected !== undefined &&
        (state.mainSelected.type == 'service' ? (
          <Selector
            classContainer={['mt-5']}
            values={state.subSelected || []}
            isMulti={true}
            checkboxComponent={OptionCheckboxComponent}
            label={label.subService}
            inputPlaceholder={placeholder.subService}
            noOptionsMessage={placeholder.noOptionsMessage}
            options={state.mainSelected.children}
            optionKey={(e) => e.id}
            optionLabel={(e) => e.label}
            inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/search-small.svg' />}
            onSelected={(e) => {
              const subSubSelected =
                e.length === 0
                  ? undefined
                  : state.subSubSelected?.filter(
                      (e1) => e.find((e) => e.id == e1.parentId) != undefined
                    )
              onChangeMainService && onChangeMainService(e)
              if (e.length === 0) form?.setFieldValue(field?.name, undefined)
              setState((state) => ({...state, subSelected: e, subSubSelected: subSubSelected}))
              // setTimeout(() => onBlurInput('sub'), 0);
            }}
            onBlur={() => onBlurInput('sub')}
            error={error as string}
            isInvalid={isInvalid && _params === 'sub'}
          />
        ) : (
          state.mainSelected.type === 'service_offer' && (
            <Selector
              classContainer={['mt-5']}
              values={state.offerSelected?.[0] || (null as any)}
              // isMulti={true}
              label={label.offerService}
              inputPlaceholder={placeholder.offerService}
              noOptionsMessage={placeholder.noOptionsMessage}
              options={state.mainSelected.children}
              optionKey={(e) => e.id}
              optionLabel={(e) => e.label}
              inputGroup={
                <KTSVG defaultColor={true} path='/assets/images/icons/search-small.svg' />
              }
              onSelected={(e) => {
                const value = {...e, quantity: 1}
                setState((state) => ({
                  ...state,
                  offerSelected: [value],
                  subSubSelected: undefined,
                  subSelected: undefined,
                }))
                form?.setFieldValue(field?.name, [value])
                // setTimeout(() => onBlurInput('service_offer'), 0);
              }}
              onMenuClose={() => {
                form?.setFieldValue(field?.name, state.offerSelected)
              }}
              onBlur={() => onBlurInput('service_offer')}
              error={error as string}
              isInvalid={isInvalid && _params === 'service_offer'}
            />
          )
        ))}
      {state.subSelected !== undefined && state.subSelected.length > 0 && (
        <Selector
          classContainer={['mt-5']}
          values={state.subSubSelected || []}
          isMulti={true}
          checkboxComponent={OptionCheckboxComponent}
          label={label.subSubService}
          inputPlaceholder={placeholder.subSubService}
          noOptionsMessage={placeholder.noOptionsMessage}
          isGroup={true}
          formatGroupLabel={(group) => <div className='txtTitleSelect'>{group.label}</div>}
          options={state.subSelected.map((e) => ({label: e.label, options: e.children}))}
          optionKey={(e) => e.id}
          optionLabel={(e) => e.label}
          inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/search-small.svg' />}
          onFocus={() => form?.setFieldTouched(field?.name, true)}
          onSelected={(e) => {
            const value = e.map((e1) => ({
              ...e1,
              quantity: state.subSubSelected?.find((e) => e.id == e1.id)?.quantity || 1,
            }))
            setState((state) => ({...state, subSubSelected: value}))
            // setTimeout(() => onBlurInput('sub_sub'), 0);
          }}
          onMenuClose={() => {
            form?.setFieldValue(field?.name, state.subSubSelected)
          }}
          onBlur={() => onBlurInput('sub_sub')}
          error={error as string}
          isInvalid={isInvalid && _params === 'sub_sub'}
        />
      )}
      <div
        className={classNameFunc([
          'section-selected',
          state.offerSelected != null && 'offer-selected',
          rtl && 'rtl',
        ])}
      >
        {(state.subSelected || state.offerSelected || []).map((e, index) => {
          if (e.type === 'sub') {
            const subSub =
              state.subSubSelected
                ?.map((e, index) => ({item: e, index}))
                .filter((e1) => e.id == e1.item.parentId) || []
            if (subSub.length == 0) return undefined

            return (
              <div key={index} className='sub-selected'>
                <strong className='sub-name'>{e.label}</strong>
                <div className='sub-sub-selected'>
                  {subSub.map(({item: e, index}) => (
                    <div className='item-container' key={index}>
                      <div className='selected-item'>
                        <div className='subsub-info'>
                          <div className='adjust'>
                            <button
                              type='button'
                              onClick={() => adjustSubsubQuantity(e, index, -1)}
                            >
                              <Dash size={16} />
                            </button>
                            <span className='value'>{e.quantity}</span>
                            <button type='button' onClick={() => adjustSubsubQuantity(e, index, 1)}>
                              <Plus size={16} />
                            </button>
                            <span className='' style={{paddingLeft: 5, paddingRight: 5}}>
                              {'x'}
                            </span>
                          </div>
                          <p className='subsub-name fw-bold'>{e.label}</p>
                        </div>
                        <p className='subsub-price mt-2'>{displayPrice(e.data, index)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          } else if (e.type == 'offer' && 'subTitleTextAr' in e.data) {
            return (
              <div key={index} className='item-container'>
                <div className='selected-item'>
                  <div className='item-header'>
                    <p className='offer-sub-title'>
                      {language === Languages.ar ? e.data.titleAr : e.data.titleEn}
                    </p>
                    <p className='offer-sub-title'>
                      {language === Languages.ar ? e.data.subTitleAr : e.data.subTitleEn}
                    </p>
                    <p className='offer-sub-title'>
                      {language === Languages.ar ? e.data.warrantyAr : e.data.warrantyEn}
                    </p>
                  </div>
                  <div className='item-content'>
                    <div
                      className='my-2'
                      dangerouslySetInnerHTML={{
                        __html:
                          (language === Languages.ar
                            ? e.data?.offerDescriptionAr
                            : e.data?.offerDescriptionEn) || '',
                      }}
                    ></div>
                    <div className='d-flex justify-content-between my-2'>
                      <div>{t('CREATE_TICKET_MODAL.OFFER_PERIOD')}:</div>
                      <div>
                        {moment(e.data?.expiredDate)
                          .locale(language === Languages.ar ? 'ar-SA' : 'en-US')
                          .format('MM/DD/YYYY hh:mm A')}
                      </div>
                    </div>

                    <div className='d-flex justify-content-between my-2'>
                      <div>{t('CREATE_ORDER_DRAWER.SECTION_2.OFFER_QUANTITY')}:</div>
                      <div>{e.data?.currentQuantity}</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export type IPropsFieldServiceInput = IPropsServiceInput & FieldConfig
export const FieldServiceInput = (props: IPropsFieldServiceInput) => (
  <Field component={ServiceInput} {...props} />
)
