import './style.scss'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { STEP_CREATE_USER_ARRAY, STEP_USERS, USER_TYPE } from '../../../../../../utils/common/constants'
import { UserFormModel } from '../../../../models'

type Props = {
  className?: string
  step?: number
  rtl?: boolean
  values: UserFormModel
  successStep?: number[]
}

const StepByStepHeader: React.FC<Props> = ({ className, values, step, successStep }) => {
  const { t } = useTranslation()
  const [stepList, setStepList] = useState<number[]>([1, 2, 3, 4, 5])

  const returnSuccessStep = (st: number) => {
    if (st == step) return 'active'
    const index = successStep ? successStep?.findIndex((s) => st == s) : -1
    if (index != undefined && index >= 0) return 'success'
    return ''
  }

  const checkHiddenLastStep = (st: number) => {
    if (values.userType == USER_TYPE.DRIVER && (st == STEP_USERS.OPERATION_HOURS || st == STEP_USERS.WORK_LOCATION)) return 'hidden-step'
    if (values.userType == USER_TYPE.ADMIN && (st == STEP_USERS.OPERATION_HOURS || st == STEP_USERS.WORK_LOCATION || st == STEP_USERS.WORK_INFO)) return 'hidden-step'
    if (values.userType == USER_TYPE.WORKER && (st == STEP_USERS.OPERATION_HOURS)) return 'hidden-step'
    return ''
  }

  return (
    <>
      <div className='box-step-header'>
        <div className='header-step'>
          {stepList.map((step) => (
            <div
              data-kt-stepper-element='nav'
              key={step}
              className={`mx-5 w-200px border step-item ${returnSuccessStep(
                step
              )} ${checkHiddenLastStep(step)}`}
            >
              <span className='name-step'>{t(`USERS_PAGE.STEP_UPDATE_USER.${STEP_CREATE_USER_ARRAY[step - 1]}`)}</span>
              {returnSuccessStep(step) == 'success' && (
                <div className='tick-icon'>
                  <img src='assets/images/icons/done-step.svg' />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default StepByStepHeader
