import AccordionItem from 'app/modules/tickets/drawer/components/AccordionItem'
import { DatePackageAvailable, SPAvaialble } from 'app/modules/tickets/models/OrderDrawerModel'
import { TimeSlotPackage } from 'shared/DateTimeVisitTable'
import { classNameFunc } from 'app/utils/helpers/components/Form'
import { Field } from 'formik'
import moment from 'moment'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimePickerInline } from 'shared/DateTime'
import { TSelectedPackageItem } from 'shared/DateTimeVisitTable'
import DateTimeVisitTable from 'shared/DateTimeVisitTable/DateTimeVisitTable'
import {DateTimeReschedule} from './type'
import {RescheduleTicketRequest} from 'app/modules/tickets/models/RescheduleTicket'
import {ticketApi} from 'app/modules/tickets/redux/TicketApi'
import {toast} from 'react-toastify'
import {ToastDefaultConfig} from 'setup/toast/ToastConfig'
import {clientTimeZoneOffset, DEFAULT_WORKER} from 'app/utils/common/constants'
import './DateTime.scss'
import { KTSVG } from 'app/utils/helpers'
import { useAppSelector } from 'setup/redux/Hooks'
import { selectTicketModalData } from 'app/modules/tickets/redux/TicketSlice'

const DateTimeSection = ({
  ticketId,
  number_visit,
  number_week,
  scheduledVisitDateTime,
  form,
  language,
}: DateTimeReschedule) => {
  const { t } = useTranslation()
  const { setValues, validateField, setFieldTouched, values } = form

  const oldDate = scheduledVisitDateTime && new Date(scheduledVisitDateTime)

  const touched = form?.touched['date_time']
  let _error = form?.errors['date_time']
  if (Array.isArray(_error)) {
    _error = _error.filter((e) => e !== undefined && e !== null)[0]
  }
  let [error, params = ''] = ![null, undefined, ''].includes(_error as any)
    ? String(typeof _error == 'object' ? Object.entries(_error).map((e) => e[1])[0] : _error).split(
      '-'
    )
    : [_error, '']
  error = ![null, undefined, ''].includes(error as any)
    ? t(error as string).format(params.split(';'))
    : error

  const isInvalid = touched !== false && touched !== undefined && error !== undefined

  const [cursor, setCursor] = useState<Date | undefined>()
  const [minDate, setMinDate] = useState<Date | undefined>(new Date())
  const [openToDate, setOpenToDate] = useState<Date | undefined>(new Date().addDays(1))
  const [selected, setSelected] = useState<TSelectedPackageItem[] | undefined>([])
  const [excludeDates, setExcludeDates] = useState<DatePackageAvailable[]>([])
  const [isPending, setIsPending] = useState<boolean>(false)
  const [initialListDate, setInitialListDate] = useState<DatePackageAvailable[]>([])
  const [isExistedVisit, setIsExistedVisit] = useState<boolean>(false)
  const [numberVisitExist, setNumberVisitExist] = useState<number>(1)
  const [listSPAvailable, setListSPAvailable] = useState<SPAvaialble[]>([])
  const [isUnavaiableDate, setIsUnavaiableDate] = useState<boolean>(false)

  const ticketDetail = useAppSelector(selectTicketModalData);
  const spIdOrg = ticketDetail?.spId;

  useEffect(() => {
    setFieldTouched('date_time', false)
    setSelected([])
    setCursor(undefined)
    setExcludeDates([])
    setIsExistedVisit(false)

    let rescheduleDayAvailables = values.defaultServiceProvider?.listAvailableDay || []
    let selectedWorker = values.defaultServiceProvider
    if (values.service_provider && values.service_provider !== DEFAULT_WORKER) {
      selectedWorker = values.service_provider_available.find(
        (el) => el.workerId === values.service_provider
      )
      rescheduleDayAvailables = selectedWorker?.listAvailableDay || []
      if (selectedWorker && selectedWorker.listAvailableDay) {
        setOpenToDate(new Date(selectedWorker.listAvailableDay[0].dateCheck))
        setMinDate(new Date(selectedWorker.listAvailableDay[0].dateCheck))
      }
    }


    setInitialListDate(rescheduleDayAvailables)
    let firstDate = rescheduleDayAvailables.find((e) => e.isAvailable)
    if (firstDate) {
      setOpenToDate(new Date(firstDate.dateCheck))
      setMinDate(new Date(firstDate.dateCheck))
    } else {
      setOpenToDate(new Date().addDays(1))
      setMinDate(new Date().addDays(1))
    }
    setValues((s) => ({
      ...s,
      date_time: undefined,
      newSpId: spIdOrg ?? '',
      visitReminder: selectedWorker?.visitReminder ?? '',
    }))
  }, [values.service_provider, values.defaultServiceProvider, values.loadingType])

  const getDateAvailable = async (req: RescheduleTicketRequest) => {
    try {
      let { data } = await ticketApi.getRescheduleTicket(req);

      return {
        workers: data.workers || [],
        packageShift: data.shiftType || 0,
      }
    } catch (error) {
      console.log(error)
      return
    }
  }

  const handleDateChange = async (date?: Date | null, event?: SyntheticEvent<any> | undefined) => {
    if (date === undefined || date === null) return

    if (cursor !== undefined && date.isSame(cursor)) return

    let newCursor = date
    let isExistVisit = isExistedVisit
    let numberExitVisit = numberVisitExist
    let checkAvailableDate = false

    try {
      let availableDays = initialListDate
      let packageShift = values.packageShift
      let newSp = spIdOrg ?? ''
      let visitReminderString = values.visitReminder
      let availableDate: DatePackageAvailable | undefined = availableDays.find(
        (el) => date && new Date(el.dateCheck).isSame(date) && el.isAvailable
      )

      //Choosen date is out of list date available re-call api to get new sp linked with selected worker or get new available date
      if (initialListDate.findIndex((el) => new Date(el.dateCheck).isSame(date)) === -1) {
        const params = {
          ticketId: ticketId,
          selectedDateString: moment(date).locale('en-US').format('MM/DD/YYYY'),
          shiftType: values.service_provider_filter.filterBy,
          selectWorkerId:
            values.service_provider && values.service_provider !== DEFAULT_WORKER
              ? values.service_provider
              : '',
          clientTimeOffset: -clientTimeZoneOffset,
        } as RescheduleTicketRequest
        setIsPending(true)
        const resp = await getDateAvailable(params)
        if (!resp) {
          toast.error(
            `${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`,
            ToastDefaultConfig()
          )
          setIsPending(false)
          return
        }
        packageShift = resp.packageShift

        //Base on Avaibility
        const defaultWorker = resp.workers.find((el) => el.isBaseOnAvailable)
        availableDays = defaultWorker?.listAvailableDay || []
        newSp = spIdOrg ?? ''
        visitReminderString = defaultWorker?.visitReminder ?? ''
        //Choose available date base on selected worker
        if (values.service_provider && values.service_provider !== DEFAULT_WORKER) {
          let selectWorker = resp.workers.find((e) => e.workerId === values.service_provider)
          if (selectWorker && selectWorker.listAvailableDay) {
            availableDays = selectWorker.listAvailableDay
            newSp = spIdOrg ?? ''
            visitReminderString = selectWorker?.visitReminder ?? ''
          }
        }
        availableDate = availableDays.find((el) => date && new Date(el.dateCheck).isSame(date))
        //
      }

      //Disabled date is not available
      let arrayInActiveDate = [...excludeDates]
      if (!availableDate) {
        isExistVisit = false;
        if (values.service_provider === '0') checkAvailableDate = true;
        setIsPending(false);
      }
      else {
        //Show warning same day visit
        isExistVisit = availableDate.isExistedVisit ?? false
        if (oldDate && new Date(newCursor).isSame(oldDate)) {
          if (availableDate.numberVisit && availableDate.numberVisit <= 1) isExistVisit = false
          else numberExitVisit = availableDate.numberVisit ?? 1
        }
        else {
          numberExitVisit = availableDate.numberVisit ? parseInt(`${availableDate.numberVisit + 1}`) : 2
        }
      }

      let busAvailables = availableDate?.spAvaialble || []
      //timeslot transform
      setListSPAvailable(busAvailables)

      let listSp = availableDate?.spAvaialble

      //Show deliveryTime
      if (values.isShowDeliveryTime && listSp) {
        listSp?.forEach(sp => {
          if (sp.visitReminder) {
            const { Before, After } = JSON.parse(sp.visitReminder)
            sp.listTimes = sp.listTimes?.map((el) => ({
              ...el,
              visitReminderStart: el.start - Number(Before),
              visitReminderEnd: el.start + Number(After),
            }))
          }
        });
      }

      const listTimeSlotSp = listSp?.find(x => x.spId == values.newSpId)?.listTimes;
      const timeSlot = listTimeSlotSp?.map((s, index) => ({ ...s, isSelected: false }))

      updateFormValues(
        [
          {
            date: newCursor,
            time: undefined,
            timeSlot: timeSlot,
            related: undefined,
          },
        ],
        newSp
      )
      setIsExistedVisit(isExistVisit)
      setIsUnavaiableDate(checkAvailableDate)
      setNumberVisitExist(numberExitVisit)
      setCursor(newCursor)
      setOpenToDate(newCursor)
      setSelected([
        {
          date: newCursor,
          time: undefined,
          timeSlot: timeSlot,
          related: undefined,
        },
      ])
      setExcludeDates(arrayInActiveDate)
      setIsPending(false)
    } catch (error) {
      toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig())
      setIsPending(false)
    }
  }

  const onSelectTimeSlot = (d: Date, spId: string, e: TimeSlotPackage) => {
    if (!e.isAvailable || cursor === undefined) return

    const selectedDate = [...(selected || [])]
    const find = selectedDate.find((el) => new Date(el.date).isSame(d))
    if (find == null) return
    find.timeSlot?.forEach((slot) =>
      slot.start === e.start ? (slot.isSelected = true) : (slot.isSelected = false)
    )

    if (number_visit === 1 && number_week > 1) {
      selectedDate.forEach((item) => {
        item.time = e
      })
    } else if (number_visit > 1 && number_week >= 1) {
      selectedDate.forEach((item) => {
        if (
          (item.related && item.related.isSame(new Date(d))) ||
          (!item.related && item.date.isSame(new Date(d)))
        ) {
          item.time = e
        }
      })
    } else {
      find.time = e
    }
    setSelected(selectedDate)
    updateFormValues(selected, spId)
  }

  const onClearSelected = (date: Date, time?: TimeSlotPackage) => {
    let selectedDate = [...(selected || [])].filter((e) =>
      time === undefined ? (!e.related ? !e.date.isSame(date) : !e.related?.isSame(date)) : true
    )
    if (time !== undefined) {
      if (number_visit === 1 && number_week > 1) {
        const find =
          selectedDate.filter((e) => e.date.isSame(date) || e.related?.isSame(date)) || []
        if (find) {
          find.forEach((item) =>
            item.timeSlot?.forEach((slot) =>
              slot.start === time.start ? (slot.isSelected = false) : slot.isSelected
            )
          )
        }
        selectedDate = selectedDate.map((s) => ({
          ...s,
          time: undefined,
        }))
      } else if (number_visit > 1 && number_week >= 1) {
        const find =
          selectedDate.filter((e) => e.date.isSame(date) || e.related?.isSame(date)) || []
        if (find) {
          find.forEach((item) =>
            item.timeSlot?.forEach((slot) =>
              slot.start === time.start ? (slot.isSelected = false) : slot.isSelected
            )
          )
        }
        selectedDate = selectedDate.map((s) =>
          s.date.isSame(date) || s.related?.isSame(date)
            ? {
              ...s,
              time: undefined,
            }
            : s
        )
      } else {
        const find = selectedDate.find((e) => e.date.isSame(date))
        if (find) find.time = undefined
        find?.timeSlot?.forEach((slot) =>
          slot.start === time.start ? (slot.isSelected = false) : slot.isSelected
        )
      }
    }
    setCursor(selectedDate[0]?.date)
    setSelected(selectedDate)
    updateFormValues(selectedDate)
  }

  const onSelectedSp = (spId: string) => {
    // no pre select timeslot when selected SP
    if (spId != values.newSpId) {
      let selectedDate = selected;
      if (selectedDate && selectedDate[0]) {
        const find = selectedDate[0]
        if (find) find.time = undefined
        find.timeSlot = undefined
      }
      setSelected(selectedDate)
      updateFormValues(selectedDate, spId)
    }
  }

  const updateFormValues = (select: typeof selected, newSp?: string) => {
    if (!select || (select && select.length === 0)) {
      setIsExistedVisit(false)
    }
    setValues((s) => ({
      ...s,
      date_time: select as TSelectedPackageItem[],
      ...(newSp && { newSpId: newSp })
    }))
    validateField('date_time')
  }

  const timeSlotsOption =
    cursor !== undefined
      ? selected?.find((e) => new Date(e.date).isSame(cursor as any))?.timeSlot
      : undefined

  const selectDateRequired =
    number_visit === 1
      ? t('CREATE_ORDER_DRAWER.SECTION_3.NUMBER_SELECT_1_DATE')
      : number_visit === 2
        ? t('CREATE_ORDER_DRAWER.SECTION_3.NUMBER_SELECT_2_DATE')
        : t('CREATE_ORDER_DRAWER.SECTION_3.NUMBER_SELECT_MULTI_DATE', { num: number_visit })
  return (
    <AccordionItem
      id='date_time--RS'
      title={`2. ${t('RESCHEDULE_TICKET.DATE_TIME_SECTION.TITLE')}`}
    >
      <p className='section-title'>{t('CREATE_ORDER_DRAWER.SECTION_3.SUB_TITLE')}</p>
      {!(values.service_provider === null || values.service_provider === undefined) &&
        number_visit >= 1 &&
        number_week >= 1 && <span>{selectDateRequired}</span>}
      <div className={classNameFunc(isInvalid && 'has-error')}>
        {isInvalid && <p className='text-danger invalid-feedback'>{error}</p>}
      </div>
      {isExistedVisit && !isUnavaiableDate && (
        <div dir={language === 'en' ? 'ltr' : 'rtl'} className='warning-same-day'>
          <span className='warning--icon'>
            <KTSVG defaultColor={true} path='/assets/images/icons/warning.svg' />
          </span>
          <span>
            {t('RESCHEDULE_TICKET.DATE_TIME_SECTION.WARNING_SAME_DAY', {
              number_visit_exist: numberVisitExist,
            })}
          </span>
        </div>
      )}

      <div className='date-time-picker-inline-component'>
        <Field
          name='date_time'
          component={DateTimePickerInline}
          onChange={handleDateChange}
          value={cursor}
          isLoading={values.loadingType || isPending}
          openToDate={openToDate}
          minDate={minDate}
          excludeDates={excludeDates}
          language={language}
          disableDate={!form.values.defaultServiceProvider}
        />
        <DateTimeVisitTable
          isLoading={isPending}
          isMulti={false}
          isEmpty={isUnavaiableDate}
          showWarning={false}
          dateTimeOptions={selected || []}
          onSelectTimeSlot={onSelectTimeSlot}
          onClearSelected={onClearSelected}
          onSelectedSp={onSelectedSp}
          lang={language}
          listSpAvailable={listSPAvailable}
          newSpId={values.newSpId}
        />
      </div>
    </AccordionItem>
  )
}

export default DateTimeSection
