/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Languages } from './Languages';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../setup/redux/Hooks';
import { authActions, selectLanguage, selectUser } from '../../../../app/modules/auth/redux/AuthSlice';
import { toAbsoluteUrl } from '../../../../app/utils/helpers';
import { useTranslation } from 'react-i18next';

const HeaderUserMenu: FC = () => {
  const user = useAppSelector(selectUser);
  const language = useAppSelector(selectLanguage);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(authActions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <Languages />
      <div className='separator my-2'></div>
      <div className='menu-item px-5' data-kt-menu-trigger='click'>
        <div onClick={logout} className='menu-link px-5'>
          {t('SIGN_OUT')}
        </div>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
