/* eslint-disable react/jsx-no-target-blank */
import {Row, Col} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {selectLanguage, selectUser, selectReportLink, selectMenuConfig} from 'app/modules/auth'

import {Languages, PageUrl, SideMenuStatus} from 'app/utils/common/constants'
import {useAppSelector} from 'setup/redux/Hooks'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItemWithHref} from './AsideMenuItemWithHref'

export function AsideMenuMain() {
  const {t} = useTranslation()
  const user = useAppSelector(selectUser)
  const lang = useAppSelector(selectLanguage)
  const reportLink = useAppSelector(selectReportLink)
  const menuConfig = useAppSelector(selectMenuConfig)

  return (
    <>
      <div className='menu-item mb-5'>
        <div className='menu-content'>
          <Row>
            <Col sm={4}>
              <img
                width={60}
                height={60}
                className='rounded-circle me-3'
                src={user?.companyLogo}
                alt='b8ak'
              />
            </Col>
            <Col sm={8}>
              <div className='d-flex align-items-center h-100 me-3'>
                <strong className='text-light'>
                  {lang === Languages.en ? user?.companyNameEn : user?.companyNameAr}
                </strong>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <AsideMenuItem
        title={t('ASIDE_MENU.ORDERS_MANAGEMENT')}
        to={PageUrl.TICKET.TICKET_LIST}
        icon='/assets/images/icons/dashboard.svg'
        disabled={menuConfig['ASIDE_MENU.ORDERS_MANAGEMENT'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.ORDERS_MANAGEMENT'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.ATTENDANCE_DELIVERY')}
        to={PageUrl.TICKET.ATTENDANCE_DELIVERY}
        icon='/assets/images/icons/list-checks.svg'
        disabled={menuConfig['ASIDE_MENU.ATTENDANCE_DELIVERY'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.ATTENDANCE_DELIVERY'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.SUBSCRIPTION_CONTRACTS')}
        to={PageUrl.TICKET.SUBSCRIPTION_CONTRACTS}
        icon='/assets/images/icons/list-checks.svg'
        disabled={menuConfig['ASIDE_MENU.SUBSCRIPTION_CONTRACTS'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.SUBSCRIPTION_CONTRACTS'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.SERVICES_MANAGEMENT')}
        to={PageUrl.SERVICE_MANAGEMENT.ROOT}
        icon='/assets/images/icons/services-box.svg'
        disabled={menuConfig['ASIDE_MENU.SERVICES_MANAGEMENT'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.SERVICES_MANAGEMENT'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.FIELD_TEAM_MANAGEMENT')}
        to={PageUrl.USERS.ROOT}
        icon='/assets/images/icons/users-employer.svg'
        disabled={menuConfig['ASIDE_MENU.FIELD_TEAM_MANAGEMENT'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.FIELD_TEAM_MANAGEMENT'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.USERS_MANAGEMENT')}
        to=''
        icon='/assets/images/icons/user.svg'
        disabled={menuConfig['ASIDE_MENU.USERS_MANAGEMENT'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.USERS_MANAGEMENT'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.REPORTS')}
        to={PageUrl.REPORTS.ROOT}
        icon='/assets/images/icons/column-chart.svg'
        disabled={!reportLink || menuConfig['ASIDE_MENU.REPORTS'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.REPORTS'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.GROWTH_AND_MARKETING')}
        to={PageUrl.GROWTH_AND_MARKETING.ROOT}
        icon='/assets/images/icons/trend-up.svg'
        disabled={menuConfig['ASIDE_MENU.GROWTH_AND_MARKETING'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.GROWTH_AND_MARKETING'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.AUTOMATION')}
        to=''
        icon='/assets/images/icons/setting.svg'
        disabled={menuConfig['ASIDE_MENU.AUTOMATION'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.AUTOMATION'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.LINKS_MANAGEMENT')}
        to=''
        icon='/assets/images/icons/online-booking.svg'
        disabled={menuConfig['ASIDE_MENU.LINKS_MANAGEMENT'] == SideMenuStatus.DISABLE}
        hide={menuConfig['ASIDE_MENU.LINKS_MANAGEMENT'] == SideMenuStatus.HIDE}
      />

      <AsideMenuItemWithHref
        title={t('ASIDE_MENU.HELP')}
        href='https://wa.me/00966550831611'
        icon='/assets/images/icons/phone-right.svg'
        target='_blank'
      />

      {/* <AsideMenuItemWithSub
				title={t('ASIDE_MENU.SERVICE')}
				to='/crafted/widgets'
				icon='/assets/images/icons/box.svg'
				fontIcon='bi-layers'
				hide
			>
				<AsideMenuItem to='/error/404' title={t('ASIDE_MENU.SERVICE')} hasBullet={true} />
			</AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        title={t('ASIDE_MENU.REPORT')}
        to='/error'
        fontIcon='bi-sticky'
        icon='/assets/images/icons/city.svg'
        hide
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        title={t('ASIDE_MENU.CUSTOMER_REVIEW')}
        to='/sample-page'
        icon='/assets/images/icons/message.svg'
        fontIcon='bi-layers'
        badgeCount={4}
        hide
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.SETTING')}
        to='/sample-page'
        icon='/assets/images/icons/setting.svg'
        fontIcon='bi-layers'
        hide
      />

      <AsideMenuItem
        title={t('ASIDE_MENU.SUPPORT')}
        to='/sample-page'
        icon='/assets/images/icons/phone.svg'
        fontIcon='bi-layers'
        hide
      />
       */}
    </>
  )
}
