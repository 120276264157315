import * as yup from "yup";
import locale from "yup/lib/locale";
import { Message } from "yup/lib/types";
declare module "yup" {
    interface StringSchema {
        phoneAr1(mess?: string): StringSchema;
        phoneAr(mess?: string): StringSchema;
        googleMapUrl(mess?: string): StringSchema;
    }

    interface NumberSchema {

    }

    interface ObjectSchema<TShape, TContext, TIn, TOut> {
        requiredObj(mess?: string): ObjectSchema<TShape, TContext, TIn, TOut>
    }

    interface ArraySchema<T, C, TIn, TOut> {
        requireArray(mess?: string): ArraySchema<T, C, TIn, TOut>
    }
}

declare module "yup/lib/locale" {
    interface StringLocale {
        phoneAr?: Message
    }
}
//#region string
yup.addMethod(yup.string, "phoneAr1", function (mess?: string) {
    return this.transform((value: string, input: string) => {
        return value !== undefined && value !== '' && (/00966([0-9]{9})$/gm.test(value) && value.length === 14) ? value : 'invalid';
    }).notOneOf(['invalid'], mess || 'VALIDATOR.PHONE_AR');
});
yup.addMethod(yup.string, "phoneAr", function (mess?: string) {
    return this.transform((value: string, input: string) => {
        return value !== undefined && value !== '' && (/05\d{8}/.test(value) && value.length === 10) ? value : 'invalid';
    }).notOneOf(['invalid'], mess || 'VALIDATOR.PHONE_AR');
});

yup.addMethod(yup.string, "googleMapUrl", function (mess?: string) {
    return this.transform((value: string, input: string) => {
        return (
            value !== undefined &&
            value !== '') &&
            /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value) && (
                /:\/\/goo.gl\/maps\/.*/i.test(value) ||
                /google\.com\/maps\/@\d+\.?\d+,\d+\.?\d+/i.test(value)
            )
            ? value : 'invalid';
    }).notOneOf(['invalid'], mess || 'VALIDATOR.MAP_URL');
});
//#endregion

//#region object
yup.addMethod(yup.object, "requiredObj", function (mess?: string) {
    mess = mess || 'VALIDATOR.REQUIRED'
    return this.default(undefined).nullable().notOneOf([undefined, null], mess);
});
//#endregion

//#region array
//@ts-ignore
yup.addMethod(yup.array, "requireArray", function (mess?: string) {
    mess = mess || 'VALIDATOR.REQUIRED'
    return this.default(undefined).nullable().notOneOf([undefined, null], mess).min(1, mess);
});
//#endregion
export { }