import { Response, ResponseArray } from 'setup/axios/HttpResponse';
import { serviceManagementAction } from './ServiceManagementSlice';
import { ServiceManagementApi } from './ServiceManagementApi';
import { call, takeLatest } from 'redux-saga/effects';
import { COMMON_ERROR, HTTP_RESPONSE_STATUS } from 'setup/constants/Http';
import { toast } from "react-toastify";
import { ToastDefaultConfig } from "../../../../setup/toast/ToastConfig";
import { PayloadAction } from '@reduxjs/toolkit';
import { CreatePackageForMajorServiceCentroRequestBody, CreatePackageWorkflowServiceRequestBody, GetBusInfoDetailByCompanyRequestBody, GetBusInfoDetailByCompanyResponseData, GetListPackageByMajorServiceIdRequestBody, PackageWorkflowServiceModel, ServiceManagementPackageInfoModel, UpdatePackageForMajorServiceCentroRequestBody, UpdatePackageStatusForMajorServiceCentroRequestBody, UpdatePackageWorkflowServiceRequestBody } from '../models';

function* getListPackageWorkflowService(action: PayloadAction<{ callback: (res: PackageWorkflowServiceModel[] | null, err: any) => void }>) {
  const { callback } = action.payload;
  try {
    const response: ResponseArray<PackageWorkflowServiceModel> = yield call(ServiceManagementApi.getListPackageWorkflowService);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data, null)
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* createPackageWorkflowService(action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: CreatePackageWorkflowServiceRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<boolean> = yield call(ServiceManagementApi.createPackageWorkflowService, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* updatePackageWorkflowService(action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: UpdatePackageWorkflowServiceRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<boolean> = yield call(ServiceManagementApi.updatePackageWorkflowService, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* getBusInfoDetailByCompany(action: PayloadAction<{ callback: (res: GetBusInfoDetailByCompanyResponseData | null, err: any) => void, body: GetBusInfoDetailByCompanyRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<GetBusInfoDetailByCompanyResponseData> = yield call(ServiceManagementApi.getBusInfoDetailByCompany, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* getListPackageByMajorServiceId(action: PayloadAction<{ callback: (res: ServiceManagementPackageInfoModel[] | null, err: any) => void, body: GetListPackageByMajorServiceIdRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<ServiceManagementPackageInfoModel[]> = yield call(ServiceManagementApi.getListPackageByMajorServiceId, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* createPackageForMajorServiceCentro(action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: CreatePackageForMajorServiceCentroRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<boolean> = yield call(ServiceManagementApi.createPackageForMajorServiceCentro, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* updatePackageForMajorServiceCentro(action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: UpdatePackageForMajorServiceCentroRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<boolean> = yield call(ServiceManagementApi.updatePackageForMajorServiceCentro, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}

function* updatePackageStatusForMajorServiceCentro(action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: UpdatePackageStatusForMajorServiceCentroRequestBody }>) {
  const { callback, body } = action.payload;
  try {
    const response: Response<boolean> = yield call(ServiceManagementApi.updatePackageStatusForMajorServiceCentro, body);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      callback(response.data || null, response.data ? null : (response?.message || COMMON_ERROR.pleaseTryAgain))
    } else {
      callback(null, COMMON_ERROR.pleaseTryAgain)
      toast.error(COMMON_ERROR.pleaseTryAgain, ToastDefaultConfig());
    }
  } catch (error: any) {
    callback(null, error)
  }
}


export default function* serviceManagementSaga() {
  yield takeLatest(serviceManagementAction.getListPackageWorkflowService, getListPackageWorkflowService);
  yield takeLatest(serviceManagementAction.createPackageWorkflowService, createPackageWorkflowService);
  yield takeLatest(serviceManagementAction.updatePackageWorkflowService, updatePackageWorkflowService);
  yield takeLatest(serviceManagementAction.getBusInfoDetailByCompany, getBusInfoDetailByCompany);
  yield takeLatest(serviceManagementAction.getListPackageByMajorServiceId, getListPackageByMajorServiceId);
  yield takeLatest(serviceManagementAction.createPackageForMajorServiceCentro, createPackageForMajorServiceCentro);
  yield takeLatest(serviceManagementAction.updatePackageForMajorServiceCentro, updatePackageForMajorServiceCentro);
  yield takeLatest(serviceManagementAction.updatePackageStatusForMajorServiceCentro, updatePackageStatusForMajorServiceCentro);
}