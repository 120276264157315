/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect, useMemo} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../theme/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../theme/layout/MasterInit'
import {useAppSelector} from '../../setup/redux/Hooks';
import { selectErrorDomain } from '../modules/auth'
import { isError } from 'util'
import { ErrorDomain } from '../modules/errors/components/ErrorDomain/ErrorDomain'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const isErrorDomain = useAppSelector(selectErrorDomain);
  return (
    <>
      <Switch>
        <Route path='/error/domain' component={ErrorDomain} />
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        
        {isErrorDomain && <Redirect to='/error/domain' />}
        
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/orders/orders-list' />
        )}

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
        
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
