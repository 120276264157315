export const PageUrl = {
    HOME: "/home",
    TICKET: {
        ROOT: '/orders',
        TICKET_LIST: "/orders/orders-list",
        COMMERCE_ORDER: "/orders/commerce-order",
        ATTENDANCE_DELIVERY: "/orders/attendance-delivery",
        SUBSCRIPTION_CONTRACTS: "/orders/subscription-contracts",
    },
    USERS: {
        ROOT: '/users', 
    },
    ONLINE_BOOKING: {
        ROOT: '/online-booking',
    },
    SERVICE_MANAGEMENT: {
        ROOT: '/services-management',
    },
    REPORTS: {
        ROOT: '/reports'
    },
    GROWTH_AND_MARKETING: {
        ROOT: '/growth',
        PROMO_CODE: '/growth/promo-code',
        LOYALTY: '/growth/loyalty'
    }
}