export const SHIFT_TICKET = {
    MORNING: 1,
    AFTERNOON: 2,
    NIGHT: 3,
}

export const SHIFT_CONFIG = {
    MORNING: {
        FROM: 0,
        TO: 719
    },
    AFTERNOON: {
        FROM: 720,
        TO: 959
    },
    NIGHT: {
        FROM: 960,
        TO: 1439
    },
}

export const CASE_RELOAD_FILTER_STATISTIC = {
    CANCEL: 1,
    CANCEL_REMAINING: 2,
    DISPUTE: 3,
    COMPLETE: 4,
    REASSIGN: 5,
    RESCHEDULE: 6,
    CREATE: 7,
    DISPUTE_IN_ACKNOW_MODAL: 8,
}

export const ALL_INDEX_SHIFT_TICKET = ['1', '2', '3'];

export const PAGE_SIZE_SHIFT_TICKET = 25;

export const RELOAD_NEW_TICKET_SECTION_TYPE = {
    DEFAULT: 1, 
    CURRENT: 2
};

export const PAGE_SIZE_NEW_ORDER= 4;

export const TIME_RELOAD = 1*60*1000;

export const NO_LINKED_BUS = "no_linked_bus";