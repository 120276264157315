/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import { FC } from 'react';
import { Col, Nav, Row, Tab, Button } from 'react-bootstrap-v5';

import { selectLanguage, selectServiceScope } from '../../../../app/modules/auth';
import { TicketFlagResponse, TicketModel } from '../../../../app/modules/tickets';
import { ticketActions } from '../../../../app/modules/tickets/redux/TicketSlice';
import { toAbsoluteUrl } from '../../../../app/utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../../setup/redux/Hooks';
import { Languages } from '../../../../app/utils/common/constants';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { TicketType } from 'app/utils/common/enum';

type Props = {
  data: TicketFlagResponse,
  loading?: boolean,
  onRefresh?: () => void,
}


const HeaderNotificationsMenu: React.FC<Props> = ({ data, loading, onRefresh }) => {
  const serviceScope = useAppSelector(selectServiceScope);
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleOpenViewOrder = (ticketDetail: TicketModel) => {
    if (ticketDetail.packageId) {
      dispatch(ticketActions.setOpeningViewPackageModal(ticketDetail));
    } else {
      if (ticketDetail.ticketType === TicketType.SUBSCRIPTION) {
        dispatch(ticketActions.getTicketSubscription({ticketId: ticketDetail.id}));
    }
      dispatch(ticketActions.setOpeningViewOrderModal(ticketDetail));
      dispatch(ticketActions.getTimeLineInOrderModal(ticketDetail));
      dispatch(ticketActions.getCustomerTags({customer_id: ticketDetail.customerId}));
    }
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <Tab.Container id="notification-app" defaultActiveKey="update">
        <Row className='app-notification'>
          <Col sm={12} className='rounded-top menu-tab' style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}>
            <div className='d-flex justify-content-between'>
              <h3 className='text-white fw-bold py-5 px-5'>
                {t('HEADER.MENU.NOTIFICATIONS')} <span className='fs-8 opacity-75 ps-3'>{data?.total || 0} {t('HEADER.MENU.REPORTS')}</span>
              </h3>
              <Button variant='' className='btn-sm' onClick={() => onRefresh?.()}>
                <i className="fas fa-sync text-white"></i>
              </Button>
            </div>

            <Nav variant="pills" className='ms-2'>
              {(rtlMode ? [
                // { label: t('HEADER.MENU.LOGS'), eventKey: 'log' },
                // { label: t('HEADER.MENU.ALERTS'), eventKey: 'alert' },
                { label: t('HEADER.MENU.NOTIFICATIONS'), eventKey: 'notify' },
              ] : [
                // { label: t('HEADER.MENU.LOGS'), eventKey: 'log' },
                { label: t('HEADER.MENU.NOTIFICATIONS'), eventKey: 'notify' },
                // { label: t('HEADER.MENU.ALERTS'), eventKey: 'alert' },
              ]).map((e, index) => (
                <Nav.Item key={index}>
                  <Nav.Link eventKey={e.eventKey}>{e.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col sm={12} className='p-0 bg-white rounded-bottom'>
            <Tab.Content>
              <Tab.Pane eventKey="log">

                <div className='py-10 text-center border-top'>
                  <div className='text-muted'> No message today </div>
                </div>

              </Tab.Pane>
              <Tab.Pane eventKey="update">
                {loading ?
                  <div className='py-10 text-center border-top'>
                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </div>
                  :
                  <div className='overflow-auto mh-500px'>
                    {data.data.length > 0 ?

                      data.data.map((flag, index) => {
                        let major = serviceScope?.majorServices?.find(x => x.id === flag.majorId);

                        return (
                          <div className='noti-item' key={index} onClick={() => handleOpenViewOrder(flag)}>
                            <div dangerouslySetInnerHTML={{ __html: major?.svgIcon || '' }} className="major-svg"></div>
                            <div className='flex-root d-flex justify-content-between align-items-center p-2'>
                              <div className='w-100px'>
                                <div>{flag.customerName}</div>
                                <div>{flag.customerPhoneNumber}</div>
                              </div>
                            </div>
                            <div className={'badge badge-pill py-3 min px-3 w-200px  ' + flag.flagLevel}>
                              <div className='text-truncate'>{rtlMode ? flag.flagDescriptionAr : flag.flagDescription}</div>
                            </div>
                          </div>)
                      })
                      :
                      <div className='py-10 text-center border-top'>There aren't any flags</div>
                    }
                  </div>
                }

              </Tab.Pane>
              <Tab.Pane eventKey="alert">
                <div className='py-10 text-center border-top'>
                  <div className='text-muted'> No message today </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div >
  )
}

export { HeaderNotificationsMenu }
function dispatch(arg0: { payload: undefined; type: string; }) {
  throw new Error('Function not implemented.');
}

