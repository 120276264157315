import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterTicketNode } from 'app/utils/common/models';
import { getShiftFromVisitTime } from 'app/utils/services';
import _ from 'lodash';
import moment from 'moment';
import DataTable from 'react-data-table-component';

import { RootState } from '../../../../setup';
import { ALL_INDEX_SHIFT_TICKET, CASE_RELOAD_FILTER_STATISTIC, clientTimeZoneOffset, DisputeSource, NO_LINKED_BUS, PAGE_SIZE_NEW_ORDER, RELOAD_NEW_TICKET_SECTION_TYPE, SHIFT_TICKET, TicketStatus, TicketStatusFilter } from '../../../utils/common/constants';
import {
    AcknowTicketModel,
    CommerceData,
    CommerceRequest,
    CompleteRequestModel,
    MarketModel,
    OrderTableModel,
    ReassignSuccessModel,
    RescheduleResponseSuccess,
    SearchOrderRequest,
    TicketFlagRequest,
    TicketFlagResponse,
    TicketModel,
    TimeLineModel,
    OrderStatistic,
    OrderStatisticRequest,
    TicketCardTableModel,
    TagsModel,
} from '../models';
import { NewTicketCardRequest } from '../models/NewTicketCardModel';
import { OrderDrawer } from '../models/OrderDrawerModel';
import { SpTeamModel } from '../models/SpTeamModel';
import { AttendanceDeliveryActionRequest, CentroOrderSourceModel, TicketAttendanceSheetModel, TicketAttendanceSheetRequest, TicketSubscriptionModel } from '../models/TicketAttendanceDelivery';
import { FilterDataSearchTicketIntermediate, SearchTicketDailyCalenderRequest } from '../models/TicketCalenderSection';
import { FilterStatistic, FilterStatisticRequest, StatusFilterStatistic, TicketFilter } from '../models/TicketFilter';
import StatusFilter from '../pages/ticket-list/components/FilterSection/FilterSectionItem/StatusFilter';
import { ListResponse, Response } from './../../../../setup/axios/HttpResponse';
import { CommerceModel } from './../models/Commerce';

export interface TicketState {
    // View order modal
    openingCreateTicketModal: boolean,
    openingViewTicketModal: boolean,
    openingViewPackageModal: boolean,
    openingCancelPackageModal: boolean,
    openingReschedulePackageModal: boolean,
    ticketModalData: TicketModel | null,
    CancelPackageModalData: TicketModel | null,
    ReschedulePackageModalData: TicketModel | null,

    ticketFlagData: TicketFlagResponse,
    loadingFlag: boolean,

    // Ack ticket modal
    acknowTicketData: AcknowTicketModel[],
    isShowAcknowModal: boolean,
    acknowTicketModalData: AcknowTicketModel | null,
    loadingAcknowTicket: boolean,


    // Order list page
    spTeams: SpTeamModel[],
    loadingSpTeam: boolean,

    // Commerce
    loadingCommerce: boolean,
    commerceData: CommerceData,

    // Market
    marketData: MarketModel[],
    loadingMarket: boolean,

    // Time line
    loadingTimeLine: boolean,
    timeLineData: TimeLineModel[],

    // Customer Tags
    loadingCustomerTags: boolean,
    customerTagsData: TagsModel[],

    // Order statistic
    orderStatistic: OrderStatistic | null,
    loadingOrderStatistic: boolean,

    filter: TicketFilter,
    workerCheckBoxListExpand: string[],
    allNumberOfWorkerPerTicket: number[],
    loadingAllNumberOfWorkerPerTicket: boolean,

    allFilterStatistic: FilterStatistic,
    loadingAllFilterStatistic: boolean,

    // New Order
    loadingNewTicketCard: boolean,
    isAutoRefreshNewTicketCard: boolean,
    newTicketCardData: OrderTableModel,
    // Search attendance sheet ticket
    loadingSearchDailyCalender: boolean,
    loadingSearchShiftMorning: boolean,
    loadingSearchShiftAfternoon: boolean,
    loadingSearchShiftNight: boolean,
    isAutoSearchShiftMorning: boolean,
    isAutoSearchShiftAfternoon: boolean,
    isAutoSearchShiftNight: boolean,
    isChangedFilterData: boolean,
    searchDailyCalenderTicketData: any,
    shiftMorningTicketData: TicketCardTableModel,
    shiftAfternoonTicketData: TicketCardTableModel,
    shiftNightTicketData: TicketCardTableModel,

    paramSearchIntermediateDailyCalender: FilterDataSearchTicketIntermediate,

    filterNewTicketCard: NewTicketCardRequest,

    // Loading status sheet ticket
    ticketsLoading: boolean,
    ordersLoading: boolean,
    refreshPage: number

    // AttendanceDelivery
    isLoadingAttendanceDelivery: boolean,
    attendanceDeliveryData: TicketAttendanceSheetModel[],
    paramSearchAttendanceDelivery: TicketAttendanceSheetRequest,

    isLoadingUpdateAttendanceDelivery: boolean,
    isUpdateSuccess: boolean,

    isLoadingTicketSubscription: boolean,
    ticketSubscriptionData: TicketSubscriptionModel[]
    // Centro Order Source
    isLoadingCentroOrderSource: boolean,
    centroOrderSourceData: CentroOrderSourceModel[],
}

const initialState: TicketState = {
    openingCreateTicketModal: false,
    openingViewTicketModal: false,
    openingViewPackageModal: false,
    openingReschedulePackageModal: false,
    openingCancelPackageModal: false,
    ticketModalData: null,
    CancelPackageModalData: null,
    ReschedulePackageModalData: null,
    acknowTicketData: [],

    ticketFlagData: {
        total: 0,
        data: []
    },
    loadingFlag: false,

    isShowAcknowModal: false,
    acknowTicketModalData: null,
    loadingAcknowTicket: false,

    spTeams: [],
    loadingSpTeam: false,

    loadingCommerce: false,
    commerceData: {
        data: [],
        total: 0
    },
    marketData: [],
    loadingMarket: false,
    // Time Line
    loadingTimeLine: false,
    timeLineData: [],

    // Customer tags
    loadingCustomerTags: false,
    customerTagsData: [],

    // Order statistic
    orderStatistic: null,
    loadingOrderStatistic: false,

    filter: {
        cities: [],
        numberOfWorker: [],
        shift: [],
        spTeams: [],
        status: [],
        workers: [],
        fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
        toDate: new Date(new Date().setHours(23, 59, 59)),
        paymentStatus: []
    },
    workerCheckBoxListExpand: [],
    allNumberOfWorkerPerTicket: [],
    loadingAllNumberOfWorkerPerTicket: false,

    allFilterStatistic: {
        busStatistics: [],
        cityStatistics: [],
        numberOfWorkerStatistics: [],
        shiftStatistics: [],
        statusStatistics: [],
        workerStatistics: [],
        paymentStatusStatisticDTOs: [],
    },
    loadingAllFilterStatistic: false,

    // New Order
    loadingNewTicketCard: false,
    isAutoRefreshNewTicketCard: false,
    newTicketCardData: {
        data: [],
        totalItem: 0,
    },

    // Search DailyCalender Ticket
    loadingSearchDailyCalender: false,
    loadingSearchShiftMorning: false,
    loadingSearchShiftAfternoon: false,
    loadingSearchShiftNight: false,
    isAutoSearchShiftMorning: false,
    isAutoSearchShiftAfternoon: false,
    isAutoSearchShiftNight: false,
    isChangedFilterData: false,
    searchDailyCalenderTicketData: null,
    shiftMorningTicketData: {
        data: [],
        totalItem: 0,
        totalCompleteItem: 0,
        totalInprogressItem: 0,
        totalCancelItem: 0
    },
    shiftAfternoonTicketData: {
        data: [],
        totalItem: 0,
        totalCompleteItem: 0,
        totalInprogressItem: 0,
        totalCancelItem: 0
    },
    shiftNightTicketData: {
        data: [],
        totalItem: 0,
        totalCompleteItem: 0,
        totalInprogressItem: 0,
        totalCancelItem: 0
    },
    paramSearchIntermediateDailyCalender: {
        spIds: [],
        keyword: '',
        status: [],
        workerIds: [],
        cityIds: [],
        shift: ALL_INDEX_SHIFT_TICKET,
        numberOfWorker: [],
        startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        endDate: new Date(new Date().setHours(23, 59, 59, 0)),
        pageIndexMorning: 1,
        pageIndexAfternoon: 1,
        pageIndexNight: 1,
        pageIndex: 1,
        searchByVisitDate: true,
        clientTimeZone: clientTimeZoneOffset,
        isLoadingMoreMorning: false,
        isLoadingMoreAfternoon: false,
        isLoadingMoreNight: false,
        isOrdersLoading: true,
        isTicketsLoading: false,
        isOrdersFound: false,
        paymentStatus: '',
    },
    // Loading status ticket
    ticketsLoading: false,
    ordersLoading: false,
    filterNewTicketCard: { 
        pageIndex: 1, 
        pageSize: PAGE_SIZE_NEW_ORDER, 
        startTime: moment(new Date()).locale('en-US').format('yyyy-MM-DD 00:00:00'),
        endTime: moment(new Date()).locale('en-US').format('yyyy-MM-DD 23:59:59'),
        clientTimeZone: clientTimeZoneOffset},
    refreshPage: 0,

    // AttendanceDelivery
    isLoadingAttendanceDelivery: true,
    attendanceDeliveryData: [],
    paramSearchAttendanceDelivery: {
        spIds: [],
        cityIds: [],
        clientTimeZone: clientTimeZoneOffset,
        dateSearch: new Date(),
    },

    isLoadingUpdateAttendanceDelivery: false,
    isUpdateSuccess: false,

    isLoadingTicketSubscription: false,
    ticketSubscriptionData: [],
    
    // Centro Order Source
    isLoadingCentroOrderSource: false,
    centroOrderSourceData: [],
};

const ticketSlice = createSlice({
    name: 'orderTable',
    initialState,
    reducers: {
        //#region  Order list page
        callRefreshPage(state, action: PayloadAction<{ handlerLoading?: boolean }>) {
            const { payload: { handlerLoading = false } = {} } = action || {};
            let count = state.refreshPage + 1;
            count += ((handlerLoading && count % 2 == 0) || (!handlerLoading && count % 2 == 1)) ? 1 : 0;
            state.refreshPage = count;
        },
        removeIntervalRefreshPage(state) {
            state.refreshPage = 0;
        },

        fetchOrderStatisticToday(state, action: PayloadAction<OrderStatisticRequest>) {
            state.loadingOrderStatistic = true;
        },
        fetchOrderStatisticTodaySuccess(state, action: PayloadAction<OrderStatistic>) {
            state.loadingOrderStatistic = false;
            state.orderStatistic = action.payload;
        },
        fetchOrderStatisticTodayFail(state) {
            state.loadingOrderStatistic = false;
        },
        //#endregion

        //#region New Order
        fetchNewTicketCardData(state, action: PayloadAction<{request: NewTicketCardRequest, isAutoRefresh: boolean}>) {
            state.loadingNewTicketCard = true;
            state.isAutoRefreshNewTicketCard = action.payload.isAutoRefresh || false;
        },
        fetchNewTicketCardDataSuccess(state, action: PayloadAction<OrderTableModel>) {
            state.loadingNewTicketCard = false;
            state.isAutoRefreshNewTicketCard = false;
            state.newTicketCardData.data = action.payload.data;
            state.newTicketCardData.totalItem = action.payload.totalItem;
        },
        fetchNewTicketCardFailed(state) {
            state.loadingNewTicketCard = false;
            state.isAutoRefreshNewTicketCard = false;
        },
        setFilterNewTicketCard(state, action: PayloadAction<NewTicketCardRequest>) {
            state.filterNewTicketCard = action.payload;
        },
        reloadNewTicketSection(state, action: PayloadAction<number>) {
            if (action.payload === RELOAD_NEW_TICKET_SECTION_TYPE.CURRENT) {
                state.filterNewTicketCard = { ...state.filterNewTicketCard };
            } else if (action.payload === RELOAD_NEW_TICKET_SECTION_TYPE.DEFAULT) {
                state.filterNewTicketCard = { 
                    pageIndex: 1, 
                    pageSize: PAGE_SIZE_NEW_ORDER,
                    startTime: moment(new Date()).locale('en-US').format('yyyy-MM-DD 00:00:00'),
                    endTime: moment(new Date()).locale('en-US').format('yyyy-MM-DD 23:59:59'),
                    clientTimeZone: clientTimeZoneOffset
                };
            }
        },
        //#endregion

        //#region search daily calender ticket
        searchDailyCalenderTicket(state, action: PayloadAction<{request: SearchTicketDailyCalenderRequest, isAutoRefresh: boolean}>) {
            state.loadingSearchDailyCalender = true;
            if (state.paramSearchIntermediateDailyCalender.isOrdersLoading) {
                state.ordersLoading = true;
            }
            if (state.paramSearchIntermediateDailyCalender.isTicketsLoading) {
                state.ticketsLoading = true;
            }
            switch (action.payload.request.shift) {
                case SHIFT_TICKET.MORNING:
                    state.loadingSearchShiftMorning = true;
                    state.isAutoSearchShiftMorning = action.payload.isAutoRefresh;
                    break;
                case SHIFT_TICKET.AFTERNOON:
                    state.loadingSearchShiftAfternoon = true;
                    state.isAutoSearchShiftAfternoon = action.payload.isAutoRefresh;
                    break;
                default:
                    state.loadingSearchShiftNight = true;
                    state.isAutoSearchShiftNight = action.payload.isAutoRefresh;
                    break;
            }
        },
        searchShiftMorningTicketSuccess(state, action: PayloadAction<{datas: TicketCardTableModel, 
            isAutoRefresh: boolean | null, pageIndex: number | null}>) {
            state.loadingSearchDailyCalender = false;
            state.loadingSearchShiftMorning = false;
            state.isAutoSearchShiftMorning = false;
            state.ordersLoading = false;
            state.ticketsLoading = false;
            if (action.payload.isAutoRefresh) {
                if (action.payload.pageIndex === 1) {
                    state.shiftMorningTicketData = action.payload.datas;
                } else {
                    state.shiftMorningTicketData = {
                        data: state.shiftMorningTicketData.data.concat(action.payload.datas.data),
                        totalItem: action.payload.datas.totalItem,
                        totalCompleteItem: action.payload.datas.totalCompleteItem,
                        totalInprogressItem: action.payload.datas.totalInprogressItem,
                        totalCancelItem: action.payload.datas.totalCancelItem,
                    };
                }
                return;
            }
            if (state.paramSearchIntermediateDailyCalender.isLoadingMoreMorning) {
                state.shiftMorningTicketData = {
                    data: state.shiftMorningTicketData.data.concat(action.payload.datas.data),
                    totalItem: action.payload.datas.totalItem,
                    totalCompleteItem: action.payload.datas.totalCompleteItem,
                    totalInprogressItem: action.payload.datas.totalInprogressItem,
                    totalCancelItem: action.payload.datas.totalCancelItem,
                };
                return;
            } 
            state.shiftMorningTicketData = action.payload.datas;
        },
        searchShiftAfternoonTicketSuccess(state, action: PayloadAction<{datas: TicketCardTableModel, 
            isAutoRefresh: boolean | null, pageIndex: number | null}>) {
            state.loadingSearchDailyCalender = false;
            state.loadingSearchShiftAfternoon = false;
            state.isAutoSearchShiftAfternoon = false;
            state.ordersLoading = false;
            state.ticketsLoading = false;
            if (action.payload.isAutoRefresh) {
                if (action.payload.pageIndex === 1) {
                    state.shiftAfternoonTicketData = action.payload.datas;
                } else {
                    state.shiftAfternoonTicketData = {
                        data: state.shiftAfternoonTicketData.data.concat(action.payload.datas.data),
                        totalItem: action.payload.datas.totalItem,
                        totalCompleteItem: action.payload.datas.totalCompleteItem,
                        totalInprogressItem: action.payload.datas.totalInprogressItem,
                        totalCancelItem: action.payload.datas.totalCancelItem,
                    };
                }
                return;
            }
            if (state.paramSearchIntermediateDailyCalender.isLoadingMoreAfternoon) {
                state.shiftAfternoonTicketData = {
                    data: state.shiftAfternoonTicketData.data.concat(action.payload.datas.data),
                    totalItem: action.payload.datas.totalItem,
                    totalCompleteItem: action.payload.datas.totalCompleteItem,
                    totalInprogressItem: action.payload.datas.totalInprogressItem,
                    totalCancelItem: action.payload.datas.totalCancelItem,
                };
                return;
            } 
            state.shiftAfternoonTicketData = action.payload.datas;
        },
        searchShiftNightTicketSuccess(state, action: PayloadAction<{datas: TicketCardTableModel, 
            isAutoRefresh: boolean | null, pageIndex: number | null}>) {
            state.loadingSearchDailyCalender = false;
            state.loadingSearchShiftNight = false;
            state.isAutoSearchShiftNight = false;
            state.ordersLoading = false;
            state.ticketsLoading = false;
            if (action.payload.isAutoRefresh) {
                if (action.payload.pageIndex === 1) {
                    state.shiftNightTicketData = action.payload.datas;
                } else {
                    state.shiftNightTicketData = {
                        data: state.shiftNightTicketData.data.concat(action.payload.datas.data),
                        totalItem: action.payload.datas.totalItem,
                        totalCompleteItem: action.payload.datas.totalCompleteItem,
                        totalInprogressItem: action.payload.datas.totalInprogressItem,
                        totalCancelItem: action.payload.datas.totalCancelItem,
                    };
                }
                return;
            }
            if (state.paramSearchIntermediateDailyCalender.isLoadingMoreNight) {
                state.shiftNightTicketData = {
                    data: state.shiftNightTicketData.data.concat(action.payload.datas.data),
                    totalItem: action.payload.datas.totalItem,
                    totalCompleteItem: action.payload.datas.totalCompleteItem,
                    totalInprogressItem: action.payload.datas.totalInprogressItem,
                    totalCancelItem: action.payload.datas.totalCancelItem,
                };
                return;
            } 
            state.shiftNightTicketData = action.payload.datas;
        },
        searchDailyCalenderTicketFailed(state, action: PayloadAction<SearchTicketDailyCalenderRequest>) {
            state.loadingSearchDailyCalender = false;
            state.paramSearchIntermediateDailyCalender.isOrdersLoading = false;
            state.paramSearchIntermediateDailyCalender.isTicketsLoading = false;
            switch (action.payload.shift) {
                case SHIFT_TICKET.MORNING:
                    state.loadingSearchShiftMorning = false;
                    break;
                case SHIFT_TICKET.AFTERNOON:
                    state.loadingSearchShiftAfternoon = false;
                    break;
                case SHIFT_TICKET.NIGHT:
                    state.loadingSearchShiftNight = false;
                    break;
            }
        },
        changeParamIntermediateSearchDailyCalender(state, action: PayloadAction<FilterDataSearchTicketIntermediate>) {
            state.filter.fromDate = action.payload.startDate;
            state.filter.toDate = action.payload.endDate;
            
            state.paramSearchIntermediateDailyCalender = action.payload;
        },
        //#endregion

        //#region  Create order modal
        setOpeningCreateModal(state, action: PayloadAction<boolean>) {
            state.openingCreateTicketModal = action.payload;
        },
        //#endregion

        //#region View order modal
        setCloseViewOrderModal(state) {
            state.openingViewTicketModal = false;
            state.ticketModalData = null;
        },
        setOpeningViewOrderModal(state, action: PayloadAction<TicketModel>) {
            state.openingViewTicketModal = true;
            state.ticketModalData = action.payload;
        },
        addEventTicketReadSuccess(state, action: PayloadAction<{ id: string, visitTime: Date }>) {
            // New order section
            if (!state.ticketModalData?.isRead) {
                if (state.newTicketCardData.data.findIndex(t => t.id === action.payload.id) > -1) {
                    state.filterNewTicketCard = { ...state.filterNewTicketCard };
                } else {
                    state.filterNewTicketCard = { 
                        pageIndex: 1, 
                        pageSize: PAGE_SIZE_NEW_ORDER,
                        startTime: moment(new Date()).locale('en-US').format('yyyy-MM-DD 00:00:00'),
                        endTime: moment(new Date()).locale('en-US').format('yyyy-MM-DD 23:59:59'),
                        clientTimeZone: clientTimeZoneOffset
                    };
                }
            }
            
            let shift = getShiftFromVisitTime(action.payload.visitTime);
            let existInCalendarSection = false;
            if (shift === SHIFT_TICKET.MORNING) {
                let indexInShiftMorning = state.shiftMorningTicketData.data.findIndex((data: TicketModel) => data.id === action.payload.id);
                if (indexInShiftMorning > -1 &&
                    !state.shiftMorningTicketData.data[indexInShiftMorning].isRead) {
                    existInCalendarSection = true;
                    state.shiftMorningTicketData.data[indexInShiftMorning].isRead = true;
                }
            } else if (shift === SHIFT_TICKET.AFTERNOON) {
                let indexInShiftAfternoon = state.shiftAfternoonTicketData.data.findIndex((data: TicketModel) => data.id === action.payload.id);
                if (indexInShiftAfternoon > -1 &&
                    !state.shiftAfternoonTicketData.data[indexInShiftAfternoon].isRead) {
                    existInCalendarSection = true;
                    state.shiftAfternoonTicketData.data[indexInShiftAfternoon].isRead = true;
                }
            } else if (shift === SHIFT_TICKET.NIGHT) {
                let indexInShiftNight = state.shiftNightTicketData.data.findIndex((data: TicketModel) => data.id === action.payload.id);
                if (indexInShiftNight > -1 &&
                    !state.shiftNightTicketData.data[indexInShiftNight].isRead) {
                    existInCalendarSection = true;
                    state.shiftNightTicketData.data[indexInShiftNight].isRead = true;
                }
            }
            if (existInCalendarSection) {
                // Order statistic
                let index = state.allFilterStatistic.statusStatistics.findIndex((data: StatusFilterStatistic) => data.status == TicketStatusFilter.New.value);
                if (index > -1) {
                    let statusStatisticsTmp = [...state.allFilterStatistic.statusStatistics];
                    statusStatisticsTmp[index] = {
                        status: TicketStatusFilter.New.value,
                        total: (statusStatisticsTmp[index].total > 0 ? statusStatisticsTmp[index].total - 1 : 0)
                    };
                    state.allFilterStatistic.statusStatistics = statusStatisticsTmp;
                }
            }
        },
        updateTicketDataModal(state, action: PayloadAction<TicketModel>) {
            state.ticketModalData = action.payload;
            const index = state.newTicketCardData.data.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                state.newTicketCardData.data[index].statusLabel = action.payload.statusLabel;
                state.newTicketCardData.data[index].status = action.payload.status;
                state.newTicketCardData.data[index].scheduledVisitDateTime = action.payload.scheduledVisitDateTime;
            }
            const indexTicketUpdateStatusMorning = state.shiftMorningTicketData.data.findIndex(item => item.id === action.payload.id);
            if (indexTicketUpdateStatusMorning > -1) {
                state.shiftMorningTicketData.data[indexTicketUpdateStatusMorning].status = action.payload.status;
                state.shiftMorningTicketData.data[indexTicketUpdateStatusMorning].statusLabel = action.payload.statusLabel;
                state.shiftMorningTicketData.data[indexTicketUpdateStatusMorning].scheduledVisitDateTime = action.payload.scheduledVisitDateTime;
            }
            const indexTicketUpdateStatusAfternoon = state.shiftAfternoonTicketData.data.findIndex(item => item.id === action.payload.id);
            if (indexTicketUpdateStatusAfternoon > -1) {
                state.shiftAfternoonTicketData.data[indexTicketUpdateStatusAfternoon].status = action.payload.status;
                state.shiftAfternoonTicketData.data[indexTicketUpdateStatusAfternoon].statusLabel = action.payload.statusLabel;
                state.shiftAfternoonTicketData.data[indexTicketUpdateStatusAfternoon].scheduledVisitDateTime = action.payload.scheduledVisitDateTime;
            }
            const indexTicketUpdateStatusEvening = state.shiftNightTicketData.data.findIndex(item => item.id === action.payload.id);
            if (indexTicketUpdateStatusEvening > -1) {
                state.shiftNightTicketData.data[indexTicketUpdateStatusEvening].status = action.payload.status;
                state.shiftNightTicketData.data[indexTicketUpdateStatusEvening].status = action.payload.statusLabel;
                state.shiftNightTicketData.data[indexTicketUpdateStatusEvening].scheduledVisitDateTime = action.payload.scheduledVisitDateTime;
            }
        },
        setCloseViewPackageModal(state) {
            state.openingViewPackageModal = false;
            state.ticketModalData = null;
        },
        setOpeningViewPackageModal(state, action: PayloadAction<TicketModel>) {
            state.openingViewPackageModal = true;
            state.ticketModalData = action.payload;
        },
        setHiddenViewPackageModal(state) {
            state.openingViewPackageModal = false;
        },
        setVisibleViewPackageModal(state) {
            state.openingViewPackageModal = true;
        },
        setCloseCancelPackageModal(state) {
            state.openingCancelPackageModal = false;
            state.CancelPackageModalData = null;
        },
        setOpeningCancelPackageModal(state, action: PayloadAction<TicketModel>) {
            state.openingCancelPackageModal = true;
            state.CancelPackageModalData = action.payload;
        },
        updateTicketCancel(state, action: PayloadAction<{ id: string, visitTime: Date | null }>) {
            if (state.ticketModalData) {
                state.ticketModalData.status = TicketStatus.Canceled;
                state.ticketModalData.statusLabel = TicketStatus.Canceled
            }
        },
        setCloseReschedulePackageModal(state) {
            state.openingReschedulePackageModal = false;
            state.ReschedulePackageModalData = null;
        },
        setOpeningReschedulePackageModal(state, action: PayloadAction<TicketModel>) {
            state.openingReschedulePackageModal = true;
            state.ReschedulePackageModalData = action.payload;
        },
        //#endregion

        //#region Reschedule modal
        setTicketRescheduleSuccess(state, action: PayloadAction<RescheduleResponseSuccess>) {
            // Ticket details
            if (state.ticketModalData) {
                state.ticketModalData.scheduledVisitDateTime = action.payload.visitTimeOffset;
                state.ticketModalData.scheduledVisitEndDateTime = action.payload.endVisitTimeOffset;
                state.ticketModalData.status = TicketStatus.Inprogress;
                state.ticketModalData.statusLabel = TicketStatus.Inprogress
            }
        },
        //#endregion

        //#region Dispute modal
        setDisputeTicketSuccess(state, action: PayloadAction<{ id: string, visitTime: Date | null, status: string, source: string }>) {
            switch (action.payload.source) {
                case DisputeSource.TICKET_MODAL:
                    state.openingViewTicketModal = false;
                    break;

                case DisputeSource.ACKNOW_MODAL:
                    state.loadingAcknowTicket = false;
                    state.isShowAcknowModal = false;
                    state.acknowTicketData.shift();
                    break;
            }
        },
        //#endregion

        //#region Complete modal
        setCompleteTicketSuccess(state, action: PayloadAction<{ request: CompleteRequestModel, visitTime: Date | null }>) {
            state.openingViewTicketModal = false;
        },
        //#endregion

        //#region Reassign modal
        setTicketReassignSuccess(state, action: PayloadAction<ReassignSuccessModel>) {
            state.openingViewTicketModal = false;
        },
        //#endregion

        //#region SP Team
        fetchSpTeamData(state) {
            state.loadingSpTeam = true;
        },
        fetchDataSpTeamFailed(state) {
            state.loadingSpTeam = false;
        },
        fetchDataSpTeamSuccess(state, action: PayloadAction<SpTeamModel[]>) {
            state.loadingSpTeam = false;
            state.spTeams = action.payload;
        },
        //#endregion

        //#region Acknow Ticket

        fetchDataAcknow(state) {
            state.acknowTicketData = [];
            state.isShowAcknowModal = false;
            state.loadingAcknowTicket = false;
            state.acknowTicketModalData = null;
        },
        showAcknowModal(state) {
            state.isShowAcknowModal = true;
            if (state.acknowTicketData.length > 0) {
                let ticketData = state.acknowTicketData[0] || null;
                if (ticketData) {
                    state.acknowTicketModalData = ticketData;
                    state.isShowAcknowModal = true;
                } else {
                    state.isShowAcknowModal = false;
                }
            }
        },
        setAcknowTicketData(state, action: PayloadAction<AcknowTicketModel[]>) {
            state.acknowTicketData = action.payload;
        },
        acknowTicket(state, action: PayloadAction<string>) {
            state.loadingAcknowTicket = true;
        },
        acknowTicketComplete(state) {
            state.loadingAcknowTicket = false;
            state.isShowAcknowModal = false;
            state.acknowTicketData.shift();
        },
        acknowTicketError(state) {
            state.loadingAcknowTicket = false;
            state.isShowAcknowModal = false;
            state.acknowTicketData.shift();
        },

        //#endregion

        //#region Ticket flag
        getFlag(state, action: PayloadAction<TicketFlagRequest>) {
            state.loadingFlag = true;
            state.ticketFlagData = {
                data: [],
                total: 0
            }
        },
        getFlagSuccess(state, action: PayloadAction<ListResponse<TicketModel>>) {
            state.loadingFlag = false;
            state.ticketFlagData = {
                data: action.payload.data.rows,
                total: action.payload.data.pagination.totalRows
            }
        },
        getFlagFalse(state) {
            state.loadingFlag = false;
        },
        //#endregion

        //#region Commerce
        getCommerce(state, action: PayloadAction<CommerceRequest>) {
            state.loadingCommerce = true;
        },
        getCommerceSuccess(state, action: PayloadAction<ListResponse<CommerceModel>>) {
            state.loadingCommerce = false;
            state.commerceData = {
                data: action.payload.data.rows,
                total: action.payload.data.pagination.totalRows
            }
        },
        getCommerceFalse(state) {
            state.loadingCommerce = false;
        },
        clearDataCommerce(state) {
            state.commerceData = {
                total: 0,
                data: []
            }
        },
        //#endregion

        //#region Market
        getMarketOrders(state) {
            state.marketData = [];
            state.loadingMarket = true;
        },
        getMarketOrdersSuccess(state, action: PayloadAction<MarketModel[]>) {
            state.loadingMarket = false;
            if (action.payload.length > 0) {
                state.marketData = action.payload;
            }
        },
        getMarketOrdersError(state) {
            state.loadingMarket = false;
            state.marketData = [];
        },
        clearMarketTicket(state, action: PayloadAction<string>) {
            let index = state.marketData.findIndex((data: MarketModel) => data.id === action.payload);
            if (index !== -1) {
                state.marketData.splice(index, 1);
            }
        },
        acceptedMarketTicket(state, action: PayloadAction<TicketModel>) {
            let index = state.marketData.findIndex((data: MarketModel) => data.id === action.payload.id);
            if (index !== -1) {
                state.marketData.splice(index, 1);
            }
        },
        //#endregion

        //#region Filter
        fetchAllNumberOfWorker(state) {
            state.loadingAllNumberOfWorkerPerTicket = true;
        },
        fetchAllNumberOfWorkerSuccess(state, action: PayloadAction<number[]>) {
            state.loadingAllNumberOfWorkerPerTicket = false;
            state.allNumberOfWorkerPerTicket = action.payload;
        },
        fetchAllNumberOfWorkerFail(state) {
            state.loadingAllNumberOfWorkerPerTicket = false;
        },

        fetchAllFilterStatisticData(state, action: PayloadAction<FilterStatisticRequest>) {
            state.loadingAllFilterStatistic = true;
        },
        fetchAllFilterStatisticDataSuccess(state, action: PayloadAction<FilterStatistic>) {
            state.loadingAllFilterStatistic = false;
            state.allFilterStatistic.workerStatistics = action.payload.workerStatistics;
            state.allFilterStatistic.cityStatistics = action.payload.cityStatistics;
            state.allFilterStatistic.busStatistics = action.payload.busStatistics;
            state.allFilterStatistic.numberOfWorkerStatistics = action.payload.numberOfWorkerStatistics;
            state.allFilterStatistic.shiftStatistics = action.payload.shiftStatistics;
            state.allFilterStatistic.statusStatistics = action.payload.statusStatistics;
            state.allFilterStatistic.paymentStatusStatisticDTOs = action.payload.paymentStatusStatisticDTOs;
        },
        fetchAllFilterStatisticDataFail(state) {
            state.loadingAllFilterStatistic = false;
        },

        setCityFilterTicketData(state, action: PayloadAction<{ isAdd: boolean, value: string[] }>) {
            if (action.payload.isAdd) {
                state.filter.cities = _.union(state.filter.cities, action.payload.value);
            } else {
                state.filter.cities = state.filter.cities.filter(c => !action.payload.value.includes(c));
            }
        },
        setNumberOfWorkerFilterTicketData(state, action: PayloadAction<{ isAdd: boolean, value: string[] }>) {
            if (action.payload.isAdd) {
                state.filter.numberOfWorker = _.union(state.filter.numberOfWorker, action.payload.value);
            } else {
                state.filter.numberOfWorker = state.filter.numberOfWorker.filter(c => !action.payload.value.includes(c));
            }
        },
        setShiftFilterTicketData(state, action: PayloadAction<{ isAdd: boolean, value: string[] }>) {
            if (action.payload.isAdd) {
                state.filter.shift = _.union(state.filter.shift, action.payload.value);
            } else {
                state.filter.shift = state.filter.shift.filter(c => !action.payload.value.includes(c));
            }
        },
        setPaymentStatusFilterTicketData(state, action: PayloadAction< {isAdd: boolean, value: string[]}>) {
            if (action.payload.isAdd) {
                state.filter.paymentStatus = _.union(state.filter.paymentStatus, action.payload.value);
            } else {
                state.filter.paymentStatus = state.filter.paymentStatus.filter(c => !action.payload.value.includes(c));
            }
        },
        setSPTeamFilterTicketData(state, action: PayloadAction<{ isAdd: boolean, value: string[] }>) {
            if (action.payload.isAdd) {
                state.filter.spTeams = _.union(state.filter.spTeams, action.payload.value);
            } else {
                state.filter.spTeams = state.filter.spTeams.filter(c => !action.payload.value.includes(c));
            }
        },
        setStatusFilterTicketData(state, action: PayloadAction<{ isAdd: boolean, value: string[] }>) {
            if (action.payload.isAdd) {
                state.filter.status = _.union(state.filter.status, action.payload.value);
            } else {
                state.filter.status = state.filter.status.filter(c => !action.payload.value.includes(c));
            }
        },
        setWorkerFilterTicketData(state, action: PayloadAction<{ isAdd: boolean, value: string[] }>) {
            if (action.payload.isAdd) {
                state.filter.workers = _.union(state.filter.workers, action.payload.value);
            } else {
                state.filter.workers = state.filter.workers.filter(c => !action.payload.value.includes(c));
            }
        },
        setWorkerCheckBoxListExpandData(state, action: PayloadAction<{ isAdd: boolean | undefined, value: string }>) {
            if (action.payload.isAdd) {
                let index = state.workerCheckBoxListExpand.findIndex(ws => ws === action.payload.value);
                state.workerCheckBoxListExpand = index > -1 ? [...state.workerCheckBoxListExpand] : [...state.workerCheckBoxListExpand, action.payload.value];
            } else {
                state.workerCheckBoxListExpand = state.workerCheckBoxListExpand.filter(ws => ws !== action.payload.value);
            }
        },
        setDateFilterTicketData(state, action: PayloadAction<{ fromDate?: Date, toDate?: Date }>) {
            const newFromDate = action.payload?.fromDate;
            const newToDate = action.payload?.toDate;
            state.filter.fromDate = newFromDate ? new Date(newFromDate.setHours(0, 0, 0, 0)) : state.filter.fromDate;

            if (newFromDate && (state.filter.toDate && newFromDate > state.filter.toDate || !state.filter.toDate)) {
                state.filter.toDate = new Date(newFromDate.setHours(23, 59, 59, 59));
                return;
            } 
            state.filter.toDate = newToDate ? new Date(newToDate.setHours(23, 59, 59, 59)) : state.filter.toDate;
        },
        changeFilterData(state) {
            let paramPaymentStatus = '';
            if (state.filter.paymentStatus.length === 1) {
                paramPaymentStatus = state.filter.paymentStatus[0];
            }
            const workersTemp = state.filter.workers.map(w => w.split('/')).filter(w => w.length > 1);
            const newParamIntermediate = { ...state.paramSearchIntermediateDailyCalender };
            newParamIntermediate.status = state.filter.status;
            newParamIntermediate.spIds = _.union(state.filter.spTeams.concat(workersTemp.map(w => w[0]).filter(sp => sp !== NO_LINKED_BUS)));
            newParamIntermediate.workerIds = _.union(workersTemp.map(w => w[1]));
            newParamIntermediate.cityIds = state.filter.cities;
            newParamIntermediate.shift = state.filter.shift;
            newParamIntermediate.numberOfWorker = state.filter.numberOfWorker;
            newParamIntermediate.startDate = state.filter.fromDate;
            newParamIntermediate.endDate = state.filter.toDate;
            newParamIntermediate.isLoadingMoreAfternoon = false;
            newParamIntermediate.isLoadingMoreMorning = false;
            newParamIntermediate.isLoadingMoreNight = false;
            newParamIntermediate.pageIndexMorning = 1;
            newParamIntermediate.pageIndexAfternoon = 1;
            newParamIntermediate.pageIndexNight = 1;
            newParamIntermediate.isTicketsLoading = false;
            newParamIntermediate.isOrdersLoading = false;
            newParamIntermediate.isOrdersFound = true;
            newParamIntermediate.paymentStatus = paramPaymentStatus;

            state.paramSearchIntermediateDailyCalender = newParamIntermediate;
            state.isChangedFilterData = true;
        },
        clearFilterData(state) {
            state.filter = {
                cities: [],
                numberOfWorker: [],
                shift: [],
                spTeams: [],
                status: [],
                workers: [],
                fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
                toDate: new Date(new Date().setHours(23, 59, 59)),
                paymentStatus: [],
            }

            const newParamIntermediate = { ...state.paramSearchIntermediateDailyCalender };
            newParamIntermediate.status = [];
            newParamIntermediate.spIds = [];
            newParamIntermediate.workerIds = [];
            newParamIntermediate.cityIds = [];
            newParamIntermediate.shift = ALL_INDEX_SHIFT_TICKET;
            newParamIntermediate.numberOfWorker = [];
            newParamIntermediate.startDate = new Date(new Date().setHours(0, 0, 0, 0));
            newParamIntermediate.endDate = new Date(new Date().setHours(23, 59, 59, 0));
            newParamIntermediate.paymentStatus = ''
            
            state.isChangedFilterData = false;
            state.paramSearchIntermediateDailyCalender = newParamIntermediate;
        },
        setIsLoadingMore(state, action: PayloadAction<{shift: number, isLoading: boolean}>) {
            switch (action.payload.shift) {
                case SHIFT_TICKET.MORNING:
                    state.paramSearchIntermediateDailyCalender.isLoadingMoreMorning = action.payload.isLoading;
                    break;
                case SHIFT_TICKET.AFTERNOON:
                    state.paramSearchIntermediateDailyCalender.isLoadingMoreAfternoon = action.payload.isLoading;
                    break;
                default:
                    state.paramSearchIntermediateDailyCalender.isLoadingMoreNight = action.payload.isLoading;
                    break;
            }
        },
        //#endregion

        //#region TimeLine
        getTimeLineInOrderModal(state, action: PayloadAction<TicketModel>) {
            state.loadingTimeLine = true;
        },
        getTimeLineInOrderModalSuccess(state, action: PayloadAction<TimeLineModel[]>) {
            state.loadingTimeLine = false;
            if (action.payload.length > 0) {
                action.payload.map((item: TimeLineModel) => {
                    if (item.event === 'Ticket_Canceled with refund') {
                        item.event = 'Ticket_Canceled_With_Refund';
                    }
                })
            }
            state.timeLineData = action.payload;
        },
        getTimeLineInOrderModalFail(state) {
            state.loadingTimeLine = false;
        },
        //#endregion

        //#region Customer tags
        getCustomerTags(state, action: PayloadAction<{customer_id: string}>) {
            state.loadingCustomerTags = true;
        },
        getCustomerTagsSuccess(state, action: PayloadAction<TagsModel[]>) {
            state.loadingCustomerTags = false;
            state.customerTagsData = action.payload;
        },
        getCustomerTagsFail(state) {
            state.loadingCustomerTags = false;
        },
        //#endregion

        // #region Attendance Delivery
        getAttendanceDelivery(state, action: PayloadAction<TicketAttendanceSheetRequest>) {
            state.isLoadingAttendanceDelivery = true;
        },
        getAttendanceDeliverySuccess(state, action: PayloadAction<TicketAttendanceSheetModel[]>) {
            state.isLoadingAttendanceDelivery = false;
            state.attendanceDeliveryData = action.payload;
        },
        getAttendanceDeliveryFail(state) {
            state.isLoadingAttendanceDelivery = false;
        },
        changeParamSearchAttendanceDelivery(state, action: PayloadAction<TicketAttendanceSheetRequest>) {
            state.paramSearchAttendanceDelivery = action.payload
        },
        updateStatusTicketSubcription(state, action: PayloadAction<AttendanceDeliveryActionRequest>) {
            state.isLoadingUpdateAttendanceDelivery = true;
        },
        updateStatusTicketSubscriptionSuccess(state, action: PayloadAction<AttendanceDeliveryActionRequest>) {
            state.isLoadingUpdateAttendanceDelivery = false;
            const indexTicketUpdateStatus = state.newTicketCardData.data.findIndex(item => item.id === action.payload.ticketId);
            if (indexTicketUpdateStatus > -1) {
                state.newTicketCardData.data[indexTicketUpdateStatus].status = action.payload.status;
            }
            const indexTicketUpdateStatusMorning = state.shiftMorningTicketData.data.findIndex(item => item.id === action.payload.ticketId);
            if (indexTicketUpdateStatusMorning > -1) {
                state.shiftMorningTicketData.data[indexTicketUpdateStatusMorning].status = action.payload.status
            }
            const indexTicketUpdateStatusAfternoon = state.shiftAfternoonTicketData.data.findIndex(item => item.id === action.payload.ticketId);
            if (indexTicketUpdateStatusMorning > -1) {
                state.shiftAfternoonTicketData.data[indexTicketUpdateStatusAfternoon].status = action.payload.status
            }
            const indexTicketUpdateStatusEvening = state.shiftNightTicketData.data.findIndex(item => item.id === action.payload.ticketId);
            if (indexTicketUpdateStatusMorning > -1) {
                state.shiftNightTicketData.data[indexTicketUpdateStatusEvening].status = action.payload.status
            }
        },
        updateStatusTicketSubscriptionFail(state) {
            state.isLoadingUpdateAttendanceDelivery = false;
        },
        getTicketSubscription(state, action: PayloadAction<{ticketId: string}>) {
            state.isLoadingTicketSubscription = true;
        },
        getTicketSubscriptionSuccess(state, action: PayloadAction<TicketSubscriptionModel[]>) {
            state.isLoadingTicketSubscription = false;
            state.ticketSubscriptionData = action.payload;
        },
        getTicketSubscriptionFail(state) {
            state.isLoadingTicketSubscription = false;
        },
        //#endregion

        //#region Centro Order Source
        getCentroOrderSource(state, action: PayloadAction<{id: number | string}>) {
            state.isLoadingCentroOrderSource = true;
        },
        getCentroOrderSourceSuccess(state, action: PayloadAction<CentroOrderSourceModel[]>) {
            state.isLoadingCentroOrderSource = false;
            state.centroOrderSourceData = action.payload;
        },
        getCentroOrderSourceFail(state) {
            state.isLoadingCentroOrderSource = false;
        },
        //#endregion

        //#region Drawer


        //#endregion

        reloadOrderStatistic(state, action: PayloadAction<{ caseUpdate: number, timeRange: FilterStatisticRequest }>) {
        },
    },
});

// Actions
export const ticketActions = ticketSlice.actions;

// Selectors
//#region Ticket
export const selectTicketsIsShowAcknowModal = (state: RootState) => state.ticket.isShowAcknowModal;
export const selectTicketsAcknowTicketData = (state: RootState) => state.ticket.acknowTicketData;
export const selectTicketsAcknowTicketModalData = (state: RootState) => state.ticket.acknowTicketModalData;
export const selectTicketsLoadingAcknow = (state: RootState) => state.ticket.loadingAcknowTicket;
export const selectTicketTimeLineData = (state: RootState) => state.ticket.timeLineData;
export const selectLoadingTimeLineTicket = (state: RootState) => state.ticket.loadingTimeLine;
//#endregion

//#region Modal
export const selectTicketOpeningCreateModal = (state: RootState) => state.ticket.openingCreateTicketModal;
export const selectTicketOpeningViewModal = (state: RootState) => state.ticket.openingViewTicketModal;
export const selectTicketOpeningCancelModal = (state: RootState) => state.ticket.openingCancelPackageModal;
export const selectTicketOpeningRescheduleModal = (state: RootState) => state.ticket.openingReschedulePackageModal;
export const selectTicketOpeningPackageModal = (state: RootState) => state.ticket.openingViewPackageModal;
export const selectTicketModalData = (state: RootState) => state.ticket.ticketModalData;
export const selectTicketSpTeam = (state: RootState) => state.ticket.spTeams;
export const selectTicketLoadingSpTeam = (state: RootState) => state.ticket.loadingSpTeam;
export const selectLoadingSearchDailyCalender = (state: RootState) => state.ticket.loadingSearchDailyCalender;
export const selectLoadingSearchShiftMorning = (state: RootState) => state.ticket.loadingSearchShiftMorning;
export const selectLoadingSearchShiftAfternoon = (state: RootState) => state.ticket.loadingSearchShiftAfternoon;
export const selectLoadingSearchShiftNight = (state: RootState) => state.ticket.loadingSearchShiftNight;
export const selectIsAutoSearchShiftMorning = (state: RootState) => state.ticket.isAutoSearchShiftMorning;
export const selectIsAutoSearchShiftAfternoon = (state: RootState) => state.ticket.isAutoSearchShiftAfternoon;
export const selectIsAutoSearchShiftNight = (state: RootState) => state.ticket.isAutoSearchShiftNight;
export const selectIsChangedFilterData = (state: RootState) => state.ticket.isChangedFilterData;
export const selectCustomerTagsData = (state: RootState) => state.ticket.customerTagsData;

export const selectParamIntermediateSearch = (state: RootState) => state.ticket.paramSearchIntermediateDailyCalender;

export const selectTicketSubscriptionData = (state: RootState) => state.ticket.ticketSubscriptionData;
//#endregion

//#region Flag
export const selectTicketLoadingFlag = (state: RootState) => state.ticket.loadingFlag;
export const selectTicketFlagData = (state: RootState) => state.ticket.ticketFlagData;
//#endregion

//#region Commerce
export const selectTicketLoadingCommerce = (state: RootState) => state.ticket.loadingCommerce;
export const selectTicketCommerceData = (state: RootState) => state.ticket.commerceData;
//#endregion

//#region Market
export const selectTicketMarketData = (state: RootState) => state.ticket.marketData;
export const selectTicketLoadingMarket = (state: RootState) => state.ticket.loadingMarket;
//#endregion

//#region Order statistic today
export const selectOrderStatisticTodayData = (state: RootState) => state.ticket.orderStatistic;
export const selectOrderStatisticTodayLoading = (state: RootState) => state.ticket.loadingOrderStatistic;
//#endregion

//#region Filter
export const selectAllNumberOfWorkerPerTicket = (state: RootState) => state.ticket.allNumberOfWorkerPerTicket;
export const selectLoadingAllNumberOfWorkerPerTicket = (state: RootState) => state.ticket.loadingAllNumberOfWorkerPerTicket;
export const selectCityFilterTicketData = (state: RootState) => state.ticket.filter.cities;
export const selectNumberOfWorkerFilter = (state: RootState) => state.ticket.filter.numberOfWorker;
export const selectShiftfilter = (state: RootState) => state.ticket.filter.shift;
export const selectSPTeamFilter = (state: RootState) => state.ticket.filter.spTeams;
export const selectStatusFilter = (state: RootState) => state.ticket.filter.status;
export const selectWorkerFilter = (state: RootState) => state.ticket.filter.workers;
export const selectDateFilter = (state: RootState) => state.ticket.filter;
export const selectPaymentStatusFilter = (state: RootState) => state.ticket.filter.paymentStatus;
export const selectCitiesStatistic = (state: RootState) => state.ticket.allFilterStatistic.cityStatistics;
export const selectBusStatistic = (state: RootState) => state.ticket.allFilterStatistic.busStatistics;
export const selectWorkersStatistic = (state: RootState) => state.ticket.allFilterStatistic.workerStatistics;
export const selectNumberOfWorkerStatistic = (state: RootState) => state.ticket.allFilterStatistic.numberOfWorkerStatistics;
export const selectStatusStatistic = (state: RootState) => state.ticket.allFilterStatistic.statusStatistics;
export const selectShiftStatistic = (state: RootState) => state.ticket.allFilterStatistic.shiftStatistics;
export const selectPaymentStatusStatistic = (state: RootState) => state.ticket.allFilterStatistic.paymentStatusStatisticDTOs;

export const selectWorkerCheckBoxListExpand = (state: RootState) => state.ticket.workerCheckBoxListExpand;
//#endregion

//#region New Order
export const selectNewTicketCardData = (state: RootState) => state.ticket.newTicketCardData;
export const selectLoadingNewTicketCardData = (state: RootState) => state.ticket.loadingNewTicketCard;
export const selectIsAutoRefreshNewTicketCard = (state: RootState) => state.ticket.isAutoRefreshNewTicketCard;
export const selectFilterNewTicketCard = (state: RootState) => state.ticket.filterNewTicketCard;
//#endregion

//#region shift ticket
export const selectShiftMorningTicketData = (state: RootState) => state.ticket.shiftMorningTicketData;
export const selectShiftAfternoonTicketData = (state: RootState) => state.ticket.shiftAfternoonTicketData;
export const selectShiftNightTicketData = (state: RootState) => state.ticket.shiftNightTicketData;
export const selectOrdersLoading = (state: RootState) => state.ticket.ordersLoading;
export const selectTicketsLoading = (state: RootState) => state.ticket.ticketsLoading;

export const selectRefreshPage = (state: RootState) => state.ticket.refreshPage;

// Attendance Delivery
export const selectAttendanceDeliveryData = (state: RootState) => state.ticket.attendanceDeliveryData;
export const selectParamSearchAttendanceDelivery = (state: RootState) => state.ticket.paramSearchAttendanceDelivery;

// Centro Order Source
export const selectCentroOrderSourceData = (state: RootState) => state.ticket.centroOrderSourceData;
// Filter TicketData

// Reducer
const ticketReducer = ticketSlice.reducer;
export default ticketReducer;