import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AccountStatusModel, MonthlyReportModel, OrderStatisticModel } from '../models';
import { homeActions } from './HomeSlice';
import { accountStatus, monthlyStatistic, orderStatistic, orderCustomer } from './HomeApi';
import { Response } from '../../../../setup/axios/HttpResponse';
import { toast } from 'react-toastify';
import { ToastDefaultConfig } from '../../../../setup/toast/ToastConfig';

function* fetchAccountStatus() {
    const { data }: Response<AccountStatusModel> = yield call(accountStatus.getAccountStatus);
    yield put(homeActions.setAccountStatus(data));
}

function* fetchMonthlyStatistic() {
    const { data }: Response<MonthlyReportModel> = yield call(monthlyStatistic.getMonthlyStatistic);
    yield put(homeActions.setMonthlyReport(data));
}

function* fetchOrderStatistic() {
    const { data }: Response<OrderStatisticModel> = yield call(orderStatistic.getOrderStatistic);
    yield put(homeActions.setOrderStatistic(data));
}

function* fetchOrderCustomer() {
    const { data }: Response<OrderStatisticModel> = yield call(orderCustomer.getOrderCustomer);
    yield put(homeActions.setOrderCustomer(data));
}

function* fetchHomeData() {
    try {
        yield all([
            call(fetchAccountStatus),
            call(fetchMonthlyStatistic),
            call(fetchOrderStatistic),
            call(fetchOrderCustomer)
        ]);
    } catch (error: any) {
        toast.error(error.toString(), ToastDefaultConfig());
        yield put(homeActions.fetchDataFailed());
    }
}

export default function* homeSaga() {
    yield takeLatest(homeActions.fetchData.type, fetchHomeData);
}