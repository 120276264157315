import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Completed: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='m-20 mb-5 text-center w-100'>
      <img src="/assets/images/icons/success-employer.svg" />
      <h3 className='mt-10'>{t('USERS_PAGE.UPDATE_USER.UPDATE_SUPERVISOR_SUCCESS')}</h3>
    </div>

  )
}

export { Completed }
