import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap-v5'
import { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks'
import { t } from 'i18next'
import { KTSVG, handleWheelInputNumber } from 'app/utils/helpers'
import { PromoCodeModel, PROMO_CODE_TYPE, PROMO_CODE_TYPE_SELECT } from '../../models/PromoCode'
import './style.scss'
import { growthAndMarketingAction, selectEditPromoCodeData } from '../../redux/GrowthAndMarketingSlice'
import { selectLanguage } from 'app/modules/auth/redux/AuthSlice'
import { Languages } from 'app/utils/common/constants'

interface Props {
  isShow: boolean
  isEditMode: boolean
  onHide: () => void
}
const EditPromoCodeModal = (props: Props) => {
  const {isShow, isEditMode, onHide} = props
  const dispatch = useAppDispatch()
  const promoCodeData = useAppSelector(selectEditPromoCodeData)
  const language = useAppSelector(selectLanguage)
  const rtl = language === Languages.ar

  const onSubmitPromoCode = () => {
    submitForm()
  }

  const changePromoCodeType = (item: { title?: string; value: any }) => {
    if (item.value == PROMO_CODE_TYPE.FIXED) {
      setValues({
        ...values,
        'promoCodeType': item.value,
        'isApplyMaxDiscountAmount': false,
        'maxDiscountAmount': '',
      })
    } else {
      setFieldValue('promoCodeType', item.value)
    }
  }

  const changeApplyMaxDiscountAmount = (item: boolean) => {
    if (item == true) {
      setValues({
        ...values,
        'isApplyMaxDiscountAmount': true,
      })
    } else {
      setValues({
        ...values,
        'isApplyMaxDiscountAmount': false,
        'maxDiscountAmount': '',
      })
    }
  }

  const initialValues: PromoCodeModel = useMemo(() => ({
    id: 0,
    promoCode: '',
    promoCodeType: PROMO_CODE_TYPE.PERCENT,
    discountAmount: '',
    maxDiscountAmount: '',
    isApplyMaxDiscountAmount: true,
    isActive: true,
    cityIds: '1',
    packageIds: '1',
  }), [])

  const editSchema = Yup.object({
    promoCode: Yup.string().required('required'),
    discountAmount: Yup.number().moreThan(0).required('required'),
    maxDiscountAmount: Yup.number().moreThan(0).nullable(true)
    // maxDiscountAmount: Yup.number().when(['promoCodeType', 'isApplyMaxDiscountAmount'], {
    //   is: (promoCodeType: number, isApplyMaxDiscountAmount: boolean) => promoCodeType === PROMO_CODE_TYPE.PERCENT && isApplyMaxDiscountAmount === true,
    //   then: Yup.number().moreThan(0).required('required')
    // })
    // .when(['promoCodeType', 'isApplyMaxDiscountAmount'], {
    //   is: (promoCodeType: number, isApplyMaxDiscountAmount: boolean) => promoCodeType === PROMO_CODE_TYPE.PERCENT && isApplyMaxDiscountAmount === false,
    //   then: Yup.number().moreThan(0).nullable(true)
    // }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: editSchema,
    enableReinitialize: true,
    onSubmit: (_values, formikHelpers) => {
      if (isEditMode) {
        dispatch(growthAndMarketingAction.updatePromoCodeData(_values))
      } else {
        dispatch(growthAndMarketingAction.createPromoCodeData(_values))
      }
      formikHelpers.resetForm();
    },
  })

  const {handleChange, handleBlur, handleSubmit, setFieldValue, values, isValid, 
    resetForm, setValues, submitForm, validateForm, errors} = formik

  useEffect(() => {
    setValues(promoCodeData || initialValues)
  }, [promoCodeData])

  return (
    <Modal
      show={isShow}
      onHide={() => {resetForm(); onHide();}}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
      className={`modal-edit-promocode ${rtl ? 'rtl' : ''}`}
    >
      <Modal.Header closeButton className='header-primary'>
        {promoCodeData ? 
          <label className='fw-18px '>
            {t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.EDIT_TITLE')}  
          </label>
          :
          <label className='fw-18px '>
            {t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.TITLE')}  
          </label>
        }
        
        {/* <Modal.Title className='fw-18px'>
          {t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.TITLE')}
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Row as={'form'} onSubmit={handleSubmit}>
          <Col xs={12} className='mb-20px'>
            <InputGroup className='mb-3'>
              <Form.Label className='w-100 fw-17px'>{t('GROWTH_AND_MARKETING_PAGE.PROMO_CODE')}</Form.Label>
              <InputGroup.Text>
                <KTSVG defaultColor={true} path='/assets/images/icons/tag.svg' />
              </InputGroup.Text>
              <Form.Control
                required
                type='text'
                name='promoCode'
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label='Promo Code'
                placeholder={t('GROWTH_AND_MARKETING_PAGE.PROMO_CODE') + ' * '}
                className='h-50px'
                value={values.promoCode}
              />
            </InputGroup>
          </Col>
          <Col xs={12} className=''>
            {PROMO_CODE_TYPE_SELECT.map((item: {title: string; value: number}) => (
              <Form.Check key={item.value}
                inline
                name='promoCodeType'
                onBlur={handleBlur}
                className='col-md-5 promo-code-type-check fw-17px mb-20px'
                type='radio'
                onChange={() => {
                  changePromoCodeType(item)
                }}
                value={item.value}
                checked={item.value == values.promoCodeType}
                id={`percent-type-${item.title}`}
                label={t(`GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.${item.title}`)}
              />
            ))}
          </Col>
          <Col xs={12} className='mb-20px'>
            <InputGroup className='mb-3'>
              <Form.Label className='w-100 fw-17px'>{t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.DISCOUNT_AMOUNT')}</Form.Label>
              <InputGroup.Text>
                {values.promoCodeType === PROMO_CODE_TYPE.FIXED ? (
                  <span className=' fw-17px'>SR</span>
                ) : (
                  <KTSVG defaultColor={true} path='/assets/images/icons/percent.svg' />
                )}
              </InputGroup.Text>
              <Form.Control
                required
                type='number'
                name='discountAmount'
                onChange={handleChange}
                onBlur={handleBlur}
                onWheel={handleWheelInputNumber}
                aria-label='Discount Amount'
                placeholder={t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.DISCOUNT_AMOUNT') + ' * '}
                className='h-50px'
                value={values.discountAmount}
                min={0}
              />
            </InputGroup>
          </Col>
          <Col xs={12} className='mb-20px'>
            <Row>
              <Col xs={12} className='d-flex justify-content-space-between'>
                <Form.Label
                  className={`fw-17px ${
                    (values.promoCodeType === PROMO_CODE_TYPE.FIXED  || 
                    (values.promoCodeType === PROMO_CODE_TYPE.PERCENT && values.isApplyMaxDiscountAmount === false))
                    ? 'opacity-50' : ''
                  }`}
                >
                  {t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.MAXIMUM_DISCOUNT_AMOUNT')}
                </Form.Label>
                <Form.Check
                  disabled={values.promoCodeType === PROMO_CODE_TYPE.FIXED}
                  checked={values.isApplyMaxDiscountAmount}
                  name='isApplyMaxDiscountAmount'
                  onBlur={handleBlur}
                  onChange={() => changeApplyMaxDiscountAmount(!values.isApplyMaxDiscountAmount)}
                  className='promocode-switcher custom-promocode-switcher'
                  type='switch'
                  aria-disabled={false}
                />
              </Col>
            </Row>
            <InputGroup className='mb-3 input-max-discount-amount'>
              <InputGroup.Text>
                <KTSVG className={(values.promoCodeType === PROMO_CODE_TYPE.FIXED  || 
                  (values.promoCodeType === PROMO_CODE_TYPE.PERCENT && values.isApplyMaxDiscountAmount === false)) 
                  ? 'svg-disabled' : ''} defaultColor={true} path='/assets/images/icons/cash.svg' />
              </InputGroup.Text>
              <Form.Control
                disabled={values.promoCodeType === PROMO_CODE_TYPE.FIXED || 
                  (values.promoCodeType === PROMO_CODE_TYPE.PERCENT && values.isApplyMaxDiscountAmount === false)}
                type='number'
                name='maxDiscountAmount'
                onChange={handleChange}
                onBlur={handleBlur}
                onWheel={handleWheelInputNumber}
                aria-label='Maximum Discount Amount'
                placeholder={t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.MAXIMUM_DISCOUNT_AMOUNT')}
                className='h-50px'
                value={values.maxDiscountAmount}
                min={0}
              />
            </InputGroup>
          </Col>
          <Col xs={12} className='mb-20px'>
            <InputGroup className='mb-3'>
              <Form.Label className='w-100 fw-17px'>{t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.ASSIGNED_CITIES')}</Form.Label>
              <InputGroup.Text>
                <KTSVG defaultColor={true} path='/assets/images/icons/map-pin.svg' />
              </InputGroup.Text>
              <Form.Select value={values.cityIds}
                required
                onBlur={handleBlur}
                aria-label='Assigned Cities'
                className='h-50px'
                onChange={(event: any) => {setFieldValue('cityIds', event.target.value)}}
              >
                <option value={"1"}>{t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.ALL')}</option>
                <option value={"2"}>Damman</option>
                <option value={"3"}>Riyadh</option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={12} className='mb-20px'>
            <InputGroup className='mb-3'>
              <Form.Label className='w-100 fw-17px'>{t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.ASSIGNED_PACKAGES')}</Form.Label>
              <InputGroup.Text>
                <KTSVG defaultColor={true} path='/assets/images/icons/box2.svg' />
              </InputGroup.Text>
              <Form.Select value={values.packageIds}
                required
                onBlur={handleBlur}
                aria-label='Assigned Packages'
                className='h-50px'
                onChange={(event: any) => {setFieldValue('packageIds', event.target.value)}}
              >
                <option value={"1"}>{t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.ALL')}</option>
                <option value={"2"}>Package 1</option>
                <option value={"3"}>Package 2</option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Form.Check
              disabled={false}
              checked={values.isActive}
              name='isActive'
              onBlur={handleBlur}
              onChange={() => setFieldValue('isActive', !values.isActive)}
              className='promocode-switcher promocode-switcher-label-left fw-17px'
              label={t('GROWTH_AND_MARKETING_PAGE.EDIT_PROMO_CODE_MODAL.ACTIVE')}
              type='switch'
              aria-disabled={false}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col xs={12} className='d-flex justify-content-between'>
          <Col xs={4}>
            <Button
              variant='light'
              className='px-10 fw-normal h-50px text-uppercase btn-close-promo-code'
              onClick={() => {onHide(); resetForm();}}
            >
              {t('CLOSE')}
            </Button>
          </Col>
          <Col xs={4} style={{'display': 'contents'}}>
            <Button
              disabled={!isValid}
              variant='dark'
              className='px-10 fw-normal h-50px text-uppercase btn-save-promo-code'
              onClick={onSubmitPromoCode}
            >
              {/* {true && (
                <div
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></div>
              )} */}
              {t('SAVE')}
            </Button>
          </Col>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}

export default EditPromoCodeModal
