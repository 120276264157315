import {memo, useState} from 'react'
import {clientTimeZoneOffset, loadingType, DEFAULT_WORKER} from 'app/utils/common/constants'
import {Worker} from 'app/modules/tickets/models/OrderDrawerModel'
import {FormikErrors, FormikHelpers} from 'formik'
import {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import ServiceProviderContainer from '../../../../../../shared/ServiceProvider/components/Container'
import {ReschedulePackageForm} from '../type'
import {ticketApi} from '../../../redux/TicketApi'
import {TicketModel} from '../../../models'
import {sortServiceProviders, transformWorkerData} from 'app/utils/helpers/index'
import moment from 'moment'

const ServiceProviderComponent = (props: {
  values: ReschedulePackageForm
  errors: FormikErrors<ReschedulePackageForm>
  setValues: FormikHelpers<ReschedulePackageForm>['setValues']
  language: string
  index: number
  id: string
  name: string
  ticketData: TicketModel | null
}) => {
  const {t} = useTranslation()

  const {values, setValues, language, index, id, name, ticketData} = props

  const [isSpAvailble, setIsSpAvailble] = useState<boolean>(true)

  const fetchData = async (startDate?: Date) => {
    if (!ticketData) return
    try {
      setValues((s) => ({...s, loadingType: loadingType.SERVICE_PROVIDER}))
      const {data} = await ticketApi.getRescheduleTicket({
        ticketId: ticketData.id,
        selectedDateString: startDate
          ? moment(startDate).locale('en-US').format('MM/DD/YYYY')
          : values.service_provider_filter.startDate
          ? moment(values.service_provider_filter.startDate).locale('en-US').format('MM/DD/YYYY')
          : '',
        shiftType: values.service_provider_filter.filterBy,
        clientTimeOffset: -clientTimeZoneOffset,
        selectWorkerId: '',
      })

      if (!data.workers) {
        setIsSpAvailble(false)
        setValues((s) => ({...s, loadingType: undefined}))
        return
      }

      const formatData = transformWorkerData(
        data.workers.map((item) =>
          item.workerId
            ? item
            : {
                ...item,
                workerId: DEFAULT_WORKER,
                isAuto: true,
                personalImageUrl: '/assets/images/common/unknown-worker.png',
                workerNameEn: t('CREATE_ORDER_DRAWER.BASE_ON_AVAILABILITY'),
                workerNameAr: t('CREATE_ORDER_DRAWER.BASE_ON_AVAILABILITY'),
              }
        )
      )

      const baseOn = formatData.find((item) => item.isAuto)
      const otherOption = formatData.filter((item) => !item.isAuto)

      const service_provider_data = sortServiceProviders(
        values.service_provider_filter,
        otherOption
      )

      setValues((s) => ({
        ...s,
        service_provider_available: service_provider_data,
        number_of_workers: data.numberOfWorker,
        defaultServiceProvider: baseOn,
        loadingType: undefined,
        ...(!s.filterStartDate && {
          filterStartDate: baseOn?.startDate,
        }),
        ...(!s.service_provider_filter.startDate && {
          service_provider_filter: {...s.service_provider_filter, startDate: baseOn?.startDate},
        }),
        isShowDeliveryTime: data.isShowDeliveryTime,
      }))
    } catch (error) {
      setValues((s) => ({...s, loadingType: undefined}))
      return
    }
  }

  const handleFavoriteWorker = (worker: Worker) => {
    const new_sp_available = values.service_provider_available.map((e) =>
      e.workerId === worker.workerId ? {...e, isFavoriteWorker: !e.isFavoriteWorker} : e
    )
    setValues((s) => ({
      ...s,
      service_provider_available: new_sp_available,
    }))
  }

  const displayedServiceProvider = useMemo(() => {
    if (
      values.service_provider_available &&
      values.number_of_workers &&
      values.defaultServiceProvider
    ) {
      return values.number_of_workers === 1
        ? [values.defaultServiceProvider, ...values.service_provider_available]
        : [values.defaultServiceProvider]
    }

    return []
  }, [values.service_provider_available])

  const trans = {
    title: `${index}. ${t('RESCHEDULE_TICKET.PROVIDER_SECTION.TITLE')}`,
    section_title: t('RESCHEDULE_TICKET.PROVIDER_SECTION.SUB_TITLE'),
    search_placeholder: t('CREATE_ORDER_DRAWER.SECTION_4.SEARCH_WORKER_PLACEHOLDER'),
  }

  const filterDisabled = displayedServiceProvider.length < 2

  const handleChangeDate = (startDate: Date) => fetchData(startDate)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (
      values.loadingType !== loadingType.SERVICE_PROVIDER &&
      values.service_provider_available.length
    ) {
      const service_provider_data = sortServiceProviders(
        values.service_provider_filter,
        values.service_provider_available
      )
      setValues((s) => ({...s, service_provider_available: service_provider_data}))
    }
  }, [values.service_provider_filter.sortBy])

  useEffect(() => {
    values.loadingType !== loadingType.SERVICE_PROVIDER && fetchData()
  }, [values.service_provider_filter.filterBy])

  return (
    <ServiceProviderContainer
      isLoading={values.loadingType === loadingType.SERVICE_PROVIDER}
      isSpAvailble={isSpAvailble}
      displayedData={displayedServiceProvider}
      filterDisabled={filterDisabled}
      handleFavoriteWorker={handleFavoriteWorker}
      trans={trans}
      id={id}
      name={name}
      filterStartDate={values.filterStartDate}
      handleChangeDate={handleChangeDate}
    />
  )
}

export default memo(ServiceProviderComponent)
