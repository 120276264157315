import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { RootState } from "../../../../setup";
import { ListResponse, Response } from "../../../../setup/axios/HttpResponse";
import { ServiceScopeModel } from "../../auth/models/AuthModel";
import { PROMO_CODE_TYPE, PromoCodeModel } from "../models/PromoCode";
import { LoyaltyEquation } from "../models/Loyalty";

interface GrowthAndMarketingState {
    isOpenEditPromoCodeModal: boolean,
    isOpenCreatePromoCodeModal: boolean,
    listPromoCodeData: PromoCodeModel[],
    editPromoCodeData: PromoCodeModel | null,
    isOpenEditLoyaltyEquationModal: boolean,
    loyaltyEquation: LoyaltyEquation | null,
}

const initialState: GrowthAndMarketingState = {
    isOpenEditPromoCodeModal: false,
    isOpenCreatePromoCodeModal: false,
    listPromoCodeData: [],
    editPromoCodeData: null,
    isOpenEditLoyaltyEquationModal: false,
    loyaltyEquation: null
}

const growthAndMarketingSlice = createSlice({
    name: "GrowthAndMarketingSlice",
    initialState,
    reducers: {
        setListPromoCodeData(state, action: PayloadAction<PromoCodeModel[]>) {
            state.listPromoCodeData = action.payload
        },
        openEditPromoCodeModal(state, action: PayloadAction<PromoCodeModel>) {
            state.isOpenEditPromoCodeModal = true
            state.editPromoCodeData = action.payload
        },
        openCreatePromoCodeModal(state) {
            state.isOpenCreatePromoCodeModal = true
            state.editPromoCodeData = null
        },
        updatePromoCodeData(state, action: PayloadAction<PromoCodeModel>) {
            for (let i = 0; i < state.listPromoCodeData.length; i++) {
                if (state.listPromoCodeData[i].id === action.payload.id) {
                    state.listPromoCodeData[i] = {...action.payload}
                    break
                }
            }
            state.isOpenEditPromoCodeModal = false;
        },
        changeStatusPromoCode(state, action: PayloadAction<{id: number; status: boolean}>) {
            for (let i = 0; i < state.listPromoCodeData.length; i++) {
                if (state.listPromoCodeData[i].id === action.payload.id) {
                    state.listPromoCodeData[i].isActive = action.payload.status
                    break
                }
            }
        },
        createPromoCodeData(state, action: PayloadAction<PromoCodeModel>) {
            let maxIndex: number = 0
            for (let i = 0; i < state.listPromoCodeData.length; i++) {
                if (state.listPromoCodeData[i].id > maxIndex) {
                    maxIndex = state.listPromoCodeData[i].id
                }
            }
            action.payload.id = maxIndex + 1;
            state.listPromoCodeData = [...state.listPromoCodeData, action.payload]
            state.isOpenCreatePromoCodeModal = false;
        },
        hideEditPromoCodeModal(state) {
            state.isOpenEditPromoCodeModal = false;
            state.editPromoCodeData = null
        },
        hideCreatePromoCodeModal(state) {
            state.isOpenCreatePromoCodeModal = false;
            state.editPromoCodeData = null
        },

        setLoyaltyEquationData(state, action: PayloadAction<LoyaltyEquation>) {
            state.loyaltyEquation = action.payload
        },
        openEditLoyaltyEquationModal(state) {
            state.isOpenEditLoyaltyEquationModal = true;
        },
        hideEditLoyaltyEquationModal(state) {
            state.isOpenEditLoyaltyEquationModal = false;
        },
        updateLoyaltyEquationData(state, action: PayloadAction<LoyaltyEquation>) {
            state.loyaltyEquation = action.payload
            state.isOpenEditLoyaltyEquationModal = false;
        },
    }
})

// Action
export const growthAndMarketingAction = growthAndMarketingSlice.actions;

//Selector
export const selectIsOpenEditPromoCodeModal = (state: RootState) => state.growthAndMarketingManagement.isOpenEditPromoCodeModal;
export const selectIsOpenCreatePromoCodeModal = (state: RootState) => state.growthAndMarketingManagement.isOpenCreatePromoCodeModal;
export const selectListPromoCodeData = (state: RootState) => state.growthAndMarketingManagement.listPromoCodeData;
export const selectEditPromoCodeData = (state: RootState) => state.growthAndMarketingManagement.editPromoCodeData;
export const selectIsOpenEditLoyaltyEquationModal = (state: RootState) => state.growthAndMarketingManagement.isOpenEditLoyaltyEquationModal;
export const selectLoyaltyEquation = (state: RootState) => state.growthAndMarketingManagement.loyaltyEquation;

// Reducer
const growthAndMarketingSliceReducer = growthAndMarketingSlice.reducer;
export default growthAndMarketingSliceReducer;