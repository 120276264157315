export const FLOW_TYPE = {
    PACKAGE: 'PACKAGE',

}

export const PaymentMethod = [
    { value: 'Cash', label_En: 'Cash', label_Ar: 'نقدي', },
    { value: 'Online', label_En: 'Online', label_Ar: 'اون لاين', },
    { value: 'Invoice', label_En: 'Invoice', label_Ar: 'فاتورة', },
]

export const PaymentStatus = [
    { value: 'unpaid', label_En: 'Not Paid', label_Ar: 'غير مدفوعة', },
    { value: 'paid', label_En: 'Paid', label_Ar: 'مدفوعة', },
]

export const SERVICE_TYPE = {
    SERVICE: 'service',
    PACKAGE_SERVICE: 'package_service',
    SERVICE_OFFER: 'service_offer'
}
