export const CancellationReason = [
  {value: "1", label: "TITLE_REASON_CUSTOMER_REQUEST"},
  {value: "2", label: "TITLE_REASON_OVERCAPACITY"},
  {value: "3", label: "TITLE_REASON_WORKER_NOT_AVAILABLE"},
  {value: "4", label: "TITLE_REASON_OTHER"},
]

export const ShiftConfig = [
  {
      id: "1",
      labelEn: "Morning",
      labelAr: "صباحاً"
  },
  {
    id: "2",
    labelEn: "Afternoon",
    labelAr: "الظهيرة"
  },
  {
    id: "3",
    labelEn: "Night",
    labelAr: "مساءً"
  }
];

export const PaymentStatusConfig = [
  {
    id: "paid",
    labelEn: "Paid",
    labelAr: "مدفوعة"
  },
  {
    id: "unpaid",
    labelEn: "Not paid",
    labelAr: "غير مدفوعة"
  }
]