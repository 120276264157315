import { selectLanguage } from 'app/modules/auth'
import { PackageWorkflowServiceModel } from 'app/modules/service-management/models'
import { Languages } from 'app/utils/common/constants'
import { KTSVG } from 'app/utils/helpers'
import { t } from 'i18next'
import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { Dot } from 'react-bootstrap-icons'
import { Form } from 'react-bootstrap-v5'
import { useAppSelector } from 'setup/redux/Hooks'
interface Props {
  serviceData: PackageWorkflowServiceModel,
  handleSelectService: () => void,
  handleOpenEditService: () => void,
  onUpdateService: (data: PackageWorkflowServiceModel) => void
  isSelected: boolean
}
const ServiceItem = (props: Props) => {
  const { serviceData, handleSelectService, handleOpenEditService, onUpdateService, isSelected } = props
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;

  return (
    <div className='position-relative d-flex flex-wrap p-0'>
      <div className={`w-100 service-item ${rtlMode && isSelected ? "service-item--selected--rtl" : ""} ${isSelected ? "service-item--selected" : ""} w-100 h-100px`} onClick={() => handleSelectService()}>
        <div className='service-item--name h3'>{serviceData[rtlMode ? "nameAr" : "nameEn"]}</div>
        {
          serviceData[rtlMode ? "subTitleTextAr" : "subTitleTextEn"] &&
          <div className='service-item--sub-title h5'><Dot size={24} />{serviceData[rtlMode ? "subTitleTextAr" : "subTitleTextEn"]}</div>
        }
      </div>
      <div className={`service-item--btn-wrapper${rtlMode ? "--rtl" : ""} ${isSelected ? "service-item--btn-wrapper--selected" : ""}`}>
        <Form.Check
          checked={Boolean(serviceData.status)}
          onChange={() => onUpdateService(_.cloneDeep({ ...serviceData, status: Boolean(serviceData.status) ? 0 : 1 }))}
          className={`service-management-package-switcher`}
          type="switch"
        />
        <span className={`service-item--btn-edit ${rtlMode ? "service-item--btn-edit--rtl" : ""}`} onClick={handleOpenEditService}><KTSVG className='h-50' defaultColor path="/assets/images/icons/pencil.svg" /> </span>
      </div>
    </div>

  )
}


export default ServiceItem