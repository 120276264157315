import { useEffect, useState } from 'react'
import moment from 'moment'
import { FormikErrors, FormikHelpers, FormikProps } from 'formik'
import { clientTimeZoneOffset, FLOW_TYPE } from 'app/utils/common/constants'
//Components
import AccordionItem from './AccordionItem'
import { FieldDateTimePickerInline, TSelectedItem } from 'app/utils/helpers/components/Form'
//Type
import { DateHighLighted, IForm } from './type'
import { ticketApi } from 'app/modules/tickets/redux/TicketApi'
import { PackageInfo, PackageServiceResponse } from 'app/modules/tickets/models/OrderDrawerModel'
import { toast } from 'react-toastify'
import { ToastDefaultConfig } from 'setup/toast/ToastConfig'
import { useTranslation } from 'react-i18next'
import { HTTP_RESPONSE_STATUS } from 'setup/constants/Http'
import { Response } from 'setup/axios/HttpResponse'
import { TimeSlotPackage } from 'app/utils/common/models'
import { activeDateProcess, highlightDateProcess, highlightDateSeveralVist } from 'app/utils/helpers/OrderDrawerHelpers'


const DateTimeComponent = (props: {
  formProps: FormikProps<IForm>,
  language: string,
  index?: number
}) => {
  const { formProps: { values, setFieldValue, setValues }, language, index = 0 } = props;
  const { t } = useTranslation()
  const flowType = values.flow_type

  const number_visit = values.numberOfVisitPerWeek || 0
  const number_week = values.numberOfWeeks || 0

  const [state, setState] = useState<{
    minDate: Date,
    isLoadingPicker: boolean,
    dateHighlighted: DateHighLighted[],
    activeDates: DateHighLighted[],
  }>({
    minDate: new Date(),
    isLoadingPicker: false,
    dateHighlighted: [],
    activeDates: [],
  })

  const isMulti = (values.flow_type && values.flow_type === FLOW_TYPE.PACKAGE) && (number_visit > 1 && number_week > 1)

  const spIds = values.service_selected?.map(e => 'spIds' in e.data ? (e.data.spIds || []) : []).flat() || []
  const offerId = values.service_selected?.[0]?.type === 'offer' ? values.service_selected?.[0]?.id : undefined
  const majorId = values.service_selected?.[0]?.type === 'sub_sub' ? values.service_selected?.[0]?.major_id : values.service_selected?.[0]?.parentId
  const handleDateClassName = (...params: Parameters<React.ComponentProps<typeof FieldDateTimePickerInline>['customDateClassName']>): (string | null) => {
    if (!isMulti) return null
    const [date, selected_date, current_date] = params
    if (selected_date) {
      const transform_date = (Array.isArray(selected_date) ? selected_date : [selected_date]).map((el) => el.date)
      if (transform_date.find((dte: Date) => dte.isSame(date) && dte.isSame(current_date))) {
        return 'react-datepicker__day--showing react-datepicker__day--selected '
      }
      return transform_date.find((dte: Date) => dte.isSame(date)) ? 'react-datepicker__day--selected' : null

    }
    return null
  }

  useEffect(() => {
    if (values.preSelectSP) {
      const availableDate: DateHighLighted[] = highlightDateProcess(values.preSelectSP?.dayAvailable || [], number_visit, number_week)
      if (number_visit === 1) {
        setState({
          ...state,
          minDate: new Date().addDays(1),
          dateHighlighted: [],
          activeDates: []
        })
      } else {
        setState({
          ...state,
          minDate: availableDate[0]?.date || new Date(),
          dateHighlighted: [],
        })
      }

    }
  }, [values.preSelectSP])

  //@ts-ignore
  const handleSelectedDate: (date: Date | null, newDateHighLight: DateHighLighted[] | undefined, selectedDays: TSelectedItem[] | undefined) => ReturnType<React.ComponentProps<typeof FieldDateTimePickerInline>['onFetchDataInfo']> = async (date, newDateHighLight, selectedDays) => {
    if (date) {
      if (flowType === FLOW_TYPE.PACKAGE) {
        if (values.preSelectSP) {
          let currentTimeSlot: DateHighLighted | undefined
          if (number_visit === 1 && number_week > 1) { //Single Visit per week
            currentTimeSlot = newDateHighLight
              ? newDateHighLight.find((s: DateHighLighted) => date.isSame(new Date(s.date) as Date))
              : state.dateHighlighted.length !== 0
                ? state.dateHighlighted.find((s: DateHighLighted) =>
                  date.isSame(new Date(s.date) as Date)
                )
                : undefined
          } else {  // Several Visit
            if (selectedDays && selectedDays.length) {
              const newSlot = selectedDays.find((s) => date.isSame(new Date(s.date) as Date))?.timeSlot || []
              if (newSlot.length !== 0) {
                return {
                  timeSlot: newSlot,
                }
              }
            }
            setState({ ...state, isLoadingPicker: true })
            try {
              const { data, status } = await ticketApi.getPackageAvailable({
                packageWorkflowId: values.main_service?.data?.packageWorkFlowId || 0,
                countryId: values.location?.countryId,
                isGetAll: true,
                clientTimeOffset: -clientTimeZoneOffset,
                customerId: values.customer_id,
                majorId: values.main_service?.id || 0,
                companyId: values.preSelectSP?.companyId || '',
                selectedDate: moment(date).locale('en-US').format("YYYY/MM/DD"),
                selectedPackage: {
                  id: Number(values.package),
                  spid: values.preSelectSP?.spId || "",
                  HasUnratedWorker: false,
                  FavWorkerIds: []
                },
                spIds: values.spIds
              }) as Response<PackageServiceResponse>

              if (status !== HTTP_RESPONSE_STATUS.SUCCESS) {
                toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
                setState({ ...state, isLoadingPicker: false })
                return
              }

              const newDayAvailable = data.packageInfo[0]?.preSelectSP?.dayAvailable || []

              if (newDayAvailable.length === 0) {
                setState({ ...state, isLoadingPicker: false })
                toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.NOT_AVAILABLE')}`, ToastDefaultConfig());
                return undefined
              }

              const newDateActive: DateHighLighted[] = highlightDateProcess(newDayAvailable, number_visit, number_week)
              currentTimeSlot = newDateActive.find((s: DateHighLighted) => date.isSame(new Date(s.date) as Date))
              if (number_visit === 1 && number_week === 1) { // Single Visit
                setState({ ...state, isLoadingPicker: false })
                return {
                  customerVisitDuration: 0,
                  timeSlot: currentTimeSlot?.timeSlots.map((s: TimeSlotPackage) => ({ ...s, isAvailable: true })) || []
                }
              }
              const activeDateMerged = activeDateProcess(state.activeDates.concat(newDateActive), state.minDate)

              if (!state.dateHighlighted.find(s => s.date.isSame(date))) {
                const highlightRange = highlightDateSeveralVist(selectedDays, date)
                setState({
                  ...state,
                  activeDates: activeDateMerged,
                  dateHighlighted: highlightRange,
                  isLoadingPicker: false
                })

              } else {
                setState({
                  ...state,
                  activeDates: activeDateMerged,
                  isLoadingPicker: false
                })

              }

              return {
                timeSlot: currentTimeSlot?.timeSlots.map((s: TimeSlotPackage) => ({ ...s, isAvailable: true })) || []
              }
            } catch (error) {
              console.log(error)
              setState({ ...state, isLoadingPicker: false })
              toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
              return
            }
          }

          return {
            customerVisitDuration: 0,
            timeSlot: currentTimeSlot?.timeSlots.map((s: TimeSlotPackage) => ({ ...s, isAvailable: true })) || []
          }
        }
      }

      if (spIds.length > 0) {
        setState({ ...state, isLoadingPicker: true })
        const resp = (await ticketApi.getDateTimeTicket({
          clientTimeZone: clientTimeZoneOffset,
          dateCheck: moment(date).locale('en-US').format('YYYY-MM-DD') || '',
          spIds,
          majorId: majorId || 0,
          offerId,
        }).catch(e => ({ data: undefined })));

        setState({ ...state, isLoadingPicker: false })
        return {
          customerVisitDuration: resp.data?.customerVisitDuration || 0,
          timeSlot: resp.data?.timeSlots || [],

        }
      }
    }
  }
  //@ts-ignore
  const resetHighLightDays: (dateCheck: Date) => ReturnType<React.ComponentProps<typeof FieldDateTimePickerInline>['resetHighlightDays']> = async (dateCheck: Date) => {
    setState({ ...state, isLoadingPicker: true })
    try {
      const { data, status } = await ticketApi.getPackageAvailable({
        packageWorkflowId: values.main_service?.data?.packageWorkFlowId || 0,
        countryId: values.location?.countryId,
        isGetAll: true,
        clientTimeOffset: -clientTimeZoneOffset,
        customerId: values.customer_id,
        companyId: values.preSelectSP?.companyId || '',
        majorId: values.main_service?.id || 0,
        selectedDate: moment(dateCheck).locale('en-US').format("YYYY/MM/DD"),
        selectedPackage: {
          id: Number(values.package),
          spid: values.preSelectSP?.spId || '',
          HasUnratedWorker: false,
          FavWorkerIds: []
        },
        spIds: values.spIds
      }) as Response<PackageServiceResponse>
      if (status !== HTTP_RESPONSE_STATUS.SUCCESS) {
        setState({ ...state, isLoadingPicker: false })
        toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR')}`, ToastDefaultConfig());
        return undefined
      }

      const newDayAvailable = data.packageInfo[0]?.preSelectSP?.dayAvailable || []
      if (newDayAvailable.length === 0) {
        setState({ ...state, isLoadingPicker: false })
        toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.NOT_AVAILABLE')}`, ToastDefaultConfig());
        return undefined
      }

      const newDateHighLight: DateHighLighted[] = highlightDateProcess(newDayAvailable, number_visit, number_week)
      setState({
        ...state, dateHighlighted: newDateHighLight,
        isLoadingPicker: false
      })

      return newDateHighLight
    } catch (error) {
      setState({ ...state, isLoadingPicker: false })
      toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
      return undefined
    }
  }

  return (
    <AccordionItem id='date_time' title={`${index}. ${t('CREATE_ORDER_DRAWER.SECTION_3.TITLE')}`}>
      <p className='section-title'>{t('CREATE_ORDER_DRAWER.SECTION_3.SUB_TITLE')}</p>
      <FieldDateTimePickerInline
        language={language}
        minDate={state.minDate}
        disableDate={((values.service_selected?.length === 0 || !values.service_selected || (flowType === FLOW_TYPE.PACKAGE && !values.package)))}
        isLoading={values.loadingType === 'dateTime' || state.isLoadingPicker}
        name='date_time'
        isMulti={isMulti === true}
        showSelectedDatetime={(values.flow_type && values.flow_type === FLOW_TYPE.PACKAGE) === true}
        //@ts-ignore
        highlightDates={state.dateHighlighted}
        onFetchDataInfo={handleSelectedDate}
        customDateClassName={handleDateClassName}
        selectedDateTime={[]}
        flowType={values.flow_type}
        intervalDate={number_visit}
        intervalWeek={number_week}
        resetHighlightDays={resetHighLightDays}
        activeDates={state.activeDates}
        header={{
          visitTime: t('CREATE_ORDER_DRAWER.SECTION_3.VISIT_TIME'),
          selectedDate: t('CREATE_ORDER_DRAWER.SECTION_3.SELECTED_DATE'),
          selectedTime: t('CREATE_ORDER_DRAWER.SECTION_3.SELECTED_TIME'),
        }}
        label={{
          emptyTimeSlot: t('CREATE_ORDER_DRAWER.SECTION_3.VALID.EMPTY_TIME_SLOT'),
        }}
      />
      <p>
        {
         
        }
      </p>
    </AccordionItem>
  )
}

export default DateTimeComponent