export interface PromoCodeModel {
    id: number,
    promoCode: string
    promoCodeType: number
    discountAmount: number | ''
    maxDiscountAmount: number | ''
    isApplyMaxDiscountAmount: boolean
    isActive: boolean
    packageIds: string
    cityIds: string
}

export const PROMO_CODE_TYPE = {
    PERCENT: 1,
    FIXED: 2
}

export const PROMO_CODE_TYPE_SELECT = [
    {
        title: 'PERCENT_DISCOUNT',
        value: 1
    },
    {
        title: 'FIXED_DISCOUNT',
        value: 2
    }
]