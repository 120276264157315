/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.scss';

import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Response } from 'setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { USER_TYPE } from '../../../../utils/common/constants';
import { ServiceTreeNode } from '../../../../utils/common/models';
import { convertOperationListToObject, convertOperationObjectToList } from '../../../../utils/helpers';
import { checkUserInfo } from '../../../../utils/helpers/UserHelper';
import { UserSchemasHelper } from '../../../../utils/helpers/UserValidate';
import { ServiceScopeModel } from '../../../auth/models/AuthModel';
import {
    AdminResponse,
    BusNotLinkedDriver,
    City,
    DispatchCenter,
    Driver,
    LinkedDispatcher,
    Nationality,
    Skill,
    Team,
    UserFormModel,
    UserModel
} from '../../models';
import { OperationHoursAndMap } from '../../models/OperationHoursAndMap';
import { userApi } from '../../redux/UserApi';
import AccountInfo from '../create-user/step/account-info/AccountInfo';
import TechOperationHours from '../create-user/step/operation-hours/TechOperationHours';
import { StepByStepTechUserHeader } from '../create-user/step/step-by-step-header/StepByStepTechUserHeader';
import WorkInfo from '../create-user/step/work-info/WorkInfo';
import WorkLocation from '../create-user/step/work-location/WorkLocation';
import { Completed } from './steps/Completed';
import { isEmptyOrNull } from 'app/utils/helpers/extendMethod';
import { CapacitySetupType } from 'app/utils/common/enum';
import async from 'react-select/dist/declarations/src/async/index';

type Props = {
    className?: string,
    techId?: string | null,
    nationalities: Nationality[],
    skills: Skill[];
    services?: ServiceScopeModel;
    listAdmin: AdminResponse[];
    busesNotLinkedDriver: BusNotLinkedDriver[];
    dispatchCenters: DispatchCenter[];
    drivers: Driver[];
    linkedDispatchers: LinkedDispatcher[];
    operationHoursAndMap: OperationHoursAndMap[];
    teams: Team[];
    cities: City[];
    onHide?: () => void,
    onUpdated?: (data: UserModel) => void,
    rtlMode: boolean,
}

const defaultValue = {
    userType: USER_TYPE.ADMIN,
    avatar: '',
    fullNameAr: '',
    fullNameEn: '',
    email: '',
    phone: '-1',
    workerId: '',
    firstPhoneNumber: '+966',
    nationality: '',
    password: '',
    confirmPassword: '',
    isActive: false
}
export const UpdateTechModal: React.FC<Props> = ({ cities, teams, operationHoursAndMap, linkedDispatchers, drivers, dispatchCenters, busesNotLinkedDriver, skills, services, listAdmin, nationalities, techId, onHide, onUpdated, rtlMode }) => {
    const stepperRef = useRef<HTMLDivElement | null>(null);
    const stepper = useRef<StepperComponent | null>(null);
    const { t } = useTranslation();
    const UserSchemas = UserSchemasHelper(t)
    const [userImageUrl, setUserImageUrl] = useState("");
    const [currentSchema, setCurrentSchema] = useState<any>(UserSchemas.ACCOUNT_INFO_TECHNICIAN_UPDATE);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [initForm, setInitForm] = useState<UserFormModel>(defaultValue);
    const [isSuccess, setSuccess] = useState(false);
    const [isStepJump, setIsStepJump] = useState(false)
    const [isSaveForm, setIsSaveForm] = useState(false)
    const [nextStep, setNextStep] = useState(0)
    useEffect(() => {
        if (!techId) return;

        resetData();
        setIsLoading(true);
        userApi.getUserById(techId).then(x => {
            if (x.data) {
                const dispatchCenterLocation = x.data.dispatchCenterLocation || undefined;
                setUserImageUrl(x.data.personalImageUrl);
                setInitForm({
                    userType: USER_TYPE.TECHNICIAN,
                    avatar: '',
                    fullNameAr: x.data.fullNameAr,
                    fullNameEn: x.data.fullNameEn,
                    email: x.data.email,
                    phone: x.data.phoneNumber,
                    workerId: x.data.workerId,
                    firstPhoneNumber: x.data.phoneNumber.substring(0, 5) == "00966" ? '+966' : '+973',
                    nationality: x.data.nationality,
                    password: x.data.password,
                    //confirmPassword: x.data.password,
                    isActive: x.data.isActive,
                    regularHours: convertOperationObjectToList(JSON.parse(x.data.operationHours || "{}")),
                    ramadanHours: convertOperationObjectToList(JSON.parse(x.data.ramadanOperationHours || "{}")),
                    cityId: parseInt(("" + x.data.cityId).split('.')[1]),
                    adminId: x.data.spAdminId,
                    serviceAreaJson: !x.data.serviceArea || x.data.serviceArea == 'undefined' ? "" : x.data.serviceArea,
                    listServiceId: x.data.subSubServiceLinkeds,
                    dispatchCenterLocation: dispatchCenterLocation != undefined ? JSON.parse(dispatchCenterLocation).map((e: any) => ({ lat: e.lat, lng: e.lng, address: e.address || e.googleAddress }))[0] : undefined,
                    capacity: x.data.capacity,
                    capacitySetup: x.data.capacitySetup,
                    sharedCapacity: x.data.sharedCapacity
                });
            }
        }).finally(() => setIsLoading(false));
    }, [techId])
    useEffect(() => {
        _loadStepper();
    }, [stepperRef.current != null])
    const onSubmit = async (values: UserFormModel) => {
        if (techId) {
            let serviceArea = [
                {
                    type: "POLYGON",
                    id: null,
                    geometry: [values?.serviceArea?.map(x => [x.lat, x.lng])]
                }
            ];
            let formData = new FormData();
            formData.append("Id", techId);
            formData.append('OperationHoursJson', JSON.stringify(convertOperationListToObject(values.regularHours || [])))
            formData.append('RamadanOperationHoursJson', JSON.stringify(convertOperationListToObject(values.ramadanHours || [])))
            formData.append('ServiceAreaJson', JSON.stringify(serviceArea))
            values.listService?.forEach((data: ServiceTreeNode) => formData.append('SubSubServiceLinkeds', `${data.subSubId}`))
            formData.append('SPAdminId', values.adminId || '');
            formData.append('CityId', `${values?.cityId}`);
            formData.append('Capacity', `${values?.capacity}`);
            formData.append('FullNameAr', values?.fullNameAr || '');
            formData.append('FullNameEn', values?.fullNameEn || '');
            formData.append('Avatar', values.avatar);
            formData.append('Email', values?.email || '');
            formData.append('Password', values?.password || '');
            formData.append('PhoneNumber', `${values?.phone}`);
            formData.append('IsActive', `${values?.isActive}`);
            formData.append('IsRemoveAvatar', `${values?.isRemoveAvatar ? "true" : "false"}`);
            formData.append('DispatchCenterLocation', values?.dispatchCenterLocation != undefined ? JSON.stringify([values?.dispatchCenterLocation].map((e: any) => ({ lat: e.lat, lng: e.lng, googleAddress: e.address }))) : '');

            const resp = await userApi.updateTech(formData).finally(() => setIsLoadingSave(false));

            if (resp.status === HTTP_RESPONSE_STATUS.SUCCESS) {

                stepper?.current?.goto(5);
                setCurrentStep(5)
                setSuccess(true);
                onUpdated?.(resp.data);
            } else {
                toast.error(resp.message, ToastDefaultConfig());
            }

        }

    }

    const _loadStepper = () => {
        stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    }
    const _checkExistEmail = async (id: any, email: any) => {
        const params = {
            email: email,
            userId: id
        }

        const response: Response<boolean> = await userApi.checkExistEmail(params);

        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            return response.data;
        } else {
            toast.error(response.message, ToastDefaultConfig());
            return false;
        }
    }
    const _checkExistPhone = async (id: any, phone: any) => {
        const params = {
            phoneNumber: phone,
            userId: id
        }

        const response: Response<boolean> = await userApi.checkExistPhone(params);

        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            return response.data;
        } else {
            toast.error(response.message, ToastDefaultConfig());
            return false;
        }
    }

    const onPrevStep = (setErrors: any) => {
        if (!stepper.current) {
            return
        }
        setErrors({});
        setSuccess(false);
        stepper.current.goPrev();

        switch (stepper?.current?.currentStepIndex) {
            case 1:
                setCurrentSchema(UserSchemas.ACCOUNT_INFO_TECHNICIAN_UPDATE);
                break
            case 2:
                setCurrentSchema(UserSchemas.WORK_INFO.TECH)
                break
            case 3:
                setCurrentSchema(UserSchemas.OPERATION_HOURS)
                break
            case 4:
                setCurrentSchema(UserSchemas.WORK_LOCATION.TECH)
                break
        }
        setCurrentStep(currentStep - 1);
    }
    const handleSubmit = async (values: UserFormModel) => {
        setIsLoading(false)
        setIsLoadingSave(true)
        return await onSubmit(values)
    }
    const onNextStep = async (values: UserFormModel, formikHelpers: FormikHelpers<UserFormModel>) => {
        if (!stepper.current || isLoading) return

        setIsLoading(true)
        switch (currentStep) {
            case 1:

                if (await _checkExistEmail(techId, values.email)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                if (await _checkExistPhone(techId, values.phone)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                let params = {
                    email: values.email,
                    phoneNumber: `${values?.phone}`,
                    //workerId: values.workerId,
                    userId: techId || ""
                };
                if (await checkUserInfo(params, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.USER_HAS_EXIST'))) {
                    setIsLoading(false)
                    return;
                }
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goNext()
                break;
            case 2:
                // if (values.listService?.length === 0) {
                //     setIsLoading(false)
                //     formikHelpers.setFieldError("listService", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SERVICE'));
                //     return;
                // }
                if (isEmptyOrNull(values.dispatchCenterLocation)) {
                    setIsLoading(false)
                    formikHelpers.setFieldError("dispatchCenterLocation", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_DISPATCH_LOCATION'));
                    return;
                }

                if (isEmptyOrNull(values.capacity)) {
                    setIsLoading(false)
                    formikHelpers.setFieldError("capacity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CAPACITY'));
                    return;
                } else if (values.capacity && values?.capacitySetup === CapacitySetupType.Both && values.capacity < Number(values.sharedCapacity)) {
                    setIsLoading(false)
                    formikHelpers.setFieldError("capacity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MIN_SHARED_CAP', { shared_cap: values?.capacitySetup }));
                    return;
                }
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goNext()
                break;
            case 3:
                if (values.isActive && (!values.regularHours || !values.regularHours.some(x => x.isSelected))) {
                    formikHelpers.setFieldError("regularHours", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_REGULAR_HOURS'));
                    setIsLoading(false)
                    return;
                } else {
                    formikHelpers.setErrors({});
                };
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goNext()
                break;
            case 4:
                if (!values.serviceArea || !values.serviceArea.length) {
                    formikHelpers.setFieldError("serviceArea", "Service area is required!");
                    setIsLoading(false)
                    return;
                }
                if (isSaveForm) await handleSubmit(values)
                break;
            case 5:
                onHideModal();
                break;
        }
        if (!isSaveForm) {
            setCurrentStep(currentStep + 1);
        }
        formikHelpers.setFieldValue('step', currentStep + 1);

        setIsLoading(false)
    }
    const onStepJump = async (values: UserFormModel, formikHelpers: FormikHelpers<UserFormModel>) => {
        if (!stepper.current || isLoading) return

        setIsLoading(true)
        switch (currentStep) {
            case 1:
                if (await _checkExistEmail(techId, values.email)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                if (await _checkExistPhone(techId, values.phone)) {
                    toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
                    setIsLoading(false)
                    return;
                }

                let params = {
                    email: values.email,
                    phoneNumber: `${values?.phone}`,
                    //workerId: values.workerId,
                    userId: techId || ""
                };
                if (await checkUserInfo(params, t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.USER_HAS_EXIST'))) {
                    setIsLoading(false)
                    return;
                }
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);

                break;
            case 2:
                // if (values.listService?.length === 0) {
                //     setIsLoading(false)
                //     formikHelpers.setFieldError("listService", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_SERVICE'));
                //     return;
                // }
                if (isEmptyOrNull(values.dispatchCenterLocation)) {
                    setIsLoading(false)
                    formikHelpers.setFieldError("dispatchCenterLocation", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_DISPATCH_LOCATION'));
                    return;
                }

                if (isEmptyOrNull(values.capacity)) {
                    setIsLoading(false)
                    formikHelpers.setFieldError("capacity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CAPACITY'));
                    return;
                } else if (values.capacity && values?.capacitySetup === CapacitySetupType.Both && values.capacity < Number(values.sharedCapacity)) {
                    setIsLoading(false)
                    formikHelpers.setFieldError("capacity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.MIN_SHARED_CAP', { shared_cap: values?.capacitySetup }));
                    return;
                }

                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);
                break;
            case 3:
                if (values.isActive && (!values.regularHours || !values.regularHours.some(x => x.isSelected))) {
                    formikHelpers.setFieldError("regularHours", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_REGULAR_HOURS'));
                    setIsLoading(false)
                    return;
                } else {
                    formikHelpers.setErrors({});
                };
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);
                break;
            case 4:
                if (!values.serviceArea || !values.serviceArea.length) {
                    formikHelpers.setFieldError("serviceArea", "Service area is required!");
                    setIsLoading(false)
                    return;
                }
                if (isSaveForm) await handleSubmit(values)
                else stepper?.current?.goto(nextStep);
                break;
            case 5:
                onHideModal();
                break;
        }
        if (!isSaveForm) {
            setCurrentStep(nextStep);
        }
        formikHelpers.setFieldValue('step', nextStep);

        setIsLoading(false)
    }

    const onHideModal = () => {
        resetData();
        onHide?.();
    };

    const resetData = () => {
        setCurrentStep(1);
        stepper?.current?.goto(1);
        setCurrentSchema(UserSchemas.ACCOUNT_INFO_TECHNICIAN_UPDATE);
        setSuccess(false);
        setInitForm(defaultValue)
    }

    return (
        <>
            <Modal show={!!techId} onHide={onHideModal} backdrop="static" keyboard={false} size='xl'>
                {!isSuccess &&
                    <Modal.Header closeButton className='header-primary'>
                        <Modal.Title className='fw-normal'>{t('USERS_PAGE.STEP_UPDATE_USER.TITLE_TECH')}</Modal.Title>
                    </Modal.Header>
                }
                {(isLoading && initForm.phone == '-1') &&
                    <div className='update-loading mt-10 mb-10'>
                        <div className='spinner-border' role='status'></div>
                    </div>
                }
                {initForm.phone != '-1' &&
                    <div
                        ref={stepperRef}
                        className='stepper stepper-links d-flex flex-column'
                        id='kt_create_account_stepper'
                    >
                        <Formik
                            validationSchema={currentSchema}
                            initialValues={{ ...initForm, ...({ step: 1, formType: 'edit' } as ({ step?: number, formType?: string })) }}
                            onSubmit={(values, helpers) => { isStepJump ? onStepJump(values, helpers) : onNextStep(values, helpers) }}
                        >
                            {({ handleSubmit, errors, touched, setTouched, setErrors, isValid, values, setFieldError, setFieldValue }) => (
                                <>
                                    {!isSuccess &&
                                        <StepByStepTechUserHeader currentStep={currentStep} handleStepJump={(step) => { handleSubmit(); setIsStepJump(true); setNextStep(step); setIsSaveForm(false) }} />
                                    }
                                    <form id='kt_create_account_form'>
                                        <Row className='justify-content-center'>
                                            <Col sm="12" md="11">
                                                <div className='current' data-kt-stepper-element='content'>
                                                    {nationalities && (
                                                        <AccountInfo
                                                            errors={errors}
                                                            values={values}
                                                            touched={touched}
                                                            rtl={rtlMode}
                                                            nationalities={nationalities}
                                                            setFieldValue={setFieldValue}
                                                            setFieldError={setFieldError}
                                                            setTouched={setTouched}
                                                            avatar={userImageUrl}
                                                            clearAvatar={() => setUserImageUrl("")}
                                                            cities={cities}
                                                        />
                                                    )}
                                                </div>

                                                <div data-kt-stepper-element='content'>
                                                    <WorkInfo
                                                        errors={errors}
                                                        values={values}
                                                        touched={touched}
                                                        rtl={rtlMode}
                                                        skills={skills}
                                                        services={services}
                                                        busesNotLinkedDriver={busesNotLinkedDriver}
                                                        dispatchCenters={dispatchCenters}
                                                        drivers={drivers}
                                                        linkedDispatchers={linkedDispatchers}
                                                        currentCheckCustomStep={stepper.current?.currentStepIndex || 1}
                                                        setFieldValue={setFieldValue}
                                                        setTouched={setTouched}
                                                        step={stepper.current?.currentStepIndex}
                                                        listAdmin={listAdmin}
                                                        techId={techId}
                                                    />
                                                </div>
                                                <div data-kt-stepper-element='content'>
                                                    <TechOperationHours
                                                        errors={errors}
                                                        values={values}
                                                        touched={touched}
                                                        rtl={rtlMode}
                                                        setFieldValue={setFieldValue}
                                                        setTouched={setTouched}
                                                        step={stepper.current?.currentStepIndex}
                                                        currentCheckCustomStep={stepper.current?.currentStepIndex || 1}
                                                        operationHoursAndMap={operationHoursAndMap}
                                                    />
                                                </div>
                                                <div data-kt-stepper-element='content'>
                                                    <WorkLocation
                                                        errors={errors}
                                                        values={values}
                                                        touched={touched}
                                                        rtl={rtlMode}
                                                        teams={teams}
                                                        cities={cities}
                                                        currentCheckCustomStep={stepper.current?.currentStepIndex || 1}
                                                        setFieldValue={setFieldValue}
                                                        setTouched={setTouched}
                                                        step={stepper.current?.currentStepIndex}
                                                        operationHoursAndMap={operationHoursAndMap}
                                                    />
                                                </div>
                                                <div data-kt-stepper-element='content'>
                                                    <Completed />
                                                </div>

                                            </Col>
                                        </Row>
                                        {!isSuccess ?
                                            <div className='d-flex flex-stack p-5 assign-footer'>

                                                <div className='mr-2'>
                                                    {
                                                        stepper?.current?.currentStepIndex === 1 ?
                                                            <button
                                                                onClick={onHideModal}
                                                                type='button'
                                                                className='btn btn-secondary w-150px me-3 btn-back'>
                                                                {t('BACK')}
                                                            </button>
                                                            :
                                                            <button
                                                                onClick={() => { onPrevStep(setErrors); setIsStepJump(false) }}
                                                                type='button'
                                                                className='btn btn-secondary w-150px me-3 btn-back'
                                                                data-kt-stepper-action='previous'
                                                            >
                                                                {t('BACK')}
                                                            </button>
                                                    }
                                                </div>

                                                <div>
                                                    {!(stepper?.current?.currentStepIndex && stepper.current.currentStepIndex === 4) && <button type='button' onClick={() => { handleSubmit(); setIsStepJump(false); setIsSaveForm(false) }} className='btn btn-secondary w-150px me-3 btn-back' disabled={isLoading || isLoadingSave || !isValid}>
                                                        {isLoading && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                                        <span className='indicator-label'>  {t('NEXT')}</span>
                                                    </button>}
                                                    <button type='button' onClick={() => { handleSubmit(); setIsSaveForm(true) }} className='btn btn-dark w-150px me-3' disabled={isLoadingSave || isLoading || !isValid}>
                                                        {isLoadingSave && <div className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></div>}
                                                        <span className='indicator-label'>  {t('USERS_PAGE.SAVE')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className="text-center mb-20">
                                                <button type='button' onClick={() => { handleSubmit(); setIsSaveForm(false); setIsStepJump(false) }} className='btn btn-secondary w-150px'>
                                                    <span className='indicator-label'> {t('CLOSE')} </span>
                                                </button>
                                            </div>
                                        }
                                    </form>
                                </>
                            )}
                        </Formik>
                    </div>
                }

            </Modal>
        </>
    )
}

