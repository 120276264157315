import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../../setup';
import { ListAdminResponse, ListResponse , WorkersResponse} from '../../../../setup/axios/HttpResponse';
import { USER_TYPE } from '../../../utils/common/constants';
import { TypeOrder } from '../../tickets/modals/create-ticket/steps/TypeOrder';
import { SearchUserRequest, UserTableModel, UserModel, Nationality, Skill, City, Team, LinkedDispatcher, DispatchCenter, Driver, BusNotLinkedDriver, UsersTypeParams, AdminResponse, UserInfo, ActiveUserParam, WorkerResponse } from '../models'
import { OperationHoursAndMap } from '../models/OperationHoursAndMap';

export interface UserState {
  // users
  openingActionUserModal: boolean,
  users: UserTableModel,
  loadingGetUser: boolean,
  isLoadingModal: boolean,
  userModalData: UserInfo | null,
  isLoadingCreateUser: boolean,
  isCreatedSuccessUser: boolean,
  isLoadingActiveUser: boolean,

  // Admin
  isLoadingGetAdmin: boolean,
  admins: AdminResponse[],
  editAdminId: string | null,
  isLoadingUpdateAdmin: boolean,

  // Active User
  isActiveUserSuccess: boolean;


  // Skill
  loadingGetSkill: boolean,
  skills: Skill[]

  // Nationality
  nationalities: Nationality[],
  loadingGetNationality: boolean,

  // City
  cities: City[],
  loadingGetCity: boolean,

  // SpTeams
  teams: Team[],
  loadingGetTeam: boolean,
  editTechId: string | null,
  isLoadingUpdateTech: boolean,

  // Linked Dispatcher
  linkedDispatcher: LinkedDispatcher[],
  loadingGetLinkedDispatcher: boolean,

  // Dispatch Center
  dispatchCenters: DispatchCenter[],
  loadingGetDispatchCenter: boolean,

  // Driver
  drivers: Driver[],
  loadingGetDrivers: boolean,

  // Bus not linked driver
  busesNotLinkedDriver: BusNotLinkedDriver[],
  loadingGetBuses: boolean,

  // operation hours and map
  operationHoursAndMap: OperationHoursAndMap[],
  loadingGetOperationHoursAndMap: boolean,

  // worker 
  isLoadingGetWorker: boolean,
  workers: WorkerResponse[],
  editWorkerId: string | null,
  isLoadingUpdateWorker: boolean,
}

const initialState: UserState = {
  // employers
  openingActionUserModal: false,
  users: {
    data: [],
    total: 0
  },
  userModalData: null,
  loadingGetUser: false,
  isLoadingModal: false,
  isLoadingCreateUser: false,
  isCreatedSuccessUser: false,
  isLoadingActiveUser: false,

  // Admin
  isLoadingGetAdmin: false,
  admins: [],
  isLoadingUpdateAdmin: false,
  editAdminId: null,

  isLoadingUpdateTech: false,
  editTechId: null,
  isActiveUserSuccess: false,
  // nationality
  nationalities: [],
  loadingGetNationality: false,

  // skill
  skills: [],
  loadingGetSkill: false,

  // City
  cities: [],
  loadingGetCity: false,

  // SpTeams
  teams: [],
  loadingGetTeam: false,

  // Linked Dispatcher
  linkedDispatcher: [],
  loadingGetLinkedDispatcher: false,

  // Dispatch Center
  dispatchCenters: [],
  loadingGetDispatchCenter: false,

  // Driver
  drivers: [],
  loadingGetDrivers: false,

  // Bus not linked driver
  busesNotLinkedDriver: [],
  loadingGetBuses: false,

  // operation hours and map
  operationHoursAndMap: [],
  loadingGetOperationHoursAndMap: false,

  isLoadingGetWorker: false,
  workers: [],
  isLoadingUpdateWorker: false,
  editWorkerId: null,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    //User list page
    fetchUserData(state, action: PayloadAction<SearchUserRequest>) {
      state.loadingGetUser = true;
    },
    fetchUserDataFailed(state) {
      state.loadingGetUser = false;
    },
    fetchUserDataSuccess(state, action: PayloadAction<ListResponse<UserModel>>) {
      state.loadingGetUser = false;
      state.users = {
        data: action.payload.data.rows,
        total: action.payload.data.pagination.totalRows
      }
    },
    clearUserData(state) {
      state.users = {
        data: [],
        total: 0
      }
    },
    // Admin
    fetchAdminData(state, action: PayloadAction<UsersTypeParams>) {
      state.isLoadingGetAdmin = true;
    },
    fetchAdminDataFailed(state) {
      state.isLoadingGetAdmin = false;
    },
    fetchAdminDataSuccess(state, action: PayloadAction<ListAdminResponse>) {
      state.isLoadingGetAdmin = false;
      state.admins = action.payload.data;
    },
    // Worker
    fetchWorkerData(state, action: PayloadAction<UsersTypeParams>) {
      state.isLoadingGetWorker = true;
    },
    fetchWorkerDataFailed(state) {
      state.isLoadingGetWorker = false;
    },
    fetchWorkerDataSuccess(state, action: PayloadAction<WorkersResponse>) {
      state.isLoadingGetWorker = false;
      state.workers = action.payload.data;
    },
    // Active User
    fetchActiveUser(state, action: PayloadAction<ActiveUserParam>) {
      state.isLoadingActiveUser = true;
    },
    fetchActiveUserFailed(state) {
      state.isLoadingActiveUser = false;
    },
    fetchActiveUserSuccess(state, action: PayloadAction<ListResponse<any>>) {
      state.isLoadingActiveUser = false;

      let users = [...state.users.data];
      const indexUserActive = users.findIndex(item => item.id == action.payload?.userActive?.userId);
      users[indexUserActive].isActive = action.payload?.userActive?.isActive || false;
      state.users = {
        data: [...users],
        total: state.users.total ? state.users.total++ : 1
      };
    },
    // create new User
    setActionUserModel(state, action: PayloadAction<{ status: boolean, data: UserInfo | null }>) {
      state.openingActionUserModal = action.payload.status;
      state.userModalData = action.payload.data;
    },
    setCloseViewUserModal(state) {
      state.openingActionUserModal = false;
      state.userModalData = null;

    },
    createUser(state, action: PayloadAction<FormData>) {
      state.isLoadingCreateUser = true;
    },
    createUserComplete(state, action: PayloadAction<UserModel>) {
      state.isLoadingCreateUser = false;
      let users = [...state.users.data];
      users.unshift(action.payload);
      state.users = {
        data: [...users],
        total: state.users.total ? state.users.total++ : 1
      };
      if (action.payload.userType === USER_TYPE.ADMIN) {
        const newAdmin: AdminResponse = {
          id: action.payload.id || '',
          fullNameAr: action.payload.fullNameAr || '',
          fullNameEn: action.payload.fullNameEn || '',
        }
        state.admins = [...state.admins, newAdmin];
      }
      state.openingActionUserModal = false;
      state.isCreatedSuccessUser = true;
    },
    createUserError(state) {
      state.isLoadingCreateUser = false;
      state.isCreatedSuccessUser = false;
    },
    clearLoadingCreateUser(state) {
      state.isLoadingCreateUser = false;
    },
    // Nationality
    fetchNationalityData(state) {
      state.loadingGetNationality = true;
    },
    fetchNationalityDataFailed(state) {
      state.loadingGetNationality = false;
    },
    fetchNationalityDataSuccess(state, action: PayloadAction<Nationality[]>) {
      state.loadingGetNationality = false;
      state.nationalities = action.payload;
    },
    // Skills
    fetchSkillData(state) {
      state.loadingGetSkill = true;
    },
    fetchSkillDataFailed(state) {
      state.loadingGetSkill = false;
    },
    fetchSkillDataSuccess(state, action: PayloadAction<Skill[]>) {
      state.loadingGetSkill = false;
      state.skills = action.payload;
    },
    // Bus not linked Driver
    fetchBusNotLinkedDriverData(state) {
      state.loadingGetBuses = true;
    },
    fetchBusNotLinkedDriverDataFailed(state) {
      state.loadingGetBuses = false;
    },
    fetchBusNotLinkedDriverDataSuccess(state, action: PayloadAction<BusNotLinkedDriver[]>) {
      state.loadingGetBuses = false;
      state.busesNotLinkedDriver = action.payload;
    },
    // City
    fetchCityData(state) {
      state.loadingGetCity = true;
    },
    fetchCityDataFailed(state) {
      state.loadingGetCity = false;
    },
    fetchCityDataSuccess(state, action: PayloadAction<City[]>) {
      state.loadingGetCity = false;
      state.cities = action.payload;
    },
    // SPTeam
    fetchTeamData(state) {
      state.loadingGetTeam = true;
    },
    fetchTeamDataFailed(state) {
      state.loadingGetTeam = false;
    },
    fetchTeamDataSuccess(state, action: PayloadAction<Team[]>) {
      state.loadingGetTeam = false;
      state.teams = action.payload;
    },
    // Linked Dispatcher
    fetchDispatcherData(state) {
      state.loadingGetLinkedDispatcher = true;
    },
    fetchDispatcherDataFailed(state) {
      state.loadingGetLinkedDispatcher = false;
    },
    fetchDispatcherDataSuccess(state, action: PayloadAction<LinkedDispatcher[]>) {
      state.loadingGetLinkedDispatcher = false;
      state.linkedDispatcher = action.payload;
    },

    // Dispatch Center
    fetchDispatchCenterData(state) {
      state.loadingGetDispatchCenter = true;
    },
    fetchDispatchCenterDataFailed(state) {
      state.loadingGetDispatchCenter = false;
    },
    fetchDispatchCenterDataSuccess(state, action: PayloadAction<DispatchCenter[]>) {
      state.loadingGetDispatchCenter = false;
      state.dispatchCenters = action.payload;
    },

    // Driver
    fetchDriversData(state) {
      state.loadingGetDrivers = true;
    },
    fetchDriversDataFailed(state) {
      state.loadingGetDrivers = false;
    },
    fetchDriversDataSuccess(state, action: PayloadAction<Driver[]>) {
      state.loadingGetDrivers = false;
      state.drivers = action.payload;
    },

    // operation hours and map
    fetchOperationHoursAndMapData(state) {
      state.loadingGetOperationHoursAndMap = true;
    },
    fetchOperationHoursAndMapDataFailed(state) {
      state.loadingGetOperationHoursAndMap = false;
    },
    fetchOperationHoursAndMapDataSuccess(state, action: PayloadAction<OperationHoursAndMap[]>) {
      state.loadingGetOperationHoursAndMap = false;
      state.operationHoursAndMap = action.payload;
    },

    // Update admin
    showUpdateAdminUser(state, action: PayloadAction<string>) {
      state.editAdminId = action.payload;
      state.isLoadingUpdateAdmin = false;
    },
    closeUpdateAdminUser(state) {
      state.editAdminId = null;
      state.isLoadingUpdateAdmin = false;
    },
    updateAdminUser(state, action: PayloadAction<FormData>) {
      state.isLoadingUpdateAdmin = true;
    },
    updateAdminUserSuccess(state, action: PayloadAction<UserModel>) {
      let index = state.users.data.findIndex(x => x.id == action.payload.id);
      if (index != -1) {
        state.users.data[index] = action.payload;
      }
      state.isLoadingUpdateAdmin = false;
    },
    updateAdminUserFailure(state) {
      state.isLoadingUpdateAdmin = false;
    },

    // Update tech
    showUpdateTechUser(state, action: PayloadAction<string>) {
      state.editTechId = action.payload;
      state.isLoadingUpdateTech = false;
    },
    closeUpdateTechUser(state) {
      state.editTechId = null;
      state.isLoadingUpdateTech = false;
    },
    updateTechUser(state, action: PayloadAction<FormData>) {
      state.isLoadingUpdateTech = true;
    },
    updateTechUserSuccess(state, action: PayloadAction<UserModel>) {
      let index = state.users.data.findIndex(x => x.id == action.payload.id);
      if (index != -1) {
        state.users.data[index] = action.payload;
      }
      state.isLoadingUpdateTech = false;
    },
    updateTechUserFailure(state) {
      state.isLoadingUpdateTech = false;
    },

    // Update worker
    showUpdateWorkerUser(state, action: PayloadAction<string>) {
      state.editWorkerId = action.payload;
      state.isLoadingUpdateWorker = false;
    },
    closeUpdateWorkerUser(state) {
      state.editWorkerId = null;
      state.isLoadingUpdateWorker = false;
    },
    updateWorkerUser(state, action: PayloadAction<FormData>) {
      state.isLoadingUpdateWorker = true;
    },
    updateWorkerUserSuccess(state, action: PayloadAction<UserModel>) {
      let index = state.users.data.findIndex(x => x.id == action.payload.id);
      if (index != -1) {
        state.users.data[index] = action.payload;
      }
      state.isLoadingUpdateWorker = false;
    },
    updateWorkerUserFailure(state) {
      state.isLoadingUpdateWorker = false;
    },
  }
});

// Actions
export const userActions = userSlice.actions;

// Selectors
//#region User
export const selectUserData = (state: RootState) => state.user.users;
export const selectUserModalData = (state: RootState) => state.user.userModalData;
export const selectLoadingGetUser = (state: RootState) => state.user.loadingGetUser;
export const selectLoadingCreateUser = (state: RootState) => state.user.isLoadingCreateUser;
export const createdUserSuccess = (state: RootState) => state.user.isCreatedSuccessUser;
export const selectLoadingUserModal = (state: RootState) => state.user.isLoadingModal;
export const selectActionUserModal = (state: RootState) => state.user.openingActionUserModal;

// Active user
export const selectLoadingActiveUser = (state: RootState) => state.user.isLoadingActiveUser;
// #region Admin
export const selectAllAdmin = (state: RootState) => state.user.admins;

//#region Nationality
export const selectNationalityData = (state: RootState) => state.user.nationalities;
//#region Skills
export const selectSkillData = (state: RootState) => state.user.skills;
//#region City
export const selectCityData = (state: RootState) => state.user.cities;
export const selectLoadingGetCity = (state: RootState) => state.user.loadingGetCity;
//#region Teams
export const selectTeamData = (state: RootState) => state.user.teams;
//#region Dispatcher
export const selectDispatcherData = (state: RootState) => state.user.linkedDispatcher;
//#region Dispatch center
export const selectDispatchCenterData = (state: RootState) => state.user.dispatchCenters;
//#region Dispatch center
export const selectBusNotLinkedDriverData = (state: RootState) => state.user.busesNotLinkedDriver;
//#region Driver
export const selectDriverData = (state: RootState) => state.user.drivers;
// operation hours and map
export const selectOperationHoursAndMapData = (state: RootState) => state.user.operationHoursAndMap;

export const selectAdminIdEdit = (state: RootState) => state.user.editAdminId;
export const selectTechIdEdit = (state: RootState) => state.user.editTechId;
export const selectWorkerIdEdit = (state: RootState) => state.user.editWorkerId;
export const selectActiveUserSuccess = (state: RootState) => state.user.isActiveUserSuccess;
export const selectWorkers = (state: RootState) => state.user.workers;
export const selectLoadingWorkers = (state: RootState) => state.user.isLoadingGetWorker;

//#endregion

// Reducer
const userReducer = userSlice.reducer;
export default userReducer;