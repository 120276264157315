import {TimeSlotPackage} from 'app/utils/common/models'
import {KTSVG} from 'app/utils/helpers'
import {minuteToTime} from 'app/utils/services'
import moment from 'moment'
import {Col, Row, Container} from 'react-bootstrap-v5'
import {useTranslation} from 'react-i18next'
import {DateTimeVisitType} from './type'
import {Languages, locale} from 'app/utils/common/constants'

const DateTimeVisit = (props: DateTimeVisitType) => {
  const {
    isLoading,
    isMulti,
    isEmpty,
    lang,
    dateTimeOptions,
    onSelectTimeSlot,
    onClearSelected,
  } = props
  const {t} = useTranslation()

  const selected = isMulti ? dateTimeOptions?.filter((s) => !s.related) : dateTimeOptions

  const renderTimeSlots = (date: Date, timeSlots: TimeSlotPackage[]) => {
    if (timeSlots.length > 0) {
      let morningSlots: TimeSlotPackage[] = []
      let eveningSlots: TimeSlotPackage[] = []

      timeSlots.forEach((el, index) => {
        if (el.start >= 300 && el.start < 960) {
          //Morning Shift 5am --> 4pm
          morningSlots.push({...el, rawIndex: index})
        } else if (el.start < 300 || el.start >= 960) {
          // Night Shift 4pm --> 5am (of the next day)
          eveningSlots.push({...el, rawIndex: index})
        }
      })
      return (
        <div className='time-slot-list'>
          {morningSlots.length > 0 && (
            <div className='morning-shift--time-slot'>
              {morningSlots.map((e, index) => (
                <div className='slot-item--wrapper'>
                  <button
                    key={e.rawIndex}
                    type='button'
                    className={`time-slot-item ${
                      !e.isAvailable ? 'disabled' : e.isSelected ? 'active' : ''
                    } `}
                    onClick={() =>
                      e.rawIndex !== undefined &&  e.isAvailable &&
                      onSelectTimeSlot(date, timeSlots[e.rawIndex])
                    }
                  >
                    {!e.isAvailable && (
                      <div className='disabled-line'>
                        <KTSVG defaultColor={true} path='/assets/images/icons/line.svg' />
                      </div>
                    )}
                    <span className='shift-icon'>
                      <KTSVG defaultColor={true} path='/assets/images/icons/morning.svg' />
                    </span>
                    <span className='time-slot-content'>
                      {`${minuteToTime(
                        e.visitReminderStart || e.start,
                        true,
                        true,
                        lang
                      )} - ${minuteToTime(e.visitReminderEnd || e.end, true, true, lang)}`}
                    </span>
                  </button>
                </div>
              ))}
            </div>
          )}
          {eveningSlots.length > 0 && (
            <div
              className={`${
                lang !== Languages.ar ? 'evening-shift--time-slot' : 'evening-shift--time-slot isRtl'
              }`}
            >
              {eveningSlots.map((e, index) => (
                <div className='slot-item--wrapper'>
                  <button
                    key={e.rawIndex}
                    type='button'
                    className={`time-slot-item ${
                      !e.isAvailable ? 'disabled' : e.isSelected ? 'active' : ''
                    } `}
                    onClick={() =>
                      e.rawIndex !== undefined && e.isAvailable &&
                      onSelectTimeSlot(date, timeSlots[e.rawIndex])
                    }
                  >
                    {!e.isAvailable && (
                      <div className='disabled-line'>
                        <KTSVG defaultColor={true} path='/assets/images/icons/line.svg' />
                      </div>
                    )}
                    <span className='shift-icon'>
                      <KTSVG defaultColor={true} path='/assets/images/icons/evening.svg' />
                    </span>
                    <span className='time-slot-content'>
                      {`${minuteToTime(
                        e.visitReminderStart || e.start,
                        true,
                        true,
                        lang
                      )} - ${minuteToTime(e.visitReminderEnd || e.end, true, true, lang)}`}
                    </span>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )
    }
    return (
      <div className='d-flex h-100 justify-content-center align-items-center p-3'>
        <span>{t('CREATE_ORDER_DRAWER.SECTION_3.VALID.EMPTY_TIME_SLOT')}</span>
      </div>
    )
  }

  return (
    <Container className='package-time-table'>
      <Row className='package-table-header'>
        <Col xs={4} className={`date-title ${lang === Languages.ar ? 'isRTL' : ''}`}>
          {t('CREATE_ORDER_DRAWER.SECTION_3.SELECTED_DATE')}
        </Col>
        <Col xs={8} className='time-title'>
          {t('CREATE_ORDER_DRAWER.SECTION_3.SELECTED_TIME')}
        </Col>
      </Row>
      <div className='package-table-body'>
        {isLoading ? (
          <div className='d-flex h-100 justify-content-center align-items-center'>
            <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
          </div>
        ) : isEmpty ? (
          <div className='d-flex h-100 justify-content-center align-items-center p-3'>
            <span>{t('CREATE_ORDER_DRAWER.SECTION_3.VALID.EMPTY_TIME_SLOT')}</span>
          </div>
        ) : (
          <>
            {selected.map((datetime, index) => (
              <Row className='package-table-row'>
                <Col xs={4} className={`selected-item--wrapper ${lang === Languages.ar ? 'isRTL' : ''}`}>
                  <div className='selected-item' key={index}>
                    <div className='date-item'>
                      <div className={datetime.related ? 'item-wrapper--related' : 'item-wrapper'}>
                        <div className='item-selected'>
                          <span>
                            {datetime.date &&
                              moment(datetime.date).locale(locale.EN).format('MM/DD/YYYY')}
                          </span>
                          <div className='button-wrapper'>
                            {!isLoading && !datetime.related && (
                              <button
                                type='button'
                                onClick={() => onClearSelected(new Date(datetime.date))}
                              >
                                <i className='fa fa-times'></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={8} className={`timeslot-items--wrapper ${lang === Languages.ar ? 'isRTL' : ''}`}>
                  {datetime.timeSlot && renderTimeSlots(new Date(datetime.date), datetime.timeSlot)}
                </Col>
              </Row>
            ))}
          </>
        )}
      </div>
    </Container>
  )
}

export default DateTimeVisit
