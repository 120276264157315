import '../operation-hours/style.scss';

import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, InputGroup, Row, Tab, Tabs } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import { SELECT_TIME_IN_WEEK, TAB_OPERATION_HOURS, TYPE_OPERATION_HOURS } from '../../../../../../utils/common/constants';
import { SelectFilter } from '../../../../../../utils/common/models';
import { TimeInWeek, UserFormModel, ValueItemTimeInWeek } from '../../../../models';
import { OperationHoursAndMap } from '../../../../models/OperationHoursAndMap';
import ManagementTimeInWeek from '../../component/management-time-in-week/ManagementTime';

type Props = {
  className?: string,
  values?: UserFormModel,
  rtl?: boolean,
  step?: number,
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  operationHoursAndMap: OperationHoursAndMap[],
  currentCheckCustomStep: number,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setTouched: (value: FormikTouched<UserFormModel>, shouldValidate?: boolean) => void
}

const TechOperationHours: React.FC<Props> = ({
  rtl,
  values,
  operationHoursAndMap,
  setFieldValue,
  errors,
}) => {
  const { t } = useTranslation()
  const [tabSelected, setTabSelected] = useState("regular")

  const onUpdateValueRegularHours = (data: TimeInWeek[]) => {
    setFieldValue('regularHours', data)
  }

  const onUpdateValueRamadanHours = (data: TimeInWeek[]) => {
    setFieldValue('ramadanHours', data)
  }

  const handleChangeTab = (eventKey: string | null, e: React.SyntheticEvent<unknown>) => {
    setTabSelected(eventKey ?? "regular"); 
  }
  return (
    <div className='box-step-2'>
      <h3 className='mt-5 mb-5'>{t('USERS_PAGE.CONTENT_STEP.STEP4.TITLE_TECH_OPERATION_HOURS')}</h3>
      {errors.regularHours ? <div className='small text-danger'>{errors.regularHours}</div> : null}
      <Row>
        <Col sm={12} className='manage-hours'>
          <Tabs
            defaultActiveKey={tabSelected}
            id="uncontrolled-tab-example"
            className="mb-5"
            onSelect={handleChangeTab}
          >
            <Tab eventKey="regular" title={t('USERS_PAGE.STEP_UPDATE_USER.REGULAR_HOURS')} className='mt-10'>
              <ManagementTimeInWeek
                timeInWeek={values?.regularHours || []}
                isRamadan={false}
                rtl={rtl}
                operationHoursAndMap={operationHoursAndMap}
                updateValueOperationHours={onUpdateValueRegularHours}
              />
            </Tab>
            <Tab eventKey="ramadan" title={t('USERS_PAGE.STEP_UPDATE_USER.RAMADAN_HOURS')} className='mt-10'>
              <ManagementTimeInWeek
                timeInWeek={values?.ramadanHours || []}
                isRamadan
                rtl={rtl}
                operationHoursAndMap={operationHoursAndMap}
                updateValueOperationHours={onUpdateValueRamadanHours}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>


    </div>
  )
}

export default TechOperationHours;