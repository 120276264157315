import { MarketModel } from './../models/Market';
import { CommerceRequest, CommerceModel } from './../models/Commerce';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { all, call, put, takeEvery, takeLatest, takeMaybe } from 'redux-saga/effects';

import { ListResponse, Response } from '../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../../setup/toast/ToastConfig';
import { CASE_RELOAD_FILTER_STATISTIC, PAGE_SIZE_SHIFT_TICKET, RELOAD_NEW_TICKET_SECTION_TYPE, SHIFT_TICKET, TicketStatus } from '../../../utils/common/constants';
import {
    AcknowTicketModel,
    OrderTableModel,
    SearchOrderRequest,
    SpTeamModel,
    TicketFlagRequest,
    TicketModel,
    TimeLineModel,
    OrderStatistic,
    OrderStatisticRequest,
    TicketCardTableModel,
    TagsModel
} from '../models';
import { ticketApi } from './TicketApi';
import { ticketActions } from './TicketSlice';
import { NewTicketCardRequest } from '../models/NewTicketCardModel';
import { SearchTicketDailyCalenderRequest } from '../models/TicketCalenderSection';
import { FilterStatistic, FilterStatisticRequest } from '../models/TicketFilter';
import { AttendanceDeliveryActionRequest, CentroOrderSourceModel, TicketAttendanceSheetModel, TicketAttendanceSheetNoBusModel, TicketAttendanceSheetRequest, TicketSubscriptionModel } from '../models/TicketAttendanceDelivery';

function* fetchAllSPTeam() {
    try {
        const response: Response<SpTeamModel[]> = yield call(ticketApi.getAllSPTeam);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.fetchDataSpTeamSuccess(response.data));
        } else {
            toast.error(response.message, ToastDefaultConfig());
            yield put(ticketActions.fetchDataSpTeamFailed());
        }
    } catch (error: any) {
        yield put(ticketActions.fetchDataSpTeamFailed());
    }
}

function* fetchDataAcknow() {
    try {
        const response: Response<AcknowTicketModel[]> = yield call(ticketApi.getTicketAcknow);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.setAcknowTicketData(response.data));
        } else {
            // toast.error(response.message, ToastDefaultConfig());
        }
    } catch (error: any) {
        yield put(ticketActions.fetchDataSpTeamFailed());
    }
}

function* acknowTicket(action: PayloadAction<string>) {
    try {
        const response: Response<boolean> = yield call(ticketApi.acknowTicket, action.payload);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.acknowTicketComplete());
        } else {
            toast.error(response.message, ToastDefaultConfig());
        }
    } catch (error: any) {
        yield put(ticketActions.acknowTicketError());
    }
}

function* getTicketFlag(action: PayloadAction<TicketFlagRequest>) {
    try {
        const response: ListResponse<TicketModel> = yield call(ticketApi.getTicketFlag, action.payload);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.getFlagSuccess(response));
        } else {
            yield put(ticketActions.getFlagFalse());
            // toast.error(response.message, ToastDefaultConfig());
        }
    } catch (error: any) {
        yield put(ticketActions.getFlagFalse());
    }

}

function* addEventTicketRead(action: PayloadAction<TicketModel>) {
    try {
        if (action.payload.id && action.payload.status.startsWith(TicketStatus.Inprogress)) {
            const response: Response<boolean> = yield call(ticketApi.addEventTicketRead, action.payload.id);
            if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                yield put(ticketActions.addEventTicketReadSuccess({ id: action.payload.id, visitTime: action.payload.scheduledVisitDateTime }));
            } else {
                // toast.error(response.message, ToastDefaultConfig());
            }
        }
    } catch (error: any) { }
}

function* getCommerceData(action: PayloadAction<CommerceRequest>) {
    try {
        const response: ListResponse<CommerceModel> = yield call(ticketApi.getCommerceData, action.payload);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.getCommerceSuccess(response));
        } else {
            yield put(ticketActions.getCommerceFalse());
            // toast.error(response.message, ToastDefaultConfig());
        }
    } catch (error: any) {
        yield put(ticketActions.getCommerceFalse());
    }
}

function* getMarketOrders() {
    try {
        const response: Response<MarketModel[]> = yield call(ticketApi.getMarketOrder);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.getMarketOrdersSuccess(response.data));
        } else {
            yield put(ticketActions.getMarketOrdersError());
            // toast.error(response.message, ToastDefaultConfig());
        }
    } catch (error: any) {
        yield put(ticketActions.getMarketOrdersError());
    }
}

function* getTimeLineInOrderModal(action: PayloadAction<TicketModel>) {
    try {
        const response: Response<TimeLineModel[]> = yield call(ticketApi.getTimeLine, action.payload.id);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.getTimeLineInOrderModalSuccess(response.data));
        }
    } catch (error: any) {
        yield put(ticketActions.getTimeLineInOrderModalFail())
    }
}

function* fetchOrderStatisticData(action: PayloadAction<OrderStatisticRequest>) {
    try {
        const response: Response<OrderStatistic> = yield call(ticketApi.fetchOrderStatisticData, action.payload);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.fetchOrderStatisticTodaySuccess(response.data));
        }
    } catch (error: any) {
        yield put(ticketActions.fetchOrderStatisticTodayFail())
    }
}

function* fetchAllNumberOfWorkerData() {
    try {
        const response: Response<number[]> = yield call(ticketApi.fetchAllNumberOfWorker);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.fetchAllNumberOfWorkerSuccess(response.data));
        }
    } catch (error: any) {
        yield put(ticketActions.fetchAllNumberOfWorkerFail())
    }
}


function* fetchNewTicketCardData(action: PayloadAction<{ request: NewTicketCardRequest, isAutoRefresh: boolean }>) {
    try {
        const response: Response<OrderTableModel> = yield call(ticketApi.fetchNewTicketCardData, action.payload.request);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.fetchNewTicketCardDataSuccess(response.data));
        }
    } catch (error: any) {
        yield put(ticketActions.fetchNewTicketCardFailed());
    }
}

function* searchDailyCalenderTicket(action: PayloadAction<{ request: SearchTicketDailyCalenderRequest, isAutoRefresh: boolean }>) {
    try {
        if (action.payload.isAutoRefresh && action.payload.request.pageIndex > 1) {
            for (let index = 1; index <= action.payload.request.pageIndex; index++) {
                let request = { ...action.payload.request };
                request.pageIndex = index;
                const response: Response<TicketCardTableModel> = yield call(ticketApi.searchDailyCalenderTicket, request);
                if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                    switch (action.payload.request.shift) {
                        case SHIFT_TICKET.MORNING:
                            yield put(ticketActions.searchShiftMorningTicketSuccess({ datas: response.data, isAutoRefresh: true, pageIndex: index }));
                            break;
                        case SHIFT_TICKET.AFTERNOON:
                            yield put(ticketActions.searchShiftAfternoonTicketSuccess({ datas: response.data, isAutoRefresh: true, pageIndex: index }));
                            break;
                        default:
                            yield put(ticketActions.searchShiftNightTicketSuccess({ datas: response.data, isAutoRefresh: true, pageIndex: index }));
                            break;
                    }
                }
            }
        } else {
            const response: Response<TicketCardTableModel> = yield call(ticketApi.searchDailyCalenderTicket, action.payload.request);
            if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
                switch (action.payload.request.shift) {
                    case SHIFT_TICKET.MORNING:
                        yield put(ticketActions.searchShiftMorningTicketSuccess({ datas: response.data, isAutoRefresh: null, pageIndex: null }));
                        break;
                    case SHIFT_TICKET.AFTERNOON:
                        yield put(ticketActions.searchShiftAfternoonTicketSuccess({ datas: response.data, isAutoRefresh: null, pageIndex: null }));
                        break;
                    default:
                        yield put(ticketActions.searchShiftNightTicketSuccess({ datas: response.data, isAutoRefresh: null, pageIndex: null }));
                        break;
                }
            }
        }
    } catch (error: any) {
        yield put(ticketActions.searchDailyCalenderTicketFailed(action.payload.request));
    }
}

function* fetchAllFilterStatisticData(action: PayloadAction<FilterStatisticRequest>) {
    try {
        const response: Response<FilterStatistic> = yield call(ticketApi.fetchAllFilterStatistic, action.payload);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.fetchAllFilterStatisticDataSuccess(response.data));
        }
    } catch (error: any) {
        yield put(ticketActions.fetchAllFilterStatisticDataFail());
    }
}

function* reloadOrderStatistic(action: PayloadAction<{ caseUpdate: number, timeRange: FilterStatisticRequest }>) {
    try {
        yield put(ticketActions.fetchAllFilterStatisticData(action.payload.timeRange));
        switch (action.payload.caseUpdate) {
            case CASE_RELOAD_FILTER_STATISTIC.CREATE:
            case CASE_RELOAD_FILTER_STATISTIC.DISPUTE_IN_ACKNOW_MODAL:
                yield put(ticketActions.reloadNewTicketSection(RELOAD_NEW_TICKET_SECTION_TYPE.DEFAULT));
                break;
            default:
                break;
        }
    } catch (error) {

    }
}

function* getCustomerTags(action: PayloadAction<{customer_id: string}>) {
    try {
        const response: Response<TagsModel[]> = yield call(ticketApi.getCustomerTags, action.payload.customer_id);
        if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.getCustomerTagsSuccess(response.data));
        }
    } catch (error: any) {
        yield put(ticketActions.getCustomerTagsFail);
    }
}

function* getAttendanceDelivery(action: PayloadAction<TicketAttendanceSheetRequest>) {
    try {
        const resp: Response<TicketAttendanceSheetModel[]> = yield call(ticketApi.getAttendanceDelivery, action.payload);

        if (resp.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            const data = resp.data;
            // yield put(ticketActions.getAttendanceDeliverySuccess(resp.data));
            yield put(ticketActions.getAttendanceDeliverySuccess(data));
        } else {
            yield put(ticketActions.getAttendanceDeliveryFail());
        }
    } catch (error) {
        yield put(ticketActions.getAttendanceDeliveryFail());
    }
}

function* updateStatusTicketSubscription(action: PayloadAction<AttendanceDeliveryActionRequest>) {
    try {
        const resp: Response<any> = yield call(ticketApi.updateStatusTicketSubscription, action.payload);

        if (resp.status === HTTP_RESPONSE_STATUS.SUCCESS && resp.data) {
            yield put(ticketActions.updateStatusTicketSubscriptionSuccess(action.payload));
        } else {
            yield put(ticketActions.updateStatusTicketSubscriptionFail());
        }
    } catch (error) {
        yield put(ticketActions.updateStatusTicketSubscriptionFail());
    }
}

function* getTicketSubscription(action: PayloadAction<{ticketId: string}>) {
    try {
        const resp: Response<TicketSubscriptionModel[]> = yield call(ticketApi.getTicketSubscription, action.payload);
        if (resp.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            yield put(ticketActions.getTicketSubscriptionSuccess(resp.data));
        } else {
            yield put(ticketActions.getTicketSubscriptionFail());
        }
    } catch (error) {
        yield put(ticketActions.getTicketSubscriptionFail());
    }
}

function* getCentroOrderSource(action: PayloadAction<{id: number | string}>) {
    try {
        const resp: Response<CentroOrderSourceModel[]> = yield call(ticketApi.getCentroOrderSource, action.payload);

        if (resp.status === HTTP_RESPONSE_STATUS.SUCCESS) {
            const data = resp.data;
            yield put(ticketActions.getCentroOrderSourceSuccess(data));
        }
    } catch (error) {
        yield put(ticketActions.getCentroOrderSourceFail());
    }
}

export default function* ticketSaga() {
    yield takeLatest(ticketActions.fetchSpTeamData, fetchAllSPTeam);
    yield takeLatest(ticketActions.fetchDataAcknow, fetchDataAcknow);
    yield takeLatest(ticketActions.acknowTicket, acknowTicket);
    yield takeLatest(ticketActions.getFlag, getTicketFlag);
    yield takeLatest(ticketActions.setOpeningViewOrderModal, addEventTicketRead);
    yield takeLatest(ticketActions.getCommerce, getCommerceData);
    yield takeLatest(ticketActions.getMarketOrders, getMarketOrders);
    yield takeLatest(ticketActions.getTimeLineInOrderModal, getTimeLineInOrderModal);
    yield takeLatest(ticketActions.fetchOrderStatisticToday, fetchOrderStatisticData);
    yield takeLatest(ticketActions.fetchAllNumberOfWorker, fetchAllNumberOfWorkerData);
    yield takeLatest(ticketActions.fetchNewTicketCardData, fetchNewTicketCardData);
    yield takeEvery(ticketActions.searchDailyCalenderTicket, searchDailyCalenderTicket);
    yield takeLatest(ticketActions.fetchAllFilterStatisticData, fetchAllFilterStatisticData);
    yield takeLatest(ticketActions.reloadOrderStatistic, reloadOrderStatistic);
    yield takeLatest(ticketActions.setOpeningViewPackageModal, addEventTicketRead);
    yield takeLatest(ticketActions.getCustomerTags, getCustomerTags);
	yield takeLatest(ticketActions.getAttendanceDelivery, getAttendanceDelivery);
    yield takeLatest(ticketActions.updateStatusTicketSubcription, updateStatusTicketSubscription);
    yield takeLatest(ticketActions.getTicketSubscription, getTicketSubscription);
    yield takeLatest(ticketActions.getCentroOrderSource, getCentroOrderSource);
}
