import { Field, FieldConfig, FieldProps } from 'formik';
import DatePicker from "react-datepicker";
import './DateTimePickerInline.scss';
import ar from 'date-fns/locale/ar';
import en from 'date-fns/locale/en-US';
import { IPropsDateTimePickerInline } from './type'
import { Languages } from 'app/utils/common/constants'

export const DateTimePickerInline = <isMulti extends boolean = false>(props: IPropsDateTimePickerInline<isMulti>) => {
    const {
        language,
        highlightDates = [],
        isLoading = false,
        selectedDateTime: _selected,
        disableDate = false,
        activeDates = [],
        excludeDates,
        minTime,
        intervalDate,
        intervalWeek,
        customDateClassName,
        //formik
        field,
        value,
        form,

        // onChangeDate,
        onChange,
        ...rest
    } = props as IPropsDateTimePickerInline<isMulti> & FieldProps;

    const locale = language === Languages.ar ? ar : en

    return (
        <>
            <div className='date-picker'>
                <DatePicker
                    allowSameDay
                    locale={locale}
                    calendarStartDay={1}
                    formatWeekDay={nameOfDay => language === Languages.ar ? <>{nameOfDay}</> : (<span style={{ fontSize: '16px' }}>{nameOfDay.substr(0, 1)}</span>)}
                    dateFormatCalendar={'MMM, yyyy'}
                    {...rest}
                    {...(() => {
                        if (disableDate || isLoading) {
                            const now = props.minDate == undefined ? new Date() : new Date(props.minDate.getTime());
                            return {
                                minDate: now,
                                maxDate: now,
                                excludeDates: [now]
                            }
                        } else if (!disableDate && activeDates.length !== 0 && !isLoading) {
                            return {
                                minDate: new Date(activeDates[0].dateCheck),
                                maxDate: new Date(activeDates[activeDates.length - 1].dateCheck),
                                excludeDates: (excludeDates?.map((el) => new Date(el.dateCheck)) || []).concat(activeDates.filter((dt) => !dt.isAvailable).map((el) => new Date(el.dateCheck)))                             
                            }
                        }
                        else
                            return {
                                excludeDates: excludeDates?.map(el => new Date(el.dateCheck)) || []
                            }

                    })()}
                    selected={value && new Date(value) || null}
                    onChange={(date, e) => (onChange && onChange(date, e))
                    }
                    inline
                    highlightDates={highlightDates?.map(s => s.date) || []}
                    dayClassName={customDateClassName}
                />
            </div>
        </>
    )
}
export type IPropsFieldDateTimePickerInline<isMulti extends boolean> = IPropsDateTimePickerInline<isMulti> & FieldConfig
export const FieldDateTimePickerInline = <isMulti extends boolean>(props: IPropsFieldDateTimePickerInline<isMulti>) => <Field component={DateTimePickerInline} {...props} />;