import { CommerceRequest, CommerceModel } from './../models/Commerce'
import axios from 'axios'
import moment from 'moment'

import { ListResponse, Response } from '../../../../setup/axios/HttpResponse'
import { MajorServiceModel, SpServiceAvailable, SpServiceAvailableRequest } from '../../../utils/common/models'
import {
  AvailableTimeRequest,
  AvailableTimeResponse,
  CheckSpJoinOfferResponse,
  SpJoinOfferRequest,
  WarrantyRequest,
} from '../../../utils/common/models/AvailableTime'
import {
  CompleteRequestModel,
  DisputeRequestModel,
  ServiceAvailabilityRequest,
  OrderTableModel,
  ReassignRequestModel,
  SearchOrderRequest,
  SpTeamModel,
  ServiceAvailabilityResponse,
  TicketVisitTimeRequest,
  TicketVisitTimeResponse,
  CreateTicketRequest,
  MinChargeResponse,
  TicketModel,
  DeliveryFeeResponse,
  GetDeliveryFeeRequest,
  TicketFlagRequest,
  RescheduleRequestModel,
  CancelRequestModel,
  TicketPackageDetail,
  NumberOfReschedule,
  WorkersAssigned,
  OrderStatistic,
  OrderStatisticRequest,
  TicketCardTableModel,
  TagsModel,
  PriceDetails,
  CreatePackageSubmitRequest,
  RescheduleTicketRequest,
  UpdatePackageDetailRequest,
  SubscriptionContractsRequest,
  SubscriptionContractsResponse,
} from '../models'
import { AcknowTicketModel } from '../models/AcknowTicket'
import { AcceptMarketRequest, MarketModel, SpTeamMarket, SpTeamMarketRequest } from '../models/Market'
import { API_URL } from '../../../utils/common/constants'
import { NewTicketCardRequest } from '../models/NewTicketCardModel'
import { SearchTicketDailyCalenderRequest } from '../models/TicketCalenderSection'
import { FilterStatistic, FilterStatisticRequest } from '../models/TicketFilter'
import { object2Query } from 'setup/axios/SetupAxios'
import {
  AttendanceDeliveryActionRequest,
  CentroOrderSourceModel,
  TicketAttendanceSheetModel,
  TicketAttendanceSheetNoBusModel,
  TicketAttendanceSheetRequest,
} from '../models/TicketAttendanceDelivery'
import {
  PackageServiceResponse,
  PackageServiceInfoRequest,
  PackageInfo,
} from '../models/OrderDrawerModel'

const ticketApi = {
  getAll(body: SearchOrderRequest): Promise<Response<OrderTableModel>> {
    const url = `${API_URL}/api/v1/tickets/search`
    return axios.post(url, body)
  },
  getAllSPTeam(): Promise<Response<SpTeamModel[]>> {
    const url = `${API_URL}/api/v1/users/get-all-sp-team`
    return axios.get(url, { headers: { 'toast-message': false } })
  },
  getReassignAvailableTime(
    payload: AvailableTimeRequest
  ): Promise<Response<AvailableTimeResponse>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/reassign-available-time?dateCheck=${moment(
      payload.dateCheck
    )
      .locale('en-US')
      .format('L')}&clientTimeZone=${payload.clientTimeZone}&isPreselect=${payload.isPreselect}`
    return axios.get(url)
  },
  disputeTicket(payload: DisputeRequestModel): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/dispute?status=${payload.status}`
    return axios.put(url)
  },
  completeTicket(payload: CompleteRequestModel): Promise<Response<boolean>> {
    var bodyFormData = new FormData()
    bodyFormData.append('ServiceFee', payload.serviceFee.toString())
    bodyFormData.append('Comments', payload.comments || '')

    for (let index = 0; index < payload.parts.length; index++) {
      bodyFormData.append(`Parts[${index}].part`, payload.parts[index].part || '')
      bodyFormData.append(`Parts[${index}].price`, payload.parts[index].price?.toString() || '0')
      bodyFormData.append(
        `Parts[${index}].quantity`,
        payload.parts[index].quantity?.toString() || '0'
      )
    }

    const url = `${API_URL}/api/v1/tickets/${payload.id}/complete`
    return axios.put(url, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  getSpAvailable(payload: SpServiceAvailableRequest): Promise<Response<SpServiceAvailable[]>> {
    const url = `${API_URL}/api/v1/users/get-sp-service-available`
    return axios.post(url, payload)
  },
  reassignTicket(payload: ReassignRequestModel): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/reassign`
    return axios.put(url, payload)
  },
  rescheduleTicket(payload: RescheduleRequestModel): Promise<Response<WorkersAssigned>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/reschedule`
    return axios.put(url, payload)
  },
  cancelPackage(payload: CancelRequestModel): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/cancel`
    return axios.put(url, payload)
  },
  // for online-booking
  getServiceAvailable(
    payload: ServiceAvailabilityRequest
  ): Promise<Response<ServiceAvailabilityResponse>> {
    const url = `${API_URL}/api/v1/services/get-service-available-by-location?lat=${payload.lat}&lng=${payload.lng}&countryId=${payload.countryId}&cityId=${payload.cityId}&clientTimeZone=${payload.clientTimeZone}`
    return axios.get(url)
  },
  // for create-order
  getMainServiceAvailable(
    payload: ServiceAvailabilityRequest,
    isToastMessage: boolean = true
  ): Promise<Response<ServiceAvailabilityResponse>> {
    const url = `${API_URL}/api/v1/services/service-available?lat=${payload.lat}&lng=${payload.lng}&countryId=${payload.countryId}&cityId=${payload.cityId}&clientTimeZone=${payload.clientTimeZone}`
    return axios.get(url, { headers: { 'toast-message': isToastMessage } })
  },
  getPriceSubSubService(payload: {
    cityId: number
    subServiceIds: number[]
    subSubServiceIds: number[]
  }): Promise<Response<PriceDetails[]>> {
    const url = `${API_URL}/api/v1/services/subsub-service/get-price-detail`
    return axios.post(url, payload)
  },
  getDateTimeTicket(payload: TicketVisitTimeRequest): Promise<Response<TicketVisitTimeResponse>> {
    const url = `${API_URL}/api/v1/visit-time/select-time`
    return axios.post(url, payload)
  },
  getMinCharge(payload: MinChargeResponse): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/services/show-mincharge`
    return axios.post(url, payload)
  },
  createTicket(payload: CreateTicketRequest | any): Promise<Response<TicketModel>> {
    const url = `${API_URL}/api/v1/tickets/create`
    return axios.post(url, payload)
  },
  getTicketAcknow(): Promise<Response<AcknowTicketModel[]>> {
    const url = `${API_URL}/api/v1/tickets/acknow`
    return axios.get(url)
  },
  acknowTicket(payload: string): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/tickets/${payload}/acknow`
    return axios.put(url)
  },
  getServiceFee(payload: GetDeliveryFeeRequest): Promise<Response<DeliveryFeeResponse>> {
    const url = `${API_URL}/api/v1/services/get-delivery-fee`
    return axios.post(url, payload)
  },
  getTicketFlag(payload: TicketFlagRequest): Promise<ListResponse<TicketModel>> {
    const url = `${API_URL}/api/v1/ticket-flag?page=${payload.pageIndex}&pageSize=${payload.pageSize}`
    return axios.get(url)
  },
  getPackageAvailable(
    payload: PackageServiceInfoRequest
  ): Promise<Response<PackageServiceResponse>> {
    const url = `${API_URL}/api/v1/packages/get-package-available`
    return axios.post(url, payload)
  },
  addEventTicketRead(payload: string): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/event-log/ticket-read?id=${payload}`
    return axios.post(url)
  },
  getCommerceData(payload: CommerceRequest): Promise<ListResponse<CommerceModel>> {
    let remapKeyword = payload.keyword.replaceAll('#', '%23')
    const url = `${API_URL}/api/v1/tickets/ecommerce-order?keyword=${remapKeyword}&page=${payload.pageIndex}&pageSize=${payload.pageSize}&sort=${payload.sort}`
    return axios.get(url)
  },
  getMarketOrder(): Promise<Response<MarketModel[]>> {
    const url = `${API_URL}/api/v1/tickets/market-ticket`
    return axios.get(url)
  },
  getSpTeamMarket(payload: SpTeamMarketRequest): Promise<Response<SpTeamMarket[]>> {
    const url = `${API_URL}/api/v1/users/get-sp-team-accept-market?ticketId=${payload.ticketId}&clientTimeOffset=${payload.clientTimeOffset}`
    return axios.get(url)
  },
  clearMarketOrder(payload: string): Promise<Response<SpTeamMarket[]>> {
    const url = `${API_URL}/api/v1/tickets/${payload}/clear-market`
    return axios.put(url)
  },
  acceptMarketOrder(payload: AcceptMarketRequest): Promise<Response<TicketModel>> {
    const url = `${API_URL}/api/v1/tickets/${payload.ticketId}/accept-market-ticket`
    return axios.put(url, { spId: payload.spId })
  },
  getPackageDetail(payload: string): Promise<Response<TicketPackageDetail>> {
    const url = `${API_URL}/api/v1/tickets/${payload}/package-details`
    return axios.get(url)
  },
  getNumberOfReschedule(payload: string): Promise<Response<NumberOfReschedule>> {
    const url = `${API_URL}/api/v1/tickets/${payload}/number-of-reschedule`
    return axios.get(url)
  },
  getTimeLine(payload: string): Promise<Response<any>> {
    const url = `${API_URL}/api/v1/tickets/${payload}/event-logs`
    return axios.get(url)
  },
  getWarrantyAvailableTime(
    payload: AvailableTimeRequest
  ): Promise<Response<AvailableTimeResponse>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/warranty-available-time?dateCheck=${moment(
      payload.dateCheck
    )
      .locale('en-US')
      .format('L')}&clientTimeZone=${payload.clientTimeZone}&isPreselect=${payload.isPreselect}`
    return axios.get(url)
  },
  createWarranty(payload: WarrantyRequest): Promise<Response<TicketModel>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}/warranty`
    return axios.post(url, payload)
  },
  checkSpJoinOffer(payload: SpJoinOfferRequest): Promise<Response<CheckSpJoinOfferResponse[]>> {
    const url = `${API_URL}/api/v1/offer/check-sp-join-offer`
    return axios.post(url, payload)
  },
  fetchOrderStatisticData(payload: OrderStatisticRequest): Promise<Response<OrderStatistic>> {
    const url = `${API_URL}/api/v1/tickets/order-statistic?startTime=${payload.startTime}&endTime=${payload.endTime}&clientTimeZone=${payload.clientTimeZone}`
    return axios.get(url)
  },
  fetchAllNumberOfWorker(): Promise<Response<number[]>> {
    const url = `${API_URL}/api/v1/tickets/all-number-of-workers`
    return axios.get(url)
  },
  fetchNewTicketCardData(payload: NewTicketCardRequest): Promise<Response<OrderTableModel>> {
    const url = `${API_URL}/api/v1/tickets/search-new-ticket?pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}&startTime=${payload.startTime}&endTime=${payload.endTime}&clientTimeZone=${payload.clientTimeZone}`
    return axios.get(url, { headers: { 'toast-message': false } })
  },
  searchDailyCalenderTicket(
    payload: SearchTicketDailyCalenderRequest
  ): Promise<Response<TicketCardTableModel>> {
    const url = `${API_URL}/api/v1/tickets/search-daily-calendar-ticket`
    return axios.post(url, payload, { headers: { 'toast-message': false } })
  },
  fetchAllFilterStatistic(payload: FilterStatisticRequest): Promise<Response<FilterStatistic>> {
    const url = `${API_URL}/api/v1/tickets/get-statistic-all?startTime=${moment(payload.startTime)
      .locale('en-US')
      .format('yyyy-MM-DD HH:mm:ss')}&endTime=${moment(payload.endTime)
        .locale('en-US')
        .format('yyyy-MM-DD HH:mm:ss')}&clientTimeZone=${payload.clientTimeZone}`
    return axios.get(url, { headers: { 'toast-message': false } })
  },
  postCustomerTags(payload: Partial<Omit<TagsModel, 'id'>>): Promise<Response<TagsModel>> {
    const url = `${API_URL}/api/v1/user-tag`
    return axios.post(url, payload)
  },
  getCustomerTags(customer_id: string): Promise<Response<TagsModel[]>> {
    const url = `${API_URL}/api/v1/user-tag/get-user-tag-by-user${object2Query(
      { customerId: customer_id },
      true
    )}`
    return axios.get(url)
  },
  getTagsByKeyword(keyword: string): Promise<Response<TagsModel[]>> {
    const url = `${API_URL}/api/v1/user-tag/get-user-tag-by-keyword${object2Query(
      { keyword: keyword },
      true
    )}`
    return axios.get(url)
  },
  packageSubmit(payload: CreatePackageSubmitRequest): Promise<Response<Boolean>> {
    const url = `${API_URL}/api/v1/packages/submit`
    return axios.post(url, payload)
  },
  getAttendanceDeliveryStatistic(payload: {
    startTime: string
    endTime: string
    clientTimeZone: number
  }): Promise<Response<{}>> {
    const url = `${API_URL}/api/v1/tickets/get-statistic-all${object2Query(payload, true)}`
    return axios.get(url)
  },
  getAttendanceDelivery(
    payload: TicketAttendanceSheetRequest
  ): Promise<Response<TicketAttendanceSheetModel[]>> {
    const url = `${API_URL}/api/v1/tickets/get-attendance-sheet-ticket`
    return axios.post(url, payload)
  },
  getAttendanceDeliveryNoBus(payload: {
    startTime: string
    endTime: string
    clientTimeZone: number
  }): Promise<Response<TicketAttendanceSheetNoBusModel[]>> {
    const url = `${API_URL}/api/v1/users/get-worker-no-bus${object2Query(payload, true)}`
    return axios.get(url)
  },
  putTickerStatusSpecificWorker(payload: {
    ticketId: string
    spId: string
    workerIds: string
    status: string
  }): Promise<
    Response<{
      customerId: string
      dateTime: string
      event: string
      id: number
      sourceType: string
      ticketId: string
      ticketStatus: string
      userActionId: string
    }>
  > {
    const url = `${API_URL}/api/v1/tickets/update-status-ticket${object2Query(payload, true)}`
    return axios.put(url)
  },
  putTickerStatusAllWorker(payload: {
    ticketId: string,
    status: string
  }): Promise<Response<{
    customerId: string
    dateTime: string
    event: string
    id: number
    sourceType: string
    ticketId: string
    ticketStatus: string
    userActionId: string
  }>> {
    const url = `${API_URL}/api/v1/tickets/update-all-status-ticket${object2Query(payload, true)}`;
    return axios.put(url);
  },
  getRescheduleTicket(payload: RescheduleTicketRequest): Promise<Response<PackageInfo>> {
    const url = `${API_URL}/api/v1/tickets/get-reschedule-ticket?ticketId=${payload.ticketId}&selectWorkerId=${payload.selectWorkerId}&selectedDateString=${payload.selectedDateString}&shiftType=${payload.shiftType}&clientTimeOffset=${payload.clientTimeOffset}`
    return axios.get(url)
  },
  updatePackageDetail(payload: UpdatePackageDetailRequest): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/tickets/${payload.id}`
    return axios.put(url, payload)
  },
  getSubscriptionContracts(
    payload: SubscriptionContractsRequest
  ): Promise<Response<SubscriptionContractsResponse>> {
    const url = `${API_URL}/api/v1/subscription/search`
    return axios.post(url, payload)
  },
  updateStatusTicketSubscription(payload: AttendanceDeliveryActionRequest) {
    const url = `${API_URL}/api/v1/tickets/update-status-subscription-ticket${object2Query(payload, true)}`;
    return axios.put(url);
  },
  getTicketSubscription(payload: {ticketId: string}) {
    const url = `${API_URL}/api/v1/subscription-ticket-event/get-subscription-ticket-by-ticketid${object2Query(payload, true)}`;
    return axios.get(url);
  }, 
  getCentroOrderSource(payload: {id: number | string}): Promise<Response<CentroOrderSourceModel[]>> {
    const url = `${API_URL}/api/v1/centro-order-source?id=${payload.id}`;
    return axios.get(url);
  }
}

export { ticketApi }
