import { ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { HTTP_RESPONSE_STATUS } from '../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../setup/toast/ToastConfig';
import { CheckStepAccountInfo } from '../../modules/users/models';
import { userApi } from '../../modules/users/redux/UserApi';

export const checkUserInfo = async (values: CheckStepAccountInfo, errorMessage: string) => {
  var response = await userApi.checkExistUserInfo(values);

  if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      if (response.data) {
          toast.error(errorMessage, ToastDefaultConfig());
          return true;
      }
      return false;
  } else {
      toast.error(response.message, ToastDefaultConfig());
      return true;
  }
}