import './style.scss';

import { DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, InputGroup } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  MAP_LIBRARY,
  MapConfig,
  optionsPolygonDefault,
  polygonOptionsDefault,
  REACT_APP_MAP_KEY_API,
} from '../../../../../../utils/common/constants';
import { SelectFilter } from '../../../../../../utils/common/models';
import { _inArrayPolygonDefinitions, _outArrayPolygonDefinitions } from '../../../../../../utils/helpers';
import { City, Coord, UserFormModel } from '../../../../models';
import { OperationHoursAndMap } from '../../../../models/OperationHoursAndMap';
import { Field, FormikErrors } from 'formik';
import { useAppSelector } from '../../../../../../../setup/redux/Hooks';


type Props = {
  rtl?: boolean,
  operationHoursAndMap: OperationHoursAndMap[];
  updateValueMapArea: (value: Coord[]) => void,
  values: UserFormModel,
}

const MapCoverage: React.FC<Props> = ({
  rtl,
  values,
  operationHoursAndMap,
  updateValueMapArea
}) => {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: (REACT_APP_MAP_KEY_API || ""), libraries: MAP_LIBRARY, language: "ar" })
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: MapConfig.Saudi.Lat, lng: MapConfig.Saudi.Lng });
  const [triangleCoords, setTriangleCoords] = useState<Coord[]>([]);
  const [optionSelectCopyMap, setOptionSelectCopyMap] = useState<SelectFilter[]>([]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef<any>(null);
  const listenersRef = useRef<any[]>([]);

  useEffect(() => {
    const listFilter: SelectFilter[] = []
    if (!operationHoursAndMap || operationHoursAndMap.length == 0) setOptionSelectCopyMap([])
    else {
      operationHoursAndMap.forEach((item: OperationHoursAndMap) => {
        listFilter.push({
          value: item.id || '',
          label: rtl ? item.fullNameAr || '' : item.fullNameEn || '',
        })
      })
      setOptionSelectCopyMap(listFilter)
    }
  }, [operationHoursAndMap])

  useEffect(() => {
    if (values.serviceAreaJson) {


      const serviceArea = JSON.parse(values.serviceAreaJson);
      if (!serviceArea) {
        resetData()
        return;
      }

      const geometry: any[] = serviceArea[0].geometry[0];
      if (!geometry || geometry.length == 0) {
        resetData()
        return;
      }

      let polygon = geometry.map(x => { return { lat: x[0], lng: x[1] } as Coord })

      setCenter(polygon[0])
      setTriangleCoords(polygon);
      updateValueMapArea(polygon);
    }

  }, [values?.serviceAreaJson])

  const onMapLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const onEditPolygon = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef?.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setTriangleCoords(nextPath);
      updateValueMapArea(nextPath);
    }
  }, [setTriangleCoords]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path: any = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEditPolygon),
        path.addListener("insert_at", onEditPolygon),
        path.addListener("remove_at", onEditPolygon)
      );
    },
    [onEditPolygon]
  );

  // Clean up refs
  const onUnmountPolygon = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onLoadDraw = (drawingManager: any) => { }

  const onPolygonComplete = (polygon: any) => {
    polygon.setMap(null);
    let shape = _inArrayPolygonDefinitions(polygon);
    let _polygon = _outArrayPolygonDefinitions(shape);
    setTriangleCoords(_polygon);
    updateValueMapArea(_polygon);
  }

  const removeSelectMap = () => {
    setTriangleCoords([]);
    updateValueMapArea([]);
  }

  const resetData = () => {
    setCenter({ lat: MapConfig.Saudi.Lat, lng: MapConfig.Saudi.Lng });
    setTriangleCoords([])
    updateValueMapArea([])
  }

  const changeSelectMapCP = (item: any) => {
    if (!item.value || operationHoursAndMap.length == 0) return
    const operationHoursMap: OperationHoursAndMap | undefined = operationHoursAndMap.find((data) => data.id == item.value) || undefined;
    if (!operationHoursMap) return

    const serviceArea = operationHoursMap.serviceArea && JSON.parse(operationHoursMap.serviceArea);
    if (!serviceArea) {
      resetData()
      return;
    }

    const geometry: any[] = serviceArea[0].geometry[0];
    if (!geometry || geometry.length == 0) {
      resetData()
      return;
    }

    let polygon = geometry.map(x => { return { lat: x[0], lng: x[1] } as Coord })

    setCenter(polygon[0])
    setTriangleCoords(polygon);
    updateValueMapArea(polygon);
  }

  return isLoaded ? (
    <>
      <div className='select-copy-map'>
        <div className='title-select'>{t('USERS_PAGE.CONTENT_STEP.STEP5.TITLE_TECHNICIAN')}</div>
        <InputGroup className='filter-bus-copy'>
          <Select
            placeholder={t('USERS_PAGE.CONTENT_STEP.STEP5.SELECT_TECHNICIAN')}
            className='select-input'
            classNamePrefix='select'
            name='technician'
            options={optionSelectCopyMap}
            onChange={changeSelectMapCP}
          />
        </InputGroup>
      </div>
      <div className='position-relative'>

        <Button size='sm' variant="danger" className={"{px-10 fw-normal position-absolute btn btn-danger z-index-1 bottom-0 m-3" + (rtl ? " end-0": " start-0")} onClick={removeSelectMap}>
          <i className="fas fa-eraser"></i> {t('USERS_PAGE.CONTENT_STEP.STEP5.CLEAR')}
        </Button>


        <GoogleMap
          key={1}
          mapContainerStyle={{
            width: '100%',
            height: '500px',
            borderRadius: '5px',
          }}
          center={center}
          zoom={MapConfig.Zoom}
          onLoad={onMapLoad}
          onUnmount={onUnmount}
          options={{ streetViewControl: true, clickableIcons: true, mapTypeControl: true }}
        >
          <DrawingManager
            onLoad={onLoadDraw}
            onPolygonComplete={onPolygonComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
              polygonOptions: polygonOptionsDefault
            }}
          />
          <Polygon
            onLoad={onLoad}
            onMouseUp={onEditPolygon}
            onDragEnd={onEditPolygon}
            onUnmount={onUnmountPolygon}
            path={triangleCoords}
            editable
            draggable
            options={optionsPolygonDefault}
          />
        </GoogleMap>
      </div>
    </>
  ) : <></>
}

export default MapCoverage;