import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { selectLanguage } from '../../../app/modules/auth';
import { Languages } from '../../../app/utils/common/constants';
import { useAppSelector } from '../../../setup/redux/Hooks';

interface Props {
  event: any,
  enabledPageSize?: boolean
  enabledPaginate?: boolean
  enabledStat?: boolean
}
function getNumberOfPages(rowCount: any, rowsPerPage: any) {
  return Math.ceil(rowCount / rowsPerPage);
}

export const B8akPagination = (props: Props) => {
  const { event, enabledPageSize = true, enabledPaginate = true, enabledStat = true } = props
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;

  const handlePageSize = (e: any) => {
    event.onChangeRowsPerPage(Number(e.target.value));
  };

  const pages = getNumberOfPages(event.rowCount, event.rowsPerPage);
  let currentItems = event.currentPage * event.rowsPerPage <= event.rowCount ? event.currentPage * event.rowsPerPage : event.rowCount;

  const handlePageClick = (eventPaging: any) => {
    event.onChangePage(Number(eventPaging.selected + 1));
  };

  const { t } = useTranslation();
  return (
    <div className='d-flex justify-content-between mt-10 mb-5' >
      <div className='d-flex'>
        {enabledPageSize && <select className='form-select page-size' value={event.rowsPerPage} onChange={handlePageSize}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
        </select>}
        {enabledPaginate && <ReactPaginate
          className='pagination'
          pageClassName="page-item"
          previousClassName="nav-item"
          nextClassName="nav-item"
          breakClassName="break-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          breakLabel="..."
          nextLabel={rtlMode ? <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4715 4.53007C10.7318 4.26826 10.7318 3.84378 10.4715 3.58198C10.2111 3.32017 9.789 3.32017 9.52865 3.58198L5.52864 7.60438C5.27626 7.85818 5.26743 8.26685 5.50861 8.53144L9.17528 12.5538C9.42408 12.8268 9.84579 12.8452 10.1172 12.595C10.3886 12.3448 10.4069 11.9208 10.1582 11.6478L6.92281 8.09859L10.4715 4.53007Z" fill="#7E8299" />
          </svg>
            : <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5286 4.53007C5.26825 4.26826 5.26825 3.84378 5.5286 3.58198C5.78895 3.32017 6.21106 3.32017 6.47141 3.58198L10.4714 7.60438C10.7238 7.85818 10.7326 8.26685 10.4914 8.53144L6.82477 12.5538C6.57598 12.8268 6.15427 12.8452 5.88285 12.595C5.61144 12.3448 5.59311 11.9208 5.8419 11.6478L9.07725 8.09859L5.5286 4.53007Z" fill="#7E8299" />
            </svg>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pages}
          previousLabel={rtlMode ? <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5286 4.53007C5.26825 4.26826 5.26825 3.84378 5.5286 3.58198C5.78895 3.32017 6.21106 3.32017 6.47141 3.58198L10.4714 7.60438C10.7238 7.85818 10.7326 8.26685 10.4914 8.53144L6.82477 12.5538C6.57598 12.8268 6.15427 12.8452 5.88285 12.595C5.61144 12.3448 5.59311 11.9208 5.8419 11.6478L9.07725 8.09859L5.5286 4.53007Z" fill="#7E8299" />
          </svg>
            : <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.4715 4.53007C10.7318 4.26826 10.7318 3.84378 10.4715 3.58198C10.2111 3.32017 9.789 3.32017 9.52865 3.58198L5.52864 7.60438C5.27626 7.85818 5.26743 8.26685 5.50861 8.53144L9.17528 12.5538C9.42408 12.8268 9.84579 12.8452 10.1172 12.595C10.3886 12.3448 10.4069 11.9208 10.1582 11.6478L6.92281 8.09859L10.4715 4.53007Z" fill="#7E8299" />
            </svg>
          }
          forcePage={event.currentPage - 1}
        />}
      </div>
      {enabledStat && <div>
        {event.currentPage > 1 ? (event.currentPage - 1) * event.rowsPerPage : 1} - {currentItems} {t('PAGINATION.OF')} {event.rowCount} {t('PAGINATION.ITEMS')}
      </div>}
    </div>

  );
};