export const orderSort = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const SortButtonData = [
  {
    title: 'SUBSCRIPTION_CONTRACTS.CUSTOMER_NAME',
    value: 'customerName',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.PHONE_NUMBER',
    value: 'phoneNumber',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.WORKER_NAME',
    value: 'workerName',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.CONTRACT_LENGTH',
    value: 'contractLength',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.START_DATE',
    value: 'startDate',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.END_DATE',
    value: 'endDate',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.COST',
    value: 'cost',
  },
  {
    title: 'SUBSCRIPTION_CONTRACTS.REMAINING_DAYS',
    value: 'remainingDays',
  },
] as {title: string; value: string}[]

export const ticketStatus = {
  TO_BE_ASSIGNED: 'to be assigned',
  IN_PROGRESS: 'in progress',
  COMPLETED_RATED: 'completed',
  COMPLETED: 'completed',
  DISPUTED_CUSTOMER: 'canceled',
  DISPUTED_SP: 'canceled',
  SP_UNDELIVERED: 'undelivered',
  CANCELED: 'canceled',
  UNDELIVERED_NO_FEMALE: 'no female present',
  UNDELIVERED_CUSTOMER_NOT_REACHABLE: 'customer not reachable or unavailable',
  UNDELIVERED_OTHER: 'other',
  DISPUTED: 'disputed',
}
