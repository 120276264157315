import { Field, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { Col, FormControl, InputGroup, Row } from "react-bootstrap-v5";
import { useTranslation } from "react-i18next";
import { ServiceTreeNode } from "../../../../utils/common/models";
import { KTSVG } from "../../../../utils/helpers";
import { convertServiceToTreeUserModal } from "../../../../utils/services/userService";
import { ServiceScopeModel } from "../../../auth/models/AuthModel";
import { TreeServiceUser } from "../../../users/modals/create-user/component";
import { UserFormModel } from "../../../users/models";
import { OnlineBookingFormModel } from "../../models";
import './OnlineBookingLink.scss'

type Props = {
    rtl?: boolean,
    errors?: FormikErrors<OnlineBookingFormModel>,
    values?: OnlineBookingFormModel,
    services?: ServiceScopeModel,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    onlineBookingDataEdit: OnlineBookingFormModel,
}

const OnlineBookingLink: React.FC<Props> = ({ rtl, errors, values, services, setFieldValue }) => {
    const { t } = useTranslation();

    const [serviceFormatTreeData, setServiceFormatTreeData] = useState<ServiceTreeNode[]>([]);
    const [selectService, setSelectedService] = useState<ServiceTreeNode[]>([]);

    useEffect(() => {
        var serviceTree = convertServiceToTreeUserModal(services, rtl, t('USERS_PAGE.VIEW_USER_MODAL.SEARCH_SERVICE_NULL'));
        if (values?.listServiceId) {
            let subsubSelected = [] as ServiceTreeNode[];
            serviceTree.forEach(major => {
              if (!major.children || major.children.length == 0) return;
              major.children?.forEach(sub => {
                if (!sub.children || sub.children.length == 0) return;
                sub.children.forEach(subSub => {
                  subSub.checked = subSub.subSubId ? values.listServiceId?.includes(subSub.subSubId) : false;
                  if (subSub.checked) {
                    subsubSelected.push(subSub);
                  }
                })
              })
            });
            setSelectedService(subsubSelected);
            setFieldValue('listService', subsubSelected);
        }
        setServiceFormatTreeData(serviceTree);
        if (!values?.listServiceId) {
            setSelectedService([]);
        }
    }, [services, values?.listServiceId]);

    useEffect(() => {
        if (!serviceFormatTreeData || serviceFormatTreeData.length == 0) return;
        let dataTmp = clearCheckTreeData();
        dataTmp.forEach(major => {
            if (!major.children || major.children.length == 0) return;
            major.children?.forEach(sub => {
                if (!sub.children || sub.children.length == 0) return;
                sub.children.forEach(subSub => {
                    const index = selectService.findIndex(service => major.majorId == service.majorId && sub.subId == service.subId && service.subSubId == subSub.subSubId);
                    if (index >= 0) subSub.checked = true;
                    else subSub.checked = false;
                })
            })
        });
        setServiceFormatTreeData(dataTmp);
    }, [selectService]);

    const onSelectedSubSubService = (selectedNodes: ServiceTreeNode[]) => {
        setSelectedService(selectedNodes);
        setFieldValue('listService', selectedNodes);
    }

    const clearCheckTreeData = () => {
        let dataTmp = [...serviceFormatTreeData];
        dataTmp.forEach(major => {
            if (!major.children || major.children.length == 0) return;
            major.children?.forEach(sub => {
                if (!sub.children || sub.children.length == 0) return;
                sub.children.forEach(subSub => {
                    subSub.checked = true;
                })
            })
        });
        return dataTmp;
    }
    return <div
        data-kt-stepper-element='content'
        className='current'
    >
        <div className='box-step-2'>
            <Row className='flex-root step-1 m-auto my-10'>
                <div>
                    <Col sm={8} className='mt-8'>
                        <h6>{t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.TITLE_LINK')}</h6>
                        <InputGroup className=''>
                            <InputGroup.Text id='basic-addon1'>
                                <KTSVG defaultColor={true} path="/assets/images/icons/globe.svg" />
                            </InputGroup.Text>
                            <Field
                                className='form-control h-50px'
                                name='title'
                                type="text"
                                placeholder={t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.PLACEHOLDER_TITLE')}
                            />
                        </InputGroup>

                        {errors?.title ? (
                            <div className='small text-danger'>{errors?.title}</div>
                        ) : null}
                    </Col>
                    {/* Select Services */}
                    <Col sm={12} className='mt-8'>
                        <h6>{t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.TITLE_SERVICE')}</h6>

                        <TreeServiceUser data={serviceFormatTreeData} onChange={setServiceFormatTreeData} onSelectedSubSubService={onSelectedSubSubService} />

                        {values?.listService.length === 0 ? (
                            <div className='small text-danger'>{t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.VALIDATE.SERVICE_REQUIRED')}</div>
                        ) : null}
                    </Col>
                    <Col sm={12} className='mt-8'>
                        <div className='pb-4'>
                            <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle' style={{ width: 'max-content' }}>
                                <input className='form-check-input  mx-2' type='checkbox' checked={values?.isActive} name="isActive" onChange={(event) => setFieldValue('isActive', event.target.checked)}/>
                                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{t('USERS_PAGE.CONTENT_STEP.STEP4.ACTIVE')}</span>
                            </label>
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    </div>
}
export default OnlineBookingLink;