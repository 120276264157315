import { selectLanguage } from 'app/modules/auth'
import { FLOW_TYPE, Languages } from 'app/utils/common/constants'
import { SpServiceAvailable } from 'app/utils/common/models'
import { KTSVG } from 'app/utils/helpers'
import { FieldRadioGroup, TextInput } from 'app/utils/helpers/components/Form'
import { FormikErrors, FormikHelpers } from 'formik'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'setup/redux/Hooks'
import { Worker } from '../../models/OrderDrawerModel'
import { ticketApi } from '../../redux/TicketApi'
import AccordionItem from './AccordionItem'
import { IForm } from './type'

const WorkerComponent = (props: {
    values: IForm,
    errors: FormikErrors<IForm>,
    setFieldValue: FormikHelpers<IForm>['setFieldValue']
    setFieldTouched: FormikHelpers<IForm>['setFieldTouched'],
    setValues: FormikHelpers<IForm>['setValues']
    rtl?: boolean;
    language: string,
    index?: number;
}) => {
    const { index = 0 } = props
    const user = useAppSelector(s => s.auth.user)
    const { t } = useTranslation()
    const ref = useRef({
        timeout: 0
    })

    const { values, rtl = false, setValues, setFieldValue, setFieldTouched, language } = props;
    const { flow_type, worker_available, number_of_workers, preSelectSP } = values

    const isOffer = values.service_selected?.[0]?.type == 'offer'

    const [state, setState] = useState<{
        textSearch: string
    }>({ textSearch: '' })

    const trans = useMemo(() => {
        return flow_type === FLOW_TYPE.PACKAGE ? {
            title: `${index}. ${t('CREATE_ORDER_DRAWER.SECTION_4.TITLE')}`,
            section_title: t('CREATE_ORDER_DRAWER.SECTION_4.SUB_TITLE'),
            search_placeholder: t('CREATE_ORDER_DRAWER.SECTION_4.SEARCH_WORKER_PLACEHOLDER')
        }
            : {
                title: `${index}. ${t('CREATE_ORDER_DRAWER.SECTION_4.TITLE')}`,
                section_title: t('CREATE_ORDER_DRAWER.SECTION_4.SUB_TITLE'),
                search_placeholder: t('CREATE_ORDER_DRAWER.SECTION_4.SEARCH_PROVIDER_PLACEHOLDER')
            }
    }, [flow_type, language])

    useEffect(() => {
        setState(prevState => {
            return {
                ...prevState,
                textSearch: ''
            }
        })
    }, [worker_available])


    const displayedWorkers = useMemo(() => {

        values.worker && setFieldValue('worker', undefined)

        if (flow_type === FLOW_TYPE.PACKAGE) {
            if (values.isOutOfCapacity) {
                return []
            }
            if (worker_available && number_of_workers) {
                const defaultWorkers = [
                    {
                        workerId: '0',
                        workerNameEn: t('CREATE_ORDER_DRAWER.BASE_ON_AVAILABILITY'),
                        workerNameAr: t('CREATE_ORDER_DRAWER.BASE_ON_AVAILABILITY'),
                        startDate: preSelectSP?.dayAvailable.find(day => day.isAvailable)?.dateCheck,
                        isAuto: true,
                        personalImageUrl: '/assets/images/common/unknown-worker.png',
                    },
                ] as Worker[]
                const displayed_workers = number_of_workers > 1 ? defaultWorkers : [...defaultWorkers, ...worker_available]
                return displayed_workers.filter((worker) => 'workerId' in worker && worker.startDate)
            }
            return []
        }
        else {
            return worker_available
        }
    }, [number_of_workers, flow_type, worker_available, values.isOutOfCapacity, preSelectSP])

    useEffect(() => {
        if (flow_type !== FLOW_TYPE.PACKAGE) {
            const spIds = values.date_time && 'time' in values.date_time && values.date_time?.time ? values.date_time?.time?.spIds : [];
            const subIds = isOffer ? [] : values.service_selected?.map(e => e.parentId) || [];
            const subSubIds = isOffer ? [] : values.service_selected?.map(e => e.id) || [];
            const timeout = new Date().getTime();
            ref.current = { ...ref.current, timeout: timeout }
            fetchInfo(timeout, isOffer, subIds, subSubIds, spIds)
        }
        return () => {
            ref.current = { ...ref.current, timeout: new Date().getTime() }
        }
    }, [flow_type, values.date_time && 'time' in values.date_time ? values.date_time?.time : undefined])

    // Function
    const fetchInfo = async (timeout: number, isOffer: boolean, subIds: number[], subSubIds: number[], spIds: string[]) => {
        setValues(values => ({ ...values, loadingType: 'worker' }))
        const spTeams = !isOffer ? [] : (await ticketApi.getAllSPTeam()).data
        const worker_available = isOffer ?
            spIds.map(e => {
                const sp = spTeams.filter(e1 => e1.id == e)[0]
                return {
                    spId: e,
                    fullNameAr: sp?.fullNameAr,
                    fullNameEn: sp?.fullNameEn,
                    isOffer: true,
                    personalImageUrl: sp?.personalImageUrl
                } as SpServiceAvailable
            })
            : spIds.length == 0 || subSubIds.length <= 0 ? []
                : (await ticketApi.getSpAvailable({
                    spIds: spIds,
                    subSubIdsSelected: subSubIds
                })).data.map(e => {
                    let servicesAvailable = e.subSubServiceScopes.filter((x) => x.isAvailable);
                    let servicesUnavailable = e.subSubServiceScopes.filter((x) => !x.isAvailable);
                    e.isAvailableAll = servicesAvailable.length === (values.service_selected?.length || 0);

                    if (rtl) {
                        e.servicesAvailable = servicesAvailable.map((x) => x.subSubNameAr).join(', ');
                        e.servicesUnavailable = servicesUnavailable.map((x) => x.subSubNameAr).join(', ');
                    } else {
                        e.servicesAvailable = servicesAvailable.map((x) => x.subSubNameEn).join(', ');
                        e.servicesUnavailable = servicesUnavailable.map((x) => x.subSubNameEn).join(', ');
                    }
                    return e;
                })
        if (timeout !== ref.current.timeout) return;
        setValues(values => ({ ...values, worker_available, worker: undefined, loadingType: undefined }))
    }

    const handleFavoriteWorker = (worker: SpServiceAvailable | Worker) => {
        if ('workerId' in worker) {
            const index = values.favWorkerIds.indexOf(worker.workerId)
            const new_favWorkerIds = index === -1 ? [...values.favWorkerIds, worker.workerId] : [...values.favWorkerIds.slice(0, index), ...values.favWorkerIds.slice(index + 1)]
            setFieldValue('favWorkerIds', new_favWorkerIds)
        }
    }

    const checkNotFoundSearch = () => {
        if (!state.textSearch) return false;
        if (values.worker_available?.length) {
            let index = values.worker_available.findIndex(e => {
                let name = 'fullNameEn' in e ? (language === Languages.ar ? e.fullNameAr : e.fullNameEn) : (language === Languages.ar ? e.workerNameAr : e.workerNameEn);
                return name.toLowerCase().indexOf(state.textSearch.toLowerCase()) >= 0;
            })
            if (index != -1) return false;
        }
        return true;
    }

    const isLoading = values.loadingType === 'worker';
    return (
        <AccordionItem id={'worker'} title={trans.title}>
            <div className='worker-section'>
                <p className='section-title'>{trans.section_title}</p>
                {
                    isLoading ?
                        <div className="d-flex h-100 justify-content-center align-items-center">
                            <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                        </div>
                        :
                        !displayedWorkers.length ? undefined : <>
                            <TextInput
                                placeholder={trans.search_placeholder}
                                inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/search-small.svg' />}
                                onChange={(e) => setState({ ...state, textSearch: e.target.value || '' })}
                            />
                            {checkNotFoundSearch() && <div className="invalid-message">{t("CREATE_ORDER_DRAWER.SECTION_4.SEARCH_SP_NOT_FOUND")}</div>}
                            <FieldRadioGroup
                                name={'worker'}
                                options={displayedWorkers || []}
                                optionKey={(e) => !('workerId' in e) ? e.spId : e.workerId}
                                renderRadio={({ Radio, onChange }) => (
                                    <div className='worker-list mt-5'>
                                        {Object.entries(Radio)
                                            .map(([, radio]) => ({ ...radio, label: !('workerId' in radio.item) ? (language === Languages.ar ? radio.item.fullNameAr : radio.item.fullNameEn) : (language === Languages.ar ? radio.item.workerNameAr : radio.item.workerNameEn) }))
                                            .filter(e => state.textSearch.trim() === '' || e.label?.toLowerCase().indexOf(state.textSearch.toLowerCase()) >= 0)
                                            .map((e, index) => (
                                                <div className='worker-item' key={index} onClick={() => onChange(e.value)}>
                                                    <img
                                                        className='avatar'
                                                        alt=''
                                                        src={e.item.personalImageUrl || user?.companyLogo}
                                                    />
                                                    <div className='info ms-2'>
                                                        <span className='name'>{e.label}</span>
                                                        {'workerId' in e.item && <span className='start-date'>{t('CREATE_ORDER_DRAWER.SECTION_4.START_DATE') + ': ' + moment(e.item.startDate || new Date()).locale(language === Languages.ar ? 'ar-SA' : 'en-US').format('DD/MM/YYYY')}</span>}
                                                        {!('workerId' in e.item) && !isOffer && <>
                                                            {e.item.isAvailableAll ?
                                                                <span className='fs-6 text-success'>{t('CREATE_ORDER_DRAWER.SECTION_4.DO_ALL')}</span>
                                                                : <>
                                                                    {e.item.servicesAvailable && <span className='fs-6 text-success'>
                                                                        {t('CREATE_ORDER_DRAWER.SECTION_4.CAN_DO')} {e.item.servicesAvailable}

                                                                    </span>
                                                                    }

                                                                    {e.item.servicesUnavailable && <span className='fs-6 text-danger'>
                                                                        {t('CREATE_ORDER_DRAWER.SECTION_4.CANT_DO')} {e.item.servicesUnavailable}
                                                                    </span>
                                                                    }
                                                                </>
                                                            }
                                                        </>}
                                                        {!('workerId' in e.item) && (e.item.isSpJoinOffer === false) && <div className='text-danger'>{t('REASSIGN_MODAL.ERROR_NOT_SUBSCRIBED')}</div>}
                                                    </div>

                                                    {'workerId' in e.item && !e.item.isAuto && (
                                                        <div
                                                            className='favorite'
                                                            onClick={(event) => {
                                                                event.stopPropagation()
                                                                handleFavoriteWorker(e.item)
                                                            }}
                                                        >
                                                            <div className='fav-icon-wrap'>
                                                                <span className={`fav-icon ${values.favWorkerIds.includes(e.item.workerId) && 'favorited'}`}></span>
                                                            </div>
                                                            <span>{t('CREATE_ORDER_DRAWER.SECTION_4.FAVORITE_WORKER')}</span>
                                                        </div>
                                                    )}
                                                    <div className='radio-check'>{e.component}</div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            />
                        </>}
            </div>
        </AccordionItem >
    )
}

export default WorkerComponent
