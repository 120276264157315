import React from 'react';
import { useAppSelector } from '../../../../setup/redux/Hooks';
import { selectReportLink } from '../../../../app/modules/auth';

const Report = () => {
    const reportLink = useAppSelector(selectReportLink);

    return (
        <iframe title="emdad - Visit Detail" width="100%" height="100%" src={reportLink} frameBorder={0} allowFullScreen={true}></iframe>
    )
}

export default Report