import { selectCityData } from 'app/modules/users/redux/UserSlice';
import { clientTimeZoneOffset } from 'app/utils/common/constants';
import { FieldFilterChoose } from 'app/utils/helpers/components/Form/FilterChoose';
import { FieldFilterDateTimePicker } from 'app/utils/helpers/components/Form/FilterDateTimePicker';
import { Formik, FormikProps } from 'formik';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, } from 'react-i18next';
import { useAppSelector } from 'setup/redux/Hooks';
import * as Yup from 'yup';
import { SpTeamModel } from '../../models';
import { TicketAttendanceSheetRequest } from '../../models/TicketAttendanceDelivery';
import { FilterStatistic } from '../../models/TicketFilter';
import { ticketApi } from '../../redux/TicketApi';
import { selectAttendanceDeliveryData, selectParamSearchAttendanceDelivery } from '../../redux/TicketSlice';
import { getTypeStatus, mapItemAttendanceDelivery, mapStatusFilter } from '../AttendanceDelivery';

export interface IProps {
    onFetch?: (data: TicketAttendanceSheetRequest) => void
    rtl?: boolean,
}

type IOption = { id: string | number, label: string }
type IFormFilter = {
    status: IOption[],
    date: Date,
    bus: IOption[],
    cities: IOption[]
}
const defaultValue = {
    status: [],
    date: new Date(),
    // date: undefined,
    bus: [],
    cities: []
} as IFormFilter

export const FilterComponent = (props: IProps) => {
    const { onFetch, rtl = false } = props;
    const refForm = useRef<FormikProps<IFormFilter>>()
    const isLoading = useAppSelector(s => s.ticket.isLoadingAttendanceDelivery)
    const params = useAppSelector(selectParamSearchAttendanceDelivery);
    const cities = useAppSelector(selectCityData);
    const data = useAppSelector(selectAttendanceDeliveryData);
    const { t } = useTranslation()

    const [state, setState] = useState<{
        isLoading: boolean
        spTeam: SpTeamModel[],
        statistic?: FilterStatistic,
        currentStatisticDate?: Date
    }>({
        isLoading: true,
        spTeam: []
    })

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        refForm.current?.setFieldValue('date', params.dateSearch)
        // refForm.current?.setValues(params)
    }, [params.dateSearch])

    const validator = Yup.object().shape({
    });

    const initialTouched = {

    }

    const init = async () => {
        const today = new Date();
        let spTeam = (await ticketApi.getAllSPTeam().catch(e => ({ data: undefined }))).data || [];
        // const statistic = (await ticketApi.fetchAllFilterStatistic({
        //     clientTimeZone: clientTimeZoneOffset,
        //     startTime: today.format('yyyy-MM-DD 00:00:00'),
        //     endTime: today.format('yyyy-MM-DD 23:59:59'),
        // }).catch(e => ({ data: undefined }))).data;


        setState(s => ({ ...s, spTeam, /* statistic, */ isLoading: false, currentStatisticDate: today }))

    }

    const onSubmit = async (data: IFormFilter) => {
        // let statistic: FilterStatistic | undefined;
        // if (state.currentStatisticDate == undefined || !data.date.isSame(state.currentStatisticDate) || true) {
        //     // statistic = (await ticketApi.fetchAllFilterStatistic({
        //     //     clientTimeZone: clientTimeZoneOffset,
        //     //     startTime: data.date.format('yyyy-MM-DD 00:00:00'),
        //     //     endTime: data.date.format('yyyy-MM-DD 23:59:59'),
        //     // }).catch(e => ({ data: undefined }))).data;

        //     ticketApi.fetchAllFilterStatistic({
        //         clientTimeZone: clientTimeZoneOffset,
        //         startTime: data.date.format('yyyy-MM-DD 00:00:00'),
        //         endTime: data.date.format('yyyy-MM-DD 23:59:59'),
        //     }).then((resp) => {
        //         setState(s => ({ ...s, currentStatisticDate: data.date, ...resp.data }));
        //     })
        // }
        // setState(s => ({ ...s, currentStatisticDate: data.date, ...statistic != undefined && { statistic } }));

        const params = {
            cityIds: data.cities.map(e => `1.${e.id}`),
            clientTimeZone: clientTimeZoneOffset,
            dateSearch: data.date,
            spIds: data.bus.map(e => String(e.id)),
            status: data.status.map(e => e.id)
        } as TicketAttendanceSheetRequest
        console.log(params)
        onFetch && onFetch(params);
    }

    const dataMap = data.map(e => mapItemAttendanceDelivery(e, data));

    return (
        <div className='filter-section'>
            <Formik<IFormFilter>
                initialValues={defaultValue}
                validationSchema={validator}
                initialTouched={initialTouched}
                validateOnChange
                onSubmit={onSubmit}
            >
                {(props) => {
                    refForm.current = props;
                    return (
                        <>
                            <div className="filter-item">
                                <p className='label-filter fw-semibold'>{t('ATTENDANCE_DELIVERY_PAGE.FILTER.STATUS')}</p>
                                <div className="input-filter">
                                    <FieldFilterChoose
                                        name='status'
                                        placeholder={t('ATTENDANCE_DELIVERY_PAGE.FILTER.PLACEHOLDER_STATUS')}
                                        options={mapStatusFilter.map((e) => ({
                                            id: e.status,
                                            label: rtl ? t(e.labelAr) : e.labelEn,
                                            isActive: true,
                                            // badge: state.statistic?.statusStatistics.filter(e1 => mapStatusFilter[k as keyof typeof mapStatusFilter].status.indexOf(e1.status) >= 0).reduce((total: number | undefined, e) => (total === undefined && (e.total === undefined || e.total < 0)) ? undefined : (e.total + (total || 0)), undefined)
                                            badge: dataMap.filter(e1 =>
                                                e.type == undefined ?
                                                    (e1.statusAttendance == undefined && e1.statusDelivery == undefined && e1.spId != '-1')
                                                    : (Array.isArray(e.group) ? e.group : [e.group]).includes(e.type == 'attendance' ? (e1.statusDelivery != undefined ? false as any : e1.statusAttendance) : e1.statusDelivery)
                                            ).length
                                        }))}
                                        optionKey={e => e.id}
                                        optionLabel={e => e.label}
                                        height={220}
                                        label={{
                                            all: t('ATTENDANCE_DELIVERY_PAGE.FILTER.ALL'),
                                            noData: t('ATTENDANCE_DELIVERY_PAGE.TABLE.NO_DATA'),
                                            notMatch: t('ATTENDANCE_DELIVERY_PAGE.NOT_MATCH')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-item">
                                <p className='label-filter fw-semibold'>{t('ATTENDANCE_DELIVERY_PAGE.FILTER.DATE')}</p>
                                <div className="input-filter">
                                    <FieldFilterDateTimePicker
                                        name='date'
                                        rtl={rtl}
                                    />
                                </div>
                            </div>
                            <div className="filter-item">
                                <p className='label-filter fw-semibold'>{t('ATTENDANCE_DELIVERY_PAGE.FILTER.BUS')}</p>
                                <div className="input-filter">
                                    <FieldFilterChoose
                                        name='bus'
                                        placeholder={t('ATTENDANCE_DELIVERY_PAGE.FILTER.PLACEHOLDER_BUS')}
                                        height={180}
                                        // isMulti={false}
                                        optionKey={e => e.label}
                                        optionLabel={e => e.label}
                                        options={state.spTeam.sort((a, b) => (b.isActive ? 1 : 0) - (a.isActive ? 1 : 0)).map(e => ({
                                            id: e.id,
                                            label: rtl ? e.fullNameAr : e.fullNameEn,
                                            isActive: e.isActive,
                                            // badge: state.statistic?.busStatistics.find(e1 => e.id == e1.spId)?.total
                                            badge: dataMap.filter((e1, index, arr) => e1.spId == e.id && arr.findIndex(e2 => e2.id == e1.id) == index).map(e => e.id).length
                                        }))}
                                        label={{
                                            all: t('ATTENDANCE_DELIVERY_PAGE.FILTER.ALL'),
                                            noData: t('ATTENDANCE_DELIVERY_PAGE.TABLE.NO_DATA'),
                                            notMatch: t('ATTENDANCE_DELIVERY_PAGE.NOT_MATCH')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filter-item">
                                <p className='label-filter fw-semibold'>{t('ATTENDANCE_DELIVERY_PAGE.FILTER.CITY')}</p>
                                <div className="input-filter">
                                    <FieldFilterChoose
                                        height={250}
                                        name='cities'
                                        placeholder={t('ATTENDANCE_DELIVERY_PAGE.FILTER.PLACEHOLDER_CITY')}
                                        options={cities.map(e => ({
                                            id: e.id,
                                            label: rtl ? e.nameAr : e.nameEn,
                                            isActive: true,
                                            // badge: state.statistic?.cityStatistics.find(e1 => e.id == String(e1.cityId)?.split('.')?.[1])?.total
                                            badge: dataMap.filter((e1, index, arr) => e.id == String(e1.cityId)?.split('.')?.[1] && arr.findIndex(e2 => e2.id == e1.id) == index).length
                                        }))}
                                        optionKey={e => e.id}
                                        optionLabel={e => e.label}
                                        label={{
                                            all: t('ATTENDANCE_DELIVERY_PAGE.FILTER.ALL'),
                                            noData: t('ATTENDANCE_DELIVERY_PAGE.TABLE.NO_DATA'),
                                            notMatch: t('ATTENDANCE_DELIVERY_PAGE.NOT_MATCH')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='nav-form d-flex flex-column mt-8'>
                                <button disabled={isLoading} className="" type='submit' onClick={() => props.handleSubmit()}>
                                    {
                                        isLoading ?
                                            <>
                                                {t('ATTENDANCE_DELIVERY_PAGE.FILTER.BTN_FILTER')}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </>
                                            : t('ATTENDANCE_DELIVERY_PAGE.FILTER.BTN_FILTER')
                                    }
                                </button>
                                <button disabled={isLoading} className="" type='reset' onClick={() => {
                                    props.resetForm()
                                    setTimeout(() => {
                                        props.handleSubmit()
                                    }, 200);
                                }}>
                                    {
                                        isLoading ?
                                            <>
                                                {t('ATTENDANCE_DELIVERY_PAGE.FILTER.BTN_CLEAR')}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </>
                                            : t('ATTENDANCE_DELIVERY_PAGE.FILTER.BTN_CLEAR')
                                    }
                                </button>
                            </div>
                        </>
                    )
                }}
            </Formik>
        </div>
    )
}