import { Field, FieldConfig, FieldProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { _debounce, _sleep } from 'app/utils/helpers/extendMethod';
import { TextInput, IPropsTextInput } from './index';
import './TagsInputInline.scss'
import { t, use } from 'i18next'

export interface IPropsTagsInputInline<TParams, T> extends IPropsTextInput {
    label?: string,
    values?: T[],
    suggest?: T[],
    onAddTags: (tags: string) => T | Promise<T>
    onFetch?: (params: { page: number, pageSize: number, textSearch: string } & TParams) => { results: T[], total: number } | Promise<{ results: T[], total: number }>,
    tagKey?: (e: T, index?: number) => number | string
    tagLabel?: (e: T, index?: number) => string,
    pageInit?: number,
    pageSize?: number,
    searchMaxlength?: number,
    params?: TParams
}
export const TagsInputInline = <TParams, T,>(props: IPropsTagsInputInline<TParams, T>) => {
    const {
        label,
        values,
        suggest = [],
        onAddTags,
        onFetch: _onFetch,
        tagKey = (e, index) => index || 0,
        tagLabel = (e, index) => 'Item-' + ((index || 0) + 1),
        pageInit = 0,
        searchMaxlength = 50,
        pageSize: _pageSize = 10,
        params: _params,

        field,
        form,

        ...rest
    } = props as IPropsTagsInputInline<TParams, T> & FieldProps;

    const params = useRef<{
        page: number,
        pageSize: number,
        textSearch: string,
        total: number,
    }>({
        page: pageInit,
        pageSize: _pageSize,
        textSearch: '',
        total: 0
    })

    const [state, setState] = useState<{
        text: string,
        results: T[],
        tags: T[],
        isShowSuggest: boolean,
        isLoading: boolean,
        total: number
    }>({
        text: '',
        results: [],
        tags: [],
        isShowSuggest: false,
        isLoading: false,
        total: 0,
    })

    useEffect(() => {
        const tags = ((field !== undefined ? field.value : values) || []) as T[];
        setState(s => ({ ...s, tags }))
    }, [(field?.value as T[] || values || []).map(e => tagKey(e)).join(',')]);

    const removeTags = (v: T) => {
        if (field !== undefined) {
            form.setFieldValue(field.name, state.tags.filter(e => tagKey(e) !== tagKey(v)))
        }
        else {
            setState(s => ({ ...s, tags: state.tags.filter(e => tagKey(e) !== tagKey(v)) }))
        }
    }

    const addTags = async (_tag?: string | T) => {
        const tag = _tag === undefined ? params.current.textSearch.trim() : typeof _tag === 'string' ? _tag : tagLabel(_tag);
        if (tag) {
            if (state.tags.map((e, index) => tagLabel(e, index)).indexOf(tag) >= 0) {
                params.current = { ...params.current, textSearch: '' }
                setState(s => ({ ...s, text: '' }))
            }
            else {
                setState(s => ({ ...s, isLoading: true, text: '' }))
                const t = ((typeof _tag === 'string' || _tag === undefined) ? await onAddTags(tag) : _tag) as T;
                setState(s => ({
                    ...s,
                    isLoading: false,
                    tags: [...s.tags, t]
                }))
                form?.setFieldValue(field.name, [...state.tags, t]);
            }
        }
    }

    const onFocusTextInput = () => {
        setState(s => ({ ...s, isShowSuggest: true }))
        if (params.current.textSearch == '') {
            handleLoadMore(0)
        }
    }

    const onBlurTextInput = async () => {
        await _sleep(200);
        setState(s => ({ ...s, isShowSuggest: false }))
    }

    const onChangeTextInput = (text: string) => {
        params.current = { ...params.current, page: pageInit, total: 0, textSearch: text }
        setState(s => ({ ...s, text }))
        onFetch()
    }

    const onFetch = useCallback(_debounce(async () => {
        if (state.isLoading || (params.current.total !== 0 && state.results.length >= params.current.total)) return

        setState(s => ({ ...s, isLoading: true }));
        const { total, results } = _onFetch === undefined ?
            { total: 0, results: [] }
            : await _onFetch({ page: params.current.page, pageSize: params.current.pageSize, textSearch: params.current.textSearch, ..._params } as any);

        params.current = { ...params.current, total }
        setState(s => ({ ...s, isLoading: false, total, results: params.current.page === pageInit ? results : [...s.results, ...results] }));
    }, 600), [state.isLoading, _params])

    const handleLoadMore = useCallback(_debounce((page?: number) => {
        if (state.isLoading) return
        const _page = page !== undefined ? page : params.current.page + 1;
        params.current = { ...params.current, page: _page };
        onFetch();
    }, 200), [state.isLoading, _params])

    const touched = form?.touched['tags_input'];
    let _error = form?.errors['tags_input'];
    let [error, _validParams = ''] = ![null, undefined, ''].includes(_error as any) ? String(_error).split('-') : [_error, '']
    error = ![null, undefined, ''].includes(error as any) ? t(error as string).format(_validParams.split(';')) : error
    const isInvalid = touched === true && error != undefined

    return (
        <div className='tags-input-inline'>
            <TextInput {...rest}
                onChange={e => onChangeTextInput(e.target.value || '')}
                onKeyUp={(e) => e.keyCode == 13 && addTags()}
                value={state.text}
                onFocus={onFocusTextInput}
                onBlur={onBlurTextInput}
                // disabledInput={state.isLoading}
                maxLength={50}
            />
            {(state.isShowSuggest && state.results.length > 0) &&
                <div className='tags-suggest'>
                    {state.results.map((e, index) => (
                        <button key={index} type='button' className='text-start' onClick={() => addTags(e)}>{tagLabel(e, index)}</button>
                    ))}
                </div>
            }
            <div className='tags-selected'>
                {state.tags.map((e, index) => (
                    <div key={index} className="tags-selected__item ms-3">
                        <span>{tagLabel(e, index)}</span>
                        <button type='button' onClick={() => removeTags(e)}>
                            <i className='fa fa-times'></i>
                        </button>
                    </div>
                ))}
            </div>
            {isInvalid && <div className="invalid-feedback"><>{error}</></div>}
        </div>
    )
}


export type IPropsFieldTagsInputInline<TParams, T> = IPropsTagsInputInline<TParams, T> & FieldConfig
export const FieldTagsInputInline = <TParams, T,>(props: IPropsFieldTagsInputInline<TParams, T>) => <Field component={TagsInputInline} {...props} />;