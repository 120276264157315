import './style.scss'

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikTouched } from 'formik';

import { UserFormModel, Team, City, Coord, TimeInWeek, DispatchCenter, SPInfos } from '../../../../models';
import { USER_TYPE } from '../../../../../../utils/common/constants';
import WorkLocationBus from '../../component/work-location-bus/WorkLocationBus';
import { OperationHoursAndMap } from '../../../../models/OperationHoursAndMap';
import WorkLocationWorker from '../../component/work-location-worker/WorkLocationWorker';
import { SelectFilter } from 'app/utils/common/models';
import _ from 'lodash';

type Props = {
  className?: string,
  values?: UserFormModel,
  rtl?: boolean,
  step?: number,
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  teams: Team[],
  cities: City[],
  currentCheckCustomStep: number,
  operationHoursAndMap: OperationHoursAndMap[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setTouched: (value: FormikTouched<UserFormModel>, shouldValidate?: boolean) => void,
  dispatchCenters?: DispatchCenter[];
}

const WorkLocation: React.FC<Props> = ({
  currentCheckCustomStep, rtl, step, values, touched, errors,
  operationHoursAndMap, setFieldValue, setTouched, cities, teams, dispatchCenters
}) => {

  const disabledDispatchCenter = true;

  const { t } = useTranslation();
  // const linkedSpSelectFilter = dispatchCenters?.map(e => e.spInfos.map(e1 => ({ parentId: e.id, label: rtl ? e1.fullNameAr : e1.fullNameEn, value: e1.spId }))).flat() || []
  const linkedSpSelectFilter = teams.filter(e => e.isActive).map(e => ({ parentId: e.id, label: rtl ? e.fullNameAr : e.fullNameEn, value: e.id }))
  const [optionBus, setOptionBus] = useState<SelectFilter[]>(disabledDispatchCenter ? linkedSpSelectFilter : []);

  // Update value worker
  const updateValueDispatchCenter = (value: SelectFilter[]) => {
    setFieldValue("workerDispatchCenter", value || [])

    const listDispatchCenterId = value.map((item: SelectFilter) => item.value);
    let listBusDis: SelectFilter[] = linkedSpSelectFilter.filter(e => listDispatchCenterId.includes(e.parentId));
    setOptionBus(listBusDis);

    let listBusSelected: SelectFilter[] = values?.listSpLinked?.filter(e => listBusDis.find(e1 => e.value === e1.value)) || []

    setFieldValue("listSpLinked", listBusSelected || []);
  };

  useEffect(() => {
    setOptionBus(linkedSpSelectFilter);
  }, [teams])

  const updateValueSpLinked = (value: SelectFilter[]) => setFieldValue("listSpLinked", value || []);

  const updateValueCity = (value: SelectFilter[]) => setFieldValue("listCity", value || []);

  const updateValueWorkerTravel = (value: boolean) => setFieldValue("workerTravel", value);

  const updateValueActive = (value: boolean) => setFieldValue("isActive", value);
  // End update value worker

  // Update value bus
  const updateValueMapArea = (value: Coord[]) => setFieldValue("serviceArea", value || []);
  // End update value bus

  return (
    <>
      <div className='box-step-2'>
        {/* step type Worker */}
        {values?.userType == USER_TYPE.WORKER && (
          <WorkLocationWorker
            rtl={rtl}
            errors={errors}
            touched={touched}
            updateValueDispatchCenter={updateValueDispatchCenter}
            updateValueSpLinked={updateValueSpLinked}
            updateValueCity={updateValueCity}
            updateValueWorkerTravel={updateValueWorkerTravel}
            updateValueActive={updateValueActive}
            cities={cities}
            values={values}
            dispatchCenters={dispatchCenters}
            optionBus={optionBus}
            disabledDispatchCenter={disabledDispatchCenter}
          />
        )}
        {/* end step type Driver */}

        {/* step type Bus */}
        {values?.userType == USER_TYPE.TECHNICIAN && (
          <WorkLocationBus
            errors={errors}
            rtl={rtl}
            operationHoursAndMap={operationHoursAndMap}
            updateValueMapArea={updateValueMapArea}
            values={values}
          />
        )}
        {/* end step type Bus */}
      </div>
    </>
  )
}

export default WorkLocation;