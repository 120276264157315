import {useState, useMemo} from 'react'
import {selectCurrency, selectServiceScope} from 'app/modules/auth'
import {
  FLOW_TYPE,
  Languages,
  PaymentMethod,
  PaymentStatus,
  SERVICE_TYPE,
  locale,
  DEFAULT_WORKER
} from 'app/utils/common/constants'
import {TimeSlot, TimeSlotPackage} from 'app/utils/common/models'
import {ArrayGroupBy} from 'app/utils/extendMethods'
import {minuteToTime} from 'app/utils/services'
import {FormikErrors, FormikHelpers} from 'formik'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from 'setup/redux/Hooks'
import {Worker} from '../../models/OrderDrawerModel'
import AccordionItem from './AccordionItem'
import {priceSubSubService} from './NeededService'
import {IForm} from './type'

export const SummaryComponent = (props: {
  rtl?: boolean
  language: string
  values: IForm
  errors: FormikErrors<IForm>
  setFieldValue: FormikHelpers<IForm>['setFieldValue']
  setValues: FormikHelpers<IForm>['setValues']
  index?: number
}) => {
  const {values, rtl = false, index = 0, language} = props
  const {t} = useTranslation()
  const flow_type = values.flow_type
  const currency = useAppSelector(selectCurrency)
  const serviceData = useAppSelector(selectServiceScope)
  const user = useAppSelector((s) => s.auth.user)

  const [state, setState] = useState<{showMore: boolean}>({showMore: false})

  const subSubService =
    values.service_selected?.map((e) => {
      const quantity = e.quantity || 0
      const price =
        values.subSubPrices === undefined
          ? undefined
          : priceSubSubService(values.subSubPrices, {id: e.id as number, quantity})
      return {quantity, price}
    }) || []
  const total =
    subSubService.reduce(
      (total, e) =>
        total + (e.price === undefined || typeof e.price === 'string' ? 0 : e.price) * e.quantity,
      0
    ) || 0
  const isUponVisit =
    subSubService.find((e) => e.price === undefined || typeof e.price === 'string') != undefined

  const isOffer = values.service_selected?.[0]?.type === 'offer'
  const isDelivery =
    values.main_service?.type === SERVICE_TYPE.PACKAGE_SERVICE &&
    values.isShowDeliveryTime &&
    !!values.preSelectSP?.visitReminder

  const selected_package =
    values.listPackages && values.listPackages.find((item) => item.id === values.package)

  const defaultWorkers = [
    {
      workerId: DEFAULT_WORKER,
      workerNameEn: t('CREATE_ORDER_DRAWER.BASE_ON_AVAILABILITY'),
      workerNameAr: t('CREATE_ORDER_DRAWER.BASE_ON_AVAILABILITY'),
      startDate:
        values.preSelectSP?.dayAvailable && values.preSelectSP?.dayAvailable.length !== 0
          ? values.preSelectSP.dayAvailable[0].dateCheck
          : new Date(),
      isAuto: true,
      personalImageUrl: '/assets/images/common/unknown-worker.png',
    },
  ] as Worker[]

  const worker_available = [...defaultWorkers, ...values.worker_available]
  const titleObj =
    values.main_service == undefined
      ? undefined
      : values.main_service.type === 'service'
      ? {labelEn: values.main_service?.data?.nameEn, labelAr: values.main_service?.data?.nameAr}
      : values.main_service.type === 'service_offer'
      ? {
          labelEn: t('CREATE_CUSTOMER_MODAL.TITLE_OFFER'),
          labelAr: t('CREATE_CUSTOMER_MODAL.TITLE_OFFER'),
        }
      : {labelEn: values.main_service?.data?.nameEn, labelAr: values.main_service?.data?.nameAr}

  const subTitleObj =
    values.main_service == undefined
      ? undefined
      : values.main_service.type === 'service'
      ? serviceData?.majorServices
          ?.filter((e) => e.id == values.main_service?.id)
          .map((e) => ({labelEn: e.subTitleTextEn, labelAr: e.subTitleTextAr}))[0]
      : values.main_service?.type === 'service_offer'
      ? {
          labelEn: values.main_service?.children[0].data.titleEn,
          labelAr: values.main_service?.children[0].data.titleAr,
        }
      : {labelEn: selected_package?.titleEn, labelAr: selected_package?.titleAr}

  const listVisit = (
    values.date_time == undefined
      ? []
      : Array.isArray(values.date_time)
      ? values.date_time
      : [values.date_time]
  ) as {date: Date; time?: TimeSlot | TimeSlotPackage; customerVisitDuration?: number}[]

  const displayedVisit = useMemo(() => {
    return listVisit.length > 2 ? (state.showMore ? listVisit : listVisit.slice(0, 2)) : listVisit
  }, [state.showMore, listVisit])

  const startDate =
    listVisit.length && listVisit[0].date
      ? moment(listVisit[0].date)
          .startOf('day')
          .locale(language === Languages.ar ? locale.SA : locale.EN)
          .format('DD/MM/YYYY')
      : ''

  const expandTitle = state.showMore
    ? t('CREATE_ORDER_DRAWER.SECTION_5.SHOW_LESS')
    : t('CREATE_ORDER_DRAWER.SECTION_5.SHOW_MORE')

  return (
    <AccordionItem
      id='summary'
      title={`${index}. ${t('CREATE_ORDER_DRAWER.SECTION_5.TITLE')}`}
      className='summary-section'
    >
      {titleObj !== undefined && (
        <div className='package-details'>
          {flow_type === FLOW_TYPE.PACKAGE && (
            <p className='package-title'>{t('CREATE_ORDER_DRAWER.SECTION_5.PACKAGE_DETAILS')}</p>
          )}
          <p className='service fw-bolder p-0'>
            {language === Languages.ar ? titleObj?.labelAr : titleObj?.labelEn}
          </p>
          <p className='visit pb-1'>
            {language === Languages.ar ? subTitleObj?.labelAr : subTitleObj?.labelEn}
          </p>
          <div className='person'>
            {worker_available
              ?.filter(
                (e) =>
                  values.worker !== undefined &&
                  ('spId' in e
                    ? e.spId === values.worker
                    : e.workerId === values.worker && !e.isAuto)
              )
              .map((e, index) =>
                'workerId' in e ? (
                  <div key={index} className='person-item'>
                    <img className='avatar' alt='' src={e.personalImageUrl || user?.companyLogo} />
                    <div className='info ms-2'>
                      <p className='name'>
                        {language === Languages.ar ? e.workerNameAr : e.workerNameEn}
                      </p>
                      <p className='start-date'>{`${t(
                        'CREATE_ORDER_DRAWER.SECTION_5.VISIT_START_DATE'
                      )}: ${startDate}`}</p>
                    </div>
                  </div>
                ) : (
                  <div key={index} className='person-item'>
                    <img className='avatar' alt='' src={e.personalImageUrl || user?.companyLogo} />
                    <div className='info ms-2'>
                      <p className='name'>
                        {language === Languages.ar ? e.fullNameAr : e.fullNameEn}
                      </p>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      )}
      <div className='visit-details'>
        <p className='visit-title'>{t('CREATE_ORDER_DRAWER.SECTION_5.VISITS_DETAILS')}</p>
        <table>
          <thead>
            <tr>
              <th className='py-1 fw-bolder' style={{width: 60}}>
                {t('CREATE_ORDER_DRAWER.SECTION_5.DAY')}
              </th>
              <th className='py-1 fw-bolder text-center' style={{width: 150}}>
                {t('CREATE_ORDER_DRAWER.SECTION_5.DATE')}
              </th>
              <th
                className='py-1 fw-bolder'
                style={
                  {
                    /*  maxWidth: 150  */
                  }
                }
              >
                {t(`CREATE_ORDER_DRAWER.SECTION_5.${isDelivery ? 'DELIVERY_TIME' : 'VISIT_TIME'}`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedVisit.map((e, index) => (
              <tr key={index}>
                <td className='py-2'>
                  {e.date
                    ? moment(e.date)
                        .startOf('day')
                        .locale(language === Languages.ar ? locale.SA : locale.EN)
                        .format('dddd')
                    : ''}
                </td>
                <td className='py-2 text-center'>
                  {(e.time?.start || 0) >= 1440
                    ? e.date
                      ? moment(e.date)
                          .add(1, 'd')
                          .locale(language === Languages.ar ? locale.SA : locale.EN)
                          .format('L')
                      : ''
                    : e.date
                    ? moment(e.date)
                        .locale(language === Languages.ar ? locale.SA : locale.EN)
                        .format('DD/MM/YYYY')
                    : ''}
                </td>
                {flow_type === FLOW_TYPE.PACKAGE && e.time ? (
                  <td className='py-2'>
                    {[
                      'visitReminderStart' in e.time && e.time.visitReminderStart
                        ? minuteToTime(e.time.visitReminderStart, true, true, language)
                        : minuteToTime(e.time.start, true, true, language),
                      'visitReminderEnd' in e.time && e.time.visitReminderEnd
                        ? minuteToTime(e.time.visitReminderEnd, true, true, language)
                        : minuteToTime(e.time.end, true, true, language),
                    ]
                      .filter((e) => e != undefined)
                      .join(' - ')}
                  </td>
                ) : (
                  <td className='py-2'>
                    {[
                      e.time?.start && minuteToTime(e.time.start, true, true, language),
                      e.time?.start &&
                        minuteToTime(
                          e.time?.start + (e.customerVisitDuration || 0),
                          true,
                          true,
                          language
                        ),
                    ]
                      .filter((e) => e != undefined)
                      .join(' - ')}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {listVisit.length > 2 && (
          <button
            type='button'
            className='btn show-more'
            onClick={() => setState({...state, showMore: !state.showMore})}
          >
            {expandTitle}
          </button>
        )}
      </div>
      {false && (
        <div className='discount'>
          {new Array(0).fill(1).map((e, index) => (
            <div key={index} className='discount-item'>
              <span className='discount-label'>{'KSA22 | 50% OFF'}</span>
              <button type='button' className='btn-remove'>
                <svg
                  width='11'
                  height='9'
                  viewBox='0 0 11 9'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.4998 7.40718L7.19498 4.57031L10.4998 1.73344C10.6752 1.58292 10.7737 1.37878 10.7737 1.16591C10.7737 0.95305 10.6752 0.748905 10.4998 0.598387C10.3245 0.44787 10.0866 0.36331 9.83867 0.36331C9.59069 0.363309 9.35287 0.447869 9.17753 0.598387L5.87268 3.43526L2.56784 0.598387C2.39249 0.44787 2.15467 0.363309 1.90669 0.363309C1.65872 0.363309 1.42089 0.447869 1.24555 0.598387C1.0702 0.748904 0.971691 0.95305 0.971691 1.16591C0.971691 1.37878 1.0702 1.58292 1.24555 1.73344L4.55039 4.57031L1.24555 7.40718C1.0702 7.5577 0.971691 7.76185 0.971691 7.97471C0.971691 8.18758 1.0702 8.39172 1.24555 8.54224C1.42089 8.69276 1.65872 8.77732 1.90669 8.77732C2.15467 8.77732 2.39249 8.69276 2.56784 8.54224L5.87268 5.70537L9.17753 8.54224C9.35287 8.69276 9.59069 8.77732 9.83867 8.77732C10.0866 8.77732 10.3245 8.69276 10.4998 8.54224C10.6752 8.39172 10.7737 8.18757 10.7737 7.97471C10.7737 7.76185 10.6752 7.5577 10.4998 7.40718Z'
                    fill='#E96161'
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
      {isOffer ? (
        <div className='offer-selected'>
          {values.service_selected?.map(
            (e, index) =>
              'subTitleTextAr' in e.data && (
                <div key={index} className='item-container'>
                  <div className='selected-item'>
                    <div className='item-header'>
                      <div className='offer-title fw-bold'>{e.label}</div>
                      <p className='offer-sub-title'>
                        {language === Languages.ar ? e.data.subTitleAr : e.data.subTitleEn}
                      </p>
                      <p className='offer-sub-title'>
                        {language === Languages.ar ? e.data.warrantyAr : e.data.warrantyEn}
                      </p>
                    </div>
                    <div className='item-content'>
                      <div
                        className='my-2'
                        dangerouslySetInnerHTML={{
                          __html:
                            (language === Languages.ar
                              ? e.data?.offerDescriptionAr
                              : e.data?.offerDescriptionEn) || '',
                        }}
                      ></div>
                      <div className='d-flex justify-content-between my-2'>
                        <div>{t('CREATE_TICKET_MODAL.OFFER_PERIOD')}:</div>
                        <div>
                          {moment(e.data?.expiredDate)
                            .locale(language === Languages.ar ? locale.SA : locale.EN)
                            .format('MM/DD/YYYY hh:mm A')}
                        </div>
                      </div>

                      <div className='d-flex justify-content-between my-2'>
                        <div>{t('CREATE_ORDER_DRAWER.SECTION_2.OFFER_QUANTITY')}:</div>
                        <div>{e.data?.currentQuantity}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        values.service_selected !== undefined &&
        values.service_selected.length > 0 && (
          <div className='payment-details'>
            <h3 className='payment-title'>{t('CREATE_ORDER_DRAWER.SECTION_5.INVOICE')}</h3>
            <div className='bill'>
              {isOffer ? (
                <></>
              ) : (
                <>
                  {ArrayGroupBy(
                    (values.service_selected || []).map((e) => ({
                      ...e,
                      subLabel: 'data_sub' in e ? e.data_sub.nameEn || '' : '',
                    })),
                    (e) => e.subLabel
                  ).map((e, index) => (
                    <div className='bill-cate' key={index}>
                      <p className='my-3' style={{fontWeight: 600, fontSize: 13}}>
                        {e.by}
                      </p>
                      {e.list.map((e, index) => {
                        const price =
                          values.subSubPrices === undefined ? (
                            <div
                              className='spinner-border spinner-border-sm text-dark'
                              role='status'
                            ></div>
                          ) : (
                            priceSubSubService(values.subSubPrices, {
                              id: e.data.id as number,
                              quantity: e.quantity || 1,
                            })
                          )
                        return (
                          <div className='bill-item mb-5' key={index} style={{fontSize: 12}}>
                            <span className='bill-label'>{`${e.quantity} x ${e.label}`}</span>
                            <span className='bill-price'>
                              {price == undefined
                                ? t('CREATE_TICKET_MODAL.UPON_CHECKUP')
                                : typeof price == 'number'
                                ? `${price} ${currency}`
                                : price}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  ))}
                </>
              )}
              <div className='bill-cate border-item'>
                <div className='bill-item total mb-5 mt-3'>
                  <span className='bill-label fw-bold text-muted'>
                    {t('CREATE_TICKET_MODAL.TOTAL_SERVICE_CHARGE')}
                  </span>
                  <div className='bill-price d-flex align-items-end flex-column text-muted'>
                    <p>{`${total} ${currency}`}</p>
                    {isUponVisit && <p>{`+ ${t('OTHER_UPON_VISIT')}`}</p>}
                  </div>
                </div>
                {/* {(values.fee && values.fee.isActive && values.fee.deliveryFee > 0) &&
                                <div className="bill-item total mb-5">
                                    <span className="bill-label fw-bold text-muted">
                                        {t('CREATE_ORDER_DRAWER.SECTION_5.SPARE_PART_FEE')}
                                    </span>
                                    <div className='bill-price d-flex align-items-end flex-column text-muted'>
                                        {`${values.fee.deliveryFee} ${currency}`}
                                    </div>
                                </div>
                            } */}
                {values.minCharge !== undefined && (
                  <div className='bill-item total mb-5'>
                    <span className='bill-label fw-bold text-muted'>
                      {t('CREATE_TICKET_MODAL.PROVIDER_MIN_CHARGE')}
                    </span>
                    <div className='bill-price d-flex align-items-end flex-column text-muted'>
                      {`${values.minCharge} ${currency}`}
                    </div>
                  </div>
                )}
              </div>

              <div className='bill-cate border-item'>
                <div className='bill-item total mb-5 mt-3'>
                  <span className='bill-label fw-bold'>
                    {t('CREATE_ORDER_DRAWER.SECTION_5.TOTAL_ESTIMATE')}
                  </span>
                  <div className='bill-price d-flex align-items-end flex-column'>
                    <div className=''>
                      {
                        Math.max(
                          total,
                          values.minCharge || 0
                        ) /* + (values.fee?.deliveryFee || 0) */
                      }{' '}
                      {currency}
                    </div>
                    <div>{isUponVisit && <p>{`+ ${t('OTHER_UPON_VISIT')}`}</p>}</div>
                  </div>
                </div>
                <div className='bill-item total'>
                  <span className='bill-label fw-bold'>
                    {t('CREATE_TICKET_MODAL.VAT_NOT_INCLUDED')}
                  </span>
                  <div className='bill-price d-flex align-items-end flex-column'></div>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {values.package && values.payment_method && values.payment_status && (
        <div className='visit-details'>
          <p className='visit-title'>{t('CREATE_ORDER_DRAWER.SECTION_5.PAYMENT_DETAILS')}</p>
          <div className='bill-cate border-item mt-1'>
            <div className='bill-item total d-flex justify-content-between'>
              <span className='bill-label fw-bold text-muted'>
                {t('CREATE_ORDER_DRAWER.SECTION_5.PACKAGE_PRICE')}
              </span>
              <span className='bill-price fw-bold d-flex align-items-end flex-column'>
                {values.without_vat} {currency}
              </span>
            </div>
            {/* <div className="bill-item total d-flex justify-content-between">
                            <span className="bill-label fw-bold text-muted">
                                {'Discount'}
                            </span>
                            <span className='bill-price fw-bold d-flex align-items-end flex-column'>
                                0 {currency}
                            </span>
                        </div>
                        <div className="bill-item total d-flex justify-content-between">
                            <span className="bill-label fw-bold text-muted">
                                {'Package price after discount'}
                            </span>
                            <span className='bill-price fw-bold d-flex align-items-end flex-column'>
                                0 {currency}
                            </span>
                        </div> */}
            {values.preSelectSP?.isShowVat && values.percentVAT && (
              <div className='bill-item total d-flex justify-content-between'>
                <span className='bill-label fw-bold text-muted'>
                  {`${t('COMPLETE_MODAL.VAT')} (${Number(values.percentVAT) * 100}%)`}
                </span>
                <span className='bill-price fw-bold d-flex align-items-end flex-column'>
                  {Number(values.vat).toFixed(2)} {currency}
                </span>
              </div>
            )}
            <div className='bill-item total d-flex justify-content-between'>
              <span className='bill-label fw-bold text-muted'>
                {t('CREATE_ORDER_DRAWER.PAYMENT_METHOD')}
              </span>
              <span className='bill-price fw-bold d-flex align-items-end flex-column'>
                {language === Languages.ar
                  ? PaymentMethod.find((item) => item.value === values.payment_method)?.label_Ar
                  : PaymentMethod.find((item) => item.value === values.payment_method)?.label_En}
              </span>
            </div>
            <div className='bill-item total d-flex justify-content-between'>
              <span className='bill-label fw-bold'>{t('VIEW_TICKET_MODAL.TOTAL')}</span>
              <span className='bill-price fw-bolder d-flex align-items-end flex-column'>
                {values.total_price} {currency}
              </span>
            </div>
            <div className='bill-item total d-flex justify-content-between'>
              <span className='bill-label fw-bold text-muted'>
                {t('CREATE_ORDER_DRAWER.PAYMENT_STATUS')}
              </span>
              <span className='bill-price fw-bold d-flex align-items-end flex-column'>
                {language === Languages.ar
                  ? PaymentStatus.find((item) => item.value === values.payment_status)?.label_Ar
                  : PaymentStatus.find((item) => item.value === values.payment_status)?.label_En}
              </span>
            </div>
          </div>
        </div>
      )}
    </AccordionItem>
  )
}
