// Default Values
export const defaultPackage = {
    preSelectSP: undefined,
    number_of_workers: 0,
    maxWorker: 0,
    total_price: '',
    without_vat: '',
    vat: '',
    percentVAT: 0,
    payment_status: undefined,
    order_source: undefined,
    payment_method: undefined,
    worker: undefined,
    worker_available: [],
    workerConfig: undefined,
    date_time: undefined,
    favWorkerIds: [],
    numberOfWeeks: undefined,
    numberOfVisitPerWeek: undefined,
}

export const defaultMainService = {
    package: undefined,
    listPackages: [],
    ...defaultPackage
}

export const defaultAreaInfo = {
    mapError: undefined,
    backupNumber: '',
    note: '',
    service_type: '',
    flow_type: '',
    service_selected: undefined,
    service_available: undefined,
    main_service: undefined,
    ...defaultMainService
}

export const defaultCustomerInfo = {
    customer_name_handler: '',
    customer_name: '',
    is_b8ak_customer: false,
    location: undefined,
    map: '',
    tags: undefined,
    ...defaultAreaInfo
}

// Default config
export const initialTouched = {
    total_price: true,
    service_selected: false,
    number_of_workers: true,
}

export const initalTouchedPackage = {
    total_price: false,
    number_of_workers: false,
    payment_method: false,
    payment_status: false,
    order_source: false,
    worker: false,
    date_time: false
}

export const initalTouchedMainService = {
    ...initalTouchedPackage,
    package: false
}

export const initalTouchedArea = {
    ...initalTouchedMainService,
    note: false,
    service_selected: false,
}

export const initalTouchedCustomerInfo = {
    ...initalTouchedArea,
    tags: false,
    customer_name: false,
    map: false
}