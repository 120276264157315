import { Field, FieldConfig, FieldProps } from 'formik';
import { classNameFunc, IClassName } from './utils';
import './FilterChoose.scss'
import { TextInput } from './TextInput';
import { useEffect, useState } from 'react';

type TypeOption<T> = T & {
    badge?: number, children?: TypeOption<T>[]
}

export interface IPropsFilterChoose<T, IType> {
    type?: IType,
    options: TypeOption<T>[],
    optionKey?: (e: TypeOption<T>, index?: number) => string | number;
    optionLabel?: (e: TypeOption<T>, index?: number) => string | number;
    className?: IClassName,
    classNameList?: IClassName,
    onChange?: (selected: TypeOption<T>[]) => void;
    height?: number,
    placeholder?: string;
    label?: {
        all?: string,
        noData?: string,
        notMatch?: string
    }
}
export const FilterChoose = <T, IType>(props: IPropsFilterChoose<TypeOption<T>, IType>) => {
    const {
        type = 'checkbox',
        className,
        classNameList,
        options,
        optionKey = (e, index) => index,
        optionLabel = (e, index) => `Option ${(index || 0) + 1}`,
        onChange,
        height,
        field,
        form,
        placeholder,
        label = {
            all: 'All',
            noData: 'No Data',
            notMatch: 'No matches found',
            ...props.label
        }
    } = props as IPropsFilterChoose<TypeOption<T>, IType> & FieldProps;

    type IOption = TypeOption<T> & { selected: boolean, isCollapse?: boolean, indeterminate?: boolean, children: IOption[], isActive?: boolean };
    type IOptionMap = { index: number[], item: IOption }

    const mapItem = (e1: IOption | IOptionMap, index: number[], handleSelected?: boolean): IOptionMap => {
        const isMap = 'index' in e1
        const e = isMap ? e1.item : e1;
        const children = 'children' in e ? e.children?.map((e, i) => mapItem(e as any, [...index, i], handleSelected)) : undefined
        const countSelected = 'children' in e ? children?.filter((e1) => e1.item.selected === true && (e1.item.indeterminate === undefined || e1.item.indeterminate === false) || e1.item.isActive === false).length : undefined
        const countInActive = 'children' in e ? children?.filter((e1) => e1.item.selected === false && e1.item.isActive === false && (e1.item.indeterminate === undefined || e1.item.indeterminate === false)).length : undefined
        const tmpCountInActive = countInActive != undefined && countInActive > 0 ? countInActive : 0;
        const indeterminate = countSelected != undefined && countSelected > tmpCountInActive && countSelected != children?.length;

        return {
            index,
            item: {
                ...e,
                selected: handleSelected !== undefined ? handleSelected : e.selected == undefined ? false : e.selected,
                isCollapse: e.isCollapse == undefined ? false : e.isCollapse,
                ...children != undefined && {
                    children: children,
                    indeterminate: indeterminate,
                    selected: indeterminate || countSelected === children.length
                }
            }
        } as IOptionMap
    }

    const getSelected = (options: IOptionMap[], selected?: number[][]) => {
        selected = selected || [];
        options.map((e: IOptionMap) => {
            if (e.item.selected && (e.item?.isActive === undefined || e.item?.isActive === true) && e.item.children === undefined)
                selected?.push(e.index)
            else if ('children' in e.item)
                getSelected(e.item.children as any, selected)
        });

        return selected;
    }

    const [state, setState] = useState({
        textSearch: '',
        options: [] as IOptionMap[],
        selected: [] as number[][]
    })

    useEffect(() => {
        const _options = options.length > 0 ? [{ children: options, type: 'all', badge: options.reduce((total: number | undefined, e) => (e.badge === undefined && total === undefined) ? undefined : ((e.badge || 0) + (total || 0)), undefined) }] : [];
        let o = _options.map((e, index) => mapItem(e as any, [index]));
        // setState(s => ({ ...s, options: o }))

        const selected = (field?.value || []) as IOption[];
        // let options = [...state.options];

        const setValues = (options: IOptionMap[]) => {
            options.map(e => {
                if ('children' in e.item) {
                    setValues(e.item.children as any)
                }
                else
                    (e as any).item.selected = selected.map((e, index) => optionKey(e, index)).find(k => optionKey(e.item, undefined) == k) != null;
            })
        };

        setValues(o);
        o = o.map((e, index) => mapItem(e, [index]));

        setState(s => ({ ...s, options: o }))
    }, [JSON.stringify(options), field?.value])

    useEffect(() => {

    }, [field?.value])

    const onChangeCheckbox = (checked: boolean, index: number[]) => {
        let options = [...state.options];
        index.reduce((a: IOptionMap[], e, i, arr) => {
            if (i == index.length - 1) {
                if ('children' in a[e].item) {
                    a[e].item.selected = a[e].item.selected === true ? a[e].item.indeterminate === true ? true : false : true;
                    a[e].item.children = a[e].item.children.map((e1: any, index) => mapItem(e1, [index], a[e].item.selected)) as any
                }
                else
                    a[e].item.selected = !a[e].item.selected
            }
            return a[e].item.children as any;
        }, options);

        options = options.map((e, index) => mapItem(e, [index]));
        const selected = getSelected(options);
        const _selected = selected.map(index => {
            return index.reduce((a: any, e, i, arr) => {
                if (i == index.length - 1) {
                    return a[e].item
                }
                return a[e].item.children as any;
            }, options);
        }) as IOption[];
        setState(s => ({ ...s, options, selected }))

        if (form != undefined)
            form.setFieldValue(field.name, _selected)
        else
            onChange && onChange(_selected)
    }

    const onCollapse = (index: number[]) => {
        const options = [...state.options];
        index.reduce((a: IOptionMap[], e, i, arr) => {
            if (i == index.length - 1) {
                a[e].item.isCollapse = !a[e].item.isCollapse
            }
            return a[e].item.children as any;
        }, options);

        setState(s => ({ ...s, options }))
    }

    const renderOptions = (options: IOptionMap[], isCollapse: boolean = false) => {
        return (
            <ul className={classNameFunc(['options-group', isCollapse === true && 'collapsed'])}>
                {
                    options.map((e, i) => {
                        const isAllItem = 'type' in e.item && (e.item as any).type == 'all'
                        return (
                            <li key={[...e.index, i].join(',')} className={classNameFunc([isAllItem && 'all-item'])}>
                                <div className='item-choose'>
                                    <label key={i} className="d-flex">
                                        <input
                                            type={type == 'checkbox' ? 'checkbox' : 'radio'}
                                            disabled={!isAllItem && e.item.isActive === false}
                                            className={classNameFunc([e.item.indeterminate === true && 'indeterminate'])}
                                            onChange={e1 => onChangeCheckbox(e1.target.checked === true, e.index)}
                                            checked={e.item.selected}
                                        />
                                        <span title={isAllItem ? label.all : String(optionLabel(e.item, i))}>{isAllItem ? label.all : optionLabel(e.item, i)}</span>
                                    </label>
                                    {
                                        (isAllItem || e.item.children == undefined) ?
                                            ((e.item.badge != undefined && e.item.badge > 0) && <span className="badge">{e.item.badge}</span>)

                                            : <a
                                                onClick={e1 => {
                                                    e1.stopPropagation();
                                                    e1.isDefaultPrevented();
                                                    onCollapse(e.index)
                                                }}
                                                href="javascript:;"
                                            >
                                                <i className={classNameFunc(['btn-collapse', e.item.isCollapse && 'collapsed'])}>

                                                </i>
                                            </a>
                                    }
                                </div>
                                {e.item.children !== undefined && renderOptions(e.item.children as any, e.item.isCollapse)}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    const renderOptionsWithFilter = (options: IOptionMap[]) => {
        const filterOption = (e: IOptionMap[]): IOptionMap[] => {
            return e.map(e => {
                const isFilter = state.textSearch.trim() == '' || String(optionLabel(e.item)).toLowerCase().indexOf(state.textSearch.trim().toLowerCase()) >= 0;
                const children = 'children' in e.item ? filterOption(e.item.children as any) as any : undefined
                return children == undefined ? (!isFilter ? null : e) : (children.length == 0 ? null : { index: e.index, item: { ...e.item, children: children } })
            }).filter((e): e is IOptionMap => e != null)
        }
        options = filterOption(options);

        return options.length == 0 ? <div>{label.notMatch}</div> : renderOptions(options);
    }

    return (
        <div className={classNameFunc(['d-flex flex-column', 'filter-choose', className])}>
            <TextInput
                classContainer='input-search'
                inputGroup={
                    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M6.86177 9.37293C6.60142 9.11259 6.60142 8.69047 6.86177 8.43013C7.12212 8.16978 7.54423 8.16978 7.80458 8.43013L10.4712 11.0968C10.7316 11.3571 10.7316 11.7793 10.4712 12.0396C10.2109 12.3 9.78878 12.3 9.52843 12.0396L6.86177 9.37293Z" fill="#7E8299" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 5.56828C0 8.14561 2.08934 10.2349 4.66667 10.2349C7.244 10.2349 9.33333 8.14561 9.33333 5.56828C9.33333 2.99095 7.244 0.901611 4.66667 0.901611C2.08934 0.901611 0 2.99095 0 5.56828ZM7.99998 5.56828C7.99998 7.40923 6.50759 8.90161 4.66664 8.90161C2.82569 8.90161 1.33331 7.40923 1.33331 5.56828C1.33331 3.72733 2.82569 2.23495 4.66664 2.23495C6.50759 2.23495 7.99998 3.72733 7.99998 5.56828Z" fill="#7E8299" />
                    </svg>
                }
                placeholder={placeholder}
                onChange={e => setState(s => ({ ...s, textSearch: e.target.value }))}
            />
            <div className={classNameFunc(["choose-container", classNameList])} style={{ ...height != undefined && { height } }}>
                {state.options.length == 0 ? <div>{label.noData}</div> : renderOptionsWithFilter(state.options)}
            </div>
        </div>
    )
}

export type IPropsFieldFilterChoose<T, IType> = IPropsFilterChoose<TypeOption<T>, IType> & FieldConfig
export const FieldFilterChoose = <T, IType>(props: IPropsFieldFilterChoose<TypeOption<T>, IType>) => <Field component={FilterChoose} {...props} />;