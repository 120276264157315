import { selectLanguage } from 'app/modules/auth'
import { Languages } from 'app/utils/common/constants'
import { KTSVG, handleWheelInputNumber } from 'app/utils/helpers'
import React from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'setup/redux/Hooks'
interface Props {
  formik: any,
  handleChangeNumberField: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, needChangeValue?: boolean) => void,
  handleBlurNumberField: React.FocusEventHandler<HTMLInputElement>
}
const AutoDiscountConfig = (props: Props) => {
  const { formik, handleBlurNumberField, handleChangeNumberField } = props
  const { t } = useTranslation()
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar;

  const { handleChange, handleBlur, touched, values } = formik
  return (
    <Row className='create-package-offcanvas--auto-discount-config'>
      <Col xs={12} className="mb-2 p-0">
        <Form.Check
          checked={values.autoDiscount.activeAutoDiscount}
          name='autoDiscount.activeAutoDiscount'
          onBlur={handleBlur}
          onChange={handleChange}
          className={`create-package-offcanvas-switcher${rtlMode ? "--rtl" : ""} create-package-offcanvas-switcher-label-left${rtlMode ? "--rtl" : ""} create-package-offcanvas-switcher--active-auto-discount ps-4`}
          label={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ACTIVE_AUTO_DISCOUNT")}
          type="switch"
        />
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.DISCOUNT_DAYS")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/calendar-check.svg' /></InputGroup.Text>
          <Form.Control key={`autoDiscount.discountDays-${values.autoDiscount.discountDays}`} disabled={!values.autoDiscount.activeAutoDiscount} required min={0} step={1} type={"text"} name='autoDiscount.discountDays' defaultValue={(!touched?.autoDiscount?.discountDays && !Boolean(values?.autoDiscount?.discountDays)) ? undefined : values.autoDiscount.discountDays} onChange={(e) => handleChangeNumberField(e, false)} onBlur={handleBlurNumberField} onWheel={handleWheelInputNumber} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_DISCOUNT_DAYS")} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.DISCOUNT_PERCENTAGE")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/percent.svg' /></InputGroup.Text>
          <Form.Control key={`autoDiscount.activeAutoDiscount-${values.autoDiscount.activeAutoDiscount}`} disabled={!values.autoDiscount.activeAutoDiscount} required min={0} step={1} type={"text"} name='autoDiscount.discountPercent' defaultValue={(!touched?.autoDiscount?.discountPercent && !Boolean(values?.autoDiscount?.discountPercent)) ? undefined : values.autoDiscount.discountPercent} onChange={(e) => handleChangeNumberField(e, false)} onBlur={handleBlurNumberField} onWheel={handleWheelInputNumber} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_DISCOUNT_PERCENTAGE")} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ARABIC_PUSH_NOTIFICATION_TITLE")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/text-indent.svg' /></InputGroup.Text>
          <Form.Control disabled={!values.autoDiscount.activeAutoDiscount} required multiple type="text" name='autoDiscount.arabicPushNotificationTitle' value={values.autoDiscount.arabicPushNotificationTitle} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_ARABIC_PUSH_NOTIFICATION_TITLE") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENGLISH_PUSH_NOTIFICATION_TITLE")}</Form.Label>
          <InputGroup.Text><KTSVG defaultColor={true} path='/assets/images/icons/text-indent.svg' /></InputGroup.Text>
          <Form.Control disabled={!values.autoDiscount.activeAutoDiscount} required multiple type="text" name='autoDiscount.englishPushNotificationTitle' value={values.autoDiscount.englishPushNotificationTitle} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_ENGLISH_PUSH_NOTIFICATION_TITLE") + "*"} className='h-35px' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ARABIC_PUSH_NOTIFICATION_CONTENT")}</Form.Label>
          <Form.Control as="textarea" rows={4} disabled={!values.autoDiscount.activeAutoDiscount} required type="text" name='autoDiscount.arabicPushNotificationDescription' value={values.autoDiscount.arabicPushNotificationDescription} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_ARABIC_PUSH_NOTIFICATION_CONTENT") + "*"} className='h-80px create-package-offcanvas--auto-discount-config--text-input' />
        </InputGroup>
      </Col>
      <Col xs={12} className="mb-2">
        <InputGroup className="mb-3">
          <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENGLISH_PUSH_NOTIFICATION_CONTENT")}</Form.Label>
          <Form.Control as="textarea" rows={4} disabled={!values.autoDiscount.activeAutoDiscount} required type="text" name='autoDiscount.englishPushNotificationDescription' value={values.autoDiscount.englishPushNotificationDescription} onChange={handleChange} onBlur={handleBlur} placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.ENTER_ENGLISH_PUSH_NOTIFICATION_CONTENT") + "*"} className='h-80px create-package-offcanvas--auto-discount-config--text-input' />
        </InputGroup>
      </Col>
    </Row>
  )
}

export default AutoDiscountConfig