import React from 'react';
import { components, OptionProps } from 'react-select'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from 'app/utils/helpers'
import { useTranslation } from 'react-i18next'
interface Props extends OptionProps<{ value: number; label: string; icon: string }> { }
const OptionsFilter: React.FC<Props> = (
  props
) => {
  const { t } = useTranslation()
  return (<><components.Option {...props}>
    <div className='d-flex align-items-center filter-option fw-bolder'>
      <SVG src={toAbsoluteUrl(props.data.icon)} />
      <span className='px-2'>{t(props.data.label)}</span>
    </div>
  </components.Option></>)
}

export default OptionsFilter
