import {TextInput} from 'app/utils/helpers/components/Form'
import {KTSVG} from 'app/utils/helpers'

const SearchBar = (props: {
  search_placeholder: string
  onChange: Function
  checkNotFoundSearch: Function
  invalid_message: string
  disabled?: boolean
}) => {
  const {
    search_placeholder,
    onChange,
    checkNotFoundSearch,
    invalid_message,
    disabled = false,
  } = props

  return (
    <>
      <TextInput
        placeholder={search_placeholder}
        inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/search-small.svg' />}
        onChange={(e) => onChange(e)}
        disabledInput={disabled}
      />
      {checkNotFoundSearch() && <div className='invalid-message'>{invalid_message}</div>}
    </>
  )
}

export default SearchBar
