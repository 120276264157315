import axios from 'axios';

import { ListResponse, Response, ResponseArray } from '../../../../setup/axios/HttpResponse';
import { API_URL } from '../../../utils/common/constants';
import { _convertObjectToQuery } from '../../../utils/helpers';
import {
  ActiveUserParam,
  BusNotLinkedDriver,
  CheckStepAccountInfo,
  City,
  DispatchCenter,
  Driver,
  LinkedDispatcher,
  Nationality,
  SearchUserRequest,
  Skill,
  Team,
  UserInfo,
  UserModel,
  UsersTypeParams,
} from '../models';
import { OperationHoursAndMap } from '../models/OperationHoursAndMap';

const userApi = {
  getUsers(body: SearchUserRequest): Promise<ListResponse<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/filter-users?${_convertObjectToQuery(body)}`;
    return axios.get(url);
  },
  deleteEmployers(payload: string[]): Promise<Response<any>> {
    const url = `${API_URL}/api/v1/users/employers/delete`;
    return axios.put(url, {ids: payload});
  },
  getAllNationality(): Promise<ResponseArray<Nationality>> {
    const url = `${API_URL}/api/v1/location/get-nationality`;
    return axios.get(url);
  },
  getAllLinkedDispatcher(): Promise<ResponseArray<LinkedDispatcher>> {
    const url = `${API_URL}/api/v1/company-user/get-dispatcher`;
    return axios.get(url);
  },
  getAllSkill(): Promise<ResponseArray<Skill>> {
    const url = `${API_URL}/api/v1/company-user/get-worker-skill`;
    return axios.get(url)
  },
  getAllCity(): Promise<ResponseArray<City>> {
    const url = `${API_URL}/api/v1/location/get-city`;
    return axios.get(url)
  },
  getAllTeams(): Promise<ResponseArray<Team>> {
    const url = `${API_URL}/api/v1/users/get-sp-teams-active`;
    return axios.get(url)
  },
  getAllDispatchCenter(): Promise<ResponseArray<DispatchCenter>> {
    const url = `${API_URL}/api/v1/company-user/get-dispatch-centers`;
    return axios.get(url)
  },
  getAllDriver(): Promise<ResponseArray<Driver>> {
    const url = `${API_URL}/api/v1/company-user/get-drivers`;
    return axios.get(url)
  },
  getAlBusNotLinkedDriver(): Promise<ResponseArray<BusNotLinkedDriver>> {
    const url = `${API_URL}/api/v1/company-user/get-bus-not-linked-driver`;
    return axios.get(url)
  },
  getOperationHoursAndMaps(): Promise<ResponseArray<OperationHoursAndMap>> {
    const url = `${API_URL}/api/v1/company-user/get-operation-hours-map`;
    return axios.get(url)
  },
  createWorker(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/create-worker`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  createDriver(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/create-driver`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  createAdmin(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/create-admin`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  createBus(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/create-tech`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  checkExistUserInfo(params: CheckStepAccountInfo): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/company-user/check-exist-user-info?${_convertObjectToQuery(params)}`;
    return axios.get(url);
  },
  // Api active users
  activeUser(payload: ActiveUserParam): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/update-status`;
    return axios.patch(url, payload, {headers: { 'Content-Type': 'application/problem+json; charset=utf-8'} });
  },
  // Edit Users
  editUser(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/`;
    return axios.post(url, payload, {headers: { 'Content-Type': 'multipart/form-data'} });
  },
  // get all Admin
  getAllAdmin(body: UsersTypeParams): Promise<ListResponse<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/users-type?${_convertObjectToQuery(body)}`;
    return axios.get(url);
  },
  // get all Admin
  getAllWorker(body: UsersTypeParams): Promise<ListResponse<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/users-type?${_convertObjectToQuery(body)}`;
    return axios.get(url);
  },

  getUserById(id: string): Promise<Response<UserInfo>> {
    const url = `${API_URL}/api/v1/company-user/${id}`;
    return axios.get(url);
  },

  
  updateAdmin(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/update-admin`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  updateTech(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/update-tech`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  getAllWorkerWithSPLinked(body: UsersTypeParams): Promise<ListResponse<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/get-worker-name`;
    return axios.get(url);
  },

  updateWorker(payload: FormData): Promise<Response<UserModel>> {
    const url = `${API_URL}/api/v1/company-user/update-worker`;
    return axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  checkExistPhone(params: {userId?: string, phoneNumber?: string}): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/users/check-exist-phone?${_convertObjectToQuery(params)}`;
    return axios.get(url);
  },
  checkExistEmail(params: {userId?: string, email?: string}): Promise<Response<boolean>> {
    const url = `${API_URL}/api/v1/users/check-exist-email?${_convertObjectToQuery(params)}`;
    return axios.get(url);
  }
}

export {userApi}