import { Field } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { SpTeamMarket } from '../../../../../app/modules/tickets';

import { SpServiceAvailable } from '../../../../../app/utils/common/models';
import Skeleton from './Skeleton';

type Props = {
    data: SpTeamMarket[],
    isLoading?: boolean,
    companyImage?: string,
    isOffer?: boolean,
    rtl?: boolean,
    valueName: string,
    onSelectedTeam?: (data: SpTeamMarket) => void,
}

const SelectTeamMarket: React.FC<Props> = ({ data, isLoading, isOffer, rtl, onSelectedTeam, companyImage, valueName }) => {
    const { t } = useTranslation();

    return (
        <Row className='flex-root step-2 m-auto mx-9 my-8'>
            <Col sm={12} className="p-0">
                <h4 className='mb-10'>{t('SELECT_TEAM_COMPONENT.TITLE')}</h4>
            </Col>
            <Col sm={12} className="p-0">
                <div className='worker-selection'>
                    {
                        isLoading ? <Skeleton /> :
                            data && data.length > 0 &&
                            data.map((sp, index: number) =>
                                <div className={'worker-selection__item ' + (!sp.subSubServiceScopes && !sp.isOfferTicket ? ' opacity-50' : '')} key={index}>
                                    <label className='d-flex flex-stack mb-0 cursor-pointer' onClick={() => onSelectedTeam?.(sp)}>
                                        <span className='d-flex align-items-center me-2'>
                                            <div className='me-6'>
                                                <img src={companyImage} width={50} height={50} className='rounded' alt='company image' />
                                            </div>

                                            <span className='d-flex flex-column'>
                                                <span className='fw-bolder text-gray-800 fs-5'>
                                                    {rtl ? sp.fullNameAr : sp.fullNameEn}
                                                </span>
                                
                                                    {sp.isAvailableAll ?
                                                        <span className='fs-6 text-success'>{t('SELECT_TEAM_COMPONENT.DO_ALL')}</span>
                                                        : <>
                                                            {sp.servicesAvailable &&
                                                                <span className='fs-6 text-success'>
                                                                    {t('SELECT_TEAM_COMPONENT.CAN_DO')} {sp.servicesAvailable}

                                                                </span>
                                                            }
                                                        </>
                                                    }

                                                    {sp.isServiceNotCover ?
                                                        <span className='text-warning'>
                                                            <i className="fas fa-exclamation-triangle text-warning"></i> {t('MARKET_MODAL.SERVICE_NOT_COVER')}

                                                        </span>
                                                        :
                                                        <>
                                                            {sp.servicesUnavailable && <span className='fs-6 text-danger'>
                                                                {t('SELECT_TEAM_COMPONENT.CANT_DO')} {sp.servicesUnavailable}
                                                            </span>
                                                            }
                                                        </>
                                                    }

                                                    {sp.visitTimeInvalid &&
                                                        <span className='text-warning'>
                                                            <i className="fas fa-exclamation-triangle text-warning"></i> {t('MARKET_MODAL.ORDER_VISIT_INVALID')}

                                                        </span>
                                                    }


                                            </span>

                                        </span>

                                        <span className='form-check form-check-custom form-check-solid'>
                                            <Field className='form-check-input' type='radio' name={valueName} value={index.toString()} disabled={!sp.isOfferTicket && !sp.subSubServiceScopes} />
                                        </span>
                                    </label>

                                   
                                </div>
                            )
                    }


                </div>
            </Col>
        </Row>
    )
}

export { SelectTeamMarket }