import { ACTION_STATUS, ATTENDANCE_STATUS, DELIVERY_STATUS, clientTimeZoneOffset } from 'app/utils/common/constants';
import { ArrayGroupBy } from 'app/utils/extendMethods';
import { classNameFunc } from 'app/utils/helpers/components/Form';
import { isEmptyOrNull } from 'app/utils/helpers/extendMethod';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap-v5';
import Accordion from 'react-bootstrap/Accordion';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'setup/redux/Hooks';
import { TicketAttendanceSheetModel, TicketAttendanceSheetRequest } from '../../models/TicketAttendanceDelivery';
import { ticketApi } from '../../redux/TicketApi';
import { selectParamSearchAttendanceDelivery, ticketActions } from '../../redux/TicketSlice';
import { getTypeStatus, IMapStatusFilter, mapItemAttendanceDelivery, mapStatus, mapStatusFilter } from '../AttendanceDelivery';
import { TicketStatus } from '../../../../utils/common/constants';
import * as Icon from 'react-bootstrap-icons';
import { ModalUndoDeliveryAction } from '../../modals/view-package/components/UndoDeliveryActionModal';
import { TicketType } from 'app/utils/common/enum';

export interface IProps {
    rtl: boolean,
    onChangeDate?: (adjust: number) => void,
    dataAttendanceDelivery: TicketAttendanceSheetModel[],
    paramAttendanceDelivery: TicketAttendanceSheetRequest,
}

export const ContentComponents = (props: IProps) => {
    const dispatch = useDispatch();
    const { rtl, onChangeDate, dataAttendanceDelivery, paramAttendanceDelivery } = props;

    const { t } = useTranslation()
    const params = useAppSelector(selectParamSearchAttendanceDelivery);
    const isLoading = useAppSelector(s => s.ticket.isLoadingAttendanceDelivery)
    const user = useAppSelector(s => s.auth.user)

    const [state, setState] = useState<{
        isLoading: boolean
        list: {
            bus: TicketAttendanceSheetModel,
            workers: {
                worker: TicketAttendanceSheetModel,
                shift: TicketAttendanceSheetModel[]
            }[]
        }[]
    }>({ isLoading: false, list: [] });

    const [currentUndoDeliveryShift, setCurrentUndoDeliveryShift] = useState<TicketAttendanceSheetModel | undefined>(undefined)

    useEffect(() => {
        let data = ArrayGroupBy(dataAttendanceDelivery.map(e => mapItemAttendanceDelivery(e, dataAttendanceDelivery))
            .filter(e1 => {
                return params.status === undefined || params.status.length === 0 ||
                    (params.status as IMapStatusFilter[]).map(e => mapStatusFilter.find(e1 => e1.status === e) as typeof mapStatusFilter[number])
                        .filter(e => e.type === undefined ? (e1.statusAttendance === undefined && e1.statusDelivery === undefined) : (Array.isArray(e.group) ? e.group : [e.group]).includes(e.type === 'attendance' ? (e1.statusDelivery !== undefined ? false as any : e1.statusAttendance) : e1.statusDelivery))
                        .length > 0;
            }), e => e.spId).map(e => {
                const g1 = ArrayGroupBy(e.list, e => e.workerId);
                return {
                    bus: e.list[0],
                    workers: g1.map(e => ({
                        worker: e.list[0],
                        shift: e.list
                    })).sort((x: any, y: any) => ('' + x.worker.routeOrder).localeCompare(y.worker.routeOrder) || ('' + x.worker.scheduledVisitDateTime).localeCompare(y.worker.scheduledVisitDateTime))
                }
            });
        //console.log('dataAttendanceDelivery', data)
        setState(s => ({ ...s, list: data }));
    }, [dataAttendanceDelivery])

    const styleColumnWorkNameTable = {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        maxWidth: '20%'
    }
    const styleColumnTable = {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        maxWidth: '40%'
    }

    const COLUMNS = [
        {
            name: t("ATTENDANCE_DELIVERY_PAGE.TABLE.HEAD_WORKER_NAME"),
            selector: "",
            cell: (cell: typeof state.list[number]['workers'][number]) => {
                return <p>{rtl ? cell.worker.workerName : cell.worker.workerNameEn}</p>
            },
            style: styleColumnWorkNameTable
        },
        {
            name: t("ATTENDANCE_DELIVERY_PAGE.TABLE.HEAD_MORNING_SHIFT"),
            sortable: false,
            selector: "userType",
            // cell: (...params: any) => {
            //     console.log(params);
            //     return (
            //         <></>
            //     )
            // },
            cell: (cell: typeof state.list[number]['workers'][number]) => (
                <>{renderShift(cell, 'morning')}</>
            ),
            style: styleColumnTable,
            allowOverflow: true
        },
        {
            name: t("ATTENDANCE_DELIVERY_PAGE.TABLE.HEAD_EVENING_SHIFT"),
            selector: "evening-shift",
            sortable: false,
            cell: (cell: typeof state.list[number]['workers'][number]) => (
                <>{renderShift(cell, 'evening')}</>
            ),
            style: styleColumnTable,
            allowOverflow: true
        }
    ]

    const onConfirmWorkerUndoDeliveryAction = () => {
        if (!currentUndoDeliveryShift) {
            return
        }
        onChangeStatus('undoDelivery', currentUndoDeliveryShift, ACTION_STATUS.UndoDelivery)
    }

    const dataFlat = state.list.map(e => e.workers.map(e => e.shift).flat()).flat();
    const renderShift = (cell: typeof state.list[number]['workers'][number], shiftType: 'morning' | 'evening') => {
        const shift = cell.shift.find(e => e.scheduledVisitDateTime === undefined ? false :
            moment(e.scheduledVisitDateTime).hour() > 5 && moment(e.scheduledVisitDateTime).hour() < 15 ?
                shiftType === 'morning'
                : shiftType === 'evening'
        );

        const attendance = {
            isActive: !state.isLoading && shift !== undefined && shift?.workerIsActive !== false && shift?.statusDelivery === undefined && 
                (shift?.numberOfWorker || 1) == dataFlat.filter(da => da.id == shift.id).length,
            selected: shift === undefined ? undefined : getTypeStatus({ group: 'attendance', workerId: shift.workerId, eventStatus: shift?.event, status: shift?.status, isMultiWorker: shift?.isMultiWorker, workerAttendantAction: shift?.workerAttendantAction })?.status,
            updateDate: typeof shift?.attendantTime === 'string' ? new Date(shift?.attendantTime) : shift?.attendantTime,
            updateBy: shift?.attendantAgentName || shift?.attendantSPNameEn
        };

        const delivery = {
            isActive: !state.isLoading && attendance.selected === ATTENDANCE_STATUS.Attendant && shift?.workerIsActive !== false && (shift?.isMultiWorker === false || dataFlat.filter(e => e.id === shift?.id).reduce((flag: boolean, e) => e.statusAttendance === ATTENDANCE_STATUS.Attendant && flag, true)) && shift?.statusDelivery === undefined && 
                shift !== undefined && (shift?.numberOfWorker || 1) == dataFlat.filter(da => da.id == shift.id).length,
            selected: shift === undefined ? undefined : getTypeStatus({ group: 'delivery', workerId: shift.workerId, eventStatus: shift?.event, status: shift?.status, isMultiWorker: shift?.isMultiWorker, workerAttendantAction: shift?.workerAttendantAction })?.status,
            updateDate: shift?.deliveryTime !== undefined ? (typeof shift.deliveryTime === 'string' ? new Date(shift.deliveryTime as string) : shift.deliveryTime) : undefined,
            updateBy: shift?.deliveryAgentName || shift?.deliverySPNameEn
        }

        const isTicketCompletedOrCanceled: boolean = Boolean(shift?.status && ["Completed", "Canceled"].some(item => (shift?.ticketStatus || "").includes(item)))
        const enabledUndoDeliveryButton: boolean = Object.values(DELIVERY_STATUS).includes(delivery.selected || "") && !isTicketCompletedOrCanceled
        return <div className='d-flex flex-column align-items-center bus- table-content'>
            <Row className='flex-root worker-cell my-2 mb-md-0'>
                <div className='d-none'>
                    {shift !== undefined && <>
                        <div>{shiftType}</div>
                        <div>{shift.id}</div>
                        <div>{moment(shift.scheduledVisitDateTime).format('llll')}</div>
                        <div>{shift.event}</div>
                        <div>{shift.status}</div>
                        <div>{`TicketID: ${shift.isMultiWorker ? shift.id : ''}`}</div>
                        <div>{shift.workerAttendantAction || 'null'}</div>
                        <div>{attendance?.selected}</div>
                        <div>{delivery?.selected}</div>
                    </>}
                </div>
                <Col xs={12} xl={5} className="my-sm-4 text-end pe-sm-0 h-30px">
                    <div className='card-select'>
                        <Dropdown className='dropdown-select'>
                            <Dropdown.Toggle className={classNameFunc([attendance.selected === ATTENDANCE_STATUS.Attendant && 'success', attendance.selected === ATTENDANCE_STATUS.Unattendant && 'danger'])} disabled={!attendance.isActive}>
                                {!attendance.selected ? t('ATTENDANCE_DELIVERY_PAGE.NO_ACTION') : attendance.selected === ATTENDANCE_STATUS.Attendant ? t('ATTENDANCE_DELIVERY_PAGE.ATTENDANT') : t('ATTENDANCE_DELIVERY_PAGE.UN_ATTENDANT')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={'button'} disabled={attendance.selected === ATTENDANCE_STATUS.Attendant} className={classNameFunc(['item-attendance d-flex align-items-center', attendance.selected === ATTENDANCE_STATUS.Attendant && ['selected', 'selected-success']])} onClick={() => onChangeStatus('attendance', shift as any, ATTENDANCE_STATUS.Attendant)}>
                                    <svg width="19" height="19" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.59375 2.53125L3.65625 6.46875L1.6875 4.5" stroke={attendance.selected === ATTENDANCE_STATUS.Attendant ? '#39D5AD' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.ATTENDANT')}</span>
                                </Dropdown.Item>
                                <Dropdown.Item as={'button'} disabled={attendance.selected === ATTENDANCE_STATUS.Unattendant} className={classNameFunc(['item-unattendant d-flex align-items-center', attendance.selected === ATTENDANCE_STATUS.Unattendant && ['selected', 'selected-danger']])} onClick={() => onChangeStatus('attendance', shift as any, ATTENDANCE_STATUS.Unattendant)}>
                                    <svg width="19" height="19" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.08162 2.64038L1.70288 7.01912" stroke={attendance.selected === ATTENDANCE_STATUS.Unattendant ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.08162 7.01912L1.70288 2.64038" stroke={attendance.selected === ATTENDANCE_STATUS.Unattendant ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.UN_ATTENDANT')}</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
                <Col xs={12} xl={7} className="my-sm-4 ps-sm-0 pe-md-6 h-30px">
                    <div className='text-center'>
                        <div className=''>
                            {isEmptyOrNull(attendance.updateDate) ? t('ATTENDANCE_DELIVERY_PAGE.NO_ATTENDANT_ACTION') : moment(attendance.updateDate).locale(rtl ? 'ar-SA' : 'en-US').format("MM/DD/YYYY")}
                        </div>
                        <div className='d-flex justify-content-center my-1'>
                            <span className='text-nowrap'>{isEmptyOrNull(attendance.updateDate) ? '' : moment(attendance.updateDate).locale(rtl ? 'ar-SA' : 'en-US').format("hh:mm A")}</span>
                            {isEmptyOrNull(attendance.updateBy) ? '' : <span className="customer">&nbsp; {` ${t('ATTENDANCE_DELIVERY_PAGE.BY')} ${attendance.updateBy}`}</span>}
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={5} className="my-sm-4 text-end pe-sm-0 h-60px">
                    <div className='card-select'>
                        <Dropdown className='dropdown-select'>
                            <Dropdown.Toggle className={classNameFunc([delivery.selected === 'Delivered' ? 'warning' : delivery.selected !== undefined && 'dark'])} disabled={(!delivery.isActive && !enabledUndoDeliveryButton) || shift?.status === TicketStatus.Completed_No_Customer_Rating || shift?.status === TicketStatus.Completed_And_Customer_Rated}>
                                {!delivery.selected ? t('ATTENDANCE_DELIVERY_PAGE.NO_ACTION') : delivery.selected === 'Delivered' ? t('ATTENDANCE_DELIVERY_PAGE.DELIVERED') : t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={'button'} disabled={delivery.selected === DELIVERY_STATUS.Delivered} className={classNameFunc(['item-delivery d-flex align-items-center', delivery.selected === 'Delivered' && ['selected', 'selected-success']])} onClick={() => onChangeStatus('delivery', shift as any, 'Delivered')}>
                                    <svg width="19" height="19" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.59375 2.53125L3.65625 6.46875L1.6875 4.5" stroke={delivery.selected === DELIVERY_STATUS.Delivered ? '#39D5AD' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.DELIVERED')}</span>
                                </Dropdown.Item>
                                <Dropdown.Item as={'button'} disabled={delivery.selected === DELIVERY_STATUS.UndeliveredCustomerNotReachable} className={classNameFunc(['item-undelivery d-flex align-items-center', delivery.selected === DELIVERY_STATUS.UndeliveredCustomerNotReachable && ['selected', 'selected-danger']])} onClick={() => onChangeStatus('delivery', shift as any, DELIVERY_STATUS.UndeliveredCustomerNotReachable)}>
                                    <svg width="19" height="19" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.08162 2.64038L1.70288 7.01912" stroke={delivery.selected === DELIVERY_STATUS.UndeliveredCustomerNotReachable ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.08162 7.01912L1.70288 2.64038" stroke={delivery.selected === DELIVERY_STATUS.UndeliveredCustomerNotReachable ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED_CUSTOMER')}</span>
                                </Dropdown.Item>
                                <Dropdown.Item as={'button'} disabled={delivery.selected === DELIVERY_STATUS.UndeliveredNoFemale} className={classNameFunc(['item-undelivery d-flex align-items-center', delivery.selected === DELIVERY_STATUS.UndeliveredNoFemale && ['selected', 'selected-danger']])} onClick={() => onChangeStatus('delivery', shift as any, DELIVERY_STATUS.UndeliveredNoFemale)}>
                                    <svg width="19" height="19" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.08162 2.64038L1.70288 7.01912" stroke={delivery.selected === DELIVERY_STATUS.UndeliveredNoFemale ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.08162 7.01912L1.70288 2.64038" stroke={delivery.selected === DELIVERY_STATUS.UndeliveredNoFemale ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED_NO')}</span>
                                </Dropdown.Item>
                                <Dropdown.Item as={'button'} disabled={delivery.selected === DELIVERY_STATUS.UndeliveredOther} className={classNameFunc(['item-undelivery d-flex align-items-center', delivery.selected === DELIVERY_STATUS.UndeliveredOther && ['selected', 'selected-danger']])} onClick={() => onChangeStatus('delivery', shift as any, DELIVERY_STATUS.UndeliveredOther)}>
                                    <svg width="19" height="19" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.08162 2.64038L1.70288 7.01912" stroke={delivery.selected === DELIVERY_STATUS.UndeliveredOther ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.08162 7.01912L1.70288 2.64038" stroke={delivery.selected === DELIVERY_STATUS.UndeliveredOther ? '#E96161' : "#00171F"} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED_OTHER')}</span>
                                </Dropdown.Item>
                                <Dropdown.Item as={'button'} disabled={!enabledUndoDeliveryButton} className={classNameFunc(['item-undo-delivery d-flex align-items-center'])} onClick={() => setCurrentUndoDeliveryShift(shift)}>
                                    <Icon.ExclamationTriangleFill size={16} color={!enabledUndoDeliveryButton ? '#AEAEAE' : '#FFC20F'} />
                                    <span className='ms-1'>{t('ATTENDANCE_DELIVERY_PAGE.UNDO_WORKER_DELIVERY_ACTION')}</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
                <Col xs={12} xl={7} className="my-sm-4 ps-sm-0 pe-md-6 h-60px">
                    <div className='text-center'>
                        <div className='text-center'>{isEmptyOrNull(delivery.updateDate) ? '' : moment(delivery.updateDate).locale(rtl ? 'ar-SA' : 'en-US').format("MM/DD/YYYY")}</div>
                        <div className='d-flex justify-content-center my-1'>
                            <span className='text-nowrap'>{isEmptyOrNull(delivery.updateDate) ? t('ATTENDANCE_DELIVERY_PAGE.NO_DELIVERY_ACTION') : moment(delivery.updateDate).locale(rtl ? 'ar-SA' : 'en-US').format("hh:mm A")}</span>
                            {isEmptyOrNull(delivery.updateBy) ? '' : <span className="customer">&nbsp; {`${t('ATTENDANCE_DELIVERY_PAGE.BY')} ${delivery.updateBy}`}</span>}
                        </div>
                        {(delivery.selected !== 'Delivered' && delivery.selected !== undefined) &&
                            <div className="reason">
                                <span>{`${t('ATTENDANCE_DELIVERY_PAGE.REASON')}: `}</span>
                                <span className='desc'>{delivery.selected === DELIVERY_STATUS.UndeliveredCustomerNotReachable ? t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED_CUSTOMER') : delivery.selected === DELIVERY_STATUS.UndeliveredNoFemale ? t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED_NO') : t('ATTENDANCE_DELIVERY_PAGE.UNDELIVERED_OTHER')}</span></div>}
                    </div>
                </Col>
            </Row>
            <div className='text-center pt-sm-0' onClick={() => onPressViewOrder(shift?.id || '')}>
                <button type='button' {...shift === undefined && { disabled: true }} className='text-decoration-underline text-blue btn btn-link btn-view-order pt-sm-0'>{t('ATTENDANCE_DELIVERY_PAGE.VIEW_ORDER')}</button>
            </div>
        </div >
    }

    const onChangePaging = (page: number) => {

    }

    const onPressViewOrder = (ticketId: string) => {
        if (!ticketId) return;

        const param = {
            keyword: `#${ticketId}`,
            startDate: new Date(paramAttendanceDelivery.dateSearch.setHours(0, 0, 0, 0)),
            endDate: new Date(paramAttendanceDelivery.dateSearch.setHours(23, 59, 59, 0)),
            shift: 0,
            pageIndex: 1,
            pageSize: 10,
            searchByVisitDate: true,
            clientTimeZone: clientTimeZoneOffset,
        }

        ticketApi.searchDailyCalenderTicket(param).then(response => {
            if (response.data.data.length > 0) {
                const data = response.data.data[0];
                switch (true) {
                    case !!data.packageId:
                        dispatch(ticketActions.setOpeningViewPackageModal(data));
                        break;

                    default:
                        if (data.ticketType === TicketType.SUBSCRIPTION) {
                            dispatch(ticketActions.getTicketSubscription({ticketId: data.id}));
                        }
                        dispatch(ticketActions.setOpeningViewOrderModal(data));
                        dispatch(ticketActions.getTimeLineInOrderModal(data));
                        break;
                }
            }
        })
    }

    const onChangeStatus = async (type: 'attendance' | 'delivery' | 'undoDelivery', item: TicketAttendanceSheetModel, status: string) => {

        const updateStatus = mapStatus.find(e => e.status === status)?.update || '';
        setState(s => ({ ...s, isLoading: true }));
        const resp = (await ticketApi.putTickerStatusSpecificWorker({
            spId: item.spId,
            ticketId: item.id,
            workerIds: item.workerId,
            status: updateStatus
        }).catch(e => ({ data: undefined, }))).data

        if (resp !== undefined) {
            let d = [...state.list];
            d = d.map(e => ({
                ...e, workers: e.workers.map(e => {
                    const index = e.shift.findIndex(e1 => e1.workerId === item.workerId && e1.id === item.id /* && e1.scheduledVisitDateTime === item.scheduledVisitDateTime */);
                    if (index >= 0) {
                        e.shift[index].event = resp.event;
                        e.shift[index].status = resp.ticketStatus;
                        if (type === 'attendance') {
                            e.shift[index].attendantTime = resp.dateTime;
                            e.shift[index].attendantAgentName = user?.fullNameEn || ''
                        }
                        else if (type === 'undoDelivery') {
                            e.shift[index].deliveryTime = undefined
                            e.shift[index].deliveryAgentName = ''
                            e.shift[index].status = TicketStatus.InprogressConfirmed
                            e.shift[index].deliverySPNameEn = ''

                        }
                        else {
                            e.shift[index].deliveryTime = new Date(resp.dateTime)
                            e.shift[index].deliveryAgentName = user?.fullNameEn || ''
                        }
                        e.shift[index] = mapItemAttendanceDelivery(e.shift[index]);
                    }
                    return { ...e, shift: e.shift }
                })
            }))
            currentUndoDeliveryShift && setCurrentUndoDeliveryShift(undefined)
            setState(s => ({ ...s, isLoading: false, list: d }));
        }
        else {
            setState(s => ({ ...s, isLoading: false }));
        }
    }
    return (
        <>
            <ModalUndoDeliveryAction isLoading={state.isLoading} isShow={Boolean(currentUndoDeliveryShift)} onHide={() => setCurrentUndoDeliveryShift(undefined)} onConfirm={onConfirmWorkerUndoDeliveryAction} />
            <div className={'content-section'}>
                <div className={classNameFunc(["nav-date d-flex justify-content-between align-items-center", isLoading && 'loading'])}>
                    {!isLoading && <button disabled={isLoading} className="btn-previous-date" onClick={() => onChangeDate && onChangeDate(-1)}>
                        {rtl ? <i className="bi bi-arrow-right-short icon-next-date"></i> : <i className="bi bi-arrow-left-short icon-next-date"></i>}
                    </button>}
                    <span className='date-title m-auto'>{isLoading ? t('ATTENDANCE_DELIVERY_PAGE.TABLE.TICKETS_LOADING') : moment(params.dateSearch).locale(rtl ? 'ar-SA' : 'en-US').format('DD MMMM YYYY')}</span>
                    {!isLoading && <button disabled={isLoading} className="btn-next-date" onClick={() => onChangeDate && onChangeDate(1)}>
                        {rtl ? <i className="bi bi-arrow-left-short icon-next-date"></i> : <i className="bi bi-arrow-right-short icon-next-date"></i>}
                    </button>}
                </div>
                <div className='bus-table'>
                    {isLoading ?
                        <>
                            <div className='skeleton-box w-100 h-40px rounded' ></div>
                            <div className='skeleton-box w-100 h-300px rounded' ></div>
                        </>
                        : state.list.length === 0 ?
                            <>
                                <div className="no-data">
                                    <img src='/assets/images/icons/no-ticket.svg' className="img-responsive" alt="Image" />
                                    <p>{t('TICKET_CALENDER_SECTION.NO_RESULTS_FOUND')}</p>
                                </div>
                            </>
                            : <>
                                {
                                    state.list.map((e, index) => {
                                        return <Accordion alwaysOpen defaultActiveKey={String(index + 1)} key={index}>
                                            <Accordion.Item
                                                eventKey={String(index + 1)}
                                                // eventKey={e.bus.id}
                                                className={`bus-item`}
                                                key={e.bus.id}
                                            >
                                                <Accordion.Header className='bus-header'>
                                                    <div className='bus-icon'>
                                                        <img src='/assets/images/icons/bus.svg'></img>
                                                        <strong>&ensp;{`${t('ATTENDANCE_DELIVERY_PAGE.TABLE.HEAD_SECTION_BUS')}: `}</strong>
                                                        <span>{e.bus.spId === '-1' ? t('ATTENDANCE_DELIVERY_PAGE.TABLE.HEAD_SECTION_NO_BUS') : e.bus.spName}</span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className={classNameFunc(['bus-detail', (state.isLoading && false) && '--loading'])}>
                                                        <DataTable
                                                            style={{
                                                                overflow: undefined
                                                            }}
                                                            responsive
                                                            columns={COLUMNS}
                                                            data={e.workers}
                                                            noHeader
                                                            paginationServer
                                                            // pagination
                                                            // paginationComponent={BusPagination}
                                                            // paginationPerPage={5}
                                                            onChangePage={onChangePaging}
                                                            noDataComponent={<div className='text-center my-10'></div>}
                                                        />
                                                        <div className='loading'>
                                                            <div className="spinner-border spinner-border-sm text-dark"></div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    })
                                }
                            </>
                    }
                </div>
            </div >
        </>
    )
}