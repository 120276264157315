import { setFilterDataSearchTicket } from 'app/utils/services';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Response } from '../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../setup/constants/Http';
import { useAppDispatch, useAppSelector } from '../../../../setup/redux/Hooks';
import { ToastDefaultConfig } from '../../../../setup/toast/ToastConfig';
import {
	CASE_RELOAD_FILTER_STATISTIC,
	clientTimeZoneOffset,
	DisputeSource,
	EventLogName,
	EventLogSource,
	Languages,
	SHIFT_TICKET,
	TicketStatus,
} from '../../../utils/common/constants';
import { selectLanguage } from '../../auth';
import {
	AcknowTicketModel,
	CompleteRequestModel,
	DisputeRequestModel,
	EventLogDetail,
	ReassignRequestModel,
	ReassignSuccessModel,
	RescheduleRequestModel,
	RescheduleResponseSuccess,
	SearchTicketDailyCalenderRequest,
	SpTeamModel,
	TicketCardTableModel,
	TicketModel,
	TicketPackageDetail,
	WorkersAssigned,
} from '../models';
import { FilterStatisticRequest } from '../models/TicketFilter';
import { ticketApi } from '../redux/TicketApi';
import {
	selectFilterNewTicketCard,
	selectIsChangedFilterData,
	selectParamIntermediateSearch,
	selectParamSearchAttendanceDelivery,
	selectTicketModalData,
	selectTicketOpeningCancelModal,
	selectTicketOpeningCreateModal,
	selectTicketOpeningPackageModal,
	selectTicketOpeningRescheduleModal,
	selectTicketOpeningViewModal,
	selectTicketsAcknowTicketData,
	selectTicketsAcknowTicketModalData,
	selectTicketsIsShowAcknowModal,
	selectTicketsLoadingAcknow,
	selectTicketSpTeam,
	ticketActions,
} from '../redux/TicketSlice';
import { NewTicketModal } from './acknow-ticket/AcknowTicketModal';
import { CancelPackageModal } from './cancel-package/CancelPackageModal';
import { CompleteTicketModal } from './complete-ticket/CompleteTicketModal';
import { DisputeTicketModal } from './dispute-ticket/DisputeTicketModal';
import { MarketModal } from './market/MarketModal';
import { ReassignTicketModal } from './reassign-ticket/ReassignTicketModal';
import { ReschedulePackage } from './reschedule-package/ReschedulePackage';
import { ViewPackageModal } from './view-package/ViewPackageModal';
import { ViewTicketModal } from './view-ticket/ViewTicketModal';
import { WarrantyTicketModal } from './warranty-ticket/WarrantyTicketModal';



const TicketModal: React.FC = () => {
	const dispatch = useAppDispatch();

	const language = useAppSelector(selectLanguage);
	const rtlMode = language === Languages.ar;
	const spTeams = useAppSelector(selectTicketSpTeam);

	const isShowViewModal = useAppSelector(selectTicketOpeningViewModal);
	const isShowCancelPackageModal = useAppSelector(selectTicketOpeningCancelModal);
	const isShowReschedulePackageModal = useAppSelector(selectTicketOpeningRescheduleModal);
	const isShowViewPackageModal = useAppSelector(selectTicketOpeningPackageModal);
	const isShowCreateModal = useAppSelector(selectTicketOpeningCreateModal);
	const isShowAcknowModal = useAppSelector(selectTicketsIsShowAcknowModal);
	const paramAttendanceDelivery = useAppSelector(selectParamSearchAttendanceDelivery)

	const [isShowDisputeModal, setIsShowDisputeModal] = useState(false);
	const [disputeModalData, setDisputeModalData] = useState('');
	const [disputeSource, setDisputeSource] = useState('');

	const [isShowReassignModal, setIsShowReassignModal] = useState(false);
	const [isShowCompleteModal, setIsShowCompleteModal] = useState(false);

	const [isLoadingDispute, setIsLoadingDispute] = useState(false);
	const [isLoadingComplete, setIsLoadingComplete] = useState(false);

	const [spAdminId, setSpAdminId] = useState('');

	const [ticketPackage, setTicketPackage] = useState<TicketPackageDetail>();

	const ticketDetail = useAppSelector(selectTicketModalData);
	const ticketAcknowData = useAppSelector(selectTicketsAcknowTicketData);
	const ticketAcknowModalData = useAppSelector(selectTicketsAcknowTicketModalData);
	const isLoadingAcknow = useAppSelector(selectTicketsLoadingAcknow);
	const filterNewTicketCard = useAppSelector(selectFilterNewTicketCard);

	const isChangedFilterData = useAppSelector(selectIsChangedFilterData);

	const [isShowWarrantyModal, setIsShowWarrantyModal] = useState(false);

	const handleCloseViewModal = () => dispatch(ticketActions.setCloseViewOrderModal());

	const handleCloseViewPackageModal = () => dispatch(ticketActions.setCloseViewPackageModal());

	const handleCloseReschedulePackageModal = () => dispatch(ticketActions.setCloseReschedulePackageModal());

	const handleCloseCancelPackageModal = () => dispatch(ticketActions.setCloseCancelPackageModal());

	const handleCloseCreateModal = () => dispatch(ticketActions.setOpeningCreateModal(false));

	const handleReloadShift = (shiftTicket?: number) => {
		Boolean(shiftTicket) && reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.CREATE, shiftTicket)
		!Boolean(shiftTicket) && dispatch(ticketActions.getAttendanceDelivery(paramAttendanceDelivery))
	}

	const handleShowDisputeModal = (ticketId: string) => {
		setIsShowDisputeModal(true);
		setDisputeModalData(ticketId);
		setDisputeSource(DisputeSource.TICKET_MODAL);
	}

	const searchTicketParam = useAppSelector(selectParamIntermediateSearch);

	const reloadOrderStatistic = (caseUpdate: number) => {
		let timeRange: FilterStatisticRequest = {
			startTime: moment(searchTicketParam.startDate).locale('en-US').format('yyyy-MM-DD 00:00:00'),
			endTime: moment(searchTicketParam.endDate).locale('en-US').format('yyyy-MM-DD 23:59:59'),
			clientTimeZone: searchTicketParam.clientTimeZone
		}
		dispatch(ticketActions.reloadOrderStatistic({ caseUpdate: caseUpdate, timeRange: timeRange }));
	}

	const reloadAfterChangeTicket = (caseUpdate: number, targetShift?: number) => {
		reloadOrderStatistic(caseUpdate);

		const generalParam: SearchTicketDailyCalenderRequest = setFilterDataSearchTicket(searchTicketParam);
		const dataTableDefault: TicketCardTableModel = {
			data: [],
			totalItem: 0,
			totalCompleteItem: 0,
			totalInprogressItem: 0,
			totalCancelItem: 0
		};
		if (generalParam.keyword && !isChangedFilterData) {
			generalParam.startDate = null;
			generalParam.endDate = null;
		}

		//dispatch(ticketActions.searchShiftMorningTicketSuccess(dataTableDefault));
		const paramShiftMorning = { ...generalParam };
		paramShiftMorning.shift = SHIFT_TICKET.MORNING;
		if (Boolean(targetShift === SHIFT_TICKET.MORNING || !targetShift)) dispatch(ticketActions.searchDailyCalenderTicket({ request: paramShiftMorning, isAutoRefresh: true }));

		//dispatch(ticketActions.searchShiftAfternoonTicketSuccess(dataTableDefault));
		const paramShiftAfternoon = { ...generalParam };
		paramShiftAfternoon.shift = SHIFT_TICKET.AFTERNOON;
		if (Boolean(targetShift === SHIFT_TICKET.AFTERNOON || !targetShift)) dispatch(ticketActions.searchDailyCalenderTicket({ request: paramShiftAfternoon, isAutoRefresh: true }));

		//dispatch(ticketActions.searchShiftNightTicketSuccess(dataTableDefault));
		const paramShiftNight = { ...generalParam };
		paramShiftNight.shift = SHIFT_TICKET.NIGHT;
		if (Boolean(targetShift === SHIFT_TICKET.NIGHT || !targetShift)) dispatch(ticketActions.searchDailyCalenderTicket({ request: paramShiftNight, isAutoRefresh: true }));

		dispatch(ticketActions.fetchNewTicketCardData({ request: filterNewTicketCard, isAutoRefresh: false }));
	}

	const handleSubmitDispute = (request: DisputeRequestModel) => {
		setIsLoadingDispute(true);
		ticketApi.disputeTicket(request).then((response: Response<boolean>) => {
			if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
				if (response.data) {
					setIsShowDisputeModal(false);
					let visitTime: Date | null = null;
					let caseDispute: number = CASE_RELOAD_FILTER_STATISTIC.DISPUTE;
					switch (disputeSource) {
						case DisputeSource.TICKET_MODAL:
							visitTime = ticketDetail?.scheduledVisitDateTime || null;
							break;

						case DisputeSource.ACKNOW_MODAL:
							visitTime = ticketAcknowModalData?.scheduledVisitDateTime || null;
							caseDispute = CASE_RELOAD_FILTER_STATISTIC.DISPUTE_IN_ACKNOW_MODAL;
							break;
					}
					dispatch(ticketActions.setDisputeTicketSuccess({ ...request, visitTime: visitTime, source: disputeSource }));

					reloadAfterChangeTicket(caseDispute);
				}
				toast.info(response.message, ToastDefaultConfig());
			} else {
				toast.error(response.message, ToastDefaultConfig());
			}
		}).finally(() => { setIsLoadingDispute(false); });
	};

	const handleSubmitComplete = (request: CompleteRequestModel) => {
		setIsLoadingComplete(true);
		ticketApi.completeTicket(request).then((response: Response<boolean>) => {
			if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
				if (response.data) {
					setIsShowCompleteModal(false);
					dispatch(ticketActions.setCompleteTicketSuccess({ request: request, visitTime: ticketDetail?.scheduledVisitDateTime || null }));

					reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.COMPLETE);
				}
				toast.info(response.message, ToastDefaultConfig());
			} else {
				toast.error(response.message, ToastDefaultConfig());
			}
		}).finally(() => { setIsLoadingComplete(false); });
	};

	const handleSubmitReassign = (request: ReassignRequestModel) => {
		let sp = spTeams.find(x => x.id === request.spId);
		let model = {
			id: request.id,
			spId: request.spId,
			visitTimeOffset: request.visitTimeOffset,
			spNameAr: sp?.fullNameAr,
			spNameEn: sp?.fullNameEn,
		} as ReassignSuccessModel
		dispatch(ticketActions.setTicketReassignSuccess(model));

		reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.REASSIGN)
	};

	const handleSubmitWarranty = (data: TicketModel) => {
		reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.CREATE);
	}

	const handleSubmitCreate = (data: TicketModel) => {
		reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.CREATE);
	};



	const handleSubmitAcknow = (data: AcknowTicketModel) => {
		dispatch(ticketActions.acknowTicket(data.id));
	};

	const handleShowAcknowTicket = () => {
		setTimeout(() => {
			if (ticketAcknowData.length > 0) {
				dispatch(ticketActions.showAcknowModal());
			}
		}, 500);

	}

	const handleDisputeAcknow = (ticketId: string) => {
		setIsShowDisputeModal(true);
		setDisputeModalData(ticketId);
		setDisputeSource(DisputeSource.ACKNOW_MODAL);
	}

	const handleCancelPackageModal = (data: TicketModel) => {
		dispatch(ticketActions.setOpeningCancelPackageModal(data));
	}

	const handleReschedulePackageModal = (data: TicketModel) => {
		dispatch(ticketActions.setOpeningReschedulePackageModal(data));
		dispatch(ticketActions.setHiddenViewPackageModal())
	}

	const handleBackViewPackageFromCancel = () => {
		dispatch(ticketActions.setCloseCancelPackageModal());
	}

	const handleBackViewPackageFromReschedule = () => {
		dispatch(ticketActions.setCloseReschedulePackageModal());
		dispatch(ticketActions.setVisibleViewPackageModal())
	}

	const finishSuccessReschedule = () => {
		dispatch(ticketActions.setCloseReschedulePackageModal());
	}

	const finishSuccessCancel = () => {
		dispatch(ticketActions.setCloseCancelPackageModal());
	}

	const updateTicketCancel = (ticketId: string, isRefund: boolean, visitTime: Date | null) => {
		dispatch(ticketActions.updateTicketCancel({ id: ticketId, visitTime: visitTime }));
		updateRCancelTimeline(isRefund);

		reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.CANCEL);
	}

	const updateTicketCancelRemaining = (packageSubmitId: number) => {
		reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.CANCEL_REMAINING);
	}

	const updateIsRefundPackageStore = (ticketPackageDetail: TicketPackageDetail) => {
		if (ticketPackageDetail) setTicketPackage(ticketPackageDetail)
	}

	const updateRescheduleTimeline = (request: RescheduleRequestModel) => {
		const eventLogs: EventLogDetail[] = ticketPackage?.eventLogs ? ticketPackage.eventLogs : [];
		let reassignOldDate = undefined as Date | undefined;
		if (eventLogs?.length > 0) {
			reassignOldDate = ticketPackage?.eventLogs[eventLogs?.length - 1].reassignNewDate;
			if (!reassignOldDate) reassignOldDate = ticketDetail?.scheduledVisitDateTime;
		}
		const newEventLog = {
			dateTime: new Date(),
			event: EventLogName.Ticket_Reassigned,
			source: EventLogSource.CentroDashboard,
			ticketStatus: ticketDetail?.status,
			userActionEn: ticketDetail?.spNameEn,
			userActionAr: ticketDetail?.spNameAr,
			reassignNewDate: new Date(request.scheduledVisitDateTime),
			reassignOldDate: reassignOldDate,
		} as EventLogDetail;
		updateEventTicketPackage(newEventLog)
	}

	const updateRCancelTimeline = (isRefund: boolean) => {
		const newEventLog = {
			dateTime: new Date(),
			event: isRefund ? EventLogName.Ticket_Canceled_With_Refund : EventLogName.Ticket_Canceled,
			source: EventLogSource.CentroDashboard,
			ticketStatus: TicketStatus.Canceled,
			userActionEn: ticketDetail?.spNameEn,
			userActionAr: ticketDetail?.spNameAr,
		} as EventLogDetail;
		updateEventTicketPackage(newEventLog)
	}

	const updateEventTicketPackage = (event: EventLogDetail) => {
		let ticketPackageTmp = { ...ticketPackage };
		ticketPackageTmp.eventLogs?.push(event);
		setTicketPackage(ticketPackageTmp as TicketPackageDetail);
	}

	const getFilterCalender = (time: Date) => {
		return {
			startDate: moment(time).startOf('month').startOf('day').toDate(),
			endDate: moment(time).endOf('month').endOf('day').toDate(),
			clientTimeZone: clientTimeZoneOffset,
			searchByVisitDate: true
		};
	}

	const onSchedulePackageUpdated = (request: RescheduleRequestModel, item: WorkersAssigned) => {
		let model = {
			id: request.id,
			visitTimeOffset: new Date(request.scheduledVisitDateTime),
			endVisitTimeOffset: new Date(request.scheduledVisitEndDateTime)
		} as RescheduleResponseSuccess;
		dispatch(ticketActions.setTicketRescheduleSuccess(model));

		//update reschedule count view package details
		let ticketPackageTmp = { ...ticketPackage };
		if (ticketPackageTmp.packageDetails) {
			const countSchedule = ticketPackageTmp.packageDetails?.rescheduleCount;
			ticketPackageTmp.packageDetails.rescheduleCount = countSchedule ? countSchedule + 1 : 1;
			// ticketPackageTmp.eventLogs.
		}
		// update worker assign
		if (ticketPackageTmp.ticketWorkers && ticketPackageTmp.ticketWorkers.length > 0) {
			ticketPackageTmp.ticketWorkers = ticketPackageTmp.ticketWorkers.filter(worker => {
				return item.workersAssigned.length > 0 ? item.workersAssigned.some(workerId => workerId == worker.id) : false;
			})
		}
		setTicketPackage(ticketPackageTmp as TicketPackageDetail);
		// update view calender		
		reloadAfterChangeTicket(CASE_RELOAD_FILTER_STATISTIC.RESCHEDULE);
	}

	useEffect(() => {
		dispatch(ticketActions.fetchDataAcknow());
		dispatch(ticketActions.getMarketOrders());
	}, [dispatch]);

	useEffect(() => {
		if (ticketAcknowData.length > 0) {
			handleShowAcknowTicket();
			console.log('Data Acknowledge change', ticketAcknowData);
		}
	}, [ticketAcknowData]);

	useEffect(() => {
		if (spTeams.length <= 0) {
			dispatch(ticketActions.fetchSpTeamData());
			return;
		}
		if (ticketDetail) {
			setSpAdminId(spTeams?.find((item: SpTeamModel) => item.id === ticketDetail.spId)?.spAdminId || '')
		}
			
	}, [ticketDetail, spTeams])

	return (
		<>
			<ViewTicketModal
				data={ticketDetail}
				isShow={isShowViewModal}
				onHide={() => handleCloseViewModal()}
				onComplete={() => setIsShowCompleteModal(true)}
				onDispute={handleShowDisputeModal}
				onWarranty={() => setIsShowWarrantyModal(true)}
				onReassign={() => setIsShowReassignModal(true)}
				rtl={rtlMode}
				spAdminId={spAdminId}
			/>

			<DisputeTicketModal
				data={ticketDetail}
				isShow={isShowDisputeModal}
				ticketId={disputeModalData}
				onSubmit={handleSubmitDispute}
				onHide={() => setIsShowDisputeModal(false)}
				isLoading={isLoadingDispute}
			/>

			<CompleteTicketModal
				isShow={isShowCompleteModal}
				data={ticketDetail}
				onHide={() => setIsShowCompleteModal(false)}
				onSubmit={handleSubmitComplete}
				isLoading={isLoadingComplete}
			/>
			<ReassignTicketModal
				isShow={isShowReassignModal}
				data={ticketDetail}
				onHide={() => setIsShowReassignModal(false)}
				onReassigned={handleSubmitReassign}
			/>

			<WarrantyTicketModal
				isShow={isShowWarrantyModal}
				data={ticketDetail}
				onHide={() => setIsShowWarrantyModal(false)}
				onWarrantied={handleSubmitWarranty}
			/>
			{/*<CreateTicketModal
				isShow={isShowCreateModal}
				data={ticketDetail}
				onHide={handleCloseCreateModal}
				onCreatedTicket={handleSubmitCreate}
			/>*/}

			<NewTicketModal
				isShow={isShowAcknowModal}
				data={ticketAcknowModalData}
				onSubmit={handleSubmitAcknow}
				onDispute={handleDisputeAcknow}
				isLoading={isLoadingAcknow}
			></NewTicketModal>

			<MarketModal />

			<ViewPackageModal
				data={ticketDetail}
				ticketPackage={ticketPackage}
				isShow={isShowViewPackageModal}
				onHide={() => handleCloseViewPackageModal()}
				onCancel={handleCancelPackageModal}
				onReschedule={handleReschedulePackageModal}
				onReloadShift={handleReloadShift}
				updateIsRefundPackageStore={updateIsRefundPackageStore}
				rtl={rtlMode}
			/>

			<CancelPackageModal
				data={ticketDetail}
				isShow={isShowCancelPackageModal}
				ticketPackage={ticketPackage}
				onBack={handleBackViewPackageFromCancel}
				finishSuccess={finishSuccessCancel}
				onHide={handleCloseCancelPackageModal}
				onReloadShift={handleReloadShift}
				updateTicketCancel={updateTicketCancel}
				updateTicketCancelRemaining={updateTicketCancelRemaining}
				rtl={rtlMode}
			/>

			<ReschedulePackage
				data={ticketDetail}
				ticketPackage={ticketPackage}
				isShow={isShowReschedulePackageModal}
				onBack={handleBackViewPackageFromReschedule}
				finishSuccess={finishSuccessReschedule}
				onHide={handleCloseReschedulePackageModal}
				updateRescheduleTimeline={updateRescheduleTimeline}
				onSchedulePackageUpdated={onSchedulePackageUpdated}
				rtl={rtlMode}
			/>
		</>
	)
}

export default TicketModal
