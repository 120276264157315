import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreatePackageForMajorServiceCentroRequestBody, CreatePackageWorkflowServiceRequestBody, GetBusInfoDetailByCompanyRequestBody, GetBusInfoDetailByCompanyResponseData, GetListPackageByMajorServiceIdRequestBody, PackageWorkflowServiceModel, ServiceManagementPackageInfoModel, UpdatePackageForMajorServiceCentroRequestBody, UpdatePackageStatusForMajorServiceCentroRequestBody, UpdatePackageWorkflowServiceRequestBody } from "../models";

interface ServiceManagementState {
    // majorServicesData: PackageWorkflowServiceModel[],
    // loadingMajorServicesData: boolean
}

const initialState: ServiceManagementState = {
    // majorServicesData: [],
    // loadingMajorServicesData: false
}

const serviceManagementSlice = createSlice({
    name: "ServiceManagementSlice",
    initialState,
    reducers: {
        getListPackageWorkflowService(state, action: PayloadAction<{ callback: (res: PackageWorkflowServiceModel[] | null, err: any) => void }>) {
            // state.loadingMajorServicesData = true
        },
        createPackageWorkflowService(state, action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: CreatePackageWorkflowServiceRequestBody }>) {

        },
        updatePackageWorkflowService(state, action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: UpdatePackageWorkflowServiceRequestBody }>) {

        },
        getBusInfoDetailByCompany(state, action: PayloadAction<{ callback: (res: GetBusInfoDetailByCompanyResponseData | null, err: any) => void, body: GetBusInfoDetailByCompanyRequestBody }>) {

        },
        getListPackageByMajorServiceId(state, action: PayloadAction<{ callback: (res: ServiceManagementPackageInfoModel[] | null, err: any) => void, body: GetListPackageByMajorServiceIdRequestBody }>) {

        },
        createPackageForMajorServiceCentro(state, action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: CreatePackageForMajorServiceCentroRequestBody }>) {

        },
        updatePackageForMajorServiceCentro(state, action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: UpdatePackageForMajorServiceCentroRequestBody }>) {

        },
        updatePackageStatusForMajorServiceCentro(state, action: PayloadAction<{ callback: (res: boolean | null, err: any) => void, body: UpdatePackageStatusForMajorServiceCentroRequestBody }>) {

        },
    },
})

// service management
// export const selectOpeningAddOrderSourceModal = (state: RootState) => state.serviceManagement.openingAddOrderSourceModal;

// Action
export const serviceManagementAction = serviceManagementSlice.actions;

// Reducer
const serviceManagementReducer = serviceManagementSlice.reducer;
export default serviceManagementReducer;