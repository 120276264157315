import moment from 'moment'
import { Worker } from "app/modules/tickets/models/OrderDrawerModel";

export const sortServiceProviders = (filter: {sortBy: number,filterBy: number,startDate?: Date},service_providers: Worker[]) => {
    if (filter.sortBy === 1 || !service_providers.find(item => item.isLastCompleted)) {
      const {favoritedItems, rest: other} = filterfavoritedItems(service_providers)
      const {lastCompletedItems, rest} = filterLastCompletedItems(other)

      return [...sortByStartDate(favoritedItems), ...sortByStartDate(lastCompletedItems), ...sortByStartDate(rest)]
    } else {
      const {lastCompletedItems, rest: other} = filterLastCompletedItems(service_providers)
      const {favoritedItems, rest} = filterfavoritedItems(other)

      return [...sortByStartDate(lastCompletedItems),...sortByStartDate(favoritedItems),...sortByStartDate(rest)]
    }
  }
  
  const filterfavoritedItems = (service_providers: Worker[]) => {
    const favoritedItems = [] as Worker[]
      const rest = [] as Worker[]
    for (let item of service_providers) {
      item.isFavoriteWorker ? favoritedItems.push(item) : rest.push(item)
    }
    return {favoritedItems, rest }
  }

  const filterLastCompletedItems = (service_providers: Worker[]) => {
    const lastCompletedItems = [] as Worker[]
      const rest = [] as Worker[]
      for (let item of service_providers) {
        item.isLastCompleted ? lastCompletedItems.push(item) : rest.push(item)
      }
    return {lastCompletedItems, rest }
  }

  const sortByStartDate = (service_providers: Worker[]) => {
    return service_providers.sort((prev: Worker, next: Worker) =>
      moment.utc(prev.startDate).diff(moment.utc(next.startDate))
    )
  }