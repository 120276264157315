import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../theme/layout/components/HeadTitle';
import { PageUrl } from '../../utils/common/constants';
import Report from './components/Report';

const ReportPage: React.FC = () => {
    const { t } = useTranslation();

    PageTitle(t('REPORT_PAGE.PAGE_TITLE'));
    return (
        <>
            <Switch>
                <Route path={PageUrl.REPORTS.ROOT}>
                    <Report />
                </Route>

                <Redirect to={PageUrl.REPORTS.ROOT} />
            </Switch>
        </>
    )

}

export default ReportPage