import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { formatLinkDisplay } from '../../../../utils/helpers/OnlineBookingHelpers';
import { OnlineBookingModel } from '../../models';
import { selectIsCreateOnlineBookingSuccess, selectOnlineBookingDataAddOrUpdate } from '../../redux/OnlineBookingSlice';

const CompleteOnlineBooking: FC = () => {
  const { t } = useTranslation();
  const onlineBookingDataAddOrUpdate: OnlineBookingModel = useAppSelector(selectOnlineBookingDataAddOrUpdate);
  const isCreateOnlineBookingSuccess = useAppSelector(selectIsCreateOnlineBookingSuccess);
  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
  const handleCopyLink = (onlineBookingDataAddOrUpdate: OnlineBookingModel) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(`${formatLinkDisplay(onlineBookingDataAddOrUpdate)}`);
    } else {
      unsecuredCopyToClipboard(`${formatLinkDisplay(onlineBookingDataAddOrUpdate)}`);
    }
  }
  return (
    <div className='m-20 mb-5 text-center w-100'>
      <img
        src='/assets/images/pages/order/success-primary.png' alt='success'
      />
      <h3 className='mt-10'>{isCreateOnlineBookingSuccess ? t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.LINK_GENERATED_COMPLETED') : t('ONLINE_BOOKING_PAGE.VIEW_ONLINE_BOOKING_MODAL.LINK_UPDATE_COMPLETED')}</h3>
      <div className="d-flex">
        <div className="d-flex m-auto">
          <a className='shorten-text text-blue'title={`${formatLinkDisplay(onlineBookingDataAddOrUpdate)}`} href={`${formatLinkDisplay(onlineBookingDataAddOrUpdate)}`} target="_blank">{`${formatLinkDisplay(onlineBookingDataAddOrUpdate)}`}</a>
          <i className="fas fa-copy field-links ml-3" onClick={() => handleCopyLink(onlineBookingDataAddOrUpdate)}></i>
        </div>
      </div>
    </div>
  )
}

export { CompleteOnlineBooking }
