import { useTranslation } from "react-i18next";
import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { Col, InputGroup, } from 'react-bootstrap-v5';
import { FormikErrors, FormikTouched } from 'formik';

import { KTSVG } from "../../../../../../utils/helpers";
import { SelectFilter } from "../../../../../../utils/common/models";
import { UserFormModel } from "../../../../models";
import { STEP_USERS } from "../../../../../../utils/common/constants";


type Props = {
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  currentCheckCustomStep: number,
  toggleActive: boolean | undefined,
  optionLinkedDispatcher : SelectFilter[],
  selectLinkedDispatcher: SelectFilter[],
  overrideStrings: any,
  changeActive: () => void,
  removeItemLinkedDispatcher: (id: string) => void,
  handleChangeLinkedDispatcher: (event: SelectFilter[]) => void
}

const WorkInfoAdmin: React.FC<Props> = ({
  touched, errors, currentCheckCustomStep, 
  toggleActive, changeActive, optionLinkedDispatcher, selectLinkedDispatcher, overrideStrings,
  removeItemLinkedDispatcher, handleChangeLinkedDispatcher
})  => {

  const { t } = useTranslation();
  
  return (
    <>
      <Col sm={12} className='mt-8'>
          <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.TITLE_DISPATCHER')}</h6>
          <InputGroup className='filter-calender'>
            <InputGroup.Text id='basic-addon1'>
                <KTSVG defaultColor={true} path="/assets/images/icons/service-and-skill.svg" />
            </InputGroup.Text>
            <MultiSelect
                options={optionLinkedDispatcher}
                value={selectLinkedDispatcher}
                onChange={handleChangeLinkedDispatcher}
                labelledBy="Select Skill *"
                className='form-multi-select form-multi-select-mobile'
                overrideStrings={overrideStrings}
            />
          </InputGroup>
          {selectLinkedDispatcher.length == 0 && currentCheckCustomStep == STEP_USERS.WORK_INFO ? (
              <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_LINKEDDISPATCHER')}</div>
          ) : null}

          <div className='list-item--select'>
          {selectLinkedDispatcher && selectLinkedDispatcher.map((dispatcher: SelectFilter) => (
            <div className="item" key={dispatcher.value}>
              <span className='name'>{dispatcher.label}</span> <span onClick={() => removeItemLinkedDispatcher(dispatcher.value)} className='close'> <i className="fas fa-times"></i></span>
            </div>
          ))}
          </div>
        </Col>
        <Col sm={12} className='mt-8'>
          <div className='pb-4'>
            <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle' style={{width: 'max-content'}}>
              <input className='form-check-input  mx-2' type='checkbox' checked={toggleActive} onChange={changeActive}/>
              <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{t('USERS_PAGE.CONTENT_STEP.STEP4.ACTIVE')}</span>
            </label>
          </div>
        </Col>
    </>
  )
}

export { WorkInfoAdmin }