import { BusNotLinkedDriver, City, DispatchCenter, SPInfos, UserFormModel } from "app/modules/users/models";
import { STEP_USERS } from "app/utils/common/constants";
import { SelectFilter } from "app/utils/common/models";
import { KTSVG } from "app/utils/helpers";
import { FormikErrors, FormikTouched } from "formik";
import { type } from "os";
import React, { useEffect, useState } from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import _ from "lodash";

type Props = {
  rtl?: boolean,
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  values?: UserFormModel,
  dispatchCenters?: DispatchCenter[],
  // setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  cities: City[],
  updateValueDispatchCenter: (value: SelectFilter[]) => void,
  updateValueSpLinked: (value: SelectFilter[]) => void,
  updateValueCity: (value: SelectFilter[]) => void,
  updateValueWorkerTravel: (value: boolean) => void,
  updateValueActive: (value: boolean) => void,
  optionBus: SelectFilter[];
  disabledDispatchCenter?: boolean,
}

const WorkLocationWorker: React.FC<Props> = ({ rtl, errors, dispatchCenters, disabledDispatchCenter = false, values, cities, updateValueSpLinked, updateValueCity,
  updateValueWorkerTravel, updateValueActive, updateValueDispatchCenter, optionBus }) => {
  const { t } = useTranslation();

  const [selectDispatchCenter, setSelectDispatchCenter] = useState<SelectFilter[]>(values?.workerDispatchCenter || []);
  const [selectBuses, setSelectBuses] = useState<SelectFilter[]>(values?.listSpLinked || []);
  const [selectCities, setSelectCities] = useState<SelectFilter[]>(values?.listCity || []);

  const optionDispatchCenter = dispatchCenters?.map((data: any) => {
    return { label: data.name, value: data.id };
  });

  const optionCities = cities.map((data: City) => {
    return { label: rtl ? data.nameAr : data.nameEn, value: data.id }
  })
  useEffect(() => {
    setSelectDispatchCenter(values?.workerDispatchCenter || []);
    setSelectBuses(values?.listSpLinked || []);
    setSelectCities(values?.listCity || []);
  }, [values?.workerDispatchCenter, values?.listSpLinked, values?.listCity])

  const handleChangeDispatchCenter = (event: SelectFilter[]) => {
    updateValueDispatchCenter([...event]);
  }
  const handleChangeBus = (event: SelectFilter[]) => {
    updateValueSpLinked([...event]);
  }
  const overrideStringsSelectDispatch = {
    "allItemsAreSelected": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_DISPATCH_CENTER.ALL_SELECTED'),
    "search": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_DISPATCH_CENTER.SEARCH'),
    "selectAll": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_DISPATCH_CENTER.ALL_SELECTED'),
    "selectSomeItems": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_DISPATCH_CENTER.SELECT_ITEM'),
  }
  const overrideStringsSelectBus = {
    "allItemsAreSelected": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_BUS.ALL_SELECTED'),
    "search": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_BUS.SEARCH'),
    "selectAll": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_BUS.ALL_SELECTED'),
    "selectSomeItems": t('USERS_PAGE.CONTENT_STEP.STEP4.SELECTED_BUS.SELECT_ITEM'),
  }
  const overrideStringSelectCities = {
    "allItemsAreSelected": t('USERS_PAGE.CONTENT_STEP.STEP4.FILTER_CITY.ALL_SELECTED'),
    "search": t('USERS_PAGE.CONTENT_STEP.STEP4.FILTER_CITY.SEARCH'),
    "selectAll": t('USERS_PAGE.CONTENT_STEP.STEP4.FILTER_CITY.ALL_SELECTED'),
    "selectSomeItems": t('USERS_PAGE.CONTENT_STEP.STEP4.FILTER_CITY.SELECT_ITEM'),
  }
  const removeItemBus = (id: string) => {
    let selectTmp = [...selectBuses];
    const index = selectTmp.findIndex((skill: SelectFilter) => skill.value == id);
    if (index < 0) return;
    selectTmp.splice(index, 1);
    updateValueSpLinked(selectTmp);
  }

  const removeItemCity = (id: string) => {
    let selectTmp = [...selectCities];
    const index = selectTmp.findIndex((skill: SelectFilter) => skill.value == id);
    if (index < 0) return;
    selectTmp.splice(index, 1);
    updateValueCity(selectTmp);
  }
  const handleChangeCities = (event: SelectFilter[]) => {
    updateValueCity([...event]);
  }
  return (
    <Row>
      {/* Select dispatch center  */}
      {/* Move to next release */}
      {!disabledDispatchCenter && <Col sm={6} className='mt-8'>
        <h6>{t('USERS_PAGE.CONTENT_STEP.STEP4.TITLE_SELECT_DISPATCH_CENTER')}</h6>
        <InputGroup className='filter-calender'>
          <InputGroup.Text id='basic-addon1'>
            <KTSVG defaultColor={true} path="/assets/images/icons/service-and-skill.svg" />
          </InputGroup.Text>
          <MultiSelect
            options={optionDispatchCenter || []}
            value={selectDispatchCenter}
            onChange={handleChangeDispatchCenter}
            labelledBy={"Select Buses *"}
            className='form-multi-select form-multi-select-mobile'
            overrideStrings={overrideStringsSelectDispatch}
          />
        </InputGroup>
        {values?.workerDispatchCenter?.length === 0 ? (
          <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_DISPATCH_CENTER')}</div>
        ) : null}
      </Col>}

      {/* Select bus */}
      <Col sm={12} className='mt-8'>
        <h6>{t('USERS_PAGE.CONTENT_STEP.STEP4.TITLE_BUSES', { fullName: rtl ? ` ${values?.firstNameAr} ${values?.lastNameAr} ` : `${values?.firstNameEn} ${values?.lastNameEn}` })}</h6>
        <InputGroup className='filter-calender'>
          <InputGroup.Text id='basic-addon1'>
            <KTSVG defaultColor={true} path="/assets/images/icons/bus.svg" />
          </InputGroup.Text>
          <MultiSelect
            options={optionBus}
            value={selectBuses}
            onChange={handleChangeBus}
            labelledBy={"Select Cities *"}
            className='form-multi-select form-multi-select-mobile'
            overrideStrings={overrideStringsSelectBus}
          />
        </InputGroup>
        {/* {errors?.listSpLinked ? (
          <div className='small text-danger'>{errors?.listSpLinked}</div>
        ) : null} */}

        <div className='list-item--select ms-5'>
          {selectBuses && selectBuses.map((bus: SelectFilter) => (
            <div className="item" key={bus.value}>
              <span className='name'>{bus.label}</span> <span onClick={() => removeItemBus(bus.value)} className='close'> <i className="fas fa-times text-white"></i></span>
            </div>
          ))}
        </div>
      </Col>

      <Col sm={12} className='mt-8'>
        <div className='pb-4'>
          <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid' style={{ width: 'max-content' }}>
            <input className='form-check-input mx-2' type='checkbox' name="workerCanTravel" checked={values?.workerTravel || false}
              onChange={(event) => updateValueWorkerTravel(event.target.checked)} />
            <h6 className='m-auto'>{t('USERS_PAGE.CONTENT_STEP.STEP4.WORK_TRAVEL')}</h6>
          </label>
        </div>
      </Col>

      {/* Select city */}
      <Col sm={12}>
        <InputGroup className='filter-calender'>
          <InputGroup.Text id='basic-addon1'>
            <KTSVG defaultColor={true} path="/assets/images/icons/globe.svg" />
          </InputGroup.Text>
          <MultiSelect
            options={optionCities}
            value={selectCities}
            onChange={handleChangeCities}
            labelledBy={"Select Cities *"}
            className='form-multi-select form-multi-select-mobile'
            overrideStrings={overrideStringSelectCities}
          />
        </InputGroup>
        {errors.listCity
          ? (
            <div className='small text-danger'>{errors.listCity}</div>
          ) : null}

        <div className='list-item--select ms-5'>
          {selectCities && selectCities.map((city: SelectFilter) => (
            <div className="item" key={city.value}>
              <span className='name'>{city.label}</span> <span onClick={() => removeItemCity(city.value)} className='close'> <i className="fas fa-times text-white"></i></span>
            </div>
          ))}
        </div>
      </Col>
      <Col sm={12} className='mt-8'>
        <div className='pb-4'>
          <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle' style={{ width: 'max-content' }}>
            <input className='form-check-input  mx-2' type='checkbox' checked={values?.isActive} onChange={(event) => updateValueActive(event.target.checked)} />
            <h6 className='m-auto'>{t('USERS_PAGE.CONTENT_STEP.STEP4.ACTIVE')}</h6>
          </label>
        </div>
      </Col>
    </Row>
  )
}
export default WorkLocationWorker;