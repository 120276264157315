import { ToastOptions } from "react-toastify";
import { Languages } from "../../app/utils/common/constants";

export const ToastDefaultConfig = () : ToastOptions =>  {
    return {
        position: JSON.parse(localStorage.getItem('persist:b8aksp-auth') || '{}')?.language?.includes(Languages.en) ? "bottom-right" : "bottom-left",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }
}