import {SyntheticEvent, useEffect, useState} from 'react'
import moment from 'moment'
import {Field, FormikProps} from 'formik'
import {clientTimeZoneOffset, FLOW_TYPE, Languages, DEFAULT_WORKER, locale} from 'app/utils/common/constants'
//Components
import AccordionItem from '../../components/AccordionItem'
import {DateTimePickerInline, FieldDateTimePickerInline} from 'shared/DateTime/index'
//Type
import {IForm} from '../../components/type'
import {ticketApi} from 'app/modules/tickets/redux/TicketApi'
import {
  DatePackageAvailable,
  PackageServiceInfoRequest,
  PackageServiceResponse,
  Worker,
} from 'app/modules/tickets/models/OrderDrawerModel'
import {toast} from 'react-toastify'
import {ToastDefaultConfig} from 'setup/toast/ToastConfig'
import {useTranslation} from 'react-i18next'
import {Response} from 'setup/axios/HttpResponse'
import {TimeSlot, TimeSlotPackage} from 'app/utils/common/models'
import {
  getDateSelectedSingleVisitPerWeek,
  highlightDateSeveralVistPackage,
} from 'app/utils/helpers/OrderDrawerHelpers'
import DateTimeVisit from './DateTimeVisit'
import {TSelectedPackageItem} from './type'
import {classNameFunc} from 'app/utils/helpers/components/Form/utils'
import './style.scss'

const DateTimePackage = (props: {
  formProps: FormikProps<IForm>
  language: string
  index?: number
}) => {
  const {
    formProps: {values, validateField, setValues, setFieldTouched},
    index = 0,
    language,
  } = props
  const {t} = useTranslation()
  const flowType = values.flow_type

  const number_visit = values.numberOfVisitPerWeek || 0
  const number_week = values.numberOfWeeks || 0
  const isMulti = flowType && flowType === FLOW_TYPE.PACKAGE && number_visit > 1 && number_week >= 1

  const touched = props.formProps?.touched['date_time']
  let _error = props.formProps?.errors['date_time']

  if (Array.isArray(_error)) {
    _error = _error.filter((e) => e !== undefined && e !== null)[0]
  }

  let [error, params = ''] = ![null, undefined, ''].includes(_error as any)
    ? String(typeof _error == 'object' ? Object.entries(_error).map((e) => e[1])[0] : _error).split(
        '-'
      )
    : [_error, '']
  error = ![null, undefined, ''].includes(error as any)
    ? t(error as string).format(params.split(';'))
    : error

  const isInvalid = touched !== false && touched !== undefined && error !== undefined

  const [state, setState] = useState<{
    minDate: Date
    isLoadingPicker: boolean
    dateHighlighted: TSelectedPackageItem[]
    activeDates: DatePackageAvailable[]
    excludeDates: DatePackageAvailable[]
    selected?: TSelectedPackageItem[]
    cursor?: Date
  }>({
    minDate: new Date(),
    isLoadingPicker: false,
    dateHighlighted: [],
    activeDates: [],
    excludeDates: [],
    cursor: new Date(),
    selected: [],
  })

  const setDateClassName = (
    ...params: Parameters<
      React.ComponentProps<typeof FieldDateTimePickerInline>['customDateClassName']
    >
  ): string | null => {
    if (number_visit === 1 && number_week === 1) return null
    const [date] = params
    const isVertical = number_visit === 1 && number_week > 1
    if (state.selected) {
      if (isVertical) {
        const lastDateSelected = state.selected[state.selected.length - 1]
        if (lastDateSelected && date.isSame(new Date(lastDateSelected.date))) {
          return 'react-datepicker__day--highlighted--last--vertical '
        }
      }
      const transform_date = (
        Array.isArray(state.selected) ? state.selected : [state.selected]
      ).filter((el) => !el.related)
      let nametext = ''
      const idx = transform_date.findIndex((item: TSelectedPackageItem) =>
        new Date(item.date).isSame(date)
      )
      if (idx === -1) {
        return null
      } else {
        if (isVertical) {
          nametext = 'react-datepicker__day--selected--vertical '
        } else {
          if (transform_date.length > 1) {
            if (idx === 0) {
              nametext =
                language === Languages.ar
                  ? 'react-datepicker__day--selected--first--rtl '
                  : 'react-datepicker__day--selected--first '
            }
            if (idx === transform_date.length - 1) {
              nametext =
                language === Languages.ar
                  ? 'react-datepicker__day--selected--last--rtl '
                  : 'react-datepicker__day--selected--last '
            }
          }
        }

        if (
          state.cursor &&
          new Date(transform_date[idx].date).isSame(state.cursor) &&
          transform_date[idx].time === undefined
        ) {
          nametext +=
            transform_date.length === 1
              ? 'react-datepicker__day--showing react-datepicker__day--selected--one '
              : 'react-datepicker__day--showing react-datepicker__day--selected '
        } else {
          nametext +=
            transform_date.length === 1
              ? 'react-datepicker__day--selected--one '
              : 'react-datepicker__day--selected '
        }
      }

      return nametext
    }
    return null
  }

  useEffect(() => {
    setValues((s) => ({
      ...s,
      date_time: undefined,
    }))
    setFieldTouched('date_time', false)

    setState({
      ...state,
      minDate: new Date(),
      isLoadingPicker: false,
      dateHighlighted: [],
      activeDates: [],
      cursor: new Date(),
      selected: [],
    })

    if (values.preSelectSP) {
      let availableDate: Date = new Date().addDays(1)
      let unAvailiableDates: DatePackageAvailable[] = []
      if (values.preSelectSP.dayAvailable && values.preSelectSP.dayAvailable.length !== 0) {
        const dateCheckFirst = values.preSelectSP.dayAvailable.find(
          (day) => day.isAvailable === true
        )?.dateCheck
        unAvailiableDates = values.preSelectSP.dayAvailable.filter((day) => !day.isAvailable)
        if (dateCheckFirst) {
          availableDate = new Date(dateCheckFirst)
        }
      }
      if (!!values.worker && values.worker !== DEFAULT_WORKER && values.worker_available) {
        const otherWorker = values.worker_available.find(
          (s) => 'workerId' in s && s.workerId === values.worker
        ) as Worker
        if (otherWorker && 'workerId' in otherWorker && otherWorker.startDate) {
          unAvailiableDates = otherWorker.listAvailableDay?.filter((day) => !day.isAvailable) || []
          availableDate = new Date(otherWorker.startDate)
        }
      }

      if (number_visit === 1) {
        setState({
          ...state,
          minDate: availableDate,
          excludeDates: unAvailiableDates,
          dateHighlighted: [],
          activeDates: [],
          cursor: undefined,
          isLoadingPicker: false,
          selected: [],
        })
      } else {
        setState({
          ...state,
          minDate: availableDate,
          excludeDates: unAvailiableDates,
          dateHighlighted: [],
          cursor: undefined,
          isLoadingPicker: false,
          selected: [],
          activeDates: [],
        })
      }
    }
  }, [
    values.preSelectSP,
    values.worker,
    values.worker_available,
    values.main_service,
    values.map,
    values.phone,
  ])

  const getDaysAvaliable = async (date: Date, packageInfo: PackageServiceInfoRequest) => {
    try {
      const {data} = (await ticketApi.getPackageAvailable(
        packageInfo
      )) as Response<PackageServiceResponse>

      let availiable = data.packageInfo[0]?.preSelectSP?.dayAvailable || []
      if (values.worker && values.worker !== DEFAULT_WORKER) {
        const days = data.packageInfo[0]?.workers.find((e) => e.workerId === values.worker)
        availiable = days?.listAvailableDay || []
      }
      return {
        dayAvailable: availiable,
        packageShift: data.packageInfo[0]?.shiftType || 0,
      }
    } catch (error) {
      console.log(error)
      toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig())
      return
    }
  }

  const onChangeDate = async (date?: Date | null, event?: SyntheticEvent<any> | undefined) => {
    if (date !== null && date !== undefined) {
      let packageRequest = {
        packageWorkflowId: values.main_service?.data?.packageWorkFlowId || 0,
        countryId: values.location?.countryId,
        isGetAll: true,
        clientTimeOffset: -clientTimeZoneOffset,
        customerId: values.customer_id,
        companyId: values.preSelectSP?.companyId || '',
        majorId: values.main_service?.id || 0,
        selectedDate: moment(date).locale(locale.EN).format('YYYY/MM/DD'),
        selectedPackage: {
          id: Number(values.package),
          spid: values.preSelectSP?.spId || '',
          HasUnratedWorker: false,
          FavWorkerIds: [],
        },
        spIds: values.spIds,
      } as PackageServiceInfoRequest

      if (
        values.workerConfig?.isApply &&
        !values.workerConfig.isRequireMatchWorkerNumber &&
        values.number_of_workers > 1
      ) {
        packageRequest.numbOfSelectWorker = values.number_of_workers
      }
      let cursor = state.cursor !== undefined && date.isSame(state.cursor) ? undefined : date

      // Handle remove date when click date again
      if (state.selected !== undefined && state.selected.length !== 0) {
        let current = [...state.selected]
        const idx = current.findIndex(
          (el) => !el.related && new Date(el.date).isSame(new Date(date))
        )

        if (idx > -1) {
          current = [...state.selected].filter(
            (el) =>
              !(
                (el.related && new Date(el.related).isSame(new Date(date))) ||
                new Date(el.date).isSame(new Date(date))
              )
          )
          let highlighted = []
          let activate = [...state.activeDates]

          if (number_visit > 1 && number_week >= 1) {
            if (current.length === 0) {
              activate = []
            }

            const mainDate = current.filter((e) => !e.related)
            if (mainDate.length !== 0) {
              const selectedStart = mainDate[0].date
              const selectedEnd = mainDate[mainDate.length - 1].date
              highlighted = highlightDateSeveralVistPackage(
                new Date(selectedStart),
                new Date(selectedEnd)
              )
            }
          }
          setState({
            ...state,
            selected: current,
            activeDates: activate,
            dateHighlighted: highlighted,
            cursor: current[0]?.date || undefined,
          })
          changeFieldsValue(current)
          return
        }
      }

      //
      if (number_visit > 1) {
        const totalVisit = number_visit * number_week
        if (state.selected?.length === totalVisit) {
          const mainSelected = state.selected.filter((e) => !e.related)
          const idx = mainSelected.findIndex(
            (e) => cursor && new Date(e.date).isSame(new Date(cursor))
          )
          if (idx === -1) {
            toast.error(
              `${t('ERROR_MESSAGE.DATE_TIME_ERROR.RANGE_LIMIT_NUMBER_DATE')}`,
              ToastDefaultConfig()
            )
            return
          }
        }
        if (number_week >= 1) {
          const pickedDatePerWeek = state.selected?.filter((s) => !s.related) || []
          if (pickedDatePerWeek.length > number_visit) {
            toast.error(
              `${t('ERROR_MESSAGE.DATE_TIME_ERROR.RANGE_LIMIT_NUMBER_DATE')}`,
              ToastDefaultConfig()
            )
            return
          }
        }
      }
      setState({...state, isLoadingPicker: true, cursor})

      let respData:
        | {
            dayAvailable: DatePackageAvailable[]
            packageShift: number
          }
        | undefined = {
        dayAvailable: [],
        packageShift: 0,
      }

      let AvaliableDate
      let arrayDateActive = [...state.activeDates]
      let arrayInActiveDate = [...state.excludeDates]
      if (state.activeDates.length === 0) {
        respData = await getDaysAvaliable(date as any, packageRequest as PackageServiceInfoRequest)

        if (!respData?.dayAvailable || respData.dayAvailable.length === 0) {
          setState({...state, isLoadingPicker: false})
          toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.NOT_AVAILABLE')}`, ToastDefaultConfig())
          return
        }
        arrayDateActive = respData.dayAvailable
        AvaliableDate = respData.dayAvailable.find((e) => e.isAvailable === true)
        const inActiveDates = respData.dayAvailable.filter((e) => !e.isAvailable)

        arrayInActiveDate = arrayInActiveDate.concat(inActiveDates)

        if (values.worker && values.worker !== DEFAULT_WORKER) {
          if (
            respData.dayAvailable.find(
              (e) => new Date(e.dateCheck).isSame(date) && e.isAvailable === true
            )
          ) {
            AvaliableDate = respData.dayAvailable.find(
              (e) => new Date(e.dateCheck).isSame(date) && e.isAvailable === true
            )
          }
        }
      } else {
        AvaliableDate = state.activeDates.find(
          (el) => cursor && el.isAvailable && new Date(el.dateCheck).isSame(new Date(cursor))
        )
        if (!AvaliableDate) {
          setState({...state, isLoadingPicker: false})
          toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.NOT_AVAILABLE')}`, ToastDefaultConfig())
          return
        }
      }

      if (AvaliableDate) {
        if (values.preSelectSP && values.isShowDeliveryTime && values.preSelectSP.visitReminder) {
          const {Before, After} = JSON.parse(values.preSelectSP.visitReminder)
          AvaliableDate.listTimes = AvaliableDate.listTimes?.map((el) => ({
            ...el,
            visitReminderStart: el.start - Number(Before),
            visitReminderEnd: el.start + Number(After),
          }))
        }
        if (!new Date(AvaliableDate.dateCheck).isSame(date)) {
          cursor = AvaliableDate.dateCheck

          arrayInActiveDate.push({
            dateCheck: new Date(date),
            isAvailable: false,
          } as DatePackageAvailable)
          toast.error(
            `${t('ERROR_MESSAGE.DATE_TIME_ERROR.NEAREST_AVALIABLE_DATE')} ${cursor}`,
            ToastDefaultConfig()
          )
        }

        const timeSlotTemp = AvaliableDate?.listTimes || []

        const timeSlot = timeSlotTemp.map((s, index) => ({...s, isSelected: false}))

        // PreSelect TimeSlot start
        let initTime: TimeSlotPackage | undefined
        const availiableTimeSlots = timeSlot.filter((el) => el.isAvailable)
        switch (
          respData.packageShift // Filter timeSlot allow packageShift
        ) {
          case 1: //Morning Shift  5am --> 4pm
            if (availiableTimeSlots.find((el) => el.start >= 300 && el.start < 960) !== undefined) {
              initTime = availiableTimeSlots.find((el) => el.start >= 300 && el.start < 960)
            }
            break
          case 2: // Night Shift 4pm --> 5am ( of the next day)
            if (availiableTimeSlots.find((el) => el.start < 300 || el.start >= 960) !== undefined) {
              initTime = availiableTimeSlots.find((el) => el.start < 300 || el.start >= 960)
            }
            break
          default: // All
            if (availiableTimeSlots.length === 1) {
              initTime = availiableTimeSlots[0]
            }
            break
        }
        if (initTime) {
          initTime.isSelected = true
        }
        // PreSelect TimeSlot end

        let selected = [] as typeof state.selected
        if (cursor) {
          if (!isMulti) {
            selected =
              number_visit === 1 && number_week > 1
                ? getDateSelectedSingleVisitPerWeek(
                    new Date(cursor),
                    [],
                    number_week,
                    timeSlot,
                    initTime
                  )
                : [{date: cursor, time: initTime, timeSlot: timeSlot, related: undefined}]
          } else {
            selected = [
              ...(state.selected || []),
              ...((state.selected || []).findIndex((e) => cursor && e.date.isSame(cursor)) < 0
                ? getDateSelectedSingleVisitPerWeek(
                    new Date(cursor),
                    state.selected || [],
                    number_week,
                    timeSlot,
                    initTime
                  )
                : []),
            ].sort((a, b) => a.date.getTime() - b.date.getTime())
          }
        }

        if (cursor !== null && cursor !== undefined) {
          if (number_visit === 1 && number_week > 1) {
            setState({
              ...state,
              cursor,
              selected,
              isLoadingPicker: false,
              dateHighlighted: getDateSelectedSingleVisitPerWeek(
                new Date(cursor),
                [],
                number_week,
                timeSlot,
                initTime
              ),
              excludeDates: arrayInActiveDate,
            })
          } else if (number_visit > 1 && number_week >= 1) {
            let highlighted = []
            if (cursor) {
              let start = cursor
              if (state.selected && state.selected.length !== 0) {
                const mainDate = state.selected.filter((e) => !e.related)
                const selectedStart = mainDate[0].date
                const selectedEnd = mainDate[mainDate.length - 1].date
                if (new Date(cursor).getTime() >= selectedEnd.getTime()) {
                  start = mainDate[0].date
                  highlighted = highlightDateSeveralVistPackage(new Date(start), new Date(cursor))
                } else if (
                  new Date(cursor).getTime() > selectedStart.getTime() &&
                  new Date(cursor).getTime() < selectedEnd.getTime()
                ) {
                  highlighted = highlightDateSeveralVistPackage(
                    new Date(selectedStart),
                    new Date(selectedEnd)
                  )
                } else {
                  highlighted = highlightDateSeveralVistPackage(
                    new Date(cursor),
                    new Date(selectedEnd)
                  )
                }

                if (mainDate.findIndex((e) => e.time === undefined) > -1) {
                  cursor = mainDate[mainDate.findIndex((e) => e.time === undefined)].date
                }
              }
            }
            setState({
              ...state,
              cursor,
              selected,
              isLoadingPicker: false,
              dateHighlighted: highlighted,
              activeDates: arrayDateActive,
              excludeDates: arrayInActiveDate,
            })
          } else {
            setState({
              ...state,
              cursor,
              selected,
              excludeDates: arrayInActiveDate,
              isLoadingPicker: false,
            })
          }
        }

        changeFieldsValue(selected)
      } else {
        setState({...state, isLoadingPicker: false})
        toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig())
        return
      }
    }
  }

  const onSelectTimeSlot = (d: Date, e: TimeSlotPackage) => {
    if (!e.isAvailable || state.cursor === undefined) return

    const selected = [...(state.selected || [])]
    const find = selected.find((e) => new Date(e.date).isSame(d))
    if (find == null) return
    find.timeSlot?.forEach((slot) =>
      slot.start === e.start ? (slot.isSelected = true) : (slot.isSelected = false)
    )

    if (number_visit === 1 && number_week > 1) {
      selected.forEach((item) => {
        item.time = e
      })
    } else if (number_visit > 1 && number_week >= 1) {
      selected.forEach((item) => {
        if (
          (item.related && item.related.isSame(new Date(d))) ||
          (!item.related && item.date.isSame(new Date(d)))
        ) {
          item.time = e
        }
      })
    } else {
      find.time = e
    }

    setState({...state, selected})
    changeFieldsValue(selected)
  }

  const onClearSelected = (date: Date, time?: TimeSlotPackage) => {
    let selected = [...(state.selected || [])].filter((e) =>
      time === undefined ? (!e.related ? !e.date.isSame(date) : !e.related?.isSame(date)) : true
    )
    if (time !== undefined) {
      if (number_visit === 1 && number_week > 1) {
        const find = selected.filter((e) => e.date.isSame(date) || e.related?.isSame(date)) || []
        if (find) {
          find.forEach((item) =>
            item.timeSlot?.forEach((slot) =>
              slot.start === time.start ? (slot.isSelected = false) : slot.isSelected
            )
          )
        }
        selected = selected.map((s) => ({
          ...s,
          time: undefined,
        }))
      } else if (number_visit > 1 && number_week >= 1) {
        const find = selected.filter((e) => e.date.isSame(date) || e.related?.isSame(date)) || []
        if (find) {
          find.forEach((item) =>
            item.timeSlot?.forEach((slot) =>
              slot.start === time.start ? (slot.isSelected = false) : slot.isSelected
            )
          )
        }
        selected = selected.map((s) =>
          s.date.isSame(date) || s.related?.isSame(date)
            ? {
                ...s,
                time: undefined,
              }
            : s
        )
      } else {
        const find = selected.find((e) => e.date.isSame(date))
        if (find) find.time = undefined
        find?.timeSlot?.forEach((slot) =>
          slot.start === time.start ? (slot.isSelected = false) : slot.isSelected
        )
      }
    }
    let highlighted = [...state.dateHighlighted]
    let actives = [...state.activeDates]
    let newCursor = state.cursor
    if (number_visit > 1 && number_week >= 1) {
      if (selected.length === 0) {
        highlighted = []
        actives = []
        newCursor = undefined
      } else {
        const mainSelected = selected.filter((e) => !e.related)
        newCursor = mainSelected[mainSelected.length - 1].date
        highlighted = highlightDateSeveralVistPackage(
          mainSelected[0].date,
          mainSelected[mainSelected.length - 1].date
        )
      }
    } else if (number_visit === 1 && number_week > 1) {
      if (time === undefined) {
        highlighted = []
      }
    } else {
      highlighted = []
    }
    if (number_visit > 1 && number_week >= 1) {
      setState({
        ...state,
        dateHighlighted: highlighted,
        activeDates: actives,
        selected,
        ...(time !== undefined ? {cursor: date} : {cursor: newCursor}),
      })
    } else {
      setState({
        ...state,
        dateHighlighted: highlighted,
        selected,
        ...(time === undefined && {cursor: selected[0]?.date}),
      })
    }
    changeFieldsValue(selected)
  }

  const changeFieldsValue = (selected: typeof state.selected) => {
    setValues((s) => ({
      ...s,
      date_time:
        isMulti === true || (number_visit === 1 && number_week > 1)
          ? (selected as TSelectedPackageItem[])
          : (selected?.[0] as {
              date?: Date
              time?: TimeSlot
              timeSlot?: TimeSlot[]
              customerVisitDuration?: number
            }),
      selected_visit: selected || ([] as TSelectedPackageItem[]),
    }))
    validateField('date_time')
  }

  const timeSlotsOption =
    state.cursor !== undefined
      ? state.selected?.find((e) => new Date(e.date).isSame(state.cursor as any))?.timeSlot
      : undefined

  const selectDateRequired =
    number_visit === 1
      ? t('CREATE_ORDER_DRAWER.SECTION_3.NUMBER_SELECT_1_DATE')
      : number_visit === 2
      ? t('CREATE_ORDER_DRAWER.SECTION_3.NUMBER_SELECT_2_DATE')
      : t('CREATE_ORDER_DRAWER.SECTION_3.NUMBER_SELECT_MULTI_DATE', {num: number_visit})

  return (
    <AccordionItem id='date_time' title={`${index}. ${t('CREATE_ORDER_DRAWER.SECTION_3.TITLE')}`}>
      <p className='section-title'>{t('CREATE_ORDER_DRAWER.SECTION_3.SUB_TITLE')}</p>
      {!(values.worker === null || values.worker === undefined) &&
        number_visit >= 1 &&
        number_week >= 1 && <span>{selectDateRequired}</span>}
      <div className={classNameFunc(isInvalid && 'has-error')}>
        {isInvalid && <p className='text-danger invalid-feedback'>{error}</p>}
      </div>
      <div className='date-time-picker-inline-component'>
        <Field
          name='date_time'
          component={DateTimePickerInline}
          value={state.cursor}
          minDate={state.minDate}
          disableDate={values.worker === null || values.worker === undefined}
          customDateClassName={setDateClassName}
          onChange={onChangeDate}
          isLoading={state.isLoadingPicker}
          highlightDates={state.dateHighlighted}
          activeDates={state.activeDates}
          excludeDates={state.excludeDates}
          openToDate={state.minDate}
          language={language}
        />
        <DateTimeVisit
          isLoading={state.isLoadingPicker}
          isMulti={isMulti === true}
          isDeliveryTime={values.isShowDeliveryTime && !!values.preSelectSP?.visitReminder}
          isEmpty={timeSlotsOption?.filter((e) => e.isAvailable).length === 0}
          showWarning={false}
          timeSlotsOption={timeSlotsOption || []}
          dateTimeOptions={state.selected || []}
          lang={language}
          onSelectTimeSlot={onSelectTimeSlot}
          onClearSelected={onClearSelected}
        />
      </div>

      <p>
        {number_visit && number_week && !(number_visit === 1 && number_week === 1) ? (
          <p className='py-2'>
            <span style={{color: 'red'}}>{t('CREATE_ORDER_DRAWER.SECTION_3.NOTE_TITLE')}</span>
            {number_week % 4 === 0
              ? number_week / 4 === 1
                ? `${t('CREATE_ORDER_DRAWER.SECTION_3.REPEAT_MONTH', {
                    frequency: (number_week / 4).toString(),
                  })}`
                : `${t('CREATE_ORDER_DRAWER.SECTION_3.REPEAT_MONTHS', {
                    frequency: (number_week / 4).toString(),
                  })}`
              : `${t('CREATE_ORDER_DRAWER.SECTION_3.REPEAT_WEEK', {
                  frequency: number_week.toString(),
                })}`}
          </p>
        ) : null}
      </p>
    </AccordionItem>
  )
}

export default DateTimePackage
