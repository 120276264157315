import { Languages } from "../common/constants";

export const minuteToTime = (value: number, is12h?: boolean, upperCase?: boolean, language?: string) => {
    // type = true -> 12h ; type = false -> 24h
    let tt = "";
    let h: any = Math.floor(value / 60);
    h = (h > 9) ? h : ('0' + h);
    let m: any = value % 60;
    m = (m > 9) ? m : ('0' + m);

    if (is12h) {
        if (upperCase) {

            if ((h >= 12 && h <= 24)) {
                tt = (language === Languages.en) ? 'PM' : 'م';
            } else {
                tt = (language === Languages.en) ? 'AM' : 'ص';
            }
        } else {
            tt = (h >= 12 && h <= 24) ? 'pm' : 'am';

        }

        if (h > 12) {
            h -= 12;
            if (h < 12) {
                h = (h > 9) ? h : ('0' + h);
            } else {
                h -= 12;
                h = ('0' + h);
                if (upperCase === null || upperCase === false) {
                    tt = 'am';
                } else {
                    tt = (language === Languages.en) ? 'AM' : 'ص';
                }
            }
        }
        return (h + ':' + m + ' ' + tt);
    } else {

        return (h + ':' + m);
    }
};