import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap-v5';

import { useAppSelector } from '../../../../../../setup/redux/Hooks';
import { Currency, Languages, TicketStatus } from '../../../../../utils/common/constants';
import { TicketType } from '../../../../../utils/common/enum';
import { MajorServiceModel } from '../../../../../utils/common/models';
import { selectServiceScope } from '../../../../auth';
import { TicketModel } from '../../../models';

type Props = {
    data?: TicketModel | null;
    rtl?: boolean;
}

const InvoiceDetail: React.FC<Props> = ({ data, rtl }) => {
    const [shoppingCart, setShoppingCart] = useState<any | null>(null);
    const [majorServices, setMajorService] = useState<MajorServiceModel | null>(null);
    const [hasSpecialOrder, setHasSpecialOrder] = useState(false);
    const [discountValue, setDiscountValue] = useState('');
    const [currency, setCurrency] = useState(data?.currency);
    const [minCharge, setMinCharge] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const serviceScope = useAppSelector(selectServiceScope);
    const [isConnectingQuestion, setIsConnectingQuestion] = useState<boolean>(false);
    
    const formatShoppingCartJson = () => {
        if (data) {
            setCurrency(Currency.currency[data.currency]?.[(rtl ? Languages.ar : Languages.en)] || data?.currency);

            if (data.majorId) {
                setMajorService(serviceScope?.majorServices?.find(x => x.id === data.majorId) || null);
            }

            setHasSpecialOrder(false);

            if (data?.shoppingCartJson) {
                let shoppingCart = JSON.parse(data.shoppingCartJson);
                setIsConnectingQuestion(shoppingCart.isNoSubSubShoppingCart);
                if (shoppingCart) {
                    if (shoppingCart.isShowMinCharge == undefined || shoppingCart.isShowMinCharge) {
                        shoppingCart.isShowMinCharge = true;
                    }

                    if (shoppingCart && shoppingCart.subServices) {
                        setHasSpecialOrder(shoppingCart.priceUponVisit);

                        // update sub service name for ticket b8ak agent
                        shoppingCart.subServices.forEach((subService: any) => {
                            if(subService.id && (!subService.subNameEn || !subService.subNameAr)) {
                                const sub = serviceScope?.subServices?.find(c=> c.id == subService.id);
                                subService.subNameEn = sub?.nameEn;
                                subService.subNameAr = sub?.nameAr;
                            }
                        })
                    }

                    let minCharge = data.status.startsWith(TicketStatus.Disputed) && shoppingCart.providerMinCharge ? shoppingCart.providerMinCharge : data.minCharge || 0;
                    setMinCharge(minCharge);

                    if (data.promoCode) {
                        setDiscountValue(data.promoCode + ' | ' + data.discountValue + " " + (data.discountType != 1 ? shoppingCart.currency : "%") + " off");
                        let totalPrice = shoppingCart.isShowMinCharge && minCharge > shoppingCart.totalPrice ? minCharge : shoppingCart.totalPrice as number;
                        let discountPrice = data.discountType == 1 ? ((totalPrice * data.discountValue) / 100) : data.discountValue;
                        let totalPriceDiscountVoucher = discountPrice <= shoppingCart.maximumDiscountAmount || shoppingCart.maximumDiscountAmount == 0 ? totalPrice - discountPrice : totalPrice - shoppingCart.maximumDiscountAmount;
                        totalPriceDiscountVoucher = totalPriceDiscountVoucher > 0 ? totalPriceDiscountVoucher : 0;
                        shoppingCart.totalPriceDiscountVoucher = totalPriceDiscountVoucher;
                        shoppingCart.discountAmount = shoppingCart.discountType == 'numeric' ? (totalPrice - totalPriceDiscountVoucher) : shoppingCart.discountAmount;
                    }

                    let total = 0;

                    if (minCharge > shoppingCart.totalPrice && shoppingCart.isShowMinCharge) {

                        if (data.promoCode && shoppingCart.discountAmount > 0 && minCharge > 0) {
                            total = minCharge - shoppingCart.discountAmount;
                        } else {
                            total = minCharge
                        }
                    } else if (data.promoCode && shoppingCart.discountAmount > 0 && shoppingCart.totalPrice > 0) {
                        total = shoppingCart.totalPrice - shoppingCart.discountAmount;
                    } else {
                        total = shoppingCart.totalPrice
                    }

                    total += (shoppingCart.delivery ? shoppingCart.delivery.fee : 0);

                    setTotalPrice(total);
                    setShoppingCart(shoppingCart);

                } else {
                    setShoppingCart(null);
                }
            } else {
                setShoppingCart(null);
            }
        }
    }

    useEffect(() => {
        formatShoppingCartJson();
    }, [data])

    if (!data) return <></>;

    return (
        <ListGroup variant="flush" className='ticket-detail border rounded h-400'>
            {!shoppingCart && <ListGroup.Item>
                <div className='d-flex'>
                    <i className="fas fa-user-circle fs-5x"></i>
                    <div className='d-flex flex-column ms-5'>
                        <span className='mb-2'>{data?.customerName}</span>
                        <span className='mb-2'>ID: {data?.customerId}</span>
                        <span className='mb-2'>{data?.customerPhoneNumber}</span>
                        <span className='mb-2'>{data?.customerEmail}</span>
                    </div>
                </div>
            </ListGroup.Item>}
            {data?.ticketType === TicketType.SUBSCRIPTION || data.packageId ?
                <>
                    <ListGroup.Item>
                        <div className="my-3">
                            <strong>{t('VIEW_TICKET_MODAL.INVOICE_DETAIL')}:</strong>
                        </div>
                        <div className="my-3">
                            <strong>{rtl ? majorServices?.nameAr : majorServices?.nameEn}</strong>
                        </div>
                        {!!data.promoCode &&
                            <div className="d-flex justify-content-between my-3">
                                <span>{t('VIEW_TICKET_MODAL.PROMO')}: </span>
                                <strong>{discountValue}</strong>
                            </div>
                        }
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div className="d-flex justify-content-between my-3">
                            <strong>{t('VIEW_TICKET_MODAL.TOTAL')}</strong>
                            <strong>{data.price} {currency}</strong>
                        </div>
                    </ListGroup.Item>
                </>
                :
                <>
                    {(data?.ticketType === TicketType.OFFER || data?.ticketType === TicketType.WARRANTY_OFFER) ?
                        <div className='p-5'>
                            <div className=' mb-3'>
                                <span className='fw-bold'>{t('VIEW_TICKET_MODAL.OFFER_TITLE')}: </span>
                                <span>{rtl ? data.offerTitle : data.offerTitleEn}</span>
                            </div>
                            <div className='mb-3'>
                                <div className='fw-bold'>{t('VIEW_TICKET_MODAL.OFFER_DESCRIPTION')}:</div>
                                <div dangerouslySetInnerHTML={{ __html: rtl ? data.offerDescription : data.offerDescriptionEn }}></div>
                            </div>
                            <div>
                                <span className='fw-bold'>{t('VIEW_TICKET_MODAL.B8AK_PERCENTAGE')}: </span>
                                <span>{data.b8akPercentage}%</span>
                            </div>
                        </div>
                        :
                        <>
                            {shoppingCart &&
                                <>
                                    <div className='p-5'>
                                        <h4 className='text-gray-800'>{t("VIEW_TICKET_MODAL.INITIAL_INVOICE")}</h4>
                                    </div>
                                    <ListGroup.Item>
                                        <div className='mh-200px scroll scroll-invoice'>
                                            {
                                                data.isNoSubSubShoppingCart ?
                                                    <>
                                                        {shoppingCart.subServices.map((subService: any, key: number) => (
                                                            <div className='mb-3' key={key}>
                                                                <div className='text-bold'>{rtl ? subService.subNameAr : subService.subNameEn}</div>
                                                                {
                                                                    subService.subSubServices?.map((subSubService: any, indexSub: number) => {
                                                                        if (subSubService.isShowSubsubQuestion) {
                                                                            return (
                                                                                <div key={indexSub}>
                                                                                    <div>{(rtl ? subSubService.NameAr : subSubService.NameEn) || (rtl ? subSubService.nameAr : subSubService.nameEn)}</div>
                                                                                    <hr />
                                                                                </div>)
                                                                        }
                                                                    })
                                                                }
                                                            </div>


                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {shoppingCart.subServices?.map((subService: any, key: number) => {
                                                            return (
                                                                <div className='my-3' key={key}>
                                                                    <div className='text-bold'>{rtl ? subService.subNameAr : subService.subNameEn}</div>

                                                                    {subService.subSubServices?.map((subSubService: any, indexSub: number) => {
                                                                        return <div className="d-flex justify-content-between ms-2" key={indexSub}>
                                                                            <div className='w-300px'>{subSubService.quantity} x {(rtl ? subSubService.NameAr : subSubService.NameEn) || (rtl ? subSubService.nameAr : subSubService.nameEn)}</div>
                                                                            {subSubService.isSpecifiedPrice ? <strong>{subSubService.price * subSubService.quantity} {currency}</strong> : t('VIEW_TICKET_MODAL.UPON_CHECKUP')}
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            )
                                                        })}
                                                        <div className='d-flex justify-content-between  my-3'>
                                                            <div>{t('VIEW_TICKET_MODAL.TOTAL_CHARGE')}</div>
                                                            <div>
                                                                <div className='text-end'>
                                                                    <strong>{shoppingCart?.totalPrice?.toFixed(2)} {currency}</strong>
                                                                </div>
                                                                {hasSpecialOrder && <strong className='small'> + {t('OTHER_UPON_VISIT')}</strong>}
                                                            </div>

                                                        </div>
                                                    </>
                                            }

                                            {shoppingCart.delivery &&
                                                <div className='d-flex justify-content-between  my-3'>
                                                    <span>{t('VIEW_TICKET_MODAL.SPARE_PART_FEE')}</span>
                                                    <strong>{shoppingCart.delivery.fee.toFixed(2)} {currency}</strong>
                                                </div>
                                            }

                                            {!!data.promoCode &&
                                                <>
                                                    <div className='d-flex justify-content-between mt-3'>
                                                        <div>{t('VIEW_TICKET_MODAL.PROMO')}</div>
                                                        <div className='text-success fw-bold'>{discountValue}</div>
                                                    </div>
                                                    {!!shoppingCart.maximumDiscountAmount && shoppingCart.maximumDiscountAmount > 0 &&
                                                        <div className='text-muted small'>
                                                            {t('VIEW_TICKET_MODAL.MAX_DISCOUNT', {price : shoppingCart.maximumDiscountAmount, currency: currency})}
                                                        </div>
                                                    }

                                                    {!!shoppingCart.minimumOrderValue && shoppingCart.minimumOrderValue > 0 ?
                                                        <div className='text-muted small'>
                                                            {t('VIEW_TICKET_MODAL.MIN_DISCOUNT', {price : shoppingCart.minimumOrderValue, currency: currency})}
                                                        </div>
                                                        :
                                                        <></>
                                                    }

                                                    {data.discountType === 1 &&
                                                        <div className='d-flex justify-content-between my-3'>
                                                            <span>{t('VIEW_TICKET_MODAL.DISCOUNT')}</span>
                                                            <strong>
                                                                {
                                                                    shoppingCart.discountType == 'numeric' ?
                                                                        <span>-{Math.round(shoppingCart.discountAmount * 100) / 100} {currency}</span>
                                                                        :
                                                                        <span>-{shoppingCart.discountAmount} %</span>
                                                                }
                                                            </strong>
                                                        </div>
                                                    }
                                                </>

                                            }


                                            {shoppingCart.isShowMinCharge &&
                                                <div className='d-flex justify-content-between my-3'>
                                                    <div>{t('VIEW_TICKET_MODAL.MIN_CHARGE')}</div>
                                                    <strong>{minCharge.toFixed(2)} {currency}</strong>
                                                </div>
                                            }
                                        </div>
                                    </ListGroup.Item>
                                    {!isConnectingQuestion && <ListGroup.Item>
                                        <div className='d-flex justify-content-between my-3'>
                                            <p>
                                            {t('VIEW_TICKET_MODAL.TOTAL')}
                                            </p>
                                            <div>
                                                <div className='text-end'><strong>{totalPrice.toFixed(2)} {currency}</strong></div>
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-between'>
                                            <div className='small'>
                                                {shoppingCart.isNotIncludeVat && <span>{t('VIEW_TICKET_MODAL.VAT_NOT_INCLUDED')}</span>}
                                            </div>
                                            <div>
                                                {hasSpecialOrder && <strong className='small'> + {t('OTHER_UPON_VISIT')}</strong>}
                                            </div>
                                        </div>
                                    </ListGroup.Item>}
                                </>


                            }
                        </>
                    }
                </>
            }

        </ListGroup >
    )

}

export { InvoiceDetail }
