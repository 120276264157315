import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { authActions, selectLanguage, selectServiceScope } from '../../auth'
import { useAppDispatch, useAppSelector } from '../../../../setup/redux/Hooks'
import { Languages, SELECT_TIME_IN_WEEK, USER_TYPE } from '../../../utils/common/constants'
import {
  selectActionUserModal,
  selectNationalityData,
  selectLoadingCreateUser,
  selectUserModalData,
  userActions,
  selectSkillData,
  selectCityData,
  selectTeamData,
  createdUserSuccess,
  selectDispatcherData,
  selectDriverData,
  selectDispatchCenterData,
  selectBusNotLinkedDriverData,
  selectOperationHoursAndMapData,
  selectAllAdmin,
  selectAdminIdEdit,
  selectTechIdEdit,
  selectWorkerIdEdit,
} from '../redux/UserSlice'
import { UserFormModel, UserModel, UsersTypeParams } from '../models'
import CreateUserModal from './create-user/CreateUserModal'
import { convertOperationObjectToList } from '../../../utils/helpers'
import { UpdateAdminModal } from './update-admin/UpdateAdminModal'
import { UpdateTechModal } from './update-tech/UpdateTechModal'
import { UpdateWorkerModal } from './update-worker/UpdateWorkerModal'

const UserModal: React.FC = () => {
  const { t } = useTranslation()
  const [flagUserTypeBus, setFlagUserTypeBus] = useState(false)
  const [flagUserTypeDriver, setFlagUserTypeDriver] = useState(false)
  const [flagUserTypeWorker, setFlagUserTypeWorker] = useState(false)
  const formInitData = {
    userType: USER_TYPE.ADMIN,
    avatar: '',
    fullNameAr: '',
    fullNameEn: '',
    firstNameEn: '',
    firstNameAr: '',
    lastNameEn: '',
    lastNameAr: '',
    email: '',
    phone: '',
    workerId: '',
    cityId: '',
    firstPhoneNumber: '+966',
    nationality: '',
    password: '',
    confirmPassword: '',
    listWorkerSkill: [],
    listService: [],
    pricePerMonth: '',
    listSpLinked: [],
    workerTravel: false,
    isActive: false,
    isEditPermission: false,
    listCity: [],
    linkedDispatchers: [],
    dispatchCenterId: '',
    driverId: '',
    linkedBuses: [],
    regularHours: JSON.parse(JSON.stringify(SELECT_TIME_IN_WEEK)),
    ramadanHours: JSON.parse(JSON.stringify(SELECT_TIME_IN_WEEK)),
    transportationTimeBefore: '',
    transportationTimeAfter: '',
    serviceArea: [],
    adminId: '',
    workerDispatchCenter: [],
    dispatchCenterLocation: undefined,
    listServiceId: undefined,
    capacity: 0,
    capacitySetup: 0,
    sharedCapacity: null
  };

  const dispatch = useAppDispatch()
  const language = useAppSelector(selectLanguage)
  const isShowViewModal = useAppSelector(selectActionUserModal)
  const isCreatedUserSuccess = useAppSelector(createdUserSuccess)
  const loadingCreate = useAppSelector(selectLoadingCreateUser)
  const nationalities = useAppSelector(selectNationalityData)
  const skills = useAppSelector(selectSkillData)
  const linkedDispatchers = useAppSelector(selectDispatcherData)
  const services = useAppSelector(selectServiceScope)
  const teams = useAppSelector(selectTeamData)
  const cities = useAppSelector(selectCityData)
  const dispatchCenters = useAppSelector(selectDispatchCenterData)
  const drivers = useAppSelector(selectDriverData)
  const busesNotLinkedDriver = useAppSelector(selectBusNotLinkedDriverData)
  const operationHoursAndMap = useAppSelector(selectOperationHoursAndMapData)
  const rtlMode = language === Languages.ar;
  const dispatchListAdmin = useAppSelector(selectAllAdmin);

  const adminIdEdit = useAppSelector(selectAdminIdEdit)
  const techIdEdit = useAppSelector(selectTechIdEdit)
  const workerIdEdit = useAppSelector(selectWorkerIdEdit)

  useEffect(() => {
    let paramAdmin: UsersTypeParams = {
      isActive: true,
      userType: '400'
    }
    dispatch(userActions.fetchAdminData(paramAdmin))
  }, [])

  useEffect(() => {
    if (workerIdEdit && teams.length === 0) {
      dispatch(userActions.fetchTeamData())
    }
  }, [workerIdEdit])
  useEffect(() => {
    if (!isShowViewModal) {
      setFlagUserTypeWorker(false);
      setFlagUserTypeDriver(false);
      setFlagUserTypeBus(false);
    }
    if (!nationalities || nationalities.length == 0) dispatch(userActions.fetchNationalityData())
    if (!skills || skills.length == 0) dispatch(userActions.fetchSkillData())
    if (!services) dispatch(authActions.getServiceScope())
    if (!cities || cities.length == 0) dispatch(userActions.fetchCityData())
    if (!dispatchCenters || dispatchCenters.length == 0)
      dispatch(userActions.fetchDispatchCenterData())

    if (techIdEdit && !operationHoursAndMap.length) {
      dispatch(userActions.fetchOperationHoursAndMapData())
    }
  }, [isShowViewModal, adminIdEdit, techIdEdit])


  const completeStepUserType = (userType: string) => {
    if (!userType) return
    switch (userType) {
      case USER_TYPE.WORKER:
        if (!flagUserTypeWorker) {
          dispatch(userActions.fetchTeamData())
          setFlagUserTypeWorker(true)
        }

        break
      case USER_TYPE.DRIVER:
        if (!flagUserTypeDriver) {
          dispatch(userActions.fetchBusNotLinkedDriverData())
          setFlagUserTypeDriver(true)
        }
        break
      case USER_TYPE.TECHNICIAN:
        if (!flagUserTypeBus) {
          // dispatch(userActions.fetchDriversData())
          dispatch(userActions.fetchOperationHoursAndMapData())
          setFlagUserTypeBus(true)
        }
        break
    }
  }

  const handleCloseUpdateAdmin = () => {
    dispatch(userActions.closeUpdateAdminUser());
  }
  const handleUpdatedAdmin = (data: UserModel) => {
    dispatch(userActions.updateAdminUserSuccess(data));
  }

  const handleCloseUpdateTech = () => {
    dispatch(userActions.closeUpdateTechUser());
  }

  const handleUpdatedTech = (data: UserModel) => {
    dispatch(userActions.updateTechUserSuccess(data));
  }

  const handleCloseUpdateWorker = () => {
    dispatch(userActions.closeUpdateWorkerUser());
  }

  const handleUpdatedWorker = (data: UserModel) => {
    dispatch(userActions.updateWorkerUserSuccess(data));
  }

  return (
    <>
      <CreateUserModal
        onCompleteStepUserType={completeStepUserType}
        initialValues={formInitData}
        isShowViewModal={isShowViewModal}
        isCreatedUserSuccess={isCreatedUserSuccess}
        loadingCreate={loadingCreate}
        nationalities={nationalities}
        skills={skills}
        cities={cities}
        linkedDispatchers={linkedDispatchers}
        services={services}
        teams={teams}
        dispatchCenters={dispatchCenters}
        drivers={drivers}
        busesNotLinkedDriver={busesNotLinkedDriver}
        operationHoursAndMap={operationHoursAndMap}
        rtlMode={rtlMode}
        listAdmin={dispatchListAdmin}
      />
      <UpdateAdminModal adminId={adminIdEdit} onHide={handleCloseUpdateAdmin} nationalities={nationalities} onUpdated={handleUpdatedAdmin} />
      <UpdateTechModal
        techId={techIdEdit}
        busesNotLinkedDriver={busesNotLinkedDriver}
        listAdmin={dispatchListAdmin}
        skills={skills}
        services={services}
        dispatchCenters={dispatchCenters}
        drivers={drivers}
        teams={teams}
        cities={cities}
        linkedDispatchers={linkedDispatchers}
        onHide={handleCloseUpdateTech}
        operationHoursAndMap={operationHoursAndMap}
        nationalities={nationalities}
        onUpdated={handleUpdatedTech}
        rtlMode={rtlMode} />
      <UpdateWorkerModal
        workerId={workerIdEdit}
        busesNotLinkedDriver={busesNotLinkedDriver}
        listAdmin={dispatchListAdmin}
        skills={skills}
        services={services}
        dispatchCenters={dispatchCenters}
        drivers={drivers}
        teams={teams}
        cities={cities}
        linkedDispatchers={linkedDispatchers}
        onHide={handleCloseUpdateWorker}
        operationHoursAndMap={operationHoursAndMap}
        nationalities={nationalities}
        onUpdated={handleUpdatedWorker}
        rtlMode={rtlMode} />
    </>
  )
}

export default UserModal
