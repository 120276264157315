import { ERROR_CODE, HTTP_STATUS } from '../constants/Http';
import { AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { ToastDefaultConfig } from '../toast/ToastConfig';
import { EventBus } from '../../app/utils/helpers';

import { EventBusName, Languages } from '../../app/utils/common/constants';
const _decodeURIComponent = (str: string) => {
  try {
    return decodeURIComponent(str);
  } catch (error) {
    return str;
  }
}

export const object2Query = (data?: { [k: string]: any }, isPrefix?: boolean, isEndCode?: boolean) => {
  if (data == undefined) return '';
  const _encodeURIComponent = [undefined, true].includes(isEndCode) ? encodeURIComponent : (e: any) => e
  const queryString = Object.keys(data || {})
    .map(k => {
      if ([undefined, null, ''].includes(data[k]))
        return null;
      else if (data[k].constructor == Array)
        return data[k].map((e: any) => object2Query({ [k]: e }, false, isEndCode)).join('&');
      return `${k}=${_encodeURIComponent(data[k])}`;
    })
    .filter(e => e != null)
    .join('&');
  return queryString == '' ? '' : ((isPrefix === true ? '?' : '') + queryString)
};

export const queryParams: { [key: string]: any } = (path?: string) => {
  return (path || '').match(new RegExp('([^?=&]+)(=([^&]*))', 'g')) || []
  .reduce(function (result: any, each: any) {
    let [key, value] = each.split('=');
    (result as any)[key] = _decodeURIComponent(value);
    return result;
  }, {});
};
export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { authInfo },
      } = store.getState()

      if (authInfo && authInfo.token) {
        config.headers.Authorization = `Bearer ${authInfo.token}`
      }
      let language = 'ar';
      try {
        language = JSON.parse(localStorage.getItem('persist:b8aksp-auth') || '{}').language || 'ar';
      } catch {

      }
      config.headers.Language = language.includes(Languages.en) ? Languages.en : Languages.ar;
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  // Add a response interceptor
  axios.interceptors.response.use(function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === HTTP_STATUS.CREATED || response.status === HTTP_STATUS.OK) {
      return response.data;
    }
    return Promise.reject(response);
  }, function (err: any) {
    const { status, data } = err.response;
    if (err.message === 'Network Error' && !err.response) {
      toast.error("Network error - make sure API is running", ToastDefaultConfig());
      return Promise.reject('Network error');
    }

    if (status === HTTP_STATUS.UNAUTHORIZED) {
      EventBus.getInstance().dispatch<null>(EventBusName.UNAUTHORIZED_REQUEST);
    } else if (data.errorCode === ERROR_CODE.DOMAIN_NOT_SUPPORT) {
      EventBus.getInstance().dispatch<null>(EventBusName.DOMAIN_NOT_SUPPORT);
    } else {
      const isToast = !('toast-message' in err.config.headers) || err.config.headers['toast-message'] == 'true'
      if (isToast && data.message) {
        toast.error(data.message, ToastDefaultConfig());
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(err);
  });
}
