import { selectCurrency } from 'app/modules/auth'
import { clientTimeZoneOffset, FLOW_TYPE, PaymentMethod, PaymentStatus, Languages } from 'app/utils/common/constants'
import { MajorServiceModel } from 'app/utils/common/models'
import { KTSVG } from 'app/utils/helpers'
import { FieldSelector, FieldServiceInput, FieldTextInput, IMainServiceSelected } from 'app/utils/helpers/components/Form'
import { transformPackageInfo, transformWorkerData } from 'app/utils/helpers/OrderDrawerHelpers'
import { FormikProps } from 'formik'
import i18next from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Response } from 'setup/axios/HttpResponse'
import { HTTP_RESPONSE_STATUS } from 'setup/constants/Http'
import { ToastDefaultConfig } from 'setup/toast/ToastConfig'
import { OrderSourceSelect, PriceDetails } from '../../models'
import { MultiWorker, PackageServiceResponse, PackageInfo } from '../../models/OrderDrawerModel'
import { ticketApi } from '../../redux/TicketApi'
import AccordionItem from './AccordionItem'
import { useAppSelector } from 'setup/redux/Hooks'
import { IForm } from './type'
import { defaultPackage, defaultMainService, initalTouchedPackage, initalTouchedMainService } from 'app/utils/common/constants/CreateOrderFormConfig'
import { _debounce } from 'app/utils/helpers/extendMethod'
import { CentroOrderSourceModel } from '../../models/TicketAttendanceDelivery'
export interface OptionServiceMerge extends MajorServiceModel {
    packageWorkFlowId: number | null
}

export const priceSubSubService = (priceDetails: PriceDetails[], subSubService: { id: number, quantity?: number }) => {
    const { id, quantity = 1 } = subSubService
    const _priceDetails = priceDetails.filter(e => e.subSubServiceId == id);
    return _priceDetails.length == 0 ? ''
        : quantity === 1 ? _priceDetails[0].price
            : (
                _priceDetails.find(e => e.quantityFrom <= quantity && quantity <= e.quantityTo)?.price ??
                (
                    quantity > _priceDetails[_priceDetails.length - 1].quantityTo ?
                        _priceDetails[_priceDetails.length - 1].price
                        : i18next.t('CREATE_TICKET_MODAL.UPON_CHECKUP') as string
                )
            );
}

const NeedServiceComponent = (props: {
    formProps: FormikProps<IForm>,
    rtl?: boolean,
    language: string,
    index?: number,
    centroOrderSourceOption: OrderSourceSelect[],
}) => {
    const { formProps: { values, setFieldValue, setValues, setTouched, setFieldTouched }, language, rtl = false, index = 0, centroOrderSourceOption } = props;
    const { t } = useTranslation();
    const currency = useAppSelector(selectCurrency);
    const [state, setState] = useState<{
        loadingPackage: boolean,
        workerConfig: MultiWorker
    }>({
        loadingPackage: false,
        workerConfig: {
            isApply: false,
            isRequireMatchWorkerNumber: true,
            minWorkerAllow: 0
        }
    })

    const onChangeMainService = (service: IMainServiceSelected) => {
        if (service) {
            setValues(s => ({
                ...s,
                main_service: service,
                flow_type: service.type === 'package_service' ? FLOW_TYPE.PACKAGE : '',
                service_type: service.type,
                ...defaultMainService
            }))
        }

        setTouched({ ...props.formProps.touched, ...initalTouchedMainService })
    }

    const onChangeNumberWorker = useCallback(_debounce(async (numberOfWorker: number) => {
        if (values.main_service && values.main_service.type === 'package_service' && values.maxWorker && numberOfWorker !== 0 && numberOfWorker <= values.maxWorker) {
            const oldSP = values.preSelectSP
            setValues(s => ({
                ...s,
                loadingType: 'worker',
                isOutOfCapacity: false,
                preSelectSP: undefined,
                date_time: undefined,
                worker: undefined
            }))
            const package_selected: PackageInfo | undefined = values.listPackages.find((s: PackageInfo) => s.id === values.package)
            if (package_selected) {
                try {
                    const { data } = await ticketApi.getPackageAvailable({
                        packageWorkflowId: values.main_service?.data?.packageWorkFlowId,
                        countryId: values.location?.countryId,
                        isGetAll: true,
                        clientTimeOffset: -clientTimeZoneOffset,
                        customerId: values.customer_id,
                        majorId: values.main_service.id,
                        companyId: package_selected.preSelectSP?.companyId || '',
                        selectedPackage: {
                            id: package_selected.id,
                            spid: package_selected?.preSelectSP?.spId || '',
                            HasUnratedWorker: package_selected.hasUnratedWorker,
                            FavWorkerIds: values.favWorkerIds
                        },
                        spIds: values.spIds,
                        numbOfSelectWorker: numberOfWorker
                    })
                    if (data.packageInfo.length === 0) {
                        toast.error(`${t('ERROR_MESSAGE.PACKAGE_ERROR.CAPACITY_ERROR', { num: numberOfWorker })}`, ToastDefaultConfig());
                        setValues(s => ({
                            ...s,
                            total_price: Number(values.total_price),
                            without_vat: Number(values.without_vat),
                            vat: Number(values.vat),
                            number_of_workers: values.number_of_workers,
                            loadingType: undefined,
                            preSelectSP: oldSP,
                        }))
                        return

                    }
                    const PackagesInfo = transformPackageInfo(data.packageInfo)
                    const newPackageInfo: PackageInfo | undefined = PackagesInfo.find((s: PackageInfo) => s.id === values.package)
                    if (newPackageInfo) {
                        setValues(s => ({
                            ...s,
                            preSelectSP: newPackageInfo.preSelectSP,
                            total_price: (newPackageInfo.packagePrice * numberOfWorker * (1 + values.percentVAT)).toFixed(2),
                            without_vat: (newPackageInfo.packagePrice * numberOfWorker).toFixed(2),
                            vat: (newPackageInfo.packagePrice * numberOfWorker * values.percentVAT).toFixed(2),
                            number_of_workers: numberOfWorker,
                            loadingType: undefined,
                        }))
                    } else {
                        setValues(s => ({
                            ...s,
                            total_price: Number(values.total_price),
                            without_vat: Number(values.without_vat),
                            vat: Number(values.vat),
                            number_of_workers: values.number_of_workers,
                            preSelectSP: oldSP,
                            loadingType: undefined,
                        }))
                        toast.error(`${t('ERROR_MESSAGE.PACKAGE_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
                    }
                } catch (error) {
                    setValues(s => ({
                        ...s,
                        total_price: Number(values.total_price),
                        without_vat: Number(values.without_vat),
                        vat: Number(values.vat),
                        number_of_workers: values.number_of_workers,
                        preSelectSP: oldSP,
                        loadingType: undefined,
                    }))
                    toast.error(`${t('ERROR_MESSAGE.PACKAGE_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
                }
            }
        }
    }, 300), [values.loadingType])

    const fetchData = async () => {
        if (values.main_service && values?.package) {

            setValues(s => ({
                ...s,
                loadingType: 'worker',
                isOutOfCapacity: false,
                ...defaultPackage,
                order_source: s.order_source
            }))
            setTouched({ ...props.formProps.touched, ...initalTouchedPackage })

            const package_selected: PackageInfo | undefined = values.listPackages.find((s: PackageInfo) => s.id === values.package)

            if (package_selected) {
                try {
                    // Recall api to get full PackageInfo after choose package
                    const { data } = await ticketApi.getPackageAvailable({
                        packageWorkflowId: values.main_service?.data?.packageWorkFlowId,
                        countryId: values.location?.countryId,
                        isGetAll: true,
                        clientTimeOffset: -clientTimeZoneOffset,
                        customerId: values.customer_id,
                        majorId: values.main_service.id,
                        companyId: package_selected.preSelectSP?.companyId || '',
                        selectedPackage: {
                            id: package_selected.id,
                            spid: package_selected?.preSelectSP?.spId || '',
                            HasUnratedWorker: package_selected.hasUnratedWorker,
                            FavWorkerIds: values.favWorkerIds
                        },
                        spIds: values.spIds
                    })

                    const PackagesInfo = transformPackageInfo(data.packageInfo)
                    const workers = transformWorkerData(PackagesInfo?.[0]?.workers || [])
                    const newPackageInfo: PackageInfo | undefined = PackagesInfo.find((s: PackageInfo) => s.id === values.package)

                    if (newPackageInfo) {
                        const numberOfWorker = newPackageInfo.multiWorker.minWorkerAllow
                        const packageVAT = newPackageInfo.percentVAT
                        const number_visit = newPackageInfo.numberOfVisitPerWeek || 0
                        const number_week = newPackageInfo.numberOfWeeks || 0

                        let packagePrice = newPackageInfo.packagePriceWithVat / (1 + packageVAT);
                        let packagePriceWithVat = packagePrice
                        if (newPackageInfo.preSelectSP?.isShowVat && packageVAT) {
                            packagePriceWithVat = newPackageInfo.packagePriceWithVat;
                        }

                        const disabledWorker = !newPackageInfo.multiWorker.isApply || (newPackageInfo.multiWorker.isApply && newPackageInfo.multiWorker.isRequireMatchWorkerNumber)

                        setValues(s => ({
                            ...s,
                            number_of_workers: numberOfWorker,
                            total_price: disabledWorker ? packagePriceWithVat.toFixed(2) : (packagePriceWithVat * numberOfWorker).toFixed(2),
                            without_vat: disabledWorker ? packagePrice.toFixed(2) : (packagePrice * numberOfWorker).toFixed(2),
                            vat: disabledWorker ? (packagePriceWithVat - packagePrice).toFixed(2) : ((packagePriceWithVat - packagePrice) * numberOfWorker).toFixed(2),
                            numberOfWeeks: newPackageInfo.numberOfWeeks,
                            numberOfVisitPerWeek: newPackageInfo.numberOfVisitPerWeek,
                            preSelectSP: newPackageInfo.preSelectSP,
                            workerConfig: newPackageInfo.multiWorker,
                            maxWorker: newPackageInfo.multiWorker.workerNum,
                            totalVisit: number_week * number_visit,
                            isShowDeliveryTime: newPackageInfo.isShowDeliveryTime,
                            worker_available: workers,
                            percentVAT: packageVAT,
                            favWorkerIds: data.favWorkerIds,
                            loadingType: undefined,

                            //For submit Package
                            packageServicePriceSetId: newPackageInfo.packageServicePriceSetId || null,
                            packageServicePriceSetDetailId: newPackageInfo.packageServicePriceSetDetailId || null,
                            isRefund: newPackageInfo.isRefund,
                            reScheduleLimit: newPackageInfo.reScheduleLimit || null,
                            subServiceId: newPackageInfo?.subServiceId || 0,
                            numberOfVisitRecurrence: newPackageInfo.numberOfVisitRecurrence || 0
                        }))

                        setState({
                            ...state,
                            workerConfig: package_selected.multiWorker
                        })
                    }

                }
                catch (error) {
                    setValues(s => ({ ...s, loadingType: undefined }))
                    toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
                    return
                }
            }
        }
    }

    useEffect(() => {
        if (values.main_service && values.main_service.type === 'package_service') {
            setState({
                ...state,
                loadingPackage: true,
            })
            //Call get PackageInfo
            ticketApi.getPackageAvailable({
                packageWorkflowId: values.main_service.data.packageWorkFlowId,
                countryId: values.location?.countryId,
                isGetAll: true,
                clientTimeOffset: -clientTimeZoneOffset,
                customerId: values.customer_id,
                majorId: values.main_service.id,
                companyId: "",
                spIds: values.spIds
            }).then((res: Response<PackageServiceResponse>) => {
                const { data, status } = res
                if (status !== HTTP_RESPONSE_STATUS.SUCCESS) {
                    toast.error(`${t('ERROR_MESSAGE.PACKAGE_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
                    return
                }

                const PackagesInfo = transformPackageInfo(data.packageInfo)

                setState({
                    ...state,
                    loadingPackage: false,
                })

                setValues(s => ({
                    ...s,
                    listPackages: PackagesInfo,
                    favWorkerIds: data.favWorkerIds
                }))

                if (!PackagesInfo.length) setFieldTouched('package', true)

            }).catch(e => {
                if (!values.customer_id || !values.map) {
                    toast.error(`${t('ERROR_MESSAGE.PACKAGE_ERROR.MISSING_FIELD')}`, ToastDefaultConfig());
                    setState({
                        ...state,
                        loadingPackage: false,
                    })
                } else {
                    toast.error(`${t('ERROR_MESSAGE.PACKAGE_ERROR.UNEXPECTED_ERROR')}`, ToastDefaultConfig());
                    setState({
                        ...state,
                        loadingPackage: false,
                    })
                }
            })
        }
    }, [values.main_service, values.customer_id])

    useEffect(() => {
        fetchData()
    }, [values.package])

    const isDisabledWorker = !state.workerConfig.isApply || (state.workerConfig.isApply && state.workerConfig.isRequireMatchWorkerNumber)

    return (
        <AccordionItem id={'need_service'} title={`${index}. ${t('CREATE_ORDER_DRAWER.SECTION_2.TITLE')}`}>
            <div className='need-service-section'>
                <p className='section-title'>{t('CREATE_ORDER_DRAWER.SECTION_2.SUB_TITLE')}</p>
                <FieldServiceInput
                    name={'service_selected'}
                    subSubPrices={values.subSubPrices}
                    serviceAvailable={values.service_available}
                    loading={state.loadingPackage}
                    rtl={rtl}
                    language={language}
                    label={{
                        mainService: t('CREATE_ORDER_DRAWER.SECTION_2.MAIN_SERVICES'),
                        subService: t('CREATE_ORDER_DRAWER.SECTION_2.SUB_SERVICES'),
                        subSubService: t('CREATE_ORDER_DRAWER.SECTION_2.SUB_SUB_SERVICES'),
                        offerService: t('CREATE_ORDER_DRAWER.SECTION_2.OFFER'),
                    }}
                    placeholder={{
                        mainService: t('CREATE_ORDER_DRAWER.SECTION_2.PLACEHOLDER_MAIN_SERVICE'),
                        subService: t('CREATE_ORDER_DRAWER.SECTION_2.PLACEHOLDER_SUB_SERVICE'),
                        subSubService: t('CREATE_ORDER_DRAWER.SECTION_2.PLACEHOLDER_SUB_SUB_SERVICE'),
                        offerService: t('CREATE_ORDER_DRAWER.SECTION_2.PLACEHOLDER_OFFER_SERVICE'),
                        noOptionsMessage: t('CREATE_ORDER_DRAWER.NO_OPTIONS'),
                    }}
                    onChangeMainService={e => {
                        if (e == undefined) {
                            return
                        }
                        else if (!Array.isArray(e))
                            onChangeMainService(e)
                        else {
                            setFieldValue('sub_service', e)
                        }
                    }}
                />
                {values.flow_type === FLOW_TYPE.PACKAGE &&
                    (<>
                        <p className='sub-title'>{t('CREATE_ORDER_DRAWER.ORDER_SOURCE')}</p>
                        <FieldSelector
                            name='order_source'
                            inputPlaceholder={t('CREATE_ORDER_DRAWER.SECTION_2.SELECT_ORDER_SOURCE')}
                            as='select'
                            inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/order-source.svg' />}
                            options={centroOrderSourceOption.filter(a => a.isActive === true).sort((a, b) => { return a.order - b.order })}
                            optionKey={(e) => e.value}
                            optionLabel={(e) => language === Languages.ar ? e.label_Ar : e.label_En}
                        />
                        <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_2.PACKAGE')}</p>
                        <FieldSelector
                            name='package'
                            inputPlaceholder={t('CREATE_ORDER_DRAWER.SECTION_2.SEARCH_PACKAGE')}
                            inputGroup={state.loadingPackage ? <div className="spinner-border spinner-border-sm text-dark" role="status"></div> : <KTSVG defaultColor={true} path='/assets/images/icons/mail.svg' />}
                            options={values.listPackages}
                            optionKey={(e: PackageInfo) => e.id}
                            optionLabel={(e: PackageInfo) => language === Languages.ar ? e.titleAr : e.titleEn}
                        />
                        <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_2.NUMBER_OF_WORKERS')}</p>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FieldTextInput
                                name='number_of_workers'
                                type='number'
                                placeholder=''
                                disabledInput={state.loadingPackage || isDisabledWorker}
                                inputGroup={
                                    values.loadingType === "worker" ? <div className="spinner-border spinner-border-sm text-dark" role="status"></div> :
                                        <KTSVG defaultColor={true} path='/assets/images/icons/users-four.svg' />
                                }
                                inputGroupEnd={<span className='text-muted'>{`${t('MAXIMUM')} ${values.maxWorker}`}</span>}
                                styleContainer={{ flex: 1 }}
                                //@ts-ignore
                                onChangeMore={(value: string) => {
                                    if (value) {
                                        if (values.maxWorker && Number(value) <= values.maxWorker) {
                                            onChangeNumberWorker(Number(value))
                                        }
                                    }
                                }}
                                className='number-worker--input'
                                allowDecimal={false}
                            />
                            <div className='d-flex'>
                                <Button
                                    className='btn-adjust minus'
                                    onClick={() => {
                                        onChangeNumberWorker((values.number_of_workers || 0) - 1)
                                    }

                                    }
                                    disabled={values.number_of_workers <= 0 || isDisabledWorker || state.loadingPackage}
                                >
                                    <i className='fa fa-regular fa-minus'></i>
                                </Button>
                                <Button
                                    className='btn-adjust plus'
                                    onClick={() => {
                                        onChangeNumberWorker((values.number_of_workers || 0) + 1)
                                    }
                                    }
                                    disabled={values.number_of_workers >= Number(values.maxWorker) || isDisabledWorker || state.loadingPackage}
                                >
                                    <i className='fa fa-regular fa-plus'></i>
                                </Button>
                            </div>
                        </div>
                        <Row className='mb-3 price-row'>
                            <Col className={rtl ? 'none-padding-col--right' : 'none-padding-col--left'}>
                                <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_2.PRICE_BEFORE_VAT')}</p>
                                <FieldTextInput
                                    name='without_vat'
                                    placeholder={t('WITHOUT_VAT')}
                                    inputGroup={values.loadingType === "worker" ? <div className="spinner-border spinner-border-sm text-dark" role="status"></div> : <KTSVG defaultColor={true} path='/assets/images/icons/cost.svg' />}
                                    //@ts-ignore
                                    onChangeMore={(value: string) => {
                                        setValues({
                                            ...values,
                                            total_price: ((Number(value) || 0) * (1 + values.percentVAT)).toFixed(2),
                                            vat: ((Number(value) || 0) * values.percentVAT).toFixed(2)
                                        })
                                    }}
                                /></Col>
                            <Col className='none-padding-col'>
                                <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_2.PRICE_VAT')}</p>
                                <FieldTextInput
                                    name='vat'
                                    placeholder={t('VAT')}
                                    disabledInput
                                />
                            </Col>
                            <Col className={rtl ? 'none-padding-col--left' : 'none-padding-col--right'}>
                                <p className='sub-title'>{t('CREATE_ORDER_DRAWER.SECTION_2.PRICE_WITH_VAT')}</p>
                                <FieldTextInput
                                    name='total_price'
                                    placeholder={t('TOTAL_PRICE')}
                                    inputGroupEnd={<span className='text-muted'>{currency}</span>}
                                    //@ts-ignore
                                    onChangeMore={(value: string) => {
                                        setValues({
                                            ...values,
                                            without_vat: ((Number(value) || 0) / (1 + values.percentVAT)).toFixed(2),
                                            vat: ((Number(value) || 0) / (1 + values.percentVAT) * values.percentVAT).toFixed(2)
                                        })
                                    }}
                                />
                            </Col>
                        </Row>

                        <p className='sub-title'>{t('CREATE_ORDER_DRAWER.PAYMENT_METHOD')}</p>
                        <FieldSelector
                            name='payment_method'
                            inputPlaceholder={t('CREATE_ORDER_DRAWER.SECTION_2.SELECT_PAYMENT_METHOD')}
                            inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/payment-method.svg' />}
                            options={PaymentMethod}
                            optionKey={(e) => e.value}
                            optionLabel={(e) => language === Languages.ar ? e.label_Ar : e.label_En}
                        />
                        <p className='sub-title'>{t('CREATE_ORDER_DRAWER.PAYMENT_STATUS')}</p>
                        <FieldSelector
                            name='payment_status'
                            inputPlaceholder={t('CREATE_ORDER_DRAWER.SECTION_2.SELECT_PAYMENT_STATUS')}
                            as='select'
                            inputGroup={<KTSVG defaultColor={true} path='/assets/images/icons/cost.svg' />}
                            options={PaymentStatus}
                            optionKey={(e) => e.value}
                            optionLabel={(e) => language === Languages.ar ? e.label_Ar : e.label_En}
                        />
                    </>)}

            </div>
        </AccordionItem>
    )
}

export default NeedServiceComponent