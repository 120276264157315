import Accordion from 'react-bootstrap/Accordion'

const AccordionItem = (props: {
  id: string
  title: React.ReactNode
  children: React.ReactNode
  className?: string | string[]
}) => {
  const { id, title, children, className } = props
  return (
    <Accordion.Item
      eventKey={id}
      className={`form-section shadow-sm mb-8 ${(className === undefined
        ? []
        : Array.isArray(className)
          ? className
          : [className]
      ).join(' ')}`}
    >
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        <div className='p-0'>{children}</div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default AccordionItem
