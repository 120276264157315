import {useState, useEffect} from 'react'
import {Languages} from 'app/utils/common/constants'
import AccordionItem from '../../../app/modules/tickets/drawer/components/AccordionItem'
import {SpServiceAvailable} from 'app/utils/common/models'
import {Worker} from '../../../app/modules/tickets/models/OrderDrawerModel'
import SearchBar from './SearchBar'
import Filter from './Filter'
import List from './List'
import {FieldRadioGroup} from 'app/utils/helpers/components/Form'
import {selectLanguage} from 'app/modules/auth'
import {useAppSelector} from 'setup/redux/Hooks'
import {useTranslation} from 'react-i18next'
import './style.scss'
import 'shared/DateTime/DateTimePickerInline.scss'

const Container = (props: {
  isLoading: boolean
  displayedData: (SpServiceAvailable | Worker)[]
  isOffer?: boolean
  handleFavoriteWorker?: Function
  trans: {title: string; section_title: string; search_placeholder: string}
  id: string
  name: string
  startDate?: Date
  filterDisabled?: boolean
  filterStartDate?: Date
  handleChangeDate: Function
  isSpAvailble: boolean
}) => {
  const {
    isLoading,
    displayedData,
    isOffer = false,
    handleFavoriteWorker,
    trans,
    id,
    name,
    filterDisabled,
    filterStartDate,
    handleChangeDate,
    isSpAvailble,
  } = props

  const language = useAppSelector(selectLanguage)
  const {t} = useTranslation()

  const [state, setState] = useState<{
    textSearch: string
  }>({textSearch: ''})

  const onChangeSearchKey = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState({...state, textSearch: e.target.value || ''})

  const checkNotFoundSearch = () => {
    if (!state.textSearch) return false
    if (displayedData?.length) {
      let index = displayedData.findIndex((e) => {
        let name =
          'fullNameEn' in e
            ? language === Languages.ar
              ? e.fullNameAr
              : e.fullNameEn
            : language === Languages.ar
            ? e.workerNameAr
            : e.workerNameEn
        return name.toLowerCase().indexOf(state.textSearch.toLowerCase()) >= 0
      })
      if (index != -1) return false
    }
    return true
  }

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        textSearch: '',
      }
    })
  }, [displayedData])

  return (
    <AccordionItem id={id} title={trans.title}>
      <div className='worker-section'>
        <p className='section-title'>{trans.section_title}</p>
        <>
          <Filter
            language={language}
            startDate={filterStartDate}
            disabled={filterDisabled}
            handleChangeDate={handleChangeDate}
          />
          <SearchBar
            search_placeholder={trans.search_placeholder}
            onChange={onChangeSearchKey}
            checkNotFoundSearch={checkNotFoundSearch}
            invalid_message={t('CREATE_ORDER_DRAWER.SECTION_4.SEARCH_SP_NOT_FOUND')}
            disabled={filterDisabled}
          />
          {!isSpAvailble && (
            <div className='invalid-message'>
              {t('RESCHEDULE_PACKAGE_MODAL.ERROR_NO_SP_AVAILBLE')}
            </div>
          )}
          {isLoading ? (
            <div className='d-flex mt-3 h-100 justify-content-center align-items-center'>
              <div className='spinner-border spinner-border-sm text-dark' role='status'></div>
            </div>
          ) : (
            isSpAvailble && <FieldRadioGroup
              name={name}
              options={displayedData || []}
              optionKey={(e) => (!('workerId' in e) ? e.spId : e.workerId)}
              renderRadio={({Radio, onChange}) => (
                <List
                  radioList={Radio}
                  textSearch={state.textSearch}
                  onChange={onChange}
                  isOffer={isOffer}
                  handleFavoriteWorker={handleFavoriteWorker}
                  language={language}
                />
              )}
            />
          )}
        </>
      </div>
    </AccordionItem>
  )
}

export default Container
