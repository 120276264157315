import React from 'react'
import { withGoogleMap, withScriptjs, GoogleMap, Marker, GoogleMapProps } from "react-google-maps"
type Props = {
  lat: number,
  lng: number,
  zoomControl?: boolean,
}

const MapMarker: React.FC<Props> = ({ lat, lng, zoomControl = false }) => {
  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControlOptions: {
      position: 1
    },
    zoomControl: zoomControl,

  } as GoogleMapProps;

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: lat, lng: lng }}
      options={mapOptions}
      
    >
      <Marker
        position={{ lat: lat, lng: lng }}
      />
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(MapMarker));