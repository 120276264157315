import { mapStatus } from "app/modules/tickets/attendance-delivery/AttendanceDelivery";
import { TicketStatus, TicketStatusColor, CommerceStatusColor, CommerceStatusLabel, TicketStatusLabel } from "../common/constants";


export const statusToColor = (status?: string) => {
    switch (status) {
        case TicketStatus.ToBeAssigned:
            return TicketStatusColor.ToBeAssigned;

        case TicketStatus.Inprogress:
        case TicketStatus.InprogressConfirmed:
        case TicketStatus.Delivered:
            return TicketStatusColor.Inprogress;

        case TicketStatus.Completed_No_Customer_Rating:
            return TicketStatusColor.Completed;

        case TicketStatus.Completed_And_Customer_Rated:
            return TicketStatusColor.CompletedRated;

        //Disputed by sp
        case TicketStatus.SPDisputed_RejectTime:
        case TicketStatus.SPDisputed_CustomerNotReachable:
        case TicketStatus.SPDisputed_OutOfScope:
        case TicketStatus.SPDisputed_PriceDisagreement:
        case TicketStatus.SPDisputed_VisitTimeNotSuitable:
        case TicketStatus.SPUndelivered_CustomerNotReachable:
        case TicketStatus.SPUndelivered_NoFemalePresent:
        case TicketStatus.SPUndelivered_Other:
        case TicketStatus.SPDisputed_CustomerCanceled:
            return TicketStatusColor.DisputedSP;

        //Disputed by customer
        // case TicketStatus.SPDisputed_CustomerCanceled:
        // case TicketStatus.CanceledByCustomer:
        // case TicketStatus.CanceledByB8akAgent:
        //     return TicketStatusColor.DisputedCustomer;

        case TicketStatus.Canceled:
        case TicketStatus.CanceledByCustomer:
        case TicketStatus.CanceledByB8akAgent:
            return TicketStatusColor.Canceled;

        default:
            break;
    }
}

export const statusCommerceToColor = (status: string) => {
    switch (status) {
        case TicketStatus.Product_Canceled:
            return CommerceStatusColor.Product_Canceled;
        case TicketStatus.Product_Delivered:
            return CommerceStatusColor.Product_Delivered;
        case TicketStatus.Product_Inprogress:
            return CommerceStatusColor.Product_Inprogress;
        case TicketStatus.Product_Shipped:
            return CommerceStatusColor.Product_Shipped;

        default:
            break;
    }
}

export const statusCommerceToLabel = (status: string) => {
    switch (status) {
        case TicketStatus.Product_Canceled:
            return CommerceStatusLabel.Product_Canceled;
        case TicketStatus.Product_Delivered:
            return CommerceStatusLabel.Product_Delivered;
        case TicketStatus.Product_Inprogress:
            return CommerceStatusLabel.Product_Inprogress;
        case TicketStatus.Product_Shipped:
            return CommerceStatusLabel.Product_Shipped;

        default:
            break;
    }
}

export const checkStatusAttendanceDelivery = (event: string, status: string) => {
    let statusItem: any = {};
    mapStatus.find(item =>{
        const itemMapStatusChild = item.statusMap.find((i: any) => (i.status === status && event === i.event) || status === i)
        if (itemMapStatusChild) {
            statusItem = item;
            return;
        } return null
    });
    if (statusItem && statusItem.labelEventDis) {
        return statusItem.labelEventDis;
    }
    return '';
}