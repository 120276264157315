export const ArrayGroupBy: <S>(arr: S[], callback: (e: S) => number | string) => { by: string | number, list: S[] }[] = (arr, callback) => {
    return arr.reduce((g: { by: string | number, list: any[] }[], e) => {
        const key = callback(e);
        const index = g.findIndex(e1 => e1.by == key);
        if (index < 0)
            g.push({ by: key || -1, list: [e] })
        else
            g[index].list.push(e);

        return g;
    }, []);
}