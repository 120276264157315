import './style.scss';

import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { WORKING_TIME_LIST } from '../../../../../../utils/common/constants';
import { SelectFilter } from '../../../../../../utils/common/models';
import { convertOperationObjectToList } from '../../../../../../utils/helpers';
import { TimeInWeek, ValueItemTimeInWeek } from '../../../../models';
import { OperationHoursAndMap } from '../../../../models/OperationHoursAndMap';
import moment from 'moment';

type Props = {
    isRamadan: boolean,
    rtl?: boolean,
    timeInWeek: TimeInWeek[],
    operationHoursAndMap: OperationHoursAndMap[],
    updateValueOperationHours: (time: TimeInWeek[]) => void,
}

export interface SelectTime {
    title: string
    value: number
}

const ManagementTimeInWeek: React.FC<Props> = ({ operationHoursAndMap, timeInWeek, rtl, isRamadan, updateValueOperationHours }) => {
    const { t } = useTranslation();
    const endDayValue = WORKING_TIME_LIST[WORKING_TIME_LIST.length - 1].value;
    const [optionSelectOperationHoursCP, setOptionSelectOperationHoursCP] = useState<SelectFilter[]>([])
    const [operationHoursCpSelected, setOperationHoursCpSelected] = useState<SelectFilter>({ label: rtl ? 'اختر سائق الباص' : 'Select bus driver', value: '' });

    // Select day on/off
    const selectWorkDay = (dataTimeInWeek: TimeInWeek) => {
        const timeInWeekTmp = [...timeInWeek];
        const index = timeInWeekTmp.findIndex((time) => time.key === dataTimeInWeek.key);
        if (index < 0) return;
        timeInWeekTmp[index].isSelected = !dataTimeInWeek.isSelected;
        // if(!timeInWeekTmp[index].isSelected) timeInWeekTmp[index].value = [{ start: 0, end: 0 }];
        updateValueOperationHours(timeInWeekTmp);
    }

    // Change time on Day
    const changeStartTimeOnDay = (event: any, indexChild: number, indexRoot: number) => {
        const timeInWeekTmp = [...timeInWeek];
        if (indexRoot < 0 || indexChild < 0) return;
        let timeOneDayTmp = { ...timeInWeekTmp[indexRoot].value[indexChild] };
        timeOneDayTmp.start = Number(event.target.value);
        timeInWeekTmp[indexRoot].value[indexChild] = { ...timeOneDayTmp };
        updateValueOperationHours(timeInWeekTmp);
    }

    const changeEndTimeOnDay = (event: any, indexChild: number, indexRoot: number) => {
        const timeInWeekTmp = [...timeInWeek];
        if (indexRoot < 0 || indexChild < 0) return;
        let timeOneDayTmp = { ...timeInWeekTmp[indexRoot].value[indexChild] };
        timeOneDayTmp.end = Number(event.target.value);
        timeInWeekTmp[indexRoot].value[indexChild] = { ...timeOneDayTmp };
        updateValueOperationHours(timeInWeekTmp);
    }
    // End Change time on Day

    // Create and remove time on Day
    const createNewTimeOnDay = (indexRoot: number) => {
        const timeInWeekTmp = [...timeInWeek];
        if (indexRoot < 0) return;
        const timeChildCurrent = timeInWeekTmp[indexRoot].value;
        const timeStartDefault = timeChildCurrent[timeChildCurrent.length - 1].end;
        timeInWeekTmp[indexRoot].value.push({ start: timeStartDefault, end: endDayValue } as ValueItemTimeInWeek);
        updateValueOperationHours(timeInWeekTmp);
    }

    const removeNewTimeOnDay = (indexChild: number, indexRoot: number) => {
        const timeInWeekTmp = [...timeInWeek];
        if (indexRoot < 0 || indexChild < 0) return;
        timeInWeekTmp[indexRoot].value.splice(indexChild, 1);
        updateValueOperationHours(timeInWeekTmp);
    }

    const handleCopyHours = (dataTimeInWeek: TimeInWeek) => {
        let timeInWeekTmp = [...timeInWeek];
        timeInWeekTmp.map(itemTime => (itemTime.value = itemTime.isSelected ? [...dataTimeInWeek.value] : itemTime.value))
        updateValueOperationHours(timeInWeekTmp);
    }


    const changeSelectOperationHoursCP = (item: any) => {
        setOperationHoursCpSelected(item);
        if (!item.value || operationHoursAndMap.length == 0) return
        let operationHoursMap: OperationHoursAndMap | undefined = operationHoursAndMap.find((data) => data.id == item.value) || undefined
        if (!operationHoursMap) return
        let operationHours = timeInWeek;
        if (isRamadan) {
            let timeObject = operationHoursMap.ramadanOperationHours && JSON.parse(operationHoursMap.ramadanOperationHours);
            operationHours = convertOperationObjectToList(timeObject);
        } else {
            let timeObject = operationHoursMap.operationHours && JSON.parse(operationHoursMap.operationHours);
            operationHours = convertOperationObjectToList(timeObject);
        }
        updateValueOperationHours(operationHours)
    }

    useEffect(() => {
        const listFilter: SelectFilter[] = [{ label: rtl ? 'اختر الفني' : 'Select bus driver', value: "", isDisabled: true }]
        if (operationHoursAndMap) {
            operationHoursAndMap.forEach((item: OperationHoursAndMap) => {
                listFilter.push({
                    value: item.id || '',
                    label: rtl ? item.fullNameAr || '' : item.fullNameEn || '',
                })
            });
        }
        setOptionSelectOperationHoursCP(listFilter);
    }, [operationHoursAndMap])

    return (
        <Row className='justify-content-center'>
            <Col sm={12} md={10}>
                <div className='select-copy-operation-hours mb-10'>
                    <div className='title-select'>{t('USERS_PAGE.CONTENT_STEP.STEP4.TITLE_SELECT_TECH')}</div>
                    <InputGroup className='filter-bus-copy'>
                        <Select
                            className='select-input'
                            classNamePrefix='select'
                            name='color'
                            placeholder="Select bus driver"
                            options={optionSelectOperationHoursCP}
                            onChange={changeSelectOperationHoursCP}
                            value={operationHoursCpSelected}

                        />
                    </InputGroup>
                </div>
                {timeInWeek.length > 0 &&
                    timeInWeek.map((dataTimeInWeek: TimeInWeek, indexRoot: number) => (
                        <Row className='box-day-select' key={indexRoot}>
                            <Col sm={12} md={12} lg={3} className='title'>
                                <div className='mt-4'>
                                    <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle' style={{ width: 'max-content' }}>
                                        <span className='title-day mx-4'>{t(`${dataTimeInWeek.label}`)}</span>
                                        <input className='form-check-input' type='checkbox' checked={dataTimeInWeek.isSelected} onChange={() => selectWorkDay(dataTimeInWeek)} />
                                    </label>
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={9} className={`select-times ${!dataTimeInWeek.isSelected ? 'disable-none-select-day' : ''}`}>
                                {dataTimeInWeek.value && dataTimeInWeek.value.map((time: ValueItemTimeInWeek, indexChild: number) => (
                                    <Row key={indexChild} className='mt-4'>
                                        <Col sm={12} md={6} lg={4} className="mb-3">
                                            <Form.Select
                                                onChange={(event: any) => changeStartTimeOnDay(event, indexChild, indexRoot)}
                                                value={time.start}
                                            >
                                                {WORKING_TIME_LIST.map((timeWorking: SelectTime, indexTimeWorking) => (
                                                    <option key={indexTimeWorking} value={timeWorking.value} disabled={timeWorking.value >= time.end}>
                                                        {moment().startOf('day').locale(rtl ? 'ar-SA' : 'en-US').add(timeWorking.value, 'minute').format('hh:mm A')}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col sm={12} md={6} lg={4} className="mb-3">
                                            <Form.Select
                                                onChange={(event: any) => changeEndTimeOnDay(event, indexChild, indexRoot)}
                                                value={time.end}
                                            >
                                                {WORKING_TIME_LIST.map((timeWorking: SelectTime) => (
                                                    <option key={timeWorking.value} value={timeWorking.value} disabled={timeWorking.value <= time.start}>
                                                        {moment().startOf('day').locale(rtl ? 'ar-SA' : 'en-US').add(timeWorking.value, 'minute').format('hh:mm A')}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col sm={12} md={12} lg={4} className='box-action-time mb-3'>
                                            <Row className="w-100 justify-content-between">
                                                <Col sm={4} className="w-50px">
                                                    <button type='button' className='btn action-time mx-0'
                                                        disabled={dataTimeInWeek.value.length <= 1}
                                                        onClick={() => removeNewTimeOnDay(indexChild, indexRoot)}>
                                                        <i className="bi bi-trash icon-delete-hour"></i>
                                                    </button>
                                                </Col>
                                                <Col sm={4} className="w-50px">
                                                    {(dataTimeInWeek.value.length - 1) == indexChild && <div className='d-flex'>
                                                        <button type='button' className='btn action-time mx-2' onClick={() => createNewTimeOnDay(indexRoot)} disabled={endDayValue == time.end}>
                                                            <i className="bi bi-plus-square-fill icon-add-hour"></i>
                                                        </button>
                                                    </div>
                                                    }
                                                </Col>
                                                <Col sm={4} className="w-50px">
                                                    {indexRoot === 0 && indexChild === 0 &&
                                                        <button type='button' className='btn action-time mx-2' onClick={() => handleCopyHours(dataTimeInWeek)}>
                                                            <i className="far fa-clone icon-copy-hour"></i>
                                                        </button>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    ))}
            </Col>
        </Row>

    )
}

export default ManagementTimeInWeek


