import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Field, FieldConfig, FieldProps } from 'formik'
import { Form, InputGroup } from 'react-bootstrap-v5'
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap-v5/lib/helpers'
import { classNameFunc } from './utils';
import { useTranslation } from 'react-i18next';

export interface IPropsInputMapPlaces extends React.PropsWithChildren<ReplaceProps<'input', BsPrefixProps<'input'>>> {
    label?: string
    type?: 'text' | 'number'
    styleContainer?: CSSProperties,
    classContainer?: string;
    classError?: string;
    styleLabel?: CSSProperties,
    classLabel?: string;
    placeholder?: string
    inputGroup?: React.ReactNode
    inputGroupEnd?: React.ReactNode
    isInvalid?: boolean
    error?: string,
    onChangeMap?: (data: { lat: string, lng: string, address: string }) => void,
}
export const InputMapPlaces = (props: IPropsInputMapPlaces) => {
    const {
        classContainer,
        styleContainer,
        label,
        inputGroup,
        inputGroupEnd,
        isInvalid: _isInvalid,
        error: __error,
        placeholder,
        styleLabel,
        classLabel,
        onChangeMap,
        classError = 'has-error',

        //formik
        field,
        form,
        meta,
        ...rest
    } = props as IPropsInputMapPlaces & FieldProps;

    const refInput = useRef<HTMLInputElement>();
    const [state, setState] = useState({
        address: ''
    })

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setState({ address: field?.value || '' })
        refInput.current && (refInput.current.value = field?.value?.address || '')
    }, [field?.value]);

    const init = async () => {
        const autocomplete = new google.maps.places.Autocomplete(
            refInput.current as any,
            {
                types: ['geocode'],
                fields: ['place_id', 'geometry', 'name']
            });

        autocomplete.addListener("place_changed", function () {
            // The selected place's position
            let placeResult = autocomplete.getPlace();
            const data = {
                lat: placeResult?.geometry?.location?.lat(),
                lng: placeResult?.geometry?.location?.lng(),
                address: refInput.current?.value,
            }
            console.log(data);
            setState({ address: data.address || '' })
            if (form == undefined)
                onChangeMap && onChangeMap(data as any)
            else
                form.setFieldValue(field.name, data);
        });
        const inputElement = document.getElementById(
            "dispatch-center-location"
          ) as HTMLInputElement;
          inputElement.addEventListener('change', () => {
            if (inputElement.value === '') {
                form.setFieldValue(field.name, '');
            }
        })
    }
    const renderInput = () => {
        return (
            <Form.Control
                type={'text'}
                placeholder={placeholder}
                as={'input'}
                ref={(e: any) => refInput.current = e}
                id="dispatch-center-location"
            />
        )
    }
    const { t } = useTranslation()

    const touched = form?.touched[field.name]
    let _error = __error !== undefined ? __error : form?.errors[field.name]
    let [error, params = ''] = ![null, undefined, ''].includes(_error as any) ? String(_error).split('-') : [_error, '']
    error = ![null, undefined, ''].includes(error as any) ? t(error as string).format(params.split(';')) : error
    const isInvalid = _isInvalid !== undefined ? _isInvalid : touched === true && error !== undefined
    return (
        <Form.Group className={classNameFunc([isInvalid && classError, classContainer])} style={styleContainer}>
            {label != null && <Form.Label style={styleLabel} className={classNameFunc(classLabel)}>{label}</Form.Label>}
            {inputGroup === undefined && inputGroupEnd === undefined ? (
                <>
                    {renderInput()}
                    {/*{isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}*/}
                </>
            ) : (
                <InputGroup>
                    {inputGroup !== undefined && <InputGroup.Text>{inputGroup}</InputGroup.Text>}
                    {renderInput()}
                    {inputGroupEnd !== undefined && <InputGroup.Text>{inputGroupEnd}</InputGroup.Text>}
                </InputGroup>
            )}
            {isInvalid && <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>}
        </Form.Group >
    )
}

export type IPropsFieldInputMapPlaces = IPropsInputMapPlaces & FieldConfig
export const FieldInputMapPlaces = (props: IPropsInputMapPlaces) => <Field component={InputMapPlaces} {...props} />