import './style.scss';

import { Field, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Response } from '../../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { CancellationReason, TicketStatus } from '../../../../utils/common/constants';
import { SelectFilter } from '../../../../utils/common/models';
import { cancelPackageSchemasHelper } from '../../../../utils/helpers';
import { CancelOrderModal, CancelRequestModel, TicketModel, TicketPackageDetail } from '../../models';
import { ticketApi } from '../../redux/TicketApi';
import { CompletedCancelPackage } from './step/CompletedCancelPackage';
import { selectTicketOpeningPackageModal, ticketActions } from '../../redux/TicketSlice';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'setup/redux/Hooks';

type Props = {
  className?: string
  data: TicketModel | null
  isShow?: boolean
  ticketPackage?: TicketPackageDetail
  onHide?: () => void
  onBack?: () => void
  finishSuccess?: () => void
  onReloadShift?: (shiftTicket?: number) => void
  updateTicketCancel: (ticketId: string, isRefund: boolean, visitTime: Date | null) => void
  updateTicketCancelRemaining: (packageSubmitId: number) => void
  rtl?: boolean
}

const CancelPackageModal: React.FC<Props> = ({
  className,
  isShow,
  data,
  ticketPackage,
  onBack,
  onHide,
  finishSuccess,
  onReloadShift,
  updateTicketCancel,
  updateTicketCancelRemaining,
  rtl,
}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

	const isShowViewPackageModal = useAppSelector(selectTicketOpeningPackageModal);

  const initCancelForm: CancelOrderModal = {
    reason: "",
    issueRefund: true,
    cancelRemaining: false,
    note: ""
  }
  const CancelSchema = cancelPackageSchemasHelper(t)

  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false)
  const [isLoadingCancel, setLoadingCancel] = useState(false)

  useEffect(() => {
    if (!isShow) return
    setLoadingCancel(false)
    setIsShowModalSuccess(false)
  }, [isShow])

  const submittedCancel = (values: CancelOrderModal) => {
    if(!data){
      return
    }
    const request: CancelRequestModel = {
      refundReason: Number.parseInt(values.reason),
      isRefund: values.issueRefund,
      isCancelRemaining: values.cancelRemaining,
      comment: values.note,
      id: data?.id,
    }
    setLoadingCancel(true)
    ticketApi.cancelPackage(request).then((response: Response<boolean>) => {
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        // go to success modal
        setIsShowModalSuccess(true)
        
        //reload package modal data
        if(isShowViewPackageModal){
          const newTicketModalData = _.cloneDeep(data)
          newTicketModalData.status = TicketStatus.Canceled
          newTicketModalData.statusLabel = TicketStatus.Canceled
          dispatch(ticketActions.setOpeningViewPackageModal(newTicketModalData))
        }
        //reload order list data
        onReloadShift && onReloadShift(data?.shift)
        
        updateTicketCancel(data?.id || "", request.isRefund, data?.scheduledVisitDateTime || null)
        if(request.isCancelRemaining) updateTicketCancelRemaining(data?.packageSubmitId || 0)
      } else {
        toast.error(response.message, ToastDefaultConfig())
      }
    }).finally(() => {
      setLoadingCancel(false)
    })
  }
  
  if (!isShow || !data) return <></>
  return (
    <>
      <Modal
        show={isShow && !isShowModalSuccess}
        backdrop='static'
        keyboard={false}
        size='lg'
        className={className}
        onHide={onHide}
      >
        <Modal.Header closeButton className='header-primary'>
          <Modal.Title className='fw-normal'>{t('CANCEL_PACKAGE_MODAL.TITLE')}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={CancelSchema}
          initialValues={initCancelForm}
          onSubmit={(values) => {
            submittedCancel(values)
          }}
        >
          {({handleSubmit, errors, touched, resetForm, isValid, values, setFieldValue}) => (
            <form id='kt_create_account_form'>
              <div className='cancel-box'>
                <Row>
                  <Col sm={12} className='mt-4 mb-4'>
                    <h6 className='mb-5'>{t("CANCEL_PACKAGE_MODAL.TITLE_REASON")}</h6>
                    {CancellationReason.map((item: SelectFilter) => (
                      <div key={item.value} className="mb-5 checkbox-reason-group">
                        <Form.Check 
                          type="radio"
                          onChange={() => {
                            setFieldValue("reason", item.value)
                          }}
                          checked={item.value == values.reason}
                          id={`default-radio-${item.value}`}
                          label={t(`CANCEL_PACKAGE_MODAL.${item.label}`)}
                          name="reason"
                          className='checkbox-reason'
                        />
                      </div>
                    ))}
                    {errors.reason && touched.reason ? (
                      <div className='small text-danger'>{errors.reason}</div>
                    ) : null}
                  </Col>
                  <Col sm={12} className='mt-4 mb-4 toggle-issues'>
                    <div className='pb-4 mr-3'>
                      <label
                        className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle'
                        style={{width: 'max-content'}}
                      >
                        <input
                          className='form-check-input mx-2'
                          type='checkbox'
                          name='issueRefund'
                          checked={values.issueRefund}
                          onChange={() => {
                            setFieldValue("issueRefund", !values.issueRefund)
                          }}
                        />
                        <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                          {t('CANCEL_PACKAGE_MODAL.ISSUE_REFUND')}
                        </span>
                      </label>
                    </div>

                    <div className={`pb-4 mr-3 ${rtl ? 'toggle-remaining-right' : 'toggle-remaining-left'}`}>
                      <label
                        className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle'
                        style={{width: 'max-content'}}
                      >
                        <input
                          className='form-check-input  mx-2'
                          type='checkbox'
                          name='cancelRemaining'
                          checked={values.cancelRemaining}
                          onChange={() => {
                            setFieldValue("cancelRemaining", !values.cancelRemaining)
                          }}
                        />
                        <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                          {t('CANCEL_PACKAGE_MODAL.CANCEL_REMAINING')}
                        </span>
                      </label>
                    </div>
                  </Col>
                  <Col sm={12} className='mt-4 mb-4'>
                  <InputGroup className=''>
                    <Field
                      className='form-control'
                      name='note'
                      type="textarea"
                      rows="6"
                      placeholder={t('CANCEL_PACKAGE_MODAL.PLACEHOLDER_NOTE')}
                      component="textarea"
                    />
                  </InputGroup>
                  </Col>
                </Row>
                {!ticketPackage?.packageDetails?.isRefund && <div className='warning-refund'>
                  <span>{t("CANCEL_PACKAGE_MODAL.WARNING_NOT_REFUND")}</span>
                </div>}
              </div>

              <div className='d-flex flex-stack p-5 assign-footer'>
                <div className='mr-2'>
                  <button
                    onClick={onBack}
                    type='button'
                    className='btn btn-secondary w-150px me-3 btn-back'
                    data-kt-stepper-action='previous'
                  >
                    {t('BACK')}
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    onClick={() => handleSubmit()}
                    className='btn btn-danger w-150px me-3 btn-submitted'
                    disabled={isLoadingCancel || !isValid}
                  >
                    {isLoadingCancel && (
                      <div
                        className='spinner-border spinner-border-sm me-2'
                        role='status'
                        aria-hidden='true'
                      ></div>
                    )}
                    <span className='indicator-label'>{t('CONFIRM')}</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      {/* Modal Step success */}
      <Modal show={isShowModalSuccess} backdrop='static' keyboard={false} className='modal-success'>
        <Modal.Body>
          <CompletedCancelPackage finishSuccess={finishSuccess} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CancelPackageModal}
