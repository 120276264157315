import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { Col, InputGroup } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';

import { STEP_USERS } from '../../../../../../utils/common/constants';
import { SelectFilter } from '../../../../../../utils/common/models';
import { KTSVG } from '../../../../../../utils/helpers';
import { UserFormModel } from '../../../../models';

type Props = {
  rtl?: boolean,
  errors: FormikErrors<UserFormModel>,
  touched: FormikTouched<UserFormModel>,
  currentCheckCustomStep: number,
  toggleActive: boolean | undefined,
  changeActive: () => void,
  optionBuses : SelectFilter[],
  selectBuses: SelectFilter[],
  overrideStrings: any,
  removeItemBuses: (id: string) => void,
  handleChangeBuses: (event: SelectFilter[]) => void
}

const WorkInfoDriver: React.FC<Props> = ({
  errors, touched, currentCheckCustomStep, rtl,
  optionBuses, selectBuses, overrideStrings, removeItemBuses, handleChangeBuses,
  toggleActive, changeActive
})  => {

  const { t } = useTranslation();
  
  return (
    <>
      <Col sm={12} className='mt-8'>
        <h6>{t('USERS_PAGE.CONTENT_STEP.STEP3.TITLE_DRIVER_USE')}</h6>
        <InputGroup className='filter-calender'>
          <InputGroup.Text id='basic-addon1'>
              <KTSVG defaultColor={true} path="/assets/images/icons/service-and-skill.svg" />
          </InputGroup.Text>
          <MultiSelect
              options={optionBuses}
              value={selectBuses}
              onChange={handleChangeBuses}
              labelledBy="Select Buses *"
              className='form-multi-select form-multi-select-mobile'
              overrideStrings={overrideStrings}
          />
        </InputGroup>
        {selectBuses.length == 0 && currentCheckCustomStep >= STEP_USERS.WORK_INFO ? (
            <div className='small text-danger'>{t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_BUS')}</div>
        ) : null}

        <div className='list-item--select'>
          {selectBuses && selectBuses.map((bus: SelectFilter) => (
          <div className="item" key={bus.value}>
            <span className='name'>{bus.label}</span> <span onClick={() => removeItemBuses?.(bus.value)} className='close'> <i className="fas fa-times"></i></span>
          </div>
          ))}
        </div>
      </Col>
      <Col sm={12} className='mt-8'>
        <div className='pb-4'>
          <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid select-toggle' style={{width: 'max-content'}}>
            <input className='form-check-input  mx-2' type='checkbox' checked={toggleActive} onChange={changeActive}/>
            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>{t('USERS_PAGE.CONTENT_STEP.STEP4.ACTIVE')}</span>
          </label>
        </div>
      </Col>
    </>
  )
}

export { WorkInfoDriver }