import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FallbackView } from '../../theme/partials';
// import HomePage from '../modules/home/HomePage';
import OnlineBookingPage from '../modules/online-booking/OnlineBookingPage';
import ReportPage from '../modules/reports/ReportPage'
import { PageUrl } from '../utils/common/constants';
import ServiceManagementPage from 'app/modules/service-management/ServiceManagementPage';
import GrowthAndMarketingPage from 'app/modules/growth-marketing/GrowthAndMarketingPage';

export function PrivateRoutes() {

  const TicketPage = lazy(() => import('../modules/tickets/TicketPage'))
  const UsersPage = lazy(() => import('../modules/users/UserPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* <Route path={PageUrl.HOME} component={HomePage} /> */}
        <Route path={PageUrl.TICKET.ROOT} component={TicketPage} />
        <Route path={PageUrl.USERS.ROOT} component={UsersPage} />
        <Route path={PageUrl.ONLINE_BOOKING.ROOT} component={OnlineBookingPage} />
        <Route path={PageUrl.REPORTS.ROOT} component={ReportPage} />
        <Route path={PageUrl.SERVICE_MANAGEMENT.ROOT} component={ServiceManagementPage} />
        <Route path={PageUrl.GROWTH_AND_MARKETING.ROOT} component={GrowthAndMarketingPage} />
        <Redirect from='/auth' to='/orders' />
        <Redirect exact from='/' to='/orders' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
