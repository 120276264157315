import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

import { EventLogClass, EventLogLabel, EventLogName, EventLogNameDis, TicketStatusFilter, TicketUndeliveredLabel } from '../../../../../utils/common/constants';
import { EventLogDetail } from '../../../models';

type Props = {
    data?: EventLogDetail[];
    rtl?: boolean;
}

const PackageTimeLine: React.FC<Props> = ({ data, rtl }) => {
    const useViewport = () => {
      const [width, setWidth] = React.useState(window.innerWidth);

      React.useEffect(() => {
          const handleWindowResize = () => setWidth(window.innerWidth);
          window.addEventListener("resize", handleWindowResize);
          return () => window.removeEventListener("resize", handleWindowResize);
      }, []);

      return { width };
    };
    const viewPort = useViewport();
    return (
        <div className={`${viewPort.width > 991 && viewPort.width < 1200 ? 'mh-900px' : 'mh-425px'} scroll`}>
            <div className={`${rtl ? "timeline-label-ar" : "timeline-label"} mt-5`} id='package-timeline'>
                {data && data.length > 0 && data.map((item, index) => (
                    <div className='timeline-item' key={index}>
                        <div className={`${rtl ? "timeline-label-ar" : "timeline-label"} fw-bolder text-gray-800 text-center small p-1`}>
                            <div><Moment date={item?.dateTime} locale={rtl ? 'ar-SA' : 'en-US'} format="HH:mm" /></div>
                            <div><Moment date={item?.dateTime} locale={rtl ? 'ar-SA' : 'en-US'} format="MM.DD.YY" /></div>
                        </div>
                        <div className='timeline-badge'>
                            <i className={'fa fa-genderless fs-1 ' + EventLogClass[item.event]}></i>
                        </div>
                        <div className='fw-bold timeline-content px-5 small'>
                            <div>{EventLogNameDis[item.event] ? t(EventLogNameDis[item.event]) : item.event} - [{t(item.source)}] {rtl ? item.userActionAr : item.userActionEn}</div>

                            {item.event === EventLogName.Package_Ticket_Undelivered &&
                                <div className='text-muted'>
                                    {t(TicketUndeliveredLabel[item.ticketStatus])}
                                </div>
                            }

                            {item.event === EventLogName.Ticket_Reassigned &&
                                <div className='text-muted'>
                                     {t("VIEW_PACKAGE_MODAL.TIME_SLOT_REASSIGN_DATE", {
                                        oldDate: item.reassignOldDate ? moment(item.reassignOldDate).locale(rtl ? 'ar-SA' : 'en-US').format("MM/DD/YYYY hh:mm A") : ' _ ',
                                        newDate: item.reassignNewDate ? moment(item.reassignNewDate).locale(rtl ? 'ar-SA' : 'en-US').format("MM/DD/YYYY hh:mm A") : ' _ '
                                     })}
                                </div>
                            }
                        </div> 
                    </div>
                ))}
            </div>
        </div>
    )
}

export { PackageTimeLine }
