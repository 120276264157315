import { Field, FormikErrors } from 'formik';
import React, { useState } from 'react';
import { Col, InputGroup } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { boolean } from 'yup/lib/locale';
import { useAppSelector } from '../../../../../../../setup/redux/Hooks';

import { City, Coord, UserFormModel } from '../../../../models';
import { OperationHoursAndMap } from '../../../../models/OperationHoursAndMap';
import MapCoverage from '../service-area/MapCoverage';
import { selectCityData } from '../../../../redux/UserSlice';

type Props = {
  rtl?: boolean,
  operationHoursAndMap: OperationHoursAndMap[];
  updateValueMapArea: (value: Coord[]) => void;
  errors: FormikErrors<UserFormModel>,
  values: UserFormModel
}

const WorkLocationBus: React.FC<Props> = ({
  rtl,
  operationHoursAndMap,
  updateValueMapArea,
  errors,
  values
}) => {
  const { t } = useTranslation();
  const cities = useAppSelector(selectCityData)

  return (
    <>
      {/* Map coverage */}
      <Col sm={12} className='mt-8'>
        {/* <h3>{t('USERS_PAGE.CONTENT_STEP.STEP5.TITLE_CITY')}</h3>

        <InputGroup className='h-50px'>
          <InputGroup.Text id='basic-addon1'>
            <img src='/assets/images/icons/globe.svg'></img>
          </InputGroup.Text>
          <Field as="select" name="cityId" className='form-select'>
            <option value="" disabled>{t('USERS_PAGE.CONTENT_STEP.STEP2.CITY')}</option>
            {cities && cities.map((cities: City) => (
              <option key={cities.id} value={cities.id}>{rtl ? cities?.nameAr : cities?.nameEn}</option>
            ))}

          </Field>
        </InputGroup>
        {errors.cityId ? (
          <div className='small text-danger'>{errors.cityId}</div>
        ) : null} */}

        <h3 className='mt-3'>{t('USERS_PAGE.CONTENT_STEP.STEP5.TITLE_GEOGRAPHIC_LOCATION')}</h3>
        {errors.serviceArea ? <div className='small text-danger'>{errors.serviceArea}</div> : null}
        <MapCoverage
          values={values}
          rtl={rtl}
          operationHoursAndMap={operationHoursAndMap}
          updateValueMapArea={updateValueMapArea}
        />
      </Col>
    </>
  )
}

export default WorkLocationBus;