import * as Yup from "yup"

export const ServiceManagementPackageSchema = Yup.object().shape({
  packageInfo: Yup.object().shape({
    subServiceId: Yup.number().typeError('Sub-service ID must be a number'),
    subServiceNameAr: Yup.string().required('Arabic sub-service name is required'),
    subServiceNameEn: Yup.string().required('English sub-service name is required'),
    workFolowId: Yup.number().typeError('Work flow ID must be a number'),
    packageNameAr: Yup.string().required('Arabic package name is required'),
    packageNameEn: Yup.string().required('English package name is required'),
    numberOfPackageWeeks: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .typeError('Number of weeks must be a number'),
    numberOfWeeklyVisits: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .typeError('Number of weekly visits must be a number'),
    numberOfVisits: Yup.number(),
    visitDuration: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .typeError('Visit duration must be a number'),
    shiftType: Yup.mixed().oneOf([0, 1, 2, null, undefined], "Shift type must match [1, 2, null, undefined]"),
    numberOfWorker: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .typeError('Number of workers must be a number'),
    rescheduleLimit: Yup.number()
      .moreThan(-1, 'Must be greater than -1')
      .typeError('Reschedule limit must be a number'),
    isActive: Yup.boolean().required('Active status is required'),
    isShowNumberOfWorker: Yup.boolean().required('Show number of workers status is required'),
    packagePrice: Yup.number()
      .typeError('Package price must be a number'),
    packagePriceWithVat: Yup.number()
      .typeError('Package price with VAT must be a number'),
    packageOriginalPrice: Yup.number()
      .typeError('Package original price must be a number'),
    packageOriginalPriceWithVat: Yup.number()
      .typeError('Package original price with VAT must be a number'),
    packageServicePriceSetId: Yup.number()
      .typeError('Package service price set ID must be a number'),
  }),
  buses: Yup.array().of(
    Yup.object().shape({
      city: Yup.object().shape({
        id: Yup.mixed().required('City ID is required'),
        nameEn: Yup.string().required('City name in English is required'),
        nameAr: Yup.string().required('City name in Arabic is required'),
        countryId: Yup.number(),
        isSelected: Yup.boolean(),
      }),
      buses: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required('Bus ID is required'),
          fullName: Yup.string().required('Bus name is required'),
          fullNameEn: Yup.string().required('Bus name is required'),
          cityId: Yup.number().required('Bus name is required'),
          isSelected: Yup.boolean().nullable(),
          packageServicePriceSetDetail: Yup.object().shape({
            packagePrice: Yup.number().min(0, 'Price before VAT must be a positive number'),
            packagePriceWithVat: Yup.number().min(0, 'VAT must be a non-negative number'),
          }).nullable(),
        })
      ),
    })
  ),
  packageServicePriceSetDefault: Yup.object().shape({
    packagePrice: Yup.number().moreThan(0, 'Must be greater than 0').typeError('must be a number').required('Price before VAT is required'),
    packagePriceWithVat: Yup.number().moreThan(0, 'Must be greater than 0').typeError('must be a number').required('VAT is required'),
  }),
  autoDiscount: Yup.object().shape({
    activeAutoDiscount: Yup.boolean(),
    discountDays: Yup.number().when("activeAutoDiscount", {
      is: true,
      then: Yup.number()
      // .moreThan(0, 'Must be greater than 0').typeError('Discount days must be a number').required('Discount days is required'),
    }),
    discountPercent: Yup.number().when("activeAutoDiscount", {
      is: true,
      then: Yup.number()
      // .moreThan(0, 'Must be greater than 0').max(100, 'Cannot be greater than 100').typeError('Discount percentage must be a number').required('Discount days is required'),
    }),
    arabicPushNotificationTitle: Yup.string()
      .when("activeAutoDiscount", {
        is: true,
        then: Yup.string()
        .required("Arabic push notification title is required when active auto discount is enabled"),
      }),
    englishPushNotificationTitle: Yup.string()
      .when("activeAutoDiscount", {
        is: true,
        then: Yup.string()
        .required("English push notification title is required when active auto discount is enabled"),
      }),
    arabicPushNotificationDescription: Yup.string()
      .when("activeAutoDiscount", {
        is: true,
        then: Yup.string()
        .required("Arabic push notification description is required when active auto discount is enabled"),
      }),
    englishPushNotificationDescription: Yup.string()
      .when("activeAutoDiscount", {
        is: true,
        then: Yup.string()
        .required("English push notification description is required when active auto discount is enabled"),
      }),
  }),
})
