import { UserMetaData, ServiceScopeModel, ServiceScopeSPModel, SPInforModel } from './../models/AuthModel';
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, take, takeLatest } from "redux-saga/effects";
import { HTTP_RESPONSE_STATUS } from "../../../../setup/constants/Http";
import { LoginParam, AuthModel, UserModel } from "../models/AuthModel";
import authApi from "./AuthApi";
import { authActions } from './AuthSlice';
import { Response } from '../../../../setup/axios/HttpResponse';
import { toast } from 'react-toastify';
import { ToastDefaultConfig } from '../../../../setup/toast/ToastConfig';

function* loginRequest(action: PayloadAction<LoginParam>) {
  try {
    const response: Response<{ auth: AuthModel, user: UserModel, userMetaData: UserMetaData }> = yield call(authApi.login, action.payload);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(authActions.loginSuccess(response.data));
      yield put(authActions.getServiceScope());
    } else {
      yield put(authActions.loginFailed());
      alert(response.message);
    }
  } catch (error) {
    yield put(authActions.loginFailed());
  }
}

function* getServiceScope() {
  try {
    const response: Response<ServiceScopeModel> = yield call(authApi.getServiceScope);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(authActions.setServiceScope(response.data));
    } else {
      toast.error(response.message, ToastDefaultConfig());
      yield put(authActions.getServiceScopeFalse());
    }
  } catch (error) {
    yield put(authActions.getServiceScopeFalse());
  }
}

function* fetchServiceScopeAvailable() {
  try {
    const response: Response<ServiceScopeSPModel[]> = yield call(authApi.getServiceScopes);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(authActions.fetchServiceScopeCompleted(response.data));
    } else {
      toast.error(response.message, ToastDefaultConfig());
      yield put(authActions.fetchServiceScopeFailed());
    }
  } catch (error) {
    yield put(authActions.fetchServiceScopeFailed());
  }
}

function* fetchAllSPLinkedWorker() {
  try {
    const response: Response<SPInforModel[]> = yield call(authApi.getAllSPLinkedWorker);
    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      yield put(authActions.fetchAllSpLinkedWorkerSuccess(response.data));
    } else {
      yield put(authActions.fetchAllSpLinkedWorkerFail());
    }
  } catch (error) {
    yield put(authActions.fetchAllSpLinkedWorkerFail());
  }
} 
export function* authSaga() {
  yield takeLatest(authActions.login.type, loginRequest);
  yield takeLatest(authActions.getServiceScope.type, getServiceScope);
  yield takeLatest(authActions.fetchServiceScope.type, fetchServiceScopeAvailable);
  yield takeLatest(authActions.fetchAllSpLinkedWorker, fetchAllSPLinkedWorker);
}