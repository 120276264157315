import { useEffect, useState } from 'react';
import './style.scss';
import { Button, Col, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'setup/redux/Hooks';
import { PromoCodeModel } from '../models/PromoCode';
import { LoyaltyEquation, LoyaltyModel } from '../models/Loyalty';
import demoPromoCodeData from "../demo-promo-code-data.json";
import demoLoyaltyEquation from "../demo-loyalty-equation.json";
import demoLoyaltyData from "../demo-loyalty-data.json";
import PromoCode from "../components/promo-code/PromoCode";
import Loyalty from './loyalty/Loyalty';
import { growthAndMarketingAction } from '../redux/GrowthAndMarketingSlice';
interface Props {
  rtl?: boolean,
}

const TABS = {
  PROMO_CODE: "PROMO_CODE",
  LOYALTY: "LOYALTY"
}

function GrowthMarketing(props: Props) {
  const { rtl } = props
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(TABS.PROMO_CODE)
  const [listLoyalty, setListLoyalty] = useState<LoyaltyModel[]>([]);

  const getListPromoCode = async () => {
    dispatch(growthAndMarketingAction.setListPromoCodeData(demoPromoCodeData as unknown as PromoCodeModel[]))
  }
  const getListLoyalty = async () => {
    dispatch(growthAndMarketingAction.setLoyaltyEquationData(demoLoyaltyEquation as unknown as LoyaltyEquation))
    setListLoyalty(demoLoyaltyData as unknown as LoyaltyModel[]);
  }

  useEffect(() => {
    getListPromoCode();
    getListLoyalty();
  }, [])

  return (
    <div className='settings-container'>
      <Row className='settings-container-header-tabs'>        
        <Col xs={12} sm={6} md={3} className='mt-1 p-5'>
          <Button onClick={() => setActiveTab(TABS.PROMO_CODE)} 
            className={`w-100 settings-container-side-tab-button text-uppercase text-bold ${activeTab === TABS.PROMO_CODE ? "settings-container-side-tab-button--active" : ""}`} 
            variant='outline-warning'>
              {t('GROWTH_AND_MARKETING_PAGE.PROMO_CODE_TAB')}
          </Button>
        </Col>
        <Col xs={12} sm={6} md={3} className='mt-1 p-5'>
          <Button onClick={() => setActiveTab(TABS.LOYALTY)} 
            className={`w-100 settings-container-side-tab-button text-uppercase text-bold ${activeTab === TABS.LOYALTY ? "settings-container-side-tab-button--active" : ""}`} 
            variant='outline-warning'>
            {t('GROWTH_AND_MARKETING_PAGE.LOYALTY')}
          </Button>
        </Col>
        <Col xs={12} sm={6} md={3} className='mt-1 p-5'>
          <Button className={`w-100 settings-container-side-tab-button text-uppercase text-bold disabled icon-block`} 
            variant='outline-warning'>
            {t('GROWTH_AND_MARKETING_PAGE.AUTO_DISCOUNTS')} 
          </Button>
        </Col>
        <Col xs={12} sm={6} md={3} className='mt-1 p-5'>
          <Button className={`w-100 settings-container-side-tab-button text-uppercase text-bold disabled icon-block`} 
            variant='outline-warning'>
            {t('GROWTH_AND_MARKETING_PAGE.AUTOMATED_MARKETING')} 
          </Button>
        </Col>
      </Row>
      <Row className={`d-flex flex-nowrap`}>        
        <Col xs={12} className='settings-container-bg rounded p-3'>
          {activeTab === TABS.PROMO_CODE && <PromoCode isLoading={false}/>}
          {activeTab === TABS.LOYALTY && <Loyalty isLoading={false} listLoyalty={listLoyalty} />}
        </Col>
      </Row>
    </div>
  )
}

export default GrowthMarketing