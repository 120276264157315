import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap-v5'
import { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks'
import { t } from 'i18next'
import { KTSVG, handleWheelInputNumber } from 'app/utils/helpers'
import { LoyaltyEquation } from '../../models/Loyalty'
import './style.scss'
import { growthAndMarketingAction, selectLoyaltyEquation } from '../../redux/GrowthAndMarketingSlice'

interface Props {
  isShow: boolean
  onHide: () => void
  rtl: boolean
}
const EditLoyaltyEquationModal = (props: Props) => {
  const {isShow, onHide, rtl} = props
  const dispatch = useAppDispatch()

  const onSubmitLoyaltyEquation = () => {
    submitForm()
  }

  const initialValues: LoyaltyEquation = {
    numberOfPoints: '',
    expriesDay: '',
    isApplyExpriesDay: true
  };

  const editSchema = Yup.object({
    numberOfPoints: Yup.number().min(1).required('required'),
    expriesDay: Yup.number().min(1).nullable(true)
    // expriesDay: Yup.number().when('isApplyExpriesDay', {
    //   is: true,
    //   then: Yup.number().min(1).required('required')
    // }).when('isApplyExpriesDay', {
    //   is: false,
    //   then: Yup.number().min(1).nullable(true)
    // }),
  })

  const changeApplyExpriesDay = (item: boolean) => {
    if (item == true) {
      setValues({
        ...values,
        'isApplyExpriesDay': true,
      })
    } else {
      setValues({
        ...values,
        'isApplyExpriesDay': false,
        'expriesDay': '',
      })
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: editSchema,
    enableReinitialize: true,
    onSubmit: (_values, formikHelpers) => {
      dispatch(growthAndMarketingAction.updateLoyaltyEquationData(_values))
      formikHelpers.resetForm()
    },
  })
  const {handleChange, handleBlur, handleSubmit, setFieldValue, values, isValid, resetForm, submitForm, setValues, validateForm} = formik

  useEffect(() => {
    validateForm()
  }, [isShow])

  return (
    <Modal
      show={isShow}
      onHide={() => {resetForm(); onHide();}}
      backdrop='static'
      keyboard={false}
      centered
      size='lg'
      className={`modal-edit-loytal-equation ${rtl ? 'modal-edit-loytal-equation-rtl md-rtl' : 'md-ltr'}`}
    >
      <Modal.Header closeButton className='header-primary'>
        <Modal.Title className='fw-18px'>
          {t('GROWTH_AND_MARKETING_PAGE.EDIT_LOYALTY_EQUATION_MODAL.TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row as={'form'} onSubmit={handleSubmit}>
          <Col xs={12} className='mt-17px'>
            <InputGroup className=''>
              <Form.Label className='w-100 fw-17px'>{t('GROWTH_AND_MARKETING_PAGE.EDIT_LOYALTY_EQUATION_MODAL.NUMBER_OF_POINTS_FOR_EVERY_1_SAR')}</Form.Label>
              <InputGroup.Text>
                <KTSVG defaultColor={true} path='/assets/images/icons/coin.svg' />
              </InputGroup.Text>
              <Form.Control
                required
                type='number'
                name='numberOfPoints'
                onChange={handleChange}
                onBlur={handleBlur}
                onWheel={handleWheelInputNumber}
                aria-label='Number of Points'
                placeholder={t('GROWTH_AND_MARKETING_PAGE.EDIT_LOYALTY_EQUATION_MODAL.NUMBER_OF_POINTS')}
                className='h-50px'
                value={values.numberOfPoints}
                min={0}
              />
            </InputGroup>
          </Col>
          <Col xs={12} className='mt-17px'>
            <Row>
              <Col xs={12} className='d-flex justify-content-space-between'>
                <Form.Label className={`fw-17px ${!values.isApplyExpriesDay ? 'opacity-50' : ''}`}>{t('GROWTH_AND_MARKETING_PAGE.EXPRIES_IN')} ({t('GROWTH_AND_MARKETING_PAGE.TABLE_LOYALTY.DAYS')})</Form.Label>
                <Form.Check
                  disabled={false}
                  checked={values.isApplyExpriesDay}
                  name='isApplyExpriesDay'
                  onBlur={handleBlur}
                  onChange={() => changeApplyExpriesDay(!values.isApplyExpriesDay)}
                  className='apply-expries-day-switcher'
                  type='switch'
                  aria-disabled={false}
                />
              </Col>
            </Row>
            <InputGroup className=''>
              <InputGroup.Text>
                <KTSVG className={`${!values.isApplyExpriesDay ? 'svg-disabled' : ''}`} defaultColor={true} path='/assets/images/icons/hash.svg' />
              </InputGroup.Text>
              <Form.Control
                disabled={!values.isApplyExpriesDay}
                type='number'
                name='expriesDay'
                onChange={handleChange}
                onBlur={handleBlur}
                onWheel={handleWheelInputNumber}
                aria-label='Number of Days'
                placeholder={t('GROWTH_AND_MARKETING_PAGE.EDIT_LOYALTY_EQUATION_MODAL.NUMBER_OF_DAYS')}
                className='h-50px'
                value={values.expriesDay}
                min={1}
              />
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col xs={12} className='d-flex justify-content-between'>
          <Col xs={4}>
            <Button
              variant='light'
              className='px-10 fw-normal h-50px text-uppercase btn-close-loyalty-modal'
              onClick={() => {resetForm(); onHide();}}
            >
              {t('CLOSE')}
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              disabled={!isValid}
              variant='dark'
              className='px-10 fw-normal h-50px text-uppercase btn-save-loyalty-modal'
              onClick={onSubmitLoyaltyEquation}
            >
              {/* {true && (
                <div
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></div>
              )} */}
              {t('SAVE')}
            </Button>
          </Col>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}

export default EditLoyaltyEquationModal
