import { authActions } from './../../app/modules/auth/redux/AuthSlice';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../../app/modules/auth';
import * as home from '../../app/modules/home/redux';
import * as ticket from '../../app/modules/tickets/redux';
import * as user from '../../app/modules/users/redux';
import { PayloadAction } from '@reduxjs/toolkit';
import * as onlineBooking from '../../app/modules/online-booking/redux';
import * as serviceManagement from '../../app/modules/service-management/redux';
import * as growthAndMarketingManagement from 'app/modules/growth-marketing/redux';

const mainReducer = combineReducers({
  auth: auth.authReducer,
  home: home.homeReducer,
  ticket: ticket.ticketReducer,
  user: user.userReducer,
  onlineBooking: onlineBooking.onlineBookingReducer,
  serviceManagement: serviceManagement.serviceManagementReducer,
  growthAndMarketingManagement: growthAndMarketingManagement.growthAndMarketingReducer,
})

export const rootReducer = (state: any, action: PayloadAction) => {
  return action.type === authActions.logout.type ? mainReducer({auth: state.auth} as any, action) :  mainReducer(state, action);
}

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.authSaga(), 
    home.homeSaga(), 
    ticket.ticketSaga(), 
    user.UserSaga(),
    onlineBooking.onlineBookingSaga(),
    serviceManagement.serviceManagementSaga(),
    growthAndMarketingManagement.growthAndMarketingSaga(),
  ])
}