import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { RootState } from "../../../../setup";
import { ListResponse, Response } from "../../../../setup/axios/HttpResponse";
import { ServiceScopeModel } from "../../auth/models/AuthModel";
import { ActiveOnlineBookingLinkRequest, CreateOnlineBookingLinkRequest, OnlineBookingModel, OnlineBookingTableModel, SearchOnlineBooking } from "../models";

interface OnlineBookingState {
    openingActionLinkModal: boolean;
    onlineBookingLinkModalData: any;

    // get all online booking
    isLoadingOnlineBooking: boolean;
    onlineBookingData: OnlineBookingTableModel;
    // create online booking
    loadingCreateOnlineBooking: boolean;

    createOnlineBookingCompleted: boolean;

    // Update
    updateOnlineBookingCompleted: boolean;
    // Update Active
    loadingActiveOnlineBooking: boolean;

    onlineBookingDataUpdateOrAdd: any;
}
const initialState: OnlineBookingState = {
    openingActionLinkModal: false,
    onlineBookingLinkModalData: {},

    // get all online booking
    isLoadingOnlineBooking: false,
    onlineBookingData: {
        data: [],
        total: 0
    },
    // create online booking
    loadingCreateOnlineBooking: false,
    createOnlineBookingCompleted: false,
    // Update
    updateOnlineBookingCompleted: false,
    // Update Active
    loadingActiveOnlineBooking: false,
    onlineBookingDataUpdateOrAdd: {},


}
const onlineBookingSlice = createSlice({
    name: "onlineBookingSlice",
    initialState,
    reducers: {
        // Get all online booking
        getOnlineBooking(state, action: PayloadAction<SearchOnlineBooking>) {
            state.isLoadingOnlineBooking = true;
        },
        getOnlineBookingCompleted(state, action: PayloadAction<ListResponse<OnlineBookingModel>>) {
            state.isLoadingOnlineBooking = false;
            state.onlineBookingData = {
                data: action.payload.data.rows,
                total: action.payload.data.pagination.totalRows
            };
        },
        getOnlineBookingFailed(state) {
            state.isLoadingOnlineBooking = false;
        },

        // Create online booking link
        createOnlineBooking(state, action: PayloadAction<CreateOnlineBookingLinkRequest>) {
            state.loadingCreateOnlineBooking = true;
            state.createOnlineBookingCompleted = false;
        },
        createOnlineBookingCompleted(state, action: PayloadAction<Response<OnlineBookingModel>>) {
            state.loadingCreateOnlineBooking = false;
            state.createOnlineBookingCompleted = true;
            state.updateOnlineBookingCompleted = false;
            let itemOnlineBooking = {...action.payload.data};
            state.onlineBookingDataUpdateOrAdd = itemOnlineBooking;
            state.onlineBookingData = {
                data: [...state.onlineBookingData.data, itemOnlineBooking],
                total: state.onlineBookingData.total ? state.onlineBookingData.total++ : 1
            }
        },
        createOnlineBookingFailed(state) {
            state.loadingCreateOnlineBooking = false;
            state.createOnlineBookingCompleted = false;
        },

        // create online booking link
        actionOpenModalOnlineBooking(state, action: PayloadAction<{status: boolean, data: any}>) {
            state.openingActionLinkModal = action.payload.status;
            state.onlineBookingLinkModalData = action.payload.data;
        },
        actionHideModalOnlineBooking(state) {
            state.openingActionLinkModal = false;
            state.onlineBookingLinkModalData = {};
        },

        // Update online booking link
        updateOnlineBookingLinkSuccess(state, action: PayloadAction<Response<OnlineBookingModel>>) {
            state.updateOnlineBookingCompleted = true;
            state.createOnlineBookingCompleted = false;
            state.onlineBookingDataUpdateOrAdd = action.payload.data;
            let index = state.onlineBookingData.data.findIndex((x: OnlineBookingModel) => x.id === action.payload.data.id);
            if (index != -1) {
                state.onlineBookingData.data[index] = action.payload.data;
            }
        },

        // Active online booking link
        activeOnlineBooking(state, action: PayloadAction<{id: string, isActive: boolean}>) {
            state.loadingActiveOnlineBooking = true;
        },
        activeOnlineBookingCompleted(state, action: PayloadAction<ActiveOnlineBookingLinkRequest>) {
            state.loadingActiveOnlineBooking = false;
            let index = state.onlineBookingData.data.findIndex((x: OnlineBookingModel) => x.id === action.payload.id);
            if (index != -1) {
                state.onlineBookingData.data[index].status = action.payload.isActive;
            }
        },
        activeOnlineBookingFailed(state) {
            state.loadingActiveOnlineBooking = false;
        }

    }
})

// online booking
export const selectLoadingOnlineBooking = (state: RootState) => state.onlineBooking.isLoadingOnlineBooking;
export const selectAllDataOnlineBooking = (state: RootState) => state.onlineBooking.onlineBookingData;

// create online booking link
export const selectOpeningActionLinkModal = (state: RootState) => state.onlineBooking.openingActionLinkModal;
export const selectOnlineBookingLinkModalData = (state: RootState) => state.onlineBooking.onlineBookingLinkModalData;
export const selectLoadingCreateOnlineBooking = (state: RootState) => state.onlineBooking.loadingCreateOnlineBooking;
export const selectIsCreateOnlineBookingSuccess = (state: RootState) => state.onlineBooking.createOnlineBookingCompleted;
export const selectIsUpdateOnlineBookingSuccess = (state: RootState) => state.onlineBooking.updateOnlineBookingCompleted;
// Active online booking link
export const selectLoadingActiveOnlineBooking = (state: RootState) => state.onlineBooking.loadingActiveOnlineBooking;
export const selectOnlineBookingDataAddOrUpdate = (state: RootState) => state.onlineBooking.onlineBookingDataUpdateOrAdd;
 
// Action
export const onlineBookingAction = onlineBookingSlice.actions;

// Reducer
const onlineBookingReducer = onlineBookingSlice.reducer;
export default onlineBookingReducer;