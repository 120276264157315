import {Languages, DateFormat} from 'app/utils/common/constants'
import {SpServiceAvailable} from 'app/utils/common/models'
import {Worker} from '../../../app/modules/tickets/models/OrderDrawerModel'
import {useAppSelector} from 'setup/redux/Hooks'
import {useTranslation} from 'react-i18next'
import {formatDateByLocale} from 'app/utils/helpers/OrderDrawerHelpers'

const ServiceProviderItem = (props: {
  selection: {
    label: string
    value: string | number
    item: SpServiceAvailable | Worker
    component: React.ReactNode
  }
  index: number
  onChange: Function
  isOffer?: boolean
  handleFavoriteWorker?: Function
  language?: string
}) => {
  const {t} = useTranslation()
  const user = useAppSelector((s) => s.auth.user)

  const {selection, index, onChange, isOffer, handleFavoriteWorker, language = Languages.ar} = props
  const {value, item, component, label} = selection

  return (
    <div className='worker-item' key={index} onClick={() => onChange(value)}>
      <img className='avatar' alt='' src={item.personalImageUrl || user?.companyLogo} />
      <div className='info ms-2'>
        <span className='name'>{label}</span>
        {'workerId' in item && (
          <span className='start-date'>
            {t('CREATE_ORDER_DRAWER.SECTION_4.START_DATE') +
              ': ' +
              formatDateByLocale(item.startDate, language, DateFormat.FORMAT_1)}
          </span>
        )}
        {!('workerId' in item) && !isOffer && (
          <>
            {item.isAvailableAll ? (
              <span className='fs-6 text-success'>{t('CREATE_ORDER_DRAWER.SECTION_4.DO_ALL')}</span>
            ) : (
              <>
                {item.servicesAvailable && (
                  <span className='fs-6 text-success'>
                    {t('CREATE_ORDER_DRAWER.SECTION_4.CAN_DO')} {item.servicesAvailable}
                  </span>
                )}
                {item.servicesUnavailable && (
                  <span className='fs-6 text-danger'>
                    {t('CREATE_ORDER_DRAWER.SECTION_4.CANT_DO')} {item.servicesUnavailable}
                  </span>
                )}
              </>
            )}
          </>
        )}
        {!('workerId' in item) && item.isSpJoinOffer === false && (
          <div className='text-danger'>{t('REASSIGN_MODAL.ERROR_NOT_SUBSCRIBED')}</div>
        )}
      </div>
      {'workerId' in item && !item.isAuto && (
        <div
          className='favorite'
          onClick={(event) => {
            event.stopPropagation()
            handleFavoriteWorker && handleFavoriteWorker(item)
          }}
        >
          <div className='fav-icon-wrap'>
            <span className={`fav-icon ${item.isFavoriteWorker && 'favorited'}`}></span>
          </div>
          <span>{t('CREATE_ORDER_DRAWER.SECTION_4.FAVORITE_WORKER')}</span>
        </div>
      )}
      <div className='radio-check'>{component}</div>
    </div>
  )
}

export default ServiceProviderItem
