import ar from 'date-fns/locale/ar';
import en from 'date-fns/locale/en-US';
import { Field, FieldConfig, FieldProps } from 'formik';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { classNameFunc } from './utils';
import './FilterDateTimePicker.scss'
import { useEffect, useState } from 'react';
import moment from 'moment';

export interface IPropsFilterDateTimePicker extends Partial<ReactDatePickerProps> {
    rtl?: boolean
}
export const FilterDateTimePicker = (props: IPropsFilterDateTimePicker) => {
    const {
        rtl,
        field,
        form
    } = props as IPropsFilterDateTimePicker & FieldProps;

    const [state, setState] = useState<{
        cursor?: Date | null
    }>({
    })
    useEffect(() => {
        setState(s => ({ ...s, cursor: field?.value === undefined ? null : field?.value }))
    }, [field?.value]);

    const onChangeDate = (date: Date | null) => {
        const cursor = (date === null || date === undefined) ? null : date;
        setState(s => ({ ...s, cursor }));
        form?.setFieldValue(field?.name, cursor)
    }
    return (
        <div className={classNameFunc(['d-flex flex-column', 'filter-datetime'])}>
            <div className='d-flex justify-content-between date-selected-display'>
                <div className={`icon-date ${rtl ? 'rtl' : ''}`}>
                    <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M9.6305 1.24238H8.4975V0.675875C8.4975 0.52563 8.43782 0.381538 8.33158 0.275299C8.22534 0.16906 8.08125 0.109375 7.931 0.109375C7.78075 0.109375 7.63666 0.16906 7.53042 0.275299C7.42418 0.381538 7.3645 0.52563 7.3645 0.675875V1.24238H3.9655V0.675875C3.9655 0.52563 3.90582 0.381538 3.79958 0.275299C3.69334 0.16906 3.54925 0.109375 3.399 0.109375C3.24875 0.109375 3.10466 0.16906 2.99842 0.275299C2.89218 0.381538 2.8325 0.52563 2.8325 0.675875V1.24238H1.6995C1.24876 1.24238 0.81649 1.42143 0.497772 1.74015C0.179054 2.05887 0 2.49114 0 2.94188V9.73988C0 10.1906 0.179054 10.6229 0.497772 10.9416C0.81649 11.2603 1.24876 11.4394 1.6995 11.4394H9.6305C10.0812 11.4394 10.5135 11.2603 10.8322 10.9416C11.1509 10.6229 11.33 10.1906 11.33 9.73988V2.94188C11.33 2.49114 11.1509 2.05887 10.8322 1.74015C10.5135 1.42143 10.0812 1.24238 9.6305 1.24238V1.24238ZM10.197 9.73988C10.197 9.89012 10.1373 10.0342 10.0311 10.1405C9.92484 10.2467 9.78075 10.3064 9.6305 10.3064H1.6995C1.54925 10.3064 1.40516 10.2467 1.29892 10.1405C1.19268 10.0342 1.133 9.89012 1.133 9.73988V5.77438H10.197V9.73988ZM10.197 4.64138H1.133V2.94188C1.133 2.79163 1.19268 2.64754 1.29892 2.5413C1.40516 2.43506 1.54925 2.37538 1.6995 2.37538H2.8325V2.94188C2.8325 3.09212 2.89218 3.23621 2.99842 3.34245C3.10466 3.44869 3.24875 3.50838 3.399 3.50838C3.54925 3.50838 3.69334 3.44869 3.79958 3.34245C3.90582 3.23621 3.9655 3.09212 3.9655 2.94188V2.37538H7.3645V2.94188C7.3645 3.09212 7.42418 3.23621 7.53042 3.34245C7.63666 3.44869 7.78075 3.50838 7.931 3.50838C8.08125 3.50838 8.22534 3.44869 8.33158 3.34245C8.43782 3.23621 8.4975 3.09212 8.4975 2.94188V2.37538H9.6305C9.78075 2.37538 9.92484 2.43506 10.0311 2.5413C10.1373 2.64754 10.197 2.79163 10.197 2.94188V4.64138Z'
                            fill='#7E8299'
                        />
                    </svg>
                </div>
                <input
                    type='text'
                    className={`date-input ${rtl ? 'rtl' : ''}`}
                    value={state.cursor == null ? '' : moment(state.cursor).locale(rtl ? 'ar-SA' : 'en-US').format('DD/MM/YYYY')}
                    disabled
                    placeholder={'Search the status you want ...'}
                ></input>
            </div>
            <DatePicker
                locale={rtl ? ar : en}
                calendarStartDay={0}
                // formatWeekDay={nameOfDay => language === Languages.ar ? nameOfDay : nameOfDay.substr(0, 1)}
                dateFormatCalendar={'MMMM yyyy'}
                inline
                onChange={s => onChangeDate(s)}
                selected={state.cursor}
            />
        </div>
    )
}

export type IPropsFieldFilterDateTimePicker = IPropsFilterDateTimePicker & FieldConfig
export const FieldFilterDateTimePicker = (props: IPropsFieldFilterDateTimePicker) => <Field component={FilterDateTimePicker} {...props} />;