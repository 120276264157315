import { DateHighLighted } from "app/modules/tickets/drawer/components/type";
import { FLOW_TYPE, Languages } from "app/utils/common/constants";
import { TimeSlot, TimeSlotPackage } from "app/utils/common/models";
import { minuteToTime } from "app/utils/services";
import { Field, FieldConfig, FieldProps } from 'formik';
import { useEffect, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastDefaultConfig } from "setup/toast/ToastConfig";
import './DateTimePickerInline.scss';
import ar from 'date-fns/locale/ar';
import en from 'date-fns/locale/en-US';
import { generateDateSelectedSingleVisitPerWeek } from "../../OrderDrawerHelpers";
import { classNameFunc } from ".";

export type TSelectedItem = {
    date: Date,
    time?: TimeSlot,
    timeSlot?: TimeSlot[]
    customerVisitDuration?: number,
    related?: boolean
}
type TOnFetchData = ({ customerVisitDuration: number, timeSlot: TimeSlot[] | TimeSlotPackage[] }) | undefined

type IPropsDateTimePickerInline<isMulti extends boolean = false> = {
    highlightDates?: DateHighLighted[],
    activeDates?: DateHighLighted[],
    isMulti?: isMulti,
    isLoading?: boolean,
    disableDate?: boolean,
    header?: {
        visitTime?: string,
        selectedDate?: string,
        selectedTime?: string,
    },
    label?: {
        emptyTimeSlot?: string,
        empty?: string,

    },
    flowType: string | undefined,
    showSelectedDatetime: boolean,
    intervalDate: number,
    intervalWeek: number,
    selectedDateTime?: isMulti extends true ? (TSelectedItem[]) : TSelectedItem
    onFetchDataInfo: (date: Date | null, newDateHighLight: DateHighLighted[] | undefined, selectedDays: TSelectedItem[] | undefined) => TOnFetchData | Promise<TOnFetchData>
    resetHighlightDays: (dateCheck: Date) => DateHighLighted | Promise<DateHighLighted> | undefined
    customDateClassName: (date: Date, selected_date: isMulti extends true ? (TSelectedItem[]) : TSelectedItem | undefined, current_date: Date) => string | null,
    language: string
} & Partial<ReactDatePickerProps>;

export const DateTimePickerInline = <isMulti extends boolean = false>(props: IPropsDateTimePickerInline<isMulti>) => {
    const {
        language,
        highlightDates = [],
        isLoading = false,
        isMulti: _isMulti = false,
        selectedDateTime: _selected,
        disableDate = false,
        activeDates = [],
        minTime,
        intervalDate,
        intervalWeek,
        label = {
            emptyTimeSlot: 'There is no available time slot on this day. Please select another day',
            ...props.label
        },
        header = {
            visitTime: 'Visit Time',
            selectedDate: 'Selected Date(s)',
            selectedTime: 'Selected Time',
            ...props.header
        },
        flowType,
        showSelectedDatetime = false,
        customDateClassName,
        onFetchDataInfo,
        resetHighlightDays,

        //formik
        field,
        form,
        ...rest
    } = props as IPropsDateTimePickerInline<isMulti> & FieldProps;
    const [state, setState] = useState<{
        isLoading: boolean,
        time?: TimeSlot,
        selected?: TSelectedItem[],
        cursor?: Date
    }>({
        isLoading: false,
        selected: []
    });

    const isPackageFlow = flowType === FLOW_TYPE.PACKAGE

    useEffect(() => {
    }, [])

    useEffect(() => {
        const s = _getSelected();
        setState({ ...state, selected: s, ...(s?.length || 0) == 0 ? { cursor: undefined } : { cursor: s?.[0]?.date } });
        if (isPackageFlow && intervalWeek > 1) {
            setState({ ...state, selected: s, ...(s?.length || 0) == 0 ? { cursor: undefined } : state.cursor === undefined ? { cursor: s?.[0]?.date } : {} });
        }
    }, [field?.value, _selected]);

    const _getSelected = () => {
        return (field !== undefined ? (field.value === undefined ? [] : !Array.isArray(field.value) ? [field.value] : field.value) : _selected) as typeof state.selected;
    }

    const { t } = useTranslation();

    const touched = form?.touched[field.name];
    let _error = form?.errors[field.name];
    let [error, params = ''] = ![null, undefined, ''].includes(_error as any) ? String(typeof _error == 'object' ? Object.entries(_error).map(e => e[1])[0] : _error).split('-') : [_error, '']
    error = ![null, undefined, ''].includes(error as any) ? t(error as string).format(params.split(';')) : error
    const isInvalid = touched !== false && touched !== undefined && error !== undefined;

    const onSelectTimeSlot = (e: TimeSlot) => {
        if (!e.isAvailable || state.cursor === undefined) return;
        const selected = [...state.selected || []];
        if (isPackageFlow && intervalDate === 1 && intervalWeek > 1) {
            selected.forEach(item => item.time = e)
        } else {
            const find = selected.find(e => e.date.isSame(state.cursor as Date))
            if (find == null) return;
            const tmpTimeSlot = { ...e };
            if (!(isPackageFlow)) {
                tmpTimeSlot.end = e.start + (timeSlotsOption?.customerVisitDuration || 0);
            }
            find.time = tmpTimeSlot;
        }
        setState({ ...state, selected });
        onChange(selected)
    }

    const onChangeDate = async (date?: Date | null) => {
        let newDateHighLighted
        if (isPackageFlow && intervalDate && intervalWeek) {
            if (intervalDate !== 1) {
                const totalVisit = intervalDate * intervalWeek
                if (state.selected?.length === totalVisit) {
                    toast.error(`${t('ERROR_MESSAGE.DATE_TIME_ERROR.RANGE_LIMIT_NUMBER_DATE')}`, ToastDefaultConfig());
                    return
                }
            }
            if (date !== null && date !== undefined) {
                if (intervalDate === 1 && intervalWeek > 1) {
                    if (highlightDates.length === 0 || (highlightDates.length > 0 && highlightDates.find(e => e.date.isSame(date)) === undefined)) {
                        newDateHighLighted = await resetHighlightDays(new Date(date));
                        if (!newDateHighLighted) {
                            return
                        }
                    } else if (highlightDates.length > 0 && highlightDates.find(e => e.date.isSame(date)) !== undefined) {
                        return
                    }
                }
            }
        } else {
            if (date !== null && date !== undefined && highlightDates.length > 0 && highlightDates.find(e => e.date.isSame(date)) === undefined) return
        }
        const cursor = (date === null || date === undefined || (state.cursor !== undefined && date.isSame(state.cursor))) ? undefined : date;
        setState({ ...state, isLoading: true, cursor })

        const respData = onFetchDataInfo === undefined ? undefined : (await onFetchDataInfo(date as any, newDateHighLighted as DateHighLighted[] | undefined, state.selected as TSelectedItem[] | undefined))

        if (!respData && isPackageFlow && intervalDate && intervalDate > 1) {
            setState({ ...state, isLoading: false, cursor: undefined })
            return
        }
        const timeSlot = respData?.timeSlot || [];
        const customerVisitDuration = respData?.customerVisitDuration || 0;

        const selected = (
            !_isMulti
                ? date !== null && date !== undefined && intervalDate === 1 && intervalWeek > 1
                    ?
                    generateDateSelectedSingleVisitPerWeek(date, state.selected || [], intervalWeek, timeSlot)
                    : [{ date, time: undefined, timeSlot: timeSlot, customerVisitDuration }]
                : [
                    ...(state.selected || []),
                    ...(date !== null &&
                        date !== undefined &&
                        (state.selected || []).findIndex((e) => e.date.isSame(date)) < 0
                        ? [{ date: date, timeSlot: timeSlot, customerVisitDuration }]
                        : []),
                ].sort((a, b) => a.date.getTime() - b.date.getTime())
        ) as typeof state.selected

        setState({ ...state, cursor, selected, isLoading: false });
        onChange(selected);
    }

    const onClearSelected = (date: Date, time?: TimeSlot) => {
        let selected = [...state.selected || []].filter(e => time === undefined ? !e.date.isSame(date) : true);
        if (time !== undefined) {
            if (isPackageFlow && intervalDate === 1 && intervalWeek > 1) {
                selected = selected.map(s => ({
                    ...s,
                    time: undefined,
                }))
            } else {
                const find = selected.find(e => e.date.isSame(date))
                if (find !== undefined)
                    find.time = undefined
            }
        }

        setState({ ...state, selected, ...time === undefined && { cursor: selected[0]?.date } });
        onChange(selected);
    }

    const onChange = (selected: typeof state.selected) => {
        if (field !== undefined) {
            const values = _isMulti === true || (intervalDate === 1 && intervalWeek > 1) ? selected : selected?.[0]
            form.setFieldValue(field.name, values)
            form.setFieldValue('selected_visit', selected)
        }
    }

    const timeSlotsOption = state.cursor != undefined ? state.selected?.find(e => e.date.isSame(state.cursor as any)) : undefined;
    const emptyAvailable = timeSlotsOption?.timeSlot?.filter(e => e.isAvailable).length === 0

    return (
        <div className={classNameFunc(isInvalid && 'has-error')}>
            {isInvalid && <div tabIndex={-1} className="invalid-feedback">{error}</div>}
            <div className={isPackageFlow ? 'date-time-picker-inline--package-flow' : 'date-time-picker-inline'}>
                <div className='date-picker'>
                    <DatePicker
                        locale={language === Languages.ar ? ar : en}
                        calendarStartDay={1}
                        formatWeekDay={nameOfDay => language === Languages.ar ? nameOfDay : String(nameOfDay).substr(0, 1)}
                        dateFormatCalendar={'MMM, yyyy'}
                        {...rest}
                        {...(() => {
                            if (disableDate || isLoading) {
                                const now = props.minDate == undefined ? new Date() : new Date(props.minDate.getTime());
                                return {
                                    minDate: now,
                                    maxDate: now,
                                    excludeDates: [now]
                                }
                            } else if (!disableDate && activeDates.length !== 0 && !isLoading) {
                                return {
                                    minDate: activeDates[0].date,
                                    maxDate: activeDates[activeDates.length - 1].date,
                                }
                            }
                            else
                                return {}

                        })()}
                        selected={state.cursor}
                        onChange={(date, e) => {
                            onChangeDate(date)
                        }}
                        inline
                        highlightDates={highlightDates?.map(s => s.date) || []}
                        dayClassName={(date: Date) =>
                            //@ts-ignore
                            customDateClassName(date, _isMulti ? state.selected : state.cursor, state.cursor)
                        }
                    />
                </div>
                <div className='time-picker'>
                    <div className="time-title">{header.visitTime}</div>
                    <div className="time-slot-list-container">
                        {(isLoading) ?
                            <div className="d-flex h-100 justify-content-center align-items-center">
                                <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                            </div>
                            : emptyAvailable ? <div className="d-flex h-100 justify-content-center align-items-center p-3">
                                <span>{label.emptyTimeSlot}</span>
                            </div>
                                : <div className="time-slot-list">
                                    {timeSlotsOption?.timeSlot?.map((e, index) => (
                                        <button key={index} type='button' className={`time-slot-item ${!e.isAvailable ? 'disabled' : state.selected?.find(e => state.cursor !== undefined && e.date.isSame(state.cursor))?.time?.start === e.start ? 'active' : ''} `} onClick={() => onSelectTimeSlot(e)}>
                                            <p>
                                                {`${minuteToTime(e.start, true, true, language)} - ${minuteToTime(isPackageFlow ? e.end : (e.start + (timeSlotsOption?.customerVisitDuration || 0)), true, true, language)}`}
                                                <span className="checker"></span>
                                            </p>
                                        </button>
                                    ))}
                                </div>
                        }
                    </div>
                </div>
                {(showSelectedDatetime) &&
                    <div className="date-time-selected">
                        <div className="selected-title">
                            <div className="date-item">{header.selectedDate}</div>
                            <div className="time-item">{header.selectedTime}</div>
                        </div>
                        <div className="selected-list-container">
                            <div className="selected-list">
                                {(isLoading) ?
                                    <div className="d-flex h-100 justify-content-center align-items-center">
                                        <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                                    </div>
                                    : emptyAvailable ? <div className="d-flex h-100 justify-content-center align-items-center">
                                        <span>{label.emptyTimeSlot}</span>
                                    </div>
                                        : state.selected?.map((e, index) => (
                                            <div className="selected-item" key={index}>
                                                <div className="date-item">
                                                    <div className={e.related ? "item-selected--related" : "item-selected"}>
                                                        <span>{e.date?.format('MM/DD/YYYY')}</span>
                                                        {(!disableDate && !isLoading && !e.related) && <button type="button" onClick={() => onClearSelected(e.date)}>
                                                            <i className="fa fa-times"></i>
                                                        </button>}
                                                    </div>
                                                </div>
                                                <div className="time-item">{e.time !== undefined ?
                                                    <div className={e.related ? "item-selected--related" : "item-selected"}>
                                                        <span>{minuteToTime(e.time?.start, true, true, Languages.en)}</span>
                                                        {(!disableDate && !isLoading && !e.related) && <button type="button" onClick={() => onClearSelected(e.date, e.time)}>
                                                            <i className="fa fa-times"></i>
                                                        </button>}
                                                    </div> : (isPackageFlow && intervalDate === 1 && intervalWeek > 1 ?
                                                        (<div className={e.related ? "item-message--related" : "item-message--error"}>
                                                            {e.related ? `${t('ERROR_MESSAGE.DATE_TIME_ERROR.RELATED_TIME')}` : `${t('ERROR_MESSAGE.DATE_TIME_ERROR.MISSING_TIME')}`}
                                                        </div>) : null)
                                                }</div>
                                            </div>
                                        ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export type IPropsFieldDateTimePickerInline<isMulti extends boolean> = IPropsDateTimePickerInline<isMulti> & FieldConfig
export const FieldDateTimePickerInline = <isMulti extends boolean>(props: IPropsFieldDateTimePickerInline<isMulti>) => <Field component={DateTimePickerInline} {...props} />;