
import { Form, FormCheckProps } from 'react-bootstrap-v5';
import React from 'react'

export interface IPropsRadio extends FormCheckProps {
    selected?: boolean
}
export const Radio = (props: IPropsRadio) => {
    const {
        ...rest
    } = props;
    const { } = props;
    return (<Form.Check {...rest} type='radio' />)
}