export const DateFormat = {
  FORMAT_1: 'DD/MM/YYYY',
  FORMAT_2: 'dd/MM/yyyy',
}

export const popperPlacement = {
  TOP_END: 'top-end',
  TOP_START: 'top-start',
}

export const locale = {
  SA: 'ar-SA',
  EN: 'en-US',
}
