import './style.scss';

import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Response } from '../../../../../setup/axios/HttpResponse';
import { HTTP_RESPONSE_STATUS } from '../../../../../setup/constants/Http';
import { useAppDispatch } from '../../../../../setup/redux/Hooks';
import { ToastDefaultConfig } from '../../../../../setup/toast/ToastConfig';
import { StepperComponent } from '../../../../../theme/assets/ts/components';
import { SELECT_TIME_IN_WEEK, STEP_USERS, USER_TYPE } from '../../../../utils/common/constants';
import { SelectFilter, ServiceTreeNode } from '../../../../utils/common/models';
import { convertOperationListToObject } from '../../../../utils/helpers';
import { UserSchemasHelper } from '../../../../utils/helpers/UserValidate';
import { checkFullNameUser, checkValidPhoneNumber } from '../../../../utils/services/userService';
import { ServiceScopeModel } from '../../../auth/models/AuthModel';
import {
  AdminResponse,
  BusNotLinkedDriver,
  CheckStepAccountInfo,
  City,
  DispatchCenter,
  Driver,
  LinkedDispatcher,
  Nationality,
  Skill,
  Team,
  UserFormModel,
  UserInfo,
} from '../../models';
import { OperationHoursAndMap } from '../../models/OperationHoursAndMap';
import { userApi } from '../../redux/UserApi';
import { userActions } from '../../redux/UserSlice';
import AccountInfo from './step/account-info/AccountInfo';
import TechOperationHours from './step/operation-hours/TechOperationHours';
import AccountType from './step/select-user-type/AccountType';
import StepByStepHeader from './step/step-by-step-header/StepByStepHeader';
import WorkInfo from './step/work-info/WorkInfo';
import WorkLocation from './step/work-location/WorkLocation';
import WorkerSuccessModal from './step/worker-success/WorkerSuccessModal';
import async from 'react-select/dist/declarations/src/async/index';

type Props = {
  initialValues: UserFormModel;
  isShowViewModal: boolean;
  isCreatedUserSuccess: boolean;
  loadingCreate: boolean;
  nationalities: Nationality[];
  skills: Skill[];
  linkedDispatchers: LinkedDispatcher[];
  services?: ServiceScopeModel;
  teams: Team[];
  dispatchCenters: DispatchCenter[];
  operationHoursAndMap: OperationHoursAndMap[];
  drivers: Driver[];
  cities: City[];
  busesNotLinkedDriver: BusNotLinkedDriver[];
  rtlMode: boolean;
  onCompleteStepUserType?: (userType: string) => void;
  listAdmin: AdminResponse[];
}

const CreateUserModal: React.FC<Props> = ({
  rtlMode,
  initialValues,
  isShowViewModal,
  isCreatedUserSuccess,
  loadingCreate,
  cities,
  nationalities,
  skills,
  operationHoursAndMap,
  linkedDispatchers,
  services,
  teams,
  dispatchCenters,
  drivers,
  busesNotLinkedDriver,
  onCompleteStepUserType,
  listAdmin,
}) => {
  const { t } = useTranslation()
  const steps = [1, 2, 3, 4, 5]

  const UserSchemas = UserSchemasHelper(t)

  const refForm = useRef<FormikProps<UserFormModel>>()

  const [initValueForm, setInitValueForm] = useState<UserFormModel>(initialValues)
  const [currentStep, setCurrentStep] = useState(1)
  const [isCompleted, setCompleted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [successStep, setSuccessStep] = useState([] as number[])
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false)
  const [currentCheckCustomStep, setCurrentCheckStep] = useState(1)

  const [formDataUpdate, setFormDataUpdate] = useState({})
  const [currentSchema, setCurrentSchema] = useState<any>('')

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const dispatch = useAppDispatch()

  // effect reset data
  useEffect(() => {
    if (!isShowViewModal) return
    resetData()
    if (!stepperRef.current) {
      return
    }
  }, [stepperRef, isShowViewModal])

  // effect show modal success
  useEffect(() => {
    // !isShowViewModal && isShowModalSuccess && !loadingCreate && isCreatedUserSuccess
    if (isCompleted && !loadingCreate && !isShowViewModal && isCreatedUserSuccess)
      setIsShowModalSuccess(true)
  }, [isCompleted, loadingCreate, isShowViewModal, isCreatedUserSuccess])

  //#region Handle step
  const loadStepper = () => {
    stepper.current = StepperComponent.createInstance(stepperRef.current as HTMLDivElement)
    stepper.current?.goFirst()
    setCurrentStep(stepper.current?.currentStepIndex || 1)
    dispatch(userActions.clearLoadingCreateUser())
  }

  const resetData = () => {
    setCurrentSchema(UserSchemas.ACCOUNT_TYPE)
    setIsShowModalSuccess(false)
    setCompleted(false)
    loadStepper()
    setCurrentCheckStep(1)
    setSuccessStep([])
  }

  const handleCloseCreateModal = () => {
    setCurrentSchema(UserSchemas.ACCOUNT_TYPE)
    dispatch(userActions.setActionUserModel({ status: false, data: null }))
  }


  const prevStep = (values: UserFormModel) => {
    if (!stepper.current) return

    let newSuccessStep: number[] = []
    steps.forEach((step: number) => {
      if (stepper.current && step < stepper.current.currentStepIndex) newSuccessStep.push(step)
    })
    setSuccessStep([...newSuccessStep])
    setCompleted(stepper.current.currentStepIndex - 1 === stepper.current.totalStepsNumber)

    if (values.userType !== USER_TYPE.TECHNICIAN && stepper?.current?.currentStepIndex === STEP_USERS.WORK_LOCATION)
      stepper.current.goPrevDouble()
    else
      stepper.current.goPrev()

    setCurrentStep(stepper?.current?.currentStepIndex)

    // update validate form
    switch (stepper?.current?.currentStepIndex) {
      case 1:
        setCurrentSchema('');
        break
      case 2:
        if (values.userType === USER_TYPE.ADMIN) {
          setCurrentSchema(UserSchemas.ACCOUNT_INFO_ADMIN);
        }
        else if (values.userType === USER_TYPE.TECHNICIAN) {
          setCurrentSchema(UserSchemas.ACCOUNT_INFO_TECHNICIAN);
        }
        else if (values.userType === USER_TYPE.WORKER) {
          setCurrentSchema(UserSchemas.ACCOUNT_INFO_WORKER);
        }
        break
      case 3:
        if (values.userType === USER_TYPE.WORKER) setCurrentSchema(UserSchemas.WORK_INFO.WORKER)
        if (values.userType === USER_TYPE.TECHNICIAN) setCurrentSchema(UserSchemas.WORK_INFO.TECH)
        if (values.userType === USER_TYPE.DRIVER) setCurrentSchema(UserSchemas.WORK_INFO.DRIVER)
        break
      case 4:
        if (values.userType === USER_TYPE.TECHNICIAN) setCurrentSchema(UserSchemas.OPERATION_HOURS)
        break
      case 5:
        if (values.userType === USER_TYPE.WORKER) setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)
        if (values.userType === USER_TYPE.DRIVER) setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)
        if (values.userType === USER_TYPE.TECHNICIAN) setCurrentSchema(UserSchemas.WORK_LOCATION.TECH)
        break
    }
  }

  const checkExistEmail = async (values: UserFormModel) => {
    const param = {
      email: values.email
    }
    setLoading(true);
    const response: Response<boolean> = await userApi.checkExistEmail(param);
    setLoading(false);

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      return response.data;
    } else {
      toast.error(response.message, ToastDefaultConfig());
      return false;
    }
  }

  const checkExistPhone = async (values: UserFormModel) => {
    const param = {
      phoneNumber: values.phone
    }
    setLoading(true);
    const response: Response<boolean> = await userApi.checkExistPhone(param);
    setLoading(false);

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      return response.data;
    } else {
      toast.error(response.message, ToastDefaultConfig());
      return false;
    }
  }

  const checkExistUserInfo = async (values: UserFormModel) => {
    const params: CheckStepAccountInfo = {
      workerId: '', //values.workerId
      phoneNumber: `${values?.phone}`,
      email: values.email
    }
    setLoading(true);
    const response: Response<boolean> = await userApi.checkExistUserInfo(params);
    setLoading(false);

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      return response.data;
    } else {
      toast.error(response.message, ToastDefaultConfig());
      return false;
    }
  }

  const processSuccessStep = async (values: UserFormModel, step: number, formikHelpers: FormikHelpers<UserFormModel>) => {
    if (!stepper.current) return
    switch (step) {
      case 1:
        // update validate form next step
        if (values.userType === USER_TYPE.ADMIN) {
          setCurrentSchema(UserSchemas.ACCOUNT_INFO_ADMIN);
        }
        else if (values.userType === USER_TYPE.TECHNICIAN) {
          setCurrentSchema(UserSchemas.ACCOUNT_INFO_TECHNICIAN);
        }
        else if (values.userType === USER_TYPE.WORKER) {
          setCurrentSchema(UserSchemas.ACCOUNT_INFO_WORKER);
        }
        setSuccessStep([1])
        // Call api driver
        stepper?.current.goNext()
        break
      case 2:
        if (values.userType !== USER_TYPE.WORKER && (!checkFullNameUser(values?.fullNameAr) || !checkFullNameUser(values?.fullNameEn))) return;

        if (!checkValidPhoneNumber(values.firstPhoneNumber || '', values.phone || '')) {
          // formikHelpers.setFieldError("phone", "Phone invalid");
        };
        if (values.email && await checkExistEmail(values)) {
          toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
          return;
        }
        if (await checkExistPhone(values)) {
          toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
          return;
        }
        if (values.email && await checkExistUserInfo(values)) {
          toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.USER_HAS_EXIST'), ToastDefaultConfig());
          return;
        }

        // update validate form next step
        if (values.userType === USER_TYPE.WORKER)
          setCurrentSchema(UserSchemas.WORK_INFO.WORKER);
        else if (values.userType === USER_TYPE.TECHNICIAN)
          setCurrentSchema(UserSchemas.WORK_INFO.TECH);
        else if (values.userType === USER_TYPE.DRIVER)
          setCurrentSchema(UserSchemas.WORK_INFO.DRIVER);

        setSuccessStep([1, 2])

        stepper?.current.goNext()
        break

      case 3:
        onCompleteStepUserType?.(values.userType || "");
        // if (values.userType === USER_TYPE.WORKER && (values.listService?.length === 0 || values.listWorkerSkill?.length === 0)) return

        if (values.userType === USER_TYPE.DRIVER && values.linkedBuses?.length === 0) return

        setSuccessStep([1, 2, 3])

        // update validate form next step
        // const isValidServices = values.listService && values.listService.length > 0;
        if (values.userType === USER_TYPE.WORKER) setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)
        // if (values.userType === USER_TYPE.TECHNICIAN && !isValidServices) {
        //   return;
        // }
        if (values.userType === USER_TYPE.TECHNICIAN) {
          stepper?.current.goNext();
          setCurrentSchema(UserSchemas.OPERATION_HOURS);
        }
        else stepper?.current.goNextDouble()
        break
      case 4:
        if (values.isActive && (!values.regularHours || !values.regularHours.some(x => x.isSelected))) {
          formikHelpers.setFieldError("regularHours", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_REGULAR_HOURS'));
          return;
        } else {
          formikHelpers.setErrors({});
        };
        setSuccessStep([1, 2, 3, 4]);
        if (values.userType === USER_TYPE.TECHNICIAN) setCurrentSchema(UserSchemas.WORK_LOCATION.TECH)

        if (values.userType === USER_TYPE.WORKER) setCurrentSchema(UserSchemas.WORK_LOCATION.WORKER)

        stepper?.current.goNext()
        break
    }
    setCurrentStep(stepper?.current?.currentStepIndex);
    formikHelpers.setFieldValue('step', step + 1);
    formikHelpers.setTouched({});
  }

  const isValidSubmitUpdate = (values: UserFormModel) => {
    if (
      values.userType === USER_TYPE.WORKER && (values.workerTravel === true && values.listCity?.length === 0)
    )
      return true
    if (values.userType === USER_TYPE.DRIVER && values.linkedBuses?.length === 0) return true
    return false
  }

  const nextStep = async (values: UserFormModel, formikHelpers: FormikHelpers<UserFormModel>) => {
    if (!stepper.current) return
    // check is submitted
    let isSubmitted = false
    switch (values.userType) {
      case USER_TYPE.ADMIN:
        isSubmitted = currentStep === STEP_USERS.ACCOUNT_INFO
        break;
      case USER_TYPE.DRIVER:
        isSubmitted = currentStep === STEP_USERS.WORK_INFO
        break;

      case USER_TYPE.TECHNICIAN: {
        if (stepper?.current?.currentStepIndex === STEP_USERS.ACCOUNT_TYPE) {
          formikHelpers.setFieldValue('regularHours', JSON.parse(JSON.stringify(SELECT_TIME_IN_WEEK)));
          formikHelpers.setFieldValue('ramadanHours', JSON.parse(JSON.stringify(SELECT_TIME_IN_WEEK)))
        }
        isSubmitted = currentStep === STEP_USERS.WORK_LOCATION
  
        if (isSubmitted && (!values.serviceArea || !values.serviceArea.length)) {
          formikHelpers.setFieldError("serviceArea", "Service area is required!");
          return;
        }
        break;
      }
      case USER_TYPE.WORKER:
        isSubmitted = currentStep === STEP_USERS.WORK_LOCATION

        if (values.userType !== USER_TYPE.WORKER)
          if (isSubmitted && (!values.serviceArea || !values.serviceArea.length)) {
            formikHelpers.setFieldError("serviceArea", "Service area is required!");
            return;
          }

        break;

      default:
        break;
    }
    if (isSubmitted) {
      setCompleted(false)
      if (values.userType !== USER_TYPE.TECHNICIAN && stepper?.current?.currentStepIndex === STEP_USERS.WORK_INFO)
        setCurrentCheckStep(currentStep + 1);
      else
        setCurrentCheckStep(currentStep)


      if (values.userType === USER_TYPE.WORKER && stepper.current.currentStepIndex === STEP_USERS.WORK_LOCATION) {
        let isSubmit = true;
        // if (!values.listSpLinked || !values.listSpLinked.length) {
        //   formikHelpers.setFieldError("listSpLinked", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_BUS'));
        //   isSubmit = false;
        // }

        // if (!values.listCity || !values.listCity.length) {
        //     formikHelpers.setFieldError("listCity", t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.REQUIRE_CITIES'));   
        //     isSubmit = false;             
        // }
        if (!isSubmit) return;
      }
      if (isValidSubmitUpdate(values)) return
      if (values.userType === USER_TYPE.ADMIN) setSuccessStep([1, 2])
      if (values.userType === USER_TYPE.DRIVER) setSuccessStep([1, 2, 3])
      if (values.userType === USER_TYPE.WORKER) setSuccessStep([1, 2, 3, 4, 5])
      if (values.userType === USER_TYPE.TECHNICIAN) setSuccessStep([1, 2, 3, 4, 5])

      if (values.userType === USER_TYPE.ADMIN) {
        if (await checkExistEmail(values)) {
          toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.EMAIL_EXIST'), ToastDefaultConfig());
          return;
        }
        if (await checkExistPhone(values)) {
          toast.error(t('USERS_PAGE.CONTENT_STEP.VALIDATE_STEP.PHONE_EXIST'), ToastDefaultConfig());
          return;
        }
      }
      submitUpdateUser(values)
      return
    }

    if (values.userType !== USER_TYPE.TECHNICIAN && stepper?.current?.currentStepIndex !== 2)
      formikHelpers.setTouched({})
    // process step not submitted
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      setCompleted(false)
      setCurrentCheckStep(stepper?.current?.currentStepIndex)
      processSuccessStep(values, stepper?.current?.currentStepIndex, formikHelpers)
    }
  }
  //#endregion

  const closeModalSuccess = () => {
    dispatch(userActions.setCloseViewUserModal())
    setIsShowModalSuccess(false)
  }

  const splitName = (name: string = '') => {
    const results = name.split(/(\s+)/).filter((item: string) => item.trim().length > 0)
    return results
  }

  const getLastName = (listChar: string[]) => {
    let lastName = ''
    if (listChar.length > 1) {
      for (let i = 1; i < listChar.length; i++) {
        lastName += `${listChar[i]} `
      }
    }
    return lastName
  }

  const setNameFormData = (formData: FormData, fullNameEn?: string, fullNameAr?: string) => {
    let listCharEn: string[] = splitName(fullNameEn);
    let listCharAr: string[] = splitName(fullNameAr);

    let firstNameEn = listCharEn[0] || '';
    let firstNameAr = listCharAr[0] || '';
    let lastNameEn = getLastName(listCharEn) || '';
    let lastNameAr = getLastName(listCharAr) || '';

    formData.append('FirstNameEn', firstNameEn || '')
    formData.append('FirstNameAr', firstNameAr || '')
    formData.append('LastNameAr', lastNameAr || '')
    formData.append('LastNameEn', lastNameEn || '')
  }

  const convertDataSubmit = (values: UserFormModel) => {
    let formData = new FormData()

    //#region Common Data
    // Format name
    formData.append('Avatar', values.avatar);
    formData.append('Email', values?.email || '');
    formData.append('Password', values?.password || '');
    formData.append(
      'PhoneNumber',
      //`${values.firstPhoneNumber === '+966' ? '00966' : '00973'}${values?.phone}`
      `${values?.phone}`
    );
    formData.append('IsActive', `${values?.isActive}`);
    formData.append('UserType', `${values?.userType}`);
    //#endregion

    switch (values.userType) {
      case USER_TYPE.ADMIN:
        formData.append('NationalityId', `${values?.nationality}`);
        formData.append('WorkerId', `${values?.workerId}`);
        formData.append('FullNameAr', values?.fullNameAr || '');
        formData.append('FullNameEn', values?.fullNameEn || '');
        break;
      case USER_TYPE.WORKER:
        formData.append('FirstNameEn', values.firstNameEn || '')
        formData.append('FirstNameAr', values.firstNameAr || '')
        formData.append('LastNameAr', values.lastNameAr || '')
        formData.append('LastNameEn', values.lastNameEn || '')
        formData.append('WorkerId', `${values?.workerId}`);
        formData.append('PricePerMonth', `${values?.pricePerMonth}`);
        formData.append('IsEditPermission', `${values?.isEditPermission}`);

        if (values.listService && values.listService?.length > 0) {
          values.listService?.forEach((data: ServiceTreeNode) =>
            formData.append('SubSubServiceLinkeds', `${data.subSubId}`)
          )
        }

        if (values.listWorkerSkill && values.listWorkerSkill?.length > 0) {
          values.listWorkerSkill?.forEach((data: Skill) =>
            formData.append('WorkerSkills', `${data.id}`)
          )
        }

        if (values.listCity && values.listCity?.length > 0) {
          values.listCity?.forEach((data: SelectFilter) => formData.append('CityLinkeds', `${data.value}`))
        }

        if (values.listSpLinked && values.listSpLinked?.length > 0) {
          values.listSpLinked?.forEach((data: SelectFilter) => formData.append('SpLinkeds', `${data.value}`))
        }
        formData.append('IsAllowTravel', `${values?.workerTravel}`);
        formData.append('NationalityId', `${values?.nationality}`);

        break;

      case USER_TYPE.DRIVER:
        setNameFormData(formData, values?.fullNameEn, values?.fullNameAr);
        if (values.linkedBuses && values.linkedBuses?.length > 0) {
          values.linkedBuses?.forEach((data: BusNotLinkedDriver) =>
            formData.append('LinkedBuses', `${data.id}`)
          )
        }

        break;

      case USER_TYPE.TECHNICIAN:
        formData.append('CityId', `${values?.cityId}`);
        formData.append('FullNameAr', values?.fullNameAr || '');
        formData.append('FullNameEn', values?.fullNameEn || '');
        formData.append('Capacity', `${values?.capacity}`);
        if (values.listService && values.listService?.length > 0) {
          values.listService?.forEach((data: ServiceTreeNode) => formData.append('SubSubServiceLinkeds', `${data.subSubId}`))
        }

        if (values.dispatchCenterLocation) {
          formData.append('DispatchCenterLocation', JSON.stringify([values?.dispatchCenterLocation].map((e: any) => ({ lat: e.lat, lng: e.lng, googleAddress: e.address }))))
        }

        if (values.regularHours && values.regularHours?.length > 0) {
          formData.append('OperationHoursJson', JSON.stringify(convertOperationListToObject(values.regularHours)))
        }

        if (values.ramadanHours && values.ramadanHours?.length > 0) {
          formData.append('RamadanOperationHoursJson', JSON.stringify(convertOperationListToObject(values.ramadanHours)))
        }

        // Map coverage
        if (values.serviceArea && values.serviceArea?.length > 0) {
          let serviceArea = [
            {
              type: "POLYGON",
              id: null,
              geometry: [values.serviceArea.map(x => [x.lat, x.lng])]
            }
          ];
          formData.append('ServiceAreaJson', JSON.stringify(serviceArea))
        }

        if (values.adminId) {
          formData.append('SPAdminId', values.adminId);
        }

        break;

      default:
        break;
    }

    return formData
  }

  const submitUpdateUser = (values: UserFormModel) => {
    const formData: FormData = convertDataSubmit(values)
    setFormDataUpdate(formData)
    dispatch(userActions.createUser(formData))
    setCompleted(true)
  }

  const continueCreateNewUser = (event: string) => {
    const updateValues = { ...initialValues }
    updateValues.userType = event
    setInitValueForm(updateValues)
    dispatch(userActions.setActionUserModel({ status: true, data: null }))
  }

  const hiddenSuccessStep = () => {
    closeModalSuccess()
    setInitValueForm({ ...initialValues })
  }

  return (
    <>
      <Formik
        validationSchema={currentSchema}
        initialValues={{
          ...initValueForm,
          // tech
          // ...{
          //   fullNameAr: "ccc cc",
          //   fullNameEn: "cc cc",
          //   email: "sdvds@cccyopmail.com",
          //   phone: "00966321123452",
          //   firstPhoneNumber: "+966",
          //   confirmPassword: "1",
          //   password: "1"
          // } as any,
          ...({ step: 1, formType: 'create' } as ({ step?: number, formType?: string }))
        }}
        // enableReinitialize
        onSubmit={(values: UserFormModel, formikHelpers: FormikHelpers<UserFormModel>) => nextStep(values, formikHelpers)}
      >
        {(props) => {
          const { handleSubmit, errors, touched, resetForm, isValid, values, setFieldValue, setFieldError, setTouched } = props
          return (
            <>
              <Modal
                show={isShowViewModal && !isShowModalSuccess}
                onHide={() => {
                  handleCloseCreateModal()
                  resetForm()
                  setInitValueForm({ ...initialValues })
                }}
                backdrop='static'
                keyboard={false}
                size='xl'
              >
                <Modal.Header closeButton className='header-primary'>
                  <Modal.Title className='fw-normal'>
                    {t('USERS_PAGE.VIEW_USER_MODAL.TITLE_ADD_BASIC_INFO')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column'
                    id='kt_create_account_stepper'
                  >
                    {/* Header step by step */}
                    <StepByStepHeader values={values} successStep={successStep} step={currentStep} />

                    {/* Step content */}
                    <Form id='kt_create_account_form'>

                      {/* Step Account type */}
                      <div
                        data-kt-stepper-element='content'
                        className={
                          currentStep === STEP_USERS.ACCOUNT_TYPE ? 'current center-box' : 'center-box'
                        }
                      >
                        <AccountType
                          values={values}
                          rtl={rtlMode}
                          setFieldValue={setFieldValue}
                          step={currentStep}
                        />
                      </div>
                      {/* End Step Account type */}

                      {/* Step Account Info */}
                      <div
                        data-kt-stepper-element='content'
                        className={currentStep === STEP_USERS.ACCOUNT_INFO ? 'current' : ''}
                      >
                        {cities && nationalities && (
                          <AccountInfo
                            errors={errors}
                            values={values}
                            touched={touched}
                            rtl={rtlMode}
                            cities={cities}
                            nationalities={nationalities}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            setTouched={setTouched}
                            step={currentStep}
                          />
                        )}
                      </div>
                      {/* End Step Account Info */}

                      {/* Step Work Info */}
                      <div
                        data-kt-stepper-element='content'
                        className={currentStep === STEP_USERS.WORK_INFO ? 'current' : ''}
                      >
                        <WorkInfo
                          errors={errors}
                          values={values}
                          touched={touched}
                          rtl={rtlMode}
                          skills={skills}
                          services={services}
                          busesNotLinkedDriver={busesNotLinkedDriver}
                          dispatchCenters={dispatchCenters}
                          drivers={drivers}
                          linkedDispatchers={linkedDispatchers}
                          currentCheckCustomStep={currentCheckCustomStep}
                          setFieldValue={setFieldValue}
                          setTouched={setTouched}
                          step={currentStep}
                          listAdmin={listAdmin}
                        />
                      </div>
                      {/* End Step Work Info */}

                      {/* Step Operation hours */}
                      <div
                        data-kt-stepper-element='content'
                        className={currentStep === STEP_USERS.OPERATION_HOURS ? 'current' : ''}
                      >
                        <TechOperationHours
                          errors={errors}
                          values={values}
                          touched={touched}
                          rtl={rtlMode}
                          setFieldValue={setFieldValue}
                          setTouched={setTouched}
                          step={currentStep}
                          currentCheckCustomStep={currentCheckCustomStep}
                          operationHoursAndMap={operationHoursAndMap}
                        />
                      </div>
                      {/* End Step Operation hours */}

                      {/* Step Work Location */}
                      <div
                        data-kt-stepper-element='content'
                        className={currentStep === STEP_USERS.WORK_LOCATION ? 'current' : ''}
                      >
                        {teams && cities && (
                          <WorkLocation
                            errors={errors}
                            values={values}
                            touched={touched}
                            rtl={rtlMode}
                            teams={teams}
                            cities={cities}
                            currentCheckCustomStep={currentCheckCustomStep}
                            setFieldValue={setFieldValue}
                            setTouched={setTouched}
                            step={currentStep}
                            operationHoursAndMap={operationHoursAndMap}
                            dispatchCenters={dispatchCenters}
                          />
                        )}
                      </div>
                      {/* End Step Work Location */}
                    </Form>
                  </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-space-between modal-footer-gray group-button-footer'>
                  <div>
                    {currentStep > STEP_USERS.ACCOUNT_TYPE && (
                      <Button
                        className='px-10 fw-normal btn-next btn-back w-200px'
                        data-kt-stepper-action='previous'
                        onClick={() => prevStep(values)}
                      >
                        {t('BACK')}
                      </Button>
                    )}
                  </div>
                  <div>
                    {loadingCreate && (
                      <Button className='px-10 fw-normal btn-next w-200px'>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                        <span className='mx-2'> {t('USERS_PAGE.CONTENT_STEP.LOADING')}</span>
                      </Button>
                    )}
                    {!loadingCreate && (
                      <Button
                        disabled={isLoading}
                        className='px-10 fw-normal btn-next w-200px'
                        onClick={() => handleSubmit()}
                      >
                        {
                          isLoading && <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='mx-1'
                          />
                        }
                        {t(
                          currentStep === STEP_USERS.WORK_LOCATION
                            ? 'USERS_PAGE.ADD_USER'
                            : t(
                              (currentStep === STEP_USERS.WORK_INFO && (values.userType === USER_TYPE.DRIVER))
                                || (currentStep === STEP_USERS.ACCOUNT_INFO && (values.userType === USER_TYPE.ADMIN))
                                ? 'USERS_PAGE.ADD_USER'
                                : 'NEXT'
                            )
                        )}
                      </Button>
                    )}
                  </div>
                </Modal.Footer>
              </Modal>
              {/* Modal Step success */}
              <Modal
                show={
                  !isShowViewModal && isShowModalSuccess && !loadingCreate && isCreatedUserSuccess
                }
                backdrop='static'
                keyboard={false}
              >
                <Modal.Body>
                  <WorkerSuccessModal
                    formDataUpdate={formDataUpdate}
                    continueCreateNewUser={continueCreateNewUser}
                    onHide={() => {
                      hiddenSuccessStep()
                      resetForm()
                    }}
                    rtl={rtlMode}
                  />
                </Modal.Body>
              </Modal>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default CreateUserModal
