import { NodeAction } from "react-dropdown-tree-select";
import { FilterDataSearchTicketIntermediate, SearchTicketDailyCalenderRequest, SpTeamModel } from "../../modules/tickets";
import { BusFilterStatistic, CitiesFilterStatistic, NumberOfWorkersFilterStatistic, PaymentStatusStatistic, ShiftsFilterStatistic, StatusFilterStatistic, WorkersFilterStatistic } from "../../modules/tickets/models/TicketFilter";
import { City, WorkerResponse } from "../../modules/users/models";
import { NO_LINKED_BUS, PAGE_SIZE_SHIFT_TICKET, PaymentStatusConfig, ShiftConfig, TicketStatus, TicketStatusFilter } from "../common/constants";
import { FilterTicketNode } from "../common/models";
import _ from 'lodash';

export const setCityFilterNodeData = (cities: City[], citiesStatistic: CitiesFilterStatistic[], citiesSelected: string[], rtl?: boolean) => {
    const dataTree = [] as FilterTicketNode[];
    if (cities.length < 1) return dataTree;
    let citiesStatisticTemp = (citiesStatistic || []).map(c => {
        return {cityId: c.cityId.toString().split(".")[1], total: c.total}
    });
    cities.forEach(city => {
        let index = citiesStatisticTemp.findIndex(c => c.cityId == city.id);
        let indexItemSelected = citiesSelected.findIndex(c => c == city.id);
        let node = {
            label: rtl ? city.nameAr : city.nameEn,
            value: city.id,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: true,
            deepChildren: [],
            totalTicket: index > -1 ? citiesStatisticTemp[index].total : null,
            actions: index > -1 ? [{className: 'sub-info', text: citiesStatisticTemp[index].total.toString()}] as NodeAction[] : null,
            checked: indexItemSelected > -1 ? true : false
        } as FilterTicketNode

        dataTree.push(node);
    })
    return dataTree;
}

export const setSPTeamFilterNodeData = (spTeams: SpTeamModel[], busStatistic: BusFilterStatistic[], busSelected: string[], rtl?: boolean) => {
    const dataTree = [] as FilterTicketNode[];
    if (spTeams.length < 1) return dataTree;
    busStatistic = (busStatistic || []).filter(b => b.total > 0);
    spTeams.forEach(spTeam => {
        let index = busStatistic.findIndex(b => b.spId == spTeam.id);
        let indexItemSelected = busSelected.findIndex(b => b == spTeam.id);
        let node = {
            label: rtl ? spTeam.fullNameAr : spTeam.fullNameEn,
            value: spTeam.id,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: true,
            deepChildren: [],
            totalTicket: index > -1 ? busStatistic[index].total : null,
            actions: index > -1 ? [{className: 'sub-info', text: busStatistic[index].total.toString()}] as NodeAction[] : null,
            checked: indexItemSelected > -1 ? true : false
        } as FilterTicketNode

        dataTree.push(node);
    })

    return dataTree;
}

export const setShiftFilterNodeData = (shiftStatistic: ShiftsFilterStatistic[], shiftSelected: string[], rtl?: boolean) => {
    const dataTree = [] as FilterTicketNode[];
    let shifts = ShiftConfig;
    shiftStatistic = (shiftStatistic || []).filter(s => s.total > 0);
    shifts.forEach(s => {
        let index = shiftStatistic.findIndex(ss => ss.shift == s.id);
        let indexItemSelected = shiftSelected.findIndex(sh => sh == s.id);
        let node = {
            label: rtl ? s.labelAr : s.labelEn,
            value: s.id,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: true,
            deepChildren: [],
            totalTicket: index > -1 ? shiftStatistic[index].total : null,
            actions: index > -1 ? [{className: 'sub-info', text: shiftStatistic[index].total.toString()}] as NodeAction[] : null,
            checked: indexItemSelected > -1 ? true : false
        } as FilterTicketNode

        dataTree.push(node);
    })

    return dataTree;
}

export const setPaymentStatusFilterNodeData = (paymentStatusStatistic: PaymentStatusStatistic[], paymentStatusSelected: string[], rtl?: boolean) => {
    const dataTree = [] as FilterTicketNode[];
    let paymentStatus = PaymentStatusConfig;
    paymentStatusStatistic = (paymentStatusStatistic || []).filter(p => p.total > 0);
    paymentStatus.forEach(p => {
        let index = paymentStatusStatistic.findIndex(ps => ps.paymentStatus == p.id);
        let indexItemSelected = paymentStatusSelected.findIndex(sh => sh == p.id);
        let node = {
            label: rtl ? p.labelAr : p.labelEn,
            value: p.id,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: true,
            deepChildren: [],
            totalTicket: index > -1 ? paymentStatusStatistic[index].total : null,
            actions: index > -1 ? [{className: 'sub-info', text: paymentStatusStatistic[index].total.toString()}] as NodeAction[] : null,
            checked: indexItemSelected > -1 ? true : false
        } as FilterTicketNode

        dataTree.push(node);
    })

    return dataTree;
}

export const setWorkerFilterNodeData = (workers: WorkerResponse[], spTeam: SpTeamModel[], workerCheckBoxListExpand: string[],
    workerFilterStatistic: WorkersFilterStatistic[], noLinkedbusLabel: string, workerSelected: string[], rtl?: boolean,) => {
    const dataTree = [] as FilterTicketNode[];
    if (workers.length < 1) return dataTree;

    let workerStatistic = workerFilterStatistic || [];

    spTeam.filter(sp => workers.some(w => w.driverId === sp.id)).forEach(sp => {
        let node = {
            label: rtl ? sp.fullNameAr : sp.fullNameEn,
            value: sp.id,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: false,
            deepChildren: [],
            expanded: workerCheckBoxListExpand.findIndex(ws => ws === sp.id) > -1
        } as FilterTicketNode

        let workerOfSP = workers.filter(w => w.driverId === sp.id);
        workerOfSP.forEach(w => {
            let totalTicket = _.uniqBy(workerStatistic.filter(wr => wr.workerId == w.id && wr.spId == sp.id), e => e.ticketId).length;
            let indexItemSelected = workerSelected.findIndex(wr => wr == `${sp.id}/${w.id}`);
            let children = {
                label: rtl ? w.fullNameAr : w.fullNameEn,
                value: `${sp.id}/${w.id}`,
                children: [] as FilterTicketNode[],
                disabled: false,
                isLeaf: true,
                deepChildren: [],
                totalTicket: totalTicket > 0 ? totalTicket : null,
                actions: totalTicket > 0 ? [{className: 'sub-info', text: totalTicket.toString()}] as NodeAction[] : null,
                checked: indexItemSelected > -1 ? true : false
            } as FilterTicketNode
            node.deepChildren?.push(children.value);
            node.children?.push(children);
            node.expanded = node.expanded || node.checked;
        })
        let totalSPTicket = _.uniqBy(workerStatistic.filter(ws => ws.spId == node.value), e => e.ticketId).length;
        node.totalTicket = totalSPTicket > 0 ? totalSPTicket : undefined;
        node.actions = node.totalTicket ? [{className: 'sub-info', text: node.totalTicket.toString()}] as NodeAction[] : undefined;

        dataTree.push(node);
    })

    let workerNoLinkedBus = workers.filter(w => !spTeam.some(sp => sp.id === w.driverId));
    if (workerNoLinkedBus.length > 0) {
        let noLinkedBus = {
            label: noLinkedbusLabel,
            value: NO_LINKED_BUS,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: false,
            deepChildren: [],
            expanded: workerCheckBoxListExpand.findIndex(ws => ws === NO_LINKED_BUS) > -1
        } as FilterTicketNode

        workerNoLinkedBus.forEach(w => {
            let totalTicket = _.uniqBy(workerStatistic.filter(wr => wr.workerId == w.id), e => e.ticketId).length;
            let indexItemSelected = workerSelected.findIndex(wr => wr == `${NO_LINKED_BUS}/${w.id}`);
            let node = {
                label: rtl ? w.fullNameAr : w.fullNameEn,
                value: `${NO_LINKED_BUS}/${w.id}`,
                children: [] as FilterTicketNode[],
                disabled: false,
                isLeaf: true,
                totalTicket: totalTicket > 0 ? totalTicket : null,
                actions: totalTicket > 0 ? [{className: 'sub-info', text: totalTicket.toString()}] as NodeAction[] : null,
                checked: indexItemSelected > -1 ? true : false
            } as FilterTicketNode
    
            noLinkedBus.deepChildren?.push(node.value);
            noLinkedBus.children?.push(node);
            noLinkedBus.expanded = noLinkedBus.expanded || noLinkedBus.checked;
        })
        let allTicketWorkerNoLinkedBus = workerStatistic.filter(wr => workerNoLinkedBus.some(ww => ww.id == wr.workerId));
        noLinkedBus.totalTicket = _.uniqBy(allTicketWorkerNoLinkedBus, e=> e.ticketId).length;
        noLinkedBus.actions = noLinkedBus.totalTicket ? [{className: 'sub-info', text: noLinkedBus.totalTicket.toString()}] as NodeAction[] : undefined;

        dataTree.push(noLinkedBus);
    }

    return dataTree;
}

export const setNumberOfWorkerFilterData = (noOfWorker: number[], numberOfWorkerStatistic: NumberOfWorkersFilterStatistic[], numberOfWorkerSelected: string[]) => {
    const dataTree = [] as FilterTicketNode[];
    if (!noOfWorker || noOfWorker.length < 1) return dataTree;
    let numberOfWorkers: number[] = [...noOfWorker];
    numberOfWorkerStatistic = (numberOfWorkerStatistic || []).filter(n => n.total > 0);
    numberOfWorkers.forEach(w => {
        let index = numberOfWorkerStatistic.findIndex(n => n.numberOfWorker == w.toString());
        let indexItemSelected = numberOfWorkerSelected.findIndex(n => n == w.toString());
        let node = {
            label: w.toString(),
            value: w.toString(),
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: true,
            deepChildren: [],
            totalTicket: index > -1 ? numberOfWorkerStatistic[index].total : null,
            actions: index > -1 ? [{className: 'sub-info', text: numberOfWorkerStatistic[index].total.toString()}] as NodeAction[] : null,
            checked: indexItemSelected > -1 ? true : false
        } as FilterTicketNode

        dataTree.push(node);
    })

    return dataTree;
}

export const setStatusFilterNodeData = (statusStatistic: StatusFilterStatistic[], statusSelected: string[], rtl?: boolean) => {
    const dataTree = [] as FilterTicketNode[];
    let status = TicketStatusFilter;
    let keys = Object.keys(status);
    statusStatistic = (statusStatistic || []).filter(s => s.total > 0);
    keys.forEach(k => {
        let total = 0;
        if (status[k].value == TicketStatus.Completed || status[k].value == TicketStatus.Disputed ||
            status[k].value == TicketStatus.Undelivered) {
            total = statusStatistic.filter(s => s.status.toString().indexOf(status[k].value) > -1).map(s => s.total).reduce((b, a) => b + a, 0);
        } else {
            total = statusStatistic.filter(s => s.status == status[k].value).map(s => s.total).reduce((b, a) => b + a, 0);
        }
        let indexItemSelected = statusSelected.findIndex(s => s == status[k].value);
        let node = {
            label: status[k].label,
            value: status[k].value,
            children: [] as FilterTicketNode[],
            disabled: false,
            isLeaf: true,
            deepChildren: [],
            totalTicket: total,
            actions: total > 0 ? [{className: 'sub-info', text: total.toString()}] as NodeAction[] : null,
            checked: indexItemSelected > -1 ? true : false
        } as FilterTicketNode

        dataTree.push(node);
    })

    return dataTree;
}

export const setFilterDataSearchTicket = (filterData: FilterDataSearchTicketIntermediate) => {
    let generalParam: SearchTicketDailyCalenderRequest = {
        spIds: filterData.spIds,
        keyword: filterData.keyword,
        status: filterData.status,
        workerIds: filterData.workerIds,
        cityIds: filterData.cityIds,
        shift: 0,
        numberOfWorker: filterData.numberOfWorker,
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        pageIndex: filterData.pageIndex,
        pageSize: PAGE_SIZE_SHIFT_TICKET,
        searchByVisitDate: filterData.searchByVisitDate,
        clientTimeZone: filterData.clientTimeZone,
        paymentStatus: filterData.paymentStatus
    }

    return generalParam;
}