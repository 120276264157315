import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState } from '../../../../setup';
import { Currency, Languages } from '../../../utils/common/constants';
import { updateStylePage } from '../../../utils/helpers';
import { AuthModel, UserMetaData, LoginParam, ServiceScopeModel, UserInfoModel, UserModel, ServiceScopeSPModel, SPInforModel } from '../models/AuthModel';
export interface IAuthState {
  user?: UserModel
  authInfo?: AuthModel,
  language: string,
  currency: string,
  isLoading: boolean,
  isLoadingServiceScope: boolean,
  servicesScope?: ServiceScopeModel,
  userMetaData?: UserMetaData,
  errorDomain?: boolean,
  loadingServiceScope?: boolean,
  serviceScopeData?: ServiceScopeSPModel[],
  loadingSpLinkedWorker?: boolean,
  spLinkerWorkerData?: SPInforModel[],
}

const initialState: IAuthState = {
  user: undefined,
  authInfo: undefined,
  language: Languages.ar,
  currency: Currency.country[1]?.[Languages.ar],
  isLoading: false,
  isLoadingServiceScope: false,
  servicesScope: undefined,
  userMetaData: undefined,
  errorDomain: false,
  loadingServiceScope: false,
  serviceScopeData: [],
  loadingSpLinkedWorker: false,
  spLinkerWorkerData: [],
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    errorDomain(state) {
      state.errorDomain = true;
    },
    successDomain(state) {
      state.errorDomain = false;
    },
    login(state, action: PayloadAction<LoginParam>) {
      state.authInfo = undefined;
      state.user = undefined;
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<{ auth: AuthModel, user: UserModel, userMetaData: UserMetaData }>) {
      state.authInfo = action.payload.auth;
      state.user = action.payload.user;
      state.userMetaData = action.payload.userMetaData;
      state.isLoading = false;
    },
    loginFailed(state) {
      state.user = undefined;
      state.authInfo = undefined;
      state.isLoading = false;
    },
    register(state, action: PayloadAction<{ user: UserModel, auth: AuthModel }>) {
      state.authInfo = action.payload.auth;
      state.user = action.payload.user;;
      state.isLoading = true;
    },
    logout(state) {
      state.authInfo = undefined;
      state.user = undefined;
      state.servicesScope = undefined;
      state.userMetaData = undefined;
      state.isLoading = false;
      state.isLoadingServiceScope = false;
      state.serviceScopeData = undefined;
    },
    setUserInfo(state, action: PayloadAction<UserInfoModel>) {
      state.user = action.payload.userInfo;
      state.userMetaData = action.payload.userMetaData;
      state.currency = Currency.country[state.user.companyCountryId || 1]?.[state.language];

    },
    changeLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
      updateStylePage(action.payload === 'ar');
      state.currency = Currency.country[state.user?.companyCountryId || 1]?.[action.payload];
    },
    getServiceScope(state) {
      state.servicesScope = undefined;
      state.isLoadingServiceScope = true;
    },
    setServiceScope(state, action: PayloadAction<ServiceScopeModel>) {
      state.servicesScope = action.payload;
      state.isLoadingServiceScope = false;

    },
    getServiceScopeFalse(state) {
      state.isLoadingServiceScope = false;
    },
    // get Service available
    fetchServiceScope(state) {
        state.loadingServiceScope = true;
        state.serviceScopeData = [];
    },
    fetchServiceScopeCompleted(state, action: PayloadAction<ServiceScopeSPModel[]>) {
        state.loadingServiceScope = false;
        state.serviceScopeData = action.payload
    },
    fetchServiceScopeFailed(state) {
        state.loadingServiceScope = false;
    },
    // all sp linked worker
    fetchAllSpLinkedWorker(state) {
      state.loadingSpLinkedWorker = true;
    },
    fetchAllSpLinkedWorkerSuccess(state, action: PayloadAction<SPInforModel[]>) {
      state.loadingSpLinkedWorker = false;
      state.spLinkerWorkerData = action.payload;
    },
    fetchAllSpLinkedWorkerFail(state) {
      state.loadingSpLinkedWorker = false;
    }
  }
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuth = (state: RootState) => state.auth.authInfo;
export const selectLanguage = (state: RootState) => state.auth.language;
export const selectLoading = (state: RootState) => state.auth.isLoading;
export const selectServiceScope = (state: RootState) => state.auth.servicesScope;
export const selectLoadingServiceScope = (state: RootState) => state.auth.isLoadingServiceScope;
export const selectUserMetaData = (state: RootState) => state.auth.userMetaData;
export const selectCurrency = (state: RootState) => state.auth.currency;
export const selectReportLink = (state: RootState) => state.auth.user?.reportLink;
export const selectMenuConfig = (state: RootState) => state.auth.userMetaData ? state.auth.userMetaData.menuConfig : {};

export const selectErrorDomain = (state: RootState) => state.auth.errorDomain;
export const selectServiceScopeSp = (state: RootState) => state.auth.serviceScopeData;

export const selectLoadingSpLinkedWorker = (state: RootState) => state.auth.loadingSpLinkedWorker;
export const selectSpLinkedWorkerData = (state: RootState) => state.auth.spLinkerWorkerData;

// Reducer
const persistConfig = {
  key: 'b8aksp-auth',
  storage: storage,
  whitelist: ['user', 'authInfo', 'language', 'currency']
};

export const authReducer = persistReducer(persistConfig, authSlice.reducer);

