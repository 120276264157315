import './style.scss';

import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

import { Languages } from '../../../../utils/common/constants';
import MapMaker from '../../../../utils/helpers/components/MapMaker';
import { AcknowTicketModel } from '../../models';
import { useAppSelector } from '../../../../../setup/redux/Hooks';
import { selectLanguage } from '../../../auth';

type Props = {
    className?: string,
    data: AcknowTicketModel | null,
    isShow: boolean,
    isLoading?: boolean,
    onHide?: () => void,
    onSubmit?: (data: AcknowTicketModel) => void,
    onDispute?: (data: string) => void,
}

// Acknow

const AcknowTicketModal: React.FC<Props> = ({ className, data, isShow, isLoading, onHide, onSubmit, onDispute }) => {
    const [timeRemaining, setTimeRemaining] = useState<moment.Duration | null>(null)
    const { t } = useTranslation();
    const language = useAppSelector(selectLanguage);
    const rtlMode = language === Languages.ar;

    useEffect(() => {
        if (isShow && data && data.ackTimerRemaining > 0) {
            let expTime = moment().add(data.ackTimerRemaining, 'minutes');
            const refreshSecondsRemaining = setInterval(() => {
                let minutes = moment.duration(expTime.diff(moment()));
                setTimeRemaining(minutes);

                if (minutes.asSeconds() <= 1) {
                    clearInterval(refreshSecondsRemaining);
                    setTimeRemaining(null);
                }
            }, 1000);

            return function cleanup() {
                clearInterval(refreshSecondsRemaining);
            };
        };
    }, [isShow])

    if (data === null) return <></>;

    const handleSubmit = () => {
        onSubmit?.(data);
    }

    const handleDispute = () => {
        onDispute?.(data.id);
    }

    return (
        <>
            <Modal show={isShow} onHide={onHide} backdrop="static" keyboard={false} size='lg' className='new-ticket'>
                <Modal.Header className='header-dark' closeButton={false}>
                    <Modal.Title className='fw-normal'>{t('ACKNOW_MODAL.TITLE')} </Modal.Title>
                </Modal.Header>
                <MapMaker
                    lat={data?.customerLat}
                    lng={data?.customerLng}
                    googleMapURL={`${process.env.REACT_APP_GOOGLE_MAP_URL}&language=${language}` as string}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `200px` }} />}
                    mapElement={<div style={{ height: `100%`, borderRadius: '5px' }} />}
                />
                <Modal.Body>
                    <Row className='ticket-detail'>
                        <Col sm={12} md={6} className='mb-5'>
                            <div className='type'>
                                <strong className='fs-5'>
                                    {t('ACKNOW_MODAL.TYPE')}:
                                </strong>
                                <div className=''>
                                    {rtlMode ? data.majorNameAr : data.majorNameEn}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} className='mb-5'>
                            <div className='customer'>
                                <strong className='fs-5'>
                                    {t('ACKNOW_MODAL.CUSTOMER')}:
                                </strong>
                                <div className=''>
                                    {data.customerName ? data.customerName : t('VIEW_TICKET_MODAL.NO_NAME')}
                                </div>
                                <div className=''>
                                    {data.customerPhoneNumber}
                                </div>
                                <div className=''>
                                    {data.customerLocationRemarks}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} className='mb-5'>
                            <div className='type'>
                                <strong className='fs-5'>
                                    {t('ACKNOW_MODAL.TIME_ADDRESS')}:
                                </strong>
                                <div className='text-info'>
                                    <i className="far fa-clock text-info me-1"></i> {moment(data.scheduledVisitDateTime).locale(rtlMode ? 'ar-SA' : 'en-US').format('DD.MM.YYYY hh:mm A')}
                                </div>
                                {data.customerLocationRemarks && <div className='text-info'>
                                    <i className="fas fa-location-dot text-info me-2"></i> {data.customerLocationRemarks}
                                </div>}
                            </div>
                        </Col>


                        {data.customerComments &&
                            <Col sm={12} md={6} className='mb-5'>

                                <div className='customer'>
                                    <strong className='fs-5'>
                                        {t('ACKNOW_MODAL.COMMENTS')}:
                                    </strong>
                                    <div className=''>
                                        {data.customerComments}
                                    </div>
                                </div>
                            </Col>
                        }

                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-end modal-footer-gray'>

                    <Button variant="danger" className="px-10 fw-normal me-5" type='submit' disabled={isLoading} onClick={handleDispute}>
                        <span>{t('ACKNOW_MODAL.DISPUTE')}</span>
                    </Button>

                    <Button variant="success" className="px-10 fw-normal" type='submit' disabled={isLoading} onClick={handleSubmit}>
                        {isLoading && <span className="spinner-border w-20px h-20px me-5" role="status" aria-hidden="true"></span>}
                        <span>{t('ACKNOW_MODAL.ACKNOWLEDGE')} {timeRemaining && `(${timeRemaining.format('mm:ss')})`}</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export { AcknowTicketModal as NewTicketModal }
