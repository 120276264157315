import { KTSVG } from "app/utils/helpers"
import { Button, Col, Modal, Row } from "react-bootstrap-v5"
import { t } from 'i18next'
import { TicketModel } from "app/modules/tickets/models"
import { TicketAttendanceSheetModel } from "app/modules/tickets/models/TicketAttendanceDelivery";

type Props = {
  isLoading?: boolean
  isShow?: boolean
  onHide?: () => void
  onConfirm?: () => void
}


const UndoDeliveryActionModal = (props: Props) => {
  const { isLoading, isShow, onHide, onConfirm } = props

  const handleConfirm = () => {
    onConfirm?.()
  }
  return <Modal
    show={isShow}
    onHide={onHide}
    backdrop='static'
    keyboard={false}
    centered
    size='lg'
    className="modal-undo-delivery-action"
  >
    <Modal.Body>
      <Row className="justify-content-center align-self-center">
        <KTSVG defaultColor path="/assets/images/icons/warning-2.svg" />
        <Col xs={12} className="d-flex justify-content-center my-10">
          <div className="h3">{t('VIEW_PACKAGE_MODAL.CONFIRM_CANCEL_DELIVERY_QUESTION')}</div>
        </Col>
        <Col xs={12} className="d-flex justify-content-around">
          <Col xs={5}>
            <Button
              variant='light'
              className='px-10 fw-normal h-50px w-100 text-uppercase'
              onClick={onHide}
              disabled={isLoading}
            >
              {t('NO')}
            </Button>
          </Col>
          <Col xs={5}>
            <Button
              variant='dark'
              className='px-10 fw-normal h-50px w-100 text-uppercase'
              onClick={handleConfirm}
              disabled={isLoading}
            >
              {isLoading && (
                <div
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></div>
              )}
              {t('YES')}
            </Button>
          </Col>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
}

export { UndoDeliveryActionModal as ModalUndoDeliveryAction }