import './theme/assets/sass/style.react.scss';

import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'app/utils/extensions';

import { App } from './app/App';
import * as _redux from './setup';
import store, { persistor } from './setup/redux/Store';
import 'moment/min/locales';

// Redux
// https://github.com/rt2zz/redux-persist
// Axios
// Apps
const { PUBLIC_URL, REACT_APP_EVN } = process.env

_redux.setupAxios(axios, store)

Chart.register(...registerables)

if (REACT_APP_EVN === 'production') {
  console.log = function () {};
  console.error = function () {};
  console.info = function () {};
  console.warn = function () {};
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <App basename={PUBLIC_URL} />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
