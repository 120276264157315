import './style.scss';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button } from 'react-bootstrap-v5';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../setup/redux/Hooks';
import { B8akPagination } from '../../../../theme/layout/components/B8akPagination';
import { API_URL, Languages, PAGESIZE } from '../../../utils/common/constants';
import { KTSVG, statusUserToColor, statusUserToLabel } from '../../../utils/helpers';
import { selectLanguage } from '../../auth';
import { onlineBookingAction, selectAllDataOnlineBooking, selectLoadingActiveOnlineBooking, selectLoadingOnlineBooking } from '../redux/OnlineBookingSlice';
import { ActiveOnlineBookingLinkRequest, OnlineBookingModel, SearchOnlineBooking, UpdateOnlineBookingLinkRequest } from '../models';
import { OnlineBookingApi } from '../redux/OnlineBookingApi';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { formatLinkDisplay } from '../../../utils/helpers/OnlineBookingHelpers';

const OnlineBookingList: React.FC = () => {
  let initFilter: SearchOnlineBooking = {
    pageIndex: 1,
    pageSize: PAGESIZE.EMPLOYERS,
    title: ''
  };
  const styleColumnTable = {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  }

  const { t } = useTranslation();
  const [filter, setFilter] = useState(initFilter);

  const language = useAppSelector(selectLanguage);
  const isLoadingOnlineBooking = useAppSelector(selectLoadingOnlineBooking);
  const onlineBookingData = useAppSelector(selectAllDataOnlineBooking);
  const isLoadingActiveOnlineBooking = useAppSelector(selectLoadingActiveOnlineBooking);
	const rtlMode = language === Languages.ar;

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(onlineBookingAction.getOnlineBooking(initFilter));
  }, []);

  useEffect(() => {
    moment.updateLocale('ar', {
      meridiem: (hours, minutes, isLower) => {
        return hours < 12 ? t("صباحا") : t("مساءاً");
      }
    });
    moment.updateLocale('en', {
      meridiem: (hours, minutes, isLower) => {
        return hours < 12 ? t("AM") : t("PM");
      }
    })
  }, [language])

  const handleShowFormCreate = () => {
    dispatch(onlineBookingAction.actionOpenModalOnlineBooking({status: true, data: null}));
  }
  const handleActiveOnlineBooking = (isActiveCurrent: boolean, cell: OnlineBookingModel) => {
    const paramActive: ActiveOnlineBookingLinkRequest = {
      isActive: isActiveCurrent,
      id: cell.id,
    }
    dispatch(onlineBookingAction.activeOnlineBooking(paramActive));
  }
  const handleUpdateOnlineBooking = (cell: OnlineBookingModel) => {
    if (!cell.isDefault) {
      const itemUpdateDetail = onlineBookingData.data.find((itemOnlineBooking: OnlineBookingModel) => itemOnlineBooking.id === cell.id);
      dispatch(onlineBookingAction.actionOpenModalOnlineBooking({status: true, data: itemUpdateDetail}));
    }
  }
  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
  const handleCopyLink = (cell: OnlineBookingModel) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(`${formatLinkDisplay(cell)}`);
    } else {
      unsecuredCopyToClipboard(`${formatLinkDisplay(cell)}`);
    }
  }
  const actionCellTable = (cell: OnlineBookingModel) => {
    return (
      <>
        <div></div>
        <div className='action-cell'>
          <Dropdown className="d-inline filter-action">
            <Dropdown.Toggle id="dropdown-autoclose-true" variant="secondary" >
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className='dropdown-body'>
                <div className='row-action'>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={(event) => handleActiveOnlineBooking(event.target.checked, cell)}
                      checked={cell.status}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className='m-2'>{t('ONLINE_BOOKING_PAGE.ACTION_TABLE.ACTIVATE')}</span>
                </div>
                <div className={!cell.isDefault ? 'row-action' : 'row-deactive'} onClick={() => handleUpdateOnlineBooking(cell)}>
                  <img src='/assets/images/icons/edit.svg' /> <span>{t('ONLINE_BOOKING_PAGE.ACTION_TABLE.EDIT')}</span>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    )
  }

  const columns = [
    {
      name: t('ONLINE_BOOKING_PAGE.TABLE.TITLE'),
      selector: "",
      cell: (cell: OnlineBookingModel) => (
        <>{cell.title}</>
      ),
      style: styleColumnTable
    },
    {   
      name: t('ONLINE_BOOKING_PAGE.TABLE.LINK'),
      sortable: false,
      selector: "userType",
      cell: (cell: OnlineBookingModel) => (
        <div className='d-flex justify-content-space-between'>
          <a className='shorten-text text-blue'
            title={`${formatLinkDisplay(cell)}`}
            href={`${formatLinkDisplay(cell)}`}
            target="_blank">{`${formatLinkDisplay(cell)}`}</a>
          <i className="fas fa-copy field-links" onClick={() => handleCopyLink(cell)}></i>
        </div>
      ),
      style: styleColumnTable
    },
    {
      name: t('ONLINE_BOOKING_PAGE.TABLE.CREATED'),
      selector: "",
      sortable: false,
      cell: (cell: OnlineBookingModel) => (
        <>{moment(cell.createdAt).locale(rtlMode ? 'ar' : 'en').format('MM.DD.YYYY hh:mm A')}</>
      ),
      style: styleColumnTable
    },
    {
      name: t('ONLINE_BOOKING_PAGE.TABLE.VIEWERS'),
      selector: "",
      sortable: false,
      cell: (cell: OnlineBookingModel) => (
        <>{cell.totalViewer}</>
      ),
      style: styleColumnTable
    },
    {
      name: t('ONLINE_BOOKING_PAGE.TABLE.ORDERS'),
      selector: '',
      cell: (cell: OnlineBookingModel) => (
        <>{cell.totalOrder}</>
      ),
      sortable: false,
      style: styleColumnTable
    },
    {
      name: t('ONLINE_BOOKING_PAGE.TABLE.STATUS'),
      selector: '',
      cell: (cell: OnlineBookingModel) => (
        <strong className={'fw-bold status-label rounded px-3 py-3 ' + statusUserToColor(cell?.status ? 1 : 0)}>{t(statusUserToLabel(cell?.status ? 1 : 0) as string)}</strong>
      ),
      sortable: false,
      style: styleColumnTable
    },
    {
      name: t('ONLINE_BOOKING_PAGE.TABLE.ACTION'),
      selector: '',
      cell: (cell: OnlineBookingModel) => (
        <>{actionCellTable(cell)}</>
      ),
      sortable: false,
      style: styleColumnTable
    },
  ] as IDataTableColumn<OnlineBookingModel>[]

  //#region Handle event

  const handleSearchChange = (event: any) => {
    let newFilter = { ...filter };
    newFilter.title = event.target.value?.trim();
    setFilter(newFilter);
  }

  const btnHandleSearchTitle = () => {
    dispatch(onlineBookingAction.getOnlineBooking({...filter}));
  }
  const handlePageChange = (page: number) => {
    let newFilter = { ...filter };
    newFilter.pageIndex = page;
    setFilter(newFilter);
    dispatch(onlineBookingAction.getOnlineBooking(newFilter));
  }

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    let newFilter = { ...filter };
    newFilter.pageIndex = page;
    newFilter.pageSize = newPerPage;
    setFilter(newFilter);
    dispatch(onlineBookingAction.getOnlineBooking(newFilter));
  }
  const handleSearchTitleKeyDown = (event: any) => {
    if (event.key === 'Enter') btnHandleSearchTitle();
  }
  return (
    <>
      <Card className='b8ak-card' id='employer-table-page'>
        <Card.Header className='border-0'>
          <Row className='flex-root'>
            {/* Filter and Search Data */}

            <Col xs={12} xl={4} className="mb-3">
              <InputGroup className='table-search' >
                <FormControl
                  placeholder={t('ONLINE_BOOKING_PAGE.SEARCH')}
                  aria-label='search'
                  aria-describedby='basic-addon1'
                  onChange={handleSearchChange}
                  className='h-50px'
                  onKeyDown={handleSearchTitleKeyDown}
                />
                <button className="btn h-50px" onClick={btnHandleSearchTitle}>
                  <KTSVG defaultColor={true} path="/assets/images/icons/search-black.svg" />
                </button>
              </InputGroup>
            </Col>
            <Col xs={6} xl={8} className="mb-3 text-end">
              <button className='btn btn-warning fs-14 h-50px' onClick={handleShowFormCreate}>{t('ONLINE_BOOKING_PAGE.CREATE_LINK')}</button>
            </Col>

          </Row>
        </Card.Header>
        <Card.Body>
          <div className='employer-body'>
            {(isLoadingOnlineBooking || isLoadingActiveOnlineBooking) && <div className='employer-loading'>
              <div className='spinner-border' role='status'></div>
            </div>}
            <div className='table-users'>
              <DataTable
                responsive
                columns={columns}
                data={onlineBookingData.data}
                noHeader
                pagination
                paginationServer
                paginationComponent={(e: any) => <B8akPagination event={e} />}
                paginationTotalRows={onlineBookingData?.total}
                paginationDefaultPage={filter.pageIndex}
                paginationPerPage={filter.pageSize}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={<div className='text-center my-10'>{t('ONLINE_BOOKING_PAGE.NO_DATA')}</div>}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
export default OnlineBookingList;
