import { KTSVG } from 'app/utils/helpers'
import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Col, Form, InputGroup, Row } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { Typeahead } from 'react-bootstrap-typeahead';
import { BusInfoDTOs, GetBusInfoDetailByCompanyResponseData, ServiceManagementBusModel, ServiceManagementBusesManagementModel, ServiceManagementPackageInfoModel } from 'app/modules/service-management/models';
import { useAppSelector } from 'setup/redux/Hooks';
import { selectLanguage } from 'app/modules/auth';
import _, { cloneDeep } from 'lodash';
import { City } from 'app/modules/users/models';
import { Languages } from 'app/utils/common/constants';
import { selectCityData } from 'app/modules/users/redux/UserSlice';
import { useDispatch } from 'react-redux';
import { serviceManagementAction } from 'app/modules/service-management/redux/ServiceManagementSlice';
import { ToastDefaultConfig } from 'setup/toast/ToastConfig';
import { toast } from 'react-toastify';

interface Props {
  formik: any,
  packageDataUpdate?: ServiceManagementPackageInfoModel | null
}
const BusesManagement = (props: Props) => {
  const { formik, packageDataUpdate } = props
  const { setFieldValue, values } = formik
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const language = useAppSelector(selectLanguage);
  const rtlMode = language === Languages.ar
  const cities = useAppSelector(selectCityData)

  const [selectedCitiesAndBuses, setSelectedCitiesAndBuses] = useState<ServiceManagementBusesManagementModel[]>([]);
  const [busAndPriceSetData, setBusAndPriceSetData] = useState<GetBusInfoDetailByCompanyResponseData | null>(null)

  const listCitiesAndBuses: ServiceManagementBusesManagementModel[] = useMemo(() => {
    const listBusRaw: BusInfoDTOs[] = _.cloneDeep(busAndPriceSetData?.busInfoDTOs || [])

    //*binding for update
    if (Boolean(packageDataUpdate) && busAndPriceSetData?.packageServicePriceSetDTO) {

      setFieldValue("packageServicePriceSetDefault", {
        packagePrice: busAndPriceSetData?.packageServicePriceSetDTO?.packagePrice || 0,
        packagePriceWithVat: busAndPriceSetData?.packageServicePriceSetDTO?.packagePriceWithVat || 0
      })

      const customPriceSetForEachBusId = _.fromPairs(_.cloneDeep(busAndPriceSetData?.packageServicePriceSetDTO?.packageServicePriceSetDetailDTOs || []).map(item => [item.spId, {
        packagePrice: item.packagePrice,
        packagePriceWithVat: item.packagePriceWithVat
      }]));

      return _.cloneDeep(cities).map(item => {
        const listBusFilteredByCity = _.cloneDeep(listBusRaw).filter(bus => Boolean(bus?.cityId) && Boolean(item?.id) && (bus?.cityId || "").toString().split(".")[1] === (item?.id || "").toString())
        const listBusFilteredByCityForUpdate: ServiceManagementBusModel[] = listBusFilteredByCity.map(bus => ({ ...bus, isSelected: Boolean(customPriceSetForEachBusId[bus.id]) || bus.isLinkedPackage, packageServicePriceSetDetail: customPriceSetForEachBusId[bus.id], isOldData: Boolean(customPriceSetForEachBusId[bus.id]) || bus.isLinkedPackage }))
        return ({ city: item, buses: listBusFilteredByCityForUpdate })
      })
    }

    return _.cloneDeep(cities).map(item => {
      const listBusFilteredByCity = _.cloneDeep(listBusRaw).filter(bus => Boolean(bus?.cityId) && Boolean(item?.id) && (bus?.cityId || "").toString().split(".")[1] === (item?.id || "").toString())
      return ({ city: item, buses: listBusFilteredByCity })
    })
  }, [cities, packageDataUpdate, busAndPriceSetData])
  const citiesOption = useMemo(() => _.cloneDeep(listCitiesAndBuses).filter(item => Boolean(item.buses.length)).map(item => item.city), [listCitiesAndBuses])

  const handleRemoveCity = (id: string) => {
    const newSelected = _.cloneDeep(selectedCitiesAndBuses).filter(item => item.city.id !== id)
    setFieldValue("buses", _.cloneDeep(newSelected))
  }

  const handleSelectCities = (selected: any[]) => {
    const selectedCitiesId = (selected as City[]).map(item => item.id)
    const newSelected = _.cloneDeep(listCitiesAndBuses).filter(item => selectedCitiesId.includes(item.city.id) && selectedCitiesAndBuses.findIndex(x => x.city.id == item.city.id) == -1)
    const newSelectedCitiesAndBus = _.cloneDeep(selectedCitiesAndBuses).concat(newSelected);
    // const isSomeCityNotHaveAnyBus = newSelected.some(item => !Boolean(item.buses.length))

    // if (isSomeCityNotHaveAnyBus) {
    //   const cityDiffName = _.differenceWith(newSelected, selectedCitiesAndBuses, _.isEqual)[0].city[rtlMode ? "nameAr" : "nameEn"];
    //   toast.error(`There are no buses in ${cityDiffName}`, ToastDefaultConfig());
    //   return
    // }
    setFieldValue("buses", _.cloneDeep(newSelectedCitiesAndBus))
  }

  const handleSelectBus = (cityAndBusesObject: ServiceManagementBusesManagementModel, bus: ServiceManagementBusModel) => {
    cityAndBusesObject.buses = _.cloneDeep(cityAndBusesObject.buses).map(item => (_.isEqual(item, bus) ? {
      ...bus, isSelected: !Boolean(bus?.isSelected)
    } : item))
    const newSelected = _.cloneDeep(selectedCitiesAndBuses).map(item => item.city.id === cityAndBusesObject.city.id ? cityAndBusesObject : item)
    setFieldValue("buses", _.cloneDeep(newSelected))
  }

  const getBusAndPriceSetDataCallback = (res: GetBusInfoDetailByCompanyResponseData | null, err: any) => {
    if (err || !res) {
      return
    }
    setBusAndPriceSetData(res)
  }

  const getBusAndPriceSetData = () => {
    dispatch(serviceManagementAction.getBusInfoDetailByCompany({
      callback: getBusAndPriceSetDataCallback, body: { packageId: packageDataUpdate?.packageId }
    }))
  }


  useEffect(() => {
    //*binding for update
    if (Boolean(packageDataUpdate)) {
      const listSelectedCity = _.cloneDeep(listCitiesAndBuses).filter(item => item.buses.some(bus => Boolean(bus?.isOldData) && Boolean(bus?.isSelected))).map(item => item.city)
      handleSelectCities(listSelectedCity)
    }
  }, [listCitiesAndBuses])

  useEffect(() => {
    setSelectedCitiesAndBuses(values.buses)
  }, [values.buses])

  useEffect(() => {
    getBusAndPriceSetData()
  }, [])

  return (
    <Row>
      <Col xs={12} className="mb-2">
        <Form.Group className="mt-3">
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey={rtlMode ? "nameAr" : "nameEn"}
            multiple
            onChange={handleSelectCities}
            options={citiesOption}
            placeholder={t("SERVICE_MANAGEMENT_PAGE.PACKAGE.SELECT_CITIES_YOU_WANT")}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
              <InputGroup className={`mb-3 create-package-offcanvas--filter-by-city-input-group${rtlMode ? "--rtl" : ""}`}>
                <Form.Label className='w-100'>{t("SERVICE_MANAGEMENT_PAGE.PACKAGE.FILTER_BY_CITY")}</Form.Label>
                <InputGroup.Text className='h-35px'><KTSVG defaultColor={true} path='/assets/images/icons/globe-hemisphere-east.svg' /></InputGroup.Text>
                <input {...inputProps}
                  ref={(input: HTMLInputElement | null) => {
                    if (!input) {
                      return
                    }
                    inputRef(input);
                    referenceElementRef(input);
                  }} className='h-35px form-control' />
              </InputGroup>
            )}
            selected={_.cloneDeep(selectedCitiesAndBuses).map(item => item.city)}
          />
        </Form.Group>
      </Col>
      <Col xs={12} className="mb-2 d-flex flex-wrap">
        {_.cloneDeep(selectedCitiesAndBuses).map(item => item.city).map(item => (<>
          <Badge key={`${item.countryId}-${item.id}`} pill className='create-package-offcanvas--custom-badge p-4 d-flex justify-content-between align-items-center me-4 mb-4' bg="dark">
            {item[rtlMode ? "nameAr" : "nameEn"]}<span className='ms-4' onClick={() => handleRemoveCity(item.id)}><KTSVG defaultColor={true} path='/assets/images/icons/x.svg' /></span>
          </Badge>
        </>))}
      </Col>
      <Col xs={12} className="mb-2">
        {selectedCitiesAndBuses.map((item, index) => (<Row key={`${item.city.countryId}-${item.city.id}-${index}`} className='create-package-offcanvas--selection-buses m-0 mb-4'>
          <Col xs={12} className='create-package-offcanvas--selection-buses--title mb-4 p-0'>{item.city[rtlMode ? "nameAr" : "nameEn"] + " " + t("SERVICE_MANAGEMENT_PAGE.PACKAGE.BUSSES")}</Col>
          {Boolean(item.buses?.length) && <Row className='create-package-offcanvas--selection-buses--selection-area m-0 p-4'>
            {item.buses.map(bus => (<Col key={`${item.city.countryId}-${item.city.id}-${index}-${bus.id}`} xs={12} md={6} className='p-0'>
              <Form.Check
                checked={Boolean(bus?.isSelected)}
                onChange={() => handleSelectBus(_.cloneDeep(item), _.cloneDeep(bus))}
                className="create-package-offcanvas--selection-buses--checkbox h-40px"
                label={bus[rtlMode ? "fullName" : "fullNameEn"]}
                type="checkbox"
              />
            </Col>))}
          </Row>}
        </Row>))}
      </Col>
    </Row>
  )
}

export default BusesManagement